import { useReducer } from 'react'

enum ActionTypes {
  goTo = 'goTo',
  goToIndex = 'goToIndex',
  goToPrev = 'goToPrev',
  push = 'push'
}

type Action = {
  type: ActionTypes
  payload?: any
}

export type PathItem<Panel> = {
  panel: Panel
  title: string
  props: any
}

function usePanelNavigation<Panel extends string = string>(
  initialPath: PathItem<Panel>[]
) {
  function pathReducer<Panel>(
    state: PathItem<Panel>[],
    { type, payload }: Action
  ) {
    switch (type) {
      case ActionTypes.goTo:
        const index = state.findIndex(
          item => item.panel === (payload as Panel)
        )
        if (index < 0) {
          return state
        }
        return state.slice(0, index + 1)
      case ActionTypes.goToIndex:
        return state.slice(0, (payload as number) + 1)
      case ActionTypes.goToPrev:
        return state.slice(0, -1)
      case ActionTypes.push:
        return [...state, payload as PathItem<string>]
      default:
        return state
    }
  }

  const [path, dispatch] = useReducer(pathReducer, initialPath)

  return {
    path,
    current: path[path.length - 1],
    goTo(panel: Panel) {
      dispatch({ type: ActionTypes.goTo, payload: panel })
    },
    goToIndex(index: number) {
      dispatch({ type: ActionTypes.goToIndex, payload: index })
    },
    goToPrev() {
      dispatch({ type: ActionTypes.goToPrev })
    },
    push(item: PathItem<Panel>) {
      dispatch({ type: ActionTypes.push, payload: item })
    }
  }
}

export default usePanelNavigation
