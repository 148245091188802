import { useMutation } from '@apollo/client'
import { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'

import useAuth from 'data/gradoo/hooks/useAuth'
import { FIX_BROKEN_PAGES } from 'data/layoutcreator/mutations/projects'
import { isProd } from 'helpers/env'
import { GetDocumentsQuery } from 'types/layoutcreator/graphql'

export const usePagesMigration = ({ documentsData, refetch }: {
  documentsData: GetDocumentsQuery | undefined
  refetch: () => Promise<void>,
}) => {
  const { pathname } = useLocation()
  const projectId = pathname.split('/')[1]
  const { authGroupId: groupId } = useAuth()

  const [isOutdated, setIsOutdated] = useState<boolean | null>(null)
  const [loadingPercent, setLoadingPercent] = useState<number>(0)

  const [isSuccess, setIsSuccess] = useState<boolean>(false)
  const [isError, setIsError] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const [fixBrokenPages] = useMutation(FIX_BROKEN_PAGES)

  const checkIsOutdated = async () => {
    if (!documentsData?.projectDocuments.length) {
      return;
    }

    setIsOutdated(documentsData?.projectDocuments.some((page, index) => page.index !== index || !page.type));
  }

  useEffect(() => {
    console.log('Is Pages Screen Outdated', isOutdated)
  }, [isOutdated])

  useEffect(() => {
    if (documentsData?.projectDocuments && documentsData.projectDocuments.length > 0) {
      checkIsOutdated()
    }
  }, [documentsData])

  const migrate = async () => {
    setIsLoading(true)
    setLoadingPercent(0);
    
    try {
      const result = await fixBrokenPages({
        variables: {
          groupId: groupId!,
          projectId
        }
      })

      console.log('Pages fix result:', result.data?.fixBrokenPages);

      setLoadingPercent(80)
  
      console.log('%cPages order updated successfully', 'color: green')
      setIsSuccess(true)
    } catch {
      console.log('%cError updating pages order', 'color: red')
      setIsError(true)
    }

    await refetch()
    setLoadingPercent(100)
    setIsLoading(false)
  }

  return {
    migrate,
    isOutdated,
    loadingPercent,
    isSuccess,
    isError,
    isLoading
  }
}
