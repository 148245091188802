import { HelmetProvider } from 'react-helmet-async'
import { Toaster } from 'react-hot-toast'
import { IntlProvider } from 'react-intl'
import { BrowserRouter } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'

import ScreenSizeContainer from 'shared/ui/ScreenSizeContainer'
import useDisablePinchZoom from 'shared/hooks/useDisablePinchZoom'
import { useIntercom } from 'shared/hooks/useIntercom'
import 'shared/styles/fonts.css'
import { theme } from 'shared/styles/theme'
import { Locales } from 'shared/types/global'

import Router from './shared/components/Router'
import GlobalStyle from './shared/styles/global'
import { TOASTER_Z_INDEX } from './shared/styles/z-index'
import ChiliColors from 'shared/components/Tools/services/ChiliColors'
import ChiliShapes from 'shared/components/Tools/services/ChiliShapes'
import ChiliImages from 'shared/components/Tools/services/ChiliImages'
import ChiliVariables from 'shared/components/Tools/services/ChiliVariables'
import ChiliDocument from 'shared/components/Tools/services/ChiliDocument'
import ChiliFrames from 'shared/components/Tools/services/ChiliFrames'
import ChiliText from 'shared/components/Tools/services/ChiliText'
import BasicService from 'shared/components/Tools/services/BasicService'

const colorsApi = ChiliColors.getInstance()
const shapesApi = ChiliShapes.getInstance()
const imagesApi = ChiliImages.getInstance()
const variablesApi = ChiliVariables.getInstance()
const documentApi = ChiliDocument.getInstance()
const framesApi = ChiliFrames.getInstance()
const textApi = ChiliText.getInstance()

type AppProps = {
  locale: Locales
  messages: Record<string, string>
}

const App: React.FC<AppProps> = ({ locale, messages }) => {
  useIntercom()
  useDisablePinchZoom()

  return (
    <>
      <GlobalStyle />
      <Toaster containerStyle={{ zIndex: TOASTER_Z_INDEX }} />
      <ThemeProvider theme={theme}>
        <IntlProvider locale={locale} messages={messages}>
          <HelmetProvider>
            <BrowserRouter>
              <ScreenSizeContainer>
                <Router />
              </ScreenSizeContainer>
            </BrowserRouter>
          </HelmetProvider>
        </IntlProvider>
      </ThemeProvider>
    </>
  )
}

export default App
