import { Formats, PagesEntity } from 'shared/types/layoutcreator/graphql'

type FormatDownloadUrl = {
  cover: string
  format: Formats
  grammage: string
  pages: Pick<PagesEntity, 'total'>
}

const FORMAT_TO_MM = {
  a4_portrait: '210-297',
  a4_landscape: '297-210',
  a5_portrait: '148-210',
  a5_landscape: '210-148',
  square_small: '240-240',
  square_big: '210-210'
}

// returns the closest number dividable by 4
const getValidPages = (n: number) => {
  if (n % 4 === 0) {
    return n
  } else if (n % 4 < 2) {
    return n - (n % 4)
  } else {
    return n + (4 - (n % 4))
  }
}

export function formatDownloadUrl({
  cover,
  format,
  grammage,
  pages
}: FormatDownloadUrl): string {
  const baseUrl =
    'https://cloud.we-help-you.de/index.php/s/bqNyP8iqR7i9sWa'
  const path = '%2Fidmls'
  const validPages = getValidPages(pages.total)
  const files = `${cover}-${FORMAT_TO_MM[format]}-${grammage}-${validPages}.idml`
  return `${baseUrl}/download?path=${path}&files=${files}`
}
