import { gql } from '@apollo/client'

export const ADD_NEW_CHILI_IMAGE = gql`
  mutation addNewChiliImage(
    $image: Upload!
    $groupId: String!
    $projectId: String!
  ) {
    addNewChiliImage(
      file: $image
      groupId: $groupId
      projectId: $projectId
    ) {
      id
      path
      name
      resolution
      height
      width
      xml
    }
  }
`

export const ADD_NEW_IMAGE = gql`
  mutation addNewImage(
    $image: Upload!
    $groupId: String!
    $projectId: String!
    $store: Boolean
  ) {
    addNewImage(
      file: $image
      groupId: $groupId
      projectId: $projectId
      store: $store
    ) {
      id
      resolution
      height
      width
      imageQueryParams
      size
    }
  }
`

export const REPLACE_CHILI_IMAGE = gql`
  mutation replaceChiliImage(
    $image: Upload!
    $groupId: String!
    $projectId: String!
    $oldImageId: String
  ) {
    replaceChiliImage(
      file: $image
      groupId: $groupId
      projectId: $projectId
      oldImageId: $oldImageId
    ) {
      id
      path
      name
      resolution
      height
      width
      xml
    }
  }
`

export const REPLACE_IMAGE = gql`
  mutation replaceImage(
    $image: Upload!
    $groupId: String!
    $projectId: String!
    $oldImageId: String
    $store: Boolean
  ) {
    replaceImage(
      file: $image
      groupId: $groupId
      projectId: $projectId
      oldImageId: $oldImageId
      store: $store
    ) {
      id
      resolution
      height
      width
      imageQueryParams
      size
    }
  }
`

export const DELETE_CHILI_IMAGE = gql`
  mutation deleteChiliImage($imageId: String!) {
    deleteChiliImage(imageId: $imageId)
  }
`

export const DELETE_IMAGE = gql`
  mutation deleteImage(
    $groupId: String!
    $projectId: String!
    $imageId: String!
  ) {
    deleteImage(
      groupId: $groupId
      projectId: $projectId
      imageId: $imageId
    )
  }
`
