import { useIntl } from 'react-intl'
import { css } from 'styled-components'
import styled from 'styled-components'

import { StylesMap } from 'styles/types'
import { applyLinearGradient } from 'styles/utils'

export enum Intents {
  primary = 'primary',
  primarySolid = 'primarySolid',
  dark = 'dark',
  neutral = 'neutral',
  outlined = 'outlined',
  outlinedDark = 'outlinedDark',
  danger = 'danger'
}

const darkHoverFilter = () => css<
  Pick<StyledButtonProps, 'isDisabled'>
>`
  &:hover {
    filter: brightness(
      ${({ isDisabled }) => (isDisabled ? '100' : '90')}%
    );
  }
`

const STYLES_MAP: StylesMap<Intents> = {
  [Intents.primary]: css`
    background: ${({ theme }) =>
      applyLinearGradient(theme.gradient.primary.cs01)};
    color: ${({ theme }) => theme.color.base.c0};
    border: none;
    background-size: 100% 100%;

    &:hover {
      background-size: 200% 100%;
    }

    &:active {
      background: ${({ theme }) => theme.color.brand_02};
    }
  `,
  [Intents.primarySolid]: css`
    background: ${({ theme }) => theme.color.brand_02};
    color: ${({ theme }) => theme.color.base.c0};
    border: none;

    ${darkHoverFilter()}
  `,
  [Intents.dark]: css`
    background-color: ${({ theme }) => theme.color.base.c9};
    color: ${({ theme }) => theme.color.base.c0};
    border: none;

    ${darkHoverFilter()}
  `,
  [Intents.neutral]: css`
    background-color: ${({ theme }) => theme.color.base.c2};
    color: ${({ theme }) => theme.color.base.c9};
    border: none;

    ${darkHoverFilter()}
  `,
  [Intents.outlined]: css`
    background: transparent;
    color: ${({ theme }) => theme.color.brand_02};
    border: 3px solid ${({ theme }) => theme.color.brand_02};
  `,
  [Intents.outlinedDark]: css`
    background: transparent;
    color: ${({ theme }) => theme.color.base.c9};
    border: 3px solid ${({ theme }) => theme.color.base.c9};
    transition: all 0.1s;

    &:hover {
      background: ${({ theme }) => theme.color.base.c9};
      color: ${({ theme }) => theme.color.base.c0};
    }
  `,
  [Intents.danger]: css`
    background: ${({ theme }) => theme.color.error};
    color: ${({ theme }) => theme.color.base.c0};
    border: none;
  `
}

type StyledButtonProps = Required<
  Pick<
    ButtonProps,
    'intent' | 'outline' | 'fontSize' | 'borderRadius'
  >
> &
  Partial<
    Pick<
      ButtonProps,
      'isFullWidth' | 'isDisabled' | 'padding' | 'minWidth'
    >
  >

const StyledButton = styled.button<StyledButtonProps>`
  width: ${({ isFullWidth }) => (isFullWidth ? '100%' : 'auto')};
  ${({ minWidth }) => minWidth && `min-width: ${minWidth}px`};
  padding: ${({ padding }) =>
    padding ? `${padding[0]}px ${padding[1]}px` : '16px 32px'};
  font-weight: 600;
  font-size: ${({ fontSize }) => fontSize}px;
  line-height: 20px;
  border-radius: ${({ borderRadius }) => borderRadius}px;
  outline: none;
  cursor: ${({ isDisabled }) =>
    isDisabled ? 'not-allowed' : 'pointer'};
  transition: background-size 0.4s ease;
  transition: filter 0.1s;

  &:focus {
    ${({ isDisabled, outline }) =>
      !isDisabled &&
      outline &&
      css`
        box-shadow: 0 0 0 2.5px #ffffff,
          0 0 0 6px ${({ theme }) => theme.color.brand_02};
      `}
  }

  &:hover {
    background-position: bottom;
  }

  ${({ intent }) => STYLES_MAP[intent]}
`

export type ButtonProps = {
  testID?: string
  labelId?: string
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void
  type?: 'submit' | 'button'
  intent?: Intents
  isFullWidth?: boolean
  isDisabled?: boolean
  padding?: [number, number]
  minWidth?: number
  fontSize?: number
  borderRadius?: number
  outline?: boolean
  href?: string
}

const Button = ({
  testID,
  labelId,
  onClick,
  type,
  intent = Intents.primary,
  isFullWidth = false,
  isDisabled = false,
  minWidth,
  padding,
  fontSize = 16,
  borderRadius = 14,
  outline = true,
  href,
  ...rest
}: ButtonProps) => {
  const { formatMessage } = useIntl()

  const handleOnClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (!isDisabled && onClick) {
      onClick(e)
    }
  }

  return (
    <StyledButton<'button' | 'a'>
      data-testid={testID}
      onClick={handleOnClick}
      type={type}
      intent={intent}
      isFullWidth={isFullWidth}
      isDisabled={isDisabled}
      padding={padding}
      fontSize={fontSize}
      borderRadius={borderRadius}
      minWidth={minWidth}
      outline={outline}
      disabled={isDisabled}
      as={href ? 'a' : undefined}
      href={href}
      {...rest}
    >
      {formatMessage({ id: labelId })}
    </StyledButton>
  )
}

export { Intents as ButtonIntents }
export default Button
