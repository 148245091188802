export function replaceSpaces(string: string) {
  return string.replace(/ /g, '%20')
}

export function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export const copyToClipboard = (string: string) => {
  if (
    navigator &&
    navigator.clipboard &&
    navigator.clipboard.writeText
  )
    return navigator.clipboard.writeText(string)
  return Promise.reject('The Clipboard API is not available.')
}
