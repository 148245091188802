import { useLazyQuery } from '@apollo/client'
import { useState } from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components'

import BackgroundItem from 'components/BackgroundItem'
import Flex from 'components/Flex'
import Select from 'components/Select'
import { Skeleton } from 'components/Skeleton'
import { GET_LIBRARY_PAGE_BACKGROUND_XML } from 'data/layoutcreator/queries/themes'
import { useBackgroundsLibrary } from 'hooks/useBackgroundsLibrary'
import ChiliVariables, {
  VariableTypes
} from 'screens/YearbookEditor/components/Tools/services/ChiliVariables'

const variablesApi = ChiliVariables.getInstance()

const Container = styled(Flex)`
  flex-direction: column;
  width: 100%;
  padding-top: 20px;
`

const ItemsContainer = styled.div`
  margin-top: 16px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 8px;
`

const ChangeBackgroundPanelLibraryTab = () => {
  const { formatMessage } = useIntl()
  const [isSelectedLoading, setIsSelectedLoading] = useState(false)

  const {
    categories,
    selectedCategoryIndex,
    handleCategoryChange,
    images,
    isLoading,
    setSelectedImage,
    selectedImage
  } = useBackgroundsLibrary({
    hideBackground: false,
    onSelectedBackgroundChange: async (_: any) => {
      if (!selectedImage) {
        return
      }

      setIsSelectedLoading(true)
      const { data } = await getBackgroundXml({
        variables: {
          id: selectedImage?.id || '',
          category: selectedImage?.category || ''
        }
      })

      await variablesApi.setVariableValue(
        'background_image',
        data?.getLibraryPageBackgroundXML || '',
        { type: VariableTypes.chiliImage }
      )

      setIsSelectedLoading(false)
    }
  })

  const [getBackgroundXml] = useLazyQuery(
    GET_LIBRARY_PAGE_BACKGROUND_XML
  )

  return (
    <Container>
      <Select
        label={formatMessage({
          id: `Project.settings.templates.backgrounds.modal.tabs.library.categories.label`
        })}
        options={categories}
        activeIndex={selectedCategoryIndex}
        onChange={handleCategoryChange}
      />

      <ItemsContainer>
        {isLoading
          ? new Array(3 * 10)
              .fill(null)
              .map((_, i) => (
                <Skeleton key={i} width={78} height={103} />
              ))
          : images?.map((image, i) => (
              <BackgroundItem
                key={i}
                width={76}
                height={103}
                onClick={() =>
                  setSelectedImage(
                    selectedImage?.id === image.id
                      ? null
                      : {
                          id: image.id,
                          category: image.category
                        }
                  )
                }
                src={new URL(
                  image.thumbPath,
                  process.env.REACT_APP_REST_URL
                ).toString()}
                isLoading={
                  selectedImage?.id === image.id && isSelectedLoading
                }
                isSelected={selectedImage?.id === image.id}
              />
            ))}
      </ItemsContainer>
    </Container>
  )
}

export default ChangeBackgroundPanelLibraryTab
