import { useMutation } from '@apollo/client'
import { add } from 'date-fns'
import { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { useIntl } from 'react-intl'
import { useLocation } from 'react-router-dom'

import useAuth from 'data/gradoo/hooks/useAuth'
import { ADD_NEW_CHILI_IMAGE } from 'data/layoutcreator/mutations/images'
import { fetchImageFile } from 'helpers/file'
import ChiliDocument from 'screens/YearbookEditor/components/Tools/services/ChiliDocument'
import ChiliFrames from 'screens/YearbookEditor/components/Tools/services/ChiliFrames'
import ChiliImages from 'screens/YearbookEditor/components/Tools/services/ChiliImages'
import ChiliText from 'screens/YearbookEditor/components/Tools/services/ChiliText'
import { DocumentEvents } from 'screens/YearbookEditor/components/Tools/services/types'
import { FrameTypes } from 'screens/YearbookEditor/components/Tools/types'
import {
  Coordinates,
  Millimeters,
  Percent,
  Pixels,
  Size
} from 'types/global'
import { ProfilePageCommentFragment } from 'types/gradoo/graphql'

import { ProfileQuestionWithAnswer } from '../types'

const framesApi = ChiliFrames.getInstance()
const textApi = ChiliText.getInstance()
const documentApi = ChiliDocument.getInstance()
const imagesApi = ChiliImages.getInstance()

const DEFAULT_FONT_SIZE: Millimeters = 11

export const useCreateEditorContent = () => {
  const { formatMessage } = useIntl()
  const { pathname } = useLocation()
  const projectId = pathname.split('/')[1]
  const { authGroupId: groupId } = useAuth()

  const [pageSize, setPageSize] = useState<Size<Millimeters> | null>(
    null
  )
  const [pagePixelSize, setPagePixelSize] =
    useState<Size<Pixels> | null>(null)

  const [addNewChiliImage] = useMutation(ADD_NEW_CHILI_IMAGE)

  const getPageSize = async () => {
    if (pageSize) {
      return pageSize
    }

    const size = await documentApi.getPageSize()
    setPageSize(size)

    return size
  }

  const addImageFrame = async ({
    url,
    dropPosition,
    size
  }: {
    url: string
    dropPosition: Coordinates<Millimeters>
    size?: Partial<Size<Millimeters>>
  }) => {
    try {
      const loadingToastId = toast.loading(
        formatMessage({ id: 'Toasts.images.uploading' })
      )

      const imageFile = await fetchImageFile(url)

      const { data } = await addNewChiliImage({
        variables: {
          projectId,
          groupId,
          image: imageFile
        }
      })

      await imagesApi
        .createNewFrame(data.addNewChiliImage, {
          position: dropPosition,
          ...size
        })
        .then(() => {
          toast.remove(loadingToastId)
        })
    } catch {
      toast.dismiss()
      toast.error(
        formatMessage({
          id: 'Toasts.images.uploading.error'
        })
      )
    }
  }

  const addTextFrame = async ({
    text,
    dropPosition,
    size,
    fontSize
  }: {
    text: string
    dropPosition: Coordinates<Millimeters>
    size?: Partial<Size<Millimeters>>
    fontSize?: number
  }) => {
    const newFrame = await framesApi.createNewFrame({
      type: FrameTypes.text,
      position: dropPosition,
      ...size
    })

    await textApi.setText(text, newFrame.javaScriptDescriptor)
    await textApi.setFontSize(fontSize || DEFAULT_FONT_SIZE)
  }

  useEffect(() => {
    documentApi.on(DocumentEvents.zoomChanged, async () => {
      setPagePixelSize(await documentApi.getPagePixelSize())
    })
  }, [])

  const transformPixelsToMillimeters = (
    number: Pixels
  ): Millimeters | null => {
    if (!pagePixelSize || !pageSize) {
      return null
    }

    const proportion = pagePixelSize.width / pageSize.width

    return number / proportion
  }

  const getDropPosition = async (
    percentPosition: Coordinates<Percent>
  ) => {
    const size = await getPageSize()

    return {
      x: (size.width / 100) * percentPosition.x,
      y: (size.height / 100) * percentPosition.y
    }
  }

  const addQuote = async ({
    text,
    position: percentPosition,
    width
  }: {
    text: string
    position: Coordinates<Percent>
    width?: Pixels
  }) => {
    await addTextFrame({
      text,
      dropPosition: await getDropPosition(percentPosition),
      size: {
        width: width
          ? transformPixelsToMillimeters(width) || undefined
          : undefined
      }
    })
  }

  const addImage = async ({
    position: percentPosition,
    url
  }: {
    url: string
    position: Coordinates<Percent>
  }) => {
    await addImageFrame({
      url,
      dropPosition: await getDropPosition(percentPosition)
    })
  }

  const addProfileQuestion = async ({
    position: percentPosition,
    question
  }: {
    position: Coordinates<Percent>
    question: ProfileQuestionWithAnswer
  }) => {
    const QUESTION_HEIGHT: Millimeters = 5
    const ANSWER_HEIGHT: Millimeters = 10
    const QUESTION_FONT_SIZE = DEFAULT_FONT_SIZE
    const ANSWER_FONT_SIZE = 13

    const dropPosition = await getDropPosition(percentPosition)

    await addTextFrame({
      text: question.question,
      dropPosition,
      size: { height: QUESTION_HEIGHT },
      fontSize: QUESTION_FONT_SIZE
    })

    if (question.answer === null) {
      return
    }

    await addTextFrame({
      text: question.answer,
      dropPosition: {
        x: dropPosition.x,
        y: dropPosition.y + QUESTION_HEIGHT
      },
      size: { height: ANSWER_HEIGHT },
      fontSize: ANSWER_FONT_SIZE
    })
  }

  const addProfileComment = async ({
    position: percentPosition,
    comment
  }: {
    position: Coordinates<Percent>
    comment: ProfilePageCommentFragment
  }) => {
    const COMMENT_HEIGHT = 40
    const COMMENT_FONT_SIZE = DEFAULT_FONT_SIZE
    const AUTHOR_MARGIN = 1
    const AUTHOR_NAME_MARGIN = 1
    const AUTHOR_AVATAR_SIZE = 7
    const AUTHOR_NAME_SIZE: Size<Millimeters> = {
      height: 5,
      width: 50
    }
    const AUTHOR_NAME_FONT_SIZE = DEFAULT_FONT_SIZE

    const dropPosition = await getDropPosition(percentPosition)

    await addTextFrame({
      text: comment.text,
      dropPosition: await getDropPosition(percentPosition),
      size: { height: COMMENT_HEIGHT },
      fontSize: COMMENT_FONT_SIZE
    })

    const avatarUrl =
      comment.comentatorUserGroup.avatar ||
      comment.comentatorUserGroup.defaultAvatar

    if (typeof avatarUrl === 'string') {
      await addImageFrame({
        url: avatarUrl,
        dropPosition: {
          x: dropPosition.x,
          y: dropPosition.y + AUTHOR_MARGIN + COMMENT_HEIGHT
        },
        size: {
          width: AUTHOR_AVATAR_SIZE,
          height: AUTHOR_AVATAR_SIZE
        }
      })
    }

    const { firstName, lastName } = comment.comentatorUserGroup.user

    await addTextFrame({
      text: `${firstName} ${lastName}`,
      dropPosition: {
        x: dropPosition.x + AUTHOR_AVATAR_SIZE + AUTHOR_NAME_MARGIN,
        y: dropPosition.y + AUTHOR_MARGIN + COMMENT_HEIGHT
      },
      size: AUTHOR_NAME_SIZE,
      fontSize: AUTHOR_NAME_FONT_SIZE
    })
  }

  const addProfile = async ({
    position: percentPosition,
    profile
  }: {
    position: Coordinates<Percent>
    profile: any
  }) => {
    const AVATAR_SIZE: Size<Millimeters> = {
      height: 20,
      width: 20
    }
    const USER_NAME_SIZE: Size<Millimeters> = {
      height: 10,
      width: 50
    }
    const USER_NAME_FONT_SIZE = 16
    const GAP = 1

    const { user, avatar } = profile.userGroup

    const dropPosition = await getDropPosition(percentPosition)

    await addImageFrame({
      url: avatar,
      dropPosition,
      size: AVATAR_SIZE
    })

    await addTextFrame({
      text: `${user.firstName} ${user.lastName}`,
      dropPosition: {
        x: dropPosition.x + AVATAR_SIZE.width + GAP,
        y: dropPosition.y
      },
      size: USER_NAME_SIZE,
      fontSize: USER_NAME_FONT_SIZE
    })
  }

  return {
    addImage,
    addQuote,
    addProfile,
    addProfileQuestion,
    addProfileComment
  }
}
