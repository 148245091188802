import { useMemo, useState } from 'react'
import styled from 'styled-components'

import useClickOutside from 'shared/hooks/useClickOutside'

const Container = styled.div<{ ref: any }>`
  position: relative;
`

const Activator = styled.div<{ width?: number }>`
  display: flex;
  justify-content: space-between;
  width: ${({ width }) => (width ? `${width}px` : '100%')};
  cursor: pointer;
  border-bottom: 1px solid ${({ theme }) => theme.color.base.c3};
  padding-bottom: 10px;
`

const Label = styled.div`
  font-size: 12px;
  font-weight: 600;
  color: ${({ theme }) => theme.color.base.c6};
`

const Value = styled.div`
  font-size: 12px;
  color: ${({ theme }) => theme.color.brand_02};
`

const Dropdown = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.color.base.c7};
  background: ${({ theme }) => theme.color.base.c0};
  border: 1px solid ${({ theme }) => theme.color.base.c1};
  left: 0;
  right: 0;
  border-radius: 5px;
  padding: 4px 0;
  max-height: 200px;
  overflow: auto;
  z-index: 9999;
`

const Option = styled.div`
  cursor: pointer;
  padding: 8px 8px;
  font-size: 14px;

  :hover {
    background: ${({ theme }) => theme.color.base.c1};
  }
`

type SelectProps = {
  width?: number
  options: string[]
  label: string
  activeIndex: number
  onChange: (newValue: string, index: number) => void
  isDisabled?: boolean
}

const Select: React.FC<SelectProps> = ({
  options,
  label,
  width,
  activeIndex,
  onChange,
  isDisabled
}) => {
  const [isOpen, setIsOpen] = useState(false)

  const toggleIsOpen = () => setIsOpen(!isDisabled ? !isOpen : isOpen)

  const value = options[activeIndex]

  const ref = useClickOutside(() => setIsOpen(false))

  const handleOptionSelect = (index: number) => {
    onChange(options[index], index)
    setIsOpen(false)
  }

  return (
    <Container ref={ref}>
      <Activator onClick={toggleIsOpen} width={width}>
        <Label>{label}</Label>
        <Value>{value}</Value>
      </Activator>

      <Dropdown style={{ display: isOpen ? 'flex' : 'none' }}>
        {options.map((option, i) => (
          <Option key={i} onClick={() => handleOptionSelect(i)}>
            {option}
          </Option>
        ))}
      </Dropdown>
    </Container>
  )
}

export default Select
