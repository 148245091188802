import { useQuery } from '@apollo/client'

import Flex from 'components/Flex'
import { Skeleton } from 'components/Skeleton'
import { PROFILE_PAGE_COMMENTS } from 'data/gradoo/queries/profile'
import {
  Draggable,
  useContentDnd
} from 'screens/YearbookEditor/providers/ContentDndProvider'
import { Ids } from 'screens/YearbookEditor/providers/ContentDndProvider/types'
import { ProfilePageCommentFragment } from 'types/gradoo/graphql'

import { TabProps } from '../../types'
import CommentItem from './components/CommentItem'

export const CommentDragOverlayContent = () => {
  const { draggingContent } = useContentDnd()

  return <CommentItem comment={draggingContent.profileComment} />
}

const CommentsTab: React.FC<TabProps> = ({
  moduleInstanceId,
  userGroupId
}) => {
  const { data: commentsData, loading: commentsLoading } = useQuery(
    PROFILE_PAGE_COMMENTS,
    {
      skip: !moduleInstanceId,
      variables: {
        moduleInstance: moduleInstanceId as string,
        profileUserGroup: userGroupId
      },
      context: { client: 'gradoo' }
    }
  )

  const { draggingContent } = useContentDnd()

  const comments: ProfilePageCommentFragment[] =
    (commentsData?.profilePageComments?.edges.map(
      edge => edge?.node
    ) as ProfilePageCommentFragment[]) || []

  if (commentsLoading) {
    return (
      <Flex direction="column" rowGap={28}>
        {new Array(5).fill(null).map((_, i) => (
          <div key={i}>
            <Skeleton key={i} height={60} marginBottom={20} />
            <Skeleton key={i} height={28} width={80} colorLevel={2} />
          </div>
        ))}
      </Flex>
    )
  }

  return (
    <Flex direction="column" rowGap={28}>
      {comments.map(comment => (
        <Draggable
          key={comment.id}
          id={comment.id}
          type={Ids.profileComment}
          onDragStart={() =>
            draggingContent.setProfileComment(comment)
          }
          onDragEnd={() => draggingContent.setProfileComment(null)}
        >
          <CommentItem comment={comment} />
        </Draggable>
      ))}
    </Flex>
  )
}

export default CommentsTab
