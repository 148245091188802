import {
  ChangeEventHandler,
  Dispatch,
  FC,
  SetStateAction
} from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components'

import Flex from 'components/Flex'

import { CountryIDs } from '../../index'
import checkboxChecked from './images/check-box-checked.svg'
import checkboxUnchecked from './images/check-box-unchecked.svg'

const LabelBox = styled.label`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 340px;
  padding: 16px;
  box-shadow: 2px 3px 2px 0 rgba(248, 249, 250, 0.8);
  border: 1px solid ${({ theme }) => theme.color.base.c2};
  border-radius: 5px;
  cursor: pointer;
`

const FlagContainer = styled(Flex).attrs({
  alignItems: 'center',
  justifyContent: 'center',
  width: 56,
  height: 56
})`
  background-color: ${({ theme }) => theme.color.base.c2};
  border-radius: 5px;
`

export const Flag = styled.img`
  width: 24px;
  height: 15px;
`

const CheckboxImg = styled.div`
  width: 24px;
  height: 24px;
  background: url(${checkboxUnchecked});
`

const HiddenRadio = styled.input.attrs({ type: 'radio' })`
  display: none;

  &:checked + ${LabelBox} {
    border-color: ${({ theme }) => theme.color.brand_02};
    background-color: ${({ theme }) => theme.color.base.c2};
  }

  &:checked + ${LabelBox} ${CheckboxImg} {
    background-image: url(${checkboxChecked});
  }
`

type CountryInputProps = {
  id: CountryIDs
  icon: string
  hasError: boolean
  setCountry: Dispatch<SetStateAction<CountryIDs>>
  isSelected: boolean
}

const CountryInput: FC<CountryInputProps> = ({
  id,
  icon,
  setCountry,
  isSelected
}) => {
  const { formatMessage } = useIntl()
  const handleChange: ChangeEventHandler<HTMLInputElement> = e =>
    setCountry(e.target.id as CountryIDs)
  return (
    <>
      <HiddenRadio
        name="country"
        id={id}
        value={id}
        onChange={handleChange}
        checked={isSelected}
      />
      <LabelBox key={id} htmlFor={id}>
        <Flex columnGap={16} alignItems="center">
          <FlagContainer>
            <Flag src={icon} alt={id} />
          </FlagContainer>
          {formatMessage({
            id: `Project.select.country.${id}`
          })}
        </Flex>
        <CheckboxImg />
      </LabelBox>
    </>
  )
}

export default CountryInput
