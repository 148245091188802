import styled from 'styled-components'

import FadeOut from 'shared/ui/FadeOut'
import { Skeleton } from 'shared/ui/Skeleton'

const Container = styled(FadeOut)`
  background: #f8fafc;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  padding: 18px 0 14px;
  align-items: center;
  justify-content: center;
`

const SkeletonWrapper = styled.div`
  aspect-ratio: 1 / 1.41;
  height: 100%;
  width: 557px;
  height: 736px;
  margin-top: 2%;
  @media (max-width: 1440px) {
    width: 557px;
    margin-top: -1.5%;
  }
  @media (max-width: 1194px) {
    width: 395px;
    height: 552px;
    margin-top: -3%;
  }
  @media (max-width: 1000px) {
    width: 395px;

    margin-left: 40%;
    margin-top: 0;
  }

  @media (max-width: 750px) {
    width: 390px;
    height: 545px;
    margin-top: -15%;
    margin-right: 0;
  }
`

type YearbookEditorSkeletonProps = {
  isShown: boolean
}

const YearbookEditorSkeleton: React.FC<
  YearbookEditorSkeletonProps
> = ({ isShown }) => {
  return (
    <Container isShown={isShown} duration={0.3}>
      <SkeletonWrapper>
        <Skeleton animationDirection={'vertical'} colorLevel={2} />
      </SkeletonWrapper>
    </Container>
  )
}

export default YearbookEditorSkeleton
