import { useNavigate } from 'react-router-dom'

import { ReactComponent as Logo } from 'shared/assets/icons/logo-admin.svg'
import Button from 'shared/ui/Button_2'
import Flex from 'shared/ui/Flex'
import { logout } from 'shared/utils/auth'

import HeaderGoBackButton from 'shared/ui/HeaderGoBackButton'

const TabsHeader = () => {
  const navigate = useNavigate()

  const handleGoBack = () => {
    navigate(-1)
  }

  return (
    <Flex justifyContent="space-between" alignItems="center">
      <Flex columnGap={16}>
        <HeaderGoBackButton onBack={handleGoBack} />
        <Logo />
      </Flex>

      <Button
        intent="secondary-contrast"
        size="sm"
        textId="Log out from admin"
        onPress={logout}
      />
    </Flex>
  )
}

export default TabsHeader
