import { useIntl } from 'react-intl'
import styled, { css } from 'styled-components'

import CMYK from 'shared/entities/CMYK'
import { ColorCMYK } from 'shared/types/global'

const Grid = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 42px 1fr;
  grid-template-rows: 48px auto;
  grid-gap: 8px 0;
`

const Color = styled.div<{ value?: string; isActive?: boolean }>`
  background-color: ${({ value }) => value};
  border-radius: 5px;
  cursor: pointer;
  width: 22px;
  height: 22px;

  ${({ isActive, theme, value }) =>
    isActive &&
    css`
      box-shadow: 0px 0px 0px 2px ${theme.color.base.c0},
        0px 0px 0px 4px ${value};
    `}
`

const OtherColors = styled.div`
  width: 100%;
  justify-content: end;
  display: grid;
  grid-template-columns: repeat(4, 27px);
  grid-template-rows: repeat(2, 27px);
  margin-left: 10px;
`

const CurrentColor = styled(Color)`
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.05);
  width: 48px;
  height: 48px;
`

const Label = styled.span`
  width: 100%;
  text-align: center;
  font-size: 10px;
  color: ${({ theme }) => theme.color.base.c6};
`

const otherColors = [
  new CMYK({ c: 0, m: 100, y: 100, k: 37 }),
  new CMYK({ c: 64, m: 37, y: 0, k: 37 }),
  new CMYK({ c: 53, m: 72, y: 0, k: 8 }),
  new CMYK({ c: 100, m: 12, y: 50, k: 23 }),

  new CMYK({ c: 0, m: 45, y: 76, k: 0 }),
  new CMYK({ c: 0, m: 0, y: 0, k: 90 }),
  new CMYK({ c: 0, m: 26, y: 100, k: 0 }),
  new CMYK({ c: 100, m: 0, y: 7, k: 28 })
]

type ColorsSetProps = {
  labelId: string
  currentColor: CMYK
  onCurrentClick: (color: ColorCMYK) => void
  onOtherClick: (color: ColorCMYK) => void
}

const ToolsLeftPanelColorsSet: React.FC<ColorsSetProps> = ({
  labelId,
  currentColor,
  onCurrentClick,
  onOtherClick
}) => {
  const { formatMessage } = useIntl()

  return (
    <Grid>
      <CurrentColor
        style={{ background: currentColor.toCSS() }}
        onClick={() => onCurrentClick(currentColor)}
      />

      <OtherColors>
        {otherColors.map((color, i) => (
          <Color
            key={i}
            value={color.toCSS()}
            isActive={color.isEqual(currentColor)}
            onClick={() => onOtherClick(color)}
          />
        ))}
      </OtherColors>

      <Label>{formatMessage({ id: labelId })}</Label>
    </Grid>
  )
}

export default ToolsLeftPanelColorsSet
