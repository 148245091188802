import styled from 'styled-components'

import Flex from 'components/Flex'

export const Container = styled(Flex).attrs({
  columnGap: 8
})<{ withBorder?: boolean }>`
  padding: 12px 0px;
  align-items: center;
  border-bottom: ${({ withBorder }) => (withBorder ? 1 : 0)}px solid
    ${({ theme }) => theme.color.base.c3};
  cursor: pointer;
`

export const Center = styled(Flex).attrs({
  direction: 'column',
  flex: 1
})`
  margin-left: 12px;
`

export const Name = styled.span`
  margin-bottom: 6px;
  font-size: 14px;
  font-weight: 600;
`

export const Counts = styled.span`
  margin-top: 6px;
  font-size: 10px;
  color: ${({ theme }) => theme.color.base.c5};
`

export const ArrowPlaceholder = styled.div`
  width: 28px;
`
