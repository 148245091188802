import styled from 'styled-components'

import FadeOut from 'shared/ui/FadeOut'

const LoadingBar = styled(FadeOut)`
  position: absolute;
  transition: 0.2s cubic-bezier(0.83, 0, 0.17, 1);
  height: 4px;
  top: 3px;
  left: 0;
  border-radius: 0 2px 2px 0;
  background: ${({ theme }) => theme.color.brand_02};
  z-index: 2;
`

type EditorLoadingBarProps = {
  loadingPercent: number
}

const ScreenContentLoadingBar: React.FC<EditorLoadingBarProps> = ({
  loadingPercent
}) => {
  return (
    <LoadingBar
      isShown={loadingPercent > 0 && loadingPercent < 100}
      style={{
        width: `${loadingPercent}%`
      }}
    />
  )
}

export default ScreenContentLoadingBar
