import { GradooTheme } from 'gradoo-theme/lib/types'
import { useIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import styled, { useTheme } from 'styled-components'

import Icon from 'shared/ui/IcomoonIcon'

export const ToolsContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 16px;
`

type ContainerProps = {
  isActive?: boolean
  isDisabled?: boolean
}
const Container = styled.div<ContainerProps>`
  color: ${({ theme, isActive }) =>
    isActive ? theme.color.base.c9 : theme.color.base.c6};
  opacity: ${({ isDisabled }) => (isDisabled ? '.5' : '1')};
`

const StyledLink = styled(Link)`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 10px;
  font-weight: 600;
  text-decoration: none;
  color: inherit;
`

const Title = styled.div<{ color: string }>`
  color: ${({ color }) => color};
  margin-top: 4px;
`

type ToolProps = {
  to: string
  icon: string
  title: string
  param?: string
  colors: {
    active: (theme: GradooTheme) => string
    default: (theme: GradooTheme) => string
  }
} & ContainerProps

const Tool: React.FC<ToolProps> = ({
  to,
  icon,
  title,
  isDisabled,
  param,
  isActive = false,
  colors
}) => {
  const { formatMessage } = useIntl()
  const theme = useTheme()

  const color = isActive
    ? colors.active(theme)
    : colors.default(theme)

  return (
    <Container isActive={isActive} isDisabled={isDisabled}>
      <StyledLink
        to={param ? to.replace(':projectId', param) : to}
        onClick={e => {
          if (isDisabled) {
            e.preventDefault()
          }
        }}
      >
      </StyledLink>
    </Container>
  )
}

export default Tool
