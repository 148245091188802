import { useMutation, useQuery } from '@apollo/client'
import { useEffect } from 'react'
import toast from 'react-hot-toast'
import { useIntl } from 'react-intl'
import { useNavigate, useParams } from 'react-router-dom'

import { YEARBOOK_PAGES_TABS } from 'data/constants'
import useAuth from 'data/gradoo/hooks/useAuth'
import { GET_PROJECT_COVER_DOCUMENTS } from 'data/layoutcreator/queries/documents'
import { GET_PROJECT_META } from 'data/layoutcreator/queries/projects'
import Head from 'shared/components/Head'
import Tools from 'shared/components/PageListTools'
import { useLiveUsers } from 'shared/hooks/useLiveUsers'
import DefaultLayout from 'shared/layout/Default'
import Flex from 'shared/ui/Flex'

import { COPY_COVER_TEMPLATES } from '../../data/layoutcreator/mutations/projects'
import { CoverTypes } from '../Home/components/NewProjectModal/components/CoverType'
import { HEADER_TOOLS } from '../YearbookPages'
import LiveCursorWrapper from '../YearbookPages/components/LiveCursorWrapper'
import PagePreviewModal from '../YearbookPages/components/PagePreviewModal'
import CoverPage from './components/CoverPage'
import EmptyInside from './components/EmptyInside'

export default function YearbookCover() {
  const navigate = useNavigate()
  const liveUsers = useLiveUsers()
  const { authGroupId } = useAuth()
  const { projectId } = useParams<{
    projectId: string
  }>()
  const { formatMessage } = useIntl()

  const { data: projectMetaData, loading: loadingProjectMeta } =
    useQuery(GET_PROJECT_META, {
      variables: {
        groupId: authGroupId || '',
        projectId: projectId || ''
      },
      skip: !authGroupId || !projectId
    })

  const { data, loading: loadingCoverDocuments } = useQuery(
    GET_PROJECT_COVER_DOCUMENTS,
    {
      variables: {
        groupId: authGroupId || '',
        projectId: projectId || ''
      },
      skip: !authGroupId || !projectId || !projectMetaData
    }
  )

  const isHardCoverType =
    !loadingProjectMeta &&
    !loadingCoverDocuments &&
    projectMetaData?.getProjectMeta.coverType === CoverTypes.hard

  const [copyCoverTemplates] = useMutation(COPY_COVER_TEMPLATES)

  useEffect(() => {
    if (
      authGroupId &&
      projectId &&
      Array.isArray(data?.findProjectCoverDocuments) &&
      data?.findProjectCoverDocuments.length === 0
    ) {
      toast.promise(
        copyCoverTemplates({
          variables: {
            groupId: authGroupId,
            projectName: projectMetaData?.getProjectMeta?.name || ''
          },
          refetchQueries: [
            {
              query: GET_PROJECT_COVER_DOCUMENTS,
              variables: {
                groupId: authGroupId,
                projectId: projectId
              }
            }
          ]
        }),
        {
          loading: formatMessage({
            id: 'Cover.copying.loading'
          }),
          success: formatMessage({
            id: 'Cover.copying.success'
          }),
          error: formatMessage({
            id: 'Cover.copying.error'
          })
        }
      )
    }
  }, [
    data?.findProjectCoverDocuments,
    projectMetaData?.getProjectMeta?.name,
    authGroupId,
    projectId
  ])

  return (
    <>
      <DefaultLayout
        liveUsers={liveUsers}
        RightComponent={<Tools isDisabled tools={HEADER_TOOLS} settingsPath={`/${projectId}/settings`} />}
        onBack={() => navigate('/')}
        tabs={YEARBOOK_PAGES_TABS}
      >
        <LiveCursorWrapper>
          <Head titleId="Page.editor.title" />
          <Flex
            columnGap={32}
            marginLeft={48}
            marginRight={48}
            marginBottom={48}
            marginTop={134}
          >
            {data?.findProjectCoverDocuments
              .filter(doc => {
                return !(
                  projectMetaData?.getProjectMeta.coverType ===
                    CoverTypes.hard && doc.name.includes('inside')
                )
              })
              .map(document => (
                <CoverPage document={document} key={document.id} />
              ))}
            {isHardCoverType &&
            data?.findProjectCoverDocuments &&
            data?.findProjectCoverDocuments?.length > 0 ? (
              <EmptyInside />
            ) : null}
          </Flex>
        </LiveCursorWrapper>
      </DefaultLayout>
      {projectId && authGroupId ? (
        <PagePreviewModal
          isCoverPreview
          projectId={projectId}
          groupId={authGroupId}
        />
      ) : null}
    </>
  )
}
