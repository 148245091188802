import { Coordinates, Millimeters } from 'shared/types/global'

import { Frame, FrameTypes } from '../types'
import BasicService from './BasicService'

export type CreateNewFrameOptions = {
  type: FrameTypes
  width?: Millimeters
  height?: Millimeters
  position?: Coordinates<Millimeters>
}

export default class ChiliFrames extends BasicService {
  private static instance: ChiliFrames

  static getInstance(): ChiliFrames {
    if (!ChiliFrames.instance) {
      ChiliFrames.instance = new ChiliFrames()
    }

    return ChiliFrames.instance
  }

  async createNewFrame(
    options: CreateNewFrameOptions
  ): Promise<Frame> {
    const newFrame = (await this.publisherConnector?.executeFunction(
      'document.pages[1].frames',
      'Add',
      options.type,
      `${options.position?.x || 0}mm`,
      `${options.position?.y || 0}mm`,
      `${options.width || 100}mm`,
      `${options.height || 100}mm`
    )) as Frame

    return newFrame
  }
}
