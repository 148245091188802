import { useMemo } from 'react'

const useTextSearch = <T>(
  array: T[],
  pattern: string,
  targetField: keyof T
) => {
  const result = useMemo(() => {
    if (pattern.trim() === '') {
      return array
    }

    return array.filter((item: T) =>
      (item[targetField] as unknown as string)
        .toLowerCase()
        .includes(pattern.toLowerCase())
    )
  }, [array, pattern, targetField])

  return result
}

export default useTextSearch
