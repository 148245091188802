import { useDroppable } from '@dnd-kit/core'
import { useEffect, useLayoutEffect, useRef, useState } from 'react'
import styled from 'styled-components'

import { useContentDnd } from 'screens/YearbookEditor/providers/ContentDndProvider'

import { Ids } from '../../types'
import ChiliDocument from 'shared/components/Tools/services/ChiliDocument'
import { BasicEventTypes } from 'shared/components/Tools/services/BasicService'
import { Coordinates, Pixels, Size } from 'shared/types/global'
import { DocumentEvents } from 'shared/components/Tools/services/types'

const documentApi = ChiliDocument.getInstance();

type ContainerProps = {
  pageSize: Size<Pixels> | null
  pageMargin: Coordinates<Pixels> | null
}

const Container = styled.div<ContainerProps>`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  padding: ${({ pageMargin }) => pageMargin && `18px 0px 0px ${pageMargin.x}px`};

  @media only screen and (max-width: ${({ pageSize, pageMargin }) => pageSize && pageMargin && `${pageSize.width + pageMargin.x * 2}px` }) {
    padding: ${({ pageMargin }) => pageMargin && `18px ${pageMargin.x}px 0px ${pageMargin.x}px`};
    display: flex;
    justify-content: flex-end;
  }
`

const Wrapper = styled.div`
  width: fit-content;
`

type PageSimulationProps = {
  pageSize: Size<Pixels> | null
  ref: any
}

const PageSimulation = styled.div<PageSimulationProps>`
  height: ${({ pageSize }) =>
    pageSize ? `${pageSize.height}px` : '100%'};
  width: ${({ pageSize }) =>
    pageSize ? `${pageSize.width}px` : '100%'};
  background: ${({ theme }) => theme.color.brand_02};
  opacity: 0.3;
`

const EditorSimulation: React.FC = () => {
  const [pageSize, setPageSize] = useState<Size<Pixels> | null>(null)
  const [pageMargin, setPageMargin] = useState<Coordinates<Pixels> | null>(null)

  useEffect(() => {
    documentApi.on(DocumentEvents.zoomChanged, async () => {
      const pageSize = await documentApi.getPagePixelSize();
      setPageSize(pageSize)
    })
  }, [documentApi.isInitialized])

  useEffect(() => {
    const listener = documentApi.addListener(BasicEventTypes.initialized, async () => {
      const pageMargin = await documentApi.getPageMargin()
      setPageMargin(pageMargin)
    })

    return () => {
      documentApi.removeListener(listener)
    }
  }, [])

  useLayoutEffect(() => {
    const handleWindowResize = () => {
      if (!containerRef.current) {
        return
      }
    }

    window.addEventListener('resize', handleWindowResize)

    return () => {
      window.removeEventListener('resize', handleWindowResize)
    }
  }, [])

  const { droppableRef, isDragging } = useContentDnd()
  const containerRef = useRef<HTMLDivElement>(null)

  const { setNodeRef } = useDroppable({
    id: Ids.editor,
    data: {
      accepts: [Ids.quote]
    }
  })

  if (!isDragging) {
    return <></>
  }

  return (
    <Container
      ref={containerRef}
      pageSize={pageSize}
      pageMargin={pageMargin}
    >
      <Wrapper ref={setNodeRef}>
        <PageSimulation
          ref={droppableRef}
          pageSize={pageSize}
        />
      </Wrapper>
    </Container>
  )
}

export default EditorSimulation
