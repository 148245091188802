import { gql } from 'shared/types/gradoo'

export const COLLAGES_ALBUM = gql(/* GraphQL */ `
  fragment CollagesAlbum on CollagesAlbumNode {
    id
    name
    photosCount
    collagesPhotos(first: $cpFirst) {
      edges {
        node {
          ...CollagesPhoto
        }
      }
    }
  }
`)

export const COLLAGES_ALBUMS = gql(/* GraphQL */ `
  query CollagesAlbums(
    $after: String
    $first: Int
    $moduleInstance: ID
    $cpFirst: Int
  ) {
    collagesAlbums(
      after: $after
      first: $first
      moduleInstance: $moduleInstance
    ) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          ...CollagesAlbum
        }
      }
    }
  }
`)

export const COLLAGES_PHOTOS = gql(/* GraphQL */ `
  query CollagesPhotos(
    $after: String
    $first: Int
    $collagesAlbum: ID
  ) {
    collagesPhotos(
      after: $after
      first: $first
      collagesAlbum: $collagesAlbum
    ) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          ...CollagesPhoto
        }
      }
    }
  }
`)
