import { useIntl } from 'react-intl'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import styled from 'styled-components'

import config from 'config'
import { ReactComponent as DesktopImage } from 'shared/assets/images/desktop.svg'
import Flex from 'shared/ui/Flex'
import {
  PageRoomProvider,
  useOthersMapped,
  useSelf
} from 'shared/context/project-room'
import DefaultLayout from 'shared/layout/Default'
import { LiveUser, ReactChildren } from 'shared/types/global'

const ContentWrapper = styled.div`
  height: 100%;
  display: none;

  @media only screen and (min-width: ${config.maintainableScreenWidthBreakpoint}px) {
    display: block;
  }
`

const MobileScreenWrapper = styled.div`
  height: 100%;
  display: none;

  @media only screen and (max-width: ${config.maintainableScreenWidthBreakpoint}px) {
    display: block;
  }
`

const BLOCK_SCREEN_BREAKPOINTS = {
  sm: 750
}

const MobileScreenContainer = styled(Flex)`
  flex-direction: column;

  @media only screen and (min-width: ${BLOCK_SCREEN_BREAKPOINTS.sm}px) {
    flex-direction: row;
  }

  margin: 169px 24px 0 24px;
  justify-content: left;
  align-items: left;
  row-gap: 32px;
`

const ImageContainer = styled.div`
  height: 70px;
`

const MobileHeading = styled.h1`
  margin: 0;
  margin-left: 0;
  font-size: 36px;
  font-weight: 600;
  font-family: Roobert PRO, sans-serif;
  line-height: 48px;
  text-align: left;
  width: 392px;
`

const MobileDescription = styled.p`
  margin: 0;
  font-size: 14px;
  width: 392px;
  text-align: left;
  line-height: 20px;
`

const ScreenSizeContainer: React.FC<ReactChildren> = ({
  children
}) => {
  const { pathname } = useLocation()
  const [, encodedProjectId] = pathname.split('/')
  const projectId = decodeURIComponent(encodedProjectId) // Ensure this is the correct index based on your URL structure
  const { pageId } = useParams()
  const { formatMessage } = useIntl()
  const navigate = useNavigate()
  const currentUserInfo = useSelf(me => me.presence.info)
  const currentUserConnectionId = useSelf(me => me.connectionId)

  const othersMapped = useOthersMapped(other => ({
    info: other.presence.info,
    editingPageId: other.presence.editingPageId
  }))

  const liveUsers: LiveUser[] =
    currentUserInfo && currentUserConnectionId
      ? [
          {
            ...currentUserInfo,
            index: currentUserConnectionId
          },
          ...othersMapped
            .filter(([, { editingPageId }]) => !editingPageId)
            .map(([index, { info }]) => ({
              ...info,
              index
            }))
        ]
      : []
  return (
    <>
      <MobileScreenWrapper>
        <DefaultLayout
          onBack={() => navigate(-1)}
          liveUsers={liveUsers}
        >
          <MobileScreenContainer>
            <ImageContainer>
              <DesktopImage height="100%" />
            </ImageContainer>

            <Flex
              direction="column"
              rowGap={24}
              flex={1}
              alignItems="flex-start"
            >
              <MobileHeading>
                {formatMessage({ id: 'MobileScreen.title' })}
              </MobileHeading>

              <MobileDescription>
                {formatMessage({ id: 'MobileScreen.description' })}
              </MobileDescription>
            </Flex>
          </MobileScreenContainer>
        </DefaultLayout>
      </MobileScreenWrapper>

      <ContentWrapper>{children}</ContentWrapper>
    </>
  )
}

export default ({ children }: any) => {
  const { pathname } = useLocation()
  const [, encodedProjectId] = pathname.split('/')
  const projectId = decodeURIComponent(encodedProjectId)
  const { pageId } = useParams()

  return (
    <PageRoomProvider projectId={projectId} pageId={pageId}>
      <ScreenSizeContainer>{children}</ScreenSizeContainer>
    </PageRoomProvider>
  )
}
