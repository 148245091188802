import { ApolloQueryResult, useQuery } from '@apollo/client'
import { format } from 'date-fns'
import { useEffect } from 'react'
import { useIntl } from 'react-intl'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'

import Flex from 'components/Flex'
import Spinner from 'components/Spinner'
import useAuth from 'data/gradoo/hooks/useAuth'
import { GET_PRODUCTION_EXPORTS } from 'data/layoutcreator/queries/production'
import { isExportLinkItemDisabledVar } from 'data/store/settings-export'
import { getDownloadPdfUrl } from 'screens/AdminProject/tabs/ExportTab/utils.'
import {
  GetProductionAttrsQuery,
  ProductionExportStatuses,
  ProductionExportType
} from 'types/layoutcreator/graphql'

import { Table, Th, Tr } from './components/Table'
import Tag from './components/Tag'

const TABLE_HEADERS = [
  'type',
  'pages',
  'export.date',
  'export.by',
  'status'
]

const Dash = styled.span`
  color: ${({ theme }) => theme.color.base.c9};
`

const DownloadLink = styled.a`
  color: ${({ theme }) => theme.color.brand_02};
`

const EXPORTS_POLL_INTERVAL = 10_000

type HistoryProps = {
  refetchProductionAttrs: () => Promise<
    ApolloQueryResult<GetProductionAttrsQuery>
  >
}
const History = ({ refetchProductionAttrs }: HistoryProps) => {
  const { formatMessage } = useIntl()
  const { pathname } = useLocation()
  const projectId = pathname.split('/')[1]
  const { authGroupId } = useAuth()

  const {
    data: productionExports,
    loading: loadingProjectExports,
    stopPolling,
    startPolling
  } = useQuery(GET_PRODUCTION_EXPORTS, {
    variables: {
      groupId: authGroupId!,
      projectId
    },
    skip: !authGroupId,
    pollInterval: EXPORTS_POLL_INTERVAL
  })

  useEffect(() => {
    startPolling(EXPORTS_POLL_INTERVAL)

    if (
      productionExports?.getProductionExports?.length &&
      productionExports?.getProductionExports[0]?.status !==
        ProductionExportStatuses.InProgress
    ) {
      stopPolling()
      refetchProductionAttrs().then(() =>
        isExportLinkItemDisabledVar(false)
      )
    }

    return () => {
      stopPolling()
    }
  }, [productionExports?.getProductionExports])

  return (
    <Table>
      <thead>
        <tr>
          {TABLE_HEADERS.map(id => (
            <Th key={id}>
              {formatMessage({
                id: `Project.export.history.th.${id}`
              })}
            </Th>
          ))}
          <Th>
            {loadingProjectExports ? (
              <Flex justifyContent="flex-end">
                <Spinner size={12} />
              </Flex>
            ) : null}
          </Th>
        </tr>
      </thead>
      <tbody>
        {productionExports?.getProductionExports.map(
          ({
            id,
            groupId,
            type,
            pages,
            startedAt,
            author,
            status
          }) => (
            <Tr key={id}>
              <td>
                <Tag
                  textI18nId={`Project.export.history.type.${type}`}
                  intent={type}
                />
              </td>
              <td>{pages}</td>
              <td>
                {format(new Date(startedAt), 'dd.MM.yyyy – HH:mm')}
              </td>
              <td>{author?.fullName}</td>
              <td>
                <Tag
                  textI18nId={`Project.export.history.status.${status}`}
                  intent={status}
                />
              </td>
              <td>
                {status === ProductionExportStatuses.Completed &&
                type === ProductionExportType.Preview ? (
                  <DownloadLink
                    href={getDownloadPdfUrl({
                      groupId,
                      projectId,
                      exportId: id
                    })}
                  >
                    {formatMessage({
                      id: 'Project.export.history.download'
                    })}
                  </DownloadLink>
                ) : (
                  <Dash>-</Dash>
                )}
              </td>
            </Tr>
          )
        )}
      </tbody>
    </Table>
  )
}

export default History
