import { Skeleton } from 'components/Skeleton'

const CollagePanelSkeleton: React.FC<{ count: number }> = ({
  count
}) => {
  return (
    <>
      {new Array(count).fill(null).map((_, i) => (
        <Skeleton
          key={i}
          width={58}
          height={58}
          radius={6}
          marginBottom={6}
        />
      ))}
    </>
  )
}

export default CollagePanelSkeleton
