import chroma from 'chroma-js'
import { useIntl } from 'react-intl'
import styled from 'styled-components'

import LiveAvatar from 'shared/ui/LiveAvatar'
import { LiveUser } from 'shared/types/global'

const ListContainer = styled.div`
  display: flex;
  align-items: center;
`

const ListText = styled.div`
  padding-right: 16px;
  width: 120px;
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  color: ${({ theme }) => theme.color.base.c9};
`

const GridContainer = styled.div`
  display: flex;
  column-gap: 8px;
  position: absolute;
  align-content: center;
  justify-content: center;
  flex-wrap: wrap;
  row-gap: 8px;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) =>
    chroma(theme.color.base.c9).alpha(0.9).css()};
`

const GridText = styled.div`
  padding: 8px 16px 0px 16px;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: ${({ theme }) => theme.color.base.c0};
`

type BlockingUserProps = {
  view: string
  editingLiveUser: LiveUser
}

const BlockingUserOverlay: React.FC<BlockingUserProps> = ({
  view,
  editingLiveUser
}) => {
  const { firstName } = editingLiveUser
  const { formatMessage } = useIntl()

  if (view === 'list') {
    return (
      <ListContainer>
        <ListText>
          {formatMessage({ id: 'Pages.page.editing' }, { firstName })}
        </ListText>
        <LiveAvatar liveUser={editingLiveUser} />
      </ListContainer>
    )
  }

  return (
    <GridContainer>
      <LiveAvatar liveUser={editingLiveUser} size={64} />
      <GridText>
        {formatMessage({ id: 'Pages.page.editing' }, { firstName })}
      </GridText>
    </GridContainer>
  )
}

export default BlockingUserOverlay
