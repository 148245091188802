import { ColorCMYK } from 'shared/types/global'

import RGB from './RGB'

export default class CMYK implements ColorCMYK {
  c!: number
  m!: number
  y!: number
  k!: number

  constructor({ c, m, y, k }: ColorCMYK) {
    this.c = c
    this.m = m
    this.y = y
    this.k = k
  }

  toRGB(): RGB {
    const c = this.c / 100
    const m = this.m / 100
    const y = this.y / 100
    const k = this.k / 100

    const r = 255 * (1 - c) * (1 - k)
    const g = 255 * (1 - m) * (1 - k)
    const b = 255 * (1 - y) * (1 - k)

    const roundedR = Math.round(r)
    const roundedG = Math.round(g)
    const roundedB = Math.round(b)

    return new RGB({ r: roundedR, g: roundedG, b: roundedB })
  }

  toCSS(): string {
    return this.toRGB().toCSS()
  }

  isEqual(to: CMYK): boolean {
    return (
      this.c === to.c &&
      this.m === to.m &&
      this.y === to.y &&
      this.k === to.k
    )
  }
}
