import React from 'react'
import { useState } from 'react'
import styled, { css } from 'styled-components'

import Flex from 'components/Flex'
import { blockInvalidChar } from 'helpers/input'

export enum NumbersInputStatuses {
  default = 'default',
  success = 'success',
  error = 'error'
}

const NUMBER_INPUT_STYLES_MAP = {
  [NumbersInputStatuses.default]: css`
    border: 1px solid ${({ theme }) => theme.color.base.c3};
    background: ${({ theme }) => theme.color.base.c0};
    color: ${({ theme }) => theme.color.base.c7};
  `,
  [NumbersInputStatuses.success]: css`
    border: 1px solid
      ${({ theme }) => theme.prevColor.functional.green2};
    background: ${({ theme }) => theme.prevColor.functional.green4};
    color: ${({ theme }) => theme.prevColor.functional.green3};
  `,
  [NumbersInputStatuses.error]: css`
    border: 1px solid ${({ theme }) => theme.prevColor.secondary.c5};
    background: ${({ theme }) => theme.prevColor.secondary.c0};
    color: ${({ theme }) => theme.prevColor.secondary.c7};
  `
}

type NumberInputProps = {
  status: NumbersInputStatuses
}

const NumberInput = styled.input.attrs({
  type: 'number'
})<NumberInputProps>`
  width: 48px;
  height: 60px;
  border-radius: 8px;
  text-align: center;
  font-weight: 600;
  font-size: 24px;
  box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.05);

  ${({ status }) => NUMBER_INPUT_STYLES_MAP[status]}

  transition: .1s;
`

type NumbersInputProps = {
  count: number
  onChange: (newValue: string) => void
  status?: NumbersInputStatuses
  gap?: number
  disabled?: boolean
}

const NumbersInput: React.FC<NumbersInputProps> = ({
  count,
  onChange,
  status = NumbersInputStatuses.default,
  gap = 8,
  disabled = false
}) => {
  const [numbers, setNumbers] = useState<number[]>(
    Array(count).fill(-1)
  )

  const moveFocusTo = (index: number) => {
    refs[index]?.current?.focus()
  }

  const handleNumberChange = (index: number, newValue: string) => {
    let internalIndex = index
    let internalNewValue = newValue

    // for copy-paste case
    if (newValue.length === count) {
      const newNumbers = newValue.split('').map(Number)
      setNumbers(newNumbers)
      refs[index + 1]?.current?.focus()
      return onChange(newNumbers.join('').replaceAll('-1', '-'))
    }

    if (newValue.length > 1) {
      if (index === count - 1) {
        return
      }

      internalIndex++
      internalNewValue = newValue[newValue.length - 1]
    }

    const newNumbers = [...numbers]
    newNumbers[internalIndex] = newValue
      ? Number(internalNewValue)
      : -1
    setNumbers(newNumbers)

    onChange(newNumbers.join('').replaceAll('-1', '-'))

    if (!newValue) {
      moveFocusTo(index - 1)
    } else {
      moveFocusTo(index + 1)
    }
  }

  const handleKeyDown = (
    e: React.KeyboardEvent<HTMLInputElement>,
    index: number
  ) => {
    blockInvalidChar(e)

    if (numbers[index] === -1 && e.key === 'Backspace') {
      moveFocusTo(index - 1)
    }
  }

  const refs = Array.from({ length: count }).map(() =>
    React.createRef<HTMLInputElement>()
  )

  return (
    <Flex columnGap={gap}>
      {Array.from({ length: count }).map((_, index) => (
        <NumberInput
          key={index}
          ref={refs[index]}
          status={status}
          value={numbers[index] === -1 ? '' : numbers[index]}
          onChange={e => handleNumberChange(index, e.target.value)}
          onKeyDown={e => handleKeyDown(e, index)}
          disabled={disabled}
        />
      ))}
    </Flex>
  )
}

export default NumbersInput
