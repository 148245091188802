import { useIntl } from 'react-intl'
import styled from 'styled-components'

const Container = styled.header<{
  marginBottom?: number
  marginTop?: number
}>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${({ marginBottom }) =>
    marginBottom ? `${marginBottom}px` : '0px'};
  margin-top: ${({ marginTop }) =>
    marginTop ? `${marginTop}px` : '0px'};
`

const Title = styled.h2`
  font-weight: 600;
  font-size: 28px;
`

type SectionHeaderProps = {
  titleId: string
  renderAppend?: () => JSX.Element
  marginBottom?: number
  marginTop?: number
}

const SectionHeader: React.FC<SectionHeaderProps> = ({
  titleId,
  renderAppend,
  marginBottom,
  marginTop
}) => {
  const { formatMessage } = useIntl()

  return (
    <Container marginBottom={marginBottom} marginTop={marginTop}>
      <Title>{formatMessage({ id: titleId })}</Title>

      {renderAppend && renderAppend()}
    </Container>
  )
}

export default SectionHeader
