import { Frame } from '../types'
import BasicService from './BasicService'
import { DocumentFrame, FrameService } from './types'

type FramesMap<FrameType> = Record<string, FrameType>

export enum FrameEventTypes {
  frameSizeUpdated = 'frameSizeUpdated'
}

export class ChiliFramesRegister<
    FrameType extends DocumentFrame = DocumentFrame,
    EventTypes extends string = any
  >
  extends BasicService<EventTypes | FrameEventTypes>
  implements FrameService<FrameType>
{
  protected framesMap: FramesMap<FrameType> = {}

  addNewFrame(frame: Frame): FrameType {
    const newFrame = {
      isSizeConnected: false
    } as FrameType

    this.framesMap[frame.id] = newFrame

    return newFrame as FrameType
  }

  setFrameProperty(id: string, field: keyof FrameType, value: any) {
    const frameSizeFields = [
      'width',
      'height',
      'isConnected'
    ] as Array<keyof FrameType>

    if (frameSizeFields.includes(field)) {
      this.triggerListeners(FrameEventTypes.frameSizeUpdated, {
        id,
        internal: false
      })
    }

    return (this.framesMap[id][field] = value)
  }

  getFrame(id: string): FrameType | null {
    return this.framesMap[id] || null
  }

  getOrAddFrame(frame: Frame): FrameType {
    const docFrame = this.getFrame(frame.id)

    return docFrame ? docFrame : this.addNewFrame(frame)
  }

  removeFrame(id: string) {
    delete this.framesMap[id]
  }
}
