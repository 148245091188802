import { useMutation } from '@apollo/client'
import { Dispatch, SetStateAction, useState } from 'react'
import toast from 'react-hot-toast'
import { useIntl } from 'react-intl'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'

import { ButtonIntents } from 'components/Button'
import Flex from 'components/Flex'
import ModalButton from 'components/ModalButton'
import Switch from 'components/Switch'
import TabBar from 'components/TabBar'
import IslandModal, {
  IslandModalProps
} from 'components/modals/IslandModal'
import useAuth from 'data/gradoo/hooks/useAuth'
import {
  RESET_THEME_PAGE_BACKGROUND,
  SET_THEME_PAGE_LIBRARY_BACKGROUND,
  SET_THEME_PAGE_UPLOADS_BACKGROUND
} from 'data/layoutcreator/mutations/themes'

import ChangeBackgroundModalLibraryTab from './tabs/ChangeBackgroundModalLibraryTab'
import ChangeBackgroundModalUploadsTab from './tabs/ChangeBackgroundModalUploadsTab'

const HideBackground = styled(Flex)`
  height: 50px;
  gap: 20px;
  align-items: center;
  padding: 14px;
  border-radius: 14px;
  background: ${({ theme }) => theme.color.base.c0};
  font-size: 14px;
  color: ${({ theme }) => theme.color.base.c8};
`

const TabContainer = styled(Flex)`
  flex-direction: column;
  width: 950px;
  height: 285px;
`

type ChangeBackgroundModalProps = Pick<
  IslandModalProps,
  'isOpen' | 'closeModal'
> & {
  pageType: string | null
  templatePath: string
  onChange: (pageType: string) => void
}

const FORMAT_PREFIX = 'Project.settings.templates.backgrounds'

const ChangeBackgroundModal: React.FC<ChangeBackgroundModalProps> = ({
  isOpen,
  closeModal,
  pageType,
  templatePath,
  onChange
}) => {
  const { formatMessage } = useIntl()
  const { pathname } = useLocation()
  const projectId = pathname.split('/')[1]
  const { authGroupId } = useAuth()

  const [currentTabIndex, setCurrentTabIndex] = useState(0)
  const [hideBg, setHideBg] = useState(false)

  const [selectedLibraryBg, setSelectedLibraryBg] = useState<{
    id: string
    category: string
  } | null>(null)

  const [selectedUploadsBg, setSelectedUploadsBg] = useState<{
    id: string
  } | null>(null)

  const [
    setThemePageLibraryBackground,
    { loading: setLibraryBackgroundLoading }
  ] = useMutation(SET_THEME_PAGE_LIBRARY_BACKGROUND)
  const [
    setThemePageUploadsBackground,
    { loading: setUploadsBackgroundLoading }
  ] = useMutation(SET_THEME_PAGE_UPLOADS_BACKGROUND)
  const [
    resetThemePageBackground,
    { loading: resetBackgroundLoading }
  ] = useMutation(RESET_THEME_PAGE_BACKGROUND)

  const isLoading =
    setLibraryBackgroundLoading ||
    setUploadsBackgroundLoading ||
    resetBackgroundLoading

  const tabProps = {
    onSelectedBackgroundChange: (data: any) =>
      handleSelectedBackgroundChange(data),
    hideBackground: hideBg
  }

  const TABS_MAP: Array<{
    name: string
    component: JSX.Element
    state: {
      id: string
      category?: string
    } | null
    setter: Dispatch<SetStateAction<any>>
    mutation: Function
  }> = [
    {
      name: formatMessage({
        id: `${FORMAT_PREFIX}.modal.tabs.library`
      }),
      component: <ChangeBackgroundModalLibraryTab {...tabProps} />,
      state: selectedLibraryBg,
      setter: setSelectedLibraryBg,
      mutation: setThemePageLibraryBackground
    },
    {
      name: formatMessage({
        id: `${FORMAT_PREFIX}.modal.tabs.uploads`
      }),
      component: <ChangeBackgroundModalUploadsTab {...tabProps} />,
      state: selectedUploadsBg,
      setter: setSelectedUploadsBg,
      mutation: setThemePageUploadsBackground
    }
  ]

  const currentTab = TABS_MAP[currentTabIndex]

  const handleSelectedBackgroundChange = (data: any) => {
    if (hideBg) {
      setHideBg(!data)
    }

    currentTab.setter(data)
  }

  const handleSave = async () => {
    const commonVariables = {
      pageType: pageType as string,
      projectId,
      groupId: authGroupId as string,
      templatePath
    }

    closeModal()

    if (hideBg) {
      await toast.promise(
        resetThemePageBackground({
          variables: commonVariables
        }),
        {
          loading: formatMessage({
            id: 'Toasts.pageBackgrounds.reset'
          }),
          success: formatMessage({
            id: 'Toasts.pageBackgrounds.change.success'
          }),
          error: formatMessage({
            id: 'Toasts.pageBackgrounds.change.error'
          })
        }
      )

      onChange(pageType!)

      return
    }

    if (currentTab.state) {
      await toast.promise(
        currentTab.mutation({
          variables: {
            ...commonVariables,
            backgroundId: currentTab.state.id,
            backgroundCategory: currentTab.state.category
          }
        }),
        {
          loading: formatMessage({
            id: 'Toasts.pageBackgrounds.change'
          }),
          success: formatMessage({
            id: 'Toasts.pageBackgrounds.change.success'
          }),
          error: formatMessage({
            id: 'Toasts.pageBackgrounds.change.error'
          })
        }
      )
      onChange(pageType!)
    }
  }

  const handleCancel = () => {
    closeModal()
  }

  const isItemSelected = !!currentTab.state

  return (
    <IslandModal
      isOpen={isOpen}
      closeModal={closeModal}
      title={formatMessage(
        {
          id: `${FORMAT_PREFIX}.change.modal.title`
        },
        { pageType }
      )}
      titleAlign="left"
      renderHeaderAppend={() => (
        <TabBar
          tabs={TABS_MAP.map(({ name }) => name)}
          activeIndex={currentTabIndex}
          onChange={setCurrentTabIndex}
          width={252}
        />
      )}
    >
      <Flex direction="column">
        <TabContainer>{currentTab.component}</TabContainer>

        <Flex
          marginTop={35}
          justifyContent="space-between"
          alignItems="center"
        >
          <HideBackground>
            <span>
              {formatMessage({
                id: `${FORMAT_PREFIX}.modal.hideBackground`
              })}
            </span>
            <Switch
              value={hideBg}
              onChange={(newValue: boolean) => setHideBg(newValue)}
            />
          </HideBackground>

          <Flex columnGap={7}>
            <ModalButton
              labelId="Actions.cancel"
              intent={ButtonIntents.neutral}
              onClick={handleCancel}
              minWidth={144}
              isDisabled={isLoading}
            />
            <ModalButton
              labelId={`${FORMAT_PREFIX}.buttons.changeBackground`}
              intent={ButtonIntents.dark}
              onClick={handleSave}
              isDisabled={isLoading || (!isItemSelected && !hideBg)}
            />
          </Flex>
        </Flex>
      </Flex>
    </IslandModal>
  )
}

export default ChangeBackgroundModal
