import { useIntl } from 'react-intl'
import styled from 'styled-components'

import BackgroundItem from 'shared/ui/BackgroundItem'
import FileUpload from 'shared/ui/FileUpload'
import Flex from 'shared/ui/Flex'
import { Skeleton } from 'shared/ui/Skeleton'
import UploadZone from 'shared/ui/UploadZone'
import { useBackgroundUploads } from 'shared/hooks/useBackgroundUploads'
import { FileTypes } from 'shared/types/global'

const Container = styled(Flex)`
  flex-direction: column;
  height: 100%;
`

const ItemsContainer = styled.div`
  flex: 0 1 auto;
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  grid-gap: 10px;
  align-items: center;
  transition: 0.2s;
  padding: 5px;
  overflow: auto;
`

type UploadsTabProps = {
  onSelectedBackgroundChange: (
    data: {
      id: string
    } | null
  ) => void
  hideBackground: boolean
}

const FORMAT_PREFIX =
  'Project.settings.templates.backgrounds.modal.tabs.uploads'

const ChangeBackgroundModalUploadsTab: React.FC<UploadsTabProps> = ({
  onSelectedBackgroundChange,
  hideBackground
}) => {
  const { formatMessage } = useIntl()

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    handleUploadClick,
    handleFileUpload,
    handleImageRemove,
    selectedImageId,
    setSelectedImageId,
    imageInputRef,
    isImagesLoading,
    images
  } = useBackgroundUploads({
    onSelectedBackgroundChange,
    hideBackground
  })

  return (
    <Container {...getRootProps()}>
      <Flex
        marginTop={30}
        marginBottom={25}
        justifyContent="flex-end"
        flex="0 0 auto"
      >
        <UploadZone
          onClick={handleUploadClick}
          isDragActive={isDragActive}
        />
      </Flex>

      <ItemsContainer style={{ opacity: isDragActive ? 0.3 : 1 }}>
        {isImagesLoading
          ? new Array(10)
              .fill(null)
              .map((_, i) => (
                <Skeleton key={i} width={84} height={109} />
              ))
          : images?.map(image => (
              <BackgroundItem
                key={image.id}
                isRemovable
                isSelected={image.id === selectedImageId}
                onClick={() =>
                  setSelectedImageId(
                    selectedImageId === image.id ? null : image.id
                  )
                }
                onRemove={() =>
                  handleImageRemove({
                    backgroundId: image.id,
                    type: image.type
                  })
                }
                src={new URL(
                  image.thumbPath,
                  process.env.REACT_APP_REST_URL
                ).toString()}
              />
            ))}
      </ItemsContainer>
      {images?.length === 0 &&
        formatMessage({ id: `${FORMAT_PREFIX}.noImages` })}

      <FileUpload
        {...getInputProps({
          ref: imageInputRef,
          onSelect: (file: any) => handleFileUpload(file),
          type: FileTypes.image
        })}
      />
    </Container>
  )
}

export default ChangeBackgroundModalUploadsTab
