import { css } from 'styled-components'

import {
  MAIN_CONTAINER_WIDTH,
  MIN_MAIN_CONTAINER_WIDTH
} from 'shared/styles/constants'

export const applyLinearGradient = (colors: string[]) =>
  `linear-gradient(92.71deg, ${colors[0]} 2.26%, ${colors[1]} 110.68%);`

const hexColors = [
  '#1D6CEC',
  '#01861E',
  '#C99504',
  '#0F172A',
  '#A4031F',
  '#0B5351',
  '#08A4BD',
  '#FF6B35',
  '#D90368',
  '#028270',
  '#4F46E5'
]

export const getLiveHexColor = (index: number): string => {
  return hexColors[index % hexColors.length]
}

export type BreakpointLabels = 's' | 'm' | 'l' | 'xl'
export const breakpoints: Record<BreakpointLabels, string> = {
  s: MIN_MAIN_CONTAINER_WIDTH + 'px',
  m: '800px',
  l: '1000px',
  xl: MAIN_CONTAINER_WIDTH + 'px'
}

export type ResponsiveProps = {
  [K in BreakpointLabels]?: React.CSSProperties
}

export type StyledProps = {
  responsive?: ResponsiveProps
}

const convertCamelCaseToCSS = (propertyName: string): string => {
  return propertyName.replace(/[A-Z]/g, m => '-' + m.toLowerCase())
}

/** 
 * @example
 * export const StyledComponent = styled.div<{responsive?: ResponsiveProps}>`
 *  display: flex;
 *  flex-direction: column;
 *  ${responsiveStyles}
 * `;

 * <StyledComponent
 *  responsive={{
 *   s:{
 *     flexDirection: 'row'
 *   },
 *   m:{
 *     flexDirection: 'column'
 *   }
 *  }}
 * >
 * // Content here
 * </StyledComponent>
*/
export const responsiveStyles = (props: StyledProps) => css`
  ${(Object.keys(props?.responsive || {}) as BreakpointLabels[])
    .sort(
      (a, b) => parseInt(breakpoints[a]) - parseInt(breakpoints[b])
    )
    .map(size => {
      const styleProps = props.responsive
        ? props.responsive[size]
        : undefined
      const breakpointSize = breakpoints[size]
      if (
        breakpointSize &&
        styleProps &&
        typeof styleProps === 'object'
      ) {
        return `
        @media (min-width: ${breakpointSize}) {
          ${Object.entries(styleProps)
            .map(
              ([key, value]) =>
                `${convertCamelCaseToCSS(key)}: ${value};`
            )
            .join(' ')}
        }
      `
      }
      return ''
    })
    .join('')}
`
