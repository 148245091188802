import { useLazyQuery } from '@apollo/client'
import config from 'config'

import { GET_THEME_COLORS } from 'data/layoutcreator/queries/themes'
import ChiliVariables, {
  VariableTypes
} from 'screens/YearbookEditor/components/Tools/services/ChiliVariables'

const variablesApi = ChiliVariables.getInstance()

const SHOW_PAGE_NUMBER_DEFAULT_VALUE = true

export const useReset = ({
  groupId,
  projectId,
  templatePath
}: {
  groupId: string
  projectId: string
  documentId: string
  templatePath: string
}) => {
  const [getThemeColors] = useLazyQuery(GET_THEME_COLORS)

  const applyThemeColors = async (templatePath: string) => {
    const { data: themeColorsData } = await getThemeColors({
      variables: {
        groupId: groupId!,
        projectId,
        templatePath
      }
    })

    const themeColors = themeColorsData!.getThemeColors!

    for (const { key, value } of themeColors) {
      await variablesApi.setVariableValue(
        key,
        variablesApi.convertColorToVariableValue(value)
      )
    }
  }

  const resetBackground = async () => {
    variablesApi.setVariableValue(
      config.themes.variablesKeys.backgroundImage,
      '',
      {
        type: VariableTypes.chiliImage
      }
    )
  }

  const resetShowPageNumber = async () => {
    variablesApi.setVariableValue(
      config.themes.variablesKeys.pageNumberVisibility,
      SHOW_PAGE_NUMBER_DEFAULT_VALUE
    )
  }

  const reset = async () => {
    await applyThemeColors(templatePath)
    await resetBackground()
    await resetShowPageNumber()
  }

  return reset
}
