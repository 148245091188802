import { gql } from 'types/gradoo'

export const REPORTS_INSTANCES = gql(/* GraphQL */ `
  query ReportsInstances(
    $after: String
    $first: Int
    $moduleInstance: ID
  ) {
    reportsInstances(
      after: $after
      first: $first
      moduleInstance: $moduleInstance
    ) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          ...ReportsInstance
        }
      }
    }
  }
`)

export const REPORTS_INSTANCE = gql(/* GraphQL */ `
  query ReportsInstance($id: ID!) {
    reportsInstance(id: $id) {
      ...ReportsInstanceDetail
    }
  }
`)
