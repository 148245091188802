import { ContentSetItemSkeleton } from './components/ContentSetItem'

const ContentSetsSkeleton: React.FC = () => (
  <>
    {new Array(5).fill(null).map((_, i) => (
      <ContentSetItemSkeleton key={i} />
    ))}
  </>
)

export default ContentSetsSkeleton
