import { FileTypes } from 'types/global'

export const readFileAsDataURL = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = () => resolve(reader.result as string)
    reader.onerror = reject
    reader.readAsDataURL(file)
  })
}

export const isSupportedFileType = (file: File | Blob) => {
  return (
    file.type.split('/')[0] === FileTypes.image ||
    file.type.split('/')[1] === FileTypes.pdf
  )
}

export const bytesToSize = (
  bytes: number,
  floor: boolean = false
): string => {
  const sizes = ['Bytes', 'Kb', 'Mb', 'Gb', 'Tb']
  if (bytes === 0) {
    return 'n/a'
  }

  const i = Math.floor(Math.log(bytes) / Math.log(1024))

  if (i === 0) {
    return `${bytes} ${sizes[i]}`
  }

  const number = bytes / Math.pow(1024, i)

  return `${floor ? Math.floor(number) : number} ${sizes[i]}`
}

export const fetchImageFile = async (
  url: string
): Promise<File | null> => {
  const image = await fetch(url, { cache: 'no-cache' })
  const imageBlob = await image.blob()

  const file = new File(
    [imageBlob],
    `${Date.now()}.${imageBlob.type.split('/')[1]}`,
    {
      type: imageBlob.type
    }
  )

  if (!isSupportedFileType(file)) {
    return null
  }

  return file
}
