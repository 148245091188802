import Flex from 'shared/ui/Flex'
import { InlineSkeleton, Skeleton } from 'shared/ui/Skeleton'

const options = [20, 50, 30]

const RankingsItemSkeleton: React.FC = () => {
  return (
    <Flex direction="column">
      <InlineSkeleton width={200} height={11} marginBottom={10} />

      <Flex direction="column" rowGap={10}>
        {options.map((percent, i) => (
          <Flex columnGap={6} key={i}>
            <Skeleton
              width={38}
              height={38}
              radius={8}
              colorLevel={3}
            />

            <Flex flex={1} alignItems="center">
              <Skeleton
                colorLevel={1}
                height={38}
                width={`${percent}%`}
              />
              <InlineSkeleton marginLeft={8} height={12} width={14} />
            </Flex>
          </Flex>
        ))}
      </Flex>

      <Flex
        marginTop={16}
        justifyContent="space-between"
        alignItems="center"
      >
        <InlineSkeleton height={9} width={80} />
        <InlineSkeleton height={9} width={70} colorLevel={4} />
      </Flex>
    </Flex>
  )
}

export default RankingsItemSkeleton
