import { useLayoutEffect, useRef, useState } from 'react'
import { useMemo } from 'react'
import styled from 'styled-components'

import Flex from 'components/Flex'
import { mapUserName } from 'helpers/mappers'
import { useClickToCopy } from 'hooks/useClickToCopy'
import { RankingQuestionResultFragment } from 'types/gradoo/graphql'

const CONTAINER_WIDTH = 206
const TEXT_MARGIN = 11
const PERCENTAGE_LEFT_MARGIN = 6

const Container = styled.div`
  display: grid;
  grid-template-columns: 38px 1fr;
  grid-gap: 6px;
  height: 38px;
`

const ImagesContainer = styled(Flex)`
  width: 38px;
  height: 38px;
  border-radius: 8px;
  flex-direction: column;
  overflow: hidden;
`

const OptionImage = styled.div<{ src: string }>`
  flex: 1;
  width: 100%;
  background: url(${({ src }) => src}) center / cover;
`

const ProgressBar = styled.div`
  position: relative;
  width: 100%;
`

const Text = styled.span`
  position: absolute;
  left: ${TEXT_MARGIN}px;
  top: 50%;
  transform: translateY(-50%);
  font-weight: 600;
  font-size: 11px;
  color: ${({ theme }) => theme.color.brand_02};
  white-space: pre-wrap;
`

const ProgressLine = styled.div<{ percentage?: number | null }>`
  width: ${({ percentage = 0 }) => percentage}%;
  height: 100%;
  background: ${({ theme }) => theme.color.base.c1};
  border-radius: 8px;
`

const Percentage = styled.span<{ left: number | null }>`
  position: absolute;
  top: 50%;
  left: ${({ left }) =>
    left ? `${left + PERCENTAGE_LEFT_MARGIN}px` : 'auto'};
  right: ${({ left }) => (left ? 'auto' : `${TEXT_MARGIN}px`)};
  transform: translateY(-50%);
  font-weight: 600;
  font-size: 11px;
  width: 30px;
`

type RankingsOptionProps = {
  rankingQuestionResultOption: RankingQuestionResultFragment
}

const RankingsOption: React.FC<RankingsOptionProps> = ({
  rankingQuestionResultOption
}) => {
  const { register } = useClickToCopy()
  const [progressLineWidth, setProgressLineWidth] = useState(0)
  const [textWidth, setTextWidth] = useState(0)
  const [percentageWidth, setPercentageWidth] = useState(0)
  const progressLineRef = useRef<HTMLDivElement>(null)
  const textRef = useRef<HTMLSpanElement>(null)
  const percentageRef = useRef<HTMLSpanElement>(null)

  const {
    percentage,
    votee1UserGroup,
    votee2UserGroup,
    voteeListOption
  } = rankingQuestionResultOption

  useLayoutEffect(() => {
    if (
      progressLineRef.current &&
      textRef.current &&
      percentageRef.current
    ) {
      setProgressLineWidth(progressLineRef.current.offsetWidth)
      setTextWidth(textRef.current.offsetWidth)
      setPercentageWidth(percentageRef.current.offsetWidth)
    }
  }, [])

  const alignPercentRight = useMemo(() => {
    return (
      Math.max(progressLineWidth, textWidth + TEXT_MARGIN) +
        PERCENTAGE_LEFT_MARGIN +
        percentageWidth >
      CONTAINER_WIDTH
    )
  }, [progressLineWidth, textWidth, percentageWidth])

  const formattedPercentage = percentage ? percentage * 100 : 0

  const text = `${
    votee1UserGroup?.user
      ? mapUserName(votee1UserGroup.user)
      : voteeListOption?.text
  }\n${
    votee2UserGroup?.user ? mapUserName(votee2UserGroup.user) : ''
  }`

  return (
    <Container>
      <ImagesContainer>
        <OptionImage
          src={
            votee1UserGroup?.avatar ||
            votee1UserGroup?.defaultAvatar?.avatar ||
            ''
          }
        />
        {votee2UserGroup && (
          <OptionImage
            src={
              votee2UserGroup.avatar ||
              votee2UserGroup.defaultAvatar?.avatar ||
              ''
            }
          />
        )}
      </ImagesContainer>
      <ProgressBar>
        <ProgressLine
          ref={progressLineRef}
          percentage={formattedPercentage}
        ></ProgressLine>
        <Text ref={textRef}>
          <span {...register()}>{text}</span>
        </Text>
        <Percentage
          ref={percentageRef}
          left={
            alignPercentRight
              ? null
              : Math.max(progressLineWidth, textWidth + TEXT_MARGIN)
          }
        >
          {formattedPercentage.toFixed(0)}%
        </Percentage>
      </ProgressBar>
    </Container>
  )
}

export default RankingsOption
