import { useMutation } from '@apollo/client'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import Button from 'shared/ui/Button_2'
import SectionHeader from 'shared/ui/SectionHeader'
import useAuth from 'data/gradoo/hooks/useAuth'
import { DELETE_PROJECT } from 'data/layoutcreator/mutations/projects'
import { GET_PROJECTS } from 'data/layoutcreator/queries/projects'
import Section from '../../components/Section'

import ConfirmDeleteModal from './components/ConfirmDeleteModal'

type DangerZoneSectionProps = {
  groupId: string
  projectId: string
}

const DangerZoneSection: React.FC<DangerZoneSectionProps> = ({ groupId, projectId }) => {
  const navigate = useNavigate()

  const { authUserId } = useAuth()

  const [deleteProject] = useMutation(DELETE_PROJECT, {
    refetchQueries: [
      { query: GET_PROJECTS, variables: { groupId } }
    ]
  })

  const handleDelete = async () => {
    await deleteProject({
      variables: {
        groupId,
        projectId,
        userId: authUserId!
      }
    })
    navigate('/')
  }

  const [isModalOpened, setIsModalOpened] = useState(false)

  return (
    <Section
      Header={
        <SectionHeader
          marginBottom={12}
          titleId="Project.settings.danger.title"
        />
      }
    >
      <Button
        textId="Project.settings.danger.deleteProject"
        intent="destructive"
        onPress={() => setIsModalOpened(true)}
        size="xl"
      />

      <ConfirmDeleteModal
        isOpen={isModalOpened}
        closeModal={() => setIsModalOpened(false)}
        onConfirm={handleDelete}
      />
    </Section>
  )
}

export default DangerZoneSection
