import Flex from 'components/Flex'
import { ReactChildren } from 'types/global'

const FooterContainer = ({ children }: ReactChildren) => (
  <Flex direction="row" justifyContent="space-between" marginTop={48}>
    {children}
  </Flex>
)

export default FooterContainer
