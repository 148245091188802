import styled from 'styled-components'

import Flex from 'components/Flex'

import Skeleton from './Skeleton'
import checkSrc from './icons/check.svg'
import { ReactComponent as PencilIcon } from './icons/pencil.svg'

const DEFAULT_PADDING = [4, 8] as [number, number]

type ContainerProps = {
  isFullWidth: boolean
  padding: [number, number]
  fontSize: number
  isSelected?: boolean
  selectable?: boolean
}

const Container = styled(Flex)<ContainerProps>`
  width: ${({ isFullWidth }) => (isFullWidth ? '100%' : '252px')};
  padding: ${({ padding }) => `${padding[0]}px ${padding[1]}px`};
  border-radius: 9999px;
  align-items: center;
  justify-content: space-between;
  border-radius: 15px;
  background: ${({ theme }) => theme.color.base.c1};
  font-size: ${({ fontSize }) => fontSize}px;
  font-weight: 600;
  border: ${({ theme, isSelected }) =>
    isSelected && `1px solid ${theme.color.brand_02}`};
  cursor: pointer;

  ${({ selectable, isSelected, theme }) =>
    selectable &&
    `
    &::after {
      display: flex;
      flex-shrink: 0;
      width: 22px;
      height: 22px;
      background-color: ${
        isSelected ? theme.color.brand_02 : theme.color.base.c3
      };
      background-image: ${isSelected ? `url('${checkSrc}')` : 'none'};
      background-position: center;
      background-repeat: no-repeat;
      border-radius: 50%;
      content: '';
    }
  `}
`

const Color = styled.div`
  width: 22px;
  height: 22px;
  border: 1px solid ${({ theme }) => theme.color.base.c2};
  border-radius: 50%;
`

const Name = styled.span`
  color: ${({ theme }) => theme.color.base.c7};
  font-size: 12px;
  font-weight: 600;
`

const StyledPencilIcon = styled(PencilIcon)`
  margin-left: 16px;
  cursor: pointer;
`

type ColorItemProps = {
  color: string
  fontSize?: number
  padding?: [number, number]
  name: string
  onEditClick?: () => void
  editable?: boolean
  isFullWidth?: boolean
  selectable?: boolean
  isSelected?: boolean
  onClick?: () => void
}

const ColorItem: React.FC<ColorItemProps> = ({
  padding = DEFAULT_PADDING,
  fontSize = 12,
  color,
  name,
  onEditClick,
  editable = true,
  selectable = false,
  isSelected = false,
  isFullWidth,
  ...rest
}) => {
  return (
    <Container
      isFullWidth
      padding={padding}
      fontSize={fontSize}
      selectable={selectable}
      isSelected={isSelected}
      {...rest}
    >
      <Flex columnGap={8} alignItems="center">
        <Color style={{ background: color }} />
        <Name>{name}</Name>
      </Flex>

      {editable && <StyledPencilIcon onClick={onEditClick} />}
    </Container>
  )
}

export { Skeleton as ColorItemSkeleton }
export default ColorItem
