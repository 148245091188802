import { Route, Routes } from 'react-router-dom'

import Admin from 'screens/Admin'
import AdminProjectsEditor from 'screens/AdminPageEditor'
import AdminProject from 'screens/AdminProject'
import AdminProjectExport from 'screens/AdminProjectExport'
import AdminProjectSettings from 'screens/AdminProjectSettings'
import Home from 'screens/Home'
import Export from 'screens/ProjectExport'
import ProjectSettings from 'screens/ProjectSettings'
import YearbookEditor from 'screens/YearbookEditor'
import YearbookPages from 'screens/YearbookPages'
import YearbookCover from 'screens/YearbookCover'
import RedirectKeepingUrlParams from 'shared/ui/RedirectKeepingUrlParams'

const Router = () => (
  <Routes>
    {/* <Route path="*" element={<Maintenance />} /> */}

    <Route path="/" element={<Home />}></Route>
    <Route path=":projectId">
      <Route
        index
        element={<RedirectKeepingUrlParams to="content" />}
      />
      <Route path="content" element={<YearbookPages />}>
        
      </Route>
      <Route path="cover" element={<YearbookCover />} />
    </Route>

    <Route path=":projectId/content/editor/:pageId" element={<YearbookEditor />} />
    <Route path=":projectId/settings" element={<ProjectSettings />} />
    <Route path=":projectId/settings/export" element={<Export />} />
    <Route path="/admin/*" element={<Admin />} />
    <Route
      path="/admin/projects/:groupId/:projectId"
      element={<AdminProject />}
    />
    <Route path="/admin/old/:projectId" element={<AdminProject />} />
    <Route
      path="/admin/projects/:groupId/:projectId/editor/:pageId"
      element={<AdminProjectsEditor />}
    />
    <Route
      path="/admin/projects/:groupId/:projectId/settings"
      element={<AdminProjectSettings />}
    />
    <Route
      path="/admin/projects/:groupId/:projectId/settings/export"
      element={<AdminProjectExport />}
    />
  </Routes>
)

export default Router
