import { useIntl } from 'react-intl'
import styled from 'styled-components'

import { LINKS } from 'data/constants'

import {
  Container,
  FooterText,
  StyledButton,
  StyledLink,
  VSeparator
} from './style'

type LinkProps = {
  text: string
  href?: string
  onPress?: () => void
}

const LinkWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const year = new Date().getFullYear()
const Footer = (): JSX.Element => {
  const { formatMessage } = useIntl()

  const onLogout = () => {
    localStorage.removeItem('token')
    localStorage.removeItem('refreshToken')
    window.location.reload()
  }

  const links: LinkProps[] = [
    {
      text: formatMessage({
        id: 'Project.settings.footer.action.logout'
      }),
      onPress: onLogout
    },
    {
      text: formatMessage({
        id: 'Project.settings.footer.action.imprint'
      }),
      href: LINKS.IMPRINT
    },
    {
      text: formatMessage({
        id: 'Project.settings.footer.action.dataPolicy'
      }),
      href: LINKS.DATA_CENTER
    }
  ]

  return (
    <Container>
      <FooterText>
        {formatMessage(
          { id: 'Project.settings.footer.copyright' },
          { year }
        )}
      </FooterText>
      {links.map((link, i) => (
        <LinkWrapper key={`s${i}`}>
          {i < links.length && <VSeparator />}
          {link.href && (
            <StyledLink href={link.href} target="_blank">
              <FooterText>{link.text}</FooterText>
            </StyledLink>
          )}
          {link.onPress && (
            <StyledButton onClick={link.onPress}>
              <FooterText>{link.text}</FooterText>
            </StyledButton>
          )}
        </LinkWrapper>
      ))}
    </Container>
  )
}

export default Footer
