import { useQuery } from '@apollo/client'
import { Dispatch, SetStateAction } from 'react'

import Spinner, { SpinnerVariants } from 'shared/ui/Spinner'
import { GET_FONT_PREVIEW_PATH } from 'data/layoutcreator/queries/fonts'
import { Font } from 'shared/components/Tools/types'

import { SecondLevelPanels } from '../../../FrameControls'
import Selector from '../../../Selector'

type SelectedFontProps = {
  font: Font | null
  onClick: Dispatch<SetStateAction<SecondLevelPanels | null>>
}

const SelectedFont = ({
  font,
  onClick
}: SelectedFontProps): JSX.Element => {
  const { loading, error, data } = useQuery(GET_FONT_PREVIEW_PATH, {
    variables: {
      fontId: font?.id
    },
    skip: !font
  })

  return (
    <>
      {loading && <Spinner variant={SpinnerVariants.light} />}
      {error && <p>Error loading font :(</p>}
      {data && (
        <Selector
          svgImage={data.getBigFontPreviewSvg}
          onClick={() => onClick(SecondLevelPanels.font)}
          width="162"
        />
      )}
    </>
  )
}

export default SelectedFont
