import { FormikContextType, useFormikContext } from 'formik'
import { Dispatch, SetStateAction } from 'react'

import { ShapeTypes } from 'screens/YearbookEditor/components/Tools/types'

import { SecondLevelPanels } from '../../../FrameControls'
import Selector from '../../../Selector'
import { SHAPE_TYPES } from '../../consts'

type SelectedShapeProps = {
  onClick: Dispatch<SetStateAction<SecondLevelPanels | null>>
}
function cmykToHex(c: number, m: number, y: number, k: number) {
  const r = 255 * (1 - c / 100) * (1 - k / 100)
  const g = 255 * (1 - m / 100) * (1 - k / 100)
  const b = 255 * (1 - y / 100) * (1 - k / 100)

  return `#${[r, g, b]
    .map(x => {
      const hex = Math.round(x).toString(16)
      return hex.length === 1 ? '0' + hex : hex
    })
    .join('')}`
}
const SelectedShape: React.FC<SelectedShapeProps> = ({ onClick }) => {
  const { values: formValues }: FormikContextType<any> =
    useFormikContext()

  const selectedShape = formValues.shapeType as ShapeTypes
  const SelectedShapeImage = SHAPE_TYPES[selectedShape]
    ? SHAPE_TYPES[selectedShape].Image
    : () => <></>
  const colorInHex = cmykToHex(
    formValues.color?.c,
    formValues.color?.m,
    formValues.color?.y,
    formValues.color?.k
  )
  return (
    <Selector
      svgImage={SelectedShapeImage}
      onClick={() => onClick(SecondLevelPanels.shapeType)}
      height="32"
      width="32"
      color={colorInHex}
    />
  )
}

export default SelectedShape
