import { PublisherInterface } from '@chili-publish/publisher-interface'

import {
  Base64String,
  Coordinates,
  Millimeters,
  Pixels,
  Size
} from 'types/global'

import BasicService from './BasicService'
import {
  ChiliDocument as ChiliDocumentType,
  ChiliDocumentViewPreferences,
  Document,
  DocumentEvents,
  DocumentViewPreferences
} from './types'

type DocumentService<InitOptions> = {
  init: (options: InitOptions) => void
}

type InitOptions = {
  publisherConnector: PublisherInterface
}

export default class ChiliDocument
  extends BasicService
  implements DocumentService<InitOptions>
{
  private static instance: ChiliDocument

  static getInstance(): ChiliDocument {
    if (!ChiliDocument.instance) {
      ChiliDocument.instance = new ChiliDocument()
    }

    return ChiliDocument.instance
  }

  async getDocument(): Promise<Document> {
    const document = (await this.publisherConnector?.getObject(
      'document'
    )) as ChiliDocumentType

    const getRealValue = (number: Pixels) => {
      return Math.floor(
        (number / 100) * Number.parseInt(document.zoom)
      )
    }

    return {
      width: Number.parseInt(document.width),
      height: Number.parseInt(document.height),
      pixelWidth: getRealValue(document.pixelWidth),
      pixelHeight: getRealValue(document.pixelHeight)
    }
  }

  async getDocumentViewPreferences(): Promise<DocumentViewPreferences> {
    const viewPrefs = (await this.publisherConnector?.getObject(
      'document.viewPreferences'
    )) as ChiliDocumentViewPreferences

    return {
      pageMarginX: Number.parseInt(viewPrefs.pageCanvasMarginX),
      pageMarginY: Number.parseInt(viewPrefs.pageCanvasMarginY)
    }
  }

  on(event: DocumentEvents, callback: () => void) {
    this.publisherConnector?.addListener(event, callback)
  }

  getSnapshot(): Promise<Base64String> {
    return this.publisherConnector?.executeFunction(
      'document.pages[0].pageControl',
      'GetPNGSnapshot'
    ) as Promise<Base64String>
  }

  async getPageSize(): Promise<Size<Millimeters>> {
    const document = await this.getDocument()

    const { width, height } = document

    return {
      width,
      height
    }
  }

  async getPagePixelSize(): Promise<Size<Pixels>> {
    const document = await this.getDocument()

    const { pixelWidth, pixelHeight } = document

    return {
      width: pixelWidth,
      height: pixelHeight
    }
  }

  async getPageMargin(): Promise<Coordinates<Pixels>> {
    const { pageMarginX, pageMarginY } =
      await this.getDocumentViewPreferences()

    return {
      x: pageMarginX,
      y: pageMarginY
    }
  }
}
