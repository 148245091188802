import { useLazyQuery, useMutation } from '@apollo/client'
import { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import Button, { ButtonIntents } from 'components/Button'
import Flex from 'components/Flex'
import Input from 'components/Input'
import StartImagesMigrationModal from 'components/StartImagesMigrationModal'
import YearbookCard from 'components/YearbookCard'
import {
  EMPTY_EXPORT_QUEUE,
  FIX_VARIABLES,
  RECOVER_DELETED_PROJECT
} from 'data/layoutcreator/mutations/admin'
import { FIX_BROKEN_PAGES } from 'data/layoutcreator/mutations/projects'
import {
  CHECK_AUTH,
  GET_ADMIN_PROJECTS,
  GET_DB_SNAPSHOT
} from 'data/layoutcreator/queries/admin'
import AddAssetModal from 'screens/Admin/components/AddAssetModal'
import ChangeProjectMetaModal from 'screens/Admin/components/ChangeProjectMetaModal'
import ColorMigrationsModal from 'screens/Admin/components/ColorMigrationsModal'
import GetMigrationDataModal from 'screens/Admin/components/GetMigrationDataModal'

const Title = styled.h1`
  margin-top: 64px;
  margin-bottom: 32px;
  font-size: 28px;
`

const ProjectWarning = styled.span`
  margin-bottom: 8px;
  color: ${({ theme }) => theme.color.error};
`

const AdminTab = () => {
  const navigate = useNavigate()

  const lastGroupId = localStorage.getItem('adminLastGroupId')
  const [groupId, setGroupId] = useState<string>(lastGroupId || '')
  const [modalProjectId, setModalProjectId] = useState<string>('')
  const [isStartMigrationModalOpen, setIsStartMigrationModalOpen] =
    useState(false)
  const [isAddAssetModalOpen, setIsAddAssetModalOpen] =
    useState(false)
  const [
    isChangeProjectMetaModalOpen,
    setIsChangeProjectMetaModalOpen
  ] = useState(false)
  const [isMigrationsModalOpen, setIsMigrationsModalOpen] =
    useState(false)
  const [
    isGetMigrationDataModalOpen,
    setIsGetMigrationDataModalOpen
  ] = useState(false)

  const [checkAuth] = useLazyQuery(CHECK_AUTH)
  const [
    fetchProjects,
    { data: projectsData, error: projectsSearchError }
  ] = useLazyQuery(GET_ADMIN_PROJECTS)
  const [fetchDbSnapshot] = useLazyQuery(GET_DB_SNAPSHOT)

  const [fixVariables, { loading: isFixPageNumberTypesLoading }] =
    useMutation(FIX_VARIABLES)
  const [fixPages, { loading: isFixPagesLoading }] =
    useMutation(FIX_BROKEN_PAGES)
  const [recoverDeletedProject] = useMutation(
    RECOVER_DELETED_PROJECT,
    {
      refetchQueries: [
        { query: GET_ADMIN_PROJECTS, variables: { groupId } }
      ]
    }
  )
  const [emptyExportQueue] = useMutation(EMPTY_EXPORT_QUEUE)

  useEffect(() => {
    const check = async () => {
      const { data } = await checkAuth()
      if (!data?.checkAuth) {
        navigate('/')
      }
    }

    check()
  }, [])

  const handleFindProjectsClick = () => {
    fetchProjects({ variables: { groupId } })
    localStorage.setItem('adminLastGroupId', groupId)
  }

  const handleDownloadDbSnapshotClick = async () => {
    const toastId = toast.loading('Downloading db snapshot...', {
      icon: '🔧'
    })
    const { data, error } = await fetchDbSnapshot({
      variables: { groupId }
    })

    if (error) {
      toast.error('Error fetching db data')
      toast.dismiss(toastId)
      return
    }

    const blob = new Blob(
      [data?.getDbSnapshot || 'Error fetching db data'],
      { type: 'text/json' }
    )

    const url = URL.createObjectURL(blob)

    const link = document.createElement('a')
    link.setAttribute('download', `db-snapshot-${groupId}.json`)
    link.setAttribute('href', url)
    document.body.appendChild(link)
    link.click()

    toast.dismiss(toastId)

    document.body.removeChild(link)
    URL.revokeObjectURL(url)
  }

  const handleFixVariables = (
    e: React.MouseEvent<HTMLButtonElement>,
    projectId: string
  ) => {
    e.preventDefault()
    const firstToast = toast.loading('Fixing page number types...', {
      icon: '🔧'
    })

    fixVariables({
      variables: { groupId, projectId }
    })
      .then(() => {
        toast.success('Page number types fixed!')
        toast.dismiss(firstToast)
      })
      .catch(() => {
        toast.error('Error fixing page number types')
        toast.dismiss(firstToast)
      })
      .finally(() => {
        toast.dismiss(firstToast)
      })
  }

  const handleFixPages = (
    e: React.MouseEvent<HTMLButtonElement>,
    projectId: string
  ) => {
    e.preventDefault()

    const firstToast = toast.loading('Fixing pages...', {
      icon: '🔧'
    })

    fixPages({
      variables: { groupId, projectId }
    })
      .then(() => {
        toast.success('Pages fixed!')
        toast.dismiss(firstToast)
      })
      .catch(() => {
        toast.error('Error fixing pages')
        toast.dismiss(firstToast)
      })
      .finally(() => {
        toast.dismiss(firstToast)
      })
  }

  const handleRecoverProject = (
    e: React.MouseEvent<HTMLButtonElement>,
    projectId: string
  ) => {
    e.preventDefault()

    const firstToast = toast.loading('Recovering project...', {
      icon: '🔧'
    })

    recoverDeletedProject({
      variables: { groupId, projectId }
    })
      .then(() => {
        toast.success('Project recovered!')
        toast.dismiss(firstToast)
      })
      .catch(() => {
        toast.error('Error recovering project')
        toast.dismiss(firstToast)
      })
      .finally(() => {
        toast.dismiss(firstToast)
      })
  }

  return (
    <>
      <Flex
        width={1200}
        justifyContent="space-between"
        columnGap={12}
      >
        <Flex direction="column">
          <Title>Enter a customer project</Title>

          <Flex direction="column" rowGap={20} width={555}>
            <Input
              value={groupId}
              onChange={setGroupId}
              label="Group ID"
            />
            <Button
              labelId="Admin.buttons.findProjects"
              intent={ButtonIntents.dark}
              minWidth={360}
              borderRadius={100}
              onClick={handleFindProjectsClick}
            />
            <Button
              labelId="Admin.buttons.downloadDbSnapshot"
              intent={ButtonIntents.neutral}
              borderRadius={100}
              onClick={handleDownloadDbSnapshotClick}
            />
          </Flex>
        </Flex>

        <Flex direction="column">
          <Title>Actions</Title>

          <Flex direction="column" rowGap={16}>
            <Button
              labelId="Admin.buttons.uploadAsset"
              intent={ButtonIntents.neutral}
              borderRadius={100}
              onClick={() => setIsAddAssetModalOpen(true)}
            />

            <Button
              labelId="Admin.buttons.changeProjectMeta"
              intent={ButtonIntents.neutral}
              borderRadius={100}
              onClick={() => setIsChangeProjectMetaModalOpen(true)}
            />

            <Button
              labelId="Admin.buttons.migrations"
              intent={ButtonIntents.neutral}
              borderRadius={100}
              onClick={() => setIsMigrationsModalOpen(true)}
            />

            <Button
              labelId="Admin.buttons.getMigrationData"
              intent={ButtonIntents.neutral}
              borderRadius={100}
              onClick={() => setIsGetMigrationDataModalOpen(true)}
            />
          </Flex>
        </Flex>
      </Flex>

      <Flex direction="column" marginBottom={72}>
        <Title>Projects:</Title>

        <Flex wrap="wrap" columnGap={26} rowGap={36}>
          {projectsData?.getAdminProjects.length === 0 &&
            'No project found'}
          {projectsSearchError && 'Error searching the group :('}
          {projectsData?.getAdminProjects.map((project, i) => (
            <YearbookCard
              key={i}
              name={project.name}
              linkTo={`/admin/${project.id}`}
              pagePreviews={project.pagePreviews}
              append={() => (
                <Flex rowGap={8} direction="column">
                  {!project.id && (
                    <ProjectWarning>
                      Don't have project meta
                    </ProjectWarning>
                  )}
                  {project.deleted && (
                    <ProjectWarning>
                      This project was deleted
                    </ProjectWarning>
                  )}

                  <Button
                    intent={ButtonIntents.neutral}
                    borderRadius={100}
                    padding={[6, 0]}
                    labelId="Admin.buttons.export"
                    isDisabled
                  />
                  <Button
                    intent={ButtonIntents.dark}
                    borderRadius={100}
                    padding={[6, 0]}
                    labelId="Admin.buttons.edit"
                    isDisabled
                  />
                  {project.id && (
                    <>
                      <Button
                        intent={ButtonIntents.neutral}
                        borderRadius={100}
                        padding={[6, 0]}
                        labelId="Admin.buttons.fixVariables"
                        onClick={e =>
                          handleFixVariables(e, project.id!)
                        }
                        isDisabled={isFixPageNumberTypesLoading}
                      />
                      <Button
                        intent={ButtonIntents.neutral}
                        borderRadius={100}
                        padding={[6, 0]}
                        labelId="Admin.buttons.fixPages"
                        onClick={e => handleFixPages(e, project.id!)}
                        isDisabled={isFixPagesLoading}
                      />
                    </>
                  )}
                  {project.deleted && (
                    <Button
                      intent={ButtonIntents.primary}
                      borderRadius={100}
                      padding={[6, 0]}
                      labelId="Admin.buttons.recover"
                      onClick={e =>
                        handleRecoverProject(e, project.id!)
                      }
                    />
                  )}
                </Flex>
              )}
            />
          ))}
        </Flex>
      </Flex>

      <AddAssetModal
        isOpen={isAddAssetModalOpen}
        closeModal={() => setIsAddAssetModalOpen(false)}
      />

      <ChangeProjectMetaModal
        isOpen={isChangeProjectMetaModalOpen}
        closeModal={() => setIsChangeProjectMetaModalOpen(false)}
        groupId={groupId}
      />

      <StartImagesMigrationModal
        isOpen={isStartMigrationModalOpen}
        closeModal={() => setIsStartMigrationModalOpen(false)}
        groupId={groupId}
        projectId={modalProjectId}
      />

      <ColorMigrationsModal
        isOpen={isMigrationsModalOpen}
        closeModal={() => setIsMigrationsModalOpen(false)}
      />

      <GetMigrationDataModal
        isOpen={isGetMigrationDataModalOpen}
        closeModal={() => setIsGetMigrationDataModalOpen(false)}
      />
    </>
  )
}

export default AdminTab
