import { format } from 'date-fns'
import { useIntl } from 'react-intl'
import styled from 'styled-components'

import Flex from 'components/Flex'

import bgSrc from './images/you-made-it.jpg'

const Container = styled(Flex).attrs({
  justifyContent: 'flex-end',
  direction: 'column',
  rowGap: 8
})`
  height: 375px;
  padding: 24px;
  background: url('${bgSrc}') no-repeat center / cover;
`

const Title = styled.div`
  font-size: 28px;
  font-weight: 600;
  color: ${({ theme }) => theme.color.brand_01};
`

const Text = styled.div`
  font-size: 14px;
  line-height: 18px;
  color: ${({ theme }) => theme.color.base.c0};
`

type YouMadeItProps = {
  deliveryDate?: number | null
}
const YouMadeIt = ({ deliveryDate }: YouMadeItProps) => {
  const { formatMessage } = useIntl()
  const date =
    deliveryDate && format(new Date(deliveryDate), 'dd.MM.yyyy')

  return (
    <Container>
      <Title>
        {formatMessage(
          { id: 'Project.export.you.made.it.title' },
          { br: <br /> }
        )}
      </Title>
      <Text>
        {formatMessage({ id: 'Project.export.you.made.it.text' })}
        {date &&
          formatMessage(
            { id: 'Project.export.you.made.it.delivery.date' },
            { date }
          )}
      </Text>
    </Container>
  )
}

export default YouMadeIt
