type Angles =
  | 'top'
  | 'bottom'
  | 'right'
  | 'left'
  | 'top-left'
  | 'top-right'
  | 'bottom-left'
  | 'bottom-right'

export const borderRadius = (
  radius: number,
  angles?: Angles[] | Angles
) => {
  if (!angles) {
    return `border-radius: ${radius}px`
  }

  const values = [0, 0, 0, 0]

  const MAP = {
    top: [0, 1],
    bottom: [1, 2],
    left: [0, 3],
    right: [1, 2],
    'top-left': [0],
    'top-right': [1],
    'bottom-right': [2],
    'bottom-left': [3]
  }

  const anglesArray = Array.isArray(angles) ? angles : [angles]

  anglesArray.forEach(angle => {
    const targetValues = MAP[angle]

    targetValues.forEach(value => {
      values[value] = radius
    })
  })

  return `border-radius: ${values.join('px ')}px;`
}
