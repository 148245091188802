import styled from 'styled-components'

import Flex from 'shared/ui/Flex'

type CommonStepElementProps = {
  isActive: boolean
}

export const Circle = styled(Flex).attrs({
  alignItems: 'center',
  justifyContent: 'center'
})<CommonStepElementProps>`
  position: relative;
  z-index: 1;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: ${({ theme, isActive }) =>
    isActive ? theme.color.base.c2 : theme.color.base.c0};
  border: 1px solid
    ${({ theme, isActive }) =>
      isActive ? theme.color.base.c4 : theme.color.base.c2};
`

export const Connector = styled.div<CommonStepElementProps>`
  position: relative;
  top: 10px;
  width: 120px;
  height: 12px;
  margin-left: -4px;
  margin-right: -4px;
  background-color: ${({ theme, isActive }) =>
    isActive ? theme.color.brand_02 : theme.color.base.c2};
`

export const StepTitle = styled.div<CommonStepElementProps>`
  position: absolute;
  top: 28px;
  margin-top: 6px;
  font-size: 8px;
  font-weight: 600;
  color: ${({ theme, isActive }) =>
    isActive ? theme.color.brand_02 : theme.color.base.c4};
  white-space: nowrap;
`
