import { useOthersMapped, useSelf } from 'shared/context/project-room'
import { LiveUser } from 'shared/types/global'

/**
 * Returns the liveblocks live users.
 *
 * @remarks
 * This method requires the parent component to be wrapped with `PageRoomProvider`.
 *
 * @returns LiveUser[]
 *
 * @example
 * ```ts
 * export default () => {
 *   const { pathname } = useLocation()
 *   const projectId = decodeURIComponent(pathname).replace('/', '')
 *   return (
 *     <PageRoomProvider projectId={projectId}>
 *       <YourComponent />
 *     </PageRoomProvider>
 *   )
 * }
 * ```
 */

export const useLiveUsers = () => {
  const currentUserInfo = useSelf(me => me.presence.info)
  const currentUserConnectionId = useSelf(me => me.connectionId)

  const othersMapped = useOthersMapped(other => ({
    info: other.presence.info,
    editingPageId: other.presence.editingPageId
  }))

  const liveUsers: LiveUser[] =
    currentUserInfo && currentUserConnectionId
      ? [
          {
            ...currentUserInfo,
            index: currentUserConnectionId
          },
          ...othersMapped
            .filter(other => !other[1].editingPageId)
            .map(other => ({
              ...other[1].info,
              index: other[0]
            }))
        ]
      : []

  return liveUsers
}
