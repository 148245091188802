import { useMutation } from '@apollo/client'
import { useRef, useState } from 'react'
import toast from 'react-hot-toast'
import styled from 'styled-components'

import Button, { ButtonIntents } from 'shared/ui/Button'
import FileUpload from 'shared/ui/FileUpload'
import Flex from 'shared/ui/Flex'
import Input from 'shared/ui/Input'
import IslandModal from 'shared/components/modals/IslandModal'
import { ADD_NEW_ASSET } from 'data/layoutcreator/mutations/admin'

const Label = styled.label`
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 8px;
`

type AddAssetModalProps = {
  isOpen: boolean
  closeModal: () => void
}

const DEFAULT_PATH = 'Creator Assets/'

const AddAssetModal: React.FC<AddAssetModalProps> = ({
  isOpen,
  closeModal
}) => {
  const [id, setId] = useState('')
  const [path, setPath] = useState(DEFAULT_PATH)
  const [name, setName] = useState('')
  const [file, setFile] = useState<File | null>(null)
  const fileInputRef = useRef<HTMLInputElement>(null)

  const resetPath = () => setPath(DEFAULT_PATH)

  const [addNewAsset, { loading: isAddNewAssetLoading }] =
    useMutation(ADD_NEW_ASSET)

  const handleFileInputChange = (file: File) => {
    setFile(file)
  }

  const handleUploadFileClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click()
    }
  }

  const handleSubmit = async () => {
    const toastId = toast.loading('Uploading asset...')
    const requestId = id

    const { data, errors } = await addNewAsset({
      variables: {
        id,
        path,
        name: name || file!.name,
        file: file as File
      }
    })

    toast.dismiss(toastId)

    if (errors) {
      toast.error('Failed to upload asset')
      return
    }

    if (data?.addNewAsset === requestId) {
      toast.success('ID matched 👌')
    } else {
      toast('Asset added, but ID mismatched! 😬')
    }
  }

  return (
    <IslandModal
      isOpen={isOpen}
      closeModal={closeModal}
      width={1000}
      title="Upload Asset"
    >
      <Flex direction="column">
        <Flex direction="column" rowGap={16}>
          <Input value={id} onChange={setId} label="ID" />
          <Input
            value={path}
            onChange={setPath}
            label="Path"
            append={
              <Button
                intent={ButtonIntents.neutral}
                labelId="Admin.buttons.reset"
                borderRadius={100}
                padding={[8, 16]}
                font-size={11}
                onClick={resetPath}
              />
            }
          />
          <Input
            value={name}
            onChange={setName}
            label="Name (isn't necessary, file's name will be used by
              default)"
          />

          <Flex direction="column">
            <Label>File:</Label>
            <Flex justifyContent="space-between" alignItems="center">
              <span>{file?.name}</span>

              <Button
                intent={ButtonIntents.neutral}
                labelId="Admin.buttons.uploadFile"
                borderRadius={100}
                padding={[10, 20]}
                font-size={12}
                onClick={handleUploadFileClick}
              />
            </Flex>
          </Flex>
        </Flex>

        <Flex marginTop={46}>
          <Button
            intent={ButtonIntents.dark}
            labelId="Admin.buttons.submit"
            borderRadius={100}
            isFullWidth
            isDisabled={!path || !file || isAddNewAssetLoading}
            onClick={handleSubmit}
          />
        </Flex>
      </Flex>

      <FileUpload
        ref={fileInputRef}
        onSelect={handleFileInputChange}
      />
    </IslandModal>
  )
}

export default AddAssetModal
