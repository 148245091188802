import BasicService from './BasicService'

export default class ChiliText extends BasicService {
  private static instance: ChiliText

  static getInstance(): ChiliText {
    if (!ChiliText.instance) {
      ChiliText.instance = new ChiliText()
    }

    return ChiliText.instance
  }

  private generateTextFlow(text: string) {
    return `
      <TextFlow xmlns="http://ns.adobe.com/textLayout/2008">
        <p><span>${text}</span></p>
      </TextFlow>
    `
  }

  async setText(text: string, selector?: string) {
    await this.publisherConnector?.executeFunction(
      selector || BasicService.selectedFrame,
      'ImportTextFlow',
      this.generateTextFlow(text)
    )
  }

  async setFontSize(newSize: number, selector?: string) {
    await this.publisherConnector?.executeFunction(
      `${
        selector || BasicService.selectedFrame
      }.textFlow.composer.selection`,
      'SelectAll'
    )
    await this.publisherConnector?.setProperty(
      `document.selectedText.textFormat`,
      'fontSize',
      newSize
    )
  }

  async getTextFlow(selector?: string): Promise<string | null> {
    const composer = (await this.publisherConnector?.getObject(
      `${
        selector || BasicService.selectedFrame
      }.textFlow.composer`
    )) as any

    return composer?.textFlow || null
  }
}
