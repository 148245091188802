import { gql } from '@apollo/client'

export const DUPLICATE_PAGE = gql`
  mutation duplicatePage(
    $pageName: String!
    $itemId: String!
    $groupId: String!
    $projectId: String!
  ) {
    duplicatePage(
      pageName: $pageName
      itemId: $itemId
      groupId: $groupId
      projectId: $projectId
    )
  }
`

export const SAVE_PAGE_PREVIEW = gql`
  mutation savePagePreview(
    $groupId: String!
    $projectId: String!
    $pageId: String!
    $image: Upload!
  ) {
    savePagePreview(
      groupId: $groupId
      projectId: $projectId
      pageId: $pageId
      image: $image
    )
  }
`
