import styled from 'styled-components'

import avatarPlaceholderSrc from 'assets/images/avatar-placeholder.png'
import { AuthUserGroupFragment } from 'types/gradoo/graphql'

const Avatar = styled.img`
  width: 32px;
  border-radius: 16px;
`

type UserProps = {
  authUserGroup: AuthUserGroupFragment
}

export default function User({ authUserGroup }: UserProps) {
  return (
    <Avatar
      data-testid="userAvatar"
      src={authUserGroup.avatar || avatarPlaceholderSrc}
      alt="User"
    />
  )
}
