import { Servers } from '../../types'

type RequestArguments = {
  body: string
  token?: string
}

type UniversalRequestArguments = {
  type: Servers
} & RequestArguments

const makeRequest = async ({
  type,
  body,
  token
}: UniversalRequestArguments) => {
  const authHeaderPrefix = type === Servers.gradoo ? 'JWT' : 'Bearer'
  const url =
    type === Servers.gradoo
      ? (process.env.REACT_APP_GRADOO_GRAPHQL_URL as string)
      : (process.env.REACT_APP_GRAPHQL_URL as string)

  const response = await fetch(url, {
    headers: {
      'content-type': 'application/json',
      'authorization': token ? `${authHeaderPrefix} ${token}` : ''
    },
    method: 'POST',
    body: JSON.stringify({ query: body })
  })
  const responseJson = await response.json()

  return responseJson
}

export const makeLayoutCreatorRequest = ({
  body,
  token
}: RequestArguments) =>
  makeRequest({
    type: Servers.layoutCreator,
    body,
    token
  })

export const makeGradooRequest = ({
  body,
  token
}: RequestArguments) =>
  makeRequest({
    type: Servers.gradoo,
    body,
    token
  })
