import React from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components'

import Flex from 'shared/ui/Flex'

import { ReactComponent as ClockIcon } from './icons/clock.svg'
import { ReactComponent as PaperIcon } from './icons/paper.svg'
import {
  Block,
  BlockTypes,
  ImageBlock,
  ParagraphBlock,
  Preword,
  SectionBlock
} from './types'

const Container = styled.div``

const Title = styled.h1`
  font-size: 28px;
  font-weight: 600;
  margin-top: 0;
  margin-bottom: 16px;
`

const StyledParagraph = styled.p`
  margin-top: 0;
  margin-bottom: 16px;
`
const Paragraph: React.FC<ParagraphBlock> = ({ text }) => (
  <StyledParagraph>{text}</StyledParagraph>
)

const StyledImage = styled.img`
  margin-top: 0;
  margin-bottom: 16px;
  width: 100%;
`
const Image: React.FC<ImageBlock> = ({ url }) => (
  <StyledImage src={url} />
)

const SecondLevelTitle = styled.h2`
  margin-top: 16px;
  margin-bottom: 8px;
  font-size: 16px;
  font-weight: 600;
`
const Section: React.FC<SectionBlock> = ({ title, blocks }) => {
  return (
    <>
      <SecondLevelTitle>{title}</SecondLevelTitle>
      {renderBlocks(blocks)}
    </>
  )
}

const Divider = styled.div`
  height: 1px;
  width: 100%;
  background: ${({ theme }) => theme.color.base.c3};
  margin-top: 32px;
  margin-bottom: 16px;
`

const MetaRow = styled(Flex)`
  gap: 8px;
  align-items: center;
`

const MetaText = styled.span`
  color: ${({ theme }) => theme.color.base.c5};
  font-size: 12px;
`

const randomImageUrl =
  'https://cdn.pixabay.com/photo/2017/11/09/21/41/cat-2934720_1280.jpg'

const preword: Preword = {
  title: 'Preword title',
  updatedAt: new Date(),
  wordsCount: 257,
  blocks: [
    {
      type: BlockTypes.paragraph,
      text: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.'
    },
    {
      type: BlockTypes.image,
      url: randomImageUrl
    },
    {
      type: BlockTypes.section,
      title: 'Sub Headline',
      blocks: [
        {
          type: BlockTypes.paragraph,
          text: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat.'
        }
      ]
    }
  ]
}

const renderBlocks = (blocks: Block[]) => {
  return (
    <>
      {blocks.map((block, i) =>
        React.createElement(BLOCK_COMPONENTS_MAP[block.type], {
          key: i,
          ...block
        })
      )}
    </>
  )
}

const BLOCK_COMPONENTS_MAP: Record<BlockTypes, React.FC<any>> = {
  [BlockTypes.paragraph]: Paragraph,
  [BlockTypes.image]: Image,
  [BlockTypes.section]: Section
}

const PrewordPanel: React.FC = () => {
  const { formatMessage } = useIntl()

  const formatDate = (date: Date) => {
    const day = date.getDate()
    const month = date.getMonth()
    const monthName = formatMessage({ id: `Date.month.${month}` })
    const hours = date.getHours()
    const minutes = date.getMinutes()

    return `${day} ${monthName} ${hours}:${minutes}`
  }

  return (
    <Container>
      <Title>{preword.title}</Title>

      {renderBlocks(preword.blocks)}

      <Divider />

      <Flex direction="column" rowGap={8}>
        <MetaRow>
          <ClockIcon />
          <MetaText>
            Last time edited: {formatDate(preword.updatedAt)}
          </MetaText>
        </MetaRow>

        <MetaRow>
          <PaperIcon />
          <MetaText>
            {preword.wordsCount}{' '}
            {formatMessage({
              id: 'Panel.contentSets.item.count.preword'
            })}
          </MetaText>
        </MetaRow>
      </Flex>
    </Container>
  )
}

export default PrewordPanel
