/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import * as types from './graphql'

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
  '\n  fragment AuthUserGroup on UserGroupNode {\n    ...UserGroup\n    group {\n      ...Group\n    }\n  }\n':
    types.AuthUserGroupFragmentDoc,
  '\n  fragment CollagesPhoto on CollagesPhotoNode {\n    id\n    photo\n    collagesPhotoLikesCount\n    created\n  }\n':
    types.CollagesPhotoFragmentDoc,
  '\n  fragment Group on GroupNode {\n    id\n    name\n    usersCount\n    year\n    institute {\n      internalId\n    }\n  }\n':
    types.GroupFragmentDoc,
  '\n  fragment Module on ModuleNode {\n    id\n    name\n    image\n    type\n  }\n':
    types.ModuleFragmentDoc,
  '\n  fragment ModuleInstanceResult on ModuleInstanceNode {\n    id\n    isActive\n    dueDate\n    module {\n      ...Module\n    }\n    prewordInstance {\n      wordCount\n    }\n    profilePageSetup {\n      usersDelivered\n      maxChars\n    }\n    rankingsSetup {\n      rankingsCount\n    }\n    collagesSetup {\n      photosCount\n    }\n    reportsSetup {\n      reportsCount\n    }\n    quotesSetup {\n      quotesCount\n    }\n    customPagesInstances {\n      edges {\n        node {\n          id\n        }\n      }\n    }\n  }\n':
    types.ModuleInstanceResultFragmentDoc,
  '\n  fragment ModuleInstanceUserSummaryResult on ModuleInstanceNode {\n    ...ModuleInstanceResult\n    profilePageSetup {\n      usersSummary {\n        ...UserSummary\n      }\n      usersDelivered\n    }\n  }\n':
    types.ModuleInstanceUserSummaryResultFragmentDoc,
  '\n  fragment UserSummary on ProfilePageUserSummaryNode {\n    userGroup {\n      ...UserGroup\n    }\n    questionsAnswered\n    imagesUploaded\n  }\n':
    types.UserSummaryFragmentDoc,
  '\n  fragment ProfilePagePhotoCategory on ProfilePagePhotoCategoryNode {\n    id\n    name\n    profilePagePhotos(userGroup: $ppqUserGroup) {\n      edges {\n        node {\n          id\n          image\n        }\n      }\n    }\n  }\n':
    types.ProfilePagePhotoCategoryFragmentDoc,
  '\n  fragment ProfilePageQuestion on ProfilePageQuestionNode {\n    id\n    text\n    profilePageAnswers(userGroup: $ppqUserGroup) {\n      edges {\n        node {\n          id\n          text\n          profilePageQuestionOption {\n            id\n            text\n          }\n        }\n      }\n    }\n  }\n':
    types.ProfilePageQuestionFragmentDoc,
  '\n  fragment ProfilePageComment on ProfilePageCommentNode {\n    id\n    text\n    comentatorUserGroup {\n      ...UserGroup\n    }\n    profileUserGroup {\n      ...UserGroup\n    }\n    created\n  }\n':
    types.ProfilePageCommentFragmentDoc,
  '\n  fragment Quote on QuotesInstanceNode {\n    id\n    quote\n    userGroup {\n      ...UserGroup\n    }\n    quotesInstanceLikesCount\n    created\n  }\n':
    types.QuoteFragmentDoc,
  '\n  fragment RankingQuestionResult on RankingsQuestionResultNode {\n    votee1UserGroup {\n      ...UserGroup\n    }\n    votee2UserGroup {\n      ...UserGroup\n    }\n    voteeListOption {\n      id\n      text\n    }\n    percentage\n  }\n':
    types.RankingQuestionResultFragmentDoc,
  '\n  fragment RankingsQuestion on RankingsQuestionNode {\n    id\n    question\n    results {\n      alreadyVoted\n      possibleVoters\n      options {\n        ...RankingQuestionResult\n      }\n    }\n  }\n':
    types.RankingsQuestionFragmentDoc,
  '\n  fragment ReportsInstance on ReportsInstanceNode {\n    id\n    title\n  }\n':
    types.ReportsInstanceFragmentDoc,
  '\n  fragment ReportsInstanceDetail on ReportsInstanceNode {\n    id\n    title\n    text\n    maxChars\n    modified\n  }\n':
    types.ReportsInstanceDetailFragmentDoc,
  '\n  fragment User on UserNode {\n    id\n    firstName\n    lastName\n    email\n  }\n':
    types.UserFragmentDoc,
  '\n  fragment UserGroup on UserGroupNode {\n    id\n    avatar\n    defaultAvatar {\n      avatar\n    }\n    user {\n      ...User\n    }\n  }\n':
    types.UserGroupFragmentDoc,
  '\n  mutation AddPhotoToCollagesAlbum(\n    $input: AddPhotoToCollagesAlbumInput!\n  ) {\n    addPhotoToCollagesAlbum(input: $input) {\n      clientMutationId,\n      photo {\n        ...CollagesPhoto\n      }\n    }\n  }\n':
    types.AddPhotoToCollagesAlbumDocument,
  '\n  mutation RemovePhotoFromCollagesAlbum(\n    $input: RemovePhotoFromCollagesAlbumInput!\n  ) {\n    removePhotoFromCollagesAlbum(input: $input) {\n      success\n    }\n  }\n':
    types.RemovePhotoFromCollagesAlbumDocument,
  '\n  query Me {\n    me {\n      id\n      email\n      created\n      phone\n      phoneCountryCode\n      firstName\n      lastName\n      avatar\n    }\n  }\n':
    types.MeDocument,
  '\n  query GroupUsers($user: ID, $status: GroupsUserGroupStatusChoices) {\n    userGroups(user: $user, status: $status) {\n      edges {\n        node {\n          ...AuthUserGroup\n        }\n      }\n    }\n  }\n':
    types.GroupUsersDocument,
  '\n  fragment CollagesAlbum on CollagesAlbumNode {\n    id\n    name\n    photosCount\n    collagesPhotos(first: $cpFirst) {\n      edges {\n        node {\n          ...CollagesPhoto\n        }\n      }\n    }\n  }\n':
    types.CollagesAlbumFragmentDoc,
  '\n  query CollagesAlbums(\n    $after: String\n    $first: Int\n    $moduleInstance: ID\n    $cpFirst: Int\n  ) {\n    collagesAlbums(\n      after: $after\n      first: $first\n      moduleInstance: $moduleInstance\n    ) {\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n      edges {\n        node {\n          ...CollagesAlbum\n        }\n      }\n    }\n  }\n':
    types.CollagesAlbumsDocument,
  '\n  query CollagesPhotos(\n    $after: String\n    $first: Int\n    $collagesAlbum: ID\n  ) {\n    collagesPhotos(\n      after: $after\n      first: $first\n      collagesAlbum: $collagesAlbum\n    ) {\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n      edges {\n        node {\n          ...CollagesPhoto\n        }\n      }\n    }\n  }\n':
    types.CollagesPhotosDocument,
  '\n  query CustomPagesInstances(\n    $after: String\n    $first: Int!\n    $userGroup: String!\n    $moduleInstance: ID\n  ) {\n    customPagesInstances(\n      after: $after\n      first: $first\n      userGroup: $userGroup\n      moduleInstance: $moduleInstance\n    ) {\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n      edges {\n        node {\n          id\n          title\n        }\n        cursor\n      }\n    }\n  }\n':
    types.CustomPagesInstancesDocument,
  '\n  query CustomPagesInstancesResult(\n    $after: String\n    $first: Int!\n    $moduleInstance: String!\n  ) {\n    customPagesInstancesResult(\n      after: $after\n      first: $first\n      moduleInstance: $moduleInstance\n    ) {\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n      edges {\n        node {\n          id\n          title\n        }\n        cursor\n      }\n    }\n  }\n':
    types.CustomPagesInstancesResultDocument,
  '\n  query CustomPagesInstance($id: ID!) {\n    customPagesInstance(id: $id) {\n      created\n      modified\n      id\n      pageFormat\n      title\n      page\n      pageType\n      pageQuality\n    }\n  }\n':
    types.CustomPagesInstanceDocument,
  '\n  query ModuleInstanceResults(\n    $after: String\n    $first: Int\n    $group: ID\n  ) {\n    moduleInstances(after: $after, first: $first, group: $group) {\n      edges {\n        node {\n          ...ModuleInstanceResult\n        }\n      }\n    }\n  }\n':
    types.ModuleInstanceResultsDocument,
  '\n  query ModuleInstanceUserSummaryResult($id: ID!) {\n    moduleInstance(id: $id) {\n      ...ModuleInstanceUserSummaryResult\n    }\n  }\n':
    types.ModuleInstanceUserSummaryResultDocument,
  '\n  query ProfilePagePhotoCategories(\n    $after: String\n    $first: Int\n    $moduleInstance: ID\n    $ppqUserGroup: ID\n  ) {\n    profilePagePhotoCategories(\n      after: $after\n      first: $first\n      moduleInstance: $moduleInstance\n    ) {\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n      edges {\n        node {\n          ...ProfilePagePhotoCategory\n        }\n      }\n    }\n  }\n':
    types.ProfilePagePhotoCategoriesDocument,
  '\n  query ProfilePageQuestions(\n    $after: String\n    $first: Int\n    $moduleInstance: ID\n    $ppqUserGroup: ID\n  ) {\n    profilePageQuestions(\n      after: $after\n      first: $first\n      moduleInstance: $moduleInstance\n    ) {\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n      edges {\n        node {\n          ...ProfilePageQuestion\n        }\n      }\n    }\n  }\n':
    types.ProfilePageQuestionsDocument,
  '\n  query ProfilePageComments(\n    $after: String\n    $first: Int\n    $profileUserGroup: ID\n    $comentatorUserGroup: ID\n    $moduleInstance: ID\n  ) {\n    profilePageComments(\n      after: $after\n      first: $first\n      profileUserGroup: $profileUserGroup\n      comentatorUserGroup: $comentatorUserGroup\n      moduleInstance: $moduleInstance\n    ) {\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n      edges {\n        node {\n          ...ProfilePageComment\n        }\n      }\n    }\n  }\n':
    types.ProfilePageCommentsDocument,
  '\n  query QuotesInstances(\n    $after: String\n    $first: Int\n    $moduleInstance: ID\n    $orderBy: String\n  ) {\n    quotesInstances(\n      after: $after\n      first: $first\n      moduleInstance: $moduleInstance\n      orderBy: $orderBy\n    ) {\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n      edges {\n        node {\n          ...Quote\n        }\n      }\n    }\n  }\n':
    types.QuotesInstancesDocument,
  '\n  query RankingsQuestions(\n    $after: String\n    $first: Int\n    $moduleInstance: ID\n  ) {\n    rankingsQuestions(\n      after: $after\n      first: $first\n      moduleInstance: $moduleInstance\n    ) {\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n      edges {\n        node {\n          ...RankingsQuestion\n        }\n      }\n    }\n  }\n':
    types.RankingsQuestionsDocument,
  '\n  query ReportsInstances(\n    $after: String\n    $first: Int\n    $moduleInstance: ID\n  ) {\n    reportsInstances(\n      after: $after\n      first: $first\n      moduleInstance: $moduleInstance\n    ) {\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n      edges {\n        node {\n          ...ReportsInstance\n        }\n      }\n    }\n  }\n':
    types.ReportsInstancesDocument,
  '\n  query ReportsInstance($id: ID!) {\n    reportsInstance(id: $id) {\n      ...ReportsInstanceDetail\n    }\n  }\n':
    types.ReportsInstanceDocument,
  '\n  query UserGroup($id: ID!) {\n    userGroup(id: $id) {\n      ...UserGroup\n    }\n  }\n':
    types.UserGroupDocument
}

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = gql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function gql(source: string): unknown

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment AuthUserGroup on UserGroupNode {\n    ...UserGroup\n    group {\n      ...Group\n    }\n  }\n'
): typeof documents['\n  fragment AuthUserGroup on UserGroupNode {\n    ...UserGroup\n    group {\n      ...Group\n    }\n  }\n']
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment CollagesPhoto on CollagesPhotoNode {\n    id\n    photo\n    collagesPhotoLikesCount\n    created\n  }\n'
): typeof documents['\n  fragment CollagesPhoto on CollagesPhotoNode {\n    id\n    photo\n    collagesPhotoLikesCount\n    created\n  }\n']
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment Group on GroupNode {\n    id\n    name\n    usersCount\n    year\n    institute {\n      internalId\n    }\n  }\n'
): typeof documents['\n  fragment Group on GroupNode {\n    id\n    name\n    usersCount\n    year\n    institute {\n      internalId\n    }\n  }\n']
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment Module on ModuleNode {\n    id\n    name\n    image\n    type\n  }\n'
): typeof documents['\n  fragment Module on ModuleNode {\n    id\n    name\n    image\n    type\n  }\n']
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment ModuleInstanceResult on ModuleInstanceNode {\n    id\n    isActive\n    dueDate\n    module {\n      ...Module\n    }\n    prewordInstance {\n      wordCount\n    }\n    profilePageSetup {\n      usersDelivered\n      maxChars\n    }\n    rankingsSetup {\n      rankingsCount\n    }\n    collagesSetup {\n      photosCount\n    }\n    reportsSetup {\n      reportsCount\n    }\n    quotesSetup {\n      quotesCount\n    }\n    customPagesInstances {\n      edges {\n        node {\n          id\n        }\n      }\n    }\n  }\n'
): typeof documents['\n  fragment ModuleInstanceResult on ModuleInstanceNode {\n    id\n    isActive\n    dueDate\n    module {\n      ...Module\n    }\n    prewordInstance {\n      wordCount\n    }\n    profilePageSetup {\n      usersDelivered\n      maxChars\n    }\n    rankingsSetup {\n      rankingsCount\n    }\n    collagesSetup {\n      photosCount\n    }\n    reportsSetup {\n      reportsCount\n    }\n    quotesSetup {\n      quotesCount\n    }\n    customPagesInstances {\n      edges {\n        node {\n          id\n        }\n      }\n    }\n  }\n']
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment ModuleInstanceUserSummaryResult on ModuleInstanceNode {\n    ...ModuleInstanceResult\n    profilePageSetup {\n      usersSummary {\n        ...UserSummary\n      }\n      usersDelivered\n    }\n  }\n'
): typeof documents['\n  fragment ModuleInstanceUserSummaryResult on ModuleInstanceNode {\n    ...ModuleInstanceResult\n    profilePageSetup {\n      usersSummary {\n        ...UserSummary\n      }\n      usersDelivered\n    }\n  }\n']
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment UserSummary on ProfilePageUserSummaryNode {\n    userGroup {\n      ...UserGroup\n    }\n    questionsAnswered\n    imagesUploaded\n  }\n'
): typeof documents['\n  fragment UserSummary on ProfilePageUserSummaryNode {\n    userGroup {\n      ...UserGroup\n    }\n    questionsAnswered\n    imagesUploaded\n  }\n']
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment ProfilePagePhotoCategory on ProfilePagePhotoCategoryNode {\n    id\n    name\n    profilePagePhotos(userGroup: $ppqUserGroup) {\n      edges {\n        node {\n          id\n          image\n        }\n      }\n    }\n  }\n'
): typeof documents['\n  fragment ProfilePagePhotoCategory on ProfilePagePhotoCategoryNode {\n    id\n    name\n    profilePagePhotos(userGroup: $ppqUserGroup) {\n      edges {\n        node {\n          id\n          image\n        }\n      }\n    }\n  }\n']
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment ProfilePageQuestion on ProfilePageQuestionNode {\n    id\n    text\n    profilePageAnswers(userGroup: $ppqUserGroup) {\n      edges {\n        node {\n          id\n          text\n          profilePageQuestionOption {\n            id\n            text\n          }\n        }\n      }\n    }\n  }\n'
): typeof documents['\n  fragment ProfilePageQuestion on ProfilePageQuestionNode {\n    id\n    text\n    profilePageAnswers(userGroup: $ppqUserGroup) {\n      edges {\n        node {\n          id\n          text\n          profilePageQuestionOption {\n            id\n            text\n          }\n        }\n      }\n    }\n  }\n']
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment ProfilePageComment on ProfilePageCommentNode {\n    id\n    text\n    comentatorUserGroup {\n      ...UserGroup\n    }\n    profileUserGroup {\n      ...UserGroup\n    }\n    created\n  }\n'
): typeof documents['\n  fragment ProfilePageComment on ProfilePageCommentNode {\n    id\n    text\n    comentatorUserGroup {\n      ...UserGroup\n    }\n    profileUserGroup {\n      ...UserGroup\n    }\n    created\n  }\n']
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment Quote on QuotesInstanceNode {\n    id\n    quote\n    userGroup {\n      ...UserGroup\n    }\n    quotesInstanceLikesCount\n    created\n  }\n'
): typeof documents['\n  fragment Quote on QuotesInstanceNode {\n    id\n    quote\n    userGroup {\n      ...UserGroup\n    }\n    quotesInstanceLikesCount\n    created\n  }\n']
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment RankingQuestionResult on RankingsQuestionResultNode {\n    votee1UserGroup {\n      ...UserGroup\n    }\n    votee2UserGroup {\n      ...UserGroup\n    }\n    voteeListOption {\n      id\n      text\n    }\n    percentage\n  }\n'
): typeof documents['\n  fragment RankingQuestionResult on RankingsQuestionResultNode {\n    votee1UserGroup {\n      ...UserGroup\n    }\n    votee2UserGroup {\n      ...UserGroup\n    }\n    voteeListOption {\n      id\n      text\n    }\n    percentage\n  }\n']
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment RankingsQuestion on RankingsQuestionNode {\n    id\n    question\n    results {\n      alreadyVoted\n      possibleVoters\n      options {\n        ...RankingQuestionResult\n      }\n    }\n  }\n'
): typeof documents['\n  fragment RankingsQuestion on RankingsQuestionNode {\n    id\n    question\n    results {\n      alreadyVoted\n      possibleVoters\n      options {\n        ...RankingQuestionResult\n      }\n    }\n  }\n']
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment ReportsInstance on ReportsInstanceNode {\n    id\n    title\n  }\n'
): typeof documents['\n  fragment ReportsInstance on ReportsInstanceNode {\n    id\n    title\n  }\n']
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment ReportsInstanceDetail on ReportsInstanceNode {\n    id\n    title\n    text\n    maxChars\n    modified\n  }\n'
): typeof documents['\n  fragment ReportsInstanceDetail on ReportsInstanceNode {\n    id\n    title\n    text\n    maxChars\n    modified\n  }\n']
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment User on UserNode {\n    id\n    firstName\n    lastName\n    email\n  }\n'
): typeof documents['\n  fragment User on UserNode {\n    id\n    firstName\n    lastName\n    email\n  }\n']
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment UserGroup on UserGroupNode {\n    id\n    avatar\n    defaultAvatar {\n      avatar\n    }\n    user {\n      ...User\n    }\n  }\n'
): typeof documents['\n  fragment UserGroup on UserGroupNode {\n    id\n    avatar\n    defaultAvatar {\n      avatar\n    }\n    user {\n      ...User\n    }\n  }\n']
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation AddPhotoToCollagesAlbum(\n    $input: AddPhotoToCollagesAlbumInput!\n  ) {\n    addPhotoToCollagesAlbum(input: $input) {\n      clientMutationId,\n      photo {\n        ...CollagesPhoto\n      }\n    }\n  }\n'
): typeof documents['\n  mutation AddPhotoToCollagesAlbum(\n    $input: AddPhotoToCollagesAlbumInput!\n  ) {\n    addPhotoToCollagesAlbum(input: $input) {\n      clientMutationId,\n      photo {\n        ...CollagesPhoto\n      }\n    }\n  }\n']
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation RemovePhotoFromCollagesAlbum(\n    $input: RemovePhotoFromCollagesAlbumInput!\n  ) {\n    removePhotoFromCollagesAlbum(input: $input) {\n      success\n    }\n  }\n'
): typeof documents['\n  mutation RemovePhotoFromCollagesAlbum(\n    $input: RemovePhotoFromCollagesAlbumInput!\n  ) {\n    removePhotoFromCollagesAlbum(input: $input) {\n      success\n    }\n  }\n']
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query Me {\n    me {\n      id\n      email\n      created\n      phone\n      phoneCountryCode\n      firstName\n      lastName\n      avatar\n    }\n  }\n'
): typeof documents['\n  query Me {\n    me {\n      id\n      email\n      created\n      phone\n      phoneCountryCode\n      firstName\n      lastName\n      avatar\n    }\n  }\n']
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query GroupUsers($user: ID, $status: GroupsUserGroupStatusChoices) {\n    userGroups(user: $user, status: $status) {\n      edges {\n        node {\n          ...AuthUserGroup\n        }\n      }\n    }\n  }\n'
): typeof documents['\n  query GroupUsers($user: ID, $status: GroupsUserGroupStatusChoices) {\n    userGroups(user: $user, status: $status) {\n      edges {\n        node {\n          ...AuthUserGroup\n        }\n      }\n    }\n  }\n']
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment CollagesAlbum on CollagesAlbumNode {\n    id\n    name\n    photosCount\n    collagesPhotos(first: $cpFirst) {\n      edges {\n        node {\n          ...CollagesPhoto\n        }\n      }\n    }\n  }\n'
): typeof documents['\n  fragment CollagesAlbum on CollagesAlbumNode {\n    id\n    name\n    photosCount\n    collagesPhotos(first: $cpFirst) {\n      edges {\n        node {\n          ...CollagesPhoto\n        }\n      }\n    }\n  }\n']
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query CollagesAlbums(\n    $after: String\n    $first: Int\n    $moduleInstance: ID\n    $cpFirst: Int\n  ) {\n    collagesAlbums(\n      after: $after\n      first: $first\n      moduleInstance: $moduleInstance\n    ) {\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n      edges {\n        node {\n          ...CollagesAlbum\n        }\n      }\n    }\n  }\n'
): typeof documents['\n  query CollagesAlbums(\n    $after: String\n    $first: Int\n    $moduleInstance: ID\n    $cpFirst: Int\n  ) {\n    collagesAlbums(\n      after: $after\n      first: $first\n      moduleInstance: $moduleInstance\n    ) {\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n      edges {\n        node {\n          ...CollagesAlbum\n        }\n      }\n    }\n  }\n']
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query CollagesPhotos(\n    $after: String\n    $first: Int\n    $collagesAlbum: ID\n  ) {\n    collagesPhotos(\n      after: $after\n      first: $first\n      collagesAlbum: $collagesAlbum\n    ) {\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n      edges {\n        node {\n          ...CollagesPhoto\n        }\n      }\n    }\n  }\n'
): typeof documents['\n  query CollagesPhotos(\n    $after: String\n    $first: Int\n    $collagesAlbum: ID\n  ) {\n    collagesPhotos(\n      after: $after\n      first: $first\n      collagesAlbum: $collagesAlbum\n    ) {\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n      edges {\n        node {\n          ...CollagesPhoto\n        }\n      }\n    }\n  }\n']
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query CustomPagesInstances(\n    $after: String\n    $first: Int!\n    $userGroup: String!\n    $moduleInstance: ID\n  ) {\n    customPagesInstances(\n      after: $after\n      first: $first\n      userGroup: $userGroup\n      moduleInstance: $moduleInstance\n    ) {\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n      edges {\n        node {\n          id\n          title\n        }\n        cursor\n      }\n    }\n  }\n'
): typeof documents['\n  query CustomPagesInstances(\n    $after: String\n    $first: Int!\n    $userGroup: String!\n    $moduleInstance: ID\n  ) {\n    customPagesInstances(\n      after: $after\n      first: $first\n      userGroup: $userGroup\n      moduleInstance: $moduleInstance\n    ) {\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n      edges {\n        node {\n          id\n          title\n        }\n        cursor\n      }\n    }\n  }\n']
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query CustomPagesInstancesResult(\n    $after: String\n    $first: Int!\n    $moduleInstance: String!\n  ) {\n    customPagesInstancesResult(\n      after: $after\n      first: $first\n      moduleInstance: $moduleInstance\n    ) {\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n      edges {\n        node {\n          id\n          title\n        }\n        cursor\n      }\n    }\n  }\n'
): typeof documents['\n  query CustomPagesInstancesResult(\n    $after: String\n    $first: Int!\n    $moduleInstance: String!\n  ) {\n    customPagesInstancesResult(\n      after: $after\n      first: $first\n      moduleInstance: $moduleInstance\n    ) {\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n      edges {\n        node {\n          id\n          title\n        }\n        cursor\n      }\n    }\n  }\n']
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query CustomPagesInstance($id: ID!) {\n    customPagesInstance(id: $id) {\n      created\n      modified\n      id\n      pageFormat\n      title\n      page\n      pageType\n      pageQuality\n    }\n  }\n'
): typeof documents['\n  query CustomPagesInstance($id: ID!) {\n    customPagesInstance(id: $id) {\n      created\n      modified\n      id\n      pageFormat\n      title\n      page\n      pageType\n      pageQuality\n    }\n  }\n']
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query ModuleInstanceResults(\n    $after: String\n    $first: Int\n    $group: ID\n  ) {\n    moduleInstances(after: $after, first: $first, group: $group) {\n      edges {\n        node {\n          ...ModuleInstanceResult\n        }\n      }\n    }\n  }\n'
): typeof documents['\n  query ModuleInstanceResults(\n    $after: String\n    $first: Int\n    $group: ID\n  ) {\n    moduleInstances(after: $after, first: $first, group: $group) {\n      edges {\n        node {\n          ...ModuleInstanceResult\n        }\n      }\n    }\n  }\n']
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query ModuleInstanceUserSummaryResult($id: ID!) {\n    moduleInstance(id: $id) {\n      ...ModuleInstanceUserSummaryResult\n    }\n  }\n'
): typeof documents['\n  query ModuleInstanceUserSummaryResult($id: ID!) {\n    moduleInstance(id: $id) {\n      ...ModuleInstanceUserSummaryResult\n    }\n  }\n']
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query ProfilePagePhotoCategories(\n    $after: String\n    $first: Int\n    $moduleInstance: ID\n    $ppqUserGroup: ID\n  ) {\n    profilePagePhotoCategories(\n      after: $after\n      first: $first\n      moduleInstance: $moduleInstance\n    ) {\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n      edges {\n        node {\n          ...ProfilePagePhotoCategory\n        }\n      }\n    }\n  }\n'
): typeof documents['\n  query ProfilePagePhotoCategories(\n    $after: String\n    $first: Int\n    $moduleInstance: ID\n    $ppqUserGroup: ID\n  ) {\n    profilePagePhotoCategories(\n      after: $after\n      first: $first\n      moduleInstance: $moduleInstance\n    ) {\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n      edges {\n        node {\n          ...ProfilePagePhotoCategory\n        }\n      }\n    }\n  }\n']
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query ProfilePageQuestions(\n    $after: String\n    $first: Int\n    $moduleInstance: ID\n    $ppqUserGroup: ID\n  ) {\n    profilePageQuestions(\n      after: $after\n      first: $first\n      moduleInstance: $moduleInstance\n    ) {\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n      edges {\n        node {\n          ...ProfilePageQuestion\n        }\n      }\n    }\n  }\n'
): typeof documents['\n  query ProfilePageQuestions(\n    $after: String\n    $first: Int\n    $moduleInstance: ID\n    $ppqUserGroup: ID\n  ) {\n    profilePageQuestions(\n      after: $after\n      first: $first\n      moduleInstance: $moduleInstance\n    ) {\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n      edges {\n        node {\n          ...ProfilePageQuestion\n        }\n      }\n    }\n  }\n']
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query ProfilePageComments(\n    $after: String\n    $first: Int\n    $profileUserGroup: ID\n    $comentatorUserGroup: ID\n    $moduleInstance: ID\n  ) {\n    profilePageComments(\n      after: $after\n      first: $first\n      profileUserGroup: $profileUserGroup\n      comentatorUserGroup: $comentatorUserGroup\n      moduleInstance: $moduleInstance\n    ) {\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n      edges {\n        node {\n          ...ProfilePageComment\n        }\n      }\n    }\n  }\n'
): typeof documents['\n  query ProfilePageComments(\n    $after: String\n    $first: Int\n    $profileUserGroup: ID\n    $comentatorUserGroup: ID\n    $moduleInstance: ID\n  ) {\n    profilePageComments(\n      after: $after\n      first: $first\n      profileUserGroup: $profileUserGroup\n      comentatorUserGroup: $comentatorUserGroup\n      moduleInstance: $moduleInstance\n    ) {\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n      edges {\n        node {\n          ...ProfilePageComment\n        }\n      }\n    }\n  }\n']
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query QuotesInstances(\n    $after: String\n    $first: Int\n    $moduleInstance: ID\n    $orderBy: String\n  ) {\n    quotesInstances(\n      after: $after\n      first: $first\n      moduleInstance: $moduleInstance\n      orderBy: $orderBy\n    ) {\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n      edges {\n        node {\n          ...Quote\n        }\n      }\n    }\n  }\n'
): typeof documents['\n  query QuotesInstances(\n    $after: String\n    $first: Int\n    $moduleInstance: ID\n    $orderBy: String\n  ) {\n    quotesInstances(\n      after: $after\n      first: $first\n      moduleInstance: $moduleInstance\n      orderBy: $orderBy\n    ) {\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n      edges {\n        node {\n          ...Quote\n        }\n      }\n    }\n  }\n']
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query RankingsQuestions(\n    $after: String\n    $first: Int\n    $moduleInstance: ID\n  ) {\n    rankingsQuestions(\n      after: $after\n      first: $first\n      moduleInstance: $moduleInstance\n    ) {\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n      edges {\n        node {\n          ...RankingsQuestion\n        }\n      }\n    }\n  }\n'
): typeof documents['\n  query RankingsQuestions(\n    $after: String\n    $first: Int\n    $moduleInstance: ID\n  ) {\n    rankingsQuestions(\n      after: $after\n      first: $first\n      moduleInstance: $moduleInstance\n    ) {\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n      edges {\n        node {\n          ...RankingsQuestion\n        }\n      }\n    }\n  }\n']
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query ReportsInstances(\n    $after: String\n    $first: Int\n    $moduleInstance: ID\n  ) {\n    reportsInstances(\n      after: $after\n      first: $first\n      moduleInstance: $moduleInstance\n    ) {\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n      edges {\n        node {\n          ...ReportsInstance\n        }\n      }\n    }\n  }\n'
): typeof documents['\n  query ReportsInstances(\n    $after: String\n    $first: Int\n    $moduleInstance: ID\n  ) {\n    reportsInstances(\n      after: $after\n      first: $first\n      moduleInstance: $moduleInstance\n    ) {\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n      edges {\n        node {\n          ...ReportsInstance\n        }\n      }\n    }\n  }\n']
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query ReportsInstance($id: ID!) {\n    reportsInstance(id: $id) {\n      ...ReportsInstanceDetail\n    }\n  }\n'
): typeof documents['\n  query ReportsInstance($id: ID!) {\n    reportsInstance(id: $id) {\n      ...ReportsInstanceDetail\n    }\n  }\n']
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query UserGroup($id: ID!) {\n    userGroup(id: $id) {\n      ...UserGroup\n    }\n  }\n'
): typeof documents['\n  query UserGroup($id: ID!) {\n    userGroup(id: $id) {\n      ...UserGroup\n    }\n  }\n']

export function gql(source: string) {
  return (documents as any)[source] ?? {}
}

export type DocumentType<
  TDocumentNode extends DocumentNode<any, any>
> = TDocumentNode extends DocumentNode<infer TType, any>
  ? TType
  : never
