import { useQuery } from '@apollo/client'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'

import Flex from 'components/Flex'
import Head from 'components/Head'
import PageContainer from 'components/PageContainer'
import PageTitle from 'components/PageHeader'
import { GET_PROJECT_DETAILS } from 'data/layoutcreator/queries/admin'
import DefaultLayout from 'layout/Default'

import Tabs from './components/Tabs'
import ExportTab from './tabs/ExportTab'
import MigrationsTab from './tabs/MigrationsTab'
import PagesTab from './tabs/PagesTab'
import { TabProps } from './types'

const Info = styled.div`
  width: 800px;
  margin-top: 26px;
  padding: 32px;
  border-radius: 20px;
  background: ${({ theme }) => theme.color.base.c0};
`

const InfoName = styled.span`
  font-size: 26px;
  font-weight: 600;
`

const InfoProperty = styled(Flex)`
  margin: 4px 0;
`

const InfoPropertyName = styled.span`
  font-weight: 600;
`
const InfoPropertyValue = styled.span`
  margin-left: 4px;
`

const TAB_LIST = [
  {
    title: 'Pages',
    Component: PagesTab
  },
  {
    title: 'Export',
    Component: ExportTab
  },
  {
    title: 'Migrations (outdated)',
    Component: MigrationsTab
  }
]

const AdminProject: React.FC = () => {
  const { pathname } = useLocation()
  const projectId = pathname.split('/')[3]

  const { data: projectData } = useQuery(GET_PROJECT_DETAILS, {
    variables: {
      projectId
    }
  })

  const projectDetails = projectData?.getProjectDetails

  const projectProps = [
    { name: 'ID:', value: projectId },
    { name: 'Group ID:', value: projectDetails?.groupId },
    {
      name: 'Images migrations count:',
      value: projectDetails?.migrations.length
    },
    { name: 'Pages count:', value: projectDetails?.pagesCount }
  ]

  return (
    <DefaultLayout>
      <Head titleId="AdminProject.title" />
      <PageTitle titleId="AdminProject.title" withBackButton />

      <PageContainer>
        <Info>
          <InfoName>{projectDetails?.name}</InfoName>

          <Flex marginTop={16} direction="column">
            {projectProps.map(({ name, value }, i) => (
              <InfoProperty key={i}>
                <InfoPropertyName>{name}</InfoPropertyName>
                <InfoPropertyValue>{value}</InfoPropertyValue>
              </InfoProperty>
            ))}
          </Flex>
        </Info>

        <Flex marginTop={40}>
          <Tabs<TabProps>
            list={TAB_LIST}
            isContentLoading={!projectDetails?.groupId}
            tabProps={{
              groupId: projectDetails?.groupId || '',
              projectId
            }}
          />
        </Flex>
      </PageContainer>
    </DefaultLayout>
  )
}

export default AdminProject
