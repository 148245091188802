import { useIntl } from 'react-intl'
import styled, { css } from 'styled-components'

import Spinner from 'shared/ui/Spinner'

import { ReactComponent as CheckIcon } from './icons/check.svg'

const DEFAULT_WIDTH = 82
const DEFAULT_HEIGHT = 107

type ContainerProps = {
  width: number
  height: number
  src: string
  isSelected: boolean
}

const Container = styled.div<ContainerProps>`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ isSelected, width }) =>
    isSelected ? width : width + 2}px;
  height: ${({ isSelected, height }) =>
    isSelected ? height : height + 2}px;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.color.base.c2};
  background-image: url(${({ src }) => src});
  background-size: cover;
  background-position: center;
  cursor: pointer;
  position: relative;

  ${({ isSelected }) =>
    isSelected &&
    css`
      ::after {
        content: '';
        position: absolute;
        top: -4px;
        left: -4px;
        right: -4px;
        bottom: -4px;
        border: 2px solid ${({ theme }) => theme.color.brand_02};
        border-radius: 11px;
      }

      ::before {
        content: '';
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        position: absolute;
        background: ${({ theme }) => theme.color.base.c9};
        opacity: 0.9;
        border-radius: 8px;
      }
    `}
`

const SelectedMark = styled.div`
  display: flex;
  gap: 4px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 100%;
  color: ${({ theme }) => theme.color.base.c2};
  font-size: 7px;
  font-weight: 600;
`

const removeIconLine = css`
  content: '';
  position: absolute;
  left: 50%;
  top: 50%;
  height: 8px;
  width: 1px;
  border-radius: 9999px;
  background: ${({ theme }) => theme.color.base.c2};
`

const Remove = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 16px;
  width: 16px;
  position: absolute;
  right: -4px;
  top: -4px;
  border: none;
  border-radius: 50%;
  background: ${({ theme }) => theme.color.base.c6};
  cursor: pointer;

  ::after {
    ${removeIconLine}
    transform: translate(-50%, -50%) rotate(45deg);
  }

  ::before {
    ${removeIconLine}
    transform: translate(-50%, -50%) rotate(-45deg);
  }
`

type BackgroundItemProps = {
  height?: number
  width?: number
  isSelected: boolean
  isLoading?: boolean
  onClick: () => void
  src: string
  isRemovable?: boolean
  onRemove?: () => void
}

const BackgroundItem: React.FC<BackgroundItemProps> = ({
  width = DEFAULT_WIDTH,
  height = DEFAULT_HEIGHT,
  isSelected,
  onClick,
  src,
  isLoading = false,
  isRemovable = false,
  onRemove
}) => {
  const { formatMessage } = useIntl()

  const handleRemoveClick = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation()
    if (onRemove) {
      onRemove()
    }
  }

  return (
    <Container
      width={width}
      height={height}
      onClick={onClick}
      isSelected={isSelected}
      src={src}
    >
      {isLoading && <Spinner />}

      <SelectedMark
        style={{
          display: isSelected && !isLoading ? 'flex' : 'none'
        }}
      >
        <CheckIcon />
        <span>{formatMessage({ id: 'Labels.selected' })}</span>
      </SelectedMark>

      {isRemovable && (
        <Remove
          style={{ display: isSelected ? 'none' : 'flex' }}
          onClick={handleRemoveClick}
        />
      )}
    </Container>
  )
}

export default BackgroundItem
