import { PublisherInterface } from '@chili-publish/publisher-interface'
import config from 'config'

import { DevLogger } from 'helpers/Logger'
import { isDev } from 'helpers/env'
import { EnvTypes } from 'types/global'
import { getChiliImageUrl } from 'utils/rest'

import {
  ChiliVariable,
  Frame,
  FrameTypes,
  ImageFrame
} from '../../types'
import ChiliFrames, { CreateNewFrameOptions } from '../ChiliFrames'
import {
  ChiliFramesRegister,
  FrameEventTypes
} from '../ChiliFramesRegister'
import ChiliVariables, { VariableTypes } from '../ChiliVariables'
import { DocumentImageFrame, ImagesService } from '../types'
import { ExternalImageData, ImageData } from './types'

const variablesApi = ChiliVariables.getInstance()

type InitOptions = {
  publisherConnector: PublisherInterface
}

enum EventTypes {
  frameUpdated = 'frameUpdated',
  frameCreated = 'frameCreated',
  imageReplaced = 'imageReplaced',
  imageFrameLoaded = 'imageFrameLoaded'
}

const PAGE_WIDTH = 210

export default class ChiliImages
  extends ChiliFramesRegister<DocumentImageFrame>
  implements ImagesService<InitOptions>
{
  private static instance: ChiliImages
  private framesApi = ChiliFrames.getInstance()

  static getInstance(): ChiliImages {
    if (!ChiliImages.instance) {
      ChiliImages.instance = new ChiliImages()
    }

    return ChiliImages.instance
  }

  async init({ publisherConnector }: InitOptions) {
    super.init({ publisherConnector })

    this.initResizeListener()
    this.initFrameLoadedListener()
  }

  async destroy() {
    super.destroy()

    await this.removeResizeListener()
  }

  private initFrameLoadedListener() {
    this.publisherConnector?.addListener(
      'FrameImageCompleted',
      () => {
        this.triggerListeners(EventTypes.imageFrameLoaded)
      }
    )
  }

  public async removeFrameLoadedListener() {
    return this.publisherConnector?.removeListener(
      'FrameImageCompleted'
    )
  }

  async removeSetupFrameLoadedListener() {
    this.publisherConnector?.removeListener('FrameImageCompleted')
  }

  private getFrameSelector(frameId?: string) {
    return frameId
      ? `document.pages[1].frames[${frameId}]`
      : 'document.selectedFrame'
  }

  private async getFrameWidth(frameId?: string) {
    const value = (await this.publisherConnector?.getObject(
      `${this.getFrameSelector(frameId)}.width`
    )) as string

    return Number.parseFloat(value)
  }

  private async getFrameHeight(frameId?: string) {
    const value = (await this.publisherConnector?.getObject(
      `${this.getFrameSelector(frameId)}.height`
    )) as string

    return Number.parseFloat(value)
  }

  private async getImageWidth(frameId?: string) {
    const value = (await this.publisherConnector?.getObject(
      `${this.getFrameSelector(frameId)}.imgWidth`
    )) as string

    return Number.parseFloat(value)
  }

  private async getImageHeight(frameId?: string) {
    const value = (await this.publisherConnector?.getObject(
      `${this.getFrameSelector(frameId)}.imgHeight`
    )) as string

    return Number.parseFloat(value)
  }

  async getSelectedFrame(): Promise<ImageFrame | null> {
    const selectedFrame = (await this.publisherConnector?.getObject(
      this.getFrameSelector()
    )) as Frame

    if (selectedFrame?.type !== FrameTypes.image) {
      return null
    }

    return selectedFrame as ImageFrame
  }

  async getImageVariableId(selector?: string) {
    const frame = selector
      ? ((await this.publisherConnector?.getObject(
          selector
        )) as ImageFrame)
      : await this.getSelectedFrame()

    if (!frame) {
      return null
    }

    const { variable: varSelector } = frame

    if (!varSelector) {
      return null
    }

    return varSelector.split('document.variables[')[1].split(']')[0]
  }

  private async alignImageToCenter(params?: {
    imageWidth?: number
    imageHeight?: number
    frameId?: string
  }) {
    const selector = this.getFrameSelector(params?.frameId)

    const frameId =
      params?.frameId ||
      (
        (await this.publisherConnector?.getObject(
          selector
        )) as ImageFrame
      ).id

    if (!this.getFrame(frameId)) {
      return
    }

    const frameWidth = await this.getFrameWidth()
    const frameHeight = await this.getFrameHeight()
    const imageWidth =
      params?.imageWidth || (await this.getImageWidth())
    const imageHeight =
      params?.imageHeight || (await this.getImageHeight())

    const imageWidthPercent = imageWidth / (frameWidth / 100)
    const imageHeightPercent = imageHeight / (frameHeight / 100)

    await this.changePosition({
      x: 50 + imageWidthPercent / 2,
      y: 50 + imageHeightPercent / 2
    })
  }

  async getImageId(frameId: string) {
    const frame = (await this.publisherConnector?.getObject(
      this.getFrameSelector(frameId)
    )) as ImageFrame

    const { variable: varSelector } = frame

    if (!varSelector) {
      return null
    }

    const variable = (await this.publisherConnector?.getObject(
      varSelector
    )) as ChiliVariable

    const { dynamicAssetProviderValue: value } = variable

    if (!value.includes('imageId=')) {
      return null
    }

    try {
      const imageId = value.split('imageId=')[1].split('&')[0]
      return imageId
    } catch {
      return null
    }
  }

  private createNewVariable(
    frameId: string,
    imageId: string,
    isExternalDAP: boolean = false
  ) {
    const DAPIdsMap = isExternalDAP
      ? config.images.externalDAPIds
      : config.images.DAPIds

    const DAPValue = isDev() ? `${imageId}&dev=true` : imageId

    return variablesApi.createVariable(`image_${frameId}`, DAPValue, {
      type: VariableTypes.DAPImage,
      DAPId: DAPIdsMap[process.env.REACT_APP_ENV as EnvTypes]
    })
  }

  async createNewFrame(
    imageData: ImageData,
    options?: {
      position?: CreateNewFrameOptions['position']
      height?: CreateNewFrameOptions['height']
      width?: CreateNewFrameOptions['width']
    }
  ) {
    return new Promise<void>(async (resolve, reject) => {
      try {
        const newFrame = (await this.framesApi.createNewFrame({
          type: FrameTypes.image,
          position: options?.position || {
            x: 50,
            y: 50
          },
          width: options?.width || 75,
          height: options?.height || 75
        })) as ImageFrame

        this.addNewFrame(newFrame, {
          previewUrl:
            imageData.imageUrl ||
            getChiliImageUrl({
              id: imageData.id,
              path: imageData.path
            }),
          ratio: imageData.width / imageData.height
        })

        await this.publisherConnector?.executeFunction(
          newFrame.javaScriptDescriptor,
          'LoadContentFromXmlString',
          imageData.xml
        )

        this.triggerListeners(EventTypes.frameCreated)

        resolve()
      } catch {
        reject()
      }
    })
  }

  async migrateImageFrame(xml: string, frameSelector: string) {
    return new Promise<void>(async (resolve, reject) => {
      try {
        const frame = (await this.publisherConnector?.getObject(
          frameSelector
        )) as ImageFrame

        await this.publisherConnector?.setProperty(
          frameSelector,
          'variable',
          null
        )

        await this.publisherConnector?.executeFunction(
          frame.javaScriptDescriptor,
          'LoadContentFromXmlString',
          xml
        )

        await this.publisherConnector?.setProperty(
          frame.javaScriptDescriptor,
          'fitMode',
          `proportional_outside`
        )

        resolve()
      } catch (error) {
        console.error(error)
        reject()
      }
    })
  }

  async replaceImage(imageData: ImageData) {
    return new Promise<void>(async (resolve, reject) => {
      try {
        const frame = (await this.publisherConnector?.getObject(
          'document.selectedFrame'
        )) as ImageFrame

        const varSelector = frame.variable

        if (varSelector) {
          const { id } = (await this.publisherConnector?.getObject(
            varSelector
          )) as ChiliVariable
          await variablesApi.removeVariable(id)
        }

        this.setFrameProperty(
          frame.id,
          'previewURL',
          imageData.imageUrl ||
            getChiliImageUrl({
              id: imageData.id,
              path: imageData.path
            })
        )

        const newRatio = imageData.width / imageData.height
        this.setFrameProperty(frame.id, 'ratio', newRatio)
        this.setFrameProperty(frame.id, 'width', imageData.width)
        this.setFrameProperty(frame.id, 'height', imageData.height)

        console.log('imageData xml', imageData.xml)

        await this.publisherConnector?.executeFunction(
          frame.javaScriptDescriptor,
          'LoadContentFromXmlString',
          imageData.xml
        )

        await this.publisherConnector?.setProperty(
          frame.javaScriptDescriptor,
          'fitMode',
          `proportional_outside`
        )

        this.triggerListeners(FrameEventTypes.frameSizeUpdated, {
          id: frame.id
        })
        this.triggerListeners(EventTypes.imageReplaced)

        resolve()
      } catch (error) {
        console.log('error', error)
        reject()
      }
    })
  }

  addNewFrame(
    frame: ImageFrame,
    params?: {
      previewUrl?: string
      ratio?: number
    }
  ): DocumentImageFrame {
    const imageWidth = Number.parseFloat(frame.imgWidth)
    const imageHeight = Number.parseFloat(frame.imgHeight)
    const width = Number.parseFloat(frame.width!)
    const height = Number.parseFloat(frame.height!)
    const imageX = Number.parseFloat(frame.imgX!)
    const imageY = Number.parseFloat(frame.imgY!)

    let internalPreviewUrl = params?.previewUrl

    if (
      frame.previewURL.includes('chili') &&
      !frame.previewURL.includes('DynamicAsset')
    ) {
      const params = new URLSearchParams({
        id: frame.externalID,
        path: frame.path
      })

      internalPreviewUrl = new URL(
        `${process.env.REACT_APP_REST_URL}images/chili?${params}`
      ).toString()
    }

    const newFrame = {
      ...super.addNewFrame(frame),
      isCropped: false,
      zoomLevel: imageWidth / (PAGE_WIDTH / 100),
      ratio: params?.ratio || imageWidth / imageHeight,
      imageWidth,
      imageHeight,
      positionX: imageX / (width / 100), // % from frame size
      positionY: imageY / (height / 100), // % from frame size
      width,
      height,
      previewURL: internalPreviewUrl || frame.previewURL
    } as DocumentImageFrame

    this.framesMap[frame.id] = newFrame

    // this.publisherConnector?.setProperty(
    //   frame.javaScriptDescriptor,
    //   'fitMode',
    //   'manual'
    // )

    return newFrame
  }

  async setIsFrameCropped(frameId: string, value: boolean) {
    this.setFrameProperty(frameId, 'isCropped', value)

    return this.publisherConnector?.setProperty(
      this.getFrameSelector(frameId),
      'fitMode',
      value ? 'manual' : 'proportional_outside'
    )
  }

  async deleteFrame(): Promise<boolean> {
    const frame = (await this.publisherConnector?.getObject(
      'document.selectedFrame'
    )) as ImageFrame

    const { id: frameId } = frame

    await this.publisherConnector?.executeFunction(
      'document.selectedFrame',
      'Delete'
    )

    this.removeFrame(frameId)

    return true
  }

  async initResizeListener() {
    const callback = async (frameId: string) => {
      if (this.framesMap[frameId]) {
        const frameWidth = await this.getFrameWidth()
        const frameHeight = await this.getFrameHeight()
        const frameX = (
          (await this.publisherConnector?.getObject(
            'document.selectedFrame'
          )) as ImageFrame
        ).imgX
        const frameY = (
          (await this.publisherConnector?.getObject(
            'document.selectedFrame'
          )) as ImageFrame
        ).imgY
        const imageWidth = await this.getImageWidth()
        const imageHeight = await this.getImageHeight()

        this.framesMap[frameId].width = frameWidth
        this.framesMap[frameId].height = frameHeight
        this.framesMap[frameId].positionX =
          Number.parseFloat(frameX) / (frameWidth / 100)
        this.framesMap[frameId].positionY =
          Number.parseFloat(frameY) / (frameHeight / 100)
        this.framesMap[frameId].imageWidth = imageWidth
        this.framesMap[frameId].imageHeight = imageHeight

        this.triggerListeners(FrameEventTypes.frameSizeUpdated, {
          id: frameId,
          internal: true
        })
        this.triggerListeners(EventTypes.frameUpdated, {
          frame: this.framesMap[frameId]
        })
      }
    }

    await this.publisherConnector?.addListener(
      'FrameResized',
      callback
    )
    this.addListener(
      FrameEventTypes.frameSizeUpdated,
      ({ id, internal }) => internal || callback(id)
    )
  }

  async removeResizeListener() {
    return this.publisherConnector?.removeListener('FrameResized')
  }

  async loadPreviewImage(frameId: string): Promise<string> {
    const DAPUrl = await this.getFrameImageUrl()

    if (DAPUrl) {
      return DAPUrl
    }

    const { previewURL } = this.framesMap[frameId]

    return previewURL
  }

  async changeZoomLevel(newZoomLevel: number) {
    const frameId = (await this.publisherConnector?.getObject(
      'document.selectedFrame.id'
    )) as string

    await this.setIsFrameCropped(frameId, true)

    const width = (PAGE_WIDTH / 100) * newZoomLevel
    const height = width / this.framesMap[frameId].ratio

    this.framesMap[frameId].zoomLevel = newZoomLevel
    this.framesMap[frameId].imageWidth = width
    this.framesMap[frameId].imageHeight = height

    this.triggerListeners(EventTypes.frameUpdated, {
      frame: this.framesMap[frameId]
    })

    setTimeout(async () => {
      await this.publisherConnector?.setProperty(
        'document.selectedFrame',
        'imgWidth',
        `${width} mm`
      )
      await this.publisherConnector?.setProperty(
        'document.selectedFrame',
        'imgHeight',
        `${height} mm`
      )
    }, 300)
  }

  async changePosition(
    // % of frame size values
    { x, y }: { x: number; y: number }
  ) {
    const frame = await this.getSelectedFrame()

    if (!frame) {
      return
    }

    await this.setIsFrameCropped(frame.id, true)

    const width = Number.parseFloat(frame.width!)
    const height = Number.parseFloat(frame.height!)

    const xMM = x * (width / 100)
    const yMM = y * (height / 100)

    await this.publisherConnector?.setProperty(
      'document.selectedFrame',
      'imgX',
      `${xMM} mm`
    )
    await this.publisherConnector?.setProperty(
      'document.selectedFrame',
      'imgY',
      `${yMM} mm`
    )

    this.setFrameProperty(frame.id, 'positionX', x)
    this.setFrameProperty(frame.id, 'positionY', y)
  }

  async getFrameImageUrl(): Promise<string | null> {
    const varId = await this.getImageVariableId()
    const frame = await this.getSelectedFrame()

    if (!varId) {
      // If image is from Chili DAM
      if (frame?.variable === null) {
        const url = new URL(frame.previewURL)

        return getChiliImageUrl({
          id: url.searchParams.get('id')!,
          path: url.searchParams.get('path')!
        })
      }

      return null
    }

    const variable = await variablesApi.getVariable(varId)

    if (!variable) {
      return null
    }

    return config.images.DAPUrls[
      variable.dynamicAssetProvider as string
    ].replace('%data%', variable.dynamicAssetProviderValue)
  }
}

export { EventTypes as ChiliImagesEventTypes }
