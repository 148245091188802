import { Link } from 'react-router-dom'
import styled from 'styled-components'

import Flex from 'components/Flex'
import Status from 'components/Status'
import { MigrationStatuses } from 'screens/AdminProject/types'

const Container = styled(Link)`
  border-radius: 16px;
  padding: 20px;
  background: ${({ theme }) => theme.color.base.c0};
  width: 500px;
  cursor: pointer;
`

const Name = styled.h3`
  margin: 0;
  margin-bottom: 16px;
`

const PropertyName = styled.span`
  font-weight: 600;
  margin-right: 4px;
`

type MigrationProps = {
  id: string
  name: string
  status: MigrationStatuses
  type: string
  date: Date
  author: string | null
  linkTo: string
}

const Migration: React.FC<MigrationProps> = ({
  id,
  name,
  status,
  date,
  author,
  type,
  linkTo
}) => {
  const properties = [
    { name: 'ID:', value: id },
    { name: 'Date:', value: date.toLocaleDateString() },
    { name: 'Author:', value: author || 'anonymous' },
    { name: 'Type:', value: type }
  ]

  return (
    <Container to={linkTo}>
      <Name>{name}</Name>
      <Flex direction="column">
        {properties.map(({ name, value }) => (
          <Flex marginBottom={6}>
            <PropertyName>{name}</PropertyName>
            {value}
          </Flex>
        ))}
      </Flex>

      <Flex marginTop={16}>
        <Status value={status} />
      </Flex>
    </Container>
  )
}

export default Migration
