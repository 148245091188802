import { useIntl } from 'react-intl'

import Input, { InputProps } from 'components/Input'
import { theme } from 'styles/theme'

import { ReactComponent as GlassIcon } from './icons/glass.svg'

type SearchInputProps = {
  iconSize?: number
  iconColor?: string
  borderRadius?: number
  label?: string
} & Omit<InputProps, 'renderIcon' | 'placeholder'>

const SearchInput: React.FC<SearchInputProps> = ({
  iconSize = 15,
  iconColor = theme.color.base.c9,
  borderRadius,
  label,
  ...rest
}) => {
  const { formatMessage } = useIntl()

  return (
    <Input
      {...rest}
      label={
        label
          ? label
          : formatMessage({
              id: 'Components.searchInput.placeholder'
            })
      }
      fontSize={18}
      renderIcon={() => (
        <GlassIcon
          width={iconSize}
          height={iconSize}
          fill={iconColor}
        />
      )}
    />
  )
}

export default SearchInput
