import styled from 'styled-components'

import styles from './index.module.css'

type Props = {
  x: number
  y: number
  timestamp: number
  value: string
}

const Container = styled.div`
  position: absolute;
  pointer-events: none;
  user-select: none;
`

export default function FlyingReaction({
  x,
  y,
  timestamp,
  value
}: Props) {
  return (
    <Container
      className={`${styles.disappear} text-${(timestamp % 5) + 2}xl ${
        styles['goUp' + (timestamp % 3)]
      }`}
      style={{ left: x, top: y }}
    >
      <div className={styles['leftRight' + (timestamp % 3)]}>
        <div className="transform -translate-x-1/2 -translate-y-1/2">
          {value}
        </div>
      </div>
    </Container>
  )
}
