import * as React from 'react'

const useClickOutside = (callback: () => void) => {
  const ref = React.useRef()

  React.useEffect(() => {
    const handleClick = (event: any) => {
      if (
        ref.current &&
        !(ref.current as any).contains(event.target)
      ) {
        callback()
      }
    }

    document.addEventListener('click', handleClick)

    return () => {
      document.removeEventListener('click', handleClick)
    }
  }, [ref])

  return ref
}

export default useClickOutside
