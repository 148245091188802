import { PublisherInterface } from '@chili-publish/publisher-interface'
import { useEffect } from 'react'

import BasicService from 'shared/components/Tools/services/BasicService'
import ChiliColors from 'shared/components/Tools/services/ChiliColors'
import ChiliDocument from 'shared/components/Tools/services/ChiliDocument'
import ChiliFrames from 'shared/components/Tools/services/ChiliFrames'
import ChiliImages from 'shared/components/Tools/services/ChiliImages'
import ChiliShapes from 'shared/components/Tools/services/ChiliShapes'
import ChiliText from 'shared/components/Tools/services/ChiliText'
import ChiliVariables from 'shared/components/Tools/services/ChiliVariables'

const colorsApi = ChiliColors.getInstance()
const shapesApi = ChiliShapes.getInstance()
const imagesApi = ChiliImages.getInstance()
const variablesApi = ChiliVariables.getInstance()
const documentApi = ChiliDocument.getInstance()
const framesApi = ChiliFrames.getInstance()
const textApi = ChiliText.getInstance()

const chiliApis = [
  colorsApi,
  shapesApi,
  imagesApi,
  variablesApi,
  documentApi,
  framesApi,
  textApi
] as BasicService[]

export const useChiliApisInit = ({
  publisherConnector,
  isDocumentLoaded
}: {
  publisherConnector: PublisherInterface | null
  isDocumentLoaded: boolean
}) => {
  useEffect(() => {
    if (publisherConnector && isDocumentLoaded) {
      chiliApis.forEach((api: BasicService) => {
        api.init({ publisherConnector })
      })
    }

    return function cleanup() {
      chiliApis.forEach((api: BasicService) => {
        api.destroy()
      })
    }
  }, [publisherConnector, isDocumentLoaded])

  return {
    colorsApi,
    shapesApi,
    imagesApi,
    variablesApi,
    documentApi,
    framesApi,
    textApi
  }
}
