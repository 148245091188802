export enum MigrationStatuses {
  notStarted = 'notStarted',
  inProgress = 'inProgress',
  completed = 'completed',
  failed = 'failed',
  skipped = 'skipped'
}

export enum ExportStatuses {
  notStarted = 'notStarted',
  inProgress = 'inProgress',
  completed = 'completed',
  failed = 'failed'
}

export enum ImagesMigrationTypes {
  full = 'full',
  externalOnly = 'externalOnly',
  internalOnly = 'internalOnly'
}

export enum ImageVariableTypes {
  internal = 'internal',
  external = 'external'
}

export type TabProps = {
  groupId: string
  projectId: string
}

export enum ProjectExportTypes {
  print = 'print',
  preview = 'preview'
}

export enum ProjectExportStatuses {
  exporting = 'exporting',
  merging = 'merging',
  error = 'error',
  warning = 'warning',
  completed = 'completed',
  waiting = 'waiting'
}
