import { GradooTheme } from 'gradoo-theme/lib/types'
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams
} from 'react-router-dom'

import Button from 'shared/ui/Button_2'
import Tool, { ToolsContainer } from 'shared/ui/Tool'

import { ButtonWrapper } from './style'

type ToolsProps = {
  openModal?: () => void
  tools: {
    to: string
    icon: string
    colors: {
      active: (theme: GradooTheme) => string
      default: (theme: GradooTheme) => string
    }
    title: string
  }[]
  settingsPath: string
  isDisabled?: boolean
}

const Tools: React.FC<ToolsProps> = ({
  openModal,
  isDisabled,
  tools,
  settingsPath
}) => {
  const params = useParams()
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()

  return (
    <ToolsContainer>
      {tools.map(tool => {
        return (
          <Tool
            key={tool.to}
            {...tool}
            param={params.projectId}
            isActive={
              searchParams.has('view') &&
              tool.to.includes(searchParams.get('view') || '')
            }
            isDisabled={isDisabled}
          />
        )
      })}
      <ButtonWrapper>
        <Button
          onPress={() => navigate(settingsPath)}
          size="sm"
          intent="secondary-base"
          textId={'Tools.settings'}
        />
        <Button
          testID={'ToolsAddButton'}
          onPress={
            !isDisabled && typeof openModal === 'function'
              ? openModal
              : undefined
          }
          size="sm"
          intent="primary-brand-02"
          textId={'Tools.add.page'}
          iconName="plus"
          icon="left"
          isDisabled={isDisabled}
          state={isDisabled ? 'disabled' : 'default'}
        />
      </ButtonWrapper>
    </ToolsContainer>
  )
}

export default Tools
