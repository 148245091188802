import { ColorRGB } from 'shared/types/global'

import CMYK from './CMYK'

export default class RGB implements ColorRGB {
  r!: number
  g!: number
  b!: number

  constructor({ r, g, b }: ColorRGB) {
    this.r = r
    this.g = g
    this.b = b
  }

  toCMYK(): CMYK {
    const r = this.r / 255
    const g = this.g / 255
    const b = this.b / 255

    const max = Math.max(r, g, b)

    const k = 1 - max

    if (k === 1) {
      return new CMYK({ c: 0, m: 0, y: 0, k: 100 })
    }

    const c = (1 - r - k) / (1 - k)
    const m = (1 - g - k) / (1 - k)
    const y = (1 - b - k) / (1 - k)

    const cPercent = Math.round(c * 100)
    const mPercent = Math.round(m * 100)
    const yPercent = Math.round(y * 100)
    const kPercent = Math.round(k * 100)

    return new CMYK({
      c: cPercent,
      m: mPercent,
      y: yPercent,
      k: kPercent
    })
  }

  toCSS(): string {
    return `rgb(${this.r}, ${this.g}, ${this.b})`
  }
}
