import styled from 'styled-components'

const FormHeader = styled.div`
  margin-bottom: 12px;
  font-size: 16px;
  font-weight: 600;
  color: ${({ theme }) => theme.color.base.c9};
`

export default FormHeader
