import { theme } from 'gradoo-theme'

type UploadIconProps = {
  isDragActive?: boolean
  isDragReject?: boolean
}

const UploadIcon = ({
  isDragActive,
  isDragReject
}: UploadIconProps) => {
  const getFill = () => {
    switch (true) {
      case isDragReject:
        return theme.color.error
      case isDragActive:
        return theme.color.success
      default:
        return theme.color.base.c4
    }
  }

  return (
    <svg
      width="64"
      height="64"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.5 4C5.91015 4 3 6.91015 3 10.5C3 12.2909 3.72297 13.911 4.89535 15.0877C5.28516 15.479 5.284 16.1121 4.89276 16.5019C4.50152 16.8917 3.86836 16.8906 3.47855 16.4993C1.94793 14.9631 1 12.8413 1 10.5C1 5.80558 4.80558 2 9.5 2C12.5448 2 15.2144 3.60142 16.7145 6.00347C20.205 6.11662 23 8.98191 23 12.5C23 14.5188 22.0784 16.3233 20.6368 17.5139C20.2109 17.8656 19.5806 17.8055 19.229 17.3796C18.8773 16.9538 18.9374 16.3235 19.3632 15.9718C20.3646 15.1448 21 13.897 21 12.5C21 10.0147 18.9853 8 16.5 8C15.9363 8 15.3998 7.70633 15.1046 7.20544C13.9729 5.28498 11.8859 4 9.5 4ZM11.2929 11.2929C11.6834 10.9024 12.3166 10.9024 12.7071 11.2929L16.7071 15.2929C17.0976 15.6834 17.0976 16.3166 16.7071 16.7071C16.3166 17.0976 15.6834 17.0976 15.2929 16.7071L13 14.4142V21C13 21.5523 12.5523 22 12 22C11.4477 22 11 21.5523 11 21V14.4142L8.70711 16.7071C8.31658 17.0976 7.68342 17.0976 7.29289 16.7071C6.90237 16.3166 6.90237 15.6834 7.29289 15.2929L11.2929 11.2929Z"
        fill={getFill()}
      />
    </svg>
  )
}

export default UploadIcon
