import styled from 'styled-components'

import Flex from 'components/Flex'

export const Carousel = styled(Flex)`
  margin-top: 124px;
  column-gap: 16px;
  row-gap: 16px;
  flex-wrap: wrap;
`
export const ImgContainer = styled.div`
  position: relative;
  width: 210px;
  height: 289px;

  & img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    transition: transform 0.2s ease-out;
  }

  &:hover img {
    transform: scale(1.1);
  }
`
export const TemplatePageContainer = styled(ImgContainer)`
  width: 136px;
  height: 192px;
`
