import { useIntl } from 'react-intl'

import Spinner, { SpinnerVariants } from 'components/Spinner'
import { theme } from 'styles/theme'

import { IconName } from '../Icon'
import { bySizeHelper, byTypeHelper } from './helpers'
import { ButtonContainer, CustomIcon, Text } from './style'
import {
  ButtonIcon,
  ButtonIntent,
  ButtonSize,
  ButtonState
} from './types'

export type ButtonComponentProps = {
  testID?: string
  textId?: string
  icon?: ButtonIcon
  iconName?: IconName
  intent?: ButtonIntent
  state?: ButtonState
  size?: ButtonSize
  loading?: boolean
  minWidth?: number
  onPress?: () => void
  type?: 'button' | 'submit' | 'reset'
  isDisabled?: boolean
  href?: string
}

export default function Button({
  testID,
  textId = '',
  icon = 'none',
  iconName,
  intent = 'primary-brand-01',
  state = 'default',
  size = 'lg',
  loading = false,
  minWidth,
  onPress,
  type = 'button',
  isDisabled,
  href
}: ButtonComponentProps): JSX.Element {
  const onPressHandler = () => {
    if (!loading && onPress) {
      onPress()
    }
  }
  const { formatMessage } = useIntl()

  const backgroundColor = byTypeHelper.bgColor(intent, state, theme)
  const calculatedHeight =
    bySizeHelper.paddingV(size) * 2 + bySizeHelper.lineHeight(size)

  if (icon === 'only') {
    return (
      <ButtonContainer
        data-testid={testID}
        buttonIntent={intent}
        state={state}
        onClick={onPressHandler}
        iconType={icon}
        backgroundColor={backgroundColor}
        size={size}
        minWidth={minWidth}
        type={type}
        disabled={isDisabled}
        as={href ? 'a' : undefined}
        href={href}
      >
        {loading ? (
          <Spinner size={20} variant={SpinnerVariants.light} />
        ) : iconName ? (
          <CustomIcon
            intent={intent}
            state={state}
            buttonSize={size}
            name={iconName}
            iconType={icon}
          />
        ) : null}
      </ButtonContainer>
    )
  }
  return (
    <ButtonContainer
      buttonIntent={intent}
      state={state}
      onClick={onPressHandler}
      iconType={icon}
      backgroundColor={backgroundColor}
      height={calculatedHeight}
      size={size}
      minWidth={minWidth}
    >
      {loading ? (
        <Spinner size={20} variant={SpinnerVariants.light} />
      ) : (
        <>
          {icon === 'left' && iconName ? (
            <CustomIcon
              name={iconName}
              intent={intent}
              state={state}
              buttonSize={size}
              iconType={icon}
            />
          ) : null}
          <Text
            intent={intent}
            state={state}
            size={size}
            iconType={icon}
          >
            {formatMessage({ id: textId })}
          </Text>
          {icon === 'right' && iconName ? (
            <CustomIcon
              name={iconName}
              intent={intent}
              state={state}
              buttonSize={size}
              iconType={icon}
            />
          ) : null}
        </>
      )}
    </ButtonContainer>
  )
}
