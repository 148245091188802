import { PublisherInterface } from '@chili-publish/publisher-interface'
import { useIntl } from 'react-intl'

import CustomScrollbars from 'shared/ui/SimpleBar'

import {
  ChangeFrameProps,
  ChangeTextFormat,
  Font,
  Frame,
  TextFormat
} from '../../types'
import Title from '../PanelFirstLevelTitle'
import FrameControls from './components/FrameControls'
import StartDesigningPanel from './components/StartDesigningPanel'

type PanelProps = {
  frame: Frame | null
  textFormat: TextFormat | null
  font: Font | null
  changeFrameProps: ChangeFrameProps
  changeTextFormat: ChangeTextFormat
  publisherConnector: PublisherInterface
}

const LeftPanel = ({
  frame,
  textFormat,
  font,
  changeFrameProps,
  changeTextFormat,
  publisherConnector
}: PanelProps): JSX.Element => {
  const { formatMessage } = useIntl()

  return (
    <>
      <CustomScrollbars
        fixedHeader={
          <Title>
            {formatMessage({
              id: `Panel.title.${
                frame ? frame.type : 'startDesigning'
              }`
            })}
          </Title>
        }
      >
        {frame ? (
          <FrameControls
            frame={frame}
            textFormat={textFormat}
            font={font}
            changeFrameProps={changeFrameProps}
            changeTextFormat={changeTextFormat}
            publisherConnector={publisherConnector}
          />
        ) : (
          <StartDesigningPanel />
        )}
      </CustomScrollbars>
    </>
  )
}

export default LeftPanel
