import { CollageItemSkeleton } from './components/CollageItem'

const CollagesListPanelSkeleton: React.FC = () => (
  <>
    {new Array(3).fill(null).map((_, i) => (
      <CollageItemSkeleton key={i} />
    ))}
  </>
)

export default CollagesListPanelSkeleton
