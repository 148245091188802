import { GRADOO_GROUP_ID_PREFIX } from 'shared/styles/constants'

export const isGroupId = (string: string): boolean => {
  if (string.length !== 64) {
    return false
  }

  if (
    string.slice(0, GRADOO_GROUP_ID_PREFIX.length) !==
    GRADOO_GROUP_ID_PREFIX
  ) {
    return false
  }

  return true
}
