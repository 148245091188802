import { ReactChildren } from 'types/global'

type SettingsSectionProps = {
  Header?: JSX.Element
} & ReactChildren

const SettingsSection: React.FC<SettingsSectionProps> = ({
  Header,
  children
}) => {
  return (
    <section>
      {Header}

      <div>{children}</div>
    </section>
  )
}

export default SettingsSection
