import { getUnixTime } from 'date-fns'

export function isTokenDateExpired(
  exp: number,
  now = new Date()
): boolean {
  try {
    return getUnixTime(now) >= exp
  } catch (e) {
    return true
  }
}
