import { PublisherInterface } from '@chili-publish/publisher-interface'
import { useState } from 'react'
import { useIntl } from 'react-intl'

import Button from 'shared/ui/Button_2'
import Flex from 'shared/ui/Flex'
import Select from 'shared/ui/Select'
import Text from 'shared/ui/Text'
import Tools from 'shared/components/Tools'

type HeaderProps = {
  onLeaveClick: () => void
  isSaveLoading: boolean
  isDocumentLoaded: boolean
  publisherConnector: PublisherInterface | null
}

const AdminProjectsEditorHeader: React.FC<HeaderProps> = ({
  onLeaveClick,
  isSaveLoading,
  isDocumentLoaded,
  publisherConnector
}) => {
  const { formatMessage } = useIntl()
  const [selectedMigrationIndex, setSelectedMigrationIndex] =
    useState<number>(0)

  const migrationActions: Array<() => void> = []

  const runMigration = () => {
    if (migrationActions[selectedMigrationIndex]) {
      migrationActions[selectedMigrationIndex]()
    }
  }

  return (
    <Flex justifyContent="space-between" alignItems="center">
      <Flex width={500} columnGap={16} alignItems="center">
        <Button
          loading={isSaveLoading}
          textId="Editor.leaveButton"
          intent="primary-brand-02"
          onPress={onLeaveClick}
          icon="left"
          iconName="save"
          minWidth={162}
        />
        <Text tag="h1" text="Edit page" size={24} weight={600} />
      </Flex>

      <Tools
        publisherConnector={publisherConnector!}
        isDocumentLoaded={isDocumentLoaded}
        showSidePanels={false}
      />

      <Flex width={500} columnGap={8} justifyContent="flex-end">
        <Select
          width={230}
          options={[]}
          activeIndex={selectedMigrationIndex}
          onChange={(_, index) => setSelectedMigrationIndex(index)}
          noOptionsMessage={formatMessage({
            id: 'AdminEditor.noMigrationsYet'
          })}
        />
        <Button
          textId="AdminEditor.runFix"
          intent="secondary-base"
          size="md"
          maxHeight={34}
          isDisabled={migrationActions.length === 0}
          onPress={runMigration}
        />
      </Flex>
    </Flex>
  )
}

export default AdminProjectsEditorHeader
