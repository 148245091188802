import React from 'react'
import styled, { css } from 'styled-components'

import { MIN_MAIN_CONTAINER_WIDTH } from 'styles/constants'
import { ReactChildren } from 'types/global'

interface StyledDivProps {
  isBoxed?: boolean
}

const StyledDiv = styled.div<StyledDivProps>`
  min-width: ${MIN_MAIN_CONTAINER_WIDTH}px;
  margin: 0 auto;

  ${({ isBoxed }) =>
    isBoxed &&
    css`
      display: grid;
      height: 100%;
      grid-template-rows: auto 1fr;
      padding: 64px 64px 114px;
      margin-top: 64px;
      background: ${({ theme }) => theme.color.base.c0};
      box-shadow: 9px 7px 38px rgba(29, 53, 71, 0.08);
      border-top-left-radius: 15px;
      border-top-right-radius: 15px;
    `}
`

type PageContainerProps = ReactChildren & StyledDivProps

const PageContainer = ({
  children,
  isBoxed = false
}: PageContainerProps) => (
  <StyledDiv isBoxed={isBoxed}>{children}</StyledDiv>
)

export default PageContainer
