import {
  LineKey,
  LineStyle,
  LineStyleMap,
  StyledText,
  TextStyleMap,
  getStyledLineMap
} from 'react-markdown-editor'
import styled from 'styled-components'

import { useClickToCopy } from 'hooks/useClickToCopy'

type LineFontFamilyInput = {
  lineStyle: LineStyle
}

export const getLineFontWeight = ({
  lineStyle
}: LineFontFamilyInput): number => {
  if (lineStyle === '#') {
    return 700
  }
  if (lineStyle === '##') {
    return 500
  }
  if (lineStyle === '###') {
    return 600
  }
  return 400
}

export const getLineTextSize = (lineStyle: LineStyle): number => {
  if (lineStyle === '#') {
    return 18
  }
  if (lineStyle === '##') {
    return 16
  }
  if (lineStyle === '###') {
    return 14
  }
  //body
  return 14
}

export type IText = Omit<StyledText, 'text'>

export const Text = styled.span<IText>`
  color: ${({ theme }) => theme.color.base.c9};
  font-family: Inter;
  ${({ bold }) => bold && 'font-weight: 600;'};
  ${({ italic }) => italic && 'font-style: italic;'};
  ${({ underline }) => underline && 'text-decoration: underline;'};
  ${({ strikethrough }) =>
    strikethrough && 'text-decoration:  line-through;'};
`

interface ILineText {
  lineStyle: LineStyle
}

export const LineText = styled.span<ILineText>`
  min-height: ${({ lineStyle }) => getLineTextSize(lineStyle)}px;
  font-family: Inter;
  font-weight: ${({ lineStyle }) => getLineFontWeight({ lineStyle })};
  font-size: ${({ lineStyle }) => getLineTextSize(lineStyle)}px;
`

type MarkdownTextProps = {
  children: string
  textStyleMap?: TextStyleMap
  lineStyleMap?: LineStyleMap
}

export default function MarkdownText({
  children,
  textStyleMap = {},
  lineStyleMap = {}
}: MarkdownTextProps): JSX.Element {
  const { register } = useClickToCopy()
  const styleLineMap = getStyledLineMap(
    children,
    textStyleMap,
    lineStyleMap
  )

  const lineKeys = Object.keys(styleLineMap) as LineKey[]
  return (
    <>
      {lineKeys.map((lineKey, lineIndex) => {
        const { lineStyle, styledTexts } = styleLineMap[lineKey]
        return (
          <LineText key={lineKey} lineStyle={lineStyle}>
            {styledTexts.map(({ text, ...style }, textIndex) => (
              <Text key={`t${textIndex}`} {...register()} {...style}>
                {text}
              </Text>
            ))}
            {lineIndex < lineKeys.length - 1 ? '\n' : ''}
          </LineText>
        )
      })}
    </>
  )
}
