import { FormikContextType, useFormikContext } from 'formik'

import { removeValuesFromArray } from 'helpers/array'

import Options from '../Options'
import { FormOptionsCommonProps } from '../types'

export enum ActionTypes {
  select = 'select',
  unselect = 'unselect'
}

export type Action<ValueType> = {
  value: ValueType
  type: ActionTypes
}

type FormOptionsProps<ValueType> = {
  onChange: (values: ValueType[], action: Action<ValueType>) => void
} & FormOptionsCommonProps<ValueType>

const FormMultiOptions = <ValueType extends string>({
  field,
  options,
  onChange,
  disabled = []
}: FormOptionsProps<ValueType>): JSX.Element => {
  const {
    values: formValues,
    setFieldValue
  }: FormikContextType<any> = useFormikContext()

  const values = formValues![field]

  const handleChange = (selectedValue: ValueType) => {
    const index = values.indexOf(selectedValue)

    let result: ValueType[]
    let actionType: Action<ValueType>['type']

    if (index < 0) {
      actionType = ActionTypes.select
      result = [...values, selectedValue]
    } else {
      actionType = ActionTypes.unselect
      result = removeValuesFromArray(values, [selectedValue])
    }

    onChange(result, { value: selectedValue, type: actionType })
    setFieldValue(field, result)
  }

  const isActive = (optionValue: ValueType) =>
    values.includes(optionValue)

  return (
    <Options
      options={options}
      isActive={isActive}
      disabled={disabled}
      onOptionClick={handleChange}
    />
  )
}

export default FormMultiOptions
