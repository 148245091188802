import { useQuery } from '@apollo/client'
import { useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useIntl } from 'react-intl'

import Flex from 'components/Flex'
import TabBar from 'components/TabBar'
import { QUOTES_INSTANCES } from 'data/gradoo/queries/quotes'
import {
  DragOverlay,
  Draggable,
  useContentDnd
} from 'screens/YearbookEditor/providers/ContentDndProvider'
import { Ids } from 'screens/YearbookEditor/providers/ContentDndProvider/types'
import { QuoteFragment } from 'types/gradoo/graphql'

import QuoteItem from './QuoteItem'
import Skeleton from './Skeleton'

const PAGE_SIZE = 20
type QuotesPanelProps = {
  moduleInstanceId?: string
}
const QuotesPanel: React.FC<QuotesPanelProps> = ({
  moduleInstanceId
}) => {
  const [activeTab, setActiveTab] = useState(0)
  const { formatMessage } = useIntl()

  const { data: quotesData, fetchMore: fetchMoreQuotes } = useQuery(
    QUOTES_INSTANCES,
    {
      skip: !moduleInstanceId,
      variables: {
        moduleInstance: moduleInstanceId,
        orderBy: activeTab === 0 ? '-created' : '-likes',
        first: PAGE_SIZE
      },
      notifyOnNetworkStatusChange: true,
      context: { client: 'gradoo' }
    }
  )

  const quotes: QuoteFragment[] =
    (quotesData?.quotesInstances?.edges.map(
      edge => edge?.node
    ) as QuoteFragment[]) || []

  const hasNextPage =
    quotesData?.quotesInstances?.pageInfo.hasNextPage || false
  const endCursor = quotesData?.quotesInstances?.pageInfo.endCursor

  const onLoadMoreQuotes = async () => {
    hasNextPage &&
      (await fetchMoreQuotes({
        variables: {
          moduleInstance: moduleInstanceId,
          orderBy: activeTab === 0 ? '-created' : '-likes',
          after: endCursor
        }
      }))
  }

  const { draggingContent } = useContentDnd()

  return (
    <Flex direction="column">
      <Flex marginTop={2} direction="column">
        <TabBar
          activeIndex={activeTab}
          onChange={setActiveTab}
          tabs={[
            formatMessage({ id: 'Panel.quotes.tabs.newest' }),
            formatMessage({ id: 'Panel.quotes.tabs.mostPopular' })
          ]}
        />
        <InfiniteScroll
          dataLength={quotes.length}
          next={onLoadMoreQuotes}
          hasMore={hasNextPage}
          loader={<Skeleton />}
          scrollThreshold={0.8}
          scrollableTarget="scrollableDivParent" // Parent: RightPanel line:188
        >
          <Flex marginTop={32} direction="column" rowGap={8}>
            {quotes.map((quote, i) => {
              return (
                <Draggable
                  key={quote.id + i}
                  id={quote.id}
                  type={Ids.quote}
                  onDragStart={() => draggingContent.setQuote(quote)}
                >
                  <QuoteItem
                    data-testid={`QuoteItem:${quote.id}`}
                    quote={quote}
                  />
                </Draggable>
              )
            })}

            <DragOverlay>
              <QuoteItem quote={draggingContent.quote} />
            </DragOverlay>
          </Flex>
        </InfiniteScroll>
      </Flex>
    </Flex>
  )
}

export default QuotesPanel
