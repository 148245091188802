import { PublisherInterface } from '@chili-publish/publisher-interface'
import config from 'config'
import { useState } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'

import EditorIframe from 'shared/components/EditorIframe'
import { useChiliApisInit } from 'shared/hooks/useChiliApisInit'
import { useEditorSave } from 'shared/hooks/useEditorSave'
import AdminLayout from 'shared/layout/Admin'

import Header from './components/Header'

const WORKSPACE_ID = config.chiliAdminWorkspaceId

const AdminProjectsEditor: React.FC = () => {
  const { pageId, projectId, groupId } = useParams()
  const [searchParams] = useSearchParams()
  const viewSearchParam = searchParams.get('view')
  const [publisherConnector, setPublisherConnector] =
    useState<PublisherInterface | null>(null)
  const [loadingPercent, setLoadingPercent] = useState(0)
  const [isDocumentLoaded, setIsDocumentLoaded] = useState(false)

  useChiliApisInit({
    publisherConnector,
    isDocumentLoaded
  })

  const backPath = viewSearchParam
    ? `/admin/projects/${groupId}/${projectId}?view=${viewSearchParam}`
    : `/admin/projects/${groupId}/${projectId}`

  const { handleSave } = useEditorSave({
    publisherConnector,
    backPath,
    groupId: groupId!,
    projectId: projectId!,
    pageId: pageId!
  })

  return (
    <AdminLayout
      showFullscreenButton={false}
      renderHeaderContent={() => (
        <Header
          onLeaveClick={handleSave}
          isSaveLoading={false}
          publisherConnector={publisherConnector}
          isDocumentLoaded={isDocumentLoaded}
        />
      )}
      loadingPercent={loadingPercent}
      isFullWidth
    >
      <EditorIframe
        onBuild={setPublisherConnector}
        onDocumentLoad={() => setIsDocumentLoaded(true)}
        pageId={pageId!}
        workspaceId={WORKSPACE_ID}
        onLoadingUpdate={setLoadingPercent}
      />
    </AdminLayout>
  )
}

export default AdminProjectsEditor
