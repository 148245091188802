import { gql } from 'shared/types/layoutcreator'

export const GET_PROJECT_EXPORT = gql(/* GraphQL */ `
  query getProjectExport($id: String!) {
    getProjectExport(id: $id) {
      id
      groupId
      projectId
      status
      startedAt
      finishedAt
      outputProfile
      error
      pages {
        pageId
        status
        startedAt
        finishedAt
        pdfUrl
        error {
          code
          message
        }
      }
      author {
        id
        fullName
      }
    }
  }
`)

export const GET_PROJECT_EXPORTS = gql(/* GraphQL */ `
  query getProjectExports($groupId: String!, $projectId: String!) {
    getProjectExports(groupId: $groupId, projectId: $projectId) {
      id
      groupId
      projectId
      status
      startedAt
      finishedAt
      outputProfile
      error
      pages {
        pageId
        status
        startedAt
        finishedAt
        pdfUrl
        error {
          code
          message
        }
      }
      author {
        id
        fullName
      }
    }
  }
`)
