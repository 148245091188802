import { useMutation } from '@apollo/client'
import {
  DndContext,
  PointerSensor,
  UniqueIdentifier,
  useSensor,
  useSensors
} from '@dnd-kit/core'
import {
  SortableContext,
  arrayMove,
  rectSortingStrategy
} from '@dnd-kit/sortable'
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import styled from 'styled-components'

import useAuth from 'data/gradoo/hooks/useAuth'
import { SET_PAGES_ORDER } from 'data/layoutcreator/mutations/projects'
import { LiveUser } from 'shared/types/global'
import {
  ProjectDocument,
  ProjectDocumentPreview
} from 'shared/types/layoutcreator/graphql'

import useFetchImageBlob from '../hooks/useFetchImageBlob'
import useSessionStorage from '../hooks/useSessionStorage'
import Page from './components/Page'

type ProjectDocumentGroup = ProjectDocument[]
type ProjectDocumentGroups = ProjectDocumentGroup[]

type EditingLiveUsersMap = { [projectId: string]: LiveUser }

export const BLOB_URLS_STORAGE_KEY = 'blobUrls'

type DndPagesProps = {
  items: ProjectDocument[]
  customPreviews: ProjectDocumentPreview[]
  editingLiveUsersMap: EditingLiveUsersMap
  setItems: React.Dispatch<React.SetStateAction<any[]>>
  onDraggingProject: (previewUrl: string | null) => void
  gap?: number
  groupId: string
  projectId: string
}

const GroupWrapper = styled.div<{ view: string }>`
  display: flex;
  flex-direction: ${({ view }) =>
    view === 'grid' ? 'row' : 'column'};
  flex-wrap: wrap;
  gap: 0;
`

const PagesContainer = styled.div<{ view: string; gap: number }>`
  display: grid;
  grid-template-columns: ${({ view }) =>
    view === 'grid' ? 'repeat(4, 1fr)' : '1fr'};
  grid-auto-flow: row;
  flex-wrap: wrap;
  justify-content: left;
  max-width: 1200px;
  gap: ${({ view, gap }) => (view === 'grid' ? '21px' : '0')};
`

const DndPages = React.memo(
  ({
    items,
    customPreviews,
    editingLiveUsersMap,
    setItems,
    onDraggingProject,
    gap = 32,
    groupId,
    projectId
  }: DndPagesProps) => {
    const [setPagesOrder] = useMutation(SET_PAGES_ORDER)
    const [searchParams] = useSearchParams()
    const viewSearchParam = searchParams.get('view') || 'grid'
    const [activeId, setActiveId] = useState<UniqueIdentifier | null>(
      null
    )

    const blobUrlsRef = useRef<Record<string, string>>({})
    const prevItemsRef = useRef(items)
    const fetchImageBlob = useFetchImageBlob()
    const { setItem: setUpdatedPageBlob, getItem } =
      useSessionStorage<Record<string, string>>(BLOB_URLS_STORAGE_KEY)

    useEffect(() => {
      const storedBlobUrls = getItem()
      if (storedBlobUrls) {
        Object.keys(storedBlobUrls).forEach(id => {
          blobUrlsRef.current[id] = storedBlobUrls[id]
        })
      }
    }, [getItem])

    const getIndex = (id: UniqueIdentifier) =>
      items.findIndex(item => id === item.id)
    const activeIndex = activeId ? getIndex(activeId) : -1
    const sensors = useSensors(
      useSensor(PointerSensor, {
        activationConstraint: { distance: 4 }
      })
    )

    const customPreviewsMap = useMemo(() => {
      return customPreviews.reduce((acc, item) => {
        acc[item.id] = item.path
        return acc
      }, {} as { [key: string]: ProjectDocumentPreview['path'] })
    }, [customPreviews])

    const groupedPages = useMemo(() => {
      return items.reduce<ProjectDocumentGroups>((acc, page) => {
        if (acc.length === 0 || acc[acc.length - 1].length === 2) {
          acc.push([page])
        } else {
          acc[acc.length - 1].push(page)
        }
        return acc
      }, [])
    }, [items])

    useEffect(() => {
      const draggingProject = items.find(item => item.id === activeId)
      onDraggingProject(draggingProject?.previewUrl || null)
    }, [activeId, items, onDraggingProject])

    const updateBlobUrl = useCallback(
      async (item: ProjectDocument) => {
        let blobData = blobUrlsRef.current[item.id]

        const fetchAndStoreBlobData = async (url: string) => {
          const data = await fetchImageBlob(url)
          if (data) {
            blobUrlsRef.current[item.id] = data
            setUpdatedPageBlob(blobUrlsRef.current)
          }
        }

        if (!blobData) {
          await fetchAndStoreBlobData(item.previewUrl)
        }
      },
      []
    )

    useEffect(() => {
      const updateAllBlobUrls = async () => {
        for (const item of items) {
          await updateBlobUrl(item)
        }
        prevItemsRef.current = items
      }

      updateAllBlobUrls()
    }, [items, updateBlobUrl])

    return (
      <DndContext
        sensors={sensors}
        onDragStart={({ active }) => {
          if (!active) {
            return
          }
          setActiveId(active.id)
        }}
        onDragEnd={({ over }) => {
          setActiveId(null)
          if (over) {
            const overIndex = getIndex(over.id)
            if (activeIndex !== overIndex && overIndex !== 0) {
              const newOrderItems = arrayMove(
                items,
                activeIndex,
                overIndex
              )
              setItems(() => newOrderItems)
              return setPagesOrder({
                variables: {
                  projectId,
                  groupId,
                  pages: newOrderItems.map(({ id }, i) => ({
                    id,
                    index: i
                  }))
                }
              })
            }
          }
        }}
        onDragCancel={() => setActiveId(null)}
      >
        <SortableContext items={items} strategy={rectSortingStrategy}>
          <PagesContainer view={viewSearchParam} gap={gap}>
            {groupedPages.map((group, groupIndex) => (
              <GroupWrapper
                key={`group-${groupIndex}`}
                view={viewSearchParam}
              >
                {group.map((doc, docIndex) => {
                  const overallIndex = groupIndex * 2 + docIndex
                  return (
                    <Page
                      testID={`DndPage:${doc.id}`}
                      key={doc.id}
                      {...doc}
                      customPreviewPath={
                        blobUrlsRef.current[doc.id] ||
                        customPreviewsMap[doc.id] ||
                        null
                      }
                      index={overallIndex}
                      view={viewSearchParam}
                      editingLiveUser={editingLiveUsersMap[doc.id]}
                      groupId={groupId}
                      projectId={projectId}
                    />
                  )
                })}
              </GroupWrapper>
            ))}
          </PagesContainer>
        </SortableContext>
      </DndContext>
    )
  }
)

export default DndPages
