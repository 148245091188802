import Flex from 'components/Flex'
import {
  CircleSkeleton,
  InlineSkeleton,
  LinesSkeleton,
  Skeleton
} from 'components/Skeleton'

const QuotesItemSkeleton: React.FC = () => {
  return (
    <Skeleton
      colorLevel={3}
      padding={[24, 16]}
      radius={14}
      direction="column"
    >
      <LinesSkeleton
        lines={['90%', '100%', '100%', '95%', '80%']}
        gap={16}
      />

      <Flex direction="column" width="100%">
        <InlineSkeleton
          width={120}
          height={2}
          marginBottom={12}
          marginTop={32}
        />

        <Flex justifyContent="space-between" alignItems="center">
          <Flex columnGap={10} alignItems="center">
            <CircleSkeleton size={18} />
            <InlineSkeleton height={8} width={100} />
          </Flex>

          <InlineSkeleton width={20} height={10} />
        </Flex>
      </Flex>
    </Skeleton>
  )
}

export default QuotesItemSkeleton
