import { HelmetProvider } from 'react-helmet-async'
import { Toaster } from 'react-hot-toast'
import { IntlProvider } from 'react-intl'
import { BrowserRouter } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'

import ScreenSizeContainer from 'components/ScreenSizeContainer'
import useDisablePinchZoom from 'hooks/useDisablePinchZoom'
import { useIntercom } from 'hooks/useIntercom'
import 'styles/fonts.css'
import { theme } from 'styles/theme'
import { Locales } from 'types/global'

import Router from './components/Router'
import GlobalStyle from './styles/global'
import { TOASTER_Z_INDEX } from './styles/z-index'

type AppProps = {
  locale: Locales
  messages: Record<string, string>
}

const App: React.FC<AppProps> = ({ locale, messages }) => {
  useIntercom()
  useDisablePinchZoom()

  return (
    <>
      <GlobalStyle />
      <Toaster containerStyle={{ zIndex: TOASTER_Z_INDEX }} />
      <ThemeProvider theme={theme}>
        <IntlProvider locale={locale} messages={messages}>
          <HelmetProvider>
            <BrowserRouter>
              <ScreenSizeContainer>
                <Router />
              </ScreenSizeContainer>
            </BrowserRouter>
          </HelmetProvider>
        </IntlProvider>
      </ThemeProvider>
    </>
  )
}

export default App
