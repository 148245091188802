import { format, parseISO } from 'date-fns'
import { useIntl } from 'react-intl'
import { LineKey, useTextEditor } from 'react-markdown-editor'
import styled, { useTheme } from 'styled-components'

import Flex from 'components/Flex'
import Icon from 'components/IcomoonIcon'
import { ReportsInstanceDetailFragment } from 'types/gradoo/graphql'

import MarkdownText from './MarkdownText'

const Image = styled.img`
  width: 100%;
`

const Divider = styled.div`
  margin-top: 16px;
  margin-bottom: 12px;
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.color.base.c3};
`

const FooterRow = styled.div`
  display: flex;
  margin-top: 4px;
  flex-direction: row;
  align-items: center;
`

const FooterText = styled.span`
  margin-left: 4px;
  color: ${({ theme }) => theme.color.base.c5};
  font-family: Inter;
  font-size: 9px;
  line-height: 10px;
`

type MarkdownViewProps = {
  reportInstance?: ReportsInstanceDetailFragment | null
  onImageClick: (url: string) => void
}

export default function MarkdownView({
  reportInstance,
  onImageClick,
  ...rest
}: MarkdownViewProps): JSX.Element {
  const { formatMessage } = useIntl()
  const theme = useTheme()

  const { inputBlockInfoMap } = useTextEditor({
    initialMarkdownText: reportInstance?.text,
    platformMode: 'ios'
  })

  const inputBlockKeys = Object.keys(inputBlockInfoMap) as LineKey[]

  const text = Object.values(inputBlockInfoMap)
    .map(inputBlockInfo =>
      inputBlockInfo.type === 'text' ? inputBlockInfo.text : ''
    )
    .join('')
  const charCount = text.length

  const prefixBase64Url = (imageUrl: string) => {
    return `data:image/png;base64,${imageUrl}`
  }

  return (
    <Flex {...rest} direction="column">
      {inputBlockKeys.map((inputBlockKey, i) => {
        const inputBlockInfo = inputBlockInfoMap[inputBlockKey]
        if (inputBlockInfo.type === 'text') {
          const { text, textStyleMap, lineStyleMap } = inputBlockInfo
          return (
            <MarkdownText
              key={inputBlockKey}
              lineStyleMap={lineStyleMap}
              textStyleMap={textStyleMap}
            >
              {text}
            </MarkdownText>
          )
        } else {
          const { imgUrl } = inputBlockInfo
          return (
            <Image
              key={`ic${i}`}
              src={prefixBase64Url(imgUrl)}
              onClick={() => onImageClick(prefixBase64Url(imgUrl))}
            />
          )
        }
      })}
      <Divider />
      <Flex direction="column">
        <FooterRow>
          <Icon
            icon="clock-outline"
            size={12}
            color={theme.color.base.c5}
          />
          <FooterText>
            {formatMessage(
              { id: 'Panel.reports.lastUpdate' },
              {
                date: format(
                  parseISO(reportInstance?.modified),
                  'dd. MMM HH:mm'
                )
              }
            )}
          </FooterText>
        </FooterRow>
        <FooterRow>
          <Icon
            icon="menu-news-outline"
            size={12}
            color={theme.color.base.c5}
          />
          <FooterText>
            {formatMessage(
              { id: 'Panel.reports.words' },
              {
                words: charCount
              }
            )}
          </FooterText>
        </FooterRow>
      </Flex>
    </Flex>
  )
}
