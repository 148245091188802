import { useMutation } from '@apollo/client'
import { useState } from 'react'
import toast from 'react-hot-toast'

import Button, { ButtonIntents } from 'shared/ui/Button'
import Flex from 'shared/ui/Flex'
import Input from 'shared/ui/Input'
import Select from 'shared/ui/SelectOld'
import IslandModal from 'shared/components/modals/IslandModal'
import { START_MIGRATION } from 'data/layoutcreator/mutations/admin'
import { GET_PROJECT_DETAILS } from 'data/layoutcreator/queries/admin'

type StartImagesMigrationModalProps = {
  isOpen: boolean
  closeModal: () => void
  projectId: string
  groupId: string
}

enum MigrationTypes {
  full = 'full',
  externalOnly = 'externalOnly',
  internalOnly = 'internalOnly'
}

const MIGRATION_TYPES_MAP: Record<MigrationTypes, string> = {
  [MigrationTypes.full]: 'Full',
  [MigrationTypes.internalOnly]: 'Only internal images',
  [MigrationTypes.externalOnly]: 'Only external images'
}

const MIGRATION_TYPES = [
  MigrationTypes.full,
  MigrationTypes.internalOnly,
  MigrationTypes.externalOnly
]

const StartImagesMigrationModal: React.FC<
  StartImagesMigrationModalProps
> = ({ isOpen, closeModal, projectId, groupId }) => {
  const [typeIndex, setTypeIndex] = useState<number>(0)
  const [author, setAuthor] = useState('')

  const [startMigration] = useMutation(START_MIGRATION, {
    refetchQueries: [
      {
        query: GET_PROJECT_DETAILS,
        variables: { groupId, projectId }
      }
    ]
  })

  const handleStartMigration = async () => {
    const { data, errors } = await startMigration({
      variables: {
        projectId,
        groupId,
        author,
        type: MIGRATION_TYPES[typeIndex]
      }
    })

    closeModal()

    if (errors) {
      return toast.error('Migration start failed')
    }

    if (data) {
      toast('Migration started')
    }
  }

  return (
    <IslandModal
      isOpen={isOpen}
      closeModal={closeModal}
      width={400}
      title="Start images migration"
    >
      <Flex direction="column" rowGap={24}>
        <Select
          label="Type"
          activeIndex={typeIndex}
          onChange={(_, index) => setTypeIndex(index)}
          options={MIGRATION_TYPES.map(
            type => MIGRATION_TYPES_MAP[type]
          )}
        />

        <Input value={author} onChange={setAuthor} label="Author" />

        <Flex marginTop={12}>
          <Button
            labelId="Admin.buttons.startMigration"
            borderRadius={100}
            intent={ButtonIntents.dark}
            isFullWidth
            onClick={handleStartMigration}
          />
        </Flex>
      </Flex>
    </IslandModal>
  )
}

export default StartImagesMigrationModal
