import { gql } from 'types/gradoo'

export const MODULE_INSTANCE_RESULTS = gql(/* GraphQL */ `
  query ModuleInstanceResults(
    $after: String
    $first: Int
    $group: ID
  ) {
    moduleInstances(after: $after, first: $first, group: $group) {
      edges {
        node {
          ...ModuleInstanceResult
        }
      }
    }
  }
`)

export const MODULE_INSTANCE_USER_SUMMARY_RESULT = gql(/* GraphQL */ `
  query ModuleInstanceUserSummaryResult($id: ID!) {
    moduleInstance(id: $id) {
      ...ModuleInstanceUserSummaryResult
    }
  }
`)
