import { CircleSkeleton, InlineSkeleton } from 'shared/ui/Skeleton'

const ColorItemSkeleton: React.FC<{ width?: number }> = ({
  width
}) => (
  <InlineSkeleton width={width ? width : '100%'} padding={[4, 8]}>
    <CircleSkeleton size={22} colorLevel={3} />
  </InlineSkeleton>
)

export default ColorItemSkeleton
