import { FormEvent, ReactNode } from 'react'

type StepFormProps = {
  children: ReactNode
  onSubmit: (e: FormEvent) => void
}
const StepForm = ({ children, onSubmit }: StepFormProps) => (
  <form onSubmit={onSubmit}>{children}</form>
)

export default StepForm
