import { useLazyQuery, useMutation } from '@apollo/client'
import { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { useIntl } from 'react-intl'
import styled, { keyframes } from 'styled-components'

import Button from 'components/Button_2'
import Flex from 'components/Flex'
import Input from 'components/Input'
import Text from 'components/Text'
import YearbookCard from 'components/YearbookCardNew'
import YearbookCardSkeleton from 'components/YearbookCardNew/Skeleton'
import { RECOVER_DELETED_PROJECT } from 'data/layoutcreator/mutations/admin'
import { GET_ADMIN_PROJECTS } from 'data/layoutcreator/queries/admin'
import { isGroupId } from 'utils/gradoo'

const LAST_GROUP_ID_STORAGE_KEY = 'adminLastGroupId'

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(10px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
`

const ProjectsContainer = styled(Flex)`
  animation: ${fadeIn} 0.5s;
`

const ProjectsTab = () => {
  const { formatMessage } = useIntl()
  const lastGroupId = localStorage.getItem(LAST_GROUP_ID_STORAGE_KEY)
  const [groupId, setGroupId] = useState(lastGroupId || '')

  const [
    fetchProjects,
    {
      data: projectsData,
      error: projectsSearchError,
      loading: isProjectsLoading
    }
  ] = useLazyQuery(GET_ADMIN_PROJECTS)
  const [recoverDeletedProject] = useMutation(
    RECOVER_DELETED_PROJECT,
    {
      refetchQueries: [
        { query: GET_ADMIN_PROJECTS, variables: { groupId } }
      ]
    }
  )

  useEffect(() => {
    toast.remove()

    if (!isGroupId(groupId)) {
      toast.error(
        formatMessage({ id: 'Toasts.searchByGroupId.error' })
      )
      return
    }

    localStorage.setItem(LAST_GROUP_ID_STORAGE_KEY, groupId)

    fetchProjects({ variables: { groupId } })
  }, [groupId])

  const handleRecoverProject = async (id: string) => {
    const firstToast = toast.loading(
      formatMessage({ id: 'Toasts.recoverProject.loading' }),
      {
        icon: '🔧'
      }
    )

    try {
      await recoverDeletedProject({
        variables: { groupId, projectId: id }
      })
      toast.success(
        formatMessage({ id: 'Toasts.recoverProject.success' })
      )
    } catch (error) {
      toast.error(
        formatMessage({ id: 'Toasts.recoverProject.error' })
      )
    }

    toast.dismiss(firstToast)
  }

  return (
    <Flex direction="column">
      <Flex
        direction="column"
        marginTop={48}
        marginBottom={16}
        rowGap={16}
      >
        <Text
          tag="h1"
          id="Admin.projects.title"
          size={28}
          weight={600}
          marginBottom={16}
        />

        <Input
          width={600}
          label={formatMessage({
            id: 'Admin.projects.groupId.label'
          })}
          value={groupId}
          onChange={setGroupId}
          withBackground
        />
      </Flex>

      <Text
        tag="h2"
        id="Admin.projects.results"
        size={16}
        weight={600}
        marginTop={100}
        marginBottom={16}
      />

      <ProjectsContainer
        wrap="wrap"
        columnGap={16}
        rowGap={24}
        alignItems="flex-start"
      >
        {projectsData?.getAdminProjects.length === 0 &&
          formatMessage({ id: 'Admin.error.noProjectsFound' })}
        {projectsSearchError &&
          formatMessage({ id: 'Admin.error.errorSearchingGroup' })}
        {isProjectsLoading &&
          new Array(8).fill(null).map(() => <YearbookCardSkeleton />)}

        {projectsData?.getAdminProjects.map((project, i) => (
          <YearbookCard
            key={i}
            name={project.name}
            isDeleted={!!project.deleted}
            linkTo={
              project.id && !project.deleted
                ? `/admin/old/${project.id}`
                : null
            }
            pagePreviews={project.pagePreviews}
            append={() => (
              <Flex direction="column" rowGap={8}>
                <Text
                  tag="span"
                  text={
                    project.id ||
                    formatMessage({ id: 'Admin.message.missingMeta' })
                  }
                  size={10}
                  color={theme =>
                    !!project.id
                      ? theme.color.base.c6
                      : theme.color.error
                  }
                />

                {!!project.deleted && (
                  <Button
                    textId="Admin.buttons.recoverProject"
                    onPress={() => handleRecoverProject(project.id!)}
                    intent="secondary-contrast"
                  />
                )}
              </Flex>
            )}
          />
        ))}
      </ProjectsContainer>
    </Flex>
  )
}

export default ProjectsTab
