import { useIntl } from 'react-intl'
import styled from 'styled-components'

import { theme } from 'styles/theme'

import errorSvg from './icons/error.svg'
import successSvg from './icons/success.svg'
import warningSvg from './icons/warning.svg'

export enum INTENTS {
  warning = 'warning',
  error = 'error',
  success = 'success'
}

export enum BadgeSize {
  small = 'small',
  medium = 'medium',
  large = 'large'
}

type Intent = INTENTS.warning | INTENTS.error | INTENTS.success
type Size = BadgeSize.small | BadgeSize.medium | BadgeSize.large

type IntentProps = {
  color: string
  bg: string
  icon: string
}

type IntentMapProps = {
  [key in Intent]: IntentProps
}

const INTENT_MAP: IntentMapProps = {
  warning: {
    color: '#F9B403',
    bg: '#FFF9DC',
    icon: warningSvg
  },
  error: {
    color: '#E11D48',
    bg: '#f9ccd6',
    icon: errorSvg
  },
  success: {
    color: '#4E870D',
    bg: '#D8FFAC',
    icon: successSvg
  }
}

interface StyledDivProps {
  intent: Intent
  size: Size
}

const sizeMap = {
  small: {
    padding: '4px 14px',
    fontSize: '10px',
    borderRadius: '16px'
  },
  medium: {
    padding: '6px 12px',
    fontSize: '14px',
    borderRadius: '20px'
  },
  large: {
    padding: '8px 16px',
    fontSize: '16px',
    borderRadius: '24px'
  }
}

const StyledDiv = styled.div<StyledDivProps>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: ${({ size }) => sizeMap[size].padding};
  font-size: ${({ size }) => sizeMap[size].fontSize};
  font-weight: 600;
  color: ${({ intent }) => INTENT_MAP[intent].color};

  background-color: ${({ intent }) => INTENT_MAP[intent].bg};
  border-radius: ${({ size }) => sizeMap[size].borderRadius};
`

const Message = styled.div`
  align-items: center;
`

const Badge = ({
  intent = INTENTS.warning,
  size = BadgeSize.small,
  message,
  messageId
}: {
  intent: Intent
  size?: Size
  message?: string
  messageId?: string
}): JSX.Element => {
  const { formatMessage } = useIntl()
  return (
    <StyledDiv intent={intent} size={size}>
      <Message>
        {messageId ? formatMessage({ id: messageId }) : message}
      </Message>
    </StyledDiv>
  )
}

export default Badge
