import { useEffect } from 'react'
import { FieldErrors } from 'react-hook-form'
import { toast } from 'react-hot-toast'
import { useIntl } from 'react-intl'

type UseToastValidation = {
  formKey: string
  errors: FieldErrors<{ string: unknown }>
}

export function useToastValidation({
  formKey,
  errors
}: UseToastValidation) {
  const { formatMessage } = useIntl()

  useEffect(() => {
    const errorList = Object.entries(errors)
    if (errorList.length > 0) {
      const [field, error] = errorList[0]
      toast.dismiss()
      toast.error(
        <span>
          {formatMessage({
            id: `Project.${formKey}.${field}`
          })}{' '}
          — {formatMessage({ id: error.message })}
        </span>
      )
    }
  }, [errors])
}
