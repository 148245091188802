import { isProd, isStage } from 'shared/helpers/env'
import { EnvTypes, Locales } from 'shared/types/global'

const STAGE_REST_URL = 'https://api.layout.stg02.gradoo.tech/rest/'

const IMAGE_DAP_IDS: Record<EnvTypes, string> = {
  [EnvTypes.dev]: '7c616ffd-1a3e-4c5e-8144-ccc33b439153',
  [EnvTypes.stage]: 'd7cf9e6e-c3f5-40d1-9d9c-22c9974a6d8c',
  [EnvTypes.prod]: '05a8ac1d-7c6e-423d-9df9-a17504eef8f1'
}

const EXTERNAL_IMAGE_DAP_IDS: Record<EnvTypes, string> = {
  [EnvTypes.dev]: '73a76b29-6775-4529-828d-d9a4f5e59ad7',
  [EnvTypes.stage]: 'b14a8607-a186-40de-b2e1-c446d3d56598',
  [EnvTypes.prod]: 'f5fa36ad-5c5c-4695-8a9c-b0ce88a3abe3'
}

const CHILI_IMAGE_DAP_IDS: Record<EnvTypes, string> = {
  [EnvTypes.dev]: '3d5502e0-6519-4eff-8358-824fa704d942',
  [EnvTypes.stage]: '3d5502e0-6519-4eff-8358-824fa704d942',
  [EnvTypes.prod]: 'b6d7b417-dff5-4e40-9560-f89167600de5'
}

const IMAGES_DAP_VALUE_ENDPOINT = 'images%data%'
const EXTERNAL_IMAGES_DAP_VALUE_ENDPOINT =
  'images/external?imageUrl=%data%'
const CHILI_IMAGES_DAP_VALUE_ENDPOINT = 'images/chili?query=%data%'

const CHILI_ENV = isProd() ? 'cp-ehn-483' : 'cp-yis-415'

const config = {
  // Debug mode may be used if you need to get specific behavior in some parts of the app for debugging purposes
  debugMode: !isStage() ? true : false,
  defaultFont: 'Inter',
  defaultLocale: Locales.de,
  supportedLocales: [Locales.de, Locales.en],
  intercomAppId: 'af64lt83',
  chiliEnvironmentUrl: `https://${CHILI_ENV}.chili-publish${
    !isProd() ? '-sandbox' : ''
  }.online/${CHILI_ENV}`,
  chiliWorkspaceId: 'db69f975-2eda-4389-84d3-4166b349019e',
  chiliAdminWorkspaceId: isProd() ? '9fcd584b-429c-4e61-b2c1-8f0c9ca04ff0' : '29962a73-49f9-4864-bc8c-f0b07eab9dd6',
  chiliViewPreferencesId: isProd()
    ? '0341022f-5ade-4021-9d8a-21a591a7d3ac'
    : 'd22b43ec-eafa-4da7-91f4-def560f21a7b',
  images: {
    DAPIds: IMAGE_DAP_IDS,
    externalDAPIds: EXTERNAL_IMAGE_DAP_IDS,
    devDAPValue: (width: number, height: number, text = 'image') =>
      `${width}x${height}/png?text=${text}`,
    DAPUrls: {
      [IMAGE_DAP_IDS[
        EnvTypes.dev
      ]]: `${STAGE_REST_URL}${IMAGES_DAP_VALUE_ENDPOINT}`,
      [IMAGE_DAP_IDS[
        EnvTypes.stage
      ]]: `${process.env.REACT_APP_REST_URL}${IMAGES_DAP_VALUE_ENDPOINT}`,
      [IMAGE_DAP_IDS[
        EnvTypes.prod
      ]]: `${process.env.REACT_APP_REST_URL}${IMAGES_DAP_VALUE_ENDPOINT}`,

      [EXTERNAL_IMAGE_DAP_IDS[
        EnvTypes.dev
      ]]: `${STAGE_REST_URL}${EXTERNAL_IMAGES_DAP_VALUE_ENDPOINT}`,
      [EXTERNAL_IMAGE_DAP_IDS[
        EnvTypes.stage
      ]]: `${process.env.REACT_APP_REST_URL}${EXTERNAL_IMAGES_DAP_VALUE_ENDPOINT}`,
      [EXTERNAL_IMAGE_DAP_IDS[
        EnvTypes.prod
      ]]: `${process.env.REACT_APP_REST_URL}${EXTERNAL_IMAGES_DAP_VALUE_ENDPOINT}`,

      [CHILI_IMAGE_DAP_IDS[
        EnvTypes.dev
      ]]: `${STAGE_REST_URL}${CHILI_IMAGES_DAP_VALUE_ENDPOINT}`,
      [CHILI_IMAGE_DAP_IDS[
        EnvTypes.stage
      ]]: `${process.env.REACT_APP_REST_URL}${CHILI_IMAGES_DAP_VALUE_ENDPOINT}`,
      [CHILI_IMAGE_DAP_IDS[
        EnvTypes.prod
      ]]: `${process.env.REACT_APP_REST_URL}${CHILI_IMAGES_DAP_VALUE_ENDPOINT}`
    } as Record<string, string>
  },
  themes: {
    pageBackgrounds: {
      DAPIds: {
        [EnvTypes.dev]: 'c4d89887-ed7d-4030-a856-2b68c6600f9f',
        [EnvTypes.stage]: 'e0d383a2-0875-461a-937d-cf58dad2dc03',
        [EnvTypes.prod]: '29659e90-d3a5-482f-b59f-a5e62ab1fb34'
      },
      devDAPValue: 'hummingbird-8013214_1280.jpg'
    },
    variablesKeys: {
      backgroundImage: 'background_image',
      themeColorPrefix: 'template_color_',
      themeColor: (number: number) =>
        `${config.themes.variablesKeys.themeColorPrefix}${number}`,
      pageNumberColor: 'page_number_color',
      pageNumberVisibility: 'page_number_visibility',
      pageNumber: 'page_number'
    }
  },
  intercom: {
    articles: {
      orderHelp: 6532154
    }
  },
  maintainableScreenWidthBreakpoint: 750,
  projectNameRegExp: /^[A-Za-z0-9]+(?:\s[A-Za-z0-9]+)*$/
}

export default config
