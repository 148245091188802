import { useCallback } from 'react'

const useFetchImageBlob = () => {
  const fetchImageBlob = useCallback(
    async (url: string): Promise<string | null> => {
      try {
        const response = await fetch(url)
        const contentType = response.headers.get('content-type')
        if (!contentType || !contentType.startsWith('image/')) {
          return null
        }
        const blob = await response.blob()
        return new Promise<string>((resolve, reject) => {
          const reader = new FileReader()
          reader.onloadend = () => {
            resolve(reader.result as string)
          }
          reader.onerror = reject
          reader.readAsDataURL(blob)
        })
      } catch (error) {
        console.error('Error fetching image blob:', error)
        return null
      }
    },
    []
  )

  return fetchImageBlob
}

export default useFetchImageBlob
