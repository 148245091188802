import { useQuery } from '@apollo/client'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { useIntl } from 'react-intl'

import Button from 'shared/ui/Button_2'
import Flex from 'shared/ui/Flex'
import FormInput from 'shared/ui/FormInput'
import { IconName } from 'shared/ui/Icon'
import Spinner from 'shared/ui/Spinner'
import StepsBar from 'shared/ui/StepsBar'
import useAuth from 'data/gradoo/hooks/useAuth'
import { GET_DELIVERY_ADDRESS } from 'data/layoutcreator/queries/erp'
import { DeliveryAddressEntity } from 'shared/types/layoutcreator/graphql'

import { useToastValidation } from '../../hooks/useToastValidation'
import {
  ProductionModalStepIds,
  ProductionModalStepProps,
  Steps
} from '../../index'
import { verifyAddressSchema } from '../../schemas'
import FormHeader from '../FormHeader'
import ModalTitle from '../ModalTitle'
import CountryInput from './components/CountryInput'
import PhoneNumberInput, {
  PhoneNumberValue
} from './components/PhoneNumberInput'
import { CountryIDs } from './components/SelectCountryModal'

export const VERIFY_ADDRESS_LOCAL_STORAGE_KEY = 'verify-address-json'

export type FieldValues = Omit<DeliveryAddressEntity, '__typename'> &
  PhoneNumberValue

const defaultValues: FieldValues = {
  firstName: '',
  lastName: '',
  phoneNumber: '',
  streetAndNumber: '',
  companyOrAdditionalInfo: '',
  zip: '',
  city: '',
  countryCode: ''
}

const fieldToIconMap: Partial<Record<keyof FieldValues, IconName>> = {
  firstName: 'user',
  lastName: 'user',
  streetAndNumber: 'map',
  companyOrAdditionalInfo: 'home'
}

const gradooCountryCodeToLowerCase = (
  countryCode: string | undefined
): CountryIDs => (countryCode || 'de').toLowerCase() as CountryIDs

const VerifyAddress = ({
  setStep,
  closeModal,
  orderNumber
}: ProductionModalStepProps) => {
  const { formatMessage } = useIntl()

  const {
    authUserGroupYear,
    authUserInstituteInternalId,
    authUserPhone,
    loading: authLoading
  } = useAuth()

  const { data, loading } = useQuery(GET_DELIVERY_ADDRESS, {
    variables: {
      orderNumber: orderNumber as string,
      year: authUserGroupYear as number,
      customerNumber: authUserInstituteInternalId as string
    },
    skip: !authUserGroupYear || !authUserInstituteInternalId
  })

  let values
  if (data?.getDeliveryAddress) {
    const { __typename, ...address } = data.getDeliveryAddress
    values = {
      ...address,
      phoneNumber: authUserPhone?.number || ''
    }
  }

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<FieldValues>({
    resolver: yupResolver<FieldValues>(verifyAddressSchema),
    defaultValues,
    values: values || defaultValues
  })

  const onSubmit = (data: FieldValues) => {
    localStorage.setItem(
      VERIFY_ADDRESS_LOCAL_STORAGE_KEY,
      JSON.stringify(data)
    )
    setStep(ProductionModalStepIds.setupPrintData)
  }

  const areFieldsDisabled =
    !data?.getDeliveryAddress || loading || authLoading

  useToastValidation({ formKey: 'verify.address', errors })

  return (
    <>
      <div>
        <Flex justifyContent="space-between">
          <ModalTitle>
            {formatMessage({ id: 'AdminProject.verify.address.title' })}
          </ModalTitle>
          {loading || authLoading ? <Spinner /> : ''}
        </Flex>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Flex direction="row" columnGap={90} marginBottom={60}>
            <Flex direction="column" flex={1}>
              <FormHeader>
                {formatMessage({
                  id: 'AdminProject.verify.address.receiver'
                })}
              </FormHeader>
              <Flex columnGap={16} wrap="wrap">
                {Object.keys(defaultValues)
                  .slice(0, 2)
                  .map((key, index) => (
                    <Flex
                      key={key}
                      width={index > 1 ? '100%' : 'calc(50% - 8px)'}
                    >
                      <FormInput<FieldValues>
                        id={key as keyof FieldValues}
                        label={formatMessage({
                          id: `Project.verify.address.${key}`
                        })}
                        register={register}
                        isDisabled={areFieldsDisabled}
                        isFullWidth
                        hideErrors
                        errors={errors}
                        iconName={
                          fieldToIconMap[key as keyof FieldValues]
                        }
                      />
                    </Flex>
                  ))}
              </Flex>
              <PhoneNumberInput
                id="phoneNumber"
                label={formatMessage({
                  id: 'AdminProject.verify.address.phoneNumber'
                })}
                register={register}
                isDisabled={areFieldsDisabled}
                hideErrors
                errors={errors}
                countryCode={gradooCountryCodeToLowerCase(
                  data?.getDeliveryAddress?.countryCode
                )}
              />
            </Flex>
            <Flex direction="column" flex={1}>
              <FormHeader>
                {formatMessage({
                  id: 'AdminProject.verify.address.address'
                })}
              </FormHeader>
              <Flex columnGap={16} wrap="wrap">
                {Object.keys(defaultValues)
                  .slice(3, -1)
                  .map((key, index) => (
                    <Flex
                      key={key}
                      width={index < 2 ? '100%' : 'calc(50% - 8px)'}
                    >
                      <FormInput<FieldValues>
                        id={key as keyof FieldValues}
                        label={formatMessage({
                          id: `Project.verify.address.${key}`
                        })}
                        register={register}
                        isDisabled={areFieldsDisabled}
                        isFullWidth
                        hideErrors
                        errors={errors}
                        iconName={
                          fieldToIconMap[key as keyof FieldValues]
                        }
                      />
                    </Flex>
                  ))}
              </Flex>
              <CountryInput
                countryCode={authUserPhone?.country || 'de'}
              />
            </Flex>
          </Flex>
          <Flex justifyContent="space-between">
            <Button
              type="button"
              intent="secondary-base"
              textId="Project.cancel"
              onPress={closeModal}
              size="xl"
            />
            <StepsBar
              stepId={ProductionModalStepIds.verifyAddress}
              steps={Steps}
            />
            <Button
              type="submit"
              intent="primary-brand-02"
              textId="Project.continue"
              isDisabled={areFieldsDisabled}
              size="xl"
            />
          </Flex>
        </form>
      </div>
    </>
  )
}

export default VerifyAddress
