import { LinesSkeleton, Skeleton } from 'shared/ui/Skeleton'

const CollageItemSkeleton: React.FC = () => (
  <Skeleton
    padding={[18, 12]}
    height={140}
    radius={14}
    alignItems="flex-end"
  >
    <LinesSkeleton
      lines={['100%', '70%']}
      height={10}
      gap={12}
      colorLevel={3}
    />
  </Skeleton>
)

export default CollageItemSkeleton
