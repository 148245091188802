import { getRestUrl } from 'shared/helpers/url'

export const getDownloadPdfUrl = ({
  exportId,
  groupId,
  projectId
}: {
  exportId: string
  groupId: string
  projectId: string
}): string =>
  getRestUrl(
    `export/pdf/${exportId}?groupId=${groupId}&projectId=${projectId}`
  )

export const downloadPdf = ({
  exportId,
  groupId,
  projectId
}: {
  exportId: string
  groupId: string
  projectId: string
}) => {
  const link = document.createElement('a')
  link.href = getDownloadPdfUrl({ exportId, groupId, projectId })
  link.download = 'file.pdf'
  link.style.display = 'none'
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}
