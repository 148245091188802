import { useQuery } from '@apollo/client'
import { useIntl } from 'react-intl'
import ReactModal from 'react-modal'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'

import Button from 'components/Button_2'
import AnimatedModal from 'components/modals/AnimatedModal'
import useAuth from 'data/gradoo/hooks/useAuth'
import { GET_PROJECT_TEMPLATES } from 'data/layoutcreator/queries/projects'

import PageTypes from './components/PageTypes'

export const CancelButton = styled.a`
  margin-top: 86px;
  color: ${({ theme }) => theme.color.base.c5};
  text-decoration: underline;
  cursor: pointer;
`

const Title = styled.h2`
  margin: 0;
  font-size: 24px;
  font-weight: 600;
`

const Description = styled.p`
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  margin-top: 4px;
  color: ${({ theme }) => theme.color.base.c6};
  margin-bottom: 50px;
`

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 60px;
`

interface NewPageModalProps extends ReactModal.Props {
  closeModal: () => void
}

const NewPageModal = ({ isOpen, closeModal }: NewPageModalProps) => {
  const { formatMessage } = useIntl()
  const { pathname } = useLocation()
  const projectId = pathname.split('/')[1]
  const groupId = useAuth().authGroupId!

  // TODO: Implement onUploadPDF
  // const onUploadPDF = () => {}

  const { data: projectTemplates } = useQuery(GET_PROJECT_TEMPLATES, {
    variables: {
      projectId,
      groupId
    }
  })

  const templates = projectTemplates?.getProjectTemplates || []
  const currentTemplate = templates.find(
    template => template.isCurrent
  )
  const currentTemplateName = currentTemplate?.name || ''

  const description = formatMessage(
    {
      id: 'Add.page.description'
    },
    {
      themeName: currentTemplateName
    }
  )

  return (
    <AnimatedModal
      isOpen={isOpen}
      closeModal={closeModal}
      width={700}
    >
      <Title>{formatMessage({ id: 'Add.page.title' })}</Title>
      <Description>{description}</Description>
      <PageTypes closeModal={closeModal} />
      <Footer>
        <Button
          intent="secondary-base"
          textId="Add.page.cancel"
          onPress={closeModal}
          size="sm"
        />
        {/* <Button
          icon="left"
          iconName="upload"
          intent="sub-action"
          textId="Add.page.button.upload"
          onPress={onUploadPDF}
          size="sm"
        /> */}
      </Footer>
    </AnimatedModal>
  )
}

export default NewPageModal
