import { gql } from 'shared/types/layoutcreator'

export const GET_PRODUCTION_EXPORTS = gql(/* GraphQL */ `
  query getProductionExports($groupId: String!, $projectId: String!) {
    getProductionExports(groupId: $groupId, projectId: $projectId) {
      id
      author {
        fullName
      }
      type
      status
      startedAt
      finishedAt
      pages
      groupId
    }
  }
`)
