import styled, { css } from 'styled-components'

import { VIEW_TYPES } from '../../enum'
import Overlay from '../PageTools/components/Overlay'

export const PagePreview = styled.div<{ previewUrl: string | null }>`
  position: relative;
  overflow: hidden;
  width: 134px;
  height: 190px;
  background: url(${({ previewUrl }) => previewUrl}) center / cover;
  transition: border-radius 0.2s ease-out;

  &:hover ${Overlay} {
    opacity: 1;
  }
  @media screen and (max-width: 1194px) {
    width: 103px;
    height: 146px;
  }
`

const PageContainer = styled.div<{
  isDragging: boolean
  isDisabled: boolean
  view: string
  isEven: boolean
}>`
  position: relative;
  display: flex;
  flex-direction: column;
  cursor: ${({ isDragging, isDisabled }) =>
    isDisabled ? 'not-allowed' : isDragging ? 'grabbing' : 'grab'};

  ${({ view, isDragging }) =>
    view === VIEW_TYPES.list &&
    css`
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 560px;
      height: 64px;
      padding: 0 16px;
      margin: 0 auto 8px;
      background-color: ${({ theme }) => theme.color.base.c0};
      border: 1px solid ${({ theme }) => theme.color.base.c2};
      border-radius: 5px;
      box-shadow: ${isDragging
        ? '0px 3px 3px 0px rgba(0, 0, 0, 0.05)'
        : 'none'};

      &:nth-of-type(2n) {
        margin-bottom: 24px;
      }
    `}

  ${({ view, isEven }) =>
    view === VIEW_TYPES.grid &&
    css`
      &:nth-of-type(5n) {
        margin-right: 20px;
      }

      &:nth-child(8n) {
        margin-right: 0;
      }
      border-top-right-radius: ${isEven ? '8px' : '0'};
      border-bottom-right-radius: ${isEven ? '8px' : '0'};
      border-top-left-radius: ${isEven ? '0' : '8px'};
      border-bottom-left-radius: ${isEven ? '0' : '8px'};
    `}


  & ${PagePreview} {
    display: ${({ view }) =>
      view === VIEW_TYPES.list ? 'none' : 'inherit'};
    border-radius: ${({ isDragging }) =>
      isDragging ? '8px' : 'inherit'};
    box-shadow: ${({ isDragging }) =>
      isDragging ? '0px 3px 3px 0px rgba(0, 0, 0, 0.05)' : 'none'};
  }
`

export default PageContainer
