import styled from 'styled-components'

import Flex from 'shared/ui/Flex'
import { ProjectExportStatuses } from 'screens/AdminOldProject/types'

import ExportStatus from '../../../ExportStatus'

const Error = styled.div`
  display: none;
  position: absolute;
  top: -100%;
  right: 0;
  padding: 14px;
  color: ${({ theme }) => theme.color.base.c7};
  background-color: ${({ theme }) => theme.color.base.c0};
  font-size: 14px;
  box-shadow: -3px 8px 24px 0px rgba(66, 68, 90, 0.3);
  border-radius: 12px;
`

const Container = styled(Flex)<{ isSelected: boolean }>`
  position: relative;
  align-items: center;
  height: 80px;
  border: 1px solid
    ${({ theme, isSelected }) =>
      isSelected ? theme.color.brand_02 : theme.color.base.c3};
  overflow: visible;
  border-radius: 8px;
  box-sizing: border-box;
  cursor: pointer;

  &:hover ${Error} {
    display: block;
  }
`

const Number = styled(Flex)<{ isSelected: boolean }>`
  width: 30px;
  height: 100%;
  background: ${({ theme, isSelected }) =>
    isSelected ? theme.color.brand_02 : theme.color.base.c3};
  border-radius: 6px 0 0 6px;
  color: ${({ theme, isSelected }) =>
    isSelected ? theme.color.base.c0 : theme.color.base.c7};
  justify-content: center;
  align-items: center;
`

const Content = styled(Flex)`
  flex: 1;
  flex-direction: column;
  padding: 10px;
`

const Name = styled.span`
  font-weight: 600;
  margin-bottom: 10px;
`

const Field = styled.span`
  font-size: 14px;
`

type ExportDocumentProps = {
  id: string
  name: string
  status?: ProjectExportStatuses
  number: number
  error?: {
    code?: string
    message?: string
  }
  isSelected?: boolean
  onClick?: () => void
}

const ExportDocument: React.FC<ExportDocumentProps> = ({
  id,
  number,
  name,
  status,
  isSelected = false,
  error,
  ...rest
}) => {
  return (
    <Container isSelected={isSelected} {...rest}>
      <Number isSelected={isSelected}>{number}</Number>

      <Content>
        <Flex
          alignItems="center"
          justifyContent="space-between"
          flex={1}
        >
          <Name>{name}</Name>

          {status && <ExportStatus value={status} />}
        </Flex>
        <Field>ID: {id}</Field>
      </Content>

      {(error?.code || error?.message) && (
        <Error>
          <div>
            <b>Code:</b> {error.code}
          </div>
          <div>
            <b>Message:</b> {error.message}
          </div>
        </Error>
      )}
    </Container>
  )
}

export default ExportDocument
