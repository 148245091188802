import { useMutation, useQuery } from '@apollo/client'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import useAuth from 'data/gradoo/hooks/useAuth'
import { UNLINK_ORDER } from 'data/layoutcreator/mutations/projects'
import { GET_PROJECT_META } from 'data/layoutcreator/queries/projects'
import Button from 'shared/ui/Button_2'
import Flex from 'shared/ui/Flex'

import LinkOrderModal from './components/LinkOrderModal'
import OrderBlockActionsSkeleton from './components/OrderBlockActionsSkeleton'

const OrderNumberPiece = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 36px;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.color.base.c1};
  border: 1px solid ${({ theme }) => theme.color.base.c3};
  box-shadow: 0px 1.83088px 1.83088px 0px rgba(0, 0, 0, 0.05);
  font-size: 14px;
  font-weight: 600;
`

type OrderBlockProps = {
  groupId: string
  projectId: string
}

const OrderBlock: React.FC<OrderBlockProps> = ({
  groupId,
  projectId
}) => {
  const projectVars = {
    groupId,
    projectId
  }

  const navigate = useNavigate()
  const { authUserGroupYear, authUserInstituteInternalId } = useAuth()

  const [isLinkOrderModalOpen, setIsLinkOrderModalOpen] =
    useState(false)

  const { data: projectMetaData, loading: projectMetaLoading } =
    useQuery(GET_PROJECT_META, {
      variables: projectVars
    })

  const [unlinkOrder] = useMutation(UNLINK_ORDER, {
    refetchQueries: [
      { query: GET_PROJECT_META, variables: projectVars }
    ]
  })

  const handleOrderUnlink = () => {
    if (projectMetaData?.getProjectMeta.order) {
      unlinkOrder({
        variables: {
          ...projectVars,
          orderNumber: projectMetaData.getProjectMeta.order,
          year: authUserGroupYear as number,
          customerNumber: authUserInstituteInternalId as string
        }
      })
    }
  }

  return (
    <>
      {projectMetaLoading ? (
        <OrderBlockActionsSkeleton />
      ) : (
        projectMetaData?.getProjectMeta?.order && (
          <Flex alignItems="center" columnGap={17}>
            <Flex columnGap={5}>
              {projectMetaData?.getProjectMeta.order
                .split('')
                .map((number, index) => (
                  <OrderNumberPiece key={index}>
                    {number}
                  </OrderNumberPiece>
                ))}
            </Flex>

            <Button
              textId='Project.settings.order.block.buttons.unlink'
              intent="destructive"
              onPress={handleOrderUnlink}
            />
            <Button
              textId="Project.settings.order.block.buttons.export"
              intent="primary-brand-02"
              onPress={() =>
                navigate('export', {
                  state: {
                    orderNumber: '123123'
                  }
                })
              }
            />
          </Flex>
        )
      )}

      {projectMetaData?.getProjectMeta?.order === null && (
        <>
          <Button
            textId="Project.settings.order.block.buttons.link"
            onPress={() => setIsLinkOrderModalOpen(true)}
            intent="secondary-contrast"
          />

          <Button
            textId="Project.settings.order.block.buttons.getOffer"
            onPress={() => {}}
            intent="primary-brand-02"
          />
        </>
      )}

      <LinkOrderModal
        isOpen={isLinkOrderModalOpen}
        closeModal={() => setIsLinkOrderModalOpen(false)}
      />
    </>
  )
}

export default OrderBlock
