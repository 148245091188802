import React from 'react'
import styled, { css } from 'styled-components'

import Flex from 'components/Flex'
import { StyledCss } from 'styles/types'

enum Intents {
  primary = 'primary',
  free = 'free'
}

const CONTAINER_STYLES_MAP: Record<Intents, StyledCss> = {
  [Intents.primary]: css`
    background: ${({ theme }) => theme.color.base.c1};
    border-radius: 5px;
  `,
  [Intents.free]: css`
    height: 38px;
  `
}

const Container = styled(Flex)<{ intent: Intents }>`
  ${({ intent }) => CONTAINER_STYLES_MAP[intent]}
  ${({ width }) =>
    width &&
    `width: ${typeof width === 'string' ? width : `${width}px`}`};
  align-items: center;
`

const TAB_STYLES_MAP: Record<Intents, StyledCss> = {
  [Intents.primary]: css`
    font-size: 12px;
  `,
  [Intents.free]: css`
    font-size: 16px;
    color: ${({ theme }) => theme.color.base.c4};
  `
}

const ACTIVE_TAB_STYLES_MAP: Record<Intents, StyledCss> = {
  [Intents.primary]: css`
    height: 26px;
    background: ${({ theme }) => theme.color.brand_02};
    color: ${({ theme }) => theme.color.base.c0};
    box-shadow: -3px 3px 4px rgba(0, 0, 0, 0.15);
  `,
  [Intents.free]: css`
    color: ${({ theme }) => theme.color.brand_02};
  `
}

type TabProps = {
  isActive: boolean
  onClick: () => void
  intent: Intents
}

const Tab = styled(Flex)<TabProps>`
  flex: 1;
  justify-content: center;
  align-items: center;
  height: 100%;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 600;
  ${({ intent }) => TAB_STYLES_MAP[intent]}

  ${({ isActive, intent }) =>
    isActive && ACTIVE_TAB_STYLES_MAP[intent]}
`

const Divider = styled.div`
  height: 23px;
  width: 1px;
  background: ${({ theme }) => theme.color.base.c3};
`

type TabBarProps = {
  tabs: string[]
  activeIndex: number
  intent?: Intents
  onChange: (index: number) => void
  width?: string | number
}

const TabBar: React.FC<TabBarProps> = ({
  tabs,
  activeIndex,
  intent = Intents.primary,
  onChange,
  width
}) => {
  const handleTabClick = (index: number) => {
    if (activeIndex !== index) {
      onChange(index)
    }
  }

  return (
    <Container intent={intent} width={width}>
      {tabs.map((tab, i) => (
        <React.Fragment key={i}>
          <Tab
            intent={intent}
            isActive={activeIndex === i}
            onClick={() => handleTabClick(i)}
          >
            {tab}
          </Tab>
          {intent === Intents.free && i + 1 !== tabs.length && (
            <Divider />
          )}
        </React.Fragment>
      ))}
    </Container>
  )
}

export { Intents as TabBarIntents }
export default TabBar
