import { forwardRef } from 'react'
import toast from 'react-hot-toast'
import { useIntl } from 'react-intl'
import styled from 'styled-components'

import { isSupportedFileType } from 'helpers/file'
import { FileTypes } from 'types/global'

const Input = styled.input.attrs({
  type: 'file'
})`
  display: none;
`

type FileUploadProps = {
  onSelect: (file: File) => void
  type?: FileTypes
}

const FileUpload = forwardRef<HTMLInputElement, FileUploadProps>(
  ({ onSelect, type, ...rest }, ref) => {
    const { formatMessage } = useIntl()

    const handleChange: React.ChangeEventHandler<
      HTMLInputElement
    > = event => {
      const file = event.target.files?.[0]

      if (!file) {
        return
      }

      if (type === FileTypes.image && !isSupportedFileType(file)) {
        toast.error(
          formatMessage({
            id: 'FileUpload.image.wrongFormat'
          })
        )
        return
      }

      onSelect(file)
      event.target.value = ''
    }

    return <Input {...rest} ref={ref} onChange={handleChange} />
  }
)

export default FileUpload
