import { useMemo, useState } from 'react'
import styled from 'styled-components'

import { ReactComponent as DropdownIcon } from 'shared/assets/icons/chevron-down.svg'
import useClickOutside from 'shared/hooks/useClickOutside'

const StyledDropdownIcon = styled(DropdownIcon)<{
  position: 'up' | 'down'
}>`
  transform: rotate(
    ${({ position }) => (position === 'up' ? '180deg' : '0')}
  );
  transition: transform 0.2s;
`

const Container = styled.div<{ ref: any }>`
  position: relative;
`

const Activator = styled.div<{ width?: number; isDisabled: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: ${({ width }) => (width ? `${width}px` : '100%')};
  cursor: ${({ isDisabled }) =>
    isDisabled ? 'not-allowed' : 'pointer'};
  border: 1px solid ${({ theme }) => theme.color.base.c3};
  background: ${({ theme }) => theme.color.base.c0};
  border-radius: 100px;
  height: 34px;
  padding: 0 8px;
`

const Value = styled.div<{ noOptions: boolean }>`
  font-size: 14px;
  font-weight: 600;
  color: ${({ theme, noOptions }) =>
    noOptions ? theme.color.base.c6 : theme.color.base.c9};
`

const Dropdown = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.color.base.c7};
  background: ${({ theme }) => theme.color.base.c0};
  border: 1px solid ${({ theme }) => theme.color.base.c1};
  left: 0;
  right: 0;
  border-radius: 12px;
  padding: 4px 0;
  max-height: 200px;
  overflow: auto;
  z-index: 9999;
`

const Option = styled.div`
  cursor: pointer;
  padding: 8px 8px;
  font-size: 14px;

  :hover {
    background: ${({ theme }) => theme.color.base.c1};
  }
`

type SelectProps = {
  width?: number
  options: string[]
  activeIndex: number
  onChange: (newValue: string, index: number) => void
  isDisabled?: boolean
  noOptionsMessage?: string
}

const Select: React.FC<SelectProps> = ({
  options,
  width,
  activeIndex,
  onChange,
  isDisabled,
  noOptionsMessage
}) => {
  const [isOpen, setIsOpen] = useState(false)

  const toggleIsOpen = () =>
    setIsOpen(!isDisabled && !!options.length ? !isOpen : isOpen)

  const value = useMemo(() => options[activeIndex], [activeIndex])

  const ref = useClickOutside(() => setIsOpen(false))

  const handleOptionSelect = (index: number) => {
    onChange(options[index], index)
    setIsOpen(false)
  }

  return (
    <Container ref={ref}>
      <Activator
        onClick={toggleIsOpen}
        width={width}
        isDisabled={isDisabled || !options.length}
      >
        <Value noOptions={!options.length}>
          {!options.length ? noOptionsMessage : value}
        </Value>
        <StyledDropdownIcon position={isOpen ? 'up' : 'down'} />
      </Activator>

      <Dropdown style={{ display: isOpen ? 'flex' : 'none' }}>
        {options.map((option, i) => (
          <Option key={i} onClick={() => handleOptionSelect(i)}>
            {option}
          </Option>
        ))}
      </Dropdown>
    </Container>
  )
}

export default Select
