import { Tooltip } from 'react-tooltip'
import styled from 'styled-components'

import { mapUserName } from 'helpers/mappers'
import { getLiveHexColor } from 'styles/utils'
import { LiveUser } from 'types/global'

const Container = styled.a<{ index: number; size: number }>`
  width: ${({ size }) => size + 4}px;
  height: ${({ size }) => size + 4}px;
  border-radius: ${({ size }) => (size + 4) / 2}px;
  background-color: ${({ index }) => getLiveHexColor(index)};

  padding: 2px;
`

const CircleView = styled.div<{ size: number }>`
  display: flex;
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  border-radius: ${({ size }) => size / 2}px;
  background-color: ${({ theme }) => theme.color.base.c2};
  border: ${({ theme }) => theme.color.base.c0} 1px solid;

  padding: 1px;
  align-items: center;
  justify-content: center;
`

const Avatar = styled.img<{ size: number }>`
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  border-radius: ${({ size }) => size / 2}px;
`

const Text = styled.div`
  font-weight: 600;
  font-size: 14px;
  text-align: center;
  color: ${({ theme }) => theme.color.base.c0};
`

export type LiveAvatarProps = {
  liveUser: LiveUser
  size?: number
  enableTooltip?: boolean
}

const LiveAvatar = ({
  liveUser,
  size = 32,
  enableTooltip
}: LiveAvatarProps) => {
  const toolTipProps = enableTooltip
    ? {
        'data-tooltip-id': liveUser.id,
        'data-tooltip-content': mapUserName(liveUser)
      }
    : {}
  return (
    <>
      <Container {...toolTipProps} index={liveUser.index} size={size}>
        <CircleView size={size}>
          {liveUser.avatar ? (
            <Avatar src={liveUser.avatar} size={size} />
          ) : (
            <Text>{liveUser.initials}</Text>
          )}
        </CircleView>
      </Container>
      {enableTooltip ? <Tooltip id={liveUser.id} /> : null}
    </>
  )
}

export default LiveAvatar
