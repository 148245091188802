import styled from 'styled-components'

import Flex from 'components/Flex'
import SliderInput, {
  SliderInputVariants
} from 'components/SliderInput'
import { CMYKValues, ColorCMYK } from 'types/global'

const Field = styled.div`
  display: grid;
  grid-template-columns: 26px 1fr auto;
  align-items: center;
  grid-gap: 8px;
`

const Input = styled.input`
  width: 64px;
  border-radius: 55px;
  border: 1px solid ${({ theme }) => theme.color.base.c3};
  font-size: 17px;
  font-weight: 600;
  padding: 9px 0;
  text-align: center;

  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`

const Label = styled.label`
  font-size: 17px;
  font-weight: 600;
  color: ${({ theme }) => theme.color.base.c10};
`

const FIELDS: Record<CMYKValues, { sliderBackground: string }> = {
  [CMYKValues.c]: {
    sliderBackground:
      'linear-gradient(270deg, #00FFFF 0%, #FFFFFF 100%)'
  },
  [CMYKValues.m]: {
    sliderBackground:
      'linear-gradient(270deg, #FF00FF 0%, #FFFFFF 100%)'
  },
  [CMYKValues.y]: {
    sliderBackground:
      'linear-gradient(270deg, #FFFF00 0%, #FFFFFF 100%)'
  },
  [CMYKValues.k]: {
    sliderBackground:
      'linear-gradient(270deg, #000000 0%, #FFFFFF 100%)'
  }
}

type ColorSelectorProps = {
  value: ColorCMYK
  onChange: (field: CMYKValues, value: number) => void
}

const ColorSelector: React.FC<ColorSelectorProps> = ({
  value,
  onChange,
  ...rest
}): JSX.Element => {
  return (
    <Flex direction="column" rowGap={10} {...rest}>
      {Object.entries(FIELDS).map(([field, { sliderBackground }]) => (
        <Field key={field}>
          <Label htmlFor={`color-${field}`}>
            {field.toUpperCase()}
          </Label>

          <SliderInput
            value={value[field as CMYKValues]}
            variant={SliderInputVariants.colorPicker}
            background={sliderBackground}
            onChange={newValue => {
              onChange(field as CMYKValues, newValue)
            }}
          />

          <Input
            id={`color-${field}`}
            type="number"
            value={value[field as CMYKValues]}
            onChange={e => {
              onChange(field as CMYKValues, Number(e.target.value))
            }}
          />
        </Field>
      ))}
    </Flex>
  )
}

export default ColorSelector
