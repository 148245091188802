import { gql } from 'types/layoutcreator'

export const SEND_TO_PRODUCTION = gql(/* GraphQL */ `
  mutation sendToProduction(
    $productionData: ProductionDataInput!
    $cover: Upload!
  ) {
    sendToProduction(cover: $cover, productionData: $productionData)
  }
`)

export const UPDATE_CUSTOM_ATTRIBUTE = gql(/* GraphQL */ `
  mutation updateCustomAttribute(
    $orderNumber: String!
    $year: Int!
    $customerNumber: String!
    $attributeKey: String!
    $value: CustomAttributeValueInput
  ) {
    updateCustomAttribute(
      orderNumber: $orderNumber
      year: $year
      customerNumber: $customerNumber
      attributeKey: $attributeKey
      value: $value
    )
  }
`)
