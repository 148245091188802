import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'

import {
  EMPTY_EXPORT_QUEUE,
  FIX_VARIABLES,
  RECOVER_DELETED_PROJECT
} from 'data/layoutcreator/mutations/admin'
import { FIX_BROKEN_PAGES } from 'data/layoutcreator/mutations/projects'
import {
  CHECK_AUTH,
  GET_ADMIN_PROJECTS,
  GET_DB_SNAPSHOT,
  GET_KPI
} from 'data/layoutcreator/queries/admin'
import AddAssetModal from 'screens/Admin/components/AddAssetModal'
import ChangeProjectMetaModal from 'screens/Admin/components/ChangeProjectMetaModal'
import GetMigrationDataModal from 'screens/Admin/components/GetMigrationDataModal'
import ColorMigrationsModal from 'screens/AdminProject/components/PageMigrationsModal'
import StartImagesMigrationModal from 'shared/components/modals/StartImagesMigrationModal'
import Button from 'shared/ui/Button_2'
import Flex from 'shared/ui/Flex'
import Input from 'shared/ui/Input'
import Text from 'shared/ui/Text'
import YearbookCard from 'shared/ui/YearbookCard'

import ActionButton from './components/ActionButton'
import KPIItem, { KPIValueSizes } from './components/KPIItem'

const KPI_MIN_PAGES_COUNT = 31

const AdminTab = () => {
  const navigate = useNavigate()

  const lastGroupId = localStorage.getItem('adminLastGroupId')
  const [groupId, setGroupId] = useState<string>(lastGroupId || '')
  const [modalProjectId, setModalProjectId] = useState<string>('')
  const [isStartMigrationModalOpen, setIsStartMigrationModalOpen] =
    useState(false)
  const [isAddAssetModalOpen, setIsAddAssetModalOpen] =
    useState(false)
  const [
    isChangeProjectMetaModalOpen,
    setIsChangeProjectMetaModalOpen
  ] = useState(false)
  const [isMigrationsModalOpen, setIsMigrationsModalOpen] =
    useState(false)
  const [
    isGetMigrationDataModalOpen,
    setIsGetMigrationDataModalOpen
  ] = useState(false)

  const { data: kpiData } = useQuery(GET_KPI, {
    variables: { minPagesCount: KPI_MIN_PAGES_COUNT }
  })
  const [checkAuth] = useLazyQuery(CHECK_AUTH)
  const [
    fetchProjects,
    { data: projectsData, error: projectsSearchError }
  ] = useLazyQuery(GET_ADMIN_PROJECTS)
  const [fetchDbSnapshot] = useLazyQuery(GET_DB_SNAPSHOT)

  const [fixVariables, { loading: isFixPageNumberTypesLoading }] =
    useMutation(FIX_VARIABLES)
  const [fixPages, { loading: isFixPagesLoading }] =
    useMutation(FIX_BROKEN_PAGES)
  const [recoverDeletedProject] = useMutation(
    RECOVER_DELETED_PROJECT,
    {
      refetchQueries: [
        { query: GET_ADMIN_PROJECTS, variables: { groupId } }
      ]
    }
  )
  const [emptyExportQueue] = useMutation(EMPTY_EXPORT_QUEUE)

  useEffect(() => {
    const check = async () => {
      const { data } = await checkAuth()
      if (!data?.checkAuth) {
        navigate('/')
      }
    }

    check()
  }, [])

  const handleFindProjectsClick = () => {
    fetchProjects({ variables: { groupId } })
    localStorage.setItem('adminLastGroupId', groupId)
  }

  const handleDownloadDbSnapshotClick = async () => {
    const toastId = toast.loading('Downloading db snapshot...', {
      icon: '🔧'
    })
    const { data, error } = await fetchDbSnapshot({
      variables: { groupId }
    })

    if (error) {
      toast.error('Error fetching db data')
      toast.dismiss(toastId)
      return
    }

    const blob = new Blob(
      [data?.getDbSnapshot || 'Error fetching db data'],
      { type: 'text/json' }
    )

    const url = URL.createObjectURL(blob)

    const link = document.createElement('a')
    link.setAttribute('download', `db-snapshot-${groupId}.json`)
    link.setAttribute('href', url)
    document.body.appendChild(link)
    link.click()

    toast.dismiss(toastId)

    document.body.removeChild(link)
    URL.revokeObjectURL(url)
  }

  const handleFixVariables = (
    e: React.MouseEvent<HTMLButtonElement>,
    projectId: string
  ) => {
    e.preventDefault()
    const firstToast = toast.loading('Fixing page number types...', {
      icon: '🔧'
    })

    fixVariables({
      variables: { groupId, projectId }
    })
      .then(() => {
        toast.success('Page number types fixed!')
        toast.dismiss(firstToast)
      })
      .catch(() => {
        toast.error('Error fixing page number types')
        toast.dismiss(firstToast)
      })
      .finally(() => {
        toast.dismiss(firstToast)
      })
  }

  const handleFixPages = (
    e: React.MouseEvent<HTMLButtonElement>,
    projectId: string
  ) => {
    e.preventDefault()

    const firstToast = toast.loading('Fixing pages...', {
      icon: '🔧'
    })

    fixPages({
      variables: { groupId, projectId }
    })
      .then(() => {
        toast.success('Pages fixed!')
        toast.dismiss(firstToast)
      })
      .catch(() => {
        toast.error('Error fixing pages')
        toast.dismiss(firstToast)
      })
      .finally(() => {
        toast.dismiss(firstToast)
      })
  }

  const handleRecoverProject = (
    e: React.MouseEvent<HTMLButtonElement>,
    projectId: string
  ) => {
    e.preventDefault()

    const firstToast = toast.loading('Recovering project...', {
      icon: '🔧'
    })

    recoverDeletedProject({
      variables: { groupId, projectId }
    })
      .then(() => {
        toast.success('AdminProject recovered!')
        toast.dismiss(firstToast)
      })
      .catch(() => {
        toast.error('Error recovering project')
        toast.dismiss(firstToast)
      })
      .finally(() => {
        toast.dismiss(firstToast)
      })
  }

  const KPIS = [
    {
      value: kpiData?.getKpi?.pagesCreated || 0,
      label: 'Pages \n created',
      size: KPIValueSizes.sm
    },
    {
      value: kpiData?.getKpi?.pagesExported || 0,
      label: 'Pages \n exported',
      size: KPIValueSizes.sm
    },
    {
      value: kpiData?.getKpi?.projectsCreatedWithMinPagesCount || 0,
      label: `Projects created \n with >30 pages`,
      size: KPIValueSizes.lg
    },
    {
      value: kpiData?.getKpi?.projectsCreated || 0,
      label: 'Projects \n created',
      size: KPIValueSizes.md
    },
    {
      value: kpiData?.getKpi?.activeGroups || 0,
      label: 'Active \n groups',
      size: KPIValueSizes.md
    }
  ]

  return (
    <>
      <Flex direction="column">
        <Flex direction="column" marginTop={58}>
          <Text text="More admin actions" size={28} weight={600} />

          <Flex marginTop={36} columnGap={16}>
            <ActionButton
              textId="Download DB snapshot"
              onClick={handleDownloadDbSnapshotClick}
            />

            <ActionButton
              textId="Get migration data"
              onClick={() => setIsGetMigrationDataModalOpen(true)}
            />

            <ActionButton
              textId="Change project meta"
              onClick={() => setIsChangeProjectMetaModalOpen(true)}
            />

            <ActionButton
              textId="Migration manager"
              onClick={() => setIsMigrationsModalOpen(true)}
            />
          </Flex>
        </Flex>

        <Flex direction="column" marginTop={88}>
          <Text text="KPI Dashboard" size={28} weight={600} />

          <Flex marginTop={64} columnGap={16}>
            {KPIS.map((kpi, index) => (
              <KPIItem
                key={index}
                value={kpi.value}
                label={kpi.label}
                valueSize={kpi.size}
              />
            ))}
          </Flex>
        </Flex>
      </Flex>

      <AddAssetModal
        isOpen={isAddAssetModalOpen}
        closeModal={() => setIsAddAssetModalOpen(false)}
      />

      <ChangeProjectMetaModal
        isOpen={isChangeProjectMetaModalOpen}
        closeModal={() => setIsChangeProjectMetaModalOpen(false)}
        groupId={groupId}
      />

      <StartImagesMigrationModal
        isOpen={isStartMigrationModalOpen}
        closeModal={() => setIsStartMigrationModalOpen(false)}
        groupId={groupId}
        projectId={modalProjectId}
      />

      <ColorMigrationsModal
        isOpen={isMigrationsModalOpen}
        closeModal={() => setIsMigrationsModalOpen(false)}
      />

      <GetMigrationDataModal
        isOpen={isGetMigrationDataModalOpen}
        closeModal={() => setIsGetMigrationDataModalOpen(false)}
      />
    </>
  )
}

export default AdminTab
