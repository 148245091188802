import ReactSlider from 'react-slider'
import styled, { css } from 'styled-components'

enum Variants {
  primary = 'primary',
  colorPicker = 'colorPicker'
}

type SliderInputProps = {
  value: number
  onChange: (value: number) => void
  variant?: Variants
  background?: string
}

type WrapperProps = Required<
  Pick<SliderInputProps, 'variant' | 'background'>
>

type StylesMap = Record<Variants, () => any>

const TRACK_STYLES_MAP: StylesMap = {
  [Variants.primary]: () => css`
    height: 6px;
    background: ${({ theme }) => theme.color.base.c3};
  `,
  [Variants.colorPicker]: () => css`
    height: 100%;
    border: 1px solid ${({ theme }) => theme.color.base.c1};
  `
}

const THUMB_STYLES_MAP: StylesMap = {
  [Variants.primary]: () => css`
    height: 24px;
    width: 24px;
    background: ${({ theme }) => theme.color.brand_02};
  `,
  [Variants.colorPicker]: () => css`
    height: 32px;
    width: 32px;
    border: 1px solid ${({ theme }) => theme.color.base.c6};
    background: transparent;
    transform: translateY(-2px);
  `
}

const Wrapper = styled.div<WrapperProps>`
  width: 100%;

  .slider {
    height: 32px;
    background: ${({ background }) => background};
    border-radius: 9999px;
    display: flex;
    align-items: center;
  }

  .thumb {
    border-radius: 50%;
    background: white;
    box-sizing: border-box;
    outline: none;
    cursor: pointer;

    ${({ variant }) => THUMB_STYLES_MAP[variant]};
  }

  .track {
    border-radius: 9999px;

    ${({ variant }) => TRACK_STYLES_MAP[variant]};
  }
`

const SliderInput: React.FC<SliderInputProps> = ({
  value,
  onChange,
  background,
  variant = Variants.primary
}): JSX.Element => {
  return (
    <Wrapper
      background={background || 'transparent'}
      variant={variant}
    >
      <ReactSlider
        value={value}
        onChange={onChange}
        className="slider"
        thumbClassName="thumb"
        trackClassName="track"
      />
    </Wrapper>
  )
}

export { Variants as SliderInputVariants }
export default SliderInput
