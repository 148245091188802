import { ProfilePageItemSkeleton } from './ProfileItem'

const ProfilePageSkeleton: React.FC = () => (
  <>
    {new Array(5).fill(null).map((_, i) => (
      <ProfilePageItemSkeleton key={i} />
    ))}
  </>
)

export default ProfilePageSkeleton
