import { UniqueIdentifier, useDraggable } from '@dnd-kit/core'
import { useEffect } from 'react'
import styled from 'styled-components'

import { ReactChildren } from 'types/global'

import { Ids } from '../../types'

const Wrapper = styled.div<{ isDragging: boolean }>`
  opacity: ${({ isDragging }) => (isDragging ? 0.5 : 1)};
  transition: 0.2s;
  user-select: none;
`

type DraggableProps = {
  onDragStart?: () => void
  onDragEnd?: () => void
  id: UniqueIdentifier
  type: Ids
} & ReactChildren

const Draggable: React.FC<DraggableProps> = ({
  onDragStart,
  onDragEnd,
  children,
  type,
  id
}) => {
  const { attributes, listeners, setNodeRef, isDragging } =
    useDraggable({
      data: {
        type
      },
      id
    })

  useEffect(() => {
    if (isDragging) {
      if (onDragStart) onDragStart()
    } else {
      if (onDragEnd) onDragEnd()
    }
  }, [isDragging])

  return (
    <Wrapper
      ref={setNodeRef}
      {...listeners}
      {...attributes}
      isDragging={isDragging}
    >
      {children}
    </Wrapper>
  )
}

export default Draggable
