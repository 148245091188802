import { GradooTheme } from 'gradoo-theme/lib/types'
import { useState } from 'react'
import { useParams } from 'react-router-dom'

import { ReactComponent as Logo } from 'shared/assets/icons/logo-admin.svg'
import Flex from 'shared/ui/Flex'
import Tools from 'shared/components/PageListTools'
import ViewModeSwitch from 'shared/ui/ViewModeSwitch'
import HeaderGoBackButton from 'shared/ui/HeaderGoBackButton'
import NewPageModal from 'screens/YearbookPages/components/NewPageModal'
import { LiveUser } from 'shared/types/global'
import LiveAvatarsRow from 'shared/ui/LiveAvatarsRow'

const TOOLS_ICON_COLOR = {
  active: (theme: GradooTheme) => theme.color.base.c0,
  default: (theme: GradooTheme) => theme.color.base.c5
}

const TOOLS = [
  {
    to: '?view=list',
    icon: 'list-outline',
    colors: TOOLS_ICON_COLOR,
    title: 'Page.editor.tools.list'
  },
  {
    to: '?view=grid',
    icon: 'grid-filled',
    colors: TOOLS_ICON_COLOR,
    title: 'Page.editor.tools.grid'
  }
]

type HeaderProps = {
  liveUsers: LiveUser[]
}

const AdminProjectHeader: React.FC<HeaderProps> = ({ liveUsers }) => {
  const { groupId, projectId } = useParams()
  const [isNewPageModalOpen, setIsNewPageModalOpen] = useState(false)

  return (
    <Flex justifyContent="space-between">
      <Flex columnGap={16}>
        <HeaderGoBackButton />
        <Logo />
      </Flex>

      <ViewModeSwitch
        isDisabled
        initialActiveIndex={1}
        options={['Cover', 'Content']}
        onChange={() => {}}
      />

      <Flex>
        <LiveAvatarsRow liveUsers={liveUsers || []} />
        
        <Tools
          openModal={() => setIsNewPageModalOpen(true)}
          tools={TOOLS}
          settingsPath={`/admin/projects/${groupId}/${projectId}/settings`}
        />
      </Flex>

      <NewPageModal
        groupId={groupId!}
        projectId={projectId!}
        isOpen={isNewPageModalOpen}
        closeModal={() => setIsNewPageModalOpen(false)}
      />
    </Flex>
  )
}

export default AdminProjectHeader
