// Updated version of YearbookCard component with new design
// Was created during Admin Panel 2.0 development and can be used in regular user projects list in the future as a replacement for the old YearbookCard component

import { Link } from 'react-router-dom'
import styled from 'styled-components'

import {
  LastUpdateFragment,
  PagePreviewsFragment
} from 'data/layoutcreator/queries/projects'
import { FragmentType } from 'shared/types/layoutcreator'
import { Maybe } from 'shared/types/layoutcreator/graphql'

import Cover from './components/Cover'
import Meta from './components/Meta'

const ContainerLink = styled(Link)<{ to: string }>`
  display: flex;
  flex-direction: column;
  width: 265px;
  color: ${({ theme }) => theme.color.base.c9};
  text-decoration: none;
  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  cursor: ${({ to }) => (to ? 'pointer' : 'default')};
`

type YearbookCardProps = {
  name: string
  lastUpdate?: Maybe<FragmentType<typeof LastUpdateFragment>>
  pagePreviews: FragmentType<typeof PagePreviewsFragment>[]
  linkTo?: string | null
  isDeleted?: boolean
  append?: () => JSX.Element
}

const YearbookCard = ({
  name,
  lastUpdate,
  pagePreviews,
  linkTo,
  isDeleted,
  append
}: YearbookCardProps) => (
  <ContainerLink to={linkTo || ''}>
    <Cover pages={pagePreviews} />
    <Meta title={name} lastUpdate={lastUpdate} isDeleted={isDeleted}>
      {append && append()}
    </Meta>
  </ContainerLink>
)

export default YearbookCard
