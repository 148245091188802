import { UserFragment, UserGroupFragment } from 'shared/types/gradoo/graphql'

type UserFullName = Pick<UserFragment, 'firstName' | 'lastName'>

export function mapUserName(user: UserFullName): string {
  const { firstName = '', lastName = '' } = user
  return `${firstName} ${lastName}`
}

export function mapUserShortName(user: UserFullName): string {
  const { firstName = '', lastName = '' } = user
  return `${firstName} ${lastName.charAt(0)}.`
}

export function mapUserAvatar(userGroup: UserGroupFragment): string {
  const { avatar, defaultAvatar } = userGroup
  return avatar || defaultAvatar?.avatar || ''
}

export function mapUserInitials(user: UserFragment): string {
  const { firstName = '', lastName = '' } = user
  return `${firstName.charAt(0)}${lastName.charAt(0)}`.toUpperCase()
}
