import { useQuery } from '@apollo/client'
import { useContext } from 'react'

import Flex from 'shared/ui/Flex'
import useAuth from 'data/gradoo/hooks/useAuth'
import { MODULE_INSTANCE_RESULTS } from 'data/gradoo/queries/modules'
import { EditorContext } from 'screens/YearbookEditor'
import {
  ModuleInstanceResultFragment,
  ModuleType
} from 'shared/types/gradoo/graphql'

import { PanelProps, Panels } from '../../types'
import Skeleton from './Skeleton'
import ContentSetItem from './components/ContentSetItem'

type SuportedModuleTypes =
  | ModuleType.ProfilePage
  | ModuleType.Rankings
  | ModuleType.Collages
  | ModuleType.Reports
  | ModuleType.Quotes
  | ModuleType.CustomPages

const ContentSetsPanel: React.FC<PanelProps> = ({ push }) => {
  const { isWorkspaceRendered } = useContext(EditorContext)

  const { authGroupId, authUserGroup } = useAuth()

  const { data: moduleInstanceResultsData, loading } = useQuery(
    MODULE_INSTANCE_RESULTS,
    {
      skip: !authGroupId,
      variables: {
        group: authGroupId
      },
      context: { client: 'gradoo' }
    }
  )

  const usersTotal = authUserGroup?.group?.usersCount

  const moduleInstanceResults: ModuleInstanceResultFragment[] =
    (moduleInstanceResultsData?.moduleInstances?.edges.map(
      edge => edge?.node
    ) as ModuleInstanceResultFragment[]) || []

  const handleItemClick = (
    type: SuportedModuleTypes,
    moduleInstanceId: string
  ) => {
    const PANELS_MAP: Record<SuportedModuleTypes, Panels> = {
      [ModuleType.ProfilePage]: Panels.profilePage,
      [ModuleType.Rankings]: Panels.rankings,
      [ModuleType.Collages]: Panels.collagesList,
      [ModuleType.Reports]: Panels.reports,
      [ModuleType.Quotes]: Panels.quotes,
      [ModuleType.CustomPages]: Panels.customPages
    }

    push({
      panel: PANELS_MAP[type],
      props: {
        moduleInstanceId
      }
    })
  }

  return (
    <Flex direction="column">
      <Flex direction="column" rowGap={16}>
        {loading || !isWorkspaceRendered ? (
          <Skeleton />
        ) : (
          moduleInstanceResults.map(moduleInstanceResult => (
            <ContentSetItem
              data-testid={`ContentSetItem:${moduleInstanceResult.id}`}
              key={moduleInstanceResult.module.type}
              moduleInstanceResult={moduleInstanceResult}
              usersTotal={usersTotal}
              onClick={() =>
                handleItemClick(
                  moduleInstanceResult.module
                    .type as SuportedModuleTypes,
                  moduleInstanceResult.id
                )
              }
            />
          ))
        )}
      </Flex>
    </Flex>
  )
}

export default ContentSetsPanel
