import React from 'react'

import CMYK from 'entities/CMYK'
import RGB from 'entities/RGB'

export enum Locales {
  en = 'en',
  de = 'de'
}

export type ReactChildren = {
  children: React.ReactNode
}

export enum FileTypes {
  image = 'image',
  pdf = 'pdf'
}

export enum ImageFormats {
  png = 'png',
  jpg = 'jpg'
}

export enum EnvTypes {
  dev = 'DEV',
  stage = 'STAGE',
  prod = 'PROD'
}

export type LiveUser = {
  id: string
  avatar: string
  firstName: string
  lastName: string
  initials: string
  index: number
}

export enum ColorTypes {
  rgb = 'RGB',
  cmyk = 'CMYK'
}

export enum CMYKValues {
  c = 'c',
  m = 'm',
  y = 'y',
  k = 'k'
}

export enum RGBValues {
  r = 'r',
  g = 'g',
  b = 'b'
}

export type ColorCMYK = Record<CMYKValues, number>
export type ColorRGB = Record<RGBValues, number>

export type Color = CMYK | RGB

export enum PageTypes {
  blank = 'blank',
  collages = 'collages',
  reports = 'reports',
  quotes = 'quotes',
  profile = 'profile',
  rankings = 'rankings'
}

export type Base64String = string

export type Percent = number
export type Millimeters = number
export type Pixels = number

export type Size<T = number> = {
  width: T
  height: T
}
export type Coordinates<T = number> = {
  x: T
  y: T
}

export type Seconds = number
export type Milliseconds = number

export enum ImageSources {
  internal = 'internal',
  collage = 'collage',
  profile = 'profile',
  report = 'report',
  custom = 'custom'
}
