import { useEffect, useState } from 'react'

import { Coordinates } from 'shared/types/global'

export function useMousePosition(): Coordinates {
  const [mousePosition, setMousePosition] = useState<Coordinates>({
    x: 0,
    y: 0
  })

  useEffect(() => {
    const handleWindowMouseMove = (event: MouseEvent) => {
      setMousePosition({
        x: event.clientX,
        y: event.clientY
      })
    }

    window.addEventListener('mousemove', handleWindowMouseMove)

    return () => {
      window.removeEventListener('mousemove', handleWindowMouseMove)
    }
  }, [])

  return mousePosition
}
