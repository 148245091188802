import { useMemo } from 'react'
import styled from 'styled-components'

import { Skeleton } from 'shared/ui/Skeleton'
import useImageLoading from 'shared/hooks/useImageLoading'
import useMultipleImageLoading from 'shared/hooks/useMultipleImageLoading'

const Container = styled.div`
  width: 100%;
  height: 140px;
  border-radius: 14px;
  position: relative;
  overflow: hidden;
  cursor: pointer;
`

const Title = styled.h3`
  font-family: TTFirsNeue, sans-serif;
  font-weight: 600;
  font-size: 24px;
  color: ${({ theme }) => theme.color.base.c0};
  margin: 0;
  position: absolute;
  z-index: 3;
  left: 12px;
  right: 12px;
  bottom: 15px;
`

const Filter = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(
    0deg,
    #0f172a 0%,
    rgba(226, 232, 240, 0) 100%
  );
  z-index: 2;
`

const GRID_GAP = '6px'

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr repeat(2, auto);
  grid-template-rows: repeat(2, 1fr);
  grid-auto-flow: column;
  grid-row-gap: ${GRID_GAP};
  width: 100%;
  height: 100%;
`

const MainImage = styled.div`
  grid-row-start: 1;
  grid-row-end: 3;
  background-position: center;
  background-size: cover;
`

const Image = styled.div`
  margin-left: ${GRID_GAP};
  width: 64px;
  background-position: center;
  background-size: cover;
`

type CollageItemProps = {
  title: string
  images: string[]
  onClick: () => void
}

const GRID_MAX_ITEMS = 5

const CollageItem: React.FC<CollageItemProps> = ({
  title,
  images,
  ...rest
}) => {
  const [mainImageLink, ...otherImages] = images

  const { isLoading: isMainLoading } = useImageLoading(mainImageLink)

  const evenImages =
    otherImages.length % 2 === 0
      ? otherImages
      : otherImages.slice(0, -1)
  const clippedImages = useMemo(
    () => evenImages.slice(0, GRID_MAX_ITEMS - 1),
    [images]
  )

  const { isLoading: isOtherLoading } =
    useMultipleImageLoading(clippedImages)

  return (
    <Container {...rest}>
      <Title>{title}</Title>
      <Filter />

      {(isMainLoading || isOtherLoading) && <Skeleton />}

      <Grid>
        <MainImage
          style={{
            backgroundImage: `url('${mainImageLink}')`
          }}
        />

        {clippedImages.map((url, i) => (
          <Image
            key={`img${i}`}
            style={{
              backgroundImage: `url('${url}')`
            }}
          />
        ))}
      </Grid>
    </Container>
  )
}

export { default as CollageItemSkeleton } from './Skeleton'
export default CollageItem
