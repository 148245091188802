import { useCallback, useEffect, useMemo } from 'react'

// Fix for the library "react-infinite-scroll-component"
// This hook is used to fix the infinite scroll when the parent element does not have a scroll bar.
// Issue: We have some components having dynamic height (such as Right Panel) and when the screen gets bigger or longer infinite scroll component doesn't trigger fetch more, there will be no scrollbar in this case because of the number of initial rendered items altough we have more items to fetch.

// Usage example: see CollagePanel

interface FixMissingScrollProps {
  hasMoreItems: boolean
  fetchMoreItems: () => void
  parentElementID: string
}

const useFixMissingScroll = ({
  hasMoreItems,
  fetchMoreItems,
  parentElementID
}: FixMissingScrollProps) => {
  const parentElement = useMemo(
    () => document.getElementById(parentElementID),
    [parentElementID]
  )

  const fetchCb = useCallback(() => {
    fetchMoreItems()
  }, [fetchMoreItems])

  useEffect(() => {
    const hasScroll = parentElement
      ? parentElement.scrollHeight > parentElement.clientHeight
      : false
    if (!hasScroll && hasMoreItems) {
      setTimeout(() => {
        fetchCb()
      }, 200)
    }
  }, [hasMoreItems, fetchCb, parentElement])
}

export default useFixMissingScroll
