import { useState } from 'react'
import styled from 'styled-components'

import Flex from 'components/Flex'

import TabBar from './components/TabBar'

const Content = styled.div<{ isActive: boolean }>`
  display: ${({ isActive }) => (isActive ? 'block' : 'none')};
  padding: 24px 0;
`

type Tab = {
  Component: React.FC<any>
  title: string
}

type TabsProps<TabProps> = {
  list: Tab[]
  isContentLoading?: boolean
  tabProps?: TabProps
}

function Tabs<TabProps = any>({
  list,
  isContentLoading,
  tabProps
}: TabsProps<TabProps>) {
  const [activeTab, setActiveTab] = useState(0)

  return (
    <Flex direction="column" width="100%">
      <TabBar
        tabs={list.map(({ title }) => title)}
        activeIndex={activeTab}
        onTabClick={setActiveTab}
      />

      {!isContentLoading &&
        list.map(({ Component }, index) => (
          <Content key={index} isActive={activeTab === index}>
            <Component {...tabProps} />
          </Content>
        ))}
    </Flex>
  )
}

export default Tabs
