import { DefaultTheme } from 'styled-components'

import { ButtonIntent, ButtonSize, ButtonState } from './types'

/** Size */
interface ButtonSizeHelper {
  textSize: (size: ButtonSize) => number
  lineHeight: (size: ButtonSize) => number
  paddingV: (size: ButtonSize) => number
  paddingH: (size: ButtonSize) => number
  iconSize: (size: ButtonSize) => number
}

type SizeNumberMap = {
  [key in ButtonSize]: number
}
export const bySizeHelper: ButtonSizeHelper = {
  textSize: (size: ButtonSize): number => {
    const sizeMap: SizeNumberMap = {
      sm: 14,
      md: 14,
      lg: 16,
      xl: 18,
      '2xl': 20
    }
    return sizeMap[size]
  },
  lineHeight: (size: ButtonSize): number => {
    const sizeMap: SizeNumberMap = {
      sm: 18,
      md: 18,
      lg: 9,
      xl: 24,
      '2xl': 24
    }
    return sizeMap[size]
  },
  paddingV: (size: ButtonSize): number => {
    const sizeMap: SizeNumberMap = {
      sm: 5,
      md: 10,
      lg: 12,
      xl: 14,
      '2xl': 16
    }
    return sizeMap[size]
  },
  paddingH: (size: ButtonSize): number => {
    const sizeMap: SizeNumberMap = {
      sm: 16,
      md: 18,
      lg: 20,
      xl: 22,
      '2xl': 32
    }
    return sizeMap[size]
  },
  iconSize: (size: ButtonSize): number => {
    const sizeMap: SizeNumberMap = {
      sm: 14,
      md: 16,
      lg: 20,
      xl: 24,
      '2xl': 24
    }
    return sizeMap[size]
  }
}

/** Types */
interface ButtonStateMap {
  innerBorderColor: (
    state: ButtonState,
    theme: DefaultTheme
  ) => string
  bgColor: (state: ButtonState, theme: DefaultTheme) => string
  textColor: (state: ButtonState, theme: DefaultTheme) => string
}

const primaryBrand01ButtonStateMap: ButtonStateMap = {
  innerBorderColor: (
    state: ButtonState,
    theme: DefaultTheme
  ): string => {
    return state === 'disabled' ? theme.color.base.c5 : '#E5A094'
  },
  bgColor: (state: ButtonState, theme: DefaultTheme): string => {
    return state === 'disabled'
      ? theme.color.base.c5
      : theme.color.brand_01
  },
  textColor: (state: ButtonState, theme: DefaultTheme): string => {
    return state === 'disabled'
      ? theme.color.base.c1
      : theme.color.base.c10
  }
}

const primaryBrand02ButtonStateMap: ButtonStateMap = {
  innerBorderColor: (
    state: ButtonState,
    theme: DefaultTheme
  ): string => {
    return state === 'disabled' ? theme.color.base.c5 : '#3967C3'
  },
  bgColor: (state: ButtonState, theme: DefaultTheme): string => {
    return state === 'disabled'
      ? theme.color.base.c5
      : theme.color.brand_02
  },
  textColor: (state: ButtonState, theme: DefaultTheme): string => {
    return state === 'disabled'
      ? theme.color.base.c1
      : theme.color.base.c0
  }
}

const primaryBrand03ButtonStateMap: ButtonStateMap = {
  innerBorderColor: (
    state: ButtonState,
    theme: DefaultTheme
  ): string => {
    return state === 'disabled'
      ? theme.color.base.c5
      : theme.color.error
  },
  bgColor: (state: ButtonState, theme: DefaultTheme): string => {
    return state === 'disabled'
      ? theme.color.base.c5
      : theme.color.error
  },
  textColor: (state: ButtonState, theme: DefaultTheme): string => {
    return state === 'disabled'
      ? theme.color.base.c1
      : theme.color.base.c0
  }
}

const secondaryBaseButtonStateMap: ButtonStateMap = {
  innerBorderColor: (
    state: ButtonState,
    theme: DefaultTheme
  ): string => {
    return state === 'disabled' ? '#9FA7AE' : theme.color.base.c2
  },
  bgColor: (state: ButtonState, theme: DefaultTheme): string => {
    return state === 'disabled'
      ? theme.color.base.c4
      : theme.color.base.c0
  },
  textColor: (state: ButtonState, theme: DefaultTheme): string => {
    return state === 'disabled'
      ? theme.color.base.c1
      : theme.color.base.c9
  }
}

const secondaryContrastButtonStateMap: ButtonStateMap = {
  innerBorderColor: (
    state: ButtonState,
    theme: DefaultTheme
  ): string => {
    return state === 'disabled' ? '#9FA7AE' : theme.color.base.c8
  },
  bgColor: (state: ButtonState, theme: DefaultTheme): string => {
    return state === 'disabled'
      ? theme.color.base.c4
      : theme.color.base.c8
  },
  textColor: (state: ButtonState, theme: DefaultTheme): string => {
    return state === 'disabled'
      ? theme.color.base.c1
      : theme.color.base.c0
  }
}

const subActionButtonStateMap: ButtonStateMap = {
  innerBorderColor: (
    state: ButtonState,
    theme: DefaultTheme
  ): string => {
    return state === 'disabled' ? '#9FA7AE' : theme.color.base.c2
  },
  bgColor: (state: ButtonState, theme: DefaultTheme): string => {
    return state === 'disabled'
      ? theme.color.base.c3
      : theme.color.base.c2
  },
  textColor: (state: ButtonState, theme: DefaultTheme): string => {
    return state === 'disabled'
      ? theme.color.base.c1
      : theme.color.brand_02
  }
}

const destructiveButtonStateMap: ButtonStateMap = {
  innerBorderColor: (
    state: ButtonState,
    theme: DefaultTheme
  ): string => {
    return state === 'disabled' ? '#9FA7AE' : theme.color.base.c2
  },
  bgColor: (state: ButtonState, theme: DefaultTheme): string => {
    return state === 'disabled'
      ? theme.color.base.c5
      : theme.color.error
  },
  textColor: (state: ButtonState, theme: DefaultTheme): string => {
    return state === 'disabled'
      ? theme.color.base.c1
      : theme.color.base.c0
  }
}

type ButtonIntentHandler = { [key in ButtonIntent]: ButtonStateMap }

const buttonIntentHandler: ButtonIntentHandler = {
  'primary-brand-01': primaryBrand01ButtonStateMap,
  'primary-brand-02': primaryBrand02ButtonStateMap,
  'primary-brand-03': primaryBrand03ButtonStateMap,
  'secondary-base': secondaryBaseButtonStateMap,
  'secondary-contrast': secondaryContrastButtonStateMap,
  'sub-action': subActionButtonStateMap,
  destructive: destructiveButtonStateMap
}

interface ButtonIntentStateHelper {
  innerBorderColor: (
    buttonIntent: ButtonIntent,
    state: ButtonState,
    theme: DefaultTheme
  ) => string
  bgColor: (
    buttonIntent: ButtonIntent,
    state: ButtonState,
    theme: DefaultTheme
  ) => string
  textColor: (
    buttonIntent: ButtonIntent,
    state: ButtonState,
    theme: DefaultTheme
  ) => string
}

export const byTypeHelper: ButtonIntentStateHelper = {
  innerBorderColor: (
    buttonIntent: ButtonIntent,
    state: ButtonState,
    theme: DefaultTheme
  ): string => {
    return buttonIntentHandler[buttonIntent].innerBorderColor(
      state,
      theme
    )
  },
  bgColor: (
    buttonIntent: ButtonIntent,
    state: ButtonState,
    theme: DefaultTheme
  ): string => {
    return buttonIntentHandler[buttonIntent].bgColor(state, theme)
  },
  textColor: (
    buttonIntent: ButtonIntent,
    state: ButtonState,
    theme: DefaultTheme
  ): string => {
    return buttonIntentHandler[buttonIntent].textColor(state, theme)
  }
}
