import { useMutation } from '@apollo/client'
import { useState } from 'react'
import toast from 'react-hot-toast'
import styled from 'styled-components'

import Button, { ButtonIntents } from 'shared/ui/Button'
import Flex from 'shared/ui/Flex'
import Input from 'shared/ui/Input'
import IslandModal from 'shared/components/modals/IslandModal'
import useAuth from 'data/gradoo/hooks/useAuth'
import { CHANGE_PROJECT_META } from 'data/layoutcreator/mutations/projects'

type ChangeProjectMetaModelProps = {
  isOpen: boolean
  closeModal: () => void
  groupId: string
}

const ChangeProjectMetaModal: React.FC<
  ChangeProjectMetaModelProps
> = ({ isOpen, closeModal, groupId }) => {
  const { authGroupId } = useAuth()

  const [id, setId] = useState('')
  const [name, setName] = useState('')

  const [changeProjectMeta] = useMutation(CHANGE_PROJECT_META)

  const handleSubmit = async () => {
    const toastId = toast.loading('Uploading project meta...')

    const { data, errors } = await changeProjectMeta({
      variables: {
        groupId,
        projectId: id,
        input: {
          name
        },
        adjustChili: false
      }
    })

    toast.dismiss(toastId)

    if (errors) {
      toast.error('Failed to change project meta 😬')
      return
    } else if (data) {
      toast.success('Project meta changed 👌')
    }
  }

  return (
    <IslandModal
      isOpen={isOpen}
      closeModal={closeModal}
      width={600}
      title="Change project meta"
    >
      <Flex direction="column">
        <Flex direction="column" rowGap={16}>
          <Input value={id} onChange={setId} label="ID" />
          <Input value={name} onChange={setName} label="Name" />
        </Flex>

        <Flex marginTop={46}>
          <Button
            intent={ButtonIntents.dark}
            labelId="Admin.buttons.submit"
            borderRadius={100}
            isFullWidth
            isDisabled={!name}
            onClick={handleSubmit}
          />
        </Flex>
      </Flex>
    </IslandModal>
  )
}

export default ChangeProjectMetaModal
