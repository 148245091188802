import { gql } from 'shared/types/gradoo'

export const QUOTES_INSTANCES = gql(/* GraphQL */ `
  query QuotesInstances(
    $after: String
    $first: Int
    $moduleInstance: ID
    $orderBy: String
  ) {
    quotesInstances(
      after: $after
      first: $first
      moduleInstance: $moduleInstance
      orderBy: $orderBy
    ) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          ...Quote
        }
      }
    }
  }
`)
