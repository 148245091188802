import { useMutation, useQuery } from '@apollo/client'
import config from 'config'
import {
  PDFDocumentProxy,
  PDFPageProxy
} from 'pdfjs-dist/types/src/display/api'
import { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { useIntl } from 'react-intl'
import { Document, Page, pdfjs } from 'react-pdf'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'

import Badge, { INTENTS } from 'shared/ui/Badge'
import Button from 'shared/ui/Button_2'
import Flex from 'shared/ui/Flex'
import useAuth from 'data/gradoo/hooks/useAuth'
import { CUSTOM_PAGES_INSTANCE } from 'data/gradoo/queries/custom'
import { ADD_NEW_CHILI_IMAGE } from 'data/layoutcreator/mutations/images'
import { GET_PROJECT_TOKEN } from 'data/layoutcreator/queries/projects'
import { isDev } from 'shared/helpers/env'
import { fetchImageFile } from 'shared/helpers/file'
import { useBackgroundUploads } from 'shared/hooks/useBackgroundUploads'
import ChiliImages from 'shared/components/Tools/services/ChiliImages'
import ChiliVariables, {
  VariableTypes
} from 'shared/components/Tools/services/ChiliVariables'
import { EnvTypes, ImageSources } from 'shared/types/global'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`

const imagesApi = ChiliImages.getInstance()
const variablesApi = ChiliVariables.getInstance()

const CustomPageImage = styled.img`
  width: 168px;
  height: 237px;
`
const EmbedPdf = styled(Document)`
  width: 168px;
  height: 237px;
  overflow: hidden;
`

const PageContainer = styled(Flex)`
  overflow: hidden;
`

const ImageInfoText = styled.p`
  font-size: 12px;
  color: ${({ theme }) => theme.color.base.c8};
  margin: 0px;
`

const SelectPageDropdown = styled.select`
  margin-left: 8px;
  padding: 4px 10px;
  border-radius: 55px;
  border-color: ${({ theme }) => theme.color.base.c3};
`

type ReportPanelProps = {
  customPageInstanceId: string
}

const CustomPagePanel: React.FC<ReportPanelProps> = ({
  customPageInstanceId
}) => {
  const { formatMessage } = useIntl()
  const { authGroupId: groupId } = useAuth()
  const { pathname } = useLocation()
  const projectId = pathname.split('/')[1]
  const { data: moduleInstanceResultsData } = useQuery(
    CUSTOM_PAGES_INSTANCE,
    {
      skip: !customPageInstanceId,
      variables: { id: customPageInstanceId },
      context: { client: 'gradoo' }
    }
  )

  const { data: token } = useQuery(GET_PROJECT_TOKEN, {
    variables: { projectId, groupId: groupId! }
  })
  const pageType =
    moduleInstanceResultsData?.customPagesInstance?.pageType

  const getBackgroundQueryParams = (imageId: string) => {
    const queryParams = new URLSearchParams()

    queryParams.append('projectId', projectId)
    queryParams.append('groupId', groupId as string)
    queryParams.append('imageId', imageId)

    if (token?.getProjectToken) {
      queryParams.append('token', token.getProjectToken)
    }

    return `?${queryParams.toString()}`
  }
  const { handleFileUpload, setSelectedImageId } =
    useBackgroundUploads({
      onSelectedBackgroundChange: async data => {
        if (!data) {
          return
        }

        await variablesApi.setVariableValue(
          config.themes.variablesKeys.backgroundImage,
          isDev()
            ? config.themes.pageBackgrounds.devDAPValue
            : getBackgroundQueryParams(data.id),
          {
            type: VariableTypes.DAPImage,
            DAPId:
              config.themes.pageBackgrounds.DAPIds[
                process.env.REACT_APP_ENV as EnvTypes
              ]
          }
        )
      },
      hideBackground: false
    })

  const [pageCount, setPageCount] = useState(1)
  const [selectedPage, setSelectedPage] = useState(1)

  useEffect(() => {
    if (
      moduleInstanceResultsData?.customPagesInstance?.pageType ===
      'PDF'
    ) {
      setPageCount(1) // Set a default page count
    }
  }, [moduleInstanceResultsData])

  const onDocumentLoadSuccess = ({
    numPages
  }: {
    numPages: number
  }) => {
    setPageCount(numPages)
  }

  const convertPdfPageToImage = async (
    pdfUrl: string,
    pageNumber: number
  ): Promise<File> => {
    const pdf: PDFDocumentProxy = await pdfjs.getDocument(pdfUrl)
      .promise
    const page: PDFPageProxy = await pdf.getPage(pageNumber)
    const viewport = page.getViewport({ scale: 1.5 })
    const canvas = document.createElement('canvas')
    const context = canvas.getContext('2d')
    canvas.height = viewport.height
    canvas.width = viewport.width

    await page.render({ canvasContext: context!, viewport }).promise

    return new Promise(resolve => {
      canvas.toBlob(blob => {
        resolve(new File([blob!], 'page.jpg', { type: 'image/jpeg' }))
      }, 'image/jpeg')
    })
  }

  const uploadImage = async () => {
    const imageUrl =
      moduleInstanceResultsData?.customPagesInstance?.page

    try {
      let imageFile: File | null = null
      if (pageType === 'PDF') {
        imageFile = await convertPdfPageToImage(
          imageUrl as string,
          selectedPage
        )
      } else {
        imageFile = await fetchImageFile(imageUrl as string)
      }

      if (!imageFile) {
        throw new Error('Cannot fetch image file')
      }

      const newImageId = await handleFileUpload(imageFile)
      if (newImageId) {
        setSelectedImageId(newImageId) // Update the selected image ID after successful upload
      }
    } catch (error) {
      console.error('Error fetching or uploading image:', error)
      toast.error(
        formatMessage({ id: 'Toasts.components.uploading.error' })
      )
    }
  }

  const [addNewChiliImage] = useMutation(ADD_NEW_CHILI_IMAGE)

  const handleImageClick = async (photoUrl: string) => {
    try {
      const loadingToastId = toast.loading(
        formatMessage({ id: 'Toasts.components.uploading' })
      )
      let imageFile: File | null = null
      if (pageType === 'PDF') {
        imageFile = await convertPdfPageToImage(
          photoUrl as string,
          selectedPage
        )
      } else {
        imageFile = await fetchImageFile(photoUrl as string)
      }

      const { data } = await addNewChiliImage({
        variables: {
          projectId,
          groupId,
          image: imageFile,
          source: ImageSources.collage
        }
      })

      await imagesApi
        .createNewFrame({
          ...data.addNewChiliImage,
          imageUrl: photoUrl
        })
        .then(() => {
          toast.remove(loadingToastId)
        })
    } catch {
      toast.dismiss()
      toast.error(
        formatMessage({ id: 'Toasts.components.uploading.error' })
      )
    }
  }

  const isPageQualityPoor =
    moduleInstanceResultsData?.customPagesInstance?.pageQuality ===
    'POOR'
  const isPageQualityGood =
    moduleInstanceResultsData?.customPagesInstance?.pageQuality ===
    'GOOD'

  const intent = isPageQualityPoor
    ? INTENTS.error
    : isPageQualityGood
    ? INTENTS.success
    : INTENTS.warning

  return (
    <PageContainer marginTop={35} direction="column">
      {moduleInstanceResultsData?.customPagesInstance?.pageType ===
      'PDF' ? (
        <EmbedPdf
          file={
            moduleInstanceResultsData?.customPagesInstance
              ?.page as string
          }
          onLoadSuccess={onDocumentLoadSuccess}
        >
          <Page pageNumber={selectedPage} height={287} />
        </EmbedPdf>
      ) : (
        <CustomPageImage
          src={
            moduleInstanceResultsData?.customPagesInstance
              ?.page as string
          }
        />
      )}
      <Flex
        direction="row"
        justifyContent="space-between"
        marginTop={20}
      >
        <ImageInfoText>
          {formatMessage({ id: 'Panel.customPage.format' })} :{' '}
          {moduleInstanceResultsData?.customPagesInstance?.pageType}
        </ImageInfoText>
        <ImageInfoText>
          {formatMessage({ id: 'Panel.customPage.intendedSize' })} :{' '}
          {formatMessage({
            id: `Panel.customPage.photoFormat.${moduleInstanceResultsData?.customPagesInstance?.pageFormat}`
          })}
        </ImageInfoText>
      </Flex>
      {moduleInstanceResultsData?.customPagesInstance?.pageType !==
      'PDF' ? (
        <Flex direction="column" marginBottom={20} columnGap={15}>
          <ImageInfoText>
            {formatMessage({ id: 'Panel.customPage.size' })} :
          </ImageInfoText>
          <ImageInfoText>
            {formatMessage({ id: 'Panel.customPage.quality' })} :{' '}
            {moduleInstanceResultsData?.customPagesInstance
              ?.pageQuality && (
              <Badge
                message={
                  moduleInstanceResultsData?.customPagesInstance
                    ?.pageQuality as string
                }
                intent={intent}
              />
            )}
          </ImageInfoText>
        </Flex>
      ) : (
        <Flex
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          marginTop={25}
          marginBottom={20}
        >
          <ImageInfoText>
            {formatMessage({ id: 'Panel.customPage.selectPage' })}
          </ImageInfoText>
          <SelectPageDropdown
            value={selectedPage}
            onChange={e => setSelectedPage(Number(e.target.value))}
          >
            {Array.from({ length: pageCount }, (_, index) => (
              <option key={index + 1} value={index + 1}>
                {index + 1}
              </option>
            ))}
          </SelectPageDropdown>
        </Flex>
      )}
      <Flex direction="column" rowGap={8}>
        <Button
          textId={formatMessage({ id: 'Panel.customPage.addToPage' })}
          onPress={() =>
            handleImageClick(
              moduleInstanceResultsData?.customPagesInstance
                ?.page as string
            )
          }
          intent="secondary-base"
        />
        <Button
          textId={formatMessage({
            id: 'Panel.customPage.fillInBackground'
          })}
          onPress={uploadImage}
          intent="secondary-contrast"
        />
      </Flex>
    </PageContainer>
  )
}

export default CustomPagePanel
