import { useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import {
  HeaderContainer,
  StyledHeader
} from 'shared/layout/Default/components/Header/style'

import Flex from '../../ui/Flex'
import IconButton, { IconButtonIntents } from '../../ui/IconButton'

export const Title = styled.div`
  font-size: 24px;
  font-weight: 600;
`

const BgContainer = styled(StyledHeader)``
const Container = styled(HeaderContainer)``

type PageHeaderProps = {
  titleId: string
  titleProps?: Record<string, string | null> | null
  withBackButton?: boolean
  beforeGoingBackFn?: () => Promise<boolean>
  onBackButtonClick?: () => void
  badge?: JSX.Element
  tools?: JSX.Element
  user?: JSX.Element
  renderBackButton?: (handleGoBack: () => void) => JSX.Element
}

const PageHeader = ({
  titleId,
  titleProps,
  withBackButton = false,
  onBackButtonClick,
  beforeGoingBackFn,
  badge: Badge,
  tools: Tools,
  user: User,
  renderBackButton
}: PageHeaderProps): JSX.Element => {
  const { formatMessage } = useIntl()
  const navigate = useNavigate()

  const handleGoBack = async () => {
    if (beforeGoingBackFn) {
      await beforeGoingBackFn()
    }

    onBackButtonClick ? onBackButtonClick() : navigate(-1)
  }

  return (
    <BgContainer>
      <Container>
        <Flex alignItems="center" columnGap={16}>
          {withBackButton && renderBackButton
            ? renderBackButton(handleGoBack)
            : withBackButton && (
                <IconButton
                  onClick={handleGoBack}
                  intent={IconButtonIntents.secondary}
                  icon="arrow-left-filled"
                  size={16}
                />
              )}
          <Title>
            {formatMessage({ id: titleId }, { ...titleProps })}
          </Title>
        </Flex>
        {Badge}
        {Tools}
        {User}
      </Container>
    </BgContainer>
  )
}

export default PageHeader
