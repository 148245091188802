import { useParams } from "react-router-dom"
import { PageRoomProvider } from "shared/context/project-room"

const withRoom = (Component: React.FC) => {
  type ComponentProps = React.ComponentProps<typeof Component>

  return (props: ComponentProps) => {
    const { projectId } = useParams()

    return (
      <PageRoomProvider projectId={projectId!}>
        <Component {...props} />
      </PageRoomProvider>
    )
  }
}

export default withRoom
