import styled, { css } from 'styled-components'

import Flex from 'components/Flex'

const Container = styled(Flex).attrs({
  direction: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  rowGap: 16
})<{
  isDragActive: boolean
  isDragReject: boolean
  isSuccessful: boolean
  hasError: boolean
}>`
  padding: 28px 16px 36px;
  text-align: center;
  box-sizing: border-box;
  cursor: pointer;

  ${({
    theme,
    isDragActive,
    isDragReject,
    hasError,
    isSuccessful
  }) => {
    function getBorder() {
      switch (true) {
        case isDragReject:
          return `2px dashed ${theme.color.error}`
        case isDragActive:
          return `2px dashed ${theme.color.success}`
        case hasError:
          return `1px solid ${theme.color.error}`
        case isSuccessful:
          return `1px solid ${theme.color.success}`
        default:
          return `1px solid ${theme.color.base.c4}`
      }
    }

    return css`
      border: ${getBorder()};
    `
  }};
  border-radius: 5px;
`

export default Container
