import Button from 'components/Button_2'
import Flex from 'components/Flex'
import LiveAvatar from 'components/LiveAvatar'
import { LiveUser } from 'types/global'

import Logo from './components/Logo'
import LogoContainerLink from './components/LogoContainerLink'
import {
  HeaderContainer,
  IconsContainer,
  LiveAvatarWrapper,
  LiveAvatarsRow,
  StyledHeader
} from './style'

export type HeaderProps = {
  liveUsers?: LiveUser[]
  RightComponent?: React.ReactNode
  onBack?: () => void
}

const Header = ({
  liveUsers,
  RightComponent,
  onBack
}: HeaderProps) => {
  return (
    <StyledHeader>
      <HeaderContainer>
        <Flex alignItems="center">
          {onBack && (
            <IconsContainer>
              <Button
                onPress={onBack}
                size="lg"
                intent="sub-action"
                textId={'Tools.add.page'}
                iconName="arrow_narrow_left"
                icon="only"
              />
            </IconsContainer>
          )}

          <LogoContainerLink to="/">
            <Logo />
          </LogoContainerLink>
        </Flex>
        <Flex>
          <Flex alignItems="center">
            <LiveAvatarsRow>
              {liveUsers?.map(liveUser => (
                <LiveAvatarWrapper key={liveUser.index}>
                  <LiveAvatar liveUser={liveUser} enableTooltip />
                </LiveAvatarWrapper>
              ))}
            </LiveAvatarsRow>
          </Flex>

          {!!RightComponent && (
            <Flex alignItems="center">{RightComponent}</Flex>
          )}
        </Flex>
      </HeaderContainer>
    </StyledHeader>
  )
}

export default Header
