export const getChiliImageUrl = ({
  id,
  path
}: {
  id: string
  path: string
}) => {
  return `${
    process.env.REACT_APP_REST_URL
  }images/chili?id=${id}&path=${encodeURIComponent(path)}`
}
