/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K]
}
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>
}
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>
}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  /**
   * The `Date` scalar type represents a Date
   * value as specified by
   * [iso8601](https://en.wikipedia.org/wiki/ISO_8601).
   */
  Date: any
  /**
   * The `DateTime` scalar type represents a DateTime
   * value as specified by
   * [iso8601](https://en.wikipedia.org/wiki/ISO_8601).
   */
  DateTime: any
  /** The `Decimal` scalar type represents a python Decimal. */
  Decimal: any
  /**
   * The `GenericScalar` scalar type represents a generic
   * GraphQL scalar value that could be:
   * String, Boolean, Int, Float, List or Object.
   */
  GenericScalar: any
  /**
   * `Geometry` scalar may be represented in a few ways:
   * - Well-known text (WKT)
   * - Hexadecimal (HEX)
   * - GeoJSON
   */
  Geometry: any
  /**
   * Allows use of a JSON String for input / output from the GraphQL schema.
   *
   * Use of this type is *not recommended* as you lose the benefits of having a defined, static
   * schema (one of the key benefits of GraphQL).
   */
  JSONString: any
  /**
   * Create scalar that ignores normal serialization/deserialization, since
   * that will be handled by the multipart request spec
   */
  Upload: any
}

export type AcceptUserInGroupInput = {
  accept: Scalars['Boolean']
  clientMutationId?: InputMaybe<Scalars['String']>
  groupId: Scalars['ID']
  userId: Scalars['ID']
}

export type AcceptUserInGroupPayload = {
  __typename?: 'AcceptUserInGroupPayload'
  clientMutationId?: Maybe<Scalars['String']>
  userGroup?: Maybe<UserGroupNode>
}

export type AcceptUserInTeamInput = {
  accept: Scalars['Boolean']
  clientMutationId?: InputMaybe<Scalars['String']>
  teamId: Scalars['ID']
  userId: Scalars['ID']
}

export type AcceptUserInTeamPayload = {
  __typename?: 'AcceptUserInTeamPayload'
  clientMutationId?: Maybe<Scalars['String']>
  userTeam?: Maybe<UserTeamNode>
}

export type ActivateModuleInstanceInput = {
  clientMutationId?: InputMaybe<Scalars['String']>
  id: Scalars['ID']
}

export type ActivateModuleInstancePayload = {
  __typename?: 'ActivateModuleInstancePayload'
  clientMutationId?: Maybe<Scalars['String']>
  moduleInstance?: Maybe<ModuleInstanceNode>
  success?: Maybe<Scalars['Boolean']>
}

export type ActivateTeamInput = {
  clientMutationId?: InputMaybe<Scalars['String']>
  id: Scalars['ID']
}

export type ActivateTeamPayload = {
  __typename?: 'ActivateTeamPayload'
  clientMutationId?: Maybe<Scalars['String']>
  success?: Maybe<Scalars['Boolean']>
  team?: Maybe<TeamNode>
}

export type AddImagesToPostInput = {
  clientMutationId?: InputMaybe<Scalars['String']>
  id: Scalars['ID']
  images?: InputMaybe<Scalars['Upload']>
}

export type AddImagesToPostPayload = {
  __typename?: 'AddImagesToPostPayload'
  clientMutationId?: Maybe<Scalars['String']>
  post?: Maybe<PostNode>
}

export type AddPhotoToCollagesAlbumInput = {
  clientMutationId?: InputMaybe<Scalars['String']>
  /** CollagesAlbum ID */
  id: Scalars['ID']
  photo?: InputMaybe<Scalars['Upload']>
}

export type AddPhotoToCollagesAlbumPayload = {
  __typename?: 'AddPhotoToCollagesAlbumPayload'
  clientMutationId?: Maybe<Scalars['String']>
  photo?: Maybe<CollagesPhotoNode>
}

export type AddPhotosToCollagesAlbumInput = {
  clientMutationId?: InputMaybe<Scalars['String']>
  id: Scalars['ID']
  photos?: InputMaybe<Scalars['Upload']>
}

export type AddPhotosToCollagesAlbumPayload = {
  __typename?: 'AddPhotosToCollagesAlbumPayload'
  clientMutationId?: Maybe<Scalars['String']>
  collagesAlbum?: Maybe<CollagesAlbumNode>
}

export type AddUserToGroupInput = {
  avatar?: InputMaybe<Scalars['Upload']>
  clientMutationId?: InputMaybe<Scalars['String']>
  defaultAvatarId?: InputMaybe<Scalars['ID']>
  groupId: Scalars['ID']
  role: Scalars['String']
  token?: InputMaybe<Scalars['String']>
  userId: Scalars['ID']
}

export type AddUserToGroupPayload = {
  __typename?: 'AddUserToGroupPayload'
  clientMutationId?: Maybe<Scalars['String']>
  userGroup?: Maybe<UserGroupNode>
}

export type AddUserToTeamInput = {
  clientMutationId?: InputMaybe<Scalars['String']>
  teamId: Scalars['ID']
  userId: Scalars['ID']
}

export type AddUserToTeamPayload = {
  __typename?: 'AddUserToTeamPayload'
  clientMutationId?: Maybe<Scalars['String']>
  teams?: Maybe<Array<Maybe<TeamNode>>>
  userTeam?: Maybe<UserTeamNode>
}

export type AdminAcceptInstituteRequestInput = {
  city?: InputMaybe<Scalars['String']>
  clientMutationId?: InputMaybe<Scalars['String']>
  country: Scalars['String']
  id?: InputMaybe<Scalars['ID']>
  name: Scalars['String']
  street?: InputMaybe<Scalars['String']>
  type?: InputMaybe<Scalars['ID']>
  website?: InputMaybe<Scalars['String']>
  zip?: InputMaybe<Scalars['String']>
}

export type AdminAcceptInstituteRequestPayload = {
  __typename?: 'AdminAcceptInstituteRequestPayload'
  clientMutationId?: Maybe<Scalars['String']>
  errors?: Maybe<Array<Maybe<ErrorType>>>
  instituteRequest?: Maybe<InstituteRequestNode>
}

export type AdminAddUserToTeamInput = {
  clientMutationId?: InputMaybe<Scalars['String']>
  teamId: Scalars['ID']
  userId: Scalars['ID']
}

export type AdminAddUserToTeamPayload = {
  __typename?: 'AdminAddUserToTeamPayload'
  clientMutationId?: Maybe<Scalars['String']>
  user?: Maybe<UserNode>
}

export type AdminClearUserFlagsInput = {
  clientMutationId?: InputMaybe<Scalars['String']>
  id: Scalars['ID']
}

export type AdminClearUserFlagsPayload = {
  __typename?: 'AdminClearUserFlagsPayload'
  clientMutationId?: Maybe<Scalars['String']>
  success?: Maybe<Scalars['Boolean']>
  user?: Maybe<UserNode>
}

export type AdminDeleteInstituteRequestInput = {
  clientMutationId?: InputMaybe<Scalars['String']>
  id: Scalars['ID']
}

export type AdminDeleteInstituteRequestPayload = {
  __typename?: 'AdminDeleteInstituteRequestPayload'
  clientMutationId?: Maybe<Scalars['String']>
  success?: Maybe<Scalars['Boolean']>
}

export type AdminDeleteUserInput = {
  clientMutationId?: InputMaybe<Scalars['String']>
  id: Scalars['ID']
}

export type AdminDeleteUserPayload = {
  __typename?: 'AdminDeleteUserPayload'
  clientMutationId?: Maybe<Scalars['String']>
  success?: Maybe<Scalars['Boolean']>
  user?: Maybe<UserNode>
}

export type AdminDowngradeUserInput = {
  clientMutationId?: InputMaybe<Scalars['String']>
  id: Scalars['ID']
}

export type AdminDowngradeUserPayload = {
  __typename?: 'AdminDowngradeUserPayload'
  clientMutationId?: Maybe<Scalars['String']>
  success?: Maybe<Scalars['Boolean']>
  user?: Maybe<UserNode>
}

export type AdminForgetPasswordLinkInput = {
  clientMutationId?: InputMaybe<Scalars['String']>
  id: Scalars['ID']
}

export type AdminForgetPasswordLinkPayload = {
  __typename?: 'AdminForgetPasswordLinkPayload'
  clientMutationId?: Maybe<Scalars['String']>
  forgetPasswordLink?: Maybe<Scalars['String']>
}

export type AdminRejectInstituteRequestInput = {
  clientMutationId?: InputMaybe<Scalars['String']>
  existingInstitute: Scalars['ID']
  id: Scalars['ID']
}

export type AdminRejectInstituteRequestPayload = {
  __typename?: 'AdminRejectInstituteRequestPayload'
  clientMutationId?: Maybe<Scalars['String']>
  instituteRequest?: Maybe<InstituteRequestNode>
  message?: Maybe<Scalars['String']>
  responseCode?: Maybe<Scalars['Int']>
}

export type AdminSetBrandLookInput = {
  clientMutationId?: InputMaybe<Scalars['String']>
  colorId: Scalars['ID']
  fontId: Scalars['ID']
  userId: Scalars['ID']
}

export type AdminSetBrandLookPayload = {
  __typename?: 'AdminSetBrandLookPayload'
  brandLookSelection?: Maybe<BrandLookSelectionNode>
  clientMutationId?: Maybe<Scalars['String']>
}

export type AdminUpdateInstituteInput = {
  city?: InputMaybe<Scalars['String']>
  clientMutationId?: InputMaybe<Scalars['String']>
  country: Scalars['String']
  id?: InputMaybe<Scalars['ID']>
  instituteTypeName: Scalars['String']
  isPrivate?: InputMaybe<Scalars['Boolean']>
  location?: InputMaybe<Scalars['String']>
  marketProfile?: InputMaybe<Scalars['ID']>
  marketProfileName: Scalars['String']
  name: Scalars['String']
  street?: InputMaybe<Scalars['String']>
  type?: InputMaybe<Scalars['ID']>
  website?: InputMaybe<Scalars['String']>
  zip?: InputMaybe<Scalars['String']>
}

export type AdminUpdateInstitutePayload = {
  __typename?: 'AdminUpdateInstitutePayload'
  clientMutationId?: Maybe<Scalars['String']>
  errors?: Maybe<Array<Maybe<ErrorType>>>
  institute?: Maybe<InstituteNode>
  message?: Maybe<Scalars['String']>
  responseCode?: Maybe<Scalars['Int']>
}

export type AdminUpdateUserInput = {
  clientMutationId?: InputMaybe<Scalars['String']>
  communicationLanguage?: InputMaybe<Scalars['String']>
  email: Scalars['String']
  firstName: Scalars['String']
  gender?: InputMaybe<Scalars['String']>
  id: Scalars['ID']
  isActive?: InputMaybe<Scalars['Boolean']>
  lastName: Scalars['String']
  optIn?: InputMaybe<Scalars['Boolean']>
  phone?: InputMaybe<Scalars['String']>
  phoneCountryCode?: InputMaybe<Scalars['String']>
}

export type AdminUpdateUserPayload = {
  __typename?: 'AdminUpdateUserPayload'
  clientMutationId?: Maybe<Scalars['String']>
  errors?: Maybe<Array<Maybe<ErrorType>>>
  user?: Maybe<UserNode>
}

export type AdminUpgradeUserInput = {
  clientMutationId?: InputMaybe<Scalars['String']>
  id: Scalars['ID']
  toSuperuser?: InputMaybe<Scalars['Boolean']>
}

/**
 * This mutation upgrades normal user to agent, or agent to super user. If you want to immediately upgrade normal
 * user to superuser, you need to provide to_superuser=True. In any case, if user is already superuser, an error is returned
 * that user is already a superuser.
 */
export type AdminUpgradeUserPayload = {
  __typename?: 'AdminUpgradeUserPayload'
  clientMutationId?: Maybe<Scalars['String']>
  success?: Maybe<Scalars['Boolean']>
  user?: Maybe<UserNode>
}

/** An enumeration. */
export enum AdvertisementsAssetChannelTypeChoices {
  /** Banner Ads */
  BannerAds = 'BANNER_ADS',
  /** Career Post */
  CareerPost = 'CAREER_POST',
  /** Sponsored Events */
  SponsoredEvents = 'SPONSORED_EVENTS',
  /** Sponsored Poll */
  SponsoredPoll = 'SPONSORED_POLL',
  /** Sponsored Post */
  SponsoredPost = 'SPONSORED_POST',
  /** Sponsored Quiz */
  SponsoredQuiz = 'SPONSORED_QUIZ'
}

/** An enumeration. */
export enum AllowedVoteType {
  Everybody = 'EVERYBODY',
  Female = 'FEMALE',
  Male = 'MALE',
  Other = 'OTHER'
}

/** An enumeration. */
export enum AnswerType {
  Couple = 'COUPLE',
  List = 'LIST',
  Person = 'PERSON'
}

/** An enumeration. */
export enum ApprovalSetting {
  AnyMember = 'ANY_MEMBER',
  AutoApproval = 'AUTO_APPROVAL',
  OnlyAdmin = 'ONLY_ADMIN'
}

export type AssetNode = Node & {
  __typename?: 'AssetNode'
  assetId: Scalars['Int']
  campaign?: Maybe<CampaignNode>
  channelData?: Maybe<Array<Maybe<BannerAd>>>
  channelType: AdvertisementsAssetChannelTypeChoices
  conversionRate?: Maybe<Scalars['Float']>
  endDate?: Maybe<Scalars['DateTime']>
  /** The ID of the object */
  id: Scalars['ID']
  isActive: Scalars['Boolean']
  name: Scalars['String']
  startDate?: Maybe<Scalars['DateTime']>
  targetingConfig: Scalars['JSONString']
  targetingFilters?: Maybe<Array<Maybe<TargetingFilterType>>>
  totalClicks?: Maybe<Scalars['Int']>
  totalViews?: Maybe<Scalars['Int']>
  visibility?: Maybe<AssetVisibilityType>
}

/** An enumeration. */
export enum AssetVisibilityType {
  Live = 'LIVE',
  Offline = 'OFFLINE',
  Schedule = 'SCHEDULE'
}

/** An enumeration. */
export enum AuthorType {
  Group = 'GROUP',
  Team = 'TEAM',
  User = 'USER'
}

export type BannerAd = {
  __typename?: 'BannerAd'
  imageLarge?: Maybe<Scalars['String']>
  imageSmall?: Maybe<Scalars['String']>
  trackingUrl?: Maybe<Scalars['String']>
}

export type BlockUserMutationInput = {
  clientMutationId?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['ID']>
}

export type BlockUserMutationPayload = {
  __typename?: 'BlockUserMutationPayload'
  clientMutationId?: Maybe<Scalars['String']>
  message?: Maybe<Scalars['String']>
  success?: Maybe<Scalars['Boolean']>
}

export type BrandColorNode = Node & {
  __typename?: 'BrandColorNode'
  created: Scalars['DateTime']
  /** The ID of the object */
  id: Scalars['ID']
  isDefault?: Maybe<Scalars['Boolean']>
  modified: Scalars['DateTime']
  name?: Maybe<Scalars['String']>
  primary?: Maybe<Scalars['String']>
  secondary?: Maybe<Scalars['String']>
  status?: Maybe<ColorStatus>
}

export type BrandColorNodeConnection = {
  __typename?: 'BrandColorNodeConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<BrandColorNodeEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
}

/** A Relay edge containing a `BrandColorNode` and its cursor. */
export type BrandColorNodeEdge = {
  __typename?: 'BrandColorNodeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node?: Maybe<BrandColorNode>
}

export type BrandFontNode = Node & {
  __typename?: 'BrandFontNode'
  created: Scalars['DateTime']
  file?: Maybe<Scalars['String']>
  /** The ID of the object */
  id: Scalars['ID']
  isDefault?: Maybe<Scalars['Boolean']>
  modified: Scalars['DateTime']
  name?: Maybe<Scalars['String']>
  secondary?: Maybe<Scalars['String']>
  status?: Maybe<FontStatus>
  weight?: Maybe<Scalars['Int']>
}

export type BrandFontNodeConnection = {
  __typename?: 'BrandFontNodeConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<BrandFontNodeEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
}

/** A Relay edge containing a `BrandFontNode` and its cursor. */
export type BrandFontNodeEdge = {
  __typename?: 'BrandFontNodeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node?: Maybe<BrandFontNode>
}

export type BrandLookSelectionHistoryNode = Node & {
  __typename?: 'BrandLookSelectionHistoryNode'
  color?: Maybe<BrandColorNode>
  created: Scalars['DateTime']
  font?: Maybe<BrandFontNode>
  /** The ID of the object */
  id: Scalars['ID']
  modified: Scalars['DateTime']
  user?: Maybe<UserNode>
}

export type BrandLookSelectionHistoryNodeConnection = {
  __typename?: 'BrandLookSelectionHistoryNodeConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<BrandLookSelectionHistoryNodeEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
}

/** A Relay edge containing a `BrandLookSelectionHistoryNode` and its cursor. */
export type BrandLookSelectionHistoryNodeEdge = {
  __typename?: 'BrandLookSelectionHistoryNodeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node?: Maybe<BrandLookSelectionHistoryNode>
}

export type BrandLookSelectionNode = Node & {
  __typename?: 'BrandLookSelectionNode'
  color?: Maybe<BrandColorNode>
  created: Scalars['DateTime']
  font?: Maybe<BrandFontNode>
  /** The ID of the object */
  id: Scalars['ID']
  modified: Scalars['DateTime']
  user?: Maybe<UserNode>
}

export type BrandLookSelectionNodeConnection = {
  __typename?: 'BrandLookSelectionNodeConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<BrandLookSelectionNodeEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
}

/** A Relay edge containing a `BrandLookSelectionNode` and its cursor. */
export type BrandLookSelectionNodeEdge = {
  __typename?: 'BrandLookSelectionNodeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node?: Maybe<BrandLookSelectionNode>
}

export type CampaignNode = Node & {
  __typename?: 'CampaignNode'
  assets?: Maybe<Array<Maybe<AssetNode>>>
  conversionRate?: Maybe<Scalars['Float']>
  customer?: Maybe<Scalars['String']>
  endDate: Scalars['DateTime']
  /** The ID of the object */
  id: Scalars['ID']
  isDeleted: Scalars['Boolean']
  name: Scalars['String']
  orderId?: Maybe<Scalars['String']>
  startDate: Scalars['DateTime']
  status?: Maybe<CampaignStatus>
  totalClicks?: Maybe<Scalars['Int']>
  totalViews?: Maybe<Scalars['Int']>
  type?: Maybe<CampaignType>
  utm: Scalars['String']
}

export type CampaignNodeConnection = {
  __typename?: 'CampaignNodeConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CampaignNodeEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  /** Number of items in the queryset. */
  totalCount: Scalars['Int']
}

/** A Relay edge containing a `CampaignNode` and its cursor. */
export type CampaignNodeEdge = {
  __typename?: 'CampaignNodeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node?: Maybe<CampaignNode>
}

/** An enumeration. */
export enum CampaignStatus {
  Active = 'ACTIVE',
  Draft = 'DRAFT',
  Inactive = 'INACTIVE'
}

/** An enumeration. */
export enum CampaignType {
  External = 'EXTERNAL',
  Internal = 'INTERNAL'
}

/** An enumeration. */
export enum ChannelType {
  BannerAds = 'BANNER_ADS',
  CareerPost = 'CAREER_POST',
  SponsoredEvents = 'SPONSORED_EVENTS',
  SponsoredPoll = 'SPONSORED_POLL',
  SponsoredPost = 'SPONSORED_POST',
  SponsoredQuiz = 'SPONSORED_QUIZ'
}

export type CityLocationNode = Node & {
  __typename?: 'CityLocationNode'
  city: Scalars['String']
  country: InstitutesCityLocationCountryChoices
  /** The ID of the object */
  id: Scalars['ID']
  location?: Maybe<Scalars['Geometry']>
  region: Scalars['String']
  zip: Scalars['String']
}

export type CityLocationNodeConnection = {
  __typename?: 'CityLocationNodeConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CityLocationNodeEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
}

/** A Relay edge containing a `CityLocationNode` and its cursor. */
export type CityLocationNodeEdge = {
  __typename?: 'CityLocationNodeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node?: Maybe<CityLocationNode>
}

export type CityNode = {
  __typename?: 'CityNode'
  name?: Maybe<Scalars['String']>
}

export type CollagesAlbumNode = Node & {
  __typename?: 'CollagesAlbumNode'
  collagesPhotos?: Maybe<CollagesPhotoNodeConnection>
  created: Scalars['DateTime']
  /** The ID of the object */
  id: Scalars['ID']
  modified: Scalars['DateTime']
  moduleInstance: ModuleInstanceNode
  name: Scalars['String']
  photosCount?: Maybe<Scalars['Int']>
  suggestedNames?: Maybe<Array<Maybe<CollagesAlbumSuggestedNameNode>>>
}

export type CollagesAlbumNodeCollagesPhotosArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
}

export type CollagesAlbumNodeConnection = {
  __typename?: 'CollagesAlbumNodeConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CollagesAlbumNodeEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
}

/** A Relay edge containing a `CollagesAlbumNode` and its cursor. */
export type CollagesAlbumNodeEdge = {
  __typename?: 'CollagesAlbumNodeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node?: Maybe<CollagesAlbumNode>
}

export type CollagesAlbumSuggestedNameNode = Node & {
  __typename?: 'CollagesAlbumSuggestedNameNode'
  created: Scalars['DateTime']
  /** The ID of the object */
  id: Scalars['ID']
  modified: Scalars['DateTime']
  name?: Maybe<Scalars['String']>
}

export type CollagesAlbumSuggestedNameNodeConnection = {
  __typename?: 'CollagesAlbumSuggestedNameNodeConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CollagesAlbumSuggestedNameNodeEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
}

/** A Relay edge containing a `CollagesAlbumSuggestedNameNode` and its cursor. */
export type CollagesAlbumSuggestedNameNodeEdge = {
  __typename?: 'CollagesAlbumSuggestedNameNodeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node?: Maybe<CollagesAlbumSuggestedNameNode>
}

export type CollagesPhotoLikeNode = Node & {
  __typename?: 'CollagesPhotoLikeNode'
  collagesPhoto?: Maybe<CollagesPhotoNode>
  created: Scalars['DateTime']
  /** The ID of the object */
  id: Scalars['ID']
  modified: Scalars['DateTime']
  userGroup?: Maybe<UserGroupNode>
}

export type CollagesPhotoLikeNodeConnection = {
  __typename?: 'CollagesPhotoLikeNodeConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CollagesPhotoLikeNodeEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
}

/** A Relay edge containing a `CollagesPhotoLikeNode` and its cursor. */
export type CollagesPhotoLikeNodeEdge = {
  __typename?: 'CollagesPhotoLikeNodeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node?: Maybe<CollagesPhotoLikeNode>
}

export type CollagesPhotoNode = Node & {
  __typename?: 'CollagesPhotoNode'
  collagesAlbum: CollagesAlbumNode
  collagesPhotoLikes?: Maybe<CollagesPhotoLikeNodeConnection>
  collagesPhotoLikesCount?: Maybe<Scalars['Int']>
  created: Scalars['DateTime']
  /** The ID of the object */
  id: Scalars['ID']
  modified: Scalars['DateTime']
  photo: Scalars['String']
  userGroup: UserGroupNode
}

export type CollagesPhotoNodeCollagesPhotoLikesArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  userGroup?: InputMaybe<Scalars['ID']>
}

export type CollagesPhotoNodeConnection = {
  __typename?: 'CollagesPhotoNodeConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CollagesPhotoNodeEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
}

/** A Relay edge containing a `CollagesPhotoNode` and its cursor. */
export type CollagesPhotoNodeEdge = {
  __typename?: 'CollagesPhotoNodeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node?: Maybe<CollagesPhotoNode>
}

export type CollagesSetupNode = Node & {
  __typename?: 'CollagesSetupNode'
  addPhotosTeam?: Maybe<TeamNode>
  createAlbumsTeam?: Maybe<TeamNode>
  created: Scalars['DateTime']
  /** The ID of the object */
  id: Scalars['ID']
  modified: Scalars['DateTime']
  moduleInstance: ModuleInstanceNode
  photosCount?: Maybe<Scalars['Int']>
  viewPhotosTeam?: Maybe<TeamNode>
}

export type ColorNode = Node & {
  __typename?: 'ColorNode'
  created: Scalars['DateTime']
  hex: Scalars['String']
  /** The ID of the object */
  id: Scalars['ID']
  modified: Scalars['DateTime']
  name: Scalars['String']
}

export type ColorNodeConnection = {
  __typename?: 'ColorNodeConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ColorNodeEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
}

/** A Relay edge containing a `ColorNode` and its cursor. */
export type ColorNodeEdge = {
  __typename?: 'ColorNodeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node?: Maybe<ColorNode>
}

/** An enumeration. */
export enum ColorStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE'
}

export type CommentLikeNode = Node & {
  __typename?: 'CommentLikeNode'
  comment?: Maybe<CommentNode>
  created: Scalars['DateTime']
  /** The ID of the object */
  id: Scalars['ID']
  modified: Scalars['DateTime']
  userGroup?: Maybe<UserGroupNode>
}

export type CommentLikeNodeConnection = {
  __typename?: 'CommentLikeNodeConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CommentLikeNodeEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
}

/** A Relay edge containing a `CommentLikeNode` and its cursor. */
export type CommentLikeNodeEdge = {
  __typename?: 'CommentLikeNodeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node?: Maybe<CommentLikeNode>
}

export type CommentNode = Node & {
  __typename?: 'CommentNode'
  created: Scalars['DateTime']
  /** The ID of the object */
  id: Scalars['ID']
  likes?: Maybe<CommentLikeNodeConnection>
  likesCount?: Maybe<Scalars['Int']>
  modified: Scalars['DateTime']
  owner?: Maybe<Owner>
  post?: Maybe<PostNode>
  team?: Maybe<TeamNode>
  text: Scalars['String']
  userGroup?: Maybe<UserGroupNode>
}

export type CommentNodeLikesArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  userGroup?: InputMaybe<Scalars['ID']>
}

export type CommentNodeConnection = {
  __typename?: 'CommentNodeConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CommentNodeEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
}

/** A Relay edge containing a `CommentNode` and its cursor. */
export type CommentNodeEdge = {
  __typename?: 'CommentNodeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node?: Maybe<CommentNode>
}

export type CommentPostInput = {
  clientMutationId?: InputMaybe<Scalars['String']>
  id: Scalars['ID']
  owner?: InputMaybe<Owner>
  team?: InputMaybe<Scalars['ID']>
  text: Scalars['String']
}

export type CommentPostPayload = {
  __typename?: 'CommentPostPayload'
  clientMutationId?: Maybe<Scalars['String']>
  post?: Maybe<PostNode>
}

export type CountryData = {
  __typename?: 'CountryData'
  country?: Maybe<Scalars['String']>
  counts?: Maybe<Array<Maybe<DateCount>>>
}

export type CreateAssetEventInput = {
  assetId: Scalars['ID']
  clientMutationId?: InputMaybe<Scalars['String']>
  eventType: EventTypeEnum
  userId: Scalars['ID']
  variant?: InputMaybe<VariantTypeEnum>
}

export type CreateAssetEventPayload = {
  __typename?: 'CreateAssetEventPayload'
  clientMutationId?: Maybe<Scalars['String']>
  success?: Maybe<Scalars['Boolean']>
}

export type CreateBannerAssetInput = {
  assetLargeImage: Scalars['Upload']
  assetSmallImage: Scalars['Upload']
  campaign: Scalars['ID']
  clientMutationId?: InputMaybe<Scalars['String']>
  targetingFilters?: InputMaybe<
    Array<InputMaybe<TargetingFilterInput>>
  >
  trackingUrl: Scalars['String']
}

export type CreateBannerAssetPayload = {
  __typename?: 'CreateBannerAssetPayload'
  asset?: Maybe<AssetNode>
  clientMutationId?: Maybe<Scalars['String']>
}

export type CreateCampaignInput = {
  clientMutationId?: InputMaybe<Scalars['String']>
  customer?: InputMaybe<Scalars['String']>
  endDate: Scalars['DateTime']
  isDeleted?: InputMaybe<Scalars['Boolean']>
  name: Scalars['String']
  orderId?: InputMaybe<Scalars['String']>
  startDate: Scalars['DateTime']
  totalClicks?: InputMaybe<Scalars['Int']>
  totalViews?: InputMaybe<Scalars['Int']>
  type: Scalars['String']
  utm: Scalars['String']
}

export type CreateCampaignPayload = {
  __typename?: 'CreateCampaignPayload'
  campaign?: Maybe<CampaignNode>
  clientMutationId?: Maybe<Scalars['String']>
  errors?: Maybe<Array<Maybe<ErrorType>>>
}

export type CreateCollagesAlbumInput = {
  clientMutationId?: InputMaybe<Scalars['String']>
  moduleInstance: Scalars['ID']
  name: Scalars['String']
}

export type CreateCollagesAlbumPayload = {
  __typename?: 'CreateCollagesAlbumPayload'
  album?: Maybe<CollagesAlbumNode>
  clientMutationId?: Maybe<Scalars['String']>
  collagesAlbum?: Maybe<CollagesAlbumNode>
  errors?: Maybe<Array<Maybe<ErrorType>>>
}

export type CreateCustomListInput = {
  clientMutationId?: InputMaybe<Scalars['String']>
  moduleId: Scalars['ID']
  options?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title: Scalars['String']
}

export type CreateCustomListOptionInput = {
  clientMutationId?: InputMaybe<Scalars['String']>
  customListId: Scalars['ID']
  text: Scalars['String']
}

export type CreateCustomListOptionPayload = {
  __typename?: 'CreateCustomListOptionPayload'
  clientMutationId?: Maybe<Scalars['String']>
  customListOption?: Maybe<CustomListOptionNode>
}

export type CreateCustomListPayload = {
  __typename?: 'CreateCustomListPayload'
  clientMutationId?: Maybe<Scalars['String']>
  customList?: Maybe<CustomListNode>
}

export type CreateCustomPagesInstanceInput = {
  clientMutationId?: InputMaybe<Scalars['String']>
  /** Module Instance ID */
  moduleInstance: Scalars['ID']
  page?: InputMaybe<Scalars['Upload']>
  pageFormat: Scalars['String']
  pageQuality?: InputMaybe<Scalars['String']>
  pageType?: InputMaybe<Scalars['String']>
  title: Scalars['String']
}

export type CreateCustomPagesInstancePayload = {
  __typename?: 'CreateCustomPagesInstancePayload'
  clientMutationId?: Maybe<Scalars['String']>
  customPagesInstance?: Maybe<CustomPagesInstanceNode>
}

export type CreateDataExportInput = {
  clientMutationId?: InputMaybe<Scalars['String']>
  exportCollages?: InputMaybe<Scalars['Boolean']>
  exportProfilePages?: InputMaybe<Scalars['Boolean']>
  exportQuotes?: InputMaybe<Scalars['Boolean']>
  exportRankings?: InputMaybe<Scalars['Boolean']>
  exportReports?: InputMaybe<Scalars['Boolean']>
  groupId: Scalars['ID']
  onlyAgent?: InputMaybe<Scalars['Boolean']>
}

export type CreateDataExportPayload = {
  __typename?: 'CreateDataExportPayload'
  clientMutationId?: Maybe<Scalars['String']>
  success?: Maybe<Scalars['Boolean']>
}

export type CreateDataPolicyAcceptanceInput = {
  agreed?: InputMaybe<Scalars['Boolean']>
  clientMutationId?: InputMaybe<Scalars['String']>
  ip: Scalars['String']
  user: Scalars['ID']
  version: Scalars['String']
}

export type CreateDataPolicyAcceptancePayload = {
  __typename?: 'CreateDataPolicyAcceptancePayload'
  clientMutationId?: Maybe<Scalars['String']>
  dataPolicyAcceptance?: Maybe<DataPolicyAcceptanceNode>
  errors?: Maybe<Array<Maybe<ErrorType>>>
  user?: Maybe<DataPolicyAcceptanceNode>
}

export type CreateEventInput = {
  authorGroup?: InputMaybe<Scalars['ID']>
  authorTeam?: InputMaybe<Scalars['ID']>
  authorType: Scalars['String']
  authorUser?: InputMaybe<Scalars['ID']>
  bannerImage?: InputMaybe<Scalars['String']>
  clientMutationId?: InputMaybe<Scalars['String']>
  companyLogo?: InputMaybe<Scalars['String']>
  companyName?: InputMaybe<Scalars['String']>
  ctaLink?: InputMaybe<Scalars['String']>
  description: Scalars['String']
  endDateTime: Scalars['DateTime']
  eventType: Scalars['String']
  isAllDay?: InputMaybe<Scalars['Boolean']>
  isJoinable?: InputMaybe<Scalars['Boolean']>
  isPublic?: InputMaybe<Scalars['Boolean']>
  isSponsored?: InputMaybe<Scalars['Boolean']>
  location: Scalars['String']
  startDateTime: Scalars['DateTime']
  title: Scalars['String']
}

export type CreateEventPayload = {
  __typename?: 'CreateEventPayload'
  clientMutationId?: Maybe<Scalars['String']>
  errors?: Maybe<Array<Maybe<ErrorType>>>
  event?: Maybe<EventNode>
}

export type CreateGroupInput = {
  clientMutationId?: InputMaybe<Scalars['String']>
  institute: Scalars['ID']
  marketProfile?: InputMaybe<Scalars['ID']>
  name: Scalars['String']
  role: Scalars['String']
  type?: InputMaybe<Scalars['ID']>
  year: Scalars['Int']
}

export type CreateGroupPayload = {
  __typename?: 'CreateGroupPayload'
  clientMutationId?: Maybe<Scalars['String']>
  errors?: Maybe<Array<Maybe<ErrorType>>>
  group?: Maybe<GroupNode>
}

export type CreateInstituteInput = {
  city?: InputMaybe<Scalars['String']>
  clientMutationId?: InputMaybe<Scalars['String']>
  country: Scalars['String']
  instituteTypeName: Scalars['String']
  isPrivate?: InputMaybe<Scalars['Boolean']>
  location?: InputMaybe<Scalars['String']>
  marketProfileName: Scalars['String']
  name: Scalars['String']
  street?: InputMaybe<Scalars['String']>
  website?: InputMaybe<Scalars['String']>
  zip?: InputMaybe<Scalars['String']>
}

export type CreateInstitutePayload = {
  __typename?: 'CreateInstitutePayload'
  clientMutationId?: Maybe<Scalars['String']>
  institute?: Maybe<InstituteNode>
  message?: Maybe<Scalars['String']>
  responseCode?: Maybe<Scalars['Int']>
}

export type CreateModuleInstanceInput = {
  clientMutationId?: InputMaybe<Scalars['String']>
  dueDate?: InputMaybe<Scalars['DateTime']>
  group: Scalars['ID']
  isActive?: InputMaybe<Scalars['Boolean']>
  module: Scalars['ID']
}

export type CreateModuleInstancePayload = {
  __typename?: 'CreateModuleInstancePayload'
  clientMutationId?: Maybe<Scalars['String']>
  errors?: Maybe<Array<Maybe<ErrorType>>>
  instance?: Maybe<ModuleInstanceNode>
  moduleInstance?: Maybe<ModuleInstanceNode>
}

export type CreatePollInput = {
  allowComments?: InputMaybe<Scalars['Boolean']>
  allowRevote?: InputMaybe<Scalars['Boolean']>
  anonymousVotes?: InputMaybe<Scalars['Boolean']>
  answersAllowed: Scalars['Int']
  clientMutationId?: InputMaybe<Scalars['String']>
  deadline: Scalars['DateTime']
  group: Scalars['ID']
  owner: Scalars['String']
  privateResults?: InputMaybe<Scalars['Boolean']>
  question: Scalars['String']
  randomizeOptions?: InputMaybe<Scalars['Boolean']>
  team?: InputMaybe<Scalars['ID']>
}

export type CreatePollOptionInput = {
  clientMutationId?: InputMaybe<Scalars['String']>
  image?: InputMaybe<Scalars['Upload']>
  poll: Scalars['ID']
  text: Scalars['String']
}

export type CreatePollOptionPayload = {
  __typename?: 'CreatePollOptionPayload'
  clientMutationId?: Maybe<Scalars['String']>
  pollOption?: Maybe<PollOptionNode>
}

export type CreatePollPayload = {
  __typename?: 'CreatePollPayload'
  clientMutationId?: Maybe<Scalars['String']>
  errors?: Maybe<Array<Maybe<ErrorType>>>
  poll?: Maybe<PollNode>
}

export type CreatePollVoteInput = {
  clientMutationId?: InputMaybe<Scalars['String']>
  pollOption: Scalars['ID']
}

export type CreatePollVotePayload = {
  __typename?: 'CreatePollVotePayload'
  clientMutationId?: Maybe<Scalars['String']>
  pollVote?: Maybe<PollVoteNode>
}

export type CreatePostInput = {
  clientMutationId?: InputMaybe<Scalars['String']>
  group: Scalars['ID']
  owner: Scalars['String']
  team?: InputMaybe<Scalars['ID']>
  text: Scalars['String']
}

export type CreatePostPayload = {
  __typename?: 'CreatePostPayload'
  clientMutationId?: Maybe<Scalars['String']>
  errors?: Maybe<Array<Maybe<ErrorType>>>
  post?: Maybe<PostNode>
}

export type CreateProfilePageAnswerInput = {
  clientMutationId?: InputMaybe<Scalars['String']>
  profilePageQuestion: Scalars['ID']
  profilePageQuestionOption?: InputMaybe<Scalars['ID']>
  text?: InputMaybe<Scalars['String']>
}

export type CreateProfilePageAnswerPayload = {
  __typename?: 'CreateProfilePageAnswerPayload'
  answer?: Maybe<ProfilePageAnswerNode>
  clientMutationId?: Maybe<Scalars['String']>
  errors?: Maybe<Array<Maybe<ErrorType>>>
  profilePageAnswer?: Maybe<ProfilePageAnswerNode>
}

export type CreateProfilePageCommentInput = {
  clientMutationId?: InputMaybe<Scalars['String']>
  moduleInstance: Scalars['ID']
  profileUserGroup: Scalars['ID']
  text: Scalars['String']
}

export type CreateProfilePageCommentPayload = {
  __typename?: 'CreateProfilePageCommentPayload'
  clientMutationId?: Maybe<Scalars['String']>
  comment?: Maybe<ProfilePageCommentNode>
  errors?: Maybe<Array<Maybe<ErrorType>>>
  profilePageComment?: Maybe<ProfilePageCommentNode>
}

export type CreateProfilePagePhotoCategoryInput = {
  clientMutationId?: InputMaybe<Scalars['String']>
  moduleInstance: Scalars['ID']
  name?: InputMaybe<Scalars['String']>
}

export type CreateProfilePagePhotoCategoryPayload = {
  __typename?: 'CreateProfilePagePhotoCategoryPayload'
  category?: Maybe<ProfilePagePhotoCategoryNode>
  clientMutationId?: Maybe<Scalars['String']>
  errors?: Maybe<Array<Maybe<ErrorType>>>
  profilePagePhotoCategory?: Maybe<ProfilePagePhotoCategoryNode>
}

export type CreateProfilePagePhotoInput = {
  clientMutationId?: InputMaybe<Scalars['String']>
  image?: InputMaybe<Scalars['Upload']>
  profilePagePhotoCategory: Scalars['ID']
}

export type CreateProfilePagePhotoPayload = {
  __typename?: 'CreateProfilePagePhotoPayload'
  clientMutationId?: Maybe<Scalars['String']>
  profilePagePhoto?: Maybe<ProfilePagePhotoNode>
}

export type CreateProfilePageQuestionInput = {
  clientMutationId?: InputMaybe<Scalars['String']>
  moduleInstance: Scalars['ID']
  text: Scalars['String']
}

export type CreateProfilePageQuestionOptionInput = {
  clientMutationId?: InputMaybe<Scalars['String']>
  profilePageQuestion: Scalars['ID']
  text: Scalars['String']
}

export type CreateProfilePageQuestionOptionPayload = {
  __typename?: 'CreateProfilePageQuestionOptionPayload'
  clientMutationId?: Maybe<Scalars['String']>
  errors?: Maybe<Array<Maybe<ErrorType>>>
  option?: Maybe<ProfilePageQuestionOptionNode>
  profilePageQuestionOption?: Maybe<ProfilePageQuestionOptionNode>
}

export type CreateProfilePageQuestionPayload = {
  __typename?: 'CreateProfilePageQuestionPayload'
  clientMutationId?: Maybe<Scalars['String']>
  errors?: Maybe<Array<Maybe<ErrorType>>>
  profilePageQuestion?: Maybe<ProfilePageQuestionNode>
  question?: Maybe<ProfilePageQuestionNode>
}

export type CreateQuotesInstanceInput = {
  clientMutationId?: InputMaybe<Scalars['String']>
  moduleInstance: Scalars['ID']
  quote: Scalars['String']
}

export type CreateQuotesInstancePayload = {
  __typename?: 'CreateQuotesInstancePayload'
  clientMutationId?: Maybe<Scalars['String']>
  errors?: Maybe<Array<Maybe<ErrorType>>>
  quote?: Maybe<QuotesInstanceNode>
  quotesInstance?: Maybe<QuotesInstanceNode>
}

export type CreateRankingsAnswerInput = {
  clientMutationId?: InputMaybe<Scalars['String']>
  rankingsQuestion: Scalars['ID']
  votee1UserGroup?: InputMaybe<Scalars['ID']>
  votee2UserGroup?: InputMaybe<Scalars['ID']>
  voteeListOption?: InputMaybe<Scalars['ID']>
}

export type CreateRankingsAnswerPayload = {
  __typename?: 'CreateRankingsAnswerPayload'
  clientMutationId?: Maybe<Scalars['String']>
  errors?: Maybe<Array<Maybe<ErrorType>>>
  rankingsAnswer?: Maybe<RankingsAnswerNode>
}

export type CreateRankingsQuestionInput = {
  allowedVotes?: InputMaybe<Scalars['String']>
  answerType: Scalars['String']
  clientMutationId?: InputMaybe<Scalars['String']>
  customList?: InputMaybe<Scalars['ID']>
  moduleInstance: Scalars['ID']
  onlyOppositeGender?: InputMaybe<Scalars['Boolean']>
  question: Scalars['String']
}

export type CreateRankingsQuestionPayload = {
  __typename?: 'CreateRankingsQuestionPayload'
  clientMutationId?: Maybe<Scalars['String']>
  errors?: Maybe<Array<Maybe<ErrorType>>>
  question?: Maybe<RankingsQuestionNode>
  rankingsQuestion?: Maybe<RankingsQuestionNode>
}

export type CreateReportsInstanceInput = {
  clientMutationId?: InputMaybe<Scalars['String']>
  editTeam?: InputMaybe<Scalars['ID']>
  maxChars: Scalars['Int']
  moduleInstance: Scalars['ID']
  text: Scalars['String']
  title: Scalars['String']
  viewTeam?: InputMaybe<Scalars['ID']>
}

export type CreateReportsInstancePayload = {
  __typename?: 'CreateReportsInstancePayload'
  clientMutationId?: Maybe<Scalars['String']>
  errors?: Maybe<Array<Maybe<ErrorType>>>
  instance?: Maybe<ReportsInstanceNode>
  reportsInstance?: Maybe<ReportsInstanceNode>
}

export type CreateTeamInput = {
  approvalSetting?: InputMaybe<Scalars['String']>
  clientMutationId?: InputMaybe<Scalars['String']>
  color: Scalars['ID']
  group: Scalars['ID']
  icon: Scalars['ID']
  name: Scalars['String']
}

export type CreateTeamPayload = {
  __typename?: 'CreateTeamPayload'
  clientMutationId?: Maybe<Scalars['String']>
  errors?: Maybe<Array<Maybe<ErrorType>>>
  team?: Maybe<TeamNode>
}

export type CreateTermsAcceptanceInput = {
  agreed?: InputMaybe<Scalars['Boolean']>
  clientMutationId?: InputMaybe<Scalars['String']>
  ip: Scalars['String']
  user: Scalars['ID']
  version: Scalars['String']
}

export type CreateTermsAcceptancePayload = {
  __typename?: 'CreateTermsAcceptancePayload'
  clientMutationId?: Maybe<Scalars['String']>
  errors?: Maybe<Array<Maybe<ErrorType>>>
  termsAcceptance?: Maybe<TermsAcceptanceNode>
  user?: Maybe<TermsAcceptanceNode>
}

export type CreateUserInput = {
  clientMutationId?: InputMaybe<Scalars['String']>
  communicationLanguage?: InputMaybe<Scalars['String']>
  countryOfResidence?: InputMaybe<Scalars['String']>
  dataPolicyAcceptance: Scalars['Boolean']
  dataPolicyVersion: Scalars['String']
  email: Scalars['String']
  firstName: Scalars['String']
  gender?: InputMaybe<Scalars['String']>
  inviteCode?: InputMaybe<Scalars['String']>
  lastName: Scalars['String']
  password: Scalars['String']
  passwordConfirmation: Scalars['String']
  phone?: InputMaybe<Scalars['String']>
  phoneCountryCode?: InputMaybe<Scalars['String']>
  referredBy?: InputMaybe<Scalars['String']>
  subscribeNewsletter?: InputMaybe<Scalars['Boolean']>
  termsAcceptance: Scalars['Boolean']
  termsVersion: Scalars['String']
}

export type CreateUserPayload = {
  __typename?: 'CreateUserPayload'
  clientMutationId?: Maybe<Scalars['String']>
  errors?: Maybe<Array<Maybe<ErrorType>>>
  user?: Maybe<UserNode>
}

export type CustomListNode = Node & {
  __typename?: 'CustomListNode'
  /** The ID of the object */
  id: Scalars['ID']
  moduleInstance: ModuleInstanceNode
  options?: Maybe<Array<Maybe<CustomListOptionNode>>>
  title?: Maybe<Scalars['String']>
}

export type CustomListNodeConnection = {
  __typename?: 'CustomListNodeConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CustomListNodeEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
}

/** A Relay edge containing a `CustomListNode` and its cursor. */
export type CustomListNodeEdge = {
  __typename?: 'CustomListNodeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node?: Maybe<CustomListNode>
}

export type CustomListOptionNode = Node & {
  __typename?: 'CustomListOptionNode'
  customList: CustomListNode
  /** The ID of the object */
  id: Scalars['ID']
  text?: Maybe<Scalars['String']>
}

export type CustomListOptionNodeConnection = {
  __typename?: 'CustomListOptionNodeConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CustomListOptionNodeEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
}

/** A Relay edge containing a `CustomListOptionNode` and its cursor. */
export type CustomListOptionNodeEdge = {
  __typename?: 'CustomListOptionNodeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node?: Maybe<CustomListOptionNode>
}

export type CustomPagesInstanceNode = Node & {
  __typename?: 'CustomPagesInstanceNode'
  created: Scalars['DateTime']
  /** The ID of the object */
  id: Scalars['ID']
  modified: Scalars['DateTime']
  moduleInstance: ModuleInstanceNode
  page?: Maybe<Scalars['String']>
  pageFormat?: Maybe<PageFormat>
  pageQuality?: Maybe<PageQuality>
  pageType?: Maybe<PageType>
  title: Scalars['String']
  userGroup?: Maybe<UserGroupNode>
}

export type CustomPagesInstanceNodeConnection = {
  __typename?: 'CustomPagesInstanceNodeConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CustomPagesInstanceNodeEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
}

/** A Relay edge containing a `CustomPagesInstanceNode` and its cursor. */
export type CustomPagesInstanceNodeEdge = {
  __typename?: 'CustomPagesInstanceNodeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node?: Maybe<CustomPagesInstanceNode>
}

export type CustomPagesSetupNode = Node & {
  __typename?: 'CustomPagesSetupNode'
  createPagesTeam?: Maybe<TeamNode>
  created: Scalars['DateTime']
  /** Intro messages to classmates to explain what to upload. */
  guide?: Maybe<Scalars['String']>
  /** The ID of the object */
  id: Scalars['ID']
  modified: Scalars['DateTime']
  moduleInstance: ModuleInstanceNode
  viewPagesTeam?: Maybe<TeamNode>
}

export type DashboardFilterInput = {
  country?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  fromDate?: InputMaybe<Scalars['Date']>
  graduationType?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  graduationYear?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>
  instituteType?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  toDate?: InputMaybe<Scalars['Date']>
}

export type DataExportNode = Node & {
  __typename?: 'DataExportNode'
  exportCollages?: Maybe<Scalars['Boolean']>
  exportFile?: Maybe<Scalars['String']>
  exportProfilePages?: Maybe<Scalars['Boolean']>
  exportQuotes?: Maybe<Scalars['Boolean']>
  exportRankings?: Maybe<Scalars['Boolean']>
  exportReports?: Maybe<Scalars['Boolean']>
  exportedFile?: Maybe<Scalars['String']>
  group?: Maybe<GroupNode>
  /** The ID of the object */
  id: Scalars['ID']
  internalId?: Maybe<Scalars['String']>
  isodatetime?: Maybe<Scalars['String']>
  userGroup?: Maybe<UserGroupNode>
}

export type DataExportNodeConnection = {
  __typename?: 'DataExportNodeConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<DataExportNodeEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
}

/** A Relay edge containing a `DataExportNode` and its cursor. */
export type DataExportNodeEdge = {
  __typename?: 'DataExportNodeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node?: Maybe<DataExportNode>
}

export type DataPolicyAcceptanceNode = Node & {
  __typename?: 'DataPolicyAcceptanceNode'
  agreed: Scalars['Boolean']
  created: Scalars['DateTime']
  /** The ID of the object */
  id: Scalars['ID']
  ip: Scalars['String']
  modified: Scalars['DateTime']
  user: UserNode
  version: Scalars['String']
}

export type DateCount = {
  __typename?: 'DateCount'
  count?: Maybe<Scalars['Int']>
  date?: Maybe<Scalars['String']>
}

export type DeactivateModuleInstanceInput = {
  clientMutationId?: InputMaybe<Scalars['String']>
  id: Scalars['ID']
}

export type DeactivateModuleInstancePayload = {
  __typename?: 'DeactivateModuleInstancePayload'
  clientMutationId?: Maybe<Scalars['String']>
  moduleInstance?: Maybe<ModuleInstanceNode>
  success?: Maybe<Scalars['Boolean']>
}

export type DefaultAvatarNode = Node & {
  __typename?: 'DefaultAvatarNode'
  avatar?: Maybe<Scalars['String']>
  created: Scalars['DateTime']
  /** The ID of the object */
  id: Scalars['ID']
  modified: Scalars['DateTime']
  name: Scalars['String']
}

export type DefaultAvatarNodeConnection = {
  __typename?: 'DefaultAvatarNodeConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<DefaultAvatarNodeEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
}

/** A Relay edge containing a `DefaultAvatarNode` and its cursor. */
export type DefaultAvatarNodeEdge = {
  __typename?: 'DefaultAvatarNodeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node?: Maybe<DefaultAvatarNode>
}

export type DeleteAssetInput = {
  assetId: Scalars['ID']
  clientMutationId?: InputMaybe<Scalars['String']>
}

export type DeleteAssetPayload = {
  __typename?: 'DeleteAssetPayload'
  assetId?: Maybe<Scalars['ID']>
  clientMutationId?: Maybe<Scalars['String']>
  success?: Maybe<Scalars['Boolean']>
}

export type DeleteCampaignInput = {
  clientMutationId?: InputMaybe<Scalars['String']>
  id: Scalars['ID']
}

export type DeleteCampaignPayload = {
  __typename?: 'DeleteCampaignPayload'
  campaign?: Maybe<CampaignNode>
  clientMutationId?: Maybe<Scalars['String']>
  success?: Maybe<Scalars['Boolean']>
}

export type DeleteCommentInput = {
  clientMutationId?: InputMaybe<Scalars['String']>
  id: Scalars['ID']
}

export type DeleteCommentPayload = {
  __typename?: 'DeleteCommentPayload'
  clientMutationId?: Maybe<Scalars['String']>
  post?: Maybe<PostNode>
}

export type DeleteCustomListInput = {
  clientMutationId?: InputMaybe<Scalars['String']>
  id: Scalars['ID']
}

export type DeleteCustomListOptionInput = {
  clientMutationId?: InputMaybe<Scalars['String']>
  id: Scalars['ID']
}

export type DeleteCustomListOptionPayload = {
  __typename?: 'DeleteCustomListOptionPayload'
  clientMutationId?: Maybe<Scalars['String']>
  customListOption?: Maybe<CustomListOptionNode>
  success?: Maybe<Scalars['Boolean']>
}

export type DeleteCustomListPayload = {
  __typename?: 'DeleteCustomListPayload'
  clientMutationId?: Maybe<Scalars['String']>
  customList?: Maybe<CustomListNode>
  success?: Maybe<Scalars['Boolean']>
}

export type DeleteEventInput = {
  clientMutationId?: InputMaybe<Scalars['String']>
  id: Scalars['ID']
}

export type DeleteEventPayload = {
  __typename?: 'DeleteEventPayload'
  clientMutationId?: Maybe<Scalars['String']>
  success?: Maybe<Scalars['Boolean']>
}

export type DeletePollInput = {
  clientMutationId?: InputMaybe<Scalars['String']>
  id: Scalars['ID']
}

export type DeletePollOptionInput = {
  clientMutationId?: InputMaybe<Scalars['String']>
  id: Scalars['ID']
}

export type DeletePollOptionPayload = {
  __typename?: 'DeletePollOptionPayload'
  clientMutationId?: Maybe<Scalars['String']>
  pollOption?: Maybe<PollOptionNode>
}

export type DeletePollPayload = {
  __typename?: 'DeletePollPayload'
  clientMutationId?: Maybe<Scalars['String']>
  poll?: Maybe<PollNode>
}

export type DeletePollVoteInput = {
  clientMutationId?: InputMaybe<Scalars['String']>
  pollOptionId: Scalars['ID']
}

export type DeletePollVotePayload = {
  __typename?: 'DeletePollVotePayload'
  clientMutationId?: Maybe<Scalars['String']>
  pollVote?: Maybe<PollVoteNode>
}

export type DeletePostInput = {
  clientMutationId?: InputMaybe<Scalars['String']>
  id: Scalars['ID']
}

export type DeletePostPayload = {
  __typename?: 'DeletePostPayload'
  clientMutationId?: Maybe<Scalars['String']>
  post?: Maybe<PostNode>
  success?: Maybe<Scalars['Boolean']>
}

export type DeleteUserInput = {
  clientMutationId?: InputMaybe<Scalars['String']>
  id: Scalars['ID']
}

export type DeleteUserPayload = {
  __typename?: 'DeleteUserPayload'
  clientMutationId?: Maybe<Scalars['String']>
  success?: Maybe<Scalars['Boolean']>
  user?: Maybe<UserNode>
}

export type DestroyCollagesAlbumInput = {
  clientMutationId?: InputMaybe<Scalars['String']>
  id: Scalars['ID']
}

export type DestroyCollagesAlbumPayload = {
  __typename?: 'DestroyCollagesAlbumPayload'
  clientMutationId?: Maybe<Scalars['String']>
  collagesAlbum?: Maybe<CollagesAlbumNode>
}

export type DestroyCustomPagesInstanceInput = {
  clientMutationId?: InputMaybe<Scalars['String']>
  id: Scalars['ID']
}

export type DestroyCustomPagesInstancePayload = {
  __typename?: 'DestroyCustomPagesInstancePayload'
  clientMutationId?: Maybe<Scalars['String']>
  customPagesInstanceId?: Maybe<Scalars['ID']>
  success?: Maybe<Scalars['Boolean']>
}

export type DestroyGroupInput = {
  clientMutationId?: InputMaybe<Scalars['String']>
  id: Scalars['ID']
}

export type DestroyGroupPayload = {
  __typename?: 'DestroyGroupPayload'
  clientMutationId?: Maybe<Scalars['String']>
  group?: Maybe<GroupNode>
}

export type DestroyModuleInstanceInput = {
  clientMutationId?: InputMaybe<Scalars['String']>
  id: Scalars['ID']
}

export type DestroyModuleInstancePayload = {
  __typename?: 'DestroyModuleInstancePayload'
  clientMutationId?: Maybe<Scalars['String']>
  moduleInstance?: Maybe<ModuleInstanceNode>
  success?: Maybe<Scalars['Boolean']>
}

export type DestroyProfilePageAnswerInput = {
  clientMutationId?: InputMaybe<Scalars['String']>
  id: Scalars['ID']
}

export type DestroyProfilePageAnswerPayload = {
  __typename?: 'DestroyProfilePageAnswerPayload'
  clientMutationId?: Maybe<Scalars['String']>
  profilePageAnswer?: Maybe<ProfilePageAnswerNode>
}

export type DestroyProfilePageCommentInput = {
  clientMutationId?: InputMaybe<Scalars['String']>
  id: Scalars['ID']
}

export type DestroyProfilePageCommentPayload = {
  __typename?: 'DestroyProfilePageCommentPayload'
  clientMutationId?: Maybe<Scalars['String']>
  profilePageComment?: Maybe<ProfilePageCommentNode>
  success?: Maybe<Scalars['Boolean']>
}

export type DestroyProfilePagePhotoCategoryInput = {
  clientMutationId?: InputMaybe<Scalars['String']>
  id: Scalars['ID']
}

export type DestroyProfilePagePhotoCategoryPayload = {
  __typename?: 'DestroyProfilePagePhotoCategoryPayload'
  clientMutationId?: Maybe<Scalars['String']>
  profilePagePhotoCategory?: Maybe<ProfilePagePhotoCategoryNode>
  success?: Maybe<Scalars['Boolean']>
}

export type DestroyProfilePagePhotoInput = {
  clientMutationId?: InputMaybe<Scalars['String']>
  id: Scalars['ID']
}

export type DestroyProfilePagePhotoPayload = {
  __typename?: 'DestroyProfilePagePhotoPayload'
  clientMutationId?: Maybe<Scalars['String']>
  profilePagePhoto?: Maybe<ProfilePagePhotoNode>
  success?: Maybe<Scalars['Boolean']>
}

export type DestroyProfilePageQuestionInput = {
  clientMutationId?: InputMaybe<Scalars['String']>
  id: Scalars['ID']
}

export type DestroyProfilePageQuestionOptionInput = {
  clientMutationId?: InputMaybe<Scalars['String']>
  id: Scalars['ID']
}

export type DestroyProfilePageQuestionOptionPayload = {
  __typename?: 'DestroyProfilePageQuestionOptionPayload'
  clientMutationId?: Maybe<Scalars['String']>
  profilePageQuestionOption?: Maybe<ProfilePageQuestionOptionNode>
}

export type DestroyProfilePageQuestionPayload = {
  __typename?: 'DestroyProfilePageQuestionPayload'
  clientMutationId?: Maybe<Scalars['String']>
  profilePageQuestion?: Maybe<ProfilePageQuestionNode>
  success?: Maybe<Scalars['Boolean']>
}

export type DestroyQuotesInstanceInput = {
  clientMutationId?: InputMaybe<Scalars['String']>
  id: Scalars['ID']
}

export type DestroyQuotesInstancePayload = {
  __typename?: 'DestroyQuotesInstancePayload'
  clientMutationId?: Maybe<Scalars['String']>
  quotesInstance?: Maybe<QuotesInstanceNode>
  success?: Maybe<Scalars['Boolean']>
}

export type DestroyRankingsAnswerInput = {
  clientMutationId?: InputMaybe<Scalars['String']>
  id: Scalars['ID']
}

export type DestroyRankingsAnswerPayload = {
  __typename?: 'DestroyRankingsAnswerPayload'
  clientMutationId?: Maybe<Scalars['String']>
  rankingsAnswer?: Maybe<RankingsAnswerNode>
}

export type DestroyRankingsQuestionInput = {
  clientMutationId?: InputMaybe<Scalars['String']>
  id: Scalars['ID']
}

export type DestroyRankingsQuestionPayload = {
  __typename?: 'DestroyRankingsQuestionPayload'
  clientMutationId?: Maybe<Scalars['String']>
  rankingsQuestion?: Maybe<RankingsQuestionNode>
}

export type DestroyReportsInstanceInput = {
  clientMutationId?: InputMaybe<Scalars['String']>
  id: Scalars['ID']
}

export type DestroyReportsInstancePayload = {
  __typename?: 'DestroyReportsInstancePayload'
  clientMutationId?: Maybe<Scalars['String']>
  reportsInstanceId?: Maybe<Scalars['ID']>
  success?: Maybe<Scalars['Boolean']>
}

export type DestroyTeamInput = {
  clientMutationId?: InputMaybe<Scalars['String']>
  id: Scalars['ID']
}

export type DestroyTeamPayload = {
  __typename?: 'DestroyTeamPayload'
  clientMutationId?: Maybe<Scalars['String']>
  success?: Maybe<Scalars['Boolean']>
  team?: Maybe<TeamNode>
}

/** Debugging information for the current query. */
export type DjangoDebug = {
  __typename?: 'DjangoDebug'
  /** Raise exceptions for this API query. */
  exceptions?: Maybe<Array<Maybe<DjangoDebugException>>>
  /** Executed SQL queries for this API query. */
  sql?: Maybe<Array<Maybe<DjangoDebugSql>>>
}

/** Represents a single exception raised. */
export type DjangoDebugException = {
  __typename?: 'DjangoDebugException'
  /** The class of the exception */
  excType: Scalars['String']
  /** The message of the exception */
  message: Scalars['String']
  /** The stack trace */
  stack: Scalars['String']
}

/** Represents a single database query made to a Django managed DB. */
export type DjangoDebugSql = {
  __typename?: 'DjangoDebugSQL'
  /** The Django database alias (e.g. 'default'). */
  alias: Scalars['String']
  /** Duration of this database query in seconds. */
  duration: Scalars['Float']
  /** Postgres connection encoding if available. */
  encoding?: Maybe<Scalars['String']>
  /** Whether this database query was a SELECT. */
  isSelect: Scalars['Boolean']
  /** Whether this database query took more than 10 seconds. */
  isSlow: Scalars['Boolean']
  /** Postgres isolation level if available. */
  isoLevel?: Maybe<Scalars['String']>
  /** JSON encoded database query parameters. */
  params: Scalars['String']
  /** The raw SQL of this query, without params. */
  rawSql: Scalars['String']
  /** The actual SQL sent to this database. */
  sql?: Maybe<Scalars['String']>
  /** Start time of this database query. */
  startTime: Scalars['Float']
  /** Stop time of this database query. */
  stopTime: Scalars['Float']
  /** Postgres transaction ID if available. */
  transId?: Maybe<Scalars['String']>
  /** Postgres transaction status if available. */
  transStatus?: Maybe<Scalars['String']>
  /** The type of database being used (e.g. postrgesql, mysql, sqlite). */
  vendor: Scalars['String']
}

export type DuplicateAssetInput = {
  assetId: Scalars['ID']
  clientMutationId?: InputMaybe<Scalars['String']>
}

export type DuplicateAssetPayload = {
  __typename?: 'DuplicateAssetPayload'
  clientMutationId?: Maybe<Scalars['String']>
  newAsset?: Maybe<AssetNode>
}

export type EditBannerAdAssetInput = {
  assetLargeImage?: InputMaybe<Scalars['Upload']>
  assetSmallImage?: InputMaybe<Scalars['Upload']>
  clientMutationId?: InputMaybe<Scalars['String']>
  endDate?: InputMaybe<Scalars['DateTime']>
  id: Scalars['ID']
  startDate?: InputMaybe<Scalars['DateTime']>
  targetingFilters?: InputMaybe<
    Array<InputMaybe<TargetingFilterInput>>
  >
  trackingUrl?: InputMaybe<Scalars['String']>
  visibility?: InputMaybe<AssetVisibilityType>
}

export type EditBannerAdAssetPayload = {
  __typename?: 'EditBannerAdAssetPayload'
  asset?: Maybe<AssetNode>
  clientMutationId?: Maybe<Scalars['String']>
}

export type EditCampaignInput = {
  clientMutationId?: InputMaybe<Scalars['String']>
  customer?: InputMaybe<Scalars['String']>
  endDate: Scalars['DateTime']
  id: Scalars['ID']
  isDeleted?: InputMaybe<Scalars['Boolean']>
  name: Scalars['String']
  orderId?: InputMaybe<Scalars['String']>
  startDate: Scalars['DateTime']
  type: Scalars['String']
  utm: Scalars['String']
}

export type EditCampaignPayload = {
  __typename?: 'EditCampaignPayload'
  campaign?: Maybe<CampaignNode>
  clientMutationId?: Maybe<Scalars['String']>
  errors?: Maybe<Array<Maybe<ErrorType>>>
}

export type EditCommentInput = {
  clientMutationId?: InputMaybe<Scalars['String']>
  id: Scalars['ID']
  text: Scalars['String']
}

export type EditCommentPayload = {
  __typename?: 'EditCommentPayload'
  clientMutationId?: Maybe<Scalars['String']>
  post?: Maybe<PostNode>
}

export type EditEventInput = {
  authorGroup?: InputMaybe<Scalars['ID']>
  authorTeam?: InputMaybe<Scalars['ID']>
  authorType: Scalars['String']
  authorUser?: InputMaybe<Scalars['ID']>
  bannerImage?: InputMaybe<Scalars['String']>
  clientMutationId?: InputMaybe<Scalars['String']>
  companyLogo?: InputMaybe<Scalars['String']>
  companyName?: InputMaybe<Scalars['String']>
  ctaLink?: InputMaybe<Scalars['String']>
  description?: InputMaybe<Scalars['String']>
  endDateTime: Scalars['DateTime']
  eventType: Scalars['String']
  id: Scalars['ID']
  isAllDay?: InputMaybe<Scalars['Boolean']>
  isJoinable?: InputMaybe<Scalars['Boolean']>
  isPublic?: InputMaybe<Scalars['Boolean']>
  isSponsored?: InputMaybe<Scalars['Boolean']>
  location?: InputMaybe<Scalars['String']>
  startDateTime: Scalars['DateTime']
  title: Scalars['String']
}

export type EditEventPayload = {
  __typename?: 'EditEventPayload'
  clientMutationId?: Maybe<Scalars['String']>
  errors?: Maybe<Array<Maybe<ErrorType>>>
  event?: Maybe<EventNode>
}

export type ErrorType = {
  __typename?: 'ErrorType'
  field: Scalars['String']
  messages: Array<Scalars['String']>
}

export type EventAttendeeNode = Node & {
  __typename?: 'EventAttendeeNode'
  event: EventNode
  eventId: EventNode
  /** The ID of the object */
  id: Scalars['ID']
  user: UserNode
}

export type EventAttendeeNodeConnection = {
  __typename?: 'EventAttendeeNodeConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<EventAttendeeNodeEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
}

/** A Relay edge containing a `EventAttendeeNode` and its cursor. */
export type EventAttendeeNodeEdge = {
  __typename?: 'EventAttendeeNodeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node?: Maybe<EventAttendeeNode>
}

export type EventNode = Node & {
  __typename?: 'EventNode'
  attendees?: Maybe<Array<Maybe<EventAttendeeNode>>>
  authorGroup?: Maybe<GroupNode>
  authorTeam?: Maybe<TeamNode>
  authorType: EventsEventAuthorTypeChoices
  authorUser?: Maybe<UserNode>
  bannerImage?: Maybe<Scalars['String']>
  companyLogo?: Maybe<Scalars['String']>
  companyName?: Maybe<Scalars['String']>
  ctaLink?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  endDateTime: Scalars['DateTime']
  eventAuthor?: Maybe<AuthorType>
  eventType?: Maybe<EventType>
  eventattendeeSet: EventAttendeeNodeConnection
  /** The ID of the object */
  id: Scalars['ID']
  isAllDay: Scalars['Boolean']
  isJoinable: Scalars['Boolean']
  isPublic: Scalars['Boolean']
  isSponsored: Scalars['Boolean']
  location?: Maybe<Scalars['String']>
  startDateTime: Scalars['DateTime']
  title: Scalars['String']
}

export type EventNodeEventattendeeSetArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
}

export type EventNodeConnection = {
  __typename?: 'EventNodeConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<EventNodeEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
}

/** A Relay edge containing a `EventNode` and its cursor. */
export type EventNodeEdge = {
  __typename?: 'EventNodeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node?: Maybe<EventNode>
}

/** An enumeration. */
export enum EventType {
  Event = 'EVENT',
  Meeting = 'MEETING',
  Party = 'PARTY'
}

export enum EventTypeEnum {
  Click = 'CLICK',
  View = 'VIEW'
}

/** An enumeration. */
export enum EventsEventAuthorTypeChoices {
  /** Group */
  Group = 'GROUP',
  /** Team */
  Team = 'TEAM',
  /** User */
  User = 'USER'
}

export type ExternalEventAttendeeNode = Node & {
  __typename?: 'ExternalEventAttendeeNode'
  attendee: UserNode
  eventId?: Maybe<Scalars['String']>
  /** The ID of the object */
  id: Scalars['ID']
  platform?: Maybe<Platform>
}

export type ExternalEventAttendeeNodeConnection = {
  __typename?: 'ExternalEventAttendeeNodeConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ExternalEventAttendeeNodeEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
}

/** A Relay edge containing a `ExternalEventAttendeeNode` and its cursor. */
export type ExternalEventAttendeeNodeEdge = {
  __typename?: 'ExternalEventAttendeeNodeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node?: Maybe<ExternalEventAttendeeNode>
}

export type ExternalTokensNode = {
  __typename?: 'ExternalTokensNode'
  intercom?: Maybe<Scalars['String']>
  shopify?: Maybe<Scalars['String']>
}

/** An enumeration. */
export enum FontStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE'
}

export type ForgetPasswordInput = {
  clientMutationId?: InputMaybe<Scalars['String']>
  email: Scalars['String']
}

export type ForgetPasswordPayload = {
  __typename?: 'ForgetPasswordPayload'
  clientMutationId?: Maybe<Scalars['String']>
  errors?: Maybe<Array<Maybe<ErrorType>>>
  message?: Maybe<Scalars['String']>
  user?: Maybe<UserNode>
}

export type GenerateShopifyRedirectUrlInput = {
  clientMutationId?: InputMaybe<Scalars['String']>
  returnUrl?: InputMaybe<Scalars['String']>
}

export type GenerateShopifyRedirectUrlPayload = {
  __typename?: 'GenerateShopifyRedirectUrlPayload'
  clientMutationId?: Maybe<Scalars['String']>
  redirectUrl?: Maybe<Scalars['String']>
}

export type GraduationTypeCountryNode = {
  __typename?: 'GraduationTypeCountryNode'
  country?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
}

export type GraduationTypeNode = Node & {
  __typename?: 'GraduationTypeNode'
  created: Scalars['DateTime']
  /** The ID of the object */
  id: Scalars['ID']
  modified: Scalars['DateTime']
  name: Scalars['String']
}

export type GraduationTypeNodeConnection = {
  __typename?: 'GraduationTypeNodeConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GraduationTypeNodeEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
}

/** A Relay edge containing a `GraduationTypeNode` and its cursor. */
export type GraduationTypeNodeEdge = {
  __typename?: 'GraduationTypeNodeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node?: Maybe<GraduationTypeNode>
}

export type GroupIconNode = Node & {
  __typename?: 'GroupIconNode'
  created: Scalars['DateTime']
  icon?: Maybe<Scalars['String']>
  /** The ID of the object */
  id: Scalars['ID']
  modified: Scalars['DateTime']
  name: Scalars['String']
}

export type GroupIconNodeConnection = {
  __typename?: 'GroupIconNodeConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GroupIconNodeEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
}

/** A Relay edge containing a `GroupIconNode` and its cursor. */
export type GroupIconNodeEdge = {
  __typename?: 'GroupIconNodeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node?: Maybe<GroupIconNode>
}

export type GroupMemberNode = Node & {
  __typename?: 'GroupMemberNode'
  avatar?: Maybe<Scalars['String']>
  collagesalbumSet: CollagesAlbumNodeConnection
  collagesphotoSet: CollagesPhotoNodeConnection
  collagesphotolikeSet: CollagesPhotoLikeNodeConnection
  commentSet: CommentNodeConnection
  commentlikeSet: CommentLikeNodeConnection
  created: Scalars['DateTime']
  custompagesinstanceSet: CustomPagesInstanceNodeConnection
  defaultAvatar?: Maybe<DefaultAvatarNode>
  email?: Maybe<Scalars['String']>
  firstName?: Maybe<Scalars['String']>
  group: GroupNode
  /** The ID of the object */
  id: Scalars['ID']
  lastName?: Maybe<Scalars['String']>
  likeSet: LikeNodeConnection
  modified: Scalars['DateTime']
  phone?: Maybe<Scalars['String']>
  phoneCountryCode?: Maybe<Scalars['String']>
  pollvoteSet: PollVoteNodeConnection
  postSet: PostNodeConnection
  profilePageCommentsGiven: ProfilePageCommentNodeConnection
  profilePageCommentsReceived: ProfilePageCommentNodeConnection
  profilepageanswerSet: ProfilePageAnswerNodeConnection
  profilepagephotoSet: ProfilePagePhotoNodeConnection
  quotesinstanceSet: QuotesInstanceNodeConnection
  quotesinstancelikeSet: QuotesInstanceLikeNodeConnection
  rankingsAnswerGiven: RankingsAnswerNodeConnection
  rankingsAnswerVotedFor1: RankingsAnswerNodeConnection
  rankingsAnswerVotedFor2: RankingsAnswerNodeConnection
  role: GroupsUserGroupRoleChoices
  status: GroupsUserGroupStatusChoices
  teams?: Maybe<Array<Maybe<TeamNode>>>
  user: UserNode
}

export type GroupMemberNodeCollagesalbumSetArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
}

export type GroupMemberNodeCollagesphotoSetArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
}

export type GroupMemberNodeCollagesphotolikeSetArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
}

export type GroupMemberNodeCommentSetArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
}

export type GroupMemberNodeCommentlikeSetArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
}

export type GroupMemberNodeCustompagesinstanceSetArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
}

export type GroupMemberNodeLikeSetArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
}

export type GroupMemberNodePollvoteSetArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
}

export type GroupMemberNodePostSetArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
}

export type GroupMemberNodeProfilePageCommentsGivenArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
}

export type GroupMemberNodeProfilePageCommentsReceivedArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
}

export type GroupMemberNodeProfilepageanswerSetArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
}

export type GroupMemberNodeProfilepagephotoSetArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
}

export type GroupMemberNodeQuotesinstanceSetArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
}

export type GroupMemberNodeQuotesinstancelikeSetArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
}

export type GroupMemberNodeRankingsAnswerGivenArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
}

export type GroupMemberNodeRankingsAnswerVotedFor1Args = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
}

export type GroupMemberNodeRankingsAnswerVotedFor2Args = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
}

export type GroupNode = Node & {
  __typename?: 'GroupNode'
  allMembers: Array<Maybe<GroupMemberNode>>
  avatar?: Maybe<Scalars['String']>
  created: Scalars['DateTime']
  icon?: Maybe<GroupIconNode>
  /** The ID of the object */
  id: Scalars['ID']
  institute: InstituteNode
  isActive?: Maybe<Scalars['Boolean']>
  isImported?: Maybe<Scalars['Boolean']>
  marketProfile?: Maybe<MarketProfileNode>
  members: Array<Maybe<GroupMemberNode>>
  modified: Scalars['DateTime']
  name: Scalars['String']
  sampleMembers?: Maybe<Array<Maybe<UserGroupNode>>>
  teams?: Maybe<Array<Maybe<TeamNode>>>
  token?: Maybe<Scalars['String']>
  type: GraduationTypeNode
  users?: Maybe<Array<Maybe<UserGroupNode>>>
  usersCount?: Maybe<Scalars['Int']>
  /** Who can create a new team? */
  whoCanCreateTeam?: Maybe<TeamNode>
  year: Scalars['Int']
  yearbookStatistics?: Maybe<YearbookStatisticsNode>
}

export type GroupNodeConnection = {
  __typename?: 'GroupNodeConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GroupNodeEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
}

/** A Relay edge containing a `GroupNode` and its cursor. */
export type GroupNodeEdge = {
  __typename?: 'GroupNodeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node?: Maybe<GroupNode>
}

/** An enumeration. */
export enum GroupsUserGroupRoleChoices {
  /** Student */
  Student = 'STUDENT',
  /** Teacher */
  Teacher = 'TEACHER'
}

/** An enumeration. */
export enum GroupsUserGroupStatusChoices {
  /** Accepted */
  Accepted = 'ACCEPTED',
  /** Pending */
  Pending = 'PENDING',
  /** Rejected */
  Rejected = 'REJECTED'
}

export type IconNode = Node & {
  __typename?: 'IconNode'
  created: Scalars['DateTime']
  icon: Scalars['String']
  /** The ID of the object */
  id: Scalars['ID']
  modified: Scalars['DateTime']
  name: Scalars['String']
}

export type IconNodeConnection = {
  __typename?: 'IconNodeConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<IconNodeEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
}

/** A Relay edge containing a `IconNode` and its cursor. */
export type IconNodeEdge = {
  __typename?: 'IconNodeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node?: Maybe<IconNode>
}

export type ImportToErpInput = {
  clientMutationId?: InputMaybe<Scalars['String']>
  groupId: Scalars['ID']
  instituteId: Scalars['ID']
  userId: Scalars['ID']
}

export type ImportToErpPayload = {
  __typename?: 'ImportToErpPayload'
  clientMutationId?: Maybe<Scalars['String']>
  success?: Maybe<Scalars['Boolean']>
}

export type Importer = {
  __typename?: 'Importer'
  error?: Maybe<Scalars['String']>
  ok?: Maybe<Scalars['Boolean']>
  userNumber?: Maybe<Scalars['Int']>
}

export type InstituteActivityType = {
  __typename?: 'InstituteActivityType'
  status?: Maybe<InstituteActivityTypeEnum>
  year?: Maybe<Scalars['Int']>
}

export enum InstituteActivityTypeEnum {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE'
}

export type InstituteNode = Node & {
  __typename?: 'InstituteNode'
  city?: Maybe<Scalars['String']>
  country: InstitutesInstituteCountryChoices
  created: Scalars['DateTime']
  groups?: Maybe<Array<Maybe<GroupNode>>>
  /** The ID of the object */
  id: Scalars['ID']
  instituteActivity?: Maybe<Array<Maybe<InstituteActivityType>>>
  internalId: Scalars['String']
  isPrivate: Scalars['Boolean']
  location?: Maybe<Scalars['Geometry']>
  marketProfile?: Maybe<MarketProfileNode>
  modified: Scalars['DateTime']
  name: Scalars['String']
  street?: Maybe<Scalars['String']>
  type: InstituteTypeNode
  website?: Maybe<Scalars['String']>
  zip?: Maybe<Scalars['String']>
}

export type InstituteNodeConnection = {
  __typename?: 'InstituteNodeConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<InstituteNodeEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  /** Number of items in the queryset. */
  totalCount: Scalars['Int']
}

/** A Relay edge containing a `InstituteNode` and its cursor. */
export type InstituteNodeEdge = {
  __typename?: 'InstituteNodeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node?: Maybe<InstituteNode>
}

export type InstituteRequestNode = Node & {
  __typename?: 'InstituteRequestNode'
  city?: Maybe<Scalars['String']>
  country: InstitutesInstituteRequestCountryChoices
  created: Scalars['DateTime']
  /** The ID of the object */
  id: Scalars['ID']
  name: Scalars['String']
  status?: Maybe<Status>
  street?: Maybe<Scalars['String']>
  type: InstituteTypeNode
  user: UserNode
  website?: Maybe<Scalars['String']>
  zip?: Maybe<Scalars['String']>
}

export type InstituteRequestNodeConnection = {
  __typename?: 'InstituteRequestNodeConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<InstituteRequestNodeEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
}

/** A Relay edge containing a `InstituteRequestNode` and its cursor. */
export type InstituteRequestNodeEdge = {
  __typename?: 'InstituteRequestNodeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node?: Maybe<InstituteRequestNode>
}

/** An enumeration. */
export enum InstituteTargetingActivityEnum {
  Active = 'ACTIVE',
  Empty = 'EMPTY',
  Inactive = 'INACTIVE'
}

export type InstituteTargetingNode = Node & {
  __typename?: 'InstituteTargetingNode'
  activity?: Maybe<InstituteTargetingActivityEnum>
  city?: Maybe<Scalars['String']>
  country: InstitutesInstituteCountryChoices
  /** The ID of the object */
  id: Scalars['ID']
  internalId: Scalars['String']
  location?: Maybe<Scalars['Geometry']>
  name: Scalars['String']
  type: InstituteTypeNode
}

export type InstituteTypeNode = Node & {
  __typename?: 'InstituteTypeNode'
  country?: Maybe<InstitutesInstituteTypeCountryChoices>
  created: Scalars['DateTime']
  graduationTypes?: Maybe<Array<Maybe<GraduationTypeNode>>>
  /** The ID of the object */
  id: Scalars['ID']
  modified: Scalars['DateTime']
  name: Scalars['String']
}

export type InstituteTypeNodeConnection = {
  __typename?: 'InstituteTypeNodeConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<InstituteTypeNodeEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
}

/** A Relay edge containing a `InstituteTypeNode` and its cursor. */
export type InstituteTypeNodeEdge = {
  __typename?: 'InstituteTypeNodeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node?: Maybe<InstituteTypeNode>
}

/** An enumeration. */
export enum InstitutesCityLocationCountryChoices {
  /** Andorra */
  Ad = 'AD',
  /** United Arab Emirates */
  Ae = 'AE',
  /** Afghanistan */
  Af = 'AF',
  /** Antigua and Barbuda */
  Ag = 'AG',
  /** Anguilla */
  Ai = 'AI',
  /** Albania */
  Al = 'AL',
  /** Armenia */
  Am = 'AM',
  /** Angola */
  Ao = 'AO',
  /** Antarctica */
  Aq = 'AQ',
  /** Argentina */
  Ar = 'AR',
  /** American Samoa */
  As = 'AS',
  /** Austria */
  At = 'AT',
  /** Australia */
  Au = 'AU',
  /** Aruba */
  Aw = 'AW',
  /** Åland Islands */
  Ax = 'AX',
  /** Azerbaijan */
  Az = 'AZ',
  /** Bosnia and Herzegovina */
  Ba = 'BA',
  /** Barbados */
  Bb = 'BB',
  /** Bangladesh */
  Bd = 'BD',
  /** Belgium */
  Be = 'BE',
  /** Burkina Faso */
  Bf = 'BF',
  /** Bulgaria */
  Bg = 'BG',
  /** Bahrain */
  Bh = 'BH',
  /** Burundi */
  Bi = 'BI',
  /** Benin */
  Bj = 'BJ',
  /** Saint Barthélemy */
  Bl = 'BL',
  /** Bermuda */
  Bm = 'BM',
  /** Brunei */
  Bn = 'BN',
  /** Bolivia */
  Bo = 'BO',
  /** Bonaire, Sint Eustatius and Saba */
  Bq = 'BQ',
  /** Brazil */
  Br = 'BR',
  /** Bahamas */
  Bs = 'BS',
  /** Bhutan */
  Bt = 'BT',
  /** Bouvet Island */
  Bv = 'BV',
  /** Botswana */
  Bw = 'BW',
  /** Belarus */
  By = 'BY',
  /** Belize */
  Bz = 'BZ',
  /** Canada */
  Ca = 'CA',
  /** Cocos (Keeling) Islands */
  Cc = 'CC',
  /** Congo (the Democratic Republic of the) */
  Cd = 'CD',
  /** Central African Republic */
  Cf = 'CF',
  /** Congo */
  Cg = 'CG',
  /** Switzerland */
  Ch = 'CH',
  /** Côte d'Ivoire */
  Ci = 'CI',
  /** Cook Islands */
  Ck = 'CK',
  /** Chile */
  Cl = 'CL',
  /** Cameroon */
  Cm = 'CM',
  /** China */
  Cn = 'CN',
  /** Colombia */
  Co = 'CO',
  /** Costa Rica */
  Cr = 'CR',
  /** Cuba */
  Cu = 'CU',
  /** Cabo Verde */
  Cv = 'CV',
  /** Curaçao */
  Cw = 'CW',
  /** Christmas Island */
  Cx = 'CX',
  /** Cyprus */
  Cy = 'CY',
  /** Czechia */
  Cz = 'CZ',
  /** Germany */
  De = 'DE',
  /** Djibouti */
  Dj = 'DJ',
  /** Denmark */
  Dk = 'DK',
  /** Dominica */
  Dm = 'DM',
  /** Dominican Republic */
  Do = 'DO',
  /** Algeria */
  Dz = 'DZ',
  /** Ecuador */
  Ec = 'EC',
  /** Estonia */
  Ee = 'EE',
  /** Egypt */
  Eg = 'EG',
  /** Western Sahara */
  Eh = 'EH',
  /** Eritrea */
  Er = 'ER',
  /** Spain */
  Es = 'ES',
  /** Ethiopia */
  Et = 'ET',
  /** Finland */
  Fi = 'FI',
  /** Fiji */
  Fj = 'FJ',
  /** Falkland Islands (Malvinas) */
  Fk = 'FK',
  /** Micronesia (Federated States of) */
  Fm = 'FM',
  /** Faroe Islands */
  Fo = 'FO',
  /** France */
  Fr = 'FR',
  /** Gabon */
  Ga = 'GA',
  /** United Kingdom */
  Gb = 'GB',
  /** Grenada */
  Gd = 'GD',
  /** Georgia */
  Ge = 'GE',
  /** French Guiana */
  Gf = 'GF',
  /** Guernsey */
  Gg = 'GG',
  /** Ghana */
  Gh = 'GH',
  /** Gibraltar */
  Gi = 'GI',
  /** Greenland */
  Gl = 'GL',
  /** Gambia */
  Gm = 'GM',
  /** Guinea */
  Gn = 'GN',
  /** Guadeloupe */
  Gp = 'GP',
  /** Equatorial Guinea */
  Gq = 'GQ',
  /** Greece */
  Gr = 'GR',
  /** South Georgia and the South Sandwich Islands */
  Gs = 'GS',
  /** Guatemala */
  Gt = 'GT',
  /** Guam */
  Gu = 'GU',
  /** Guinea-Bissau */
  Gw = 'GW',
  /** Guyana */
  Gy = 'GY',
  /** Hong Kong */
  Hk = 'HK',
  /** Heard Island and McDonald Islands */
  Hm = 'HM',
  /** Honduras */
  Hn = 'HN',
  /** Croatia */
  Hr = 'HR',
  /** Haiti */
  Ht = 'HT',
  /** Hungary */
  Hu = 'HU',
  /** Indonesia */
  Id = 'ID',
  /** Ireland */
  Ie = 'IE',
  /** Israel */
  Il = 'IL',
  /** Isle of Man */
  Im = 'IM',
  /** India */
  In = 'IN',
  /** British Indian Ocean Territory */
  Io = 'IO',
  /** Iraq */
  Iq = 'IQ',
  /** Iran */
  Ir = 'IR',
  /** Iceland */
  Is = 'IS',
  /** Italy */
  It = 'IT',
  /** Jersey */
  Je = 'JE',
  /** Jamaica */
  Jm = 'JM',
  /** Jordan */
  Jo = 'JO',
  /** Japan */
  Jp = 'JP',
  /** Kenya */
  Ke = 'KE',
  /** Kyrgyzstan */
  Kg = 'KG',
  /** Cambodia */
  Kh = 'KH',
  /** Kiribati */
  Ki = 'KI',
  /** Comoros */
  Km = 'KM',
  /** Saint Kitts and Nevis */
  Kn = 'KN',
  /** North Korea */
  Kp = 'KP',
  /** South Korea */
  Kr = 'KR',
  /** Kuwait */
  Kw = 'KW',
  /** Cayman Islands */
  Ky = 'KY',
  /** Kazakhstan */
  Kz = 'KZ',
  /** Laos */
  La = 'LA',
  /** Lebanon */
  Lb = 'LB',
  /** Saint Lucia */
  Lc = 'LC',
  /** Liechtenstein */
  Li = 'LI',
  /** Sri Lanka */
  Lk = 'LK',
  /** Liberia */
  Lr = 'LR',
  /** Lesotho */
  Ls = 'LS',
  /** Lithuania */
  Lt = 'LT',
  /** Luxembourg */
  Lu = 'LU',
  /** Latvia */
  Lv = 'LV',
  /** Libya */
  Ly = 'LY',
  /** Morocco */
  Ma = 'MA',
  /** Monaco */
  Mc = 'MC',
  /** Moldova */
  Md = 'MD',
  /** Montenegro */
  Me = 'ME',
  /** Saint Martin (French part) */
  Mf = 'MF',
  /** Madagascar */
  Mg = 'MG',
  /** Marshall Islands */
  Mh = 'MH',
  /** North Macedonia */
  Mk = 'MK',
  /** Mali */
  Ml = 'ML',
  /** Myanmar */
  Mm = 'MM',
  /** Mongolia */
  Mn = 'MN',
  /** Macao */
  Mo = 'MO',
  /** Northern Mariana Islands */
  Mp = 'MP',
  /** Martinique */
  Mq = 'MQ',
  /** Mauritania */
  Mr = 'MR',
  /** Montserrat */
  Ms = 'MS',
  /** Malta */
  Mt = 'MT',
  /** Mauritius */
  Mu = 'MU',
  /** Maldives */
  Mv = 'MV',
  /** Malawi */
  Mw = 'MW',
  /** Mexico */
  Mx = 'MX',
  /** Malaysia */
  My = 'MY',
  /** Mozambique */
  Mz = 'MZ',
  /** Namibia */
  Na = 'NA',
  /** New Caledonia */
  Nc = 'NC',
  /** Niger */
  Ne = 'NE',
  /** Norfolk Island */
  Nf = 'NF',
  /** Nigeria */
  Ng = 'NG',
  /** Nicaragua */
  Ni = 'NI',
  /** Netherlands */
  Nl = 'NL',
  /** Norway */
  No = 'NO',
  /** Nepal */
  Np = 'NP',
  /** Nauru */
  Nr = 'NR',
  /** Niue */
  Nu = 'NU',
  /** New Zealand */
  Nz = 'NZ',
  /** Oman */
  Om = 'OM',
  /** Panama */
  Pa = 'PA',
  /** Peru */
  Pe = 'PE',
  /** French Polynesia */
  Pf = 'PF',
  /** Papua New Guinea */
  Pg = 'PG',
  /** Philippines */
  Ph = 'PH',
  /** Pakistan */
  Pk = 'PK',
  /** Poland */
  Pl = 'PL',
  /** Saint Pierre and Miquelon */
  Pm = 'PM',
  /** Pitcairn */
  Pn = 'PN',
  /** Puerto Rico */
  Pr = 'PR',
  /** Palestine, State of */
  Ps = 'PS',
  /** Portugal */
  Pt = 'PT',
  /** Palau */
  Pw = 'PW',
  /** Paraguay */
  Py = 'PY',
  /** Qatar */
  Qa = 'QA',
  /** Réunion */
  Re = 'RE',
  /** Romania */
  Ro = 'RO',
  /** Serbia */
  Rs = 'RS',
  /** Russia */
  Ru = 'RU',
  /** Rwanda */
  Rw = 'RW',
  /** Saudi Arabia */
  Sa = 'SA',
  /** Solomon Islands */
  Sb = 'SB',
  /** Seychelles */
  Sc = 'SC',
  /** Sudan */
  Sd = 'SD',
  /** Sweden */
  Se = 'SE',
  /** Singapore */
  Sg = 'SG',
  /** Saint Helena, Ascension and Tristan da Cunha */
  Sh = 'SH',
  /** Slovenia */
  Si = 'SI',
  /** Svalbard and Jan Mayen */
  Sj = 'SJ',
  /** Slovakia */
  Sk = 'SK',
  /** Sierra Leone */
  Sl = 'SL',
  /** San Marino */
  Sm = 'SM',
  /** Senegal */
  Sn = 'SN',
  /** Somalia */
  So = 'SO',
  /** Suriname */
  Sr = 'SR',
  /** South Sudan */
  Ss = 'SS',
  /** Sao Tome and Principe */
  St = 'ST',
  /** El Salvador */
  Sv = 'SV',
  /** Sint Maarten (Dutch part) */
  Sx = 'SX',
  /** Syria */
  Sy = 'SY',
  /** Eswatini */
  Sz = 'SZ',
  /** Turks and Caicos Islands */
  Tc = 'TC',
  /** Chad */
  Td = 'TD',
  /** French Southern Territories */
  Tf = 'TF',
  /** Togo */
  Tg = 'TG',
  /** Thailand */
  Th = 'TH',
  /** Tajikistan */
  Tj = 'TJ',
  /** Tokelau */
  Tk = 'TK',
  /** Timor-Leste */
  Tl = 'TL',
  /** Turkmenistan */
  Tm = 'TM',
  /** Tunisia */
  Tn = 'TN',
  /** Tonga */
  To = 'TO',
  /** Turkey */
  Tr = 'TR',
  /** Trinidad and Tobago */
  Tt = 'TT',
  /** Tuvalu */
  Tv = 'TV',
  /** Taiwan */
  Tw = 'TW',
  /** Tanzania */
  Tz = 'TZ',
  /** Ukraine */
  Ua = 'UA',
  /** Uganda */
  Ug = 'UG',
  /** United States Minor Outlying Islands */
  Um = 'UM',
  /** United States of America */
  Us = 'US',
  /** Uruguay */
  Uy = 'UY',
  /** Uzbekistan */
  Uz = 'UZ',
  /** Holy See */
  Va = 'VA',
  /** Saint Vincent and the Grenadines */
  Vc = 'VC',
  /** Venezuela */
  Ve = 'VE',
  /** Virgin Islands (British) */
  Vg = 'VG',
  /** Virgin Islands (U.S.) */
  Vi = 'VI',
  /** Vietnam */
  Vn = 'VN',
  /** Vanuatu */
  Vu = 'VU',
  /** Wallis and Futuna */
  Wf = 'WF',
  /** Samoa */
  Ws = 'WS',
  /** Yemen */
  Ye = 'YE',
  /** Mayotte */
  Yt = 'YT',
  /** South Africa */
  Za = 'ZA',
  /** Zambia */
  Zm = 'ZM',
  /** Zimbabwe */
  Zw = 'ZW'
}

/** An enumeration. */
export enum InstitutesInstituteCountryChoices {
  /** Andorra */
  Ad = 'AD',
  /** United Arab Emirates */
  Ae = 'AE',
  /** Afghanistan */
  Af = 'AF',
  /** Antigua and Barbuda */
  Ag = 'AG',
  /** Anguilla */
  Ai = 'AI',
  /** Albania */
  Al = 'AL',
  /** Armenia */
  Am = 'AM',
  /** Angola */
  Ao = 'AO',
  /** Antarctica */
  Aq = 'AQ',
  /** Argentina */
  Ar = 'AR',
  /** American Samoa */
  As = 'AS',
  /** Austria */
  At = 'AT',
  /** Australia */
  Au = 'AU',
  /** Aruba */
  Aw = 'AW',
  /** Åland Islands */
  Ax = 'AX',
  /** Azerbaijan */
  Az = 'AZ',
  /** Bosnia and Herzegovina */
  Ba = 'BA',
  /** Barbados */
  Bb = 'BB',
  /** Bangladesh */
  Bd = 'BD',
  /** Belgium */
  Be = 'BE',
  /** Burkina Faso */
  Bf = 'BF',
  /** Bulgaria */
  Bg = 'BG',
  /** Bahrain */
  Bh = 'BH',
  /** Burundi */
  Bi = 'BI',
  /** Benin */
  Bj = 'BJ',
  /** Saint Barthélemy */
  Bl = 'BL',
  /** Bermuda */
  Bm = 'BM',
  /** Brunei */
  Bn = 'BN',
  /** Bolivia */
  Bo = 'BO',
  /** Bonaire, Sint Eustatius and Saba */
  Bq = 'BQ',
  /** Brazil */
  Br = 'BR',
  /** Bahamas */
  Bs = 'BS',
  /** Bhutan */
  Bt = 'BT',
  /** Bouvet Island */
  Bv = 'BV',
  /** Botswana */
  Bw = 'BW',
  /** Belarus */
  By = 'BY',
  /** Belize */
  Bz = 'BZ',
  /** Canada */
  Ca = 'CA',
  /** Cocos (Keeling) Islands */
  Cc = 'CC',
  /** Congo (the Democratic Republic of the) */
  Cd = 'CD',
  /** Central African Republic */
  Cf = 'CF',
  /** Congo */
  Cg = 'CG',
  /** Switzerland */
  Ch = 'CH',
  /** Côte d'Ivoire */
  Ci = 'CI',
  /** Cook Islands */
  Ck = 'CK',
  /** Chile */
  Cl = 'CL',
  /** Cameroon */
  Cm = 'CM',
  /** China */
  Cn = 'CN',
  /** Colombia */
  Co = 'CO',
  /** Costa Rica */
  Cr = 'CR',
  /** Cuba */
  Cu = 'CU',
  /** Cabo Verde */
  Cv = 'CV',
  /** Curaçao */
  Cw = 'CW',
  /** Christmas Island */
  Cx = 'CX',
  /** Cyprus */
  Cy = 'CY',
  /** Czechia */
  Cz = 'CZ',
  /** Germany */
  De = 'DE',
  /** Djibouti */
  Dj = 'DJ',
  /** Denmark */
  Dk = 'DK',
  /** Dominica */
  Dm = 'DM',
  /** Dominican Republic */
  Do = 'DO',
  /** Algeria */
  Dz = 'DZ',
  /** Ecuador */
  Ec = 'EC',
  /** Estonia */
  Ee = 'EE',
  /** Egypt */
  Eg = 'EG',
  /** Western Sahara */
  Eh = 'EH',
  /** Eritrea */
  Er = 'ER',
  /** Spain */
  Es = 'ES',
  /** Ethiopia */
  Et = 'ET',
  /** Finland */
  Fi = 'FI',
  /** Fiji */
  Fj = 'FJ',
  /** Falkland Islands (Malvinas) */
  Fk = 'FK',
  /** Micronesia (Federated States of) */
  Fm = 'FM',
  /** Faroe Islands */
  Fo = 'FO',
  /** France */
  Fr = 'FR',
  /** Gabon */
  Ga = 'GA',
  /** United Kingdom */
  Gb = 'GB',
  /** Grenada */
  Gd = 'GD',
  /** Georgia */
  Ge = 'GE',
  /** French Guiana */
  Gf = 'GF',
  /** Guernsey */
  Gg = 'GG',
  /** Ghana */
  Gh = 'GH',
  /** Gibraltar */
  Gi = 'GI',
  /** Greenland */
  Gl = 'GL',
  /** Gambia */
  Gm = 'GM',
  /** Guinea */
  Gn = 'GN',
  /** Guadeloupe */
  Gp = 'GP',
  /** Equatorial Guinea */
  Gq = 'GQ',
  /** Greece */
  Gr = 'GR',
  /** South Georgia and the South Sandwich Islands */
  Gs = 'GS',
  /** Guatemala */
  Gt = 'GT',
  /** Guam */
  Gu = 'GU',
  /** Guinea-Bissau */
  Gw = 'GW',
  /** Guyana */
  Gy = 'GY',
  /** Hong Kong */
  Hk = 'HK',
  /** Heard Island and McDonald Islands */
  Hm = 'HM',
  /** Honduras */
  Hn = 'HN',
  /** Croatia */
  Hr = 'HR',
  /** Haiti */
  Ht = 'HT',
  /** Hungary */
  Hu = 'HU',
  /** Indonesia */
  Id = 'ID',
  /** Ireland */
  Ie = 'IE',
  /** Israel */
  Il = 'IL',
  /** Isle of Man */
  Im = 'IM',
  /** India */
  In = 'IN',
  /** British Indian Ocean Territory */
  Io = 'IO',
  /** Iraq */
  Iq = 'IQ',
  /** Iran */
  Ir = 'IR',
  /** Iceland */
  Is = 'IS',
  /** Italy */
  It = 'IT',
  /** Jersey */
  Je = 'JE',
  /** Jamaica */
  Jm = 'JM',
  /** Jordan */
  Jo = 'JO',
  /** Japan */
  Jp = 'JP',
  /** Kenya */
  Ke = 'KE',
  /** Kyrgyzstan */
  Kg = 'KG',
  /** Cambodia */
  Kh = 'KH',
  /** Kiribati */
  Ki = 'KI',
  /** Comoros */
  Km = 'KM',
  /** Saint Kitts and Nevis */
  Kn = 'KN',
  /** North Korea */
  Kp = 'KP',
  /** South Korea */
  Kr = 'KR',
  /** Kuwait */
  Kw = 'KW',
  /** Cayman Islands */
  Ky = 'KY',
  /** Kazakhstan */
  Kz = 'KZ',
  /** Laos */
  La = 'LA',
  /** Lebanon */
  Lb = 'LB',
  /** Saint Lucia */
  Lc = 'LC',
  /** Liechtenstein */
  Li = 'LI',
  /** Sri Lanka */
  Lk = 'LK',
  /** Liberia */
  Lr = 'LR',
  /** Lesotho */
  Ls = 'LS',
  /** Lithuania */
  Lt = 'LT',
  /** Luxembourg */
  Lu = 'LU',
  /** Latvia */
  Lv = 'LV',
  /** Libya */
  Ly = 'LY',
  /** Morocco */
  Ma = 'MA',
  /** Monaco */
  Mc = 'MC',
  /** Moldova */
  Md = 'MD',
  /** Montenegro */
  Me = 'ME',
  /** Saint Martin (French part) */
  Mf = 'MF',
  /** Madagascar */
  Mg = 'MG',
  /** Marshall Islands */
  Mh = 'MH',
  /** North Macedonia */
  Mk = 'MK',
  /** Mali */
  Ml = 'ML',
  /** Myanmar */
  Mm = 'MM',
  /** Mongolia */
  Mn = 'MN',
  /** Macao */
  Mo = 'MO',
  /** Northern Mariana Islands */
  Mp = 'MP',
  /** Martinique */
  Mq = 'MQ',
  /** Mauritania */
  Mr = 'MR',
  /** Montserrat */
  Ms = 'MS',
  /** Malta */
  Mt = 'MT',
  /** Mauritius */
  Mu = 'MU',
  /** Maldives */
  Mv = 'MV',
  /** Malawi */
  Mw = 'MW',
  /** Mexico */
  Mx = 'MX',
  /** Malaysia */
  My = 'MY',
  /** Mozambique */
  Mz = 'MZ',
  /** Namibia */
  Na = 'NA',
  /** New Caledonia */
  Nc = 'NC',
  /** Niger */
  Ne = 'NE',
  /** Norfolk Island */
  Nf = 'NF',
  /** Nigeria */
  Ng = 'NG',
  /** Nicaragua */
  Ni = 'NI',
  /** Netherlands */
  Nl = 'NL',
  /** Norway */
  No = 'NO',
  /** Nepal */
  Np = 'NP',
  /** Nauru */
  Nr = 'NR',
  /** Niue */
  Nu = 'NU',
  /** New Zealand */
  Nz = 'NZ',
  /** Oman */
  Om = 'OM',
  /** Panama */
  Pa = 'PA',
  /** Peru */
  Pe = 'PE',
  /** French Polynesia */
  Pf = 'PF',
  /** Papua New Guinea */
  Pg = 'PG',
  /** Philippines */
  Ph = 'PH',
  /** Pakistan */
  Pk = 'PK',
  /** Poland */
  Pl = 'PL',
  /** Saint Pierre and Miquelon */
  Pm = 'PM',
  /** Pitcairn */
  Pn = 'PN',
  /** Puerto Rico */
  Pr = 'PR',
  /** Palestine, State of */
  Ps = 'PS',
  /** Portugal */
  Pt = 'PT',
  /** Palau */
  Pw = 'PW',
  /** Paraguay */
  Py = 'PY',
  /** Qatar */
  Qa = 'QA',
  /** Réunion */
  Re = 'RE',
  /** Romania */
  Ro = 'RO',
  /** Serbia */
  Rs = 'RS',
  /** Russia */
  Ru = 'RU',
  /** Rwanda */
  Rw = 'RW',
  /** Saudi Arabia */
  Sa = 'SA',
  /** Solomon Islands */
  Sb = 'SB',
  /** Seychelles */
  Sc = 'SC',
  /** Sudan */
  Sd = 'SD',
  /** Sweden */
  Se = 'SE',
  /** Singapore */
  Sg = 'SG',
  /** Saint Helena, Ascension and Tristan da Cunha */
  Sh = 'SH',
  /** Slovenia */
  Si = 'SI',
  /** Svalbard and Jan Mayen */
  Sj = 'SJ',
  /** Slovakia */
  Sk = 'SK',
  /** Sierra Leone */
  Sl = 'SL',
  /** San Marino */
  Sm = 'SM',
  /** Senegal */
  Sn = 'SN',
  /** Somalia */
  So = 'SO',
  /** Suriname */
  Sr = 'SR',
  /** South Sudan */
  Ss = 'SS',
  /** Sao Tome and Principe */
  St = 'ST',
  /** El Salvador */
  Sv = 'SV',
  /** Sint Maarten (Dutch part) */
  Sx = 'SX',
  /** Syria */
  Sy = 'SY',
  /** Eswatini */
  Sz = 'SZ',
  /** Turks and Caicos Islands */
  Tc = 'TC',
  /** Chad */
  Td = 'TD',
  /** French Southern Territories */
  Tf = 'TF',
  /** Togo */
  Tg = 'TG',
  /** Thailand */
  Th = 'TH',
  /** Tajikistan */
  Tj = 'TJ',
  /** Tokelau */
  Tk = 'TK',
  /** Timor-Leste */
  Tl = 'TL',
  /** Turkmenistan */
  Tm = 'TM',
  /** Tunisia */
  Tn = 'TN',
  /** Tonga */
  To = 'TO',
  /** Turkey */
  Tr = 'TR',
  /** Trinidad and Tobago */
  Tt = 'TT',
  /** Tuvalu */
  Tv = 'TV',
  /** Taiwan */
  Tw = 'TW',
  /** Tanzania */
  Tz = 'TZ',
  /** Ukraine */
  Ua = 'UA',
  /** Uganda */
  Ug = 'UG',
  /** United States Minor Outlying Islands */
  Um = 'UM',
  /** United States of America */
  Us = 'US',
  /** Uruguay */
  Uy = 'UY',
  /** Uzbekistan */
  Uz = 'UZ',
  /** Holy See */
  Va = 'VA',
  /** Saint Vincent and the Grenadines */
  Vc = 'VC',
  /** Venezuela */
  Ve = 'VE',
  /** Virgin Islands (British) */
  Vg = 'VG',
  /** Virgin Islands (U.S.) */
  Vi = 'VI',
  /** Vietnam */
  Vn = 'VN',
  /** Vanuatu */
  Vu = 'VU',
  /** Wallis and Futuna */
  Wf = 'WF',
  /** Samoa */
  Ws = 'WS',
  /** Yemen */
  Ye = 'YE',
  /** Mayotte */
  Yt = 'YT',
  /** South Africa */
  Za = 'ZA',
  /** Zambia */
  Zm = 'ZM',
  /** Zimbabwe */
  Zw = 'ZW'
}

/** An enumeration. */
export enum InstitutesInstituteRequestCountryChoices {
  /** Andorra */
  Ad = 'AD',
  /** United Arab Emirates */
  Ae = 'AE',
  /** Afghanistan */
  Af = 'AF',
  /** Antigua and Barbuda */
  Ag = 'AG',
  /** Anguilla */
  Ai = 'AI',
  /** Albania */
  Al = 'AL',
  /** Armenia */
  Am = 'AM',
  /** Angola */
  Ao = 'AO',
  /** Antarctica */
  Aq = 'AQ',
  /** Argentina */
  Ar = 'AR',
  /** American Samoa */
  As = 'AS',
  /** Austria */
  At = 'AT',
  /** Australia */
  Au = 'AU',
  /** Aruba */
  Aw = 'AW',
  /** Åland Islands */
  Ax = 'AX',
  /** Azerbaijan */
  Az = 'AZ',
  /** Bosnia and Herzegovina */
  Ba = 'BA',
  /** Barbados */
  Bb = 'BB',
  /** Bangladesh */
  Bd = 'BD',
  /** Belgium */
  Be = 'BE',
  /** Burkina Faso */
  Bf = 'BF',
  /** Bulgaria */
  Bg = 'BG',
  /** Bahrain */
  Bh = 'BH',
  /** Burundi */
  Bi = 'BI',
  /** Benin */
  Bj = 'BJ',
  /** Saint Barthélemy */
  Bl = 'BL',
  /** Bermuda */
  Bm = 'BM',
  /** Brunei */
  Bn = 'BN',
  /** Bolivia */
  Bo = 'BO',
  /** Bonaire, Sint Eustatius and Saba */
  Bq = 'BQ',
  /** Brazil */
  Br = 'BR',
  /** Bahamas */
  Bs = 'BS',
  /** Bhutan */
  Bt = 'BT',
  /** Bouvet Island */
  Bv = 'BV',
  /** Botswana */
  Bw = 'BW',
  /** Belarus */
  By = 'BY',
  /** Belize */
  Bz = 'BZ',
  /** Canada */
  Ca = 'CA',
  /** Cocos (Keeling) Islands */
  Cc = 'CC',
  /** Congo (the Democratic Republic of the) */
  Cd = 'CD',
  /** Central African Republic */
  Cf = 'CF',
  /** Congo */
  Cg = 'CG',
  /** Switzerland */
  Ch = 'CH',
  /** Côte d'Ivoire */
  Ci = 'CI',
  /** Cook Islands */
  Ck = 'CK',
  /** Chile */
  Cl = 'CL',
  /** Cameroon */
  Cm = 'CM',
  /** China */
  Cn = 'CN',
  /** Colombia */
  Co = 'CO',
  /** Costa Rica */
  Cr = 'CR',
  /** Cuba */
  Cu = 'CU',
  /** Cabo Verde */
  Cv = 'CV',
  /** Curaçao */
  Cw = 'CW',
  /** Christmas Island */
  Cx = 'CX',
  /** Cyprus */
  Cy = 'CY',
  /** Czechia */
  Cz = 'CZ',
  /** Germany */
  De = 'DE',
  /** Djibouti */
  Dj = 'DJ',
  /** Denmark */
  Dk = 'DK',
  /** Dominica */
  Dm = 'DM',
  /** Dominican Republic */
  Do = 'DO',
  /** Algeria */
  Dz = 'DZ',
  /** Ecuador */
  Ec = 'EC',
  /** Estonia */
  Ee = 'EE',
  /** Egypt */
  Eg = 'EG',
  /** Western Sahara */
  Eh = 'EH',
  /** Eritrea */
  Er = 'ER',
  /** Spain */
  Es = 'ES',
  /** Ethiopia */
  Et = 'ET',
  /** Finland */
  Fi = 'FI',
  /** Fiji */
  Fj = 'FJ',
  /** Falkland Islands (Malvinas) */
  Fk = 'FK',
  /** Micronesia (Federated States of) */
  Fm = 'FM',
  /** Faroe Islands */
  Fo = 'FO',
  /** France */
  Fr = 'FR',
  /** Gabon */
  Ga = 'GA',
  /** United Kingdom */
  Gb = 'GB',
  /** Grenada */
  Gd = 'GD',
  /** Georgia */
  Ge = 'GE',
  /** French Guiana */
  Gf = 'GF',
  /** Guernsey */
  Gg = 'GG',
  /** Ghana */
  Gh = 'GH',
  /** Gibraltar */
  Gi = 'GI',
  /** Greenland */
  Gl = 'GL',
  /** Gambia */
  Gm = 'GM',
  /** Guinea */
  Gn = 'GN',
  /** Guadeloupe */
  Gp = 'GP',
  /** Equatorial Guinea */
  Gq = 'GQ',
  /** Greece */
  Gr = 'GR',
  /** South Georgia and the South Sandwich Islands */
  Gs = 'GS',
  /** Guatemala */
  Gt = 'GT',
  /** Guam */
  Gu = 'GU',
  /** Guinea-Bissau */
  Gw = 'GW',
  /** Guyana */
  Gy = 'GY',
  /** Hong Kong */
  Hk = 'HK',
  /** Heard Island and McDonald Islands */
  Hm = 'HM',
  /** Honduras */
  Hn = 'HN',
  /** Croatia */
  Hr = 'HR',
  /** Haiti */
  Ht = 'HT',
  /** Hungary */
  Hu = 'HU',
  /** Indonesia */
  Id = 'ID',
  /** Ireland */
  Ie = 'IE',
  /** Israel */
  Il = 'IL',
  /** Isle of Man */
  Im = 'IM',
  /** India */
  In = 'IN',
  /** British Indian Ocean Territory */
  Io = 'IO',
  /** Iraq */
  Iq = 'IQ',
  /** Iran */
  Ir = 'IR',
  /** Iceland */
  Is = 'IS',
  /** Italy */
  It = 'IT',
  /** Jersey */
  Je = 'JE',
  /** Jamaica */
  Jm = 'JM',
  /** Jordan */
  Jo = 'JO',
  /** Japan */
  Jp = 'JP',
  /** Kenya */
  Ke = 'KE',
  /** Kyrgyzstan */
  Kg = 'KG',
  /** Cambodia */
  Kh = 'KH',
  /** Kiribati */
  Ki = 'KI',
  /** Comoros */
  Km = 'KM',
  /** Saint Kitts and Nevis */
  Kn = 'KN',
  /** North Korea */
  Kp = 'KP',
  /** South Korea */
  Kr = 'KR',
  /** Kuwait */
  Kw = 'KW',
  /** Cayman Islands */
  Ky = 'KY',
  /** Kazakhstan */
  Kz = 'KZ',
  /** Laos */
  La = 'LA',
  /** Lebanon */
  Lb = 'LB',
  /** Saint Lucia */
  Lc = 'LC',
  /** Liechtenstein */
  Li = 'LI',
  /** Sri Lanka */
  Lk = 'LK',
  /** Liberia */
  Lr = 'LR',
  /** Lesotho */
  Ls = 'LS',
  /** Lithuania */
  Lt = 'LT',
  /** Luxembourg */
  Lu = 'LU',
  /** Latvia */
  Lv = 'LV',
  /** Libya */
  Ly = 'LY',
  /** Morocco */
  Ma = 'MA',
  /** Monaco */
  Mc = 'MC',
  /** Moldova */
  Md = 'MD',
  /** Montenegro */
  Me = 'ME',
  /** Saint Martin (French part) */
  Mf = 'MF',
  /** Madagascar */
  Mg = 'MG',
  /** Marshall Islands */
  Mh = 'MH',
  /** North Macedonia */
  Mk = 'MK',
  /** Mali */
  Ml = 'ML',
  /** Myanmar */
  Mm = 'MM',
  /** Mongolia */
  Mn = 'MN',
  /** Macao */
  Mo = 'MO',
  /** Northern Mariana Islands */
  Mp = 'MP',
  /** Martinique */
  Mq = 'MQ',
  /** Mauritania */
  Mr = 'MR',
  /** Montserrat */
  Ms = 'MS',
  /** Malta */
  Mt = 'MT',
  /** Mauritius */
  Mu = 'MU',
  /** Maldives */
  Mv = 'MV',
  /** Malawi */
  Mw = 'MW',
  /** Mexico */
  Mx = 'MX',
  /** Malaysia */
  My = 'MY',
  /** Mozambique */
  Mz = 'MZ',
  /** Namibia */
  Na = 'NA',
  /** New Caledonia */
  Nc = 'NC',
  /** Niger */
  Ne = 'NE',
  /** Norfolk Island */
  Nf = 'NF',
  /** Nigeria */
  Ng = 'NG',
  /** Nicaragua */
  Ni = 'NI',
  /** Netherlands */
  Nl = 'NL',
  /** Norway */
  No = 'NO',
  /** Nepal */
  Np = 'NP',
  /** Nauru */
  Nr = 'NR',
  /** Niue */
  Nu = 'NU',
  /** New Zealand */
  Nz = 'NZ',
  /** Oman */
  Om = 'OM',
  /** Panama */
  Pa = 'PA',
  /** Peru */
  Pe = 'PE',
  /** French Polynesia */
  Pf = 'PF',
  /** Papua New Guinea */
  Pg = 'PG',
  /** Philippines */
  Ph = 'PH',
  /** Pakistan */
  Pk = 'PK',
  /** Poland */
  Pl = 'PL',
  /** Saint Pierre and Miquelon */
  Pm = 'PM',
  /** Pitcairn */
  Pn = 'PN',
  /** Puerto Rico */
  Pr = 'PR',
  /** Palestine, State of */
  Ps = 'PS',
  /** Portugal */
  Pt = 'PT',
  /** Palau */
  Pw = 'PW',
  /** Paraguay */
  Py = 'PY',
  /** Qatar */
  Qa = 'QA',
  /** Réunion */
  Re = 'RE',
  /** Romania */
  Ro = 'RO',
  /** Serbia */
  Rs = 'RS',
  /** Russia */
  Ru = 'RU',
  /** Rwanda */
  Rw = 'RW',
  /** Saudi Arabia */
  Sa = 'SA',
  /** Solomon Islands */
  Sb = 'SB',
  /** Seychelles */
  Sc = 'SC',
  /** Sudan */
  Sd = 'SD',
  /** Sweden */
  Se = 'SE',
  /** Singapore */
  Sg = 'SG',
  /** Saint Helena, Ascension and Tristan da Cunha */
  Sh = 'SH',
  /** Slovenia */
  Si = 'SI',
  /** Svalbard and Jan Mayen */
  Sj = 'SJ',
  /** Slovakia */
  Sk = 'SK',
  /** Sierra Leone */
  Sl = 'SL',
  /** San Marino */
  Sm = 'SM',
  /** Senegal */
  Sn = 'SN',
  /** Somalia */
  So = 'SO',
  /** Suriname */
  Sr = 'SR',
  /** South Sudan */
  Ss = 'SS',
  /** Sao Tome and Principe */
  St = 'ST',
  /** El Salvador */
  Sv = 'SV',
  /** Sint Maarten (Dutch part) */
  Sx = 'SX',
  /** Syria */
  Sy = 'SY',
  /** Eswatini */
  Sz = 'SZ',
  /** Turks and Caicos Islands */
  Tc = 'TC',
  /** Chad */
  Td = 'TD',
  /** French Southern Territories */
  Tf = 'TF',
  /** Togo */
  Tg = 'TG',
  /** Thailand */
  Th = 'TH',
  /** Tajikistan */
  Tj = 'TJ',
  /** Tokelau */
  Tk = 'TK',
  /** Timor-Leste */
  Tl = 'TL',
  /** Turkmenistan */
  Tm = 'TM',
  /** Tunisia */
  Tn = 'TN',
  /** Tonga */
  To = 'TO',
  /** Turkey */
  Tr = 'TR',
  /** Trinidad and Tobago */
  Tt = 'TT',
  /** Tuvalu */
  Tv = 'TV',
  /** Taiwan */
  Tw = 'TW',
  /** Tanzania */
  Tz = 'TZ',
  /** Ukraine */
  Ua = 'UA',
  /** Uganda */
  Ug = 'UG',
  /** United States Minor Outlying Islands */
  Um = 'UM',
  /** United States of America */
  Us = 'US',
  /** Uruguay */
  Uy = 'UY',
  /** Uzbekistan */
  Uz = 'UZ',
  /** Holy See */
  Va = 'VA',
  /** Saint Vincent and the Grenadines */
  Vc = 'VC',
  /** Venezuela */
  Ve = 'VE',
  /** Virgin Islands (British) */
  Vg = 'VG',
  /** Virgin Islands (U.S.) */
  Vi = 'VI',
  /** Vietnam */
  Vn = 'VN',
  /** Vanuatu */
  Vu = 'VU',
  /** Wallis and Futuna */
  Wf = 'WF',
  /** Samoa */
  Ws = 'WS',
  /** Yemen */
  Ye = 'YE',
  /** Mayotte */
  Yt = 'YT',
  /** South Africa */
  Za = 'ZA',
  /** Zambia */
  Zm = 'ZM',
  /** Zimbabwe */
  Zw = 'ZW'
}

/** An enumeration. */
export enum InstitutesInstituteTypeCountryChoices {
  /** Andorra */
  Ad = 'AD',
  /** United Arab Emirates */
  Ae = 'AE',
  /** Afghanistan */
  Af = 'AF',
  /** Antigua and Barbuda */
  Ag = 'AG',
  /** Anguilla */
  Ai = 'AI',
  /** Albania */
  Al = 'AL',
  /** Armenia */
  Am = 'AM',
  /** Angola */
  Ao = 'AO',
  /** Antarctica */
  Aq = 'AQ',
  /** Argentina */
  Ar = 'AR',
  /** American Samoa */
  As = 'AS',
  /** Austria */
  At = 'AT',
  /** Australia */
  Au = 'AU',
  /** Aruba */
  Aw = 'AW',
  /** Åland Islands */
  Ax = 'AX',
  /** Azerbaijan */
  Az = 'AZ',
  /** Bosnia and Herzegovina */
  Ba = 'BA',
  /** Barbados */
  Bb = 'BB',
  /** Bangladesh */
  Bd = 'BD',
  /** Belgium */
  Be = 'BE',
  /** Burkina Faso */
  Bf = 'BF',
  /** Bulgaria */
  Bg = 'BG',
  /** Bahrain */
  Bh = 'BH',
  /** Burundi */
  Bi = 'BI',
  /** Benin */
  Bj = 'BJ',
  /** Saint Barthélemy */
  Bl = 'BL',
  /** Bermuda */
  Bm = 'BM',
  /** Brunei */
  Bn = 'BN',
  /** Bolivia */
  Bo = 'BO',
  /** Bonaire, Sint Eustatius and Saba */
  Bq = 'BQ',
  /** Brazil */
  Br = 'BR',
  /** Bahamas */
  Bs = 'BS',
  /** Bhutan */
  Bt = 'BT',
  /** Bouvet Island */
  Bv = 'BV',
  /** Botswana */
  Bw = 'BW',
  /** Belarus */
  By = 'BY',
  /** Belize */
  Bz = 'BZ',
  /** Canada */
  Ca = 'CA',
  /** Cocos (Keeling) Islands */
  Cc = 'CC',
  /** Congo (the Democratic Republic of the) */
  Cd = 'CD',
  /** Central African Republic */
  Cf = 'CF',
  /** Congo */
  Cg = 'CG',
  /** Switzerland */
  Ch = 'CH',
  /** Côte d'Ivoire */
  Ci = 'CI',
  /** Cook Islands */
  Ck = 'CK',
  /** Chile */
  Cl = 'CL',
  /** Cameroon */
  Cm = 'CM',
  /** China */
  Cn = 'CN',
  /** Colombia */
  Co = 'CO',
  /** Costa Rica */
  Cr = 'CR',
  /** Cuba */
  Cu = 'CU',
  /** Cabo Verde */
  Cv = 'CV',
  /** Curaçao */
  Cw = 'CW',
  /** Christmas Island */
  Cx = 'CX',
  /** Cyprus */
  Cy = 'CY',
  /** Czechia */
  Cz = 'CZ',
  /** Germany */
  De = 'DE',
  /** Djibouti */
  Dj = 'DJ',
  /** Denmark */
  Dk = 'DK',
  /** Dominica */
  Dm = 'DM',
  /** Dominican Republic */
  Do = 'DO',
  /** Algeria */
  Dz = 'DZ',
  /** Ecuador */
  Ec = 'EC',
  /** Estonia */
  Ee = 'EE',
  /** Egypt */
  Eg = 'EG',
  /** Western Sahara */
  Eh = 'EH',
  /** Eritrea */
  Er = 'ER',
  /** Spain */
  Es = 'ES',
  /** Ethiopia */
  Et = 'ET',
  /** Finland */
  Fi = 'FI',
  /** Fiji */
  Fj = 'FJ',
  /** Falkland Islands (Malvinas) */
  Fk = 'FK',
  /** Micronesia (Federated States of) */
  Fm = 'FM',
  /** Faroe Islands */
  Fo = 'FO',
  /** France */
  Fr = 'FR',
  /** Gabon */
  Ga = 'GA',
  /** United Kingdom */
  Gb = 'GB',
  /** Grenada */
  Gd = 'GD',
  /** Georgia */
  Ge = 'GE',
  /** French Guiana */
  Gf = 'GF',
  /** Guernsey */
  Gg = 'GG',
  /** Ghana */
  Gh = 'GH',
  /** Gibraltar */
  Gi = 'GI',
  /** Greenland */
  Gl = 'GL',
  /** Gambia */
  Gm = 'GM',
  /** Guinea */
  Gn = 'GN',
  /** Guadeloupe */
  Gp = 'GP',
  /** Equatorial Guinea */
  Gq = 'GQ',
  /** Greece */
  Gr = 'GR',
  /** South Georgia and the South Sandwich Islands */
  Gs = 'GS',
  /** Guatemala */
  Gt = 'GT',
  /** Guam */
  Gu = 'GU',
  /** Guinea-Bissau */
  Gw = 'GW',
  /** Guyana */
  Gy = 'GY',
  /** Hong Kong */
  Hk = 'HK',
  /** Heard Island and McDonald Islands */
  Hm = 'HM',
  /** Honduras */
  Hn = 'HN',
  /** Croatia */
  Hr = 'HR',
  /** Haiti */
  Ht = 'HT',
  /** Hungary */
  Hu = 'HU',
  /** Indonesia */
  Id = 'ID',
  /** Ireland */
  Ie = 'IE',
  /** Israel */
  Il = 'IL',
  /** Isle of Man */
  Im = 'IM',
  /** India */
  In = 'IN',
  /** British Indian Ocean Territory */
  Io = 'IO',
  /** Iraq */
  Iq = 'IQ',
  /** Iran */
  Ir = 'IR',
  /** Iceland */
  Is = 'IS',
  /** Italy */
  It = 'IT',
  /** Jersey */
  Je = 'JE',
  /** Jamaica */
  Jm = 'JM',
  /** Jordan */
  Jo = 'JO',
  /** Japan */
  Jp = 'JP',
  /** Kenya */
  Ke = 'KE',
  /** Kyrgyzstan */
  Kg = 'KG',
  /** Cambodia */
  Kh = 'KH',
  /** Kiribati */
  Ki = 'KI',
  /** Comoros */
  Km = 'KM',
  /** Saint Kitts and Nevis */
  Kn = 'KN',
  /** North Korea */
  Kp = 'KP',
  /** South Korea */
  Kr = 'KR',
  /** Kuwait */
  Kw = 'KW',
  /** Cayman Islands */
  Ky = 'KY',
  /** Kazakhstan */
  Kz = 'KZ',
  /** Laos */
  La = 'LA',
  /** Lebanon */
  Lb = 'LB',
  /** Saint Lucia */
  Lc = 'LC',
  /** Liechtenstein */
  Li = 'LI',
  /** Sri Lanka */
  Lk = 'LK',
  /** Liberia */
  Lr = 'LR',
  /** Lesotho */
  Ls = 'LS',
  /** Lithuania */
  Lt = 'LT',
  /** Luxembourg */
  Lu = 'LU',
  /** Latvia */
  Lv = 'LV',
  /** Libya */
  Ly = 'LY',
  /** Morocco */
  Ma = 'MA',
  /** Monaco */
  Mc = 'MC',
  /** Moldova */
  Md = 'MD',
  /** Montenegro */
  Me = 'ME',
  /** Saint Martin (French part) */
  Mf = 'MF',
  /** Madagascar */
  Mg = 'MG',
  /** Marshall Islands */
  Mh = 'MH',
  /** North Macedonia */
  Mk = 'MK',
  /** Mali */
  Ml = 'ML',
  /** Myanmar */
  Mm = 'MM',
  /** Mongolia */
  Mn = 'MN',
  /** Macao */
  Mo = 'MO',
  /** Northern Mariana Islands */
  Mp = 'MP',
  /** Martinique */
  Mq = 'MQ',
  /** Mauritania */
  Mr = 'MR',
  /** Montserrat */
  Ms = 'MS',
  /** Malta */
  Mt = 'MT',
  /** Mauritius */
  Mu = 'MU',
  /** Maldives */
  Mv = 'MV',
  /** Malawi */
  Mw = 'MW',
  /** Mexico */
  Mx = 'MX',
  /** Malaysia */
  My = 'MY',
  /** Mozambique */
  Mz = 'MZ',
  /** Namibia */
  Na = 'NA',
  /** New Caledonia */
  Nc = 'NC',
  /** Niger */
  Ne = 'NE',
  /** Norfolk Island */
  Nf = 'NF',
  /** Nigeria */
  Ng = 'NG',
  /** Nicaragua */
  Ni = 'NI',
  /** Netherlands */
  Nl = 'NL',
  /** Norway */
  No = 'NO',
  /** Nepal */
  Np = 'NP',
  /** Nauru */
  Nr = 'NR',
  /** Niue */
  Nu = 'NU',
  /** New Zealand */
  Nz = 'NZ',
  /** Oman */
  Om = 'OM',
  /** Panama */
  Pa = 'PA',
  /** Peru */
  Pe = 'PE',
  /** French Polynesia */
  Pf = 'PF',
  /** Papua New Guinea */
  Pg = 'PG',
  /** Philippines */
  Ph = 'PH',
  /** Pakistan */
  Pk = 'PK',
  /** Poland */
  Pl = 'PL',
  /** Saint Pierre and Miquelon */
  Pm = 'PM',
  /** Pitcairn */
  Pn = 'PN',
  /** Puerto Rico */
  Pr = 'PR',
  /** Palestine, State of */
  Ps = 'PS',
  /** Portugal */
  Pt = 'PT',
  /** Palau */
  Pw = 'PW',
  /** Paraguay */
  Py = 'PY',
  /** Qatar */
  Qa = 'QA',
  /** Réunion */
  Re = 'RE',
  /** Romania */
  Ro = 'RO',
  /** Serbia */
  Rs = 'RS',
  /** Russia */
  Ru = 'RU',
  /** Rwanda */
  Rw = 'RW',
  /** Saudi Arabia */
  Sa = 'SA',
  /** Solomon Islands */
  Sb = 'SB',
  /** Seychelles */
  Sc = 'SC',
  /** Sudan */
  Sd = 'SD',
  /** Sweden */
  Se = 'SE',
  /** Singapore */
  Sg = 'SG',
  /** Saint Helena, Ascension and Tristan da Cunha */
  Sh = 'SH',
  /** Slovenia */
  Si = 'SI',
  /** Svalbard and Jan Mayen */
  Sj = 'SJ',
  /** Slovakia */
  Sk = 'SK',
  /** Sierra Leone */
  Sl = 'SL',
  /** San Marino */
  Sm = 'SM',
  /** Senegal */
  Sn = 'SN',
  /** Somalia */
  So = 'SO',
  /** Suriname */
  Sr = 'SR',
  /** South Sudan */
  Ss = 'SS',
  /** Sao Tome and Principe */
  St = 'ST',
  /** El Salvador */
  Sv = 'SV',
  /** Sint Maarten (Dutch part) */
  Sx = 'SX',
  /** Syria */
  Sy = 'SY',
  /** Eswatini */
  Sz = 'SZ',
  /** Turks and Caicos Islands */
  Tc = 'TC',
  /** Chad */
  Td = 'TD',
  /** French Southern Territories */
  Tf = 'TF',
  /** Togo */
  Tg = 'TG',
  /** Thailand */
  Th = 'TH',
  /** Tajikistan */
  Tj = 'TJ',
  /** Tokelau */
  Tk = 'TK',
  /** Timor-Leste */
  Tl = 'TL',
  /** Turkmenistan */
  Tm = 'TM',
  /** Tunisia */
  Tn = 'TN',
  /** Tonga */
  To = 'TO',
  /** Turkey */
  Tr = 'TR',
  /** Trinidad and Tobago */
  Tt = 'TT',
  /** Tuvalu */
  Tv = 'TV',
  /** Taiwan */
  Tw = 'TW',
  /** Tanzania */
  Tz = 'TZ',
  /** Ukraine */
  Ua = 'UA',
  /** Uganda */
  Ug = 'UG',
  /** United States Minor Outlying Islands */
  Um = 'UM',
  /** United States of America */
  Us = 'US',
  /** Uruguay */
  Uy = 'UY',
  /** Uzbekistan */
  Uz = 'UZ',
  /** Holy See */
  Va = 'VA',
  /** Saint Vincent and the Grenadines */
  Vc = 'VC',
  /** Venezuela */
  Ve = 'VE',
  /** Virgin Islands (British) */
  Vg = 'VG',
  /** Virgin Islands (U.S.) */
  Vi = 'VI',
  /** Vietnam */
  Vn = 'VN',
  /** Vanuatu */
  Vu = 'VU',
  /** Wallis and Futuna */
  Wf = 'WF',
  /** Samoa */
  Ws = 'WS',
  /** Yemen */
  Ye = 'YE',
  /** Mayotte */
  Yt = 'YT',
  /** South Africa */
  Za = 'ZA',
  /** Zambia */
  Zm = 'ZM',
  /** Zimbabwe */
  Zw = 'ZW'
}

export type JoinEventInput = {
  clientMutationId?: InputMaybe<Scalars['String']>
  eventId: Scalars['ID']
}

export type JoinEventPayload = {
  __typename?: 'JoinEventPayload'
  attendee?: Maybe<EventAttendeeNode>
  clientMutationId?: Maybe<Scalars['String']>
}

export type KpIsNode = {
  __typename?: 'KPIsNode'
  albumsPerGroupPerYear?: Maybe<Array<Maybe<YearlyAverage>>>
  averageStudentsPerGroup?: Maybe<Scalars['Float']>
  groupTimeToActive?: Maybe<Scalars['Float']>
  photosPerGroupPerYear?: Maybe<Array<Maybe<YearlyAverage>>>
  profileQuestionsPerYear?: Maybe<Array<Maybe<YearlyAverage>>>
  quotesPerGroupPerYear?: Maybe<Array<Maybe<YearlyAverage>>>
  rankingQuestionsPerGroupPerYear?: Maybe<Array<Maybe<YearlyAverage>>>
}

/** An enumeration. */
export enum Language {
  English = 'ENGLISH',
  German = 'GERMAN',
  Turkish = 'TURKISH'
}

export type LeaderboardUserNode = Node & {
  __typename?: 'LeaderboardUserNode'
  created: Scalars['DateTime']
  /** The ID of the object */
  id: Scalars['ID']
  modified: Scalars['DateTime']
  points: Scalars['Int']
  position?: Maybe<Scalars['Int']>
  user: UserNode
}

export type LeaderboardUserNodeConnection = {
  __typename?: 'LeaderboardUserNodeConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<LeaderboardUserNodeEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
}

/** A Relay edge containing a `LeaderboardUserNode` and its cursor. */
export type LeaderboardUserNodeEdge = {
  __typename?: 'LeaderboardUserNodeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node?: Maybe<LeaderboardUserNode>
}

export type LeaveEventInput = {
  clientMutationId?: InputMaybe<Scalars['String']>
  eventId: Scalars['ID']
}

export type LeaveEventPayload = {
  __typename?: 'LeaveEventPayload'
  clientMutationId?: Maybe<Scalars['String']>
  success?: Maybe<Scalars['Boolean']>
}

export type LeaveFromTeamInput = {
  clientMutationId?: InputMaybe<Scalars['String']>
  teamId: Scalars['ID']
}

export type LeaveFromTeamPayload = {
  __typename?: 'LeaveFromTeamPayload'
  clientMutationId?: Maybe<Scalars['String']>
  success?: Maybe<Scalars['Boolean']>
  userTeam?: Maybe<UserTeamNode>
}

export type LikeCollagesPhotoInput = {
  clientMutationId?: InputMaybe<Scalars['String']>
  id: Scalars['ID']
}

export type LikeCollagesPhotoPayload = {
  __typename?: 'LikeCollagesPhotoPayload'
  clientMutationId?: Maybe<Scalars['String']>
  collagesPhoto?: Maybe<CollagesPhotoNode>
}

export type LikeCommentInput = {
  clientMutationId?: InputMaybe<Scalars['String']>
  id: Scalars['ID']
}

export type LikeCommentPayload = {
  __typename?: 'LikeCommentPayload'
  clientMutationId?: Maybe<Scalars['String']>
  post?: Maybe<PostNode>
}

export type LikeNode = Node & {
  __typename?: 'LikeNode'
  created: Scalars['DateTime']
  /** The ID of the object */
  id: Scalars['ID']
  modified: Scalars['DateTime']
  post?: Maybe<PostNode>
  userGroup?: Maybe<UserGroupNode>
}

export type LikeNodeConnection = {
  __typename?: 'LikeNodeConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<LikeNodeEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
}

/** A Relay edge containing a `LikeNode` and its cursor. */
export type LikeNodeEdge = {
  __typename?: 'LikeNodeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node?: Maybe<LikeNode>
}

export type LikePostInput = {
  clientMutationId?: InputMaybe<Scalars['String']>
  id: Scalars['ID']
}

export type LikePostPayload = {
  __typename?: 'LikePostPayload'
  clientMutationId?: Maybe<Scalars['String']>
  post?: Maybe<PostNode>
}

export type LikeQuotesInstanceInput = {
  clientMutationId?: InputMaybe<Scalars['String']>
  id: Scalars['ID']
}

export type LikeQuotesInstancePayload = {
  __typename?: 'LikeQuotesInstancePayload'
  clientMutationId?: Maybe<Scalars['String']>
  quotesInstance?: Maybe<QuotesInstanceNode>
}

export type MarketProfileNode = Node & {
  __typename?: 'MarketProfileNode'
  created: Scalars['DateTime']
  groups?: Maybe<Array<Maybe<GroupNode>>>
  /** The ID of the object */
  id: Scalars['ID']
  institutes?: Maybe<Array<Maybe<InstituteNode>>>
  modified: Scalars['DateTime']
  name: Scalars['String']
}

export type MarketProfileNodeConnection = {
  __typename?: 'MarketProfileNodeConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<MarketProfileNodeEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
}

/** A Relay edge containing a `MarketProfileNode` and its cursor. */
export type MarketProfileNodeEdge = {
  __typename?: 'MarketProfileNodeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node?: Maybe<MarketProfileNode>
}

export type MatchedAssetNode = Node & {
  __typename?: 'MatchedAssetNode'
  assetId: Scalars['Int']
  campaign?: Maybe<CampaignNode>
  channelData?: Maybe<Array<Maybe<BannerAd>>>
  channelType: AdvertisementsAssetChannelTypeChoices
  endDate?: Maybe<Scalars['DateTime']>
  /** The ID of the object */
  id: Scalars['ID']
  isActive: Scalars['Boolean']
  name: Scalars['String']
  startDate?: Maybe<Scalars['DateTime']>
  targetingConfig: Scalars['JSONString']
  targetingFilters?: Maybe<Array<Maybe<TargetingFilterType>>>
  visibility?: Maybe<AssetVisibilityType>
}

export type ModuleInstanceNode = Node & {
  __typename?: 'ModuleInstanceNode'
  collagesAlbums?: Maybe<CollagesAlbumNodeConnection>
  collagesSetup?: Maybe<CollagesSetupNode>
  created: Scalars['DateTime']
  customLists?: Maybe<CustomListNodeConnection>
  customPagesInstances?: Maybe<CustomPagesInstanceNodeConnection>
  customPagesSetup?: Maybe<CustomPagesSetupNode>
  dueDate?: Maybe<Scalars['DateTime']>
  group: GroupNode
  /** The ID of the object */
  id: Scalars['ID']
  isActive: Scalars['Boolean']
  modified: Scalars['DateTime']
  module: ModuleNode
  prewordInstance?: Maybe<PrewordInstanceNode>
  profilePageComments?: Maybe<ProfilePageCommentNodeConnection>
  profilePagePhotoCategories?: Maybe<ProfilePagePhotoCategoryNodeConnection>
  profilePageQuestions?: Maybe<ProfilePageQuestionNodeConnection>
  profilePageSetup?: Maybe<ProfilePageSetupNode>
  quotesInstances?: Maybe<QuotesInstanceNodeConnection>
  quotesSetup?: Maybe<QuotesSetupNode>
  rankingsQuestions?: Maybe<RankingsQuestionNodeConnection>
  rankingsSetup?: Maybe<RankingsSetupNode>
  reportsInstances?: Maybe<ReportsInstanceNodeConnection>
  reportsSetup?: Maybe<ReportsSetupNode>
}

export type ModuleInstanceNodeCollagesAlbumsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
}

export type ModuleInstanceNodeCustomListsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
}

export type ModuleInstanceNodeCustomPagesInstancesArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
}

export type ModuleInstanceNodeProfilePageCommentsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  comentatorUserGroup?: InputMaybe<Scalars['ID']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  moduleInstance?: InputMaybe<Scalars['ID']>
  offset?: InputMaybe<Scalars['Int']>
  profileUserGroup?: InputMaybe<Scalars['ID']>
}

export type ModuleInstanceNodeProfilePagePhotoCategoriesArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
}

export type ModuleInstanceNodeProfilePageQuestionsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
}

export type ModuleInstanceNodeQuotesInstancesArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
}

export type ModuleInstanceNodeRankingsQuestionsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
}

export type ModuleInstanceNodeReportsInstancesArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
}

export type ModuleInstanceNodeConnection = {
  __typename?: 'ModuleInstanceNodeConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ModuleInstanceNodeEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
}

/** A Relay edge containing a `ModuleInstanceNode` and its cursor. */
export type ModuleInstanceNodeEdge = {
  __typename?: 'ModuleInstanceNodeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node?: Maybe<ModuleInstanceNode>
}

export type ModuleNode = Node & {
  __typename?: 'ModuleNode'
  created: Scalars['DateTime']
  description?: Maybe<Scalars['String']>
  features?: Maybe<Array<Maybe<Scalars['String']>>>
  /** The ID of the object */
  id: Scalars['ID']
  image?: Maybe<Scalars['String']>
  isActive: Scalars['Boolean']
  modified: Scalars['DateTime']
  name?: Maybe<Scalars['String']>
  type?: Maybe<ModuleType>
}

export type ModuleNodeConnection = {
  __typename?: 'ModuleNodeConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ModuleNodeEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
}

/** A Relay edge containing a `ModuleNode` and its cursor. */
export type ModuleNodeEdge = {
  __typename?: 'ModuleNodeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node?: Maybe<ModuleNode>
}

/** An enumeration. */
export enum ModuleType {
  Collages = 'COLLAGES',
  CustomPages = 'CUSTOM_PAGES',
  Foreword = 'FOREWORD',
  Preword = 'PREWORD',
  ProfilePage = 'PROFILE_PAGE',
  Quotes = 'QUOTES',
  Rankings = 'RANKINGS',
  Reports = 'REPORTS'
}

export type MoveGroupToInstituteInput = {
  clientMutationId?: InputMaybe<Scalars['String']>
  groupId: Scalars['ID']
  instituteId: Scalars['ID']
}

export type MoveGroupToInstitutePayload = {
  __typename?: 'MoveGroupToInstitutePayload'
  clientMutationId?: Maybe<Scalars['String']>
  group?: Maybe<GroupNode>
}

export type MoveProfilePageQuestionToPositionInput = {
  clientMutationId?: InputMaybe<Scalars['String']>
  id: Scalars['ID']
  newPosition: Scalars['Int']
}

export type MoveProfilePageQuestionToPositionPayload = {
  __typename?: 'MoveProfilePageQuestionToPositionPayload'
  clientMutationId?: Maybe<Scalars['String']>
  profilePageQuestion?: Maybe<ProfilePageQuestionNode>
}

export type Mutation = {
  __typename?: 'Mutation'
  acceptUserInGroup?: Maybe<AcceptUserInGroupPayload>
  acceptUserInTeam?: Maybe<AcceptUserInTeamPayload>
  activateModuleInstance?: Maybe<ActivateModuleInstancePayload>
  activateTeam?: Maybe<ActivateTeamPayload>
  addImagesToPost?: Maybe<AddImagesToPostPayload>
  addPhotoToCollagesAlbum?: Maybe<AddPhotoToCollagesAlbumPayload>
  addPhotosToCollagesAlbum?: Maybe<AddPhotosToCollagesAlbumPayload>
  addUserToGroup?: Maybe<AddUserToGroupPayload>
  addUserToTeam?: Maybe<AddUserToTeamPayload>
  adminAcceptInstituteRequest?: Maybe<AdminAcceptInstituteRequestPayload>
  adminAddUserToTeam?: Maybe<AdminAddUserToTeamPayload>
  adminClearUserFlags?: Maybe<AdminClearUserFlagsPayload>
  adminDeleteInstituteRequest?: Maybe<AdminDeleteInstituteRequestPayload>
  adminDeleteUser?: Maybe<AdminDeleteUserPayload>
  adminDowngradeUser?: Maybe<AdminDowngradeUserPayload>
  adminForgetPasswordLink?: Maybe<AdminForgetPasswordLinkPayload>
  adminRejectInstituteRequest?: Maybe<AdminRejectInstituteRequestPayload>
  adminSetBrandLook?: Maybe<AdminSetBrandLookPayload>
  adminUpdateInstitute?: Maybe<AdminUpdateInstitutePayload>
  adminUpdateUser?: Maybe<AdminUpdateUserPayload>
  /**
   * This mutation upgrades normal user to agent, or agent to super user. If you want to immediately upgrade normal
   * user to superuser, you need to provide to_superuser=True. In any case, if user is already superuser, an error is returned
   * that user is already a superuser.
   */
  adminUpgradeUser?: Maybe<AdminUpgradeUserPayload>
  blockUser?: Maybe<BlockUserMutationPayload>
  commentPost?: Maybe<CommentPostPayload>
  createAssetEvent?: Maybe<CreateAssetEventPayload>
  createBannerAsset?: Maybe<CreateBannerAssetPayload>
  createCampaign?: Maybe<CreateCampaignPayload>
  createCollagesAlbum?: Maybe<CreateCollagesAlbumPayload>
  createCustomList?: Maybe<CreateCustomListPayload>
  createCustomListOption?: Maybe<CreateCustomListOptionPayload>
  createCustomPagesInstance?: Maybe<CreateCustomPagesInstancePayload>
  createDataExport?: Maybe<CreateDataExportPayload>
  createDataPolicyAcceptance?: Maybe<CreateDataPolicyAcceptancePayload>
  createEvent?: Maybe<CreateEventPayload>
  createGroup?: Maybe<CreateGroupPayload>
  createInstitute?: Maybe<CreateInstitutePayload>
  createModuleInstance?: Maybe<CreateModuleInstancePayload>
  createPoll?: Maybe<CreatePollPayload>
  createPollOption?: Maybe<CreatePollOptionPayload>
  createPollVote?: Maybe<CreatePollVotePayload>
  createPost?: Maybe<CreatePostPayload>
  createProfilePageAnswer?: Maybe<CreateProfilePageAnswerPayload>
  createProfilePageComment?: Maybe<CreateProfilePageCommentPayload>
  createProfilePagePhoto?: Maybe<CreateProfilePagePhotoPayload>
  createProfilePagePhotoCategory?: Maybe<CreateProfilePagePhotoCategoryPayload>
  createProfilePageQuestion?: Maybe<CreateProfilePageQuestionPayload>
  createProfilePageQuestionOption?: Maybe<CreateProfilePageQuestionOptionPayload>
  createQuotesInstance?: Maybe<CreateQuotesInstancePayload>
  createRankingsAnswer?: Maybe<CreateRankingsAnswerPayload>
  createRankingsQuestion?: Maybe<CreateRankingsQuestionPayload>
  createReportsInstance?: Maybe<CreateReportsInstancePayload>
  createTeam?: Maybe<CreateTeamPayload>
  createTermsAcceptance?: Maybe<CreateTermsAcceptancePayload>
  createToken?: Maybe<ObtainJsonWebTokenPayload>
  createUser?: Maybe<CreateUserPayload>
  deactivateModuleInstance?: Maybe<DeactivateModuleInstancePayload>
  deleteAsset?: Maybe<DeleteAssetPayload>
  deleteCampaign?: Maybe<DeleteCampaignPayload>
  deleteComment?: Maybe<DeleteCommentPayload>
  deleteCustomList?: Maybe<DeleteCustomListPayload>
  deleteCustomListOption?: Maybe<DeleteCustomListOptionPayload>
  deleteEvent?: Maybe<DeleteEventPayload>
  deletePoll?: Maybe<DeletePollPayload>
  deletePollOption?: Maybe<DeletePollOptionPayload>
  deletePollVote?: Maybe<DeletePollVotePayload>
  deletePost?: Maybe<DeletePostPayload>
  deleteUser?: Maybe<DeleteUserPayload>
  destroyCollagesAlbum?: Maybe<DestroyCollagesAlbumPayload>
  destroyCustomPagesInstance?: Maybe<DestroyCustomPagesInstancePayload>
  destroyGroup?: Maybe<DestroyGroupPayload>
  destroyModuleInstance?: Maybe<DestroyModuleInstancePayload>
  destroyProfilePageAnswer?: Maybe<DestroyProfilePageAnswerPayload>
  destroyProfilePageComment?: Maybe<DestroyProfilePageCommentPayload>
  destroyProfilePagePhoto?: Maybe<DestroyProfilePagePhotoPayload>
  destroyProfilePagePhotoCategory?: Maybe<DestroyProfilePagePhotoCategoryPayload>
  destroyProfilePageQuestion?: Maybe<DestroyProfilePageQuestionPayload>
  destroyProfilePageQuestionOption?: Maybe<DestroyProfilePageQuestionOptionPayload>
  destroyQuotesInstance?: Maybe<DestroyQuotesInstancePayload>
  destroyRankingsAnswer?: Maybe<DestroyRankingsAnswerPayload>
  destroyRankingsQuestion?: Maybe<DestroyRankingsQuestionPayload>
  destroyReportsInstance?: Maybe<DestroyReportsInstancePayload>
  destroyTeam?: Maybe<DestroyTeamPayload>
  duplicateAsset?: Maybe<DuplicateAssetPayload>
  editBannerAdAsset?: Maybe<EditBannerAdAssetPayload>
  editCampaign?: Maybe<EditCampaignPayload>
  editComment?: Maybe<EditCommentPayload>
  editEvent?: Maybe<EditEventPayload>
  forgetPassword?: Maybe<ForgetPasswordPayload>
  generateShopifyUrl?: Maybe<GenerateShopifyRedirectUrlPayload>
  importAbihome?: Maybe<Importer>
  importToErp?: Maybe<ImportToErpPayload>
  joinEvent?: Maybe<JoinEventPayload>
  leaveEvent?: Maybe<LeaveEventPayload>
  leaveFromTeam?: Maybe<LeaveFromTeamPayload>
  likeCollagesPhoto?: Maybe<LikeCollagesPhotoPayload>
  likeComment?: Maybe<LikeCommentPayload>
  likePost?: Maybe<LikePostPayload>
  likeQuotesInstance?: Maybe<LikeQuotesInstancePayload>
  moveGroupToInstitute?: Maybe<MoveGroupToInstitutePayload>
  moveProfilePageQuestionToPosition?: Maybe<MoveProfilePageQuestionToPositionPayload>
  refreshGroupToken?: Maybe<RefreshGroupTokenPayload>
  refreshToken?: Maybe<RefreshPayload>
  removeExternalEvent?: Maybe<RemoveExternalEventPayload>
  removeImageFromPost?: Maybe<RemoveImageFromPostPayload>
  removeLikeFromCollagesPhoto?: Maybe<RemoveLikeFromCollagesPhotoPayload>
  removeLikeFromComment?: Maybe<RemoveLikeFromCommentPayload>
  removeLikeFromPost?: Maybe<RemoveLikeFromPostPayload>
  removeLikeFromQuotesInstance?: Maybe<RemoveLikeFromQuotesInstancePayload>
  removePhotoFromCollagesAlbum?: Maybe<RemovePhotoFromCollagesAlbumPayload>
  removeUserFromGroup?: Maybe<RemoveUserFromGroupPayload>
  removeUserFromTeam?: Maybe<RemoveUserFromTeamPayload>
  reportMutation?: Maybe<ReportMutationPayload>
  reportPostContent?: Maybe<ReportPostContentPayload>
  requestMissingInstitute?: Maybe<RequestMissingInstitutePayload>
  resetPassword?: Maybe<ResetPasswordPayload>
  revokeToken?: Maybe<RevokePayload>
  saveExternalEvent?: Maybe<SaveExternalEventPayload>
  setBrandLook?: Maybe<SetBrandLookPayload>
  setEventBanner?: Maybe<SetEventBannerPayload>
  setGroupAvatar?: Maybe<SetGroupAvatarPayload>
  setUserGroupAvatar?: Maybe<SetUserGroupAvatarPayload>
  toggleExternalEventSave?: Maybe<ToggleExternalEventSavePayload>
  toggleIsActiveGroup?: Maybe<ToggleIsActiveGroupPayload>
  tokenAuth?: Maybe<ObtainJsonWebTokenPayload>
  updateCollagesAlbum?: Maybe<UpdateCollagesAlbumPayload>
  updateCollagesSetup?: Maybe<UpdateCollagesSetupPayload>
  updateCustomList?: Maybe<UpdateCustomListPayload>
  updateCustomListOption?: Maybe<UpdateCustomListOptionPayload>
  updateCustomPagesInstance?: Maybe<UpdateCustomPagesInstancePayload>
  updateCustomPagesSetup?: Maybe<UpdateCustomPagesSetupPayload>
  updateGroup?: Maybe<UpdateGroupPayload>
  updateMe?: Maybe<UpdateMePayload>
  updateModuleInstance?: Maybe<UpdateModuleInstancePayload>
  updatePassword?: Maybe<UpdatePasswordPayload>
  updatePoll?: Maybe<UpdatePollPayload>
  updatePollOption?: Maybe<UpdatePollOptionPayload>
  updatePost?: Maybe<UpdatePostPayload>
  updatePrewordInstance?: Maybe<UpdatePrewordInstancePayload>
  updateProfilePageAnswer?: Maybe<UpdateProfilePageAnswerPayload>
  updateProfilePageComment?: Maybe<UpdateProfilePageCommentPayload>
  updateProfilePagePhoto?: Maybe<UpdateProfilePagePhotoPayload>
  updateProfilePagePhotoCategory?: Maybe<UpdateProfilePagePhotoCategoryPayload>
  updateProfilePageQuestion?: Maybe<UpdateProfilePageQuestionPayload>
  updateProfilePageQuestionOption?: Maybe<UpdateProfilePageQuestionOptionPayload>
  updateProfilePageSetup?: Maybe<UpdateProfilePageSetupPayload>
  updateQuotesInstance?: Maybe<UpdateQuotesInstancePayload>
  updateQuotesSetup?: Maybe<UpdateQuotesSetupPayload>
  updateRankingsAnswer?: Maybe<UpdateRankingsAnswerPayload>
  updateRankingsQuestion?: Maybe<UpdateRankingsQuestionPayload>
  updateRankingsSetup?: Maybe<UpdateRankingsSetupPayload>
  updateReportsInstance?: Maybe<UpdateReportsInstancePayload>
  updateReportsSetup?: Maybe<UpdateReportsSetupPayload>
  updateTeam?: Maybe<UpdateTeamPayload>
  updateUserGroup?: Maybe<UpdateUserGroupPayload>
  updateUserGroupNotification?: Maybe<UpdateUserGroupNotificationPayload>
  updateUserTeamAdmin?: Maybe<UpdateUserTeamAdminPayload>
  verifyToken?: Maybe<VerifyPayload>
}

export type MutationAcceptUserInGroupArgs = {
  input: AcceptUserInGroupInput
}

export type MutationAcceptUserInTeamArgs = {
  input: AcceptUserInTeamInput
}

export type MutationActivateModuleInstanceArgs = {
  input: ActivateModuleInstanceInput
}

export type MutationActivateTeamArgs = {
  input: ActivateTeamInput
}

export type MutationAddImagesToPostArgs = {
  input: AddImagesToPostInput
}

export type MutationAddPhotoToCollagesAlbumArgs = {
  input: AddPhotoToCollagesAlbumInput
}

export type MutationAddPhotosToCollagesAlbumArgs = {
  input: AddPhotosToCollagesAlbumInput
}

export type MutationAddUserToGroupArgs = {
  input: AddUserToGroupInput
}

export type MutationAddUserToTeamArgs = {
  input: AddUserToTeamInput
}

export type MutationAdminAcceptInstituteRequestArgs = {
  input: AdminAcceptInstituteRequestInput
}

export type MutationAdminAddUserToTeamArgs = {
  input: AdminAddUserToTeamInput
}

export type MutationAdminClearUserFlagsArgs = {
  input: AdminClearUserFlagsInput
}

export type MutationAdminDeleteInstituteRequestArgs = {
  input: AdminDeleteInstituteRequestInput
}

export type MutationAdminDeleteUserArgs = {
  input: AdminDeleteUserInput
}

export type MutationAdminDowngradeUserArgs = {
  input: AdminDowngradeUserInput
}

export type MutationAdminForgetPasswordLinkArgs = {
  input: AdminForgetPasswordLinkInput
}

export type MutationAdminRejectInstituteRequestArgs = {
  input: AdminRejectInstituteRequestInput
}

export type MutationAdminSetBrandLookArgs = {
  input: AdminSetBrandLookInput
}

export type MutationAdminUpdateInstituteArgs = {
  input: AdminUpdateInstituteInput
}

export type MutationAdminUpdateUserArgs = {
  input: AdminUpdateUserInput
}

export type MutationAdminUpgradeUserArgs = {
  input: AdminUpgradeUserInput
}

export type MutationBlockUserArgs = {
  input: BlockUserMutationInput
}

export type MutationCommentPostArgs = {
  input: CommentPostInput
}

export type MutationCreateAssetEventArgs = {
  input: CreateAssetEventInput
}

export type MutationCreateBannerAssetArgs = {
  input: CreateBannerAssetInput
}

export type MutationCreateCampaignArgs = {
  input: CreateCampaignInput
}

export type MutationCreateCollagesAlbumArgs = {
  input: CreateCollagesAlbumInput
}

export type MutationCreateCustomListArgs = {
  input: CreateCustomListInput
}

export type MutationCreateCustomListOptionArgs = {
  input: CreateCustomListOptionInput
}

export type MutationCreateCustomPagesInstanceArgs = {
  input: CreateCustomPagesInstanceInput
}

export type MutationCreateDataExportArgs = {
  input: CreateDataExportInput
}

export type MutationCreateDataPolicyAcceptanceArgs = {
  input: CreateDataPolicyAcceptanceInput
}

export type MutationCreateEventArgs = {
  input: CreateEventInput
}

export type MutationCreateGroupArgs = {
  input: CreateGroupInput
}

export type MutationCreateInstituteArgs = {
  input: CreateInstituteInput
}

export type MutationCreateModuleInstanceArgs = {
  input: CreateModuleInstanceInput
}

export type MutationCreatePollArgs = {
  input: CreatePollInput
}

export type MutationCreatePollOptionArgs = {
  input: CreatePollOptionInput
}

export type MutationCreatePollVoteArgs = {
  input: CreatePollVoteInput
}

export type MutationCreatePostArgs = {
  input: CreatePostInput
}

export type MutationCreateProfilePageAnswerArgs = {
  input: CreateProfilePageAnswerInput
}

export type MutationCreateProfilePageCommentArgs = {
  input: CreateProfilePageCommentInput
}

export type MutationCreateProfilePagePhotoArgs = {
  input: CreateProfilePagePhotoInput
}

export type MutationCreateProfilePagePhotoCategoryArgs = {
  input: CreateProfilePagePhotoCategoryInput
}

export type MutationCreateProfilePageQuestionArgs = {
  input: CreateProfilePageQuestionInput
}

export type MutationCreateProfilePageQuestionOptionArgs = {
  input: CreateProfilePageQuestionOptionInput
}

export type MutationCreateQuotesInstanceArgs = {
  input: CreateQuotesInstanceInput
}

export type MutationCreateRankingsAnswerArgs = {
  input: CreateRankingsAnswerInput
}

export type MutationCreateRankingsQuestionArgs = {
  input: CreateRankingsQuestionInput
}

export type MutationCreateReportsInstanceArgs = {
  input: CreateReportsInstanceInput
}

export type MutationCreateTeamArgs = {
  input: CreateTeamInput
}

export type MutationCreateTermsAcceptanceArgs = {
  input: CreateTermsAcceptanceInput
}

export type MutationCreateTokenArgs = {
  input: ObtainJsonWebTokenInput
}

export type MutationCreateUserArgs = {
  input: CreateUserInput
}

export type MutationDeactivateModuleInstanceArgs = {
  input: DeactivateModuleInstanceInput
}

export type MutationDeleteAssetArgs = {
  input: DeleteAssetInput
}

export type MutationDeleteCampaignArgs = {
  input: DeleteCampaignInput
}

export type MutationDeleteCommentArgs = {
  input: DeleteCommentInput
}

export type MutationDeleteCustomListArgs = {
  input: DeleteCustomListInput
}

export type MutationDeleteCustomListOptionArgs = {
  input: DeleteCustomListOptionInput
}

export type MutationDeleteEventArgs = {
  input: DeleteEventInput
}

export type MutationDeletePollArgs = {
  input: DeletePollInput
}

export type MutationDeletePollOptionArgs = {
  input: DeletePollOptionInput
}

export type MutationDeletePollVoteArgs = {
  input: DeletePollVoteInput
}

export type MutationDeletePostArgs = {
  input: DeletePostInput
}

export type MutationDeleteUserArgs = {
  input: DeleteUserInput
}

export type MutationDestroyCollagesAlbumArgs = {
  input: DestroyCollagesAlbumInput
}

export type MutationDestroyCustomPagesInstanceArgs = {
  input: DestroyCustomPagesInstanceInput
}

export type MutationDestroyGroupArgs = {
  input: DestroyGroupInput
}

export type MutationDestroyModuleInstanceArgs = {
  input: DestroyModuleInstanceInput
}

export type MutationDestroyProfilePageAnswerArgs = {
  input: DestroyProfilePageAnswerInput
}

export type MutationDestroyProfilePageCommentArgs = {
  input: DestroyProfilePageCommentInput
}

export type MutationDestroyProfilePagePhotoArgs = {
  input: DestroyProfilePagePhotoInput
}

export type MutationDestroyProfilePagePhotoCategoryArgs = {
  input: DestroyProfilePagePhotoCategoryInput
}

export type MutationDestroyProfilePageQuestionArgs = {
  input: DestroyProfilePageQuestionInput
}

export type MutationDestroyProfilePageQuestionOptionArgs = {
  input: DestroyProfilePageQuestionOptionInput
}

export type MutationDestroyQuotesInstanceArgs = {
  input: DestroyQuotesInstanceInput
}

export type MutationDestroyRankingsAnswerArgs = {
  input: DestroyRankingsAnswerInput
}

export type MutationDestroyRankingsQuestionArgs = {
  input: DestroyRankingsQuestionInput
}

export type MutationDestroyReportsInstanceArgs = {
  input: DestroyReportsInstanceInput
}

export type MutationDestroyTeamArgs = {
  input: DestroyTeamInput
}

export type MutationDuplicateAssetArgs = {
  input: DuplicateAssetInput
}

export type MutationEditBannerAdAssetArgs = {
  input: EditBannerAdAssetInput
}

export type MutationEditCampaignArgs = {
  input: EditCampaignInput
}

export type MutationEditCommentArgs = {
  input: EditCommentInput
}

export type MutationEditEventArgs = {
  input: EditEventInput
}

export type MutationForgetPasswordArgs = {
  input: ForgetPasswordInput
}

export type MutationGenerateShopifyUrlArgs = {
  input: GenerateShopifyRedirectUrlInput
}

export type MutationImportAbihomeArgs = {
  approved?: InputMaybe<Scalars['Boolean']>
  avatar?: InputMaybe<Scalars['Upload']>
  email: Scalars['String']
  graduationType: Scalars['String']
  graduationYear: Scalars['Int']
  groupName: Scalars['String']
  iconId?: InputMaybe<Scalars['ID']>
  password: Scalars['String']
}

export type MutationImportToErpArgs = {
  input: ImportToErpInput
}

export type MutationJoinEventArgs = {
  input: JoinEventInput
}

export type MutationLeaveEventArgs = {
  input: LeaveEventInput
}

export type MutationLeaveFromTeamArgs = {
  input: LeaveFromTeamInput
}

export type MutationLikeCollagesPhotoArgs = {
  input: LikeCollagesPhotoInput
}

export type MutationLikeCommentArgs = {
  input: LikeCommentInput
}

export type MutationLikePostArgs = {
  input: LikePostInput
}

export type MutationLikeQuotesInstanceArgs = {
  input: LikeQuotesInstanceInput
}

export type MutationMoveGroupToInstituteArgs = {
  input: MoveGroupToInstituteInput
}

export type MutationMoveProfilePageQuestionToPositionArgs = {
  input: MoveProfilePageQuestionToPositionInput
}

export type MutationRefreshGroupTokenArgs = {
  input: RefreshGroupTokenInput
}

export type MutationRefreshTokenArgs = {
  input: RefreshInput
}

export type MutationRemoveExternalEventArgs = {
  input: RemoveExternalEventInput
}

export type MutationRemoveImageFromPostArgs = {
  input: RemoveImageFromPostInput
}

export type MutationRemoveLikeFromCollagesPhotoArgs = {
  input: RemoveLikeFromCollagesPhotoInput
}

export type MutationRemoveLikeFromCommentArgs = {
  input: RemoveLikeFromCommentInput
}

export type MutationRemoveLikeFromPostArgs = {
  input: RemoveLikeFromPostInput
}

export type MutationRemoveLikeFromQuotesInstanceArgs = {
  input: RemoveLikeFromQuotesInstanceInput
}

export type MutationRemovePhotoFromCollagesAlbumArgs = {
  input: RemovePhotoFromCollagesAlbumInput
}

export type MutationRemoveUserFromGroupArgs = {
  input: RemoveUserFromGroupInput
}

export type MutationRemoveUserFromTeamArgs = {
  input: RemoveUserFromTeamInput
}

export type MutationReportMutationArgs = {
  input: ReportMutationInput
}

export type MutationReportPostContentArgs = {
  input: ReportPostContentInput
}

export type MutationRequestMissingInstituteArgs = {
  input: RequestMissingInstituteInput
}

export type MutationResetPasswordArgs = {
  input: ResetPasswordInput
}

export type MutationRevokeTokenArgs = {
  input: RevokeInput
}

export type MutationSaveExternalEventArgs = {
  input: SaveExternalEventInput
}

export type MutationSetBrandLookArgs = {
  input: SetBrandLookInput
}

export type MutationSetEventBannerArgs = {
  input: SetEventBannerInput
}

export type MutationSetGroupAvatarArgs = {
  input: SetGroupAvatarInput
}

export type MutationSetUserGroupAvatarArgs = {
  input: SetUserGroupAvatarInput
}

export type MutationToggleExternalEventSaveArgs = {
  input: ToggleExternalEventSaveInput
}

export type MutationToggleIsActiveGroupArgs = {
  input: ToggleIsActiveGroupInput
}

export type MutationTokenAuthArgs = {
  input: ObtainJsonWebTokenInput
}

export type MutationUpdateCollagesAlbumArgs = {
  input: UpdateCollagesAlbumInput
}

export type MutationUpdateCollagesSetupArgs = {
  input: UpdateCollagesSetupInput
}

export type MutationUpdateCustomListArgs = {
  input: UpdateCustomListInput
}

export type MutationUpdateCustomListOptionArgs = {
  input: UpdateCustomListOptionInput
}

export type MutationUpdateCustomPagesInstanceArgs = {
  input: UpdateCustomPagesInstanceInput
}

export type MutationUpdateCustomPagesSetupArgs = {
  input: UpdateCustomPagesSetupInput
}

export type MutationUpdateGroupArgs = {
  input: UpdateGroupInput
}

export type MutationUpdateMeArgs = {
  input: UpdateMeInput
}

export type MutationUpdateModuleInstanceArgs = {
  input: UpdateModuleInstanceInput
}

export type MutationUpdatePasswordArgs = {
  input: UpdatePasswordInput
}

export type MutationUpdatePollArgs = {
  input: UpdatePollInput
}

export type MutationUpdatePollOptionArgs = {
  input: UpdatePollOptionInput
}

export type MutationUpdatePostArgs = {
  input: UpdatePostInput
}

export type MutationUpdatePrewordInstanceArgs = {
  input: UpdatePrewordInstanceInput
}

export type MutationUpdateProfilePageAnswerArgs = {
  input: UpdateProfilePageAnswerInput
}

export type MutationUpdateProfilePageCommentArgs = {
  input: UpdateProfilePageCommentInput
}

export type MutationUpdateProfilePagePhotoArgs = {
  input: UpdateProfilePagePhotoInput
}

export type MutationUpdateProfilePagePhotoCategoryArgs = {
  input: UpdateProfilePagePhotoCategoryInput
}

export type MutationUpdateProfilePageQuestionArgs = {
  input: UpdateProfilePageQuestionInput
}

export type MutationUpdateProfilePageQuestionOptionArgs = {
  input: UpdateProfilePageQuestionOptionInput
}

export type MutationUpdateProfilePageSetupArgs = {
  input: UpdateProfilePageSetupInput
}

export type MutationUpdateQuotesInstanceArgs = {
  input: UpdateQuotesInstanceInput
}

export type MutationUpdateQuotesSetupArgs = {
  input: UpdateQuotesSetupInput
}

export type MutationUpdateRankingsAnswerArgs = {
  input: UpdateRankingsAnswerInput
}

export type MutationUpdateRankingsQuestionArgs = {
  input: UpdateRankingsQuestionInput
}

export type MutationUpdateRankingsSetupArgs = {
  input: UpdateRankingsSetupInput
}

export type MutationUpdateReportsInstanceArgs = {
  input: UpdateReportsInstanceInput
}

export type MutationUpdateReportsSetupArgs = {
  input: UpdateReportsSetupInput
}

export type MutationUpdateTeamArgs = {
  input: UpdateTeamInput
}

export type MutationUpdateUserGroupArgs = {
  input: UpdateUserGroupInput
}

export type MutationUpdateUserGroupNotificationArgs = {
  input: UpdateUserGroupNotificationInput
}

export type MutationUpdateUserTeamAdminArgs = {
  input: UpdateUserTeamAdminInput
}

export type MutationVerifyTokenArgs = {
  input: VerifyInput
}

/** An object with an ID */
export type Node = {
  /** The ID of the object */
  id: Scalars['ID']
}

export type NotificationNode = Node & {
  __typename?: 'NotificationNode'
  created: Scalars['DateTime']
  /** The ID of the object */
  id: Scalars['ID']
  modified: Scalars['DateTime']
  name: Scalars['String']
}

export type NotificationNodeConnection = {
  __typename?: 'NotificationNodeConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<NotificationNodeEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
}

/** A Relay edge containing a `NotificationNode` and its cursor. */
export type NotificationNodeEdge = {
  __typename?: 'NotificationNodeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node?: Maybe<NotificationNode>
}

export type ObtainJsonWebTokenInput = {
  clientMutationId?: InputMaybe<Scalars['String']>
  email: Scalars['String']
  password: Scalars['String']
}

export type ObtainJsonWebTokenPayload = {
  __typename?: 'ObtainJSONWebTokenPayload'
  clientMutationId?: Maybe<Scalars['String']>
  payload: Scalars['GenericScalar']
  refreshExpiresIn: Scalars['Int']
  refreshToken: Scalars['String']
  token: Scalars['String']
}

/** An enumeration. */
export enum Owner {
  Team = 'TEAM',
  User = 'USER'
}

/** An enumeration. */
export enum PageFormat {
  Custom = 'CUSTOM',
  Landscape = 'LANDSCAPE',
  Portrait = 'PORTRAIT'
}

/** The Relay compliant `PageInfo` type, containing data necessary to paginate this connection. */
export type PageInfo = {
  __typename?: 'PageInfo'
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>
}

/** An enumeration. */
export enum PageQuality {
  Critical = 'CRITICAL',
  Good = 'GOOD',
  Poor = 'POOR'
}

/** An enumeration. */
export enum PageType {
  Image = 'IMAGE',
  Pdf = 'PDF'
}

/** An enumeration. */
export enum PhotoFormat {
  AsUploaded = 'AS_UPLOADED',
  Portrait = 'PORTRAIT',
  Square = 'SQUARE'
}

/** An enumeration. */
export enum Platform {
  Contentful = 'CONTENTFUL'
}

export type PollNode = Node & {
  __typename?: 'PollNode'
  allowComments: Scalars['Boolean']
  allowRevote: Scalars['Boolean']
  anonymousVotes: Scalars['Boolean']
  answersAllowed: Scalars['Int']
  created: Scalars['DateTime']
  deadline: Scalars['DateTime']
  /** The ID of the object */
  id: Scalars['ID']
  modified: Scalars['DateTime']
  options?: Maybe<Array<Maybe<PollOptionNode>>>
  post?: Maybe<PostNode>
  privateResults: Scalars['Boolean']
  question: Scalars['String']
  randomizeOptions: Scalars['Boolean']
  totalVotes?: Maybe<Scalars['Int']>
}

export type PollNodeConnection = {
  __typename?: 'PollNodeConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<PollNodeEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
}

/** A Relay edge containing a `PollNode` and its cursor. */
export type PollNodeEdge = {
  __typename?: 'PollNodeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node?: Maybe<PollNode>
}

export type PollOptionNode = Node & {
  __typename?: 'PollOptionNode'
  created: Scalars['DateTime']
  /** The ID of the object */
  id: Scalars['ID']
  image?: Maybe<Scalars['String']>
  modified: Scalars['DateTime']
  percentage?: Maybe<Scalars['Float']>
  poll?: Maybe<PollNode>
  text: Scalars['String']
  votes?: Maybe<Array<Maybe<PollVoteNode>>>
  votesNumber?: Maybe<Scalars['Int']>
}

export type PollVoteNode = Node & {
  __typename?: 'PollVoteNode'
  created: Scalars['DateTime']
  /** The ID of the object */
  id: Scalars['ID']
  modified: Scalars['DateTime']
  pollOption?: Maybe<PollOptionNode>
  userGroup?: Maybe<UserGroupNode>
}

export type PollVoteNodeConnection = {
  __typename?: 'PollVoteNodeConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<PollVoteNodeEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
}

/** A Relay edge containing a `PollVoteNode` and its cursor. */
export type PollVoteNodeEdge = {
  __typename?: 'PollVoteNodeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node?: Maybe<PollVoteNode>
}

export type PostNode = Node & {
  __typename?: 'PostNode'
  comments?: Maybe<Array<Maybe<CommentNode>>>
  created: Scalars['DateTime']
  createdBy?: Maybe<UserGroupNode>
  /** The ID of the object */
  id: Scalars['ID']
  images?: Maybe<Array<Maybe<Scalars['String']>>>
  likes?: Maybe<LikeNodeConnection>
  likesCount?: Maybe<Scalars['Int']>
  modified: Scalars['DateTime']
  owner?: Maybe<Owner>
  poll?: Maybe<PollNode>
  team?: Maybe<TeamNode>
  text: Scalars['String']
}

export type PostNodeLikesArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  userGroup?: InputMaybe<Scalars['ID']>
}

export type PostNodeConnection = {
  __typename?: 'PostNodeConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<PostNodeEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
}

/** A Relay edge containing a `PostNode` and its cursor. */
export type PostNodeEdge = {
  __typename?: 'PostNodeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node?: Maybe<PostNode>
}

export type PrewordInstanceNode = Node & {
  __typename?: 'PrewordInstanceNode'
  created: Scalars['DateTime']
  /** The ID of the object */
  id: Scalars['ID']
  modified: Scalars['DateTime']
  moduleInstance: ModuleInstanceNode
  text: Scalars['String']
  wordCount?: Maybe<Scalars['Int']>
}

export type ProfilePageAnswerNode = Node & {
  __typename?: 'ProfilePageAnswerNode'
  created: Scalars['DateTime']
  /** The ID of the object */
  id: Scalars['ID']
  modified: Scalars['DateTime']
  profilePageQuestion: ProfilePageQuestionNode
  profilePageQuestionOption?: Maybe<ProfilePageQuestionOptionNode>
  text?: Maybe<Scalars['String']>
  userGroup: UserGroupNode
}

export type ProfilePageAnswerNodeConnection = {
  __typename?: 'ProfilePageAnswerNodeConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ProfilePageAnswerNodeEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
}

/** A Relay edge containing a `ProfilePageAnswerNode` and its cursor. */
export type ProfilePageAnswerNodeEdge = {
  __typename?: 'ProfilePageAnswerNodeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node?: Maybe<ProfilePageAnswerNode>
}

export type ProfilePageCommentNode = Node & {
  __typename?: 'ProfilePageCommentNode'
  comentatorUserGroup: UserGroupNode
  created: Scalars['DateTime']
  /** The ID of the object */
  id: Scalars['ID']
  modified: Scalars['DateTime']
  moduleInstance: ModuleInstanceNode
  profileUserGroup: UserGroupNode
  text: Scalars['String']
}

export type ProfilePageCommentNodeConnection = {
  __typename?: 'ProfilePageCommentNodeConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ProfilePageCommentNodeEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
}

/** A Relay edge containing a `ProfilePageCommentNode` and its cursor. */
export type ProfilePageCommentNodeEdge = {
  __typename?: 'ProfilePageCommentNodeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node?: Maybe<ProfilePageCommentNode>
}

export type ProfilePagePhotoCategoryNode = Node & {
  __typename?: 'ProfilePagePhotoCategoryNode'
  created: Scalars['DateTime']
  /** The ID of the object */
  id: Scalars['ID']
  modified: Scalars['DateTime']
  moduleInstance: ModuleInstanceNode
  name?: Maybe<Scalars['String']>
  profilePagePhotos?: Maybe<ProfilePagePhotoNodeConnection>
  suggestedNames?: Maybe<
    Array<Maybe<ProfilePagePhotoCategorySuggestedNameNode>>
  >
}

export type ProfilePagePhotoCategoryNodeProfilePagePhotosArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  profilePagePhotoCategory?: InputMaybe<Scalars['ID']>
  userGroup?: InputMaybe<Scalars['ID']>
}

export type ProfilePagePhotoCategoryNodeConnection = {
  __typename?: 'ProfilePagePhotoCategoryNodeConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ProfilePagePhotoCategoryNodeEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
}

/** A Relay edge containing a `ProfilePagePhotoCategoryNode` and its cursor. */
export type ProfilePagePhotoCategoryNodeEdge = {
  __typename?: 'ProfilePagePhotoCategoryNodeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node?: Maybe<ProfilePagePhotoCategoryNode>
}

export type ProfilePagePhotoCategorySuggestedNameNode = Node & {
  __typename?: 'ProfilePagePhotoCategorySuggestedNameNode'
  created: Scalars['DateTime']
  /** The ID of the object */
  id: Scalars['ID']
  modified: Scalars['DateTime']
  name?: Maybe<Scalars['String']>
}

export type ProfilePagePhotoCategorySuggestedNameNodeConnection = {
  __typename?: 'ProfilePagePhotoCategorySuggestedNameNodeConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ProfilePagePhotoCategorySuggestedNameNodeEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
}

/** A Relay edge containing a `ProfilePagePhotoCategorySuggestedNameNode` and its cursor. */
export type ProfilePagePhotoCategorySuggestedNameNodeEdge = {
  __typename?: 'ProfilePagePhotoCategorySuggestedNameNodeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node?: Maybe<ProfilePagePhotoCategorySuggestedNameNode>
}

export type ProfilePagePhotoNode = Node & {
  __typename?: 'ProfilePagePhotoNode'
  created: Scalars['DateTime']
  /** The ID of the object */
  id: Scalars['ID']
  image?: Maybe<Scalars['String']>
  modified: Scalars['DateTime']
  profilePagePhotoCategory: ProfilePagePhotoCategoryNode
  userGroup: UserGroupNode
}

export type ProfilePagePhotoNodeConnection = {
  __typename?: 'ProfilePagePhotoNodeConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ProfilePagePhotoNodeEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
}

/** A Relay edge containing a `ProfilePagePhotoNode` and its cursor. */
export type ProfilePagePhotoNodeEdge = {
  __typename?: 'ProfilePagePhotoNodeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node?: Maybe<ProfilePagePhotoNode>
}

export type ProfilePageQuestionNode = Node & {
  __typename?: 'ProfilePageQuestionNode'
  created: Scalars['DateTime']
  /** The ID of the object */
  id: Scalars['ID']
  modified: Scalars['DateTime']
  moduleInstance: ModuleInstanceNode
  order: Scalars['Int']
  profilePageAnswers?: Maybe<ProfilePageAnswerNodeConnection>
  profilePageQuestionOptions?: Maybe<
    Array<Maybe<ProfilePageQuestionOptionNode>>
  >
  text: Scalars['String']
}

export type ProfilePageQuestionNodeProfilePageAnswersArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  profilePageQuestion?: InputMaybe<Scalars['ID']>
  userGroup?: InputMaybe<Scalars['ID']>
}

export type ProfilePageQuestionNodeConnection = {
  __typename?: 'ProfilePageQuestionNodeConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ProfilePageQuestionNodeEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
}

/** A Relay edge containing a `ProfilePageQuestionNode` and its cursor. */
export type ProfilePageQuestionNodeEdge = {
  __typename?: 'ProfilePageQuestionNodeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node?: Maybe<ProfilePageQuestionNode>
}

export type ProfilePageQuestionOptionNode = Node & {
  __typename?: 'ProfilePageQuestionOptionNode'
  created: Scalars['DateTime']
  /** The ID of the object */
  id: Scalars['ID']
  modified: Scalars['DateTime']
  profilePageQuestion: ProfilePageQuestionNode
  text: Scalars['String']
}

export type ProfilePageSetupNode = Node & {
  __typename?: 'ProfilePageSetupNode'
  anonymousComments: Scalars['Boolean']
  created: Scalars['DateTime']
  /** The ID of the object */
  id: Scalars['ID']
  maxChars: Scalars['Int']
  modified: Scalars['DateTime']
  moduleInstance: ModuleInstanceNode
  photoFormat?: Maybe<PhotoFormat>
  usersDelivered?: Maybe<Scalars['Int']>
  usersSummary?: Maybe<Array<Maybe<ProfilePageUserSummaryNode>>>
  visibleComments: Scalars['Boolean']
}

export type ProfilePageSuggestedQuestionNode = Node & {
  __typename?: 'ProfilePageSuggestedQuestionNode'
  created: Scalars['DateTime']
  /** The ID of the object */
  id: Scalars['ID']
  modified: Scalars['DateTime']
  options?: Maybe<
    Array<Maybe<ProfilePageSuggestedQuestionOptionNode>>
  >
  text: Scalars['String']
}

export type ProfilePageSuggestedQuestionNodeConnection = {
  __typename?: 'ProfilePageSuggestedQuestionNodeConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ProfilePageSuggestedQuestionNodeEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
}

/** A Relay edge containing a `ProfilePageSuggestedQuestionNode` and its cursor. */
export type ProfilePageSuggestedQuestionNodeEdge = {
  __typename?: 'ProfilePageSuggestedQuestionNodeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node?: Maybe<ProfilePageSuggestedQuestionNode>
}

export type ProfilePageSuggestedQuestionOptionNode = Node & {
  __typename?: 'ProfilePageSuggestedQuestionOptionNode'
  created: Scalars['DateTime']
  /** The ID of the object */
  id: Scalars['ID']
  modified: Scalars['DateTime']
  question: ProfilePageSuggestedQuestionNode
  text: Scalars['String']
}

export type ProfilePageUserSummaryNode = {
  __typename?: 'ProfilePageUserSummaryNode'
  imagesUploaded?: Maybe<Scalars['Int']>
  questionsAnswered?: Maybe<Scalars['Int']>
  userGroup: UserGroupNode
}

export type Query = {
  __typename?: 'Query'
  _debug?: Maybe<DjangoDebug>
  activeGroups?: Maybe<Array<Maybe<CountryData>>>
  adminSearchResults?: Maybe<SearchStructure3>
  asset?: Maybe<AssetNode>
  bestMatchAssets?: Maybe<Array<Maybe<MatchedAssetNode>>>
  brandLookColor?: Maybe<BrandColorNode>
  brandLookColors?: Maybe<BrandColorNodeConnection>
  brandLookFont?: Maybe<BrandFontNode>
  brandLookFonts?: Maybe<BrandFontNodeConnection>
  brandLookSelection?: Maybe<BrandLookSelectionNode>
  brandLookSelectionHistory?: Maybe<BrandLookSelectionHistoryNode>
  brandLookSelections?: Maybe<BrandLookSelectionNodeConnection>
  brandLookSelectionsHistory?: Maybe<BrandLookSelectionHistoryNodeConnection>
  campaign?: Maybe<CampaignNode>
  campaigns?: Maybe<CampaignNodeConnection>
  citiesZip?: Maybe<Array<Maybe<CityNode>>>
  cityLocation?: Maybe<CityLocationNode>
  cityLocations?: Maybe<CityLocationNodeConnection>
  collagesAlbum?: Maybe<CollagesAlbumNode>
  collagesAlbumSuggestedNames?: Maybe<CollagesAlbumSuggestedNameNodeConnection>
  collagesAlbums?: Maybe<CollagesAlbumNodeConnection>
  collagesPhotos?: Maybe<CollagesPhotoNodeConnection>
  color?: Maybe<ColorNode>
  colors?: Maybe<ColorNodeConnection>
  customList?: Maybe<CustomListNode>
  customListOptions?: Maybe<CustomListOptionNodeConnection>
  customLists?: Maybe<CustomListNodeConnection>
  customPagesInstance?: Maybe<CustomPagesInstanceNode>
  customPagesInstances?: Maybe<CustomPagesInstanceNodeConnection>
  customPagesInstancesResult?: Maybe<CustomPagesInstanceNodeConnection>
  customPagesSetup?: Maybe<CustomPagesSetupNode>
  dataExport?: Maybe<DataExportNode>
  dataExports?: Maybe<DataExportNodeConnection>
  defaultAvatar?: Maybe<DefaultAvatarNode>
  defaultAvatars?: Maybe<DefaultAvatarNodeConnection>
  event?: Maybe<EventNode>
  events?: Maybe<EventNodeConnection>
  externalEventAttendees?: Maybe<ExternalEventAttendeeNodeConnection>
  findDuplicateInstitutes?: Maybe<Array<Maybe<InstituteNode>>>
  generateFreshUrl?: Maybe<Scalars['String']>
  graduationType?: Maybe<GraduationTypeNode>
  graduationTypes?: Maybe<GraduationTypeNodeConnection>
  graduationTypesCountry?: Maybe<
    Array<Maybe<GraduationTypeCountryNode>>
  >
  group?: Maybe<GroupNode>
  groupIcon?: Maybe<GroupIconNode>
  groupIcons?: Maybe<GroupIconNodeConnection>
  groups?: Maybe<GroupNodeConnection>
  icon?: Maybe<IconNode>
  icons?: Maybe<IconNodeConnection>
  inactiveGroups?: Maybe<Array<Maybe<CountryData>>>
  institute?: Maybe<InstituteNode>
  instituteRequest?: Maybe<InstituteRequestNode>
  instituteRequests?: Maybe<InstituteRequestNodeConnection>
  instituteType?: Maybe<InstituteTypeNode>
  instituteTypes?: Maybe<InstituteTypeNodeConnection>
  institutes?: Maybe<InstituteNodeConnection>
  institutesTargeting?: Maybe<TargetingNode>
  institutesWithOneGroup?: Maybe<Array<Maybe<CountryData>>>
  kpis?: Maybe<KpIsNode>
  leaderboardUsers?: Maybe<LeaderboardUserNodeConnection>
  marketProfile?: Maybe<MarketProfileNode>
  marketProfiles?: Maybe<MarketProfileNodeConnection>
  me?: Maybe<UserNode>
  module?: Maybe<ModuleNode>
  moduleInstance?: Maybe<ModuleInstanceNode>
  moduleInstances?: Maybe<ModuleInstanceNodeConnection>
  modules?: Maybe<ModuleNodeConnection>
  notification?: Maybe<NotificationNode>
  notifications?: Maybe<NotificationNodeConnection>
  number?: Maybe<Scalars['Int']>
  polls?: Maybe<PollNodeConnection>
  post?: Maybe<PostNode>
  posts?: Maybe<PostNodeConnection>
  profilePageComment?: Maybe<ProfilePageCommentNode>
  profilePageComments?: Maybe<ProfilePageCommentNodeConnection>
  profilePagePhoto?: Maybe<ProfilePagePhotoNode>
  profilePagePhotoCategories?: Maybe<ProfilePagePhotoCategoryNodeConnection>
  profilePagePhotoCategory?: Maybe<ProfilePagePhotoCategoryNode>
  profilePagePhotoCategorySuggestedNames?: Maybe<ProfilePagePhotoCategorySuggestedNameNodeConnection>
  profilePagePhotos?: Maybe<ProfilePagePhotoNodeConnection>
  profilePageQuestion?: Maybe<ProfilePageQuestionNode>
  profilePageQuestions?: Maybe<ProfilePageQuestionNodeConnection>
  profilePageSuggestedQuestion?: Maybe<ProfilePageSuggestedQuestionNode>
  profilePageSuggestedQuestions?: Maybe<ProfilePageSuggestedQuestionNodeConnection>
  quotesInstance?: Maybe<QuotesInstanceNode>
  quotesInstances?: Maybe<QuotesInstanceNodeConnection>
  quotesResult?: Maybe<QuotesInstanceNodeConnection>
  rankingsQuestion?: Maybe<RankingsQuestionNode>
  rankingsQuestions?: Maybe<RankingsQuestionNodeConnection>
  regions?: Maybe<Array<Maybe<CityLocationNode>>>
  reportsInstance?: Maybe<ReportsInstanceNode>
  reportsInstances?: Maybe<ReportsInstanceNodeConnection>
  runAssetCronJobChecks?: Maybe<Scalars['Boolean']>
  runCampaignCronJobChecks?: Maybe<Scalars['Boolean']>
  signups?: Maybe<Array<Maybe<CountryData>>>
  statistics?: Maybe<StatisticsNode>
  team?: Maybe<TeamNode>
  teams?: Maybe<TeamNodeConnection>
  user?: Maybe<UserNode>
  userExists?: Maybe<UserExistsNode>
  userGroup?: Maybe<UserGroupNode>
  userGroups?: Maybe<UserGroupNodeConnection>
  userTeam?: Maybe<UserTeamNode>
  users?: Maybe<UserNodeConnection>
  usersWithoutGroup?: Maybe<Array<Maybe<CountryData>>>
}

export type QueryActiveGroupsArgs = {
  filter?: InputMaybe<DashboardFilterInput>
}

export type QueryAdminSearchResultsArgs = {
  city?: InputMaybe<Scalars['String']>
  custom?: InputMaybe<Scalars['String']>
  email?: InputMaybe<Scalars['String']>
  gid?: InputMaybe<Scalars['String']>
  gname?: InputMaybe<Scalars['String']>
  gtype?: InputMaybe<Scalars['String']>
  itype?: InputMaybe<Scalars['String']>
  name?: InputMaybe<Scalars['String']>
  output?: InputMaybe<Scalars['String']>
  street?: InputMaybe<Scalars['String']>
  uid?: InputMaybe<Scalars['String']>
  year?: InputMaybe<Scalars['String']>
  zip?: InputMaybe<Scalars['String']>
}

export type QueryAssetArgs = {
  id?: InputMaybe<Scalars['ID']>
}

export type QueryBestMatchAssetsArgs = {
  channelType?: InputMaybe<ChannelType>
  userId?: InputMaybe<Scalars['String']>
}

export type QueryBrandLookColorArgs = {
  id: Scalars['ID']
}

export type QueryBrandLookColorsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  created?: InputMaybe<Scalars['DateTime']>
  first?: InputMaybe<Scalars['Int']>
  isDefault?: InputMaybe<Scalars['Boolean']>
  last?: InputMaybe<Scalars['Int']>
  name?: InputMaybe<Scalars['String']>
  offset?: InputMaybe<Scalars['Int']>
  primary?: InputMaybe<Scalars['String']>
  secondary?: InputMaybe<Scalars['String']>
  status?: InputMaybe<ColorStatus>
}

export type QueryBrandLookFontArgs = {
  id: Scalars['ID']
}

export type QueryBrandLookFontsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  created?: InputMaybe<Scalars['DateTime']>
  first?: InputMaybe<Scalars['Int']>
  isDefault?: InputMaybe<Scalars['Boolean']>
  last?: InputMaybe<Scalars['Int']>
  name?: InputMaybe<Scalars['String']>
  offset?: InputMaybe<Scalars['Int']>
  status?: InputMaybe<FontStatus>
  weight?: InputMaybe<Scalars['Int']>
}

export type QueryBrandLookSelectionArgs = {
  id: Scalars['ID']
}

export type QueryBrandLookSelectionHistoryArgs = {
  id: Scalars['ID']
}

export type QueryBrandLookSelectionsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  color?: InputMaybe<Scalars['ID']>
  created?: InputMaybe<Scalars['DateTime']>
  email?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  font?: InputMaybe<Scalars['ID']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  user?: InputMaybe<Scalars['ID']>
}

export type QueryBrandLookSelectionsHistoryArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  color?: InputMaybe<Scalars['ID']>
  created?: InputMaybe<Scalars['DateTime']>
  created_Gt?: InputMaybe<Scalars['DateTime']>
  created_Gte?: InputMaybe<Scalars['DateTime']>
  created_Lt?: InputMaybe<Scalars['DateTime']>
  created_Lte?: InputMaybe<Scalars['DateTime']>
  email?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  font?: InputMaybe<Scalars['ID']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  user?: InputMaybe<Scalars['ID']>
}

export type QueryCampaignArgs = {
  id?: InputMaybe<Scalars['ID']>
}

export type QueryCampaignsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  customer?: InputMaybe<Scalars['String']>
  endDate?: InputMaybe<Scalars['DateTime']>
  first?: InputMaybe<Scalars['Int']>
  id?: InputMaybe<Scalars['ID']>
  isDeleted?: InputMaybe<Scalars['Boolean']>
  last?: InputMaybe<Scalars['Int']>
  name?: InputMaybe<Scalars['String']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Scalars['String']>
  orderId?: InputMaybe<Scalars['String']>
  searchFilter?: InputMaybe<Scalars['String']>
  startDate?: InputMaybe<Scalars['DateTime']>
  status?: InputMaybe<CampaignStatus>
  totalClicks?: InputMaybe<Scalars['Int']>
  totalViews?: InputMaybe<Scalars['Int']>
  type?: InputMaybe<CampaignType>
  utm?: InputMaybe<Scalars['String']>
}

export type QueryCitiesZipArgs = {
  country?: InputMaybe<Scalars['String']>
  query?: InputMaybe<Scalars['String']>
}

export type QueryCityLocationArgs = {
  id: Scalars['ID']
}

export type QueryCityLocationsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  city?: InputMaybe<Scalars['String']>
  country?: InputMaybe<InstitutesCityLocationCountryChoices>
  first?: InputMaybe<Scalars['Int']>
  id?: InputMaybe<Scalars['ID']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  region?: InputMaybe<Scalars['String']>
  zip?: InputMaybe<Scalars['String']>
}

export type QueryCollagesAlbumArgs = {
  id: Scalars['ID']
}

export type QueryCollagesAlbumSuggestedNamesArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  marketProfile?: InputMaybe<Scalars['ID']>
  offset?: InputMaybe<Scalars['Int']>
}

export type QueryCollagesAlbumsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  moduleInstance?: InputMaybe<Scalars['ID']>
  offset?: InputMaybe<Scalars['Int']>
  userGroup?: InputMaybe<Scalars['ID']>
}

export type QueryCollagesPhotosArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  collagesAlbum?: InputMaybe<Scalars['ID']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
}

export type QueryColorArgs = {
  id: Scalars['ID']
}

export type QueryColorsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
}

export type QueryCustomListArgs = {
  id: Scalars['ID']
}

export type QueryCustomListOptionsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  customList?: InputMaybe<Scalars['ID']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
}

export type QueryCustomListsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  moduleInstance?: InputMaybe<Scalars['ID']>
  offset?: InputMaybe<Scalars['Int']>
}

export type QueryCustomPagesInstanceArgs = {
  id: Scalars['ID']
}

export type QueryCustomPagesInstancesArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  moduleInstance?: InputMaybe<Scalars['ID']>
  offset?: InputMaybe<Scalars['Int']>
  userGroup: Scalars['String']
}

export type QueryCustomPagesInstancesResultArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  moduleInstance: Scalars['String']
  offset?: InputMaybe<Scalars['Int']>
}

export type QueryCustomPagesSetupArgs = {
  id: Scalars['ID']
}

export type QueryDataExportArgs = {
  id: Scalars['ID']
}

export type QueryDataExportsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  group?: InputMaybe<Scalars['ID']>
  isodatetime?: InputMaybe<Scalars['String']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
}

export type QueryDefaultAvatarArgs = {
  id: Scalars['ID']
}

export type QueryDefaultAvatarsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
}

export type QueryEventArgs = {
  id: Scalars['ID']
}

export type QueryEventsArgs = {
  after?: InputMaybe<Scalars['String']>
  authorType?: InputMaybe<EventsEventAuthorTypeChoices>
  before?: InputMaybe<Scalars['String']>
  endDate?: InputMaybe<Scalars['Date']>
  eventType?: InputMaybe<EventType>
  first?: InputMaybe<Scalars['Int']>
  fromDate?: InputMaybe<Scalars['Date']>
  isJoinable?: InputMaybe<Scalars['Boolean']>
  isPublic?: InputMaybe<Scalars['Boolean']>
  last?: InputMaybe<Scalars['Int']>
  locationContains?: InputMaybe<Scalars['String']>
  offset?: InputMaybe<Scalars['Int']>
  radius?: InputMaybe<Scalars['Decimal']>
  startDate?: InputMaybe<Scalars['Date']>
  titleContains?: InputMaybe<Scalars['String']>
  toDate?: InputMaybe<Scalars['Date']>
}

export type QueryExternalEventAttendeesArgs = {
  after?: InputMaybe<Scalars['String']>
  attendee?: InputMaybe<Scalars['ID']>
  before?: InputMaybe<Scalars['String']>
  eventId?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
}

export type QueryFindDuplicateInstitutesArgs = {
  customSearch?: InputMaybe<Scalars['String']>
  name?: InputMaybe<Scalars['String']>
  street?: InputMaybe<Scalars['String']>
  website?: InputMaybe<Scalars['String']>
}

export type QueryGenerateFreshUrlArgs = {
  url?: InputMaybe<Scalars['String']>
}

export type QueryGraduationTypeArgs = {
  id: Scalars['ID']
}

export type QueryGraduationTypesArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  id?: InputMaybe<Scalars['String']>
  last?: InputMaybe<Scalars['Int']>
  name?: InputMaybe<Scalars['String']>
  offset?: InputMaybe<Scalars['Int']>
}

export type QueryGraduationTypesCountryArgs = {
  country?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['String']>
  name?: InputMaybe<Scalars['String']>
}

export type QueryGroupArgs = {
  id: Scalars['ID']
}

export type QueryGroupIconArgs = {
  id: Scalars['ID']
}

export type QueryGroupIconsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
}

export type QueryGroupsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  institute?: InputMaybe<Scalars['ID']>
  last?: InputMaybe<Scalars['Int']>
  marketProfile?: InputMaybe<Scalars['ID']>
  name?: InputMaybe<Scalars['String']>
  offset?: InputMaybe<Scalars['Int']>
  type?: InputMaybe<Scalars['ID']>
  year?: InputMaybe<Scalars['Int']>
}

export type QueryIconArgs = {
  id: Scalars['ID']
}

export type QueryIconsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
}

export type QueryInactiveGroupsArgs = {
  filter?: InputMaybe<DashboardFilterInput>
}

export type QueryInstituteArgs = {
  id: Scalars['ID']
}

export type QueryInstituteRequestArgs = {
  id: Scalars['ID']
}

export type QueryInstituteRequestsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  country?: InputMaybe<InstitutesInstituteRequestCountryChoices>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  name?: InputMaybe<Scalars['String']>
  offset?: InputMaybe<Scalars['Int']>
  status?: InputMaybe<Status>
  street?: InputMaybe<Scalars['String']>
  type?: InputMaybe<Scalars['ID']>
  website?: InputMaybe<Scalars['String']>
  zip?: InputMaybe<Scalars['String']>
}

export type QueryInstituteTypeArgs = {
  id: Scalars['ID']
}

export type QueryInstituteTypesArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  country?: InputMaybe<InstitutesInstituteCountryChoices>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  name?: InputMaybe<Scalars['String']>
  offset?: InputMaybe<Scalars['Int']>
}

export type QueryInstitutesArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  city?: InputMaybe<Scalars['String']>
  country?: InputMaybe<InstitutesInstituteCountryChoices>
  first?: InputMaybe<Scalars['Int']>
  id?: InputMaybe<Scalars['String']>
  internalId?: InputMaybe<Scalars['String']>
  isPrivate?: InputMaybe<Scalars['Boolean']>
  last?: InputMaybe<Scalars['Int']>
  name?: InputMaybe<Scalars['String']>
  offset?: InputMaybe<Scalars['Int']>
  street?: InputMaybe<Scalars['String']>
  type?: InputMaybe<Scalars['ID']>
  website?: InputMaybe<Scalars['String']>
  zip?: InputMaybe<Scalars['String']>
}

export type QueryInstitutesTargetingArgs = {
  targetingFilters?: InputMaybe<
    Array<InputMaybe<TargetingFilterInput>>
  >
}

export type QueryInstitutesWithOneGroupArgs = {
  filter?: InputMaybe<DashboardFilterInput>
}

export type QueryKpisArgs = {
  filter?: InputMaybe<DashboardFilterInput>
}

export type QueryLeaderboardUsersArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  user?: InputMaybe<Scalars['ID']>
}

export type QueryMarketProfileArgs = {
  id: Scalars['ID']
}

export type QueryMarketProfilesArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
}

export type QueryModuleArgs = {
  id: Scalars['ID']
}

export type QueryModuleInstanceArgs = {
  id: Scalars['ID']
}

export type QueryModuleInstancesArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  group?: InputMaybe<Scalars['ID']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
}

export type QueryModulesArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
}

export type QueryNotificationArgs = {
  id: Scalars['ID']
}

export type QueryNotificationsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
}

export type QueryPollsArgs = {
  after?: InputMaybe<Scalars['String']>
  allowComments?: InputMaybe<Scalars['Boolean']>
  allowRevote?: InputMaybe<Scalars['Boolean']>
  anonymousVotes?: InputMaybe<Scalars['Boolean']>
  answersAllowed?: InputMaybe<Scalars['Int']>
  before?: InputMaybe<Scalars['String']>
  deadline?: InputMaybe<Scalars['DateTime']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  post?: InputMaybe<Scalars['ID']>
  post_CreatedBy_Group?: InputMaybe<Scalars['ID']>
  post_Team?: InputMaybe<Scalars['ID']>
  privateResults?: InputMaybe<Scalars['Boolean']>
  question?: InputMaybe<Scalars['String']>
  randomizeOptions?: InputMaybe<Scalars['Boolean']>
}

export type QueryPostArgs = {
  id: Scalars['ID']
}

export type QueryPostsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  createdBy?: InputMaybe<Scalars['ID']>
  createdBy_Group?: InputMaybe<Scalars['ID']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  owner?: InputMaybe<Owner>
  team?: InputMaybe<Scalars['ID']>
  text?: InputMaybe<Scalars['String']>
}

export type QueryProfilePageCommentArgs = {
  id: Scalars['ID']
}

export type QueryProfilePageCommentsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  comentatorUserGroup?: InputMaybe<Scalars['ID']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  moduleInstance?: InputMaybe<Scalars['ID']>
  offset?: InputMaybe<Scalars['Int']>
  profileUserGroup?: InputMaybe<Scalars['ID']>
}

export type QueryProfilePagePhotoArgs = {
  id: Scalars['ID']
}

export type QueryProfilePagePhotoCategoriesArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  moduleInstance?: InputMaybe<Scalars['ID']>
  offset?: InputMaybe<Scalars['Int']>
}

export type QueryProfilePagePhotoCategoryArgs = {
  id: Scalars['ID']
}

export type QueryProfilePagePhotoCategorySuggestedNamesArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  marketProfile?: InputMaybe<Scalars['ID']>
  offset?: InputMaybe<Scalars['Int']>
}

export type QueryProfilePagePhotosArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  profilePagePhotoCategory?: InputMaybe<Scalars['ID']>
  userGroup?: InputMaybe<Scalars['ID']>
}

export type QueryProfilePageQuestionArgs = {
  id: Scalars['ID']
}

export type QueryProfilePageQuestionsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  moduleInstance?: InputMaybe<Scalars['ID']>
  offset?: InputMaybe<Scalars['Int']>
}

export type QueryProfilePageSuggestedQuestionArgs = {
  id: Scalars['ID']
}

export type QueryProfilePageSuggestedQuestionsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
}

export type QueryQuotesInstanceArgs = {
  id: Scalars['ID']
}

export type QueryQuotesInstancesArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  moduleInstance?: InputMaybe<Scalars['ID']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Scalars['String']>
  quote?: InputMaybe<Scalars['String']>
  userGroup?: InputMaybe<Scalars['ID']>
}

export type QueryQuotesResultArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  moduleInstance?: InputMaybe<Scalars['ID']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Scalars['String']>
  quote?: InputMaybe<Scalars['String']>
}

export type QueryRankingsQuestionArgs = {
  id: Scalars['ID']
}

export type QueryRankingsQuestionsArgs = {
  after?: InputMaybe<Scalars['String']>
  allowedVotes?: InputMaybe<AllowedVoteType>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  moduleInstance?: InputMaybe<Scalars['ID']>
  offset?: InputMaybe<Scalars['Int']>
  onlyOppositeGender?: InputMaybe<Scalars['Boolean']>
  orderBy?: InputMaybe<Scalars['String']>
}

export type QueryRegionsArgs = {
  country?: InputMaybe<Scalars['String']>
}

export type QueryReportsInstanceArgs = {
  id: Scalars['ID']
}

export type QueryReportsInstancesArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  moduleInstance?: InputMaybe<Scalars['ID']>
  offset?: InputMaybe<Scalars['Int']>
}

export type QuerySignupsArgs = {
  filter?: InputMaybe<DashboardFilterInput>
}

export type QueryStatisticsArgs = {
  filter?: InputMaybe<DashboardFilterInput>
}

export type QueryTeamArgs = {
  id: Scalars['ID']
}

export type QueryTeamsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  color?: InputMaybe<Scalars['ID']>
  first?: InputMaybe<Scalars['Int']>
  group?: InputMaybe<Scalars['ID']>
  icon?: InputMaybe<Scalars['ID']>
  last?: InputMaybe<Scalars['Int']>
  name?: InputMaybe<Scalars['String']>
  offset?: InputMaybe<Scalars['Int']>
  userGroupId?: InputMaybe<Scalars['String']>
}

export type QueryUserArgs = {
  id?: InputMaybe<Scalars['ID']>
}

export type QueryUserExistsArgs = {
  email: Scalars['String']
  phone: Scalars['String']
  phoneCountryCode: Scalars['String']
}

export type QueryUserGroupArgs = {
  id: Scalars['ID']
}

export type QueryUserGroupsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  group?: InputMaybe<Scalars['ID']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  role?: InputMaybe<GroupsUserGroupRoleChoices>
  status?: InputMaybe<GroupsUserGroupStatusChoices>
  user?: InputMaybe<Scalars['ID']>
}

export type QueryUserTeamArgs = {
  id: Scalars['ID']
}

export type QueryUsersArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  email?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  firstName?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['ID']>
  isAgent?: InputMaybe<Scalars['Boolean']>
  isStaff?: InputMaybe<Scalars['Boolean']>
  isSuperuser?: InputMaybe<Scalars['Boolean']>
  last?: InputMaybe<Scalars['Int']>
  lastName?: InputMaybe<Scalars['String']>
  offset?: InputMaybe<Scalars['Int']>
  searchNameEmail?: InputMaybe<Scalars['String']>
}

export type QueryUsersWithoutGroupArgs = {
  filter?: InputMaybe<DashboardFilterInput>
}

export type QuotesInstanceLikeNode = Node & {
  __typename?: 'QuotesInstanceLikeNode'
  created: Scalars['DateTime']
  /** The ID of the object */
  id: Scalars['ID']
  modified: Scalars['DateTime']
  quotesInstance?: Maybe<QuotesInstanceNode>
  userGroup?: Maybe<UserGroupNode>
}

export type QuotesInstanceLikeNodeConnection = {
  __typename?: 'QuotesInstanceLikeNodeConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<QuotesInstanceLikeNodeEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
}

/** A Relay edge containing a `QuotesInstanceLikeNode` and its cursor. */
export type QuotesInstanceLikeNodeEdge = {
  __typename?: 'QuotesInstanceLikeNodeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node?: Maybe<QuotesInstanceLikeNode>
}

export type QuotesInstanceNode = Node & {
  __typename?: 'QuotesInstanceNode'
  /** This refers to if the quotes setup was set as anonymous when the quote was written. */
  anonymous?: Maybe<Scalars['Boolean']>
  created: Scalars['DateTime']
  /** The ID of the object */
  id: Scalars['ID']
  modified: Scalars['DateTime']
  moduleInstance: ModuleInstanceNode
  quote: Scalars['String']
  quotesInstanceLikes?: Maybe<QuotesInstanceLikeNodeConnection>
  quotesInstanceLikesCount?: Maybe<Scalars['Int']>
  userGroup: UserGroupNode
}

export type QuotesInstanceNodeQuotesInstanceLikesArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order?: InputMaybe<Scalars['String']>
  userGroup?: InputMaybe<Scalars['ID']>
}

export type QuotesInstanceNodeConnection = {
  __typename?: 'QuotesInstanceNodeConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<QuotesInstanceNodeEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
}

/** A Relay edge containing a `QuotesInstanceNode` and its cursor. */
export type QuotesInstanceNodeEdge = {
  __typename?: 'QuotesInstanceNodeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node?: Maybe<QuotesInstanceNode>
}

export type QuotesSetupNode = Node & {
  __typename?: 'QuotesSetupNode'
  anonymous: Scalars['Boolean']
  created: Scalars['DateTime']
  /** The ID of the object */
  id: Scalars['ID']
  maxChars: Scalars['Int']
  modified: Scalars['DateTime']
  moduleInstance: ModuleInstanceNode
  public: Scalars['Boolean']
  quotesCount?: Maybe<Scalars['Int']>
}

export type RankingsAnswerNode = Node & {
  __typename?: 'RankingsAnswerNode'
  created: Scalars['DateTime']
  /** The ID of the object */
  id: Scalars['ID']
  modified: Scalars['DateTime']
  rankingsQuestion: RankingsQuestionNode
  votee1UserGroup?: Maybe<UserGroupNode>
  votee2UserGroup?: Maybe<UserGroupNode>
  voteeListOption?: Maybe<CustomListOptionNode>
  voterUserGroup: UserGroupNode
}

export type RankingsAnswerNodeConnection = {
  __typename?: 'RankingsAnswerNodeConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<RankingsAnswerNodeEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
}

/** A Relay edge containing a `RankingsAnswerNode` and its cursor. */
export type RankingsAnswerNodeEdge = {
  __typename?: 'RankingsAnswerNodeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node?: Maybe<RankingsAnswerNode>
}

export type RankingsQuestionNode = Node & {
  __typename?: 'RankingsQuestionNode'
  allowedVotes?: Maybe<AllowedVoteType>
  answerType?: Maybe<AnswerType>
  created: Scalars['DateTime']
  customList?: Maybe<CustomListNode>
  /** The ID of the object */
  id: Scalars['ID']
  modified: Scalars['DateTime']
  moduleInstance: ModuleInstanceNode
  onlyOppositeGender?: Maybe<Scalars['Boolean']>
  question: Scalars['String']
  rankingsAnswers?: Maybe<RankingsAnswerNodeConnection>
  results?: Maybe<RankingsQuestionResultsNode>
}

export type RankingsQuestionNodeRankingsAnswersArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  rankingsQuestion?: InputMaybe<Scalars['ID']>
  voterUserGroup?: InputMaybe<Scalars['ID']>
}

export type RankingsQuestionNodeConnection = {
  __typename?: 'RankingsQuestionNodeConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<RankingsQuestionNodeEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
}

/** A Relay edge containing a `RankingsQuestionNode` and its cursor. */
export type RankingsQuestionNodeEdge = {
  __typename?: 'RankingsQuestionNodeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node?: Maybe<RankingsQuestionNode>
}

export type RankingsQuestionResultNode = {
  __typename?: 'RankingsQuestionResultNode'
  percentage?: Maybe<Scalars['Float']>
  votee1UserGroup?: Maybe<UserGroupNode>
  votee2UserGroup?: Maybe<UserGroupNode>
  voteeListOption?: Maybe<CustomListOptionNode>
  votesNumber?: Maybe<Scalars['Int']>
}

export type RankingsQuestionResultsNode = {
  __typename?: 'RankingsQuestionResultsNode'
  alreadyVoted?: Maybe<Scalars['Int']>
  options?: Maybe<Array<Maybe<RankingsQuestionResultNode>>>
  possibleVoters?: Maybe<Scalars['Int']>
}

export type RankingsSetupNode = Node & {
  __typename?: 'RankingsSetupNode'
  created: Scalars['DateTime']
  /** The ID of the object */
  id: Scalars['ID']
  modified: Scalars['DateTime']
  moduleInstance: ModuleInstanceNode
  rankingsCount?: Maybe<Scalars['Int']>
  resultsTeam?: Maybe<TeamNode>
}

export type RefreshGroupTokenInput = {
  clientMutationId?: InputMaybe<Scalars['String']>
  id: Scalars['ID']
}

export type RefreshGroupTokenPayload = {
  __typename?: 'RefreshGroupTokenPayload'
  clientMutationId?: Maybe<Scalars['String']>
  group?: Maybe<GroupNode>
}

export type RefreshInput = {
  clientMutationId?: InputMaybe<Scalars['String']>
  refreshToken?: InputMaybe<Scalars['String']>
}

export type RefreshPayload = {
  __typename?: 'RefreshPayload'
  clientMutationId?: Maybe<Scalars['String']>
  payload: Scalars['GenericScalar']
  refreshExpiresIn: Scalars['Int']
  refreshToken: Scalars['String']
  token: Scalars['String']
}

export type RemoveExternalEventInput = {
  clientMutationId?: InputMaybe<Scalars['String']>
  eventId: Scalars['ID']
}

export type RemoveExternalEventPayload = {
  __typename?: 'RemoveExternalEventPayload'
  clientMutationId?: Maybe<Scalars['String']>
  externalEvent?: Maybe<ExternalEventAttendeeNode>
  success?: Maybe<Scalars['Boolean']>
}

export type RemoveImageFromPostInput = {
  clientMutationId?: InputMaybe<Scalars['String']>
  id: Scalars['ID']
  imageName: Scalars['String']
}

export type RemoveImageFromPostPayload = {
  __typename?: 'RemoveImageFromPostPayload'
  clientMutationId?: Maybe<Scalars['String']>
  post?: Maybe<PostNode>
}

export type RemoveLikeFromCollagesPhotoInput = {
  clientMutationId?: InputMaybe<Scalars['String']>
  id: Scalars['ID']
}

export type RemoveLikeFromCollagesPhotoPayload = {
  __typename?: 'RemoveLikeFromCollagesPhotoPayload'
  clientMutationId?: Maybe<Scalars['String']>
  collagesPhoto?: Maybe<CollagesPhotoNode>
}

export type RemoveLikeFromCommentInput = {
  clientMutationId?: InputMaybe<Scalars['String']>
  id: Scalars['ID']
}

export type RemoveLikeFromCommentPayload = {
  __typename?: 'RemoveLikeFromCommentPayload'
  clientMutationId?: Maybe<Scalars['String']>
  post?: Maybe<PostNode>
}

export type RemoveLikeFromPostInput = {
  clientMutationId?: InputMaybe<Scalars['String']>
  id: Scalars['ID']
}

export type RemoveLikeFromPostPayload = {
  __typename?: 'RemoveLikeFromPostPayload'
  clientMutationId?: Maybe<Scalars['String']>
  post?: Maybe<PostNode>
}

export type RemoveLikeFromQuotesInstanceInput = {
  clientMutationId?: InputMaybe<Scalars['String']>
  id: Scalars['ID']
}

export type RemoveLikeFromQuotesInstancePayload = {
  __typename?: 'RemoveLikeFromQuotesInstancePayload'
  clientMutationId?: Maybe<Scalars['String']>
  quotesInstance?: Maybe<QuotesInstanceNode>
}

export type RemovePhotoFromCollagesAlbumInput = {
  clientMutationId?: InputMaybe<Scalars['String']>
  id: Scalars['ID']
}

export type RemovePhotoFromCollagesAlbumPayload = {
  __typename?: 'RemovePhotoFromCollagesAlbumPayload'
  clientMutationId?: Maybe<Scalars['String']>
  collagesAlbum?: Maybe<CollagesAlbumNode>
  success?: Maybe<Scalars['Boolean']>
}

export type RemoveUserFromGroupInput = {
  clientMutationId?: InputMaybe<Scalars['String']>
  groupId: Scalars['ID']
  userId: Scalars['ID']
}

export type RemoveUserFromGroupPayload = {
  __typename?: 'RemoveUserFromGroupPayload'
  clientMutationId?: Maybe<Scalars['String']>
  success?: Maybe<Scalars['Boolean']>
  userGroup?: Maybe<UserGroupNode>
}

export type RemoveUserFromTeamInput = {
  clientMutationId?: InputMaybe<Scalars['String']>
  teamId: Scalars['ID']
  userId: Scalars['ID']
}

export type RemoveUserFromTeamPayload = {
  __typename?: 'RemoveUserFromTeamPayload'
  clientMutationId?: Maybe<Scalars['String']>
  success?: Maybe<Scalars['Boolean']>
  userTeam?: Maybe<UserTeamNode>
}

export type ReportMutationInput = {
  clientMutationId?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['ID']>
}

export type ReportMutationPayload = {
  __typename?: 'ReportMutationPayload'
  clientMutationId?: Maybe<Scalars['String']>
  message?: Maybe<Scalars['String']>
  success?: Maybe<Scalars['Boolean']>
}

export type ReportPostContentInput = {
  clientMutationId?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['ID']>
}

export type ReportPostContentPayload = {
  __typename?: 'ReportPostContentPayload'
  clientMutationId?: Maybe<Scalars['String']>
  message?: Maybe<Scalars['String']>
  success?: Maybe<Scalars['Boolean']>
}

export type ReportsInstanceNode = Node & {
  __typename?: 'ReportsInstanceNode'
  created: Scalars['DateTime']
  editTeam?: Maybe<TeamNode>
  /** The ID of the object */
  id: Scalars['ID']
  maxChars: Scalars['Int']
  modified: Scalars['DateTime']
  moduleInstance: ModuleInstanceNode
  text: Scalars['String']
  title: Scalars['String']
  viewTeam?: Maybe<TeamNode>
}

export type ReportsInstanceNodeConnection = {
  __typename?: 'ReportsInstanceNodeConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ReportsInstanceNodeEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
}

/** A Relay edge containing a `ReportsInstanceNode` and its cursor. */
export type ReportsInstanceNodeEdge = {
  __typename?: 'ReportsInstanceNodeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node?: Maybe<ReportsInstanceNode>
}

export type ReportsSetupNode = Node & {
  __typename?: 'ReportsSetupNode'
  allowImages: Scalars['Boolean']
  created: Scalars['DateTime']
  /** The ID of the object */
  id: Scalars['ID']
  modified: Scalars['DateTime']
  moduleInstance: ModuleInstanceNode
  reportsCount?: Maybe<Scalars['Int']>
}

export type RequestMissingInstituteInput = {
  city?: InputMaybe<Scalars['String']>
  clientMutationId?: InputMaybe<Scalars['String']>
  country: Scalars['String']
  id?: InputMaybe<Scalars['ID']>
  name: Scalars['String']
  street?: InputMaybe<Scalars['String']>
  type?: InputMaybe<Scalars['ID']>
  website?: InputMaybe<Scalars['String']>
  zip?: InputMaybe<Scalars['String']>
}

export type RequestMissingInstitutePayload = {
  __typename?: 'RequestMissingInstitutePayload'
  clientMutationId?: Maybe<Scalars['String']>
  errors?: Maybe<Array<Maybe<ErrorType>>>
  instituteRequest?: Maybe<InstituteRequestNode>
  message?: Maybe<Scalars['String']>
  responseCode?: Maybe<Scalars['Int']>
}

export type ResetPasswordInput = {
  clientMutationId?: InputMaybe<Scalars['String']>
  password: Scalars['String']
  passwordConfirmation: Scalars['String']
  token: Scalars['String']
}

export type ResetPasswordPayload = {
  __typename?: 'ResetPasswordPayload'
  clientMutationId?: Maybe<Scalars['String']>
  errors?: Maybe<Array<Maybe<ErrorType>>>
  user?: Maybe<UserNode>
}

export type RevokeInput = {
  clientMutationId?: InputMaybe<Scalars['String']>
  refreshToken?: InputMaybe<Scalars['String']>
}

export type RevokePayload = {
  __typename?: 'RevokePayload'
  clientMutationId?: Maybe<Scalars['String']>
  revoked: Scalars['Int']
}

export type SaveExternalEventInput = {
  clientMutationId?: InputMaybe<Scalars['String']>
  eventId: Scalars['ID']
}

export type SaveExternalEventPayload = {
  __typename?: 'SaveExternalEventPayload'
  clientMutationId?: Maybe<Scalars['String']>
  externalEvent?: Maybe<ExternalEventAttendeeNode>
  success?: Maybe<Scalars['Boolean']>
}

export type SearchGroupType = Node & {
  __typename?: 'SearchGroupType'
  avatar?: Maybe<Scalars['String']>
  graduationYear: Scalars['String']
  id: Scalars['ID']
  institute: SearchInstituteType
  name: Scalars['String']
  type?: Maybe<Scalars['String']>
}

export type SearchInstituteType = Node & {
  __typename?: 'SearchInstituteType'
  city?: Maybe<Scalars['String']>
  country: Scalars['String']
  id: Scalars['ID']
  internalId: Scalars['String']
  name: Scalars['String']
  type?: Maybe<Scalars['String']>
  zip?: Maybe<Scalars['String']>
}

export type SearchStructure3 = {
  __typename?: 'SearchStructure3'
  groups: Array<Maybe<SearchGroupType>>
  institutes: Array<Maybe<SearchInstituteType>>
  users: Array<Maybe<SearchUserType>>
}

export type SearchUserType = Node & {
  __typename?: 'SearchUserType'
  avatar?: Maybe<Scalars['String']>
  email: Scalars['String']
  firstName: Scalars['String']
  groups?: Maybe<Array<Maybe<SearchGroupType>>>
  id: Scalars['ID']
  lastName: Scalars['String']
  phone?: Maybe<Scalars['String']>
  phoneCountryCode?: Maybe<Scalars['String']>
}

export type SetBrandLookInput = {
  clientMutationId?: InputMaybe<Scalars['String']>
  colorId: Scalars['ID']
  fontId: Scalars['ID']
}

export type SetBrandLookPayload = {
  __typename?: 'SetBrandLookPayload'
  brandLookSelection?: Maybe<BrandLookSelectionNode>
  clientMutationId?: Maybe<Scalars['String']>
}

export type SetEventBannerInput = {
  bannerImage?: InputMaybe<Scalars['Upload']>
  clientMutationId?: InputMaybe<Scalars['String']>
  id: Scalars['ID']
}

export type SetEventBannerPayload = {
  __typename?: 'SetEventBannerPayload'
  clientMutationId?: Maybe<Scalars['String']>
  event?: Maybe<EventNode>
}

export type SetGroupAvatarInput = {
  avatar?: InputMaybe<Scalars['Upload']>
  clientMutationId?: InputMaybe<Scalars['String']>
  iconId?: InputMaybe<Scalars['ID']>
  id: Scalars['ID']
}

export type SetGroupAvatarPayload = {
  __typename?: 'SetGroupAvatarPayload'
  clientMutationId?: Maybe<Scalars['String']>
  group?: Maybe<GroupNode>
}

export type SetUserGroupAvatarInput = {
  avatar?: InputMaybe<Scalars['Upload']>
  clientMutationId?: InputMaybe<Scalars['String']>
  defaultAvatarId?: InputMaybe<Scalars['ID']>
  userGroupId: Scalars['ID']
}

export type SetUserGroupAvatarPayload = {
  __typename?: 'SetUserGroupAvatarPayload'
  clientMutationId?: Maybe<Scalars['String']>
  userGroup?: Maybe<UserGroupNode>
}

export type StatisticsNode = {
  __typename?: 'StatisticsNode'
  activeGroupsCount?: Maybe<Scalars['Int']>
  inactiveGroupsCount?: Maybe<Scalars['Int']>
  institutesCount?: Maybe<Scalars['Int']>
  usersCount?: Maybe<Scalars['Int']>
}

/** An enumeration. */
export enum Status {
  Accepted = 'ACCEPTED',
  Pending = 'PENDING',
  Rejected = 'REJECTED'
}

export type TargetingFilterGroupInput = {
  graduationType?: InputMaybe<Scalars['ID']>
  graduationYear?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>
  groupIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>
  instituteType?: InputMaybe<Scalars['ID']>
  members?: InputMaybe<TargetingFilterGroupMembersInput>
  teams?: InputMaybe<Array<InputMaybe<TeamType>>>
}

export type TargetingFilterGroupMembersInput = {
  operator?: InputMaybe<TargetingFilterGroupMembersOperation>
  value?: InputMaybe<Scalars['Int']>
}

/** An enumeration. */
export enum TargetingFilterGroupMembersOperation {
  Below = 'BELOW',
  Exactly = 'EXACTLY',
  LargerThan = 'LARGER_THAN'
}

export type TargetingFilterGroupMembersType = {
  __typename?: 'TargetingFilterGroupMembersType'
  operator?: Maybe<TargetingFilterGroupMembersOperation>
  value?: Maybe<Scalars['Int']>
}

export type TargetingFilterGroupType = {
  __typename?: 'TargetingFilterGroupType'
  graduationType?: Maybe<Scalars['ID']>
  graduationYear?: Maybe<Array<Maybe<Scalars['Int']>>>
  groupIds?: Maybe<Array<Maybe<Scalars['ID']>>>
  instituteType?: Maybe<Scalars['ID']>
  members?: Maybe<TargetingFilterGroupMembersType>
  teams?: Maybe<Array<Maybe<TeamType>>>
}

export type TargetingFilterInput = {
  group?: InputMaybe<TargetingFilterGroupInput>
  location?: InputMaybe<TargetingFilterLocationInput>
  operator: Scalars['String']
  type: Scalars['String']
  user?: InputMaybe<TargetingFilterUserInput>
}

export type TargetingFilterLocationInput = {
  city?: InputMaybe<Scalars['String']>
  country?: InputMaybe<Scalars['String']>
  radius?: InputMaybe<Scalars['Int']>
  region?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  zip?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>
}

export type TargetingFilterLocationType = {
  __typename?: 'TargetingFilterLocationType'
  city?: Maybe<Scalars['String']>
  country?: Maybe<Scalars['String']>
  radius?: Maybe<Scalars['Int']>
  region?: Maybe<Array<Maybe<Scalars['String']>>>
  zip?: Maybe<Array<Maybe<Scalars['Int']>>>
}

export type TargetingFilterType = {
  __typename?: 'TargetingFilterType'
  group?: Maybe<TargetingFilterGroupType>
  location?: Maybe<TargetingFilterLocationType>
  operator: Scalars['String']
  type: Scalars['String']
  user?: Maybe<TargetingFilterUserType>
}

/** An enumeration. */
export enum TargetingFilterUserGenderOperation {
  Diverse = 'DIVERSE',
  Female = 'FEMALE',
  Male = 'MALE',
  Notset = 'NOTSET'
}

export type TargetingFilterUserInput = {
  gender?: InputMaybe<TargetingFilterUserGenderOperation>
  registration?: InputMaybe<TargetingFilterUserRegistrationInput>
  userIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>
}

export type TargetingFilterUserRegistrationInput = {
  date?: InputMaybe<Scalars['String']>
  operator?: InputMaybe<TargetingFilterUserRegistrationOperation>
}

/** An enumeration. */
export enum TargetingFilterUserRegistrationOperation {
  Before = 'BEFORE',
  Exactly = 'EXACTLY',
  LaterThan = 'LATER_THAN'
}

export type TargetingFilterUserRegistrationType = {
  __typename?: 'TargetingFilterUserRegistrationType'
  date?: Maybe<Scalars['String']>
  operator?: Maybe<TargetingFilterUserRegistrationOperation>
}

export type TargetingFilterUserType = {
  __typename?: 'TargetingFilterUserType'
  gender?: Maybe<TargetingFilterUserGenderOperation>
  registration?: Maybe<TargetingFilterUserRegistrationType>
  userIds?: Maybe<Array<Maybe<Scalars['ID']>>>
}

export type TargetingNode = {
  __typename?: 'TargetingNode'
  activeGroupsCount?: Maybe<Scalars['Int']>
  inactiveGroupsCount?: Maybe<Scalars['Int']>
  institutes?: Maybe<Array<Maybe<InstituteTargetingNode>>>
  usersCount?: Maybe<Scalars['Int']>
}

export type TeamNode = Node & {
  __typename?: 'TeamNode'
  approvalSetting?: Maybe<ApprovalSetting>
  avatar?: Maybe<Scalars['String']>
  color?: Maybe<ColorNode>
  created: Scalars['DateTime']
  group: GroupNode
  icon?: Maybe<IconNode>
  /** The ID of the object */
  id: Scalars['ID']
  isHidden: Scalars['Boolean']
  modified: Scalars['DateTime']
  name: Scalars['String']
  sampleMembers?: Maybe<Array<Maybe<UserTeamNode>>>
  type?: Maybe<TeamType>
  users?: Maybe<Array<Maybe<UserTeamNode>>>
  usersCount?: Maybe<Scalars['Int']>
}

export type TeamNodeConnection = {
  __typename?: 'TeamNodeConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<TeamNodeEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
}

/** A Relay edge containing a `TeamNode` and its cursor. */
export type TeamNodeEdge = {
  __typename?: 'TeamNodeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node?: Maybe<TeamNode>
}

/** An enumeration. */
export enum TeamType {
  Clothing = 'CLOTHING',
  Core = 'CORE',
  Finance = 'FINANCE',
  Other = 'OTHER',
  Prom = 'PROM',
  Yearbook = 'YEARBOOK'
}

export type TermsAcceptanceNode = Node & {
  __typename?: 'TermsAcceptanceNode'
  agreed: Scalars['Boolean']
  created: Scalars['DateTime']
  /** The ID of the object */
  id: Scalars['ID']
  ip: Scalars['String']
  modified: Scalars['DateTime']
  user: UserNode
  version: Scalars['String']
}

export type ToggleExternalEventSaveInput = {
  clientMutationId?: InputMaybe<Scalars['String']>
  eventId: Scalars['ID']
}

export type ToggleExternalEventSavePayload = {
  __typename?: 'ToggleExternalEventSavePayload'
  clientMutationId?: Maybe<Scalars['String']>
  externalEvent?: Maybe<ExternalEventAttendeeNode>
  success?: Maybe<Scalars['Boolean']>
}

export type ToggleIsActiveGroupInput = {
  clientMutationId?: InputMaybe<Scalars['String']>
  groupId: Scalars['ID']
  isActive: Scalars['Boolean']
}

export type ToggleIsActiveGroupPayload = {
  __typename?: 'ToggleIsActiveGroupPayload'
  clientMutationId?: Maybe<Scalars['String']>
  group?: Maybe<GroupNode>
}

export type UpdateCollagesAlbumInput = {
  clientMutationId?: InputMaybe<Scalars['String']>
  id: Scalars['ID']
  name: Scalars['String']
}

export type UpdateCollagesAlbumPayload = {
  __typename?: 'UpdateCollagesAlbumPayload'
  clientMutationId?: Maybe<Scalars['String']>
  collagesAlbum?: Maybe<CollagesAlbumNode>
  errors?: Maybe<Array<Maybe<ErrorType>>>
}

export type UpdateCollagesSetupInput = {
  addPhotosTeam?: InputMaybe<Scalars['ID']>
  clientMutationId?: InputMaybe<Scalars['String']>
  createAlbumsTeam?: InputMaybe<Scalars['ID']>
  dueDate?: InputMaybe<Scalars['DateTime']>
  id: Scalars['ID']
  isActive: Scalars['Boolean']
  viewPhotosTeam?: InputMaybe<Scalars['ID']>
}

export type UpdateCollagesSetupPayload = {
  __typename?: 'UpdateCollagesSetupPayload'
  clientMutationId?: Maybe<Scalars['String']>
  collagesSetup?: Maybe<CollagesSetupNode>
  errors?: Maybe<Array<Maybe<ErrorType>>>
}

export type UpdateCustomListInput = {
  clientMutationId?: InputMaybe<Scalars['String']>
  id: Scalars['ID']
  title: Scalars['String']
}

export type UpdateCustomListOptionInput = {
  clientMutationId?: InputMaybe<Scalars['String']>
  id: Scalars['ID']
  text: Scalars['String']
}

export type UpdateCustomListOptionPayload = {
  __typename?: 'UpdateCustomListOptionPayload'
  clientMutationId?: Maybe<Scalars['String']>
  customListOption?: Maybe<CustomListOptionNode>
}

export type UpdateCustomListPayload = {
  __typename?: 'UpdateCustomListPayload'
  clientMutationId?: Maybe<Scalars['String']>
  customList?: Maybe<CustomListNode>
}

export type UpdateCustomPagesInstanceInput = {
  clientMutationId?: InputMaybe<Scalars['String']>
  /** CustomPagesInstance ID */
  id: Scalars['ID']
  page?: InputMaybe<Scalars['Upload']>
  pageFormat?: InputMaybe<Scalars['String']>
  pageQuality?: InputMaybe<Scalars['String']>
  pageType?: InputMaybe<Scalars['String']>
  title?: InputMaybe<Scalars['String']>
}

export type UpdateCustomPagesInstancePayload = {
  __typename?: 'UpdateCustomPagesInstancePayload'
  clientMutationId?: Maybe<Scalars['String']>
  customPagesInstance?: Maybe<CustomPagesInstanceNode>
}

export type UpdateCustomPagesSetupInput = {
  clientMutationId?: InputMaybe<Scalars['String']>
  createPagesTeam?: InputMaybe<Scalars['ID']>
  dueDate?: InputMaybe<Scalars['DateTime']>
  /** Intro messages to classmates to explain what to upload. */
  guide?: InputMaybe<Scalars['String']>
  id: Scalars['ID']
  isActive: Scalars['Boolean']
  viewPagesTeam?: InputMaybe<Scalars['ID']>
}

export type UpdateCustomPagesSetupPayload = {
  __typename?: 'UpdateCustomPagesSetupPayload'
  clientMutationId?: Maybe<Scalars['String']>
  customPagesSetup?: Maybe<CustomPagesSetupNode>
  errors?: Maybe<Array<Maybe<ErrorType>>>
}

export type UpdateGroupInput = {
  clientMutationId?: InputMaybe<Scalars['String']>
  id: Scalars['ID']
  name: Scalars['String']
  type?: InputMaybe<Scalars['ID']>
  whoCanCreateTeam?: InputMaybe<Scalars['ID']>
  year: Scalars['Int']
}

export type UpdateGroupPayload = {
  __typename?: 'UpdateGroupPayload'
  clientMutationId?: Maybe<Scalars['String']>
  errors?: Maybe<Array<Maybe<ErrorType>>>
  group?: Maybe<GroupNode>
}

export type UpdateMeInput = {
  clientMutationId?: InputMaybe<Scalars['String']>
  communicationLanguage?: InputMaybe<Scalars['String']>
  countryOfResidence?: InputMaybe<Scalars['String']>
  email: Scalars['String']
  firstName: Scalars['String']
  gender?: InputMaybe<Scalars['String']>
  lastName: Scalars['String']
  phone?: InputMaybe<Scalars['String']>
  phoneCountryCode?: InputMaybe<Scalars['String']>
}

export type UpdateMePayload = {
  __typename?: 'UpdateMePayload'
  clientMutationId?: Maybe<Scalars['String']>
  errors?: Maybe<Array<Maybe<ErrorType>>>
  refreshToken?: Maybe<Scalars['String']>
  token?: Maybe<Scalars['String']>
  user?: Maybe<UserNode>
}

export type UpdateModuleInstanceInput = {
  clientMutationId?: InputMaybe<Scalars['String']>
  dueDate?: InputMaybe<Scalars['DateTime']>
  id: Scalars['ID']
  isActive?: InputMaybe<Scalars['Boolean']>
}

export type UpdateModuleInstancePayload = {
  __typename?: 'UpdateModuleInstancePayload'
  clientMutationId?: Maybe<Scalars['String']>
  errors?: Maybe<Array<Maybe<ErrorType>>>
  moduleInstance?: Maybe<ModuleInstanceNode>
}

export type UpdatePasswordInput = {
  clientMutationId?: InputMaybe<Scalars['String']>
  oldPassword: Scalars['String']
  password: Scalars['String']
  passwordConfirmation: Scalars['String']
}

export type UpdatePasswordPayload = {
  __typename?: 'UpdatePasswordPayload'
  clientMutationId?: Maybe<Scalars['String']>
  errors?: Maybe<Array<Maybe<ErrorType>>>
  user?: Maybe<UserNode>
}

export type UpdatePollInput = {
  allowComments?: InputMaybe<Scalars['Boolean']>
  allowRevote?: InputMaybe<Scalars['Boolean']>
  anonymousVotes?: InputMaybe<Scalars['Boolean']>
  answersAllowed: Scalars['Int']
  clientMutationId?: InputMaybe<Scalars['String']>
  deadline: Scalars['DateTime']
  id: Scalars['ID']
  owner: Scalars['String']
  privateResults?: InputMaybe<Scalars['Boolean']>
  question: Scalars['String']
  randomizeOptions?: InputMaybe<Scalars['Boolean']>
}

export type UpdatePollOptionInput = {
  clientMutationId?: InputMaybe<Scalars['String']>
  id: Scalars['ID']
  image?: InputMaybe<Scalars['Upload']>
  text: Scalars['String']
}

export type UpdatePollOptionPayload = {
  __typename?: 'UpdatePollOptionPayload'
  clientMutationId?: Maybe<Scalars['String']>
  pollOption?: Maybe<PollOptionNode>
}

export type UpdatePollPayload = {
  __typename?: 'UpdatePollPayload'
  clientMutationId?: Maybe<Scalars['String']>
  errors?: Maybe<Array<Maybe<ErrorType>>>
  poll?: Maybe<PollNode>
}

export type UpdatePostInput = {
  clientMutationId?: InputMaybe<Scalars['String']>
  id: Scalars['ID']
  owner: Scalars['String']
  text: Scalars['String']
}

export type UpdatePostPayload = {
  __typename?: 'UpdatePostPayload'
  clientMutationId?: Maybe<Scalars['String']>
  errors?: Maybe<Array<Maybe<ErrorType>>>
  post?: Maybe<PostNode>
}

export type UpdatePrewordInstanceInput = {
  clientMutationId?: InputMaybe<Scalars['String']>
  dueDate?: InputMaybe<Scalars['DateTime']>
  id: Scalars['ID']
  isActive: Scalars['Boolean']
  text: Scalars['String']
}

export type UpdatePrewordInstancePayload = {
  __typename?: 'UpdatePrewordInstancePayload'
  clientMutationId?: Maybe<Scalars['String']>
  errors?: Maybe<Array<Maybe<ErrorType>>>
  prewordInstance?: Maybe<PrewordInstanceNode>
}

export type UpdateProfilePageAnswerInput = {
  clientMutationId?: InputMaybe<Scalars['String']>
  id: Scalars['ID']
  profilePageQuestionOption?: InputMaybe<Scalars['ID']>
  text?: InputMaybe<Scalars['String']>
}

export type UpdateProfilePageAnswerPayload = {
  __typename?: 'UpdateProfilePageAnswerPayload'
  clientMutationId?: Maybe<Scalars['String']>
  errors?: Maybe<Array<Maybe<ErrorType>>>
  profilePageAnswer?: Maybe<ProfilePageAnswerNode>
}

export type UpdateProfilePageCommentInput = {
  clientMutationId?: InputMaybe<Scalars['String']>
  id: Scalars['ID']
  text: Scalars['String']
}

export type UpdateProfilePageCommentPayload = {
  __typename?: 'UpdateProfilePageCommentPayload'
  clientMutationId?: Maybe<Scalars['String']>
  errors?: Maybe<Array<Maybe<ErrorType>>>
  profilePageComment?: Maybe<ProfilePageCommentNode>
}

export type UpdateProfilePagePhotoCategoryInput = {
  clientMutationId?: InputMaybe<Scalars['String']>
  id: Scalars['ID']
  name?: InputMaybe<Scalars['String']>
}

export type UpdateProfilePagePhotoCategoryPayload = {
  __typename?: 'UpdateProfilePagePhotoCategoryPayload'
  clientMutationId?: Maybe<Scalars['String']>
  errors?: Maybe<Array<Maybe<ErrorType>>>
  profilePagePhotoCategory?: Maybe<ProfilePagePhotoCategoryNode>
}

export type UpdateProfilePagePhotoInput = {
  clientMutationId?: InputMaybe<Scalars['String']>
  id: Scalars['ID']
  image?: InputMaybe<Scalars['Upload']>
}

export type UpdateProfilePagePhotoPayload = {
  __typename?: 'UpdateProfilePagePhotoPayload'
  clientMutationId?: Maybe<Scalars['String']>
  profilePagePhoto?: Maybe<ProfilePagePhotoNode>
}

export type UpdateProfilePageQuestionInput = {
  clientMutationId?: InputMaybe<Scalars['String']>
  id: Scalars['ID']
  text: Scalars['String']
}

export type UpdateProfilePageQuestionOptionInput = {
  clientMutationId?: InputMaybe<Scalars['String']>
  id: Scalars['ID']
  text: Scalars['String']
}

export type UpdateProfilePageQuestionOptionPayload = {
  __typename?: 'UpdateProfilePageQuestionOptionPayload'
  clientMutationId?: Maybe<Scalars['String']>
  errors?: Maybe<Array<Maybe<ErrorType>>>
  profilePageQuestionOption?: Maybe<ProfilePageQuestionOptionNode>
}

export type UpdateProfilePageQuestionPayload = {
  __typename?: 'UpdateProfilePageQuestionPayload'
  clientMutationId?: Maybe<Scalars['String']>
  errors?: Maybe<Array<Maybe<ErrorType>>>
  profilePageQuestion?: Maybe<ProfilePageQuestionNode>
}

export type UpdateProfilePageSetupInput = {
  anonymousComments?: InputMaybe<Scalars['Boolean']>
  clientMutationId?: InputMaybe<Scalars['String']>
  dueDate?: InputMaybe<Scalars['DateTime']>
  id: Scalars['ID']
  isActive: Scalars['Boolean']
  maxChars: Scalars['Int']
  photoFormat?: InputMaybe<Scalars['String']>
  visibleComments?: InputMaybe<Scalars['Boolean']>
}

export type UpdateProfilePageSetupPayload = {
  __typename?: 'UpdateProfilePageSetupPayload'
  clientMutationId?: Maybe<Scalars['String']>
  errors?: Maybe<Array<Maybe<ErrorType>>>
  profilePageSetup?: Maybe<ProfilePageSetupNode>
}

export type UpdateQuotesInstanceInput = {
  clientMutationId?: InputMaybe<Scalars['String']>
  id: Scalars['ID']
  quote: Scalars['String']
}

export type UpdateQuotesInstancePayload = {
  __typename?: 'UpdateQuotesInstancePayload'
  clientMutationId?: Maybe<Scalars['String']>
  errors?: Maybe<Array<Maybe<ErrorType>>>
  quotesInstance?: Maybe<QuotesInstanceNode>
}

export type UpdateQuotesSetupInput = {
  anonymous?: InputMaybe<Scalars['Boolean']>
  clientMutationId?: InputMaybe<Scalars['String']>
  dueDate?: InputMaybe<Scalars['DateTime']>
  id: Scalars['ID']
  isActive: Scalars['Boolean']
  maxChars: Scalars['Int']
  public?: InputMaybe<Scalars['Boolean']>
}

export type UpdateQuotesSetupPayload = {
  __typename?: 'UpdateQuotesSetupPayload'
  clientMutationId?: Maybe<Scalars['String']>
  errors?: Maybe<Array<Maybe<ErrorType>>>
  quotesSetup?: Maybe<QuotesSetupNode>
}

export type UpdateRankingsAnswerInput = {
  clientMutationId?: InputMaybe<Scalars['String']>
  id: Scalars['ID']
  votee1UserGroup?: InputMaybe<Scalars['ID']>
  votee2UserGroup?: InputMaybe<Scalars['ID']>
  voteeListOption?: InputMaybe<Scalars['ID']>
}

export type UpdateRankingsAnswerPayload = {
  __typename?: 'UpdateRankingsAnswerPayload'
  clientMutationId?: Maybe<Scalars['String']>
  errors?: Maybe<Array<Maybe<ErrorType>>>
  rankingsAnswer?: Maybe<RankingsAnswerNode>
}

export type UpdateRankingsQuestionInput = {
  allowedVotes?: InputMaybe<Scalars['String']>
  answerType: Scalars['String']
  clientMutationId?: InputMaybe<Scalars['String']>
  customList?: InputMaybe<Scalars['ID']>
  id: Scalars['ID']
  onlyOppositeGender?: InputMaybe<Scalars['Boolean']>
  question: Scalars['String']
}

export type UpdateRankingsQuestionPayload = {
  __typename?: 'UpdateRankingsQuestionPayload'
  clientMutationId?: Maybe<Scalars['String']>
  errors?: Maybe<Array<Maybe<ErrorType>>>
  rankingsQuestion?: Maybe<RankingsQuestionNode>
}

export type UpdateRankingsSetupInput = {
  clientMutationId?: InputMaybe<Scalars['String']>
  dueDate?: InputMaybe<Scalars['DateTime']>
  id: Scalars['ID']
  isActive: Scalars['Boolean']
  resultsTeam?: InputMaybe<Scalars['ID']>
}

export type UpdateRankingsSetupPayload = {
  __typename?: 'UpdateRankingsSetupPayload'
  clientMutationId?: Maybe<Scalars['String']>
  errors?: Maybe<Array<Maybe<ErrorType>>>
  rankingsSetup?: Maybe<RankingsSetupNode>
}

export type UpdateReportsInstanceInput = {
  clientMutationId?: InputMaybe<Scalars['String']>
  editTeam?: InputMaybe<Scalars['ID']>
  id: Scalars['ID']
  maxChars: Scalars['Int']
  text: Scalars['String']
  title: Scalars['String']
  viewTeam?: InputMaybe<Scalars['ID']>
}

export type UpdateReportsInstancePayload = {
  __typename?: 'UpdateReportsInstancePayload'
  clientMutationId?: Maybe<Scalars['String']>
  errors?: Maybe<Array<Maybe<ErrorType>>>
  reportsInstance?: Maybe<ReportsInstanceNode>
}

export type UpdateReportsSetupInput = {
  allowImages?: InputMaybe<Scalars['Boolean']>
  clientMutationId?: InputMaybe<Scalars['String']>
  dueDate?: InputMaybe<Scalars['DateTime']>
  id: Scalars['ID']
  isActive: Scalars['Boolean']
}

export type UpdateReportsSetupPayload = {
  __typename?: 'UpdateReportsSetupPayload'
  clientMutationId?: Maybe<Scalars['String']>
  errors?: Maybe<Array<Maybe<ErrorType>>>
  reportsSetup?: Maybe<ReportsSetupNode>
}

export type UpdateTeamInput = {
  clientMutationId?: InputMaybe<Scalars['String']>
  color: Scalars['ID']
  icon: Scalars['ID']
  id: Scalars['ID']
  name: Scalars['String']
}

export type UpdateTeamPayload = {
  __typename?: 'UpdateTeamPayload'
  clientMutationId?: Maybe<Scalars['String']>
  errors?: Maybe<Array<Maybe<ErrorType>>>
  team?: Maybe<TeamNode>
}

export type UpdateUserGroupInput = {
  avatar?: InputMaybe<Scalars['Upload']>
  clientMutationId?: InputMaybe<Scalars['String']>
  defaultAvatarId?: InputMaybe<Scalars['ID']>
  groupId: Scalars['ID']
  role: Scalars['String']
  userId: Scalars['ID']
}

export type UpdateUserGroupNotificationInput = {
  clientMutationId?: InputMaybe<Scalars['String']>
  groupId: Scalars['ID']
  isActive: Scalars['Boolean']
  notificationId: Scalars['ID']
  userId: Scalars['ID']
}

export type UpdateUserGroupNotificationPayload = {
  __typename?: 'UpdateUserGroupNotificationPayload'
  clientMutationId?: Maybe<Scalars['String']>
  notification?: Maybe<NotificationNode>
}

export type UpdateUserGroupPayload = {
  __typename?: 'UpdateUserGroupPayload'
  clientMutationId?: Maybe<Scalars['String']>
  userGroup?: Maybe<UserGroupNode>
}

export type UpdateUserTeamAdminInput = {
  clientMutationId?: InputMaybe<Scalars['String']>
  isAdmin: Scalars['Boolean']
  teamId: Scalars['ID']
  userId: Scalars['ID']
}

export type UpdateUserTeamAdminPayload = {
  __typename?: 'UpdateUserTeamAdminPayload'
  clientMutationId?: Maybe<Scalars['String']>
  userTeam?: Maybe<UserTeamNode>
}

export type UserExistsNode = {
  __typename?: 'UserExistsNode'
  emailExists?: Maybe<Scalars['Boolean']>
  phoneExists?: Maybe<Scalars['Boolean']>
}

export type UserGroupNode = Node & {
  __typename?: 'UserGroupNode'
  avatar?: Maybe<Scalars['String']>
  created: Scalars['DateTime']
  defaultAvatar?: Maybe<DefaultAvatarNode>
  group?: Maybe<GroupNode>
  /** The ID of the object */
  id: Scalars['ID']
  isCoreTeamMember?: Maybe<Scalars['Boolean']>
  isYearbookTeamMember?: Maybe<Scalars['Boolean']>
  modified: Scalars['DateTime']
  notifications?: Maybe<Array<Maybe<NotificationNode>>>
  role?: Maybe<UserType>
  status?: Maybe<Status>
  user: UserNode
}

export type UserGroupNodeConnection = {
  __typename?: 'UserGroupNodeConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<UserGroupNodeEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
}

/** A Relay edge containing a `UserGroupNode` and its cursor. */
export type UserGroupNodeEdge = {
  __typename?: 'UserGroupNodeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node?: Maybe<UserGroupNode>
}

export type UserNode = Node & {
  __typename?: 'UserNode'
  avatar?: Maybe<Scalars['String']>
  brandLook?: Maybe<BrandLookSelectionNode>
  communicationLanguage?: Maybe<Language>
  countryOfResidence?: Maybe<Scalars['String']>
  created: Scalars['DateTime']
  dataPolicyAcceptances?: Maybe<
    Array<Maybe<DataPolicyAcceptanceNode>>
  >
  email: Scalars['String']
  emailValidated: Scalars['Boolean']
  externalEvents: ExternalEventAttendeeNodeConnection
  externalTokens?: Maybe<ExternalTokensNode>
  firstName: Scalars['String']
  gender?: Maybe<UsersUserGenderChoices>
  groups?: Maybe<Array<Maybe<GroupNode>>>
  /** The ID of the object */
  id: Scalars['ID']
  inviteCode?: Maybe<Scalars['String']>
  isActive: Scalars['Boolean']
  isAgent: Scalars['Boolean']
  isImported?: Maybe<Scalars['Boolean']>
  isSuperuser: Scalars['Boolean']
  lastName: Scalars['String']
  leaderboardPosition?: Maybe<Scalars['Int']>
  modified: Scalars['DateTime']
  optIn?: Maybe<Scalars['Boolean']>
  phone?: Maybe<Scalars['String']>
  phoneCountryCode?: Maybe<Scalars['String']>
  refferralsNum?: Maybe<Scalars['Int']>
  teams?: Maybe<Array<Maybe<TeamNode>>>
  termsAcceptances?: Maybe<Array<Maybe<TermsAcceptanceNode>>>
  userGroups?: Maybe<Array<Maybe<UsersGroupNode>>>
}

export type UserNodeExternalEventsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
}

export type UserNodeConnection = {
  __typename?: 'UserNodeConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<UserNodeEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  /** Number of items in the queryset. */
  totalCount: Scalars['Int']
}

/** A Relay edge containing a `UserNode` and its cursor. */
export type UserNodeEdge = {
  __typename?: 'UserNodeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node?: Maybe<UserNode>
}

export type UserTeamNode = Node & {
  __typename?: 'UserTeamNode'
  avatar?: Maybe<Scalars['String']>
  created: Scalars['DateTime']
  defaultAvatar?: Maybe<Scalars['String']>
  /** The ID of the object */
  id: Scalars['ID']
  isAdmin: Scalars['Boolean']
  modified: Scalars['DateTime']
  status?: Maybe<Status>
  team?: Maybe<TeamNode>
  user: UserNode
}

/** An enumeration. */
export enum UserType {
  Student = 'STUDENT',
  Teacher = 'TEACHER'
}

export type UsersGroupNode = {
  __typename?: 'UsersGroupNode'
  avatar?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['ID']>
  institute: InstituteNode
  isActive?: Maybe<Scalars['Boolean']>
  members?: Maybe<Array<Maybe<GroupMemberNode>>>
  name?: Maybe<Scalars['String']>
  role?: Maybe<UserType>
  status?: Maybe<Status>
  teams?: Maybe<Array<Maybe<TeamNode>>>
  type?: Maybe<GraduationTypeNode>
  users?: Maybe<Array<Maybe<UserGroupNode>>>
  year?: Maybe<Scalars['Int']>
}

/** An enumeration. */
export enum UsersUserGenderChoices {
  /** Female */
  Female = 'FEMALE',
  /** Male */
  Male = 'MALE',
  /** Other */
  Other = 'OTHER'
}

export enum VariantTypeEnum {
  Large = 'LARGE',
  Small = 'SMALL'
}

export type VerifyInput = {
  clientMutationId?: InputMaybe<Scalars['String']>
  token?: InputMaybe<Scalars['String']>
}

export type VerifyPayload = {
  __typename?: 'VerifyPayload'
  clientMutationId?: Maybe<Scalars['String']>
  payload: Scalars['GenericScalar']
}

export type YearbookStatisticsNode = {
  __typename?: 'YearbookStatisticsNode'
  contentModules?: Maybe<Scalars['Int']>
  estimatedPages?: Maybe<Scalars['Int']>
  imagesUploaded?: Maybe<Scalars['Int']>
  lastDeadline?: Maybe<Scalars['DateTime']>
  profilePagesFilledIn?: Maybe<Scalars['Float']>
  usersWithQuote?: Maybe<Scalars['Float']>
  wordCount?: Maybe<Scalars['Int']>
}

export type YearlyAverage = {
  __typename?: 'YearlyAverage'
  average?: Maybe<Scalars['Float']>
  groups?: Maybe<Scalars['Int']>
  instances?: Maybe<Scalars['Int']>
  year?: Maybe<Scalars['Int']>
}

export type AuthUserGroupFragment = {
  __typename?: 'UserGroupNode'
  id: string
  avatar?: string | null
  group?: {
    __typename?: 'GroupNode'
    id: string
    name: string
    usersCount?: number | null
    year: number
    institute: { __typename?: 'InstituteNode'; internalId: string }
  } | null
  defaultAvatar?: {
    __typename?: 'DefaultAvatarNode'
    avatar?: string | null
  } | null
  user: {
    __typename?: 'UserNode'
    id: string
    firstName: string
    lastName: string
    email: string
  }
}

export type CollagesPhotoFragment = {
  __typename?: 'CollagesPhotoNode'
  id: string
  photo: string
  collagesPhotoLikesCount?: number | null
  created: any
}

export type GroupFragment = {
  __typename?: 'GroupNode'
  id: string
  name: string
  usersCount?: number | null
  year: number
  institute: { __typename?: 'InstituteNode'; internalId: string }
}

export type ModuleFragment = {
  __typename?: 'ModuleNode'
  id: string
  name?: string | null
  image?: string | null
  type?: ModuleType | null
}

export type ModuleInstanceResultFragment = {
  __typename?: 'ModuleInstanceNode'
  id: string
  isActive: boolean
  dueDate?: any | null
  module: {
    __typename?: 'ModuleNode'
    id: string
    name?: string | null
    image?: string | null
    type?: ModuleType | null
  }
  prewordInstance?: {
    __typename?: 'PrewordInstanceNode'
    wordCount?: number | null
  } | null
  profilePageSetup?: {
    __typename?: 'ProfilePageSetupNode'
    usersDelivered?: number | null
    maxChars: number
  } | null
  rankingsSetup?: {
    __typename?: 'RankingsSetupNode'
    rankingsCount?: number | null
  } | null
  collagesSetup?: {
    __typename?: 'CollagesSetupNode'
    photosCount?: number | null
  } | null
  reportsSetup?: {
    __typename?: 'ReportsSetupNode'
    reportsCount?: number | null
  } | null
  quotesSetup?: {
    __typename?: 'QuotesSetupNode'
    quotesCount?: number | null
  } | null
  customPagesInstances?: {
    __typename?: 'CustomPagesInstanceNodeConnection'
    edges: Array<{
      __typename?: 'CustomPagesInstanceNodeEdge'
      node?: {
        __typename?: 'CustomPagesInstanceNode'
        id: string
      } | null
    } | null>
  } | null
}

export type ModuleInstanceUserSummaryResultFragment = {
  __typename?: 'ModuleInstanceNode'
  id: string
  isActive: boolean
  dueDate?: any | null
  profilePageSetup?: {
    __typename?: 'ProfilePageSetupNode'
    usersDelivered?: number | null
    maxChars: number
    usersSummary?: Array<{
      __typename?: 'ProfilePageUserSummaryNode'
      questionsAnswered?: number | null
      imagesUploaded?: number | null
      userGroup: {
        __typename?: 'UserGroupNode'
        id: string
        avatar?: string | null
        defaultAvatar?: {
          __typename?: 'DefaultAvatarNode'
          avatar?: string | null
        } | null
        user: {
          __typename?: 'UserNode'
          id: string
          firstName: string
          lastName: string
          email: string
        }
      }
    } | null> | null
  } | null
  module: {
    __typename?: 'ModuleNode'
    id: string
    name?: string | null
    image?: string | null
    type?: ModuleType | null
  }
  prewordInstance?: {
    __typename?: 'PrewordInstanceNode'
    wordCount?: number | null
  } | null
  rankingsSetup?: {
    __typename?: 'RankingsSetupNode'
    rankingsCount?: number | null
  } | null
  collagesSetup?: {
    __typename?: 'CollagesSetupNode'
    photosCount?: number | null
  } | null
  reportsSetup?: {
    __typename?: 'ReportsSetupNode'
    reportsCount?: number | null
  } | null
  quotesSetup?: {
    __typename?: 'QuotesSetupNode'
    quotesCount?: number | null
  } | null
  customPagesInstances?: {
    __typename?: 'CustomPagesInstanceNodeConnection'
    edges: Array<{
      __typename?: 'CustomPagesInstanceNodeEdge'
      node?: {
        __typename?: 'CustomPagesInstanceNode'
        id: string
      } | null
    } | null>
  } | null
}

export type UserSummaryFragment = {
  __typename?: 'ProfilePageUserSummaryNode'
  questionsAnswered?: number | null
  imagesUploaded?: number | null
  userGroup: {
    __typename?: 'UserGroupNode'
    id: string
    avatar?: string | null
    defaultAvatar?: {
      __typename?: 'DefaultAvatarNode'
      avatar?: string | null
    } | null
    user: {
      __typename?: 'UserNode'
      id: string
      firstName: string
      lastName: string
      email: string
    }
  }
}

export type ProfilePagePhotoCategoryFragment = {
  __typename?: 'ProfilePagePhotoCategoryNode'
  id: string
  name?: string | null
  profilePagePhotos?: {
    __typename?: 'ProfilePagePhotoNodeConnection'
    edges: Array<{
      __typename?: 'ProfilePagePhotoNodeEdge'
      node?: {
        __typename?: 'ProfilePagePhotoNode'
        id: string
        image?: string | null
      } | null
    } | null>
  } | null
}

export type ProfilePageQuestionFragment = {
  __typename?: 'ProfilePageQuestionNode'
  id: string
  text: string
  profilePageAnswers?: {
    __typename?: 'ProfilePageAnswerNodeConnection'
    edges: Array<{
      __typename?: 'ProfilePageAnswerNodeEdge'
      node?: {
        __typename?: 'ProfilePageAnswerNode'
        id: string
        text?: string | null
        profilePageQuestionOption?: {
          __typename?: 'ProfilePageQuestionOptionNode'
          id: string
          text: string
        } | null
      } | null
    } | null>
  } | null
}

export type ProfilePageCommentFragment = {
  __typename?: 'ProfilePageCommentNode'
  id: string
  text: string
  created: any
  comentatorUserGroup: {
    __typename?: 'UserGroupNode'
    id: string
    avatar?: string | null
    defaultAvatar?: {
      __typename?: 'DefaultAvatarNode'
      avatar?: string | null
    } | null
    user: {
      __typename?: 'UserNode'
      id: string
      firstName: string
      lastName: string
      email: string
    }
  }
  profileUserGroup: {
    __typename?: 'UserGroupNode'
    id: string
    avatar?: string | null
    defaultAvatar?: {
      __typename?: 'DefaultAvatarNode'
      avatar?: string | null
    } | null
    user: {
      __typename?: 'UserNode'
      id: string
      firstName: string
      lastName: string
      email: string
    }
  }
}

export type QuoteFragment = {
  __typename?: 'QuotesInstanceNode'
  id: string
  quote: string
  quotesInstanceLikesCount?: number | null
  created: any
  userGroup: {
    __typename?: 'UserGroupNode'
    id: string
    avatar?: string | null
    defaultAvatar?: {
      __typename?: 'DefaultAvatarNode'
      avatar?: string | null
    } | null
    user: {
      __typename?: 'UserNode'
      id: string
      firstName: string
      lastName: string
      email: string
    }
  }
}

export type RankingQuestionResultFragment = {
  __typename?: 'RankingsQuestionResultNode'
  percentage?: number | null
  votee1UserGroup?: {
    __typename?: 'UserGroupNode'
    id: string
    avatar?: string | null
    defaultAvatar?: {
      __typename?: 'DefaultAvatarNode'
      avatar?: string | null
    } | null
    user: {
      __typename?: 'UserNode'
      id: string
      firstName: string
      lastName: string
      email: string
    }
  } | null
  votee2UserGroup?: {
    __typename?: 'UserGroupNode'
    id: string
    avatar?: string | null
    defaultAvatar?: {
      __typename?: 'DefaultAvatarNode'
      avatar?: string | null
    } | null
    user: {
      __typename?: 'UserNode'
      id: string
      firstName: string
      lastName: string
      email: string
    }
  } | null
  voteeListOption?: {
    __typename?: 'CustomListOptionNode'
    id: string
    text?: string | null
  } | null
}

export type RankingsQuestionFragment = {
  __typename?: 'RankingsQuestionNode'
  id: string
  question: string
  results?: {
    __typename?: 'RankingsQuestionResultsNode'
    alreadyVoted?: number | null
    possibleVoters?: number | null
    options?: Array<{
      __typename?: 'RankingsQuestionResultNode'
      percentage?: number | null
      votee1UserGroup?: {
        __typename?: 'UserGroupNode'
        id: string
        avatar?: string | null
        defaultAvatar?: {
          __typename?: 'DefaultAvatarNode'
          avatar?: string | null
        } | null
        user: {
          __typename?: 'UserNode'
          id: string
          firstName: string
          lastName: string
          email: string
        }
      } | null
      votee2UserGroup?: {
        __typename?: 'UserGroupNode'
        id: string
        avatar?: string | null
        defaultAvatar?: {
          __typename?: 'DefaultAvatarNode'
          avatar?: string | null
        } | null
        user: {
          __typename?: 'UserNode'
          id: string
          firstName: string
          lastName: string
          email: string
        }
      } | null
      voteeListOption?: {
        __typename?: 'CustomListOptionNode'
        id: string
        text?: string | null
      } | null
    } | null> | null
  } | null
}

export type ReportsInstanceFragment = {
  __typename?: 'ReportsInstanceNode'
  id: string
  title: string
}

export type ReportsInstanceDetailFragment = {
  __typename?: 'ReportsInstanceNode'
  id: string
  title: string
  text: string
  maxChars: number
  modified: any
}

export type UserFragment = {
  __typename?: 'UserNode'
  id: string
  firstName: string
  lastName: string
  email: string
}

export type UserGroupFragment = {
  __typename?: 'UserGroupNode'
  id: string
  avatar?: string | null
  defaultAvatar?: {
    __typename?: 'DefaultAvatarNode'
    avatar?: string | null
  } | null
  user: {
    __typename?: 'UserNode'
    id: string
    firstName: string
    lastName: string
    email: string
  }
}

export type AddPhotoToCollagesAlbumMutationVariables = Exact<{
  input: AddPhotoToCollagesAlbumInput
}>

export type AddPhotoToCollagesAlbumMutation = {
  __typename?: 'Mutation'
  addPhotoToCollagesAlbum?: {
    __typename?: 'AddPhotoToCollagesAlbumPayload'
    clientMutationId?: string | null
    photo?: {
      __typename?: 'CollagesPhotoNode'
      id: string
      photo: string
      collagesPhotoLikesCount?: number | null
      created: any
    } | null
  } | null
}

export type RemovePhotoFromCollagesAlbumMutationVariables = Exact<{
  input: RemovePhotoFromCollagesAlbumInput
}>

export type RemovePhotoFromCollagesAlbumMutation = {
  __typename?: 'Mutation'
  removePhotoFromCollagesAlbum?: {
    __typename?: 'RemovePhotoFromCollagesAlbumPayload'
    success?: boolean | null
  } | null
}

export type MeQueryVariables = Exact<{ [key: string]: never }>

export type MeQuery = {
  __typename?: 'Query'
  me?: {
    __typename?: 'UserNode'
    id: string
    email: string
    created: any
    phone?: string | null
    phoneCountryCode?: string | null
    firstName: string
    lastName: string
    avatar?: string | null
  } | null
}

export type GroupUsersQueryVariables = Exact<{
  user?: InputMaybe<Scalars['ID']>
  status?: InputMaybe<GroupsUserGroupStatusChoices>
}>

export type GroupUsersQuery = {
  __typename?: 'Query'
  userGroups?: {
    __typename?: 'UserGroupNodeConnection'
    edges: Array<{
      __typename?: 'UserGroupNodeEdge'
      node?: {
        __typename?: 'UserGroupNode'
        id: string
        avatar?: string | null
        group?: {
          __typename?: 'GroupNode'
          id: string
          name: string
          usersCount?: number | null
          year: number
          institute: {
            __typename?: 'InstituteNode'
            internalId: string
          }
        } | null
        defaultAvatar?: {
          __typename?: 'DefaultAvatarNode'
          avatar?: string | null
        } | null
        user: {
          __typename?: 'UserNode'
          id: string
          firstName: string
          lastName: string
          email: string
        }
      } | null
    } | null>
  } | null
}

export type CollagesAlbumFragment = {
  __typename?: 'CollagesAlbumNode'
  id: string
  name: string
  photosCount?: number | null
  collagesPhotos?: {
    __typename?: 'CollagesPhotoNodeConnection'
    edges: Array<{
      __typename?: 'CollagesPhotoNodeEdge'
      node?: {
        __typename?: 'CollagesPhotoNode'
        id: string
        photo: string
        collagesPhotoLikesCount?: number | null
        created: any
      } | null
    } | null>
  } | null
}

export type CollagesAlbumsQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  moduleInstance?: InputMaybe<Scalars['ID']>
  cpFirst?: InputMaybe<Scalars['Int']>
}>

export type CollagesAlbumsQuery = {
  __typename?: 'Query'
  collagesAlbums?: {
    __typename?: 'CollagesAlbumNodeConnection'
    pageInfo: {
      __typename?: 'PageInfo'
      hasNextPage: boolean
      endCursor?: string | null
    }
    edges: Array<{
      __typename?: 'CollagesAlbumNodeEdge'
      node?: {
        __typename?: 'CollagesAlbumNode'
        id: string
        name: string
        photosCount?: number | null
        collagesPhotos?: {
          __typename?: 'CollagesPhotoNodeConnection'
          edges: Array<{
            __typename?: 'CollagesPhotoNodeEdge'
            node?: {
              __typename?: 'CollagesPhotoNode'
              id: string
              photo: string
              collagesPhotoLikesCount?: number | null
              created: any
            } | null
          } | null>
        } | null
      } | null
    } | null>
  } | null
}

export type CollagesPhotosQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  collagesAlbum?: InputMaybe<Scalars['ID']>
}>

export type CollagesPhotosQuery = {
  __typename?: 'Query'
  collagesPhotos?: {
    __typename?: 'CollagesPhotoNodeConnection'
    pageInfo: {
      __typename?: 'PageInfo'
      hasNextPage: boolean
      endCursor?: string | null
    }
    edges: Array<{
      __typename?: 'CollagesPhotoNodeEdge'
      node?: {
        __typename?: 'CollagesPhotoNode'
        id: string
        photo: string
        collagesPhotoLikesCount?: number | null
        created: any
      } | null
    } | null>
  } | null
}

export type CustomPagesInstancesQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']>
  first: Scalars['Int']
  userGroup: Scalars['String']
  moduleInstance?: InputMaybe<Scalars['ID']>
}>

export type CustomPagesInstancesQuery = {
  __typename?: 'Query'
  customPagesInstances?: {
    __typename?: 'CustomPagesInstanceNodeConnection'
    pageInfo: {
      __typename?: 'PageInfo'
      hasNextPage: boolean
      endCursor?: string | null
    }
    edges: Array<{
      __typename?: 'CustomPagesInstanceNodeEdge'
      cursor: string
      node?: {
        __typename?: 'CustomPagesInstanceNode'
        id: string
        title: string
      } | null
    } | null>
  } | null
}

export type CustomPagesInstancesResultQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']>
  first: Scalars['Int']
  moduleInstance: Scalars['String']
}>

export type CustomPagesInstancesResultQuery = {
  __typename?: 'Query'
  customPagesInstancesResult?: {
    __typename?: 'CustomPagesInstanceNodeConnection'
    pageInfo: {
      __typename?: 'PageInfo'
      hasNextPage: boolean
      endCursor?: string | null
    }
    edges: Array<{
      __typename?: 'CustomPagesInstanceNodeEdge'
      cursor: string
      node?: {
        __typename?: 'CustomPagesInstanceNode'
        id: string
        title: string
      } | null
    } | null>
  } | null
}

export type CustomPagesInstanceQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type CustomPagesInstanceQuery = {
  __typename?: 'Query'
  customPagesInstance?: {
    __typename?: 'CustomPagesInstanceNode'
    created: any
    modified: any
    id: string
    pageFormat?: PageFormat | null
    title: string
    page?: string | null
    pageType?: PageType | null
    pageQuality?: PageQuality | null
  } | null
}

export type ModuleInstanceResultsQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  group?: InputMaybe<Scalars['ID']>
}>

export type ModuleInstanceResultsQuery = {
  __typename?: 'Query'
  moduleInstances?: {
    __typename?: 'ModuleInstanceNodeConnection'
    edges: Array<{
      __typename?: 'ModuleInstanceNodeEdge'
      node?: {
        __typename?: 'ModuleInstanceNode'
        id: string
        isActive: boolean
        dueDate?: any | null
        module: {
          __typename?: 'ModuleNode'
          id: string
          name?: string | null
          image?: string | null
          type?: ModuleType | null
        }
        prewordInstance?: {
          __typename?: 'PrewordInstanceNode'
          wordCount?: number | null
        } | null
        profilePageSetup?: {
          __typename?: 'ProfilePageSetupNode'
          usersDelivered?: number | null
          maxChars: number
        } | null
        rankingsSetup?: {
          __typename?: 'RankingsSetupNode'
          rankingsCount?: number | null
        } | null
        collagesSetup?: {
          __typename?: 'CollagesSetupNode'
          photosCount?: number | null
        } | null
        reportsSetup?: {
          __typename?: 'ReportsSetupNode'
          reportsCount?: number | null
        } | null
        quotesSetup?: {
          __typename?: 'QuotesSetupNode'
          quotesCount?: number | null
        } | null
        customPagesInstances?: {
          __typename?: 'CustomPagesInstanceNodeConnection'
          edges: Array<{
            __typename?: 'CustomPagesInstanceNodeEdge'
            node?: {
              __typename?: 'CustomPagesInstanceNode'
              id: string
            } | null
          } | null>
        } | null
      } | null
    } | null>
  } | null
}

export type ModuleInstanceUserSummaryResultQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type ModuleInstanceUserSummaryResultQuery = {
  __typename?: 'Query'
  moduleInstance?: {
    __typename?: 'ModuleInstanceNode'
    id: string
    isActive: boolean
    dueDate?: any | null
    profilePageSetup?: {
      __typename?: 'ProfilePageSetupNode'
      usersDelivered?: number | null
      maxChars: number
      usersSummary?: Array<{
        __typename?: 'ProfilePageUserSummaryNode'
        questionsAnswered?: number | null
        imagesUploaded?: number | null
        userGroup: {
          __typename?: 'UserGroupNode'
          id: string
          avatar?: string | null
          defaultAvatar?: {
            __typename?: 'DefaultAvatarNode'
            avatar?: string | null
          } | null
          user: {
            __typename?: 'UserNode'
            id: string
            firstName: string
            lastName: string
            email: string
          }
        }
      } | null> | null
    } | null
    module: {
      __typename?: 'ModuleNode'
      id: string
      name?: string | null
      image?: string | null
      type?: ModuleType | null
    }
    prewordInstance?: {
      __typename?: 'PrewordInstanceNode'
      wordCount?: number | null
    } | null
    rankingsSetup?: {
      __typename?: 'RankingsSetupNode'
      rankingsCount?: number | null
    } | null
    collagesSetup?: {
      __typename?: 'CollagesSetupNode'
      photosCount?: number | null
    } | null
    reportsSetup?: {
      __typename?: 'ReportsSetupNode'
      reportsCount?: number | null
    } | null
    quotesSetup?: {
      __typename?: 'QuotesSetupNode'
      quotesCount?: number | null
    } | null
    customPagesInstances?: {
      __typename?: 'CustomPagesInstanceNodeConnection'
      edges: Array<{
        __typename?: 'CustomPagesInstanceNodeEdge'
        node?: {
          __typename?: 'CustomPagesInstanceNode'
          id: string
        } | null
      } | null>
    } | null
  } | null
}

export type ProfilePagePhotoCategoriesQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  moduleInstance?: InputMaybe<Scalars['ID']>
  ppqUserGroup?: InputMaybe<Scalars['ID']>
}>

export type ProfilePagePhotoCategoriesQuery = {
  __typename?: 'Query'
  profilePagePhotoCategories?: {
    __typename?: 'ProfilePagePhotoCategoryNodeConnection'
    pageInfo: {
      __typename?: 'PageInfo'
      hasNextPage: boolean
      endCursor?: string | null
    }
    edges: Array<{
      __typename?: 'ProfilePagePhotoCategoryNodeEdge'
      node?: {
        __typename?: 'ProfilePagePhotoCategoryNode'
        id: string
        name?: string | null
        profilePagePhotos?: {
          __typename?: 'ProfilePagePhotoNodeConnection'
          edges: Array<{
            __typename?: 'ProfilePagePhotoNodeEdge'
            node?: {
              __typename?: 'ProfilePagePhotoNode'
              id: string
              image?: string | null
            } | null
          } | null>
        } | null
      } | null
    } | null>
  } | null
}

export type ProfilePageQuestionsQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  moduleInstance?: InputMaybe<Scalars['ID']>
  ppqUserGroup?: InputMaybe<Scalars['ID']>
}>

export type ProfilePageQuestionsQuery = {
  __typename?: 'Query'
  profilePageQuestions?: {
    __typename?: 'ProfilePageQuestionNodeConnection'
    pageInfo: {
      __typename?: 'PageInfo'
      hasNextPage: boolean
      endCursor?: string | null
    }
    edges: Array<{
      __typename?: 'ProfilePageQuestionNodeEdge'
      node?: {
        __typename?: 'ProfilePageQuestionNode'
        id: string
        text: string
        profilePageAnswers?: {
          __typename?: 'ProfilePageAnswerNodeConnection'
          edges: Array<{
            __typename?: 'ProfilePageAnswerNodeEdge'
            node?: {
              __typename?: 'ProfilePageAnswerNode'
              id: string
              text?: string | null
              profilePageQuestionOption?: {
                __typename?: 'ProfilePageQuestionOptionNode'
                id: string
                text: string
              } | null
            } | null
          } | null>
        } | null
      } | null
    } | null>
  } | null
}

export type ProfilePageCommentsQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  profileUserGroup?: InputMaybe<Scalars['ID']>
  comentatorUserGroup?: InputMaybe<Scalars['ID']>
  moduleInstance?: InputMaybe<Scalars['ID']>
}>

export type ProfilePageCommentsQuery = {
  __typename?: 'Query'
  profilePageComments?: {
    __typename?: 'ProfilePageCommentNodeConnection'
    pageInfo: {
      __typename?: 'PageInfo'
      hasNextPage: boolean
      endCursor?: string | null
    }
    edges: Array<{
      __typename?: 'ProfilePageCommentNodeEdge'
      node?: {
        __typename?: 'ProfilePageCommentNode'
        id: string
        text: string
        created: any
        comentatorUserGroup: {
          __typename?: 'UserGroupNode'
          id: string
          avatar?: string | null
          defaultAvatar?: {
            __typename?: 'DefaultAvatarNode'
            avatar?: string | null
          } | null
          user: {
            __typename?: 'UserNode'
            id: string
            firstName: string
            lastName: string
            email: string
          }
        }
        profileUserGroup: {
          __typename?: 'UserGroupNode'
          id: string
          avatar?: string | null
          defaultAvatar?: {
            __typename?: 'DefaultAvatarNode'
            avatar?: string | null
          } | null
          user: {
            __typename?: 'UserNode'
            id: string
            firstName: string
            lastName: string
            email: string
          }
        }
      } | null
    } | null>
  } | null
}

export type QuotesInstancesQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  moduleInstance?: InputMaybe<Scalars['ID']>
  orderBy?: InputMaybe<Scalars['String']>
}>

export type QuotesInstancesQuery = {
  __typename?: 'Query'
  quotesInstances?: {
    __typename?: 'QuotesInstanceNodeConnection'
    pageInfo: {
      __typename?: 'PageInfo'
      hasNextPage: boolean
      endCursor?: string | null
    }
    edges: Array<{
      __typename?: 'QuotesInstanceNodeEdge'
      node?: {
        __typename?: 'QuotesInstanceNode'
        id: string
        quote: string
        quotesInstanceLikesCount?: number | null
        created: any
        userGroup: {
          __typename?: 'UserGroupNode'
          id: string
          avatar?: string | null
          defaultAvatar?: {
            __typename?: 'DefaultAvatarNode'
            avatar?: string | null
          } | null
          user: {
            __typename?: 'UserNode'
            id: string
            firstName: string
            lastName: string
            email: string
          }
        }
      } | null
    } | null>
  } | null
}

export type RankingsQuestionsQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  moduleInstance?: InputMaybe<Scalars['ID']>
}>

export type RankingsQuestionsQuery = {
  __typename?: 'Query'
  rankingsQuestions?: {
    __typename?: 'RankingsQuestionNodeConnection'
    pageInfo: {
      __typename?: 'PageInfo'
      hasNextPage: boolean
      endCursor?: string | null
    }
    edges: Array<{
      __typename?: 'RankingsQuestionNodeEdge'
      node?: {
        __typename?: 'RankingsQuestionNode'
        id: string
        question: string
        results?: {
          __typename?: 'RankingsQuestionResultsNode'
          alreadyVoted?: number | null
          possibleVoters?: number | null
          options?: Array<{
            __typename?: 'RankingsQuestionResultNode'
            percentage?: number | null
            votee1UserGroup?: {
              __typename?: 'UserGroupNode'
              id: string
              avatar?: string | null
              defaultAvatar?: {
                __typename?: 'DefaultAvatarNode'
                avatar?: string | null
              } | null
              user: {
                __typename?: 'UserNode'
                id: string
                firstName: string
                lastName: string
                email: string
              }
            } | null
            votee2UserGroup?: {
              __typename?: 'UserGroupNode'
              id: string
              avatar?: string | null
              defaultAvatar?: {
                __typename?: 'DefaultAvatarNode'
                avatar?: string | null
              } | null
              user: {
                __typename?: 'UserNode'
                id: string
                firstName: string
                lastName: string
                email: string
              }
            } | null
            voteeListOption?: {
              __typename?: 'CustomListOptionNode'
              id: string
              text?: string | null
            } | null
          } | null> | null
        } | null
      } | null
    } | null>
  } | null
}

export type ReportsInstancesQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  moduleInstance?: InputMaybe<Scalars['ID']>
}>

export type ReportsInstancesQuery = {
  __typename?: 'Query'
  reportsInstances?: {
    __typename?: 'ReportsInstanceNodeConnection'
    pageInfo: {
      __typename?: 'PageInfo'
      hasNextPage: boolean
      endCursor?: string | null
    }
    edges: Array<{
      __typename?: 'ReportsInstanceNodeEdge'
      node?: {
        __typename?: 'ReportsInstanceNode'
        id: string
        title: string
      } | null
    } | null>
  } | null
}

export type ReportsInstanceQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type ReportsInstanceQuery = {
  __typename?: 'Query'
  reportsInstance?: {
    __typename?: 'ReportsInstanceNode'
    id: string
    title: string
    text: string
    maxChars: number
    modified: any
  } | null
}

export type UserGroupQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type UserGroupQuery = {
  __typename?: 'Query'
  userGroup?: {
    __typename?: 'UserGroupNode'
    id: string
    avatar?: string | null
    defaultAvatar?: {
      __typename?: 'DefaultAvatarNode'
      avatar?: string | null
    } | null
    user: {
      __typename?: 'UserNode'
      id: string
      firstName: string
      lastName: string
      email: string
    }
  } | null
}

export const UserFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserNode' }
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'firstName' }
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lastName' }
          },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } }
        ]
      }
    }
  ]
} as unknown as DocumentNode<UserFragment, unknown>
export const UserGroupFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserGroup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserGroupNode' }
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'defaultAvatar' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'avatar' }
                }
              ]
            }
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'User' }
                }
              ]
            }
          }
        ]
      }
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserNode' }
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'firstName' }
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lastName' }
          },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } }
        ]
      }
    }
  ]
} as unknown as DocumentNode<UserGroupFragment, unknown>
export const GroupFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Group' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GroupNode' }
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'usersCount' }
          },
          { kind: 'Field', name: { kind: 'Name', value: 'year' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'institute' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'internalId' }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<GroupFragment, unknown>
export const AuthUserGroupFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AuthUserGroup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserGroupNode' }
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'UserGroup' }
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'group' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Group' }
                }
              ]
            }
          }
        ]
      }
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserNode' }
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'firstName' }
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lastName' }
          },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } }
        ]
      }
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserGroup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserGroupNode' }
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'defaultAvatar' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'avatar' }
                }
              ]
            }
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'User' }
                }
              ]
            }
          }
        ]
      }
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Group' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GroupNode' }
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'usersCount' }
          },
          { kind: 'Field', name: { kind: 'Name', value: 'year' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'institute' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'internalId' }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<AuthUserGroupFragment, unknown>
export const ModuleFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Module' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ModuleNode' }
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'image' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } }
        ]
      }
    }
  ]
} as unknown as DocumentNode<ModuleFragment, unknown>
export const ModuleInstanceResultFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ModuleInstanceResult' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ModuleInstanceNode' }
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isActive' }
          },
          { kind: 'Field', name: { kind: 'Name', value: 'dueDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'module' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Module' }
                }
              ]
            }
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'prewordInstance' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'wordCount' }
                }
              ]
            }
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profilePageSetup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'usersDelivered' }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'maxChars' }
                }
              ]
            }
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rankingsSetup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'rankingsCount' }
                }
              ]
            }
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'collagesSetup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'photosCount' }
                }
              ]
            }
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reportsSetup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'reportsCount' }
                }
              ]
            }
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'quotesSetup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'quotesCount' }
                }
              ]
            }
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'customPagesInstances' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' }
                            }
                          ]
                        }
                      }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Module' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ModuleNode' }
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'image' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } }
        ]
      }
    }
  ]
} as unknown as DocumentNode<ModuleInstanceResultFragment, unknown>
export const UserSummaryFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserSummary' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProfilePageUserSummaryNode' }
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userGroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserGroup' }
                }
              ]
            }
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'questionsAnswered' }
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'imagesUploaded' }
          }
        ]
      }
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserNode' }
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'firstName' }
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lastName' }
          },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } }
        ]
      }
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserGroup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserGroupNode' }
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'defaultAvatar' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'avatar' }
                }
              ]
            }
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'User' }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<UserSummaryFragment, unknown>
export const ModuleInstanceUserSummaryResultFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'ModuleInstanceUserSummaryResult'
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ModuleInstanceNode' }
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'ModuleInstanceResult' }
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profilePageSetup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'usersSummary' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'UserSummary' }
                      }
                    ]
                  }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'usersDelivered' }
                }
              ]
            }
          }
        ]
      }
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Module' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ModuleNode' }
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'image' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } }
        ]
      }
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserNode' }
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'firstName' }
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lastName' }
          },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } }
        ]
      }
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserGroup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserGroupNode' }
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'defaultAvatar' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'avatar' }
                }
              ]
            }
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'User' }
                }
              ]
            }
          }
        ]
      }
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ModuleInstanceResult' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ModuleInstanceNode' }
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isActive' }
          },
          { kind: 'Field', name: { kind: 'Name', value: 'dueDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'module' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Module' }
                }
              ]
            }
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'prewordInstance' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'wordCount' }
                }
              ]
            }
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profilePageSetup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'usersDelivered' }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'maxChars' }
                }
              ]
            }
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rankingsSetup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'rankingsCount' }
                }
              ]
            }
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'collagesSetup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'photosCount' }
                }
              ]
            }
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reportsSetup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'reportsCount' }
                }
              ]
            }
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'quotesSetup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'quotesCount' }
                }
              ]
            }
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'customPagesInstances' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' }
                            }
                          ]
                        }
                      }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserSummary' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProfilePageUserSummaryNode' }
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userGroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserGroup' }
                }
              ]
            }
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'questionsAnswered' }
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'imagesUploaded' }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  ModuleInstanceUserSummaryResultFragment,
  unknown
>
export const ProfilePagePhotoCategoryFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProfilePagePhotoCategory' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProfilePagePhotoCategoryNode' }
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profilePagePhotos' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'userGroup' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'ppqUserGroup' }
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' }
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'image' }
                            }
                          ]
                        }
                      }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  ProfilePagePhotoCategoryFragment,
  unknown
>
export const ProfilePageQuestionFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProfilePageQuestion' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProfilePageQuestionNode' }
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profilePageAnswers' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'userGroup' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'ppqUserGroup' }
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' }
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'text' }
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'profilePageQuestionOption'
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'id'
                                    }
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'text'
                                    }
                                  }
                                ]
                              }
                            }
                          ]
                        }
                      }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<ProfilePageQuestionFragment, unknown>
export const ProfilePageCommentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProfilePageComment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProfilePageCommentNode' }
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'comentatorUserGroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserGroup' }
                }
              ]
            }
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profileUserGroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserGroup' }
                }
              ]
            }
          },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } }
        ]
      }
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserNode' }
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'firstName' }
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lastName' }
          },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } }
        ]
      }
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserGroup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserGroupNode' }
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'defaultAvatar' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'avatar' }
                }
              ]
            }
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'User' }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<ProfilePageCommentFragment, unknown>
export const QuoteFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Quote' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'QuotesInstanceNode' }
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'quote' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userGroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserGroup' }
                }
              ]
            }
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'quotesInstanceLikesCount' }
          },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } }
        ]
      }
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserNode' }
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'firstName' }
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lastName' }
          },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } }
        ]
      }
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserGroup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserGroupNode' }
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'defaultAvatar' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'avatar' }
                }
              ]
            }
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'User' }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<QuoteFragment, unknown>
export const RankingQuestionResultFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RankingQuestionResult' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'RankingsQuestionResultNode' }
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'votee1UserGroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserGroup' }
                }
              ]
            }
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'votee2UserGroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserGroup' }
                }
              ]
            }
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'voteeListOption' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'text' }
                }
              ]
            }
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'percentage' }
          }
        ]
      }
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserNode' }
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'firstName' }
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lastName' }
          },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } }
        ]
      }
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserGroup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserGroupNode' }
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'defaultAvatar' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'avatar' }
                }
              ]
            }
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'User' }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<RankingQuestionResultFragment, unknown>
export const RankingsQuestionFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RankingsQuestion' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'RankingsQuestionNode' }
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'question' }
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'results' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'alreadyVoted' }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'possibleVoters' }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'options' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'RankingQuestionResult'
                        }
                      }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserNode' }
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'firstName' }
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lastName' }
          },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } }
        ]
      }
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserGroup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserGroupNode' }
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'defaultAvatar' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'avatar' }
                }
              ]
            }
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'User' }
                }
              ]
            }
          }
        ]
      }
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RankingQuestionResult' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'RankingsQuestionResultNode' }
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'votee1UserGroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserGroup' }
                }
              ]
            }
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'votee2UserGroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserGroup' }
                }
              ]
            }
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'voteeListOption' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'text' }
                }
              ]
            }
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'percentage' }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<RankingsQuestionFragment, unknown>
export const ReportsInstanceFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ReportsInstance' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportsInstanceNode' }
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } }
        ]
      }
    }
  ]
} as unknown as DocumentNode<ReportsInstanceFragment, unknown>
export const ReportsInstanceDetailFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ReportsInstanceDetail' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportsInstanceNode' }
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'maxChars' }
          },
          { kind: 'Field', name: { kind: 'Name', value: 'modified' } }
        ]
      }
    }
  ]
} as unknown as DocumentNode<ReportsInstanceDetailFragment, unknown>
export const CollagesPhotoFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CollagesPhoto' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CollagesPhotoNode' }
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'photo' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'collagesPhotoLikesCount' }
          },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } }
        ]
      }
    }
  ]
} as unknown as DocumentNode<CollagesPhotoFragment, unknown>
export const CollagesAlbumFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CollagesAlbum' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CollagesAlbumNode' }
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'photosCount' }
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'collagesPhotos' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'cpFirst' }
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'CollagesPhoto'
                              }
                            }
                          ]
                        }
                      }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CollagesPhoto' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CollagesPhotoNode' }
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'photo' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'collagesPhotoLikesCount' }
          },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } }
        ]
      }
    }
  ]
} as unknown as DocumentNode<CollagesAlbumFragment, unknown>
export const AddPhotoToCollagesAlbumDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AddPhotoToCollagesAlbum' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'AddPhotoToCollagesAlbumInput'
              }
            }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addPhotoToCollagesAlbum' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' }
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'clientMutationId' }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'photo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'CollagesPhoto' }
                      }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CollagesPhoto' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CollagesPhotoNode' }
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'photo' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'collagesPhotoLikesCount' }
          },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  AddPhotoToCollagesAlbumMutation,
  AddPhotoToCollagesAlbumMutationVariables
>
export const RemovePhotoFromCollagesAlbumDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RemovePhotoFromCollagesAlbum' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'RemovePhotoFromCollagesAlbumInput'
              }
            }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'removePhotoFromCollagesAlbum'
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' }
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'success' }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  RemovePhotoFromCollagesAlbumMutation,
  RemovePhotoFromCollagesAlbumMutationVariables
>
export const MeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Me' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'me' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'email' }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'created' }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'phone' }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'phoneCountryCode' }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'firstName' }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'lastName' }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'avatar' }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<MeQuery, MeQueryVariables>
export const GroupUsersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GroupUsers' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'user' }
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'ID' }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'status' }
          },
          type: {
            kind: 'NamedType',
            name: {
              kind: 'Name',
              value: 'GroupsUserGroupStatusChoices'
            }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userGroups' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'user' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'user' }
                }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'status' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'status' }
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'AuthUserGroup'
                              }
                            }
                          ]
                        }
                      }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserNode' }
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'firstName' }
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lastName' }
          },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } }
        ]
      }
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserGroup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserGroupNode' }
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'defaultAvatar' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'avatar' }
                }
              ]
            }
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'User' }
                }
              ]
            }
          }
        ]
      }
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Group' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GroupNode' }
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'usersCount' }
          },
          { kind: 'Field', name: { kind: 'Name', value: 'year' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'institute' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'internalId' }
                }
              ]
            }
          }
        ]
      }
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AuthUserGroup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserGroupNode' }
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'UserGroup' }
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'group' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Group' }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  GroupUsersQuery,
  GroupUsersQueryVariables
>
export const CollagesAlbumsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CollagesAlbums' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'after' }
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'String' }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'first' }
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'Int' }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'moduleInstance' }
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'ID' }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'cpFirst' }
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'Int' }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'collagesAlbums' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'after' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'after' }
                }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'first' }
                }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'moduleInstance' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'moduleInstance' }
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hasNextPage' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'endCursor' }
                      }
                    ]
                  }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'CollagesAlbum'
                              }
                            }
                          ]
                        }
                      }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CollagesPhoto' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CollagesPhotoNode' }
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'photo' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'collagesPhotoLikesCount' }
          },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } }
        ]
      }
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CollagesAlbum' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CollagesAlbumNode' }
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'photosCount' }
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'collagesPhotos' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'cpFirst' }
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'CollagesPhoto'
                              }
                            }
                          ]
                        }
                      }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  CollagesAlbumsQuery,
  CollagesAlbumsQueryVariables
>
export const CollagesPhotosDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CollagesPhotos' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'after' }
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'String' }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'first' }
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'Int' }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'collagesAlbum' }
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'ID' }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'collagesPhotos' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'after' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'after' }
                }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'first' }
                }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'collagesAlbum' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'collagesAlbum' }
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hasNextPage' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'endCursor' }
                      }
                    ]
                  }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'CollagesPhoto'
                              }
                            }
                          ]
                        }
                      }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CollagesPhoto' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CollagesPhotoNode' }
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'photo' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'collagesPhotoLikesCount' }
          },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  CollagesPhotosQuery,
  CollagesPhotosQueryVariables
>
export const CustomPagesInstancesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CustomPagesInstances' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'after' }
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'String' }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'first' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'Int' }
            }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userGroup' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' }
            }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'moduleInstance' }
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'ID' }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'customPagesInstances' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'after' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'after' }
                }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'first' }
                }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'userGroup' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'userGroup' }
                }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'moduleInstance' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'moduleInstance' }
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hasNextPage' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'endCursor' }
                      }
                    ]
                  }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' }
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'title' }
                            }
                          ]
                        }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'cursor' }
                      }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  CustomPagesInstancesQuery,
  CustomPagesInstancesQueryVariables
>
export const CustomPagesInstancesResultDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CustomPagesInstancesResult' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'after' }
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'String' }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'first' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'Int' }
            }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'moduleInstance' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' }
            }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'customPagesInstancesResult'
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'after' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'after' }
                }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'first' }
                }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'moduleInstance' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'moduleInstance' }
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hasNextPage' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'endCursor' }
                      }
                    ]
                  }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' }
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'title' }
                            }
                          ]
                        }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'cursor' }
                      }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  CustomPagesInstancesResultQuery,
  CustomPagesInstancesResultQueryVariables
>
export const CustomPagesInstanceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CustomPagesInstance' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'id' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ID' }
            }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'customPagesInstance' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' }
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'created' }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'modified' }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageFormat' }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'title' }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'page' }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageType' }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageQuality' }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  CustomPagesInstanceQuery,
  CustomPagesInstanceQueryVariables
>
export const ModuleInstanceResultsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ModuleInstanceResults' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'after' }
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'String' }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'first' }
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'Int' }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'group' }
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'ID' }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'moduleInstances' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'after' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'after' }
                }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'first' }
                }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'group' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'group' }
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'ModuleInstanceResult'
                              }
                            }
                          ]
                        }
                      }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Module' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ModuleNode' }
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'image' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } }
        ]
      }
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ModuleInstanceResult' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ModuleInstanceNode' }
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isActive' }
          },
          { kind: 'Field', name: { kind: 'Name', value: 'dueDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'module' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Module' }
                }
              ]
            }
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'prewordInstance' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'wordCount' }
                }
              ]
            }
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profilePageSetup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'usersDelivered' }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'maxChars' }
                }
              ]
            }
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rankingsSetup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'rankingsCount' }
                }
              ]
            }
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'collagesSetup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'photosCount' }
                }
              ]
            }
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reportsSetup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'reportsCount' }
                }
              ]
            }
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'quotesSetup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'quotesCount' }
                }
              ]
            }
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'customPagesInstances' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' }
                            }
                          ]
                        }
                      }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  ModuleInstanceResultsQuery,
  ModuleInstanceResultsQueryVariables
>
export const ModuleInstanceUserSummaryResultDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {
        kind: 'Name',
        value: 'ModuleInstanceUserSummaryResult'
      },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'id' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ID' }
            }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'moduleInstance' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' }
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'ModuleInstanceUserSummaryResult'
                  }
                }
              ]
            }
          }
        ]
      }
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Module' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ModuleNode' }
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'image' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } }
        ]
      }
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ModuleInstanceResult' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ModuleInstanceNode' }
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isActive' }
          },
          { kind: 'Field', name: { kind: 'Name', value: 'dueDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'module' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Module' }
                }
              ]
            }
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'prewordInstance' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'wordCount' }
                }
              ]
            }
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profilePageSetup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'usersDelivered' }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'maxChars' }
                }
              ]
            }
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rankingsSetup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'rankingsCount' }
                }
              ]
            }
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'collagesSetup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'photosCount' }
                }
              ]
            }
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reportsSetup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'reportsCount' }
                }
              ]
            }
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'quotesSetup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'quotesCount' }
                }
              ]
            }
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'customPagesInstances' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' }
                            }
                          ]
                        }
                      }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserNode' }
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'firstName' }
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lastName' }
          },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } }
        ]
      }
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserGroup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserGroupNode' }
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'defaultAvatar' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'avatar' }
                }
              ]
            }
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'User' }
                }
              ]
            }
          }
        ]
      }
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserSummary' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProfilePageUserSummaryNode' }
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userGroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserGroup' }
                }
              ]
            }
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'questionsAnswered' }
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'imagesUploaded' }
          }
        ]
      }
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'ModuleInstanceUserSummaryResult'
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ModuleInstanceNode' }
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'ModuleInstanceResult' }
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profilePageSetup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'usersSummary' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'UserSummary' }
                      }
                    ]
                  }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'usersDelivered' }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  ModuleInstanceUserSummaryResultQuery,
  ModuleInstanceUserSummaryResultQueryVariables
>
export const ProfilePagePhotoCategoriesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ProfilePagePhotoCategories' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'after' }
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'String' }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'first' }
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'Int' }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'moduleInstance' }
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'ID' }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'ppqUserGroup' }
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'ID' }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'profilePagePhotoCategories'
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'after' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'after' }
                }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'first' }
                }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'moduleInstance' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'moduleInstance' }
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hasNextPage' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'endCursor' }
                      }
                    ]
                  }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'ProfilePagePhotoCategory'
                              }
                            }
                          ]
                        }
                      }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProfilePagePhotoCategory' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProfilePagePhotoCategoryNode' }
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profilePagePhotos' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'userGroup' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'ppqUserGroup' }
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' }
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'image' }
                            }
                          ]
                        }
                      }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  ProfilePagePhotoCategoriesQuery,
  ProfilePagePhotoCategoriesQueryVariables
>
export const ProfilePageQuestionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ProfilePageQuestions' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'after' }
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'String' }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'first' }
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'Int' }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'moduleInstance' }
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'ID' }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'ppqUserGroup' }
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'ID' }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profilePageQuestions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'after' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'after' }
                }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'first' }
                }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'moduleInstance' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'moduleInstance' }
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hasNextPage' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'endCursor' }
                      }
                    ]
                  }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'ProfilePageQuestion'
                              }
                            }
                          ]
                        }
                      }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProfilePageQuestion' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProfilePageQuestionNode' }
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profilePageAnswers' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'userGroup' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'ppqUserGroup' }
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' }
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'text' }
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'profilePageQuestionOption'
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'id'
                                    }
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'text'
                                    }
                                  }
                                ]
                              }
                            }
                          ]
                        }
                      }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  ProfilePageQuestionsQuery,
  ProfilePageQuestionsQueryVariables
>
export const ProfilePageCommentsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ProfilePageComments' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'after' }
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'String' }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'first' }
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'Int' }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'profileUserGroup' }
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'ID' }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'comentatorUserGroup' }
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'ID' }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'moduleInstance' }
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'ID' }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profilePageComments' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'after' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'after' }
                }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'first' }
                }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'profileUserGroup' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'profileUserGroup' }
                }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'comentatorUserGroup' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'comentatorUserGroup' }
                }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'moduleInstance' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'moduleInstance' }
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hasNextPage' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'endCursor' }
                      }
                    ]
                  }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'ProfilePageComment'
                              }
                            }
                          ]
                        }
                      }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserNode' }
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'firstName' }
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lastName' }
          },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } }
        ]
      }
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserGroup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserGroupNode' }
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'defaultAvatar' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'avatar' }
                }
              ]
            }
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'User' }
                }
              ]
            }
          }
        ]
      }
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProfilePageComment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProfilePageCommentNode' }
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'comentatorUserGroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserGroup' }
                }
              ]
            }
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profileUserGroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserGroup' }
                }
              ]
            }
          },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  ProfilePageCommentsQuery,
  ProfilePageCommentsQueryVariables
>
export const QuotesInstancesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'QuotesInstances' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'after' }
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'String' }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'first' }
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'Int' }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'moduleInstance' }
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'ID' }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'orderBy' }
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'String' }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'quotesInstances' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'after' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'after' }
                }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'first' }
                }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'moduleInstance' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'moduleInstance' }
                }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'orderBy' }
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hasNextPage' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'endCursor' }
                      }
                    ]
                  }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'Quote' }
                            }
                          ]
                        }
                      }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserNode' }
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'firstName' }
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lastName' }
          },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } }
        ]
      }
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserGroup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserGroupNode' }
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'defaultAvatar' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'avatar' }
                }
              ]
            }
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'User' }
                }
              ]
            }
          }
        ]
      }
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Quote' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'QuotesInstanceNode' }
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'quote' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userGroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserGroup' }
                }
              ]
            }
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'quotesInstanceLikesCount' }
          },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  QuotesInstancesQuery,
  QuotesInstancesQueryVariables
>
export const RankingsQuestionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'RankingsQuestions' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'after' }
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'String' }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'first' }
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'Int' }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'moduleInstance' }
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'ID' }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rankingsQuestions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'after' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'after' }
                }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'first' }
                }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'moduleInstance' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'moduleInstance' }
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hasNextPage' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'endCursor' }
                      }
                    ]
                  }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'RankingsQuestion'
                              }
                            }
                          ]
                        }
                      }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserNode' }
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'firstName' }
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lastName' }
          },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } }
        ]
      }
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserGroup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserGroupNode' }
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'defaultAvatar' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'avatar' }
                }
              ]
            }
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'User' }
                }
              ]
            }
          }
        ]
      }
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RankingQuestionResult' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'RankingsQuestionResultNode' }
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'votee1UserGroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserGroup' }
                }
              ]
            }
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'votee2UserGroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserGroup' }
                }
              ]
            }
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'voteeListOption' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'text' }
                }
              ]
            }
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'percentage' }
          }
        ]
      }
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RankingsQuestion' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'RankingsQuestionNode' }
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'question' }
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'results' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'alreadyVoted' }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'possibleVoters' }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'options' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'RankingQuestionResult'
                        }
                      }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  RankingsQuestionsQuery,
  RankingsQuestionsQueryVariables
>
export const ReportsInstancesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ReportsInstances' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'after' }
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'String' }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'first' }
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'Int' }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'moduleInstance' }
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'ID' }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reportsInstances' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'after' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'after' }
                }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'first' }
                }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'moduleInstance' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'moduleInstance' }
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hasNextPage' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'endCursor' }
                      }
                    ]
                  }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'ReportsInstance'
                              }
                            }
                          ]
                        }
                      }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ReportsInstance' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportsInstanceNode' }
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  ReportsInstancesQuery,
  ReportsInstancesQueryVariables
>
export const ReportsInstanceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ReportsInstance' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'id' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ID' }
            }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reportsInstance' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' }
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'ReportsInstanceDetail'
                  }
                }
              ]
            }
          }
        ]
      }
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ReportsInstanceDetail' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportsInstanceNode' }
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'maxChars' }
          },
          { kind: 'Field', name: { kind: 'Name', value: 'modified' } }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  ReportsInstanceQuery,
  ReportsInstanceQueryVariables
>
export const UserGroupDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'UserGroup' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'id' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ID' }
            }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userGroup' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' }
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserGroup' }
                }
              ]
            }
          }
        ]
      }
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserNode' }
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'firstName' }
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lastName' }
          },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } }
        ]
      }
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserGroup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserGroupNode' }
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'defaultAvatar' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'avatar' }
                }
              ]
            }
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'User' }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<UserGroupQuery, UserGroupQueryVariables>
