export enum BlockTypes {
  section = 'section',
  paragraph = 'paragraph',
  image = 'image'
}

export type Block = ParagraphBlock | SectionBlock | ImageBlock

export type BlockCommon = {
  type: BlockTypes
}

export type ParagraphBlock = {
  text: string
} & BlockCommon

export type SectionBlock = {
  title: string
  blocks: Block[]
} & BlockCommon

export type ImageBlock = {
  url: string
} & BlockCommon

export type Preword = {
  title: string
  blocks: Block[]
  updatedAt: Date
  wordsCount: number
}
