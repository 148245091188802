import { FormikContextType, useFormikContext } from 'formik'
import styled from 'styled-components'

import { theme } from 'shared/styles/theme'
import Flex from 'shared/ui/Flex'
import {
  DocumentFrame,
  FrameService
} from 'shared/components/Tools/services/types'
import {
  ChangeFrameProps,
  Frame,
  FrameSizeFields
} from 'shared/components/Tools/types'

import useConnectedSizeProps from '../../hooks/useConnectedSize'
import { ReactComponent as ConnectedIcon } from '../../icons/connected.svg'
import FormGroup from '../FormGroup'

const Connection = styled.div`
  margin-top: 3px;
  height: 32px;
  width: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  cursor: pointer;
`

type FrameSizeProps = {
  frame: Frame
  frameApi: FrameService
  changeFrameProps: ChangeFrameProps
}

const SIZE_INPUT_WIDTH = 67

const FrameSize: React.FC<FrameSizeProps> = ({
  frame,
  frameApi,
  changeFrameProps
}) => {
  const {
    values: formValues,
    setFieldValue
  }: FormikContextType<any> = useFormikContext()

  useConnectedSizeProps({
    frame,
    changeFrameProps,
    isConnected: formValues.isSizeConnected,
    frameApi
  })

  const handleSizeConnectionChange = () => {
    const currentFrame = frameApi.getOrAddFrame(frame)
    const newValue = !currentFrame?.isSizeConnected
    setFieldValue('isSizeConnected', newValue)
    frameApi.setFrameProperty(frame.id, 'isSizeConnected', newValue)

    if (newValue === true) {
      frameApi.setFrameProperty(
        frame.id,
        'width',
        Number.parseFloat(frame.height!) || 0
      )
      changeFrameProps('width', frame.height || '0 mm')
    }
  }

  const handleInputChange = (field: string, value: string) => {
    frameApi.setFrameProperty(
      frame.id,
      field as keyof DocumentFrame,
      Number.parseFloat(value) || 0
    )
    return changeFrameProps(field, value)
  }

  return (
    <Flex columnGap={8} marginTop={8}>
      <FormGroup
        key={FrameSizeFields.width}
        id={FrameSizeFields.width}
        onChange={handleInputChange}
        width={SIZE_INPUT_WIDTH}
      />
      <Connection
        style={{
          background: formValues.isSizeConnected
            ? theme.color.base.c2
            : theme.color.base.c2
        }}
        onClick={handleSizeConnectionChange}
      >
        <ConnectedIcon />
      </Connection>
      <FormGroup
        key={FrameSizeFields.height}
        id={FrameSizeFields.height}
        onChange={handleInputChange}
        width={SIZE_INPUT_WIDTH}
      />
    </Flex>
  )
}

export default FrameSize
