import { useMutation, useQuery } from '@apollo/client'
import { useLocation } from 'react-router-dom'

import Flex from 'shared/ui/Flex'
import { Skeleton } from 'shared/ui/Skeleton'
import useAuth from 'data/gradoo/hooks/useAuth'
import { REPORTS_INSTANCE } from 'data/gradoo/queries/reports'
import { ADD_NEW_CHILI_IMAGE } from 'data/layoutcreator/mutations/images'
import { fetchImageFile } from 'shared/helpers/file'
import ChiliImages from 'shared/components/Tools/services/ChiliImages'
import { ImageSources } from 'shared/types/global'

import MarkdownView from './components/MarkdownView'

const imagesApi = ChiliImages.getInstance()

type ReportPanelProps = {
  reportInstanceId: string
}

const ReportPanel: React.FC<ReportPanelProps> = ({
  reportInstanceId
}) => {
  const { pathname } = useLocation()
  const projectId = pathname.split('/')[1]
  const { authGroupId } = useAuth()

  const { data: reportsInstanceData, loading } = useQuery(
    REPORTS_INSTANCE,
    {
      skip: !reportInstanceId,
      variables: {
        id: reportInstanceId
      },
      context: { client: 'gradoo' }
    }
  )

  const [addNewChiliImage] = useMutation(ADD_NEW_CHILI_IMAGE)

  const reportInstance = reportsInstanceData?.reportsInstance

  const handleImageClick = async (url: string) => {
    const file = await fetchImageFile(url)

    const { data } = await addNewChiliImage({
      variables: {
        projectId,
        groupId: authGroupId,
        image: file,
        source: ImageSources.report
      }
    })

    imagesApi.createNewFrame({
      ...data.addNewChiliImage,
      imageUrl: url
    })
  }

  return (
    <Flex marginTop={9} direction="column">
      {loading ? (
        <>
          <Skeleton height={20} width={120} />
          <Skeleton height={14} marginTop={14} />
          <Skeleton height={14} marginTop={14} />
          <Skeleton height={14} marginTop={14} />
        </>
      ) : (
        <MarkdownView
          data-testid={`MarkdownView:${reportInstance?.id}`}
          onImageClick={handleImageClick}
          reportInstance={reportInstance}
        />
      )}
    </Flex>
  )
}

export default ReportPanel
