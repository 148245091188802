import { gql } from 'shared/types/layoutcreator'

export const GET_DELIVERY_ADDRESS = gql(/* GraphQL */ `
  query getDeliveryAddress(
    $orderNumber: String!
    $year: Int!
    $customerNumber: String!
  ) {
    getDeliveryAddress(
      orderNumber: $orderNumber
      year: $year
      customerNumber: $customerNumber
    ) {
      firstName
      lastName
      streetAndNumber
      companyOrAdditionalInfo
      zip
      city
      countryCode
    }
  }
`)

export const GET_YEARBOOK_PARAMS = gql(/* GraphQL */ `
  query getYearbookParams(
    $orderNumber: String!
    $year: Int!
    $customerNumber: String!
  ) {
    getYearbookParams(
      orderNumber: $orderNumber
      year: $year
      customerNumber: $customerNumber
    ) {
      pages {
        total
      }
      cover
      format
      grammage
    }
  }
`)

export const GET_PRODUCTION_ATTRS = gql(/* GraphQL */ `
  query getProductionAttrs(
    $orderNumber: String!
    $year: Int!
    $customerNumber: String!
  ) {
    getProductionAttributes(
      orderNumber: $orderNumber
      year: $year
      customerNumber: $customerNumber
    ) {
      orderYear
      proofDataInProductionDate
      approvalDate
      latestPrintDataUploadDate
      finalDataUploadDate
      noApproval
      plannedApprovalDate
      waitingForApprovalDate
      proofDataUploadDate
      orderDeliveryDate
    }
  }
`)
