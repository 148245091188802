import { ApolloProvider } from '@apollo/client'
import * as Sentry from '@sentry/react'
import { createRoot } from 'react-dom/client'

import client from 'data/client'
import { isDev } from 'helpers/env'
import { redirectToGradooLogin } from 'utils/auth'
import { setupLocale } from 'utils/locale'

import App from './App'
import reportWebVitals from './reportWebVitals'

if (!isDev()) {
  Sentry.init({
    dsn: 'https://d2ef9ebf4ad6492f9af365766297db66@o697731.ingest.sentry.io/4504714909253632',
    integrations: [new Sentry.Replay()],
    tracePropagationTargets: [
      'localhost',
      'https://api.gradoo.app',
      'https://dev.stg01.gradoo.tech'
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  })
}

const container = document.getElementById('root')

const root = createRoot(container!)

const startup = () => {
  const { locale, messages } = setupLocale()

  root.render(
    <ApolloProvider client={client}>
      <App locale={locale} messages={messages} />
    </ApolloProvider>
  )
}

const urlParams = new URLSearchParams(window.location.search)
const tokenParam = urlParams.get('token')
const refreshTokenParam = urlParams.get('refreshToken')

if (tokenParam && refreshTokenParam) {
  localStorage.setItem('token', tokenParam)
  localStorage.setItem('refreshToken', refreshTokenParam)

  urlParams.delete('token')
  urlParams.delete('refreshToken')

  window.history.pushState(
    {},
    document.title,
    window.location.pathname
  )
}
const token = localStorage.getItem('token')
const refreshToken = localStorage.getItem('refreshToken')

if (!token || !refreshToken) {
  redirectToGradooLogin()
} else {
  startup()
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
