import { css } from 'styled-components'

export const containerStyles = css`
  max-width: 1440px;
  margin: 0 auto;
  padding-right: ${({ theme }) => theme.spacing.large};
  padding-left: ${({ theme }) => theme.spacing.large};
`

export const MAIN_CONTAINER_WIDTH = 1140
export const MIN_MAIN_CONTAINER_WIDTH = 750
export const HEADER_HEIGHT = 55

export const SMALL_SCREEN = 1000

export const GRADOO_GROUP_ID_PREFIX = 'R3JvdXBO'
