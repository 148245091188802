import { StepsBarIconProps } from 'components/StepsBar/types'

const TemplateIcon = ({ isActive }: StepsBarIconProps) => {
  const darkColor = isActive ? '#4F46E5' : '#64748B'
  const size = 16
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.61379 17.4475C1.88168 18.718 3.00765 19.5624 4.31835 19.4964C5.90215 19.4167 8.3817 19.2317 11.8746 18.8076C15.3675 18.3835 17.8186 17.9698 19.3749 17.6682C20.6628 17.4187 21.5488 16.33 21.4979 15.0332C21.4423 13.6154 21.2941 11.4716 20.9269 8.51434C20.5597 5.55709 20.179 3.44154 19.8862 2.05256C19.6183 0.782044 18.4923 -0.0623108 17.1816 0.00365419C15.5978 0.0833592 13.1183 0.268384 9.6254 0.692499C6.1325 1.11661 3.68133 1.53028 2.12508 1.83184C0.837164 2.08141 -0.0488013 3.17005 0.0020837 4.46689C0.0577137 5.88469 0.205899 8.02849 0.573084 10.9857C0.940264 13.943 1.32092 16.0585 1.61379 17.4475Z"
        fill={darkColor}
      />
      <path
        d="M1.24282 19.4549C1.35471 20.7278 2.34911 21.6798 3.62386 21.7687C5.2377 21.8812 7.8144 22 11.5 22C15.1856 22 17.7623 21.8812 19.3762 21.7687C20.6509 21.6798 21.6453 20.7278 21.7572 19.4549C21.8798 18.0605 22 15.9426 22 13C22 10.0575 21.8798 7.93955 21.7572 6.54515C21.6453 5.27225 20.6509 4.32025 19.3762 4.23135C17.7623 4.11885 15.1856 4.00005 11.5 4.00005C7.8144 4.00005 5.2377 4.11885 3.62386 4.23135C2.34911 4.32025 1.35471 5.27225 1.24282 6.54515C1.12026 7.93955 1 10.0575 1 13C1 15.9426 1.12026 18.0605 1.24282 19.4549Z"
        fill={isActive ? '#A5B4FC' : '#CBD5E1'}
      />
      <path
        d="M4.0879 18.4131C4.1949 18.7813 4.56905 18.9462 4.9524 18.9533C5.8961 18.9708 7.99675 19 11.5 19C15.0032 19 17.1039 18.9708 18.0476 18.9533C18.4309 18.9462 18.8051 18.7813 18.9121 18.4131C18.9626 18.2392 19 18.0195 19 17.75C19 17.4805 18.9626 17.2608 18.9121 17.0869C18.8051 16.7187 18.4309 16.5538 18.0476 16.5467C17.1039 16.5292 15.0032 16.5 11.5 16.5C7.99675 16.5 5.8961 16.5292 4.9524 16.5467C4.56905 16.5538 4.1949 16.7187 4.0879 17.0869C4.03735 17.2608 4 17.4805 4 17.75C4 18.0195 4.03735 18.2392 4.0879 18.4131Z"
        fill={darkColor}
      />
      <path
        d="M11.5519 12.8998C11.5979 13.7532 12.2466 14.4019 13.0999 14.4478C13.6448 14.4772 14.356 14.5 15.2498 14.5C16.1435 14.5 16.8547 14.4772 17.3996 14.4478C18.253 14.4019 18.9017 13.7532 18.9476 12.8998C18.977 12.3549 18.9998 11.6437 18.9998 10.75C18.9998 9.85623 18.977 9.14503 18.9476 8.60013C18.9017 7.74678 18.253 7.09808 17.3996 7.05213C16.8547 7.02278 16.1435 6.99998 15.2498 6.99998C14.356 6.99998 13.6448 7.02278 13.0999 7.05213C12.2466 7.09808 11.5979 7.74678 11.5519 8.60013C11.5226 9.14503 11.4998 9.85623 11.4998 10.75C11.4998 11.6437 11.5226 12.3549 11.5519 12.8998Z"
        fill={darkColor}
      />
      <path
        d="M4.01315 8.91885C4.04475 9.3975 4.4468 9.70925 4.92625 9.7234C5.3907 9.73715 6.07245 9.74995 7 9.74995C7.9276 9.74995 8.6093 9.73715 9.07375 9.7234C9.5532 9.70925 9.95525 9.3975 9.98685 8.91885C9.995 8.7954 10 8.656 10 8.49995C10 8.3439 9.995 8.2045 9.98685 8.08105C9.95525 7.6024 9.5532 7.29065 9.07375 7.2765C8.6093 7.26275 7.9276 7.24995 7 7.24995C6.07245 7.24995 5.3907 7.26275 4.92625 7.2765C4.4468 7.29065 4.04475 7.6024 4.01315 8.08105C4.005 8.2045 4 8.3439 4 8.49995C4 8.656 4.005 8.7954 4.01315 8.91885Z"
        fill={darkColor}
      />
      <path
        d="M4.01315 13.419C4.04475 13.8976 4.4468 14.2094 4.92625 14.2235C5.3907 14.2373 6.07245 14.2501 7 14.2501C7.9276 14.2501 8.6093 14.2373 9.07375 14.2235C9.5532 14.2094 9.95525 13.8976 9.98685 13.419C9.995 13.2955 10 13.1561 10 13.0001C10 12.844 9.995 12.7046 9.98685 12.5812C9.95525 12.1025 9.5532 11.7908 9.07375 11.7766C8.6093 11.7629 7.9276 11.7501 7 11.7501C6.07245 11.7501 5.3907 11.7629 4.92625 11.7766C4.4468 11.7908 4.04475 12.1025 4.01315 12.5812C4.005 12.7046 4 12.844 4 13.0001C4 13.1561 4.005 13.2955 4.01315 13.419Z"
        fill={darkColor}
      />
    </svg>
  )
}

export default TemplateIcon
