import { FormikContextType, useFormikContext } from 'formik'
import { Dispatch, SetStateAction } from 'react'
import styled, { css } from 'styled-components'

import CMYK from 'shared/entities/CMYK'
import { ColorCMYK } from 'shared/types/global'

import ColorsSet from '../ColorsSet'
import { SecondLevelPanels } from '../FrameControls'

const Grid = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 72px 1fr;
  grid-template-rows: 72px auto;
  grid-gap: 8px 0;
`

const Color = styled.div<{ value?: string; isActive?: boolean }>`
  background-color: ${({ value }) => value};
  border-radius: 8px;
  cursor: pointer;

  ${({ isActive, theme, value }) =>
    isActive &&
    css`
      box-shadow: 0px 0px 0px 2px ${theme.color.base.c0},
        0px 0px 0px 4px ${value};
    `}
`

const OtherColors = styled.div`
  width: 100%;
  justify-content: end;
  display: grid;
  grid-template-columns: repeat(4, 32px);
  grid-template-rows: repeat(2, 32px);
  grid-gap: 6px 12px;
`

const CurrentColor = styled(Color)`
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.05);
`

const Label = styled.span`
  width: 100%;
  text-align: center;
  font-size: 12px;
  font-weight: 600;
  color: ${({ theme }) => theme.color.base.c5};
`

const otherColors = [
  new CMYK({ c: 0, m: 100, y: 100, k: 37 }),
  new CMYK({ c: 64, m: 37, y: 0, k: 37 }),
  new CMYK({ c: 53, m: 72, y: 0, k: 8 }),
  new CMYK({ c: 100, m: 12, y: 50, k: 23 }),

  new CMYK({ c: 0, m: 45, y: 76, k: 0 }),
  new CMYK({ c: 0, m: 0, y: 0, k: 90 }),
  new CMYK({ c: 0, m: 26, y: 100, k: 0 }),
  new CMYK({ c: 100, m: 0, y: 7, k: 28 })
]

type FrameColorsProps = {
  labelId: string
  onClick: Dispatch<SetStateAction<SecondLevelPanels | null>>
}

const FrameColors: React.FC<FrameColorsProps> = ({
  onClick,
  labelId
}): JSX.Element => {
  const {
    values: formValues,
    setFieldValue
  }: FormikContextType<any> = useFormikContext()

  const currentColor = new CMYK({ ...formValues!.color })

  return (
    <ColorsSet
      labelId={labelId}
      currentColor={currentColor}
      onCurrentClick={() => onClick(SecondLevelPanels.color)}
      onOtherClick={(color: ColorCMYK) => {
        setFieldValue('palletteColor', color)
      }}
    />
  )
}

export default FrameColors
