import { useMutation } from '@apollo/client'
import { useState } from 'react'
import toast from 'react-hot-toast'
import { useIntl } from 'react-intl'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'

import { ButtonIntents } from 'components/Button'
import ColorSelector from 'components/ColorSelector'
import Flex from 'components/Flex'
import ModalButton from 'components/ModalButton'
import IslandModal, {
  IslandModalProps
} from 'components/modals/IslandModal'
import useAuth from 'data/gradoo/hooks/useAuth'
import { SET_THEME_COLOR } from 'data/layoutcreator/mutations/themes'
import { GET_THEME_COLORS } from 'data/layoutcreator/queries/themes'
import CMYK from 'entities/CMYK'
import { replaceSpaces } from 'helpers/string'
import { ColorCMYK } from 'types/global'

const CurrentColor = styled.div`
  margin-top: 16px;
  margin-bottom: 24px;
  height: 57px;
  border-radius: 15px;
`

const StyledColorSelector = styled(ColorSelector)`
  width: 295px;
`

type ChangeColorModalProps = Pick<
  IslandModalProps,
  'isOpen' | 'closeModal'
> & {
  name: string
  value: ColorCMYK
  templatePath: string
  colorKey: string
}

const ChangeColorModal: React.FC<ChangeColorModalProps> = ({
  isOpen,
  closeModal,
  name,
  value,
  templatePath,
  colorKey
}) => {
  const { formatMessage } = useIntl()
  const { authGroupId } = useAuth()
  const { pathname } = useLocation()
  const projectId = pathname.split('/')[1]

  const themeVars = {
    groupId: authGroupId!,
    projectId: replaceSpaces(projectId),
    templatePath
  }

  const [color, setColor] = useState<ColorCMYK>(value)

  const [setThemeColor, { loading }] = useMutation(SET_THEME_COLOR, {
    refetchQueries: [
      {
        query: GET_THEME_COLORS,
        variables: themeVars
      }
    ]
  })

  const handleSave = async () => {
    const { c, m, y, k } = color

    await toast.promise(
      setThemeColor({
        variables: {
          ...themeVars,
          key: colorKey,
          value: { c, m, y, k }
        }
      }),
      {
        loading: formatMessage({ id: 'Toasts.colors.change' }),
        success: formatMessage({
          id: 'Toasts.colors.change.success'
        }),
        error: formatMessage({ id: 'Toasts.colors.change.error' })
      }
    )

    closeModal()
  }

  const handleCancel = () => {
    setColor(value)
    closeModal()
  }

  return (
    <IslandModal
      isOpen={isOpen}
      closeModal={closeModal}
      title={formatMessage(
        {
          id: 'Project.settings.templates.colors.change.modal.title'
        },
        { name: name.toLowerCase() }
      )}
    >
      <Flex direction="column">
        <CurrentColor
          style={{ background: new CMYK(color).toCSS() }}
        />

        <StyledColorSelector
          value={new CMYK(color)}
          onChange={(field, value) => {
            setColor({
              ...color,
              [field]: value
            })
          }}
        />

        <Flex marginTop={48} columnGap={7}>
          <ModalButton
            labelId="Actions.cancel"
            isFullWidth
            intent={ButtonIntents.neutral}
            onClick={handleCancel}
            isDisabled={loading}
          />
          <ModalButton
            labelId="Project.settings.templates.colors.buttons.changeColor"
            intent={ButtonIntents.dark}
            isFullWidth
            onClick={handleSave}
            isDisabled={loading}
          />
        </Flex>
      </Flex>
    </IslandModal>
  )
}

export default ChangeColorModal
