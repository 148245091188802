import { useNavigate, useParams } from 'react-router-dom'

import useAuth from 'data/gradoo/hooks/useAuth'
import ProjectSettings from 'shared/components/ProjectSettings'
import withRoom from 'shared/hocs/withRoom'
import { useLiveUsers } from 'shared/hooks/useLiveUsers'
import IslandLayout from 'shared/layout/Island'
import OrderBlock from './components/OrderBlock'

const ProjectSettingsScreen: React.FC = () => {
  const liveUsers = useLiveUsers()
  const navigate = useNavigate()

  const { authGroupId: groupId } = useAuth()
  const { projectId } = useParams()

  return (
    <IslandLayout
      showTools={false}
      liveUsers={liveUsers}
      onBack={() => navigate(-1)}
      marginBottom={120}
      noBackground
    >
      <ProjectSettings
        groupId={groupId!}
        projectId={projectId!}
        exportBlock={{
          titleId: 'Project.settings.order.block.title',
          descriptionId: 'Project.settings.order.block.description',
          renderContent: (props) => <OrderBlock {...props} />
        }}
      />
    </IslandLayout>
  )
}

export default withRoom(ProjectSettingsScreen)
