import { ReactNode, forwardRef } from 'react'
import styled, { css } from 'styled-components'

import { ResponsiveProps, responsiveStyles } from 'shared/styles/utils'

export type FlexDivProps = {
  centered?: boolean
  flex?: number | string
  wrap?: 'wrap' | 'nowrap' | 'wrap-reverse'
  justifyContent?:
    | 'flex-start'
    | 'flex-end'
    | 'center'
    | 'space-between'
    | 'space-around'
    | 'space-evenly'
  alignItems?:
    | 'flex-start'
    | 'flex-end'
    | 'center'
    | 'baseline'
    | 'stretch'
  alignSelf?: string
  direction?: 'row' | 'row-reverse' | 'column' | 'column-reverse'
  columnGap?: number
  rowGap?: number
  marginTop?: number
  marginRight?: number
  marginBottom?: number
  marginLeft?: number
  paddingTop?: number
  paddingRight?: number
  paddingBottom?: number
  paddingLeft?: number
  width?: string | number
  height?: string | number
  style?: React.CSSProperties
  responsive?: ResponsiveProps
  grow?: number
}

interface FlexProps extends FlexDivProps {
  children: ReactNode
  onClick?: () => void
}

const Div = styled.div<FlexDivProps>`
  display: flex;
  flex: ${({ flex }) => flex};
  flex-wrap: ${({ wrap }) => wrap};
  justify-content: ${({ justifyContent }) => justifyContent};
  align-items: ${({ alignItems }) => alignItems};
  align-self: ${({ alignSelf }) => alignSelf};
  flex-direction: ${({ direction }) => direction};
  flex-grow: ${({ grow }) => grow};
  column-gap: ${({ columnGap }) => columnGap && `${columnGap}px`};
  row-gap: ${({ rowGap }) => rowGap && `${rowGap}px`};
  margin-top: ${({ marginTop }) => marginTop && `${marginTop}px`};
  margin-right: ${({ marginRight }) =>
    marginRight && `${marginRight}px`};
  margin-bottom: ${({ marginBottom }) =>
    marginBottom && `${marginBottom}px`};
  margin-left: ${({ marginLeft }) => marginLeft && `${marginLeft}px`};
  padding-top: ${({ paddingTop }) => paddingTop && `${paddingTop}px`};
  padding-right: ${({ paddingRight }) =>
    paddingRight && `${paddingRight}px`};
  padding-left: ${({ paddingLeft }) =>
    paddingLeft && `${paddingLeft}px`};
  padding-bottom: ${({ paddingBottom }) =>
    paddingBottom && `${paddingBottom}px`};

  ${({ centered }) =>
    centered &&
    css`
      align-items: center;
      justify-content: center;
    `}
  width: ${({ width }) =>
    width && typeof width === 'number' ? `${width}px` : width};
  height: ${({ height }) =>
    height && typeof height === 'number' ? `${height}px` : height};
  ${responsiveStyles}
`

const Flex = (props: FlexProps, ref: any) => (
  <Div ref={ref} {...props}>
    {props.children}
  </Div>
)

export default forwardRef(Flex)
