import Flex from "components/Flex"
import styled from "styled-components"

import guideActionImage from './images/guide-action.jpg'
import { useIntl } from "react-intl"

const INTERCOM_ARTICLE_ID = 8816285

const Container = styled(Flex)`
  flex-direction: column;
  height: 192px;
  padding: 24px;
  border-radius: 14px;
  border: 1px solid ${({ theme }) => theme.color.base.c3};
  background: url(${guideActionImage});
  background-size: cover;
  background-position: center;
  box-shadow: -2px 2px 1px 0px rgba(203, 213, 225, 0.20);
  cursor: pointer;
`

const Title = styled.h3`
  color: ${({ theme }) => theme.color.brand_02};
  font-size: 16px;
  font-weight: 600;
  margin: 0;
  margin-bottom: 4px;
`

const SubTitle = styled.h4`
  color: ${({ theme }) => theme.color.base.c6};
  font-size: 12px;
  font-weight: 400;
  margin: 0;
`

const StartDesigningPanelGuide = () => {
  const { formatMessage } = useIntl()

  const openIntercomGuide = () => {
    (window as any).Intercom('showArticle', INTERCOM_ARTICLE_ID)
  }

  return (
    <Container onClick={openIntercomGuide}>
      <Title>{formatMessage({ id: 'Panel.startDesigning.guide.title' })}</Title>
      <SubTitle>{formatMessage({ id: 'Panel.startDesigning.guide.description' })}</SubTitle>
    </Container>
  )
}

export default StartDesigningPanelGuide
