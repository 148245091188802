import * as yup from 'yup'

import { AdsPlacement } from 'shared/types/layoutcreator/graphql'

const REQUIRED = 'Form.field.is.required'
const MIN_3 = 'Form.field.min.3'
const MIN_4 = 'Form.field.min.4'
const MAX_5 = 'Form.field.max.5'
const CONTAIN_LETTERS = 'Form.field.contain.letters'
const CONTAIN_DIGITS = 'Form.field.contain.digits'
const NO_LETTERS = 'Form.field.no.letters'
const NO_DIGITS = 'Form.field.no.digits'

const strictString = yup
  .string()
  .trim()
  .ensure()
  .min(3, MIN_3)
  .matches(/^(\D*)$/, NO_DIGITS)
  .required(REQUIRED)

export const verifyAddressSchema = yup
  .object({
    firstName: strictString,
    lastName: strictString,
    phoneNumber: yup.string().required(REQUIRED),
    streetAndNumber: yup
      .string()
      .matches(/\D/, CONTAIN_LETTERS)
      .matches(/\d/, CONTAIN_DIGITS)
      .required(REQUIRED),
    companyOrAdditionalInfo: yup.string(),
    zip: yup
      .string()
      .min(4, MIN_4)
      .max(5, MAX_5)
      .matches(/^(\d*)$/, NO_LETTERS)
      .required(REQUIRED),
    city: strictString,
    countryCode: yup.string().required(REQUIRED)
  })
  .required()

export const setUpPrintDataSchema = yup
  .object({
    bnwPages: yup.string().matches(/^[\d,-]+$/, {
      message: 'Form.field.bnw.matches.error.message',
      excludeEmptyString: true
    }),
    adsPlacement: yup
      .string()
      .oneOf(Object.values(AdsPlacement))
      .required(REQUIRED),
    cover: yup
      .mixed<File>()
      .test('files-chosen', REQUIRED, value => !!value?.name)
      .required(REQUIRED)
  })
  .required()
