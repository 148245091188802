import styled from 'styled-components'

import Flex from 'shared/ui/Flex'

export const Container = styled(Flex).attrs({
  columnGap: 8
})`
  padding: 16px;
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.color.base.c3};
  box-shadow: -2px 2px 1px rgba(203, 213, 225, 0.2);
  cursor: pointer;
`

const Title = styled.span`
  color: ${({ theme }) => theme.color.base.c7};
  font-family: Inter;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
`

type CollageItemProps = {
  title: string
  onClick: () => void
}

const ReportItem: React.FC<CollageItemProps> = ({
  title,
  ...rest
}) => {
  return (
    <Container {...rest}>
      <Title>{title}</Title>
    </Container>
  )
}
export default ReportItem
