import { useIntl } from 'react-intl'
import styled from 'styled-components'

import Button from 'components/Button_2'
import Flex from 'components/Flex'
import IslandModal from 'components/modals/IslandModal'

import heartsStickerSrc from './images/hearts-sticker.png'
import todoStickerSrc from './images/todo-sticker.png'

const Headline = styled.div`
  font-size: 32px;
  font-weight: 600;
  line-height: 38px;
`

const Text = styled.div`
  font-size: 22px;
  line-height: 28px;
  color: ${({ theme }) => theme.color.base.c7};
`

const HeartSticker = styled.img`
  width: 138px;
`

const ToDoSticker = styled.img`
  width: 77px;
  margin-bottom: 20px;
`

export enum Status {
  prepareForProduction = 'prepareForProduction',
  checkReport = 'checkReport',
  needApproval = 'needApproval'
}

type StatusModalProps = {
  closeModal: () => void
  isOpen: boolean
  modalWidth: number
  status: Status
}
const StatusModal = ({
  closeModal,
  isOpen,
  modalWidth,
  status
}: StatusModalProps) => {
  const { formatMessage } = useIntl()

  return (
    <IslandModal
      closeModal={closeModal}
      isOpen={isOpen}
      width={modalWidth}
    >
      {status === Status.needApproval ? (
        <ToDoSticker src={todoStickerSrc} />
      ) : (
        <HeartSticker src={heartsStickerSrc} />
      )}
      <Flex
        direction="column"
        rowGap={32}
        width={644}
        marginBottom={80}
      >
        <Headline>
          {formatMessage({
            id: `Status.modal.headline.${status}`
          })}
        </Headline>
        <Text>
          {formatMessage({
            id: `Status.modal.text.${status}`
          })}
        </Text>
      </Flex>
      <Flex columnGap={16}>
        {status === Status.needApproval ? (
          <>
            <Button
              intent="primary-brand-02"
              textId="Status.modal.send.new.data"
              onPress={closeModal}
              size="xl"
            />
            <Button
              intent="secondary-base"
              textId="Status.modal.contact.support"
              href="mailto:support@gradoo.com"
              size="xl"
            />
            <Button
              intent="secondary-base"
              textId="Status.modal.close"
              onPress={closeModal}
              size="xl"
            />
          </>
        ) : (
          <>
            <Button
              intent="secondary-base"
              textId="Status.modal.continue"
              onPress={closeModal}
              size="xl"
            />
            <Button
              intent="primary-brand-02"
              textId="Status.modal.contact.support"
              href="mailto:support@gradoo.com"
              size="xl"
            />
          </>
        )}
      </Flex>
    </IslandModal>
  )
}

export default StatusModal
