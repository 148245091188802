import { useMutation, useQuery } from '@apollo/client'
import { DragOverlay } from '@dnd-kit/core'
import React, { useContext, useMemo, useState } from 'react'
import toast from 'react-hot-toast'
import { useIntl } from 'react-intl'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'

import Flex from 'shared/ui/Flex'
import { Skeleton } from 'shared/ui/Skeleton'
import TabBar, { TabBarIntents } from 'shared/ui/TabBar'
import useAuth from 'data/gradoo/hooks/useAuth'
import { PROFILE_PAGE_PHOTO_CATEGORIES } from 'data/gradoo/queries/profile'
import { USER_GROUP } from 'data/gradoo/queries/user'
import {
  ADD_NEW_CHILI_IMAGE,
  REPLACE_CHILI_IMAGE
} from 'data/layoutcreator/mutations/images'
import { isDev } from 'shared/helpers/env'
import { fetchImageFile } from 'shared/helpers/file'
import { getRestUrl } from 'shared/helpers/url'
import { useClickToCopy } from 'shared/hooks/useClickToCopy'
import { EditorContext } from 'screens/YearbookEditor'
import ChiliImages from 'shared/components/Tools/services/ChiliImages'
import {
  Draggable,
  useContentDnd
} from 'screens/YearbookEditor/providers/ContentDndProvider'
import { Ids } from 'screens/YearbookEditor/providers/ContentDndProvider/types'
import { ImageSources } from 'shared/types/global'
import { ProfilePagePhotoCategoryFragment } from 'shared/types/gradoo/graphql'

import { PanelProps } from '../../types'
import CommentsTab, {
  CommentDragOverlayContent
} from './tabs/CommentsTab'
import QuestionsAnswersTab, {
  QuestionAnswerDragOverlayContent
} from './tabs/QuestionsAnswersTab'
import { TabProps } from './types'

const imagesApi = ChiliImages.getInstance()

const Container = styled.div``

const TitleView = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 24px;
  align-items: center;
`

const Title = styled.h2`
  font-size: 17px;
  font-weight: 600;
`

const Images = styled.div`
  display: flex;
  gap: 22px;
  overflow-x: auto;
  flex-wrap: nowrap;
  padding-bottom: 20px;
`

const Image = styled.div`
  flex: 0 0 auto;
  height: 114px;
  width: 86px;
  background-color: ${({ theme }) => theme.color.base.c3};
  background-position: center;
  background-size: cover;
  border-radius: 14px;
  cursor: pointer;
`

const ImageSkeleton = styled(Skeleton)`
  flex: 0 0 auto;
  height: 114px;
  width: 86px;
`

const TabTitle = styled.h3`
  font-family: TTFirsNeue, sans-serif;
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 22px;
`

const tabs: {
  labelId: string
  titleId: string
  component: React.FC<TabProps>
}[] = [
  {
    labelId: 'Panel.profileDetails.tabs.q&a.label',
    titleId: 'Panel.profileDetails.tabs.q&a.title',
    component: QuestionsAnswersTab
  },
  {
    labelId: 'Panel.profileDetails.tabs.comments.label',
    titleId: 'Panel.profileDetails.tabs.comments.title',
    component: CommentsTab
  }
]

type ProfileDetailsPanelProps = PanelProps & {
  moduleInstanceId: string
  userGroupId: string
}

const ProfilePhotoDragOverlayContent = () => {
  const { draggingContent } = useContentDnd()

  return (
    <Image
      style={{
        backgroundImage: `url('${draggingContent.imageUrl}')`
      }}
    />
  )
}

const DragOverlayContent = () => {
  const { draggingContent } = useContentDnd()

  return draggingContent.imageUrl ? (
    <ProfilePhotoDragOverlayContent />
  ) : draggingContent.profileQuestion ? (
    <QuestionAnswerDragOverlayContent />
  ) : draggingContent.profileComment ? (
    <CommentDragOverlayContent />
  ) : (
    <></>
  )
}

const ProfileDetailsPanel: React.FC<ProfileDetailsPanelProps> = ({
  moduleInstanceId,
  userGroupId,
  push
}) => {
  const { pathname } = useLocation()
  const projectId = pathname.split('/')[1]
  const { authGroupId } = useAuth()

  const { isWorkspaceRendered } = useContext(EditorContext)
  const { formatMessage } = useIntl()
  const { register } = useClickToCopy()

  const { draggingContent } = useContentDnd()

  const { data: userGroupData, loading: userGroupLoading } = useQuery(
    USER_GROUP,
    {
      skip: !userGroupId,
      variables: {
        id: userGroupId as string
      },
      context: { client: 'gradoo' }
    }
  )

  const [addNewChiliImage] = useMutation(ADD_NEW_CHILI_IMAGE)
  const [replaceChiliImage] = useMutation(REPLACE_CHILI_IMAGE)

  const userGroup = userGroupData?.userGroup

  const {
    data: photoCategoriesData,
    loading: photoCategoriesLoading
  } = useQuery(PROFILE_PAGE_PHOTO_CATEGORIES, {
    skip: !moduleInstanceId,
    variables: {
      moduleInstance: moduleInstanceId as string,
      ppqUserGroup: userGroupId
    },
    context: { client: 'gradoo' }
  })

  const photoCategories: ProfilePagePhotoCategoryFragment[] =
    (photoCategoriesData?.profilePagePhotoCategories?.edges.map(
      edge => edge?.node
    ) as ProfilePagePhotoCategoryFragment[]) || []

  const [activeTab, setActiveTab] = useState(0)
  const currentTab = useMemo(() => tabs[activeTab], [activeTab])
  const CurrentTabComponent = useMemo(
    () => currentTab.component,
    [activeTab]
  )

  const fullName = `${userGroup?.user.firstName} ${userGroup?.user.lastName}`

  const loading =
    !isWorkspaceRendered || userGroupLoading || photoCategoriesLoading

  const handleImageClick = async (url: string, photoId: string) => {
    const file = await fetchImageFile(url)

    const selectedFrame = await imagesApi.getSelectedFrame()

    if (selectedFrame) {
      const loadingToastId = toast.loading(
        formatMessage({ id: 'Toasts.components.uploading' })
      )

      const { data } = await replaceChiliImage({
        variables: {
          projectId,
          groupId: authGroupId,
          image: file,
          oldImageId: selectedFrame.externalID
        }
      })

      imagesApi
        .replaceImage(data.replaceChiliImage)
        .then(() => {
          toast.remove(loadingToastId)
        })
        .catch(() => {
          toast.error(
            formatMessage({
              id: 'Toasts.components.replacing.error'
            })
          )
          toast.remove(loadingToastId)
        })
    } else {
      const { data } = await addNewChiliImage({
        variables: {
          projectId,
          groupId: authGroupId,
          image: file,
          source: ImageSources.profile
        }
      })

      imagesApi.createNewFrame({
        ...data.addNewChiliImage,
        imageUrl: url
      })
    }
  }

  if (loading) {
    return (
      <Container>
        <TitleView>
          <Skeleton
            height={17}
            width={80}
            paddingTop={14}
            paddingBottom={14}
            radius={0}
          />
        </TitleView>
        <Images>
          {new Array(5).fill(null).map((_, i) => (
            <ImageSkeleton key={i} />
          ))}
          {photoCategories.map(photoCategory => {
            const url =
              photoCategory?.profilePagePhotos?.edges?.[0]?.node
                ?.image || ''
            return (
              <Image
                key={photoCategory.id}
                style={{
                  backgroundImage: `url('${url}')`
                }}
              />
            )
          })}
        </Images>
        <TabBar
          activeIndex={activeTab}
          onChange={setActiveTab}
          intent={TabBarIntents.free}
          tabs={tabs.map(tab => formatMessage({ id: tab.labelId }))}
        />
      </Container>
    )
  }

  return (
    <Container>
      <TitleView>
        <Title {...register()}>{fullName}</Title>
      </TitleView>
      <Images>
        {photoCategories.map(photoCategory => {
          const url =
            photoCategory?.profilePagePhotos?.edges?.[0]?.node
              ?.image || ''
          return (
            <Draggable
              id={photoCategory.id}
              key={photoCategory.id}
              type={Ids.image}
              onDragStart={() => draggingContent.setImageUrl(url)}
              onDragEnd={() => draggingContent.setImageUrl(null)}
            >
              <Image
                data-testid={`ProfilePhotoCategory:${photoCategory.id}`}
                key={photoCategory.id}
                style={{
                  backgroundImage: `url('${url}')`
                }}
                onClick={() =>
                  handleImageClick(url, photoCategory.id)
                }
              />
            </Draggable>
          )
        })}
      </Images>
      <TabBar
        activeIndex={activeTab}
        onChange={setActiveTab}
        intent={TabBarIntents.free}
        tabs={tabs.map(tab => formatMessage({ id: tab.labelId }))}
      />
      <Flex marginTop={32} direction="column">
        <TabTitle>
          {formatMessage({ id: currentTab.titleId }, { fullName })}
        </TabTitle>
        <CurrentTabComponent
          moduleInstanceId={moduleInstanceId}
          userGroupId={userGroupId}
          push={push}
        />
      </Flex>

      <DragOverlay>
        <DragOverlayContent />
      </DragOverlay>
    </Container>
  )
}

export default ProfileDetailsPanel
