import Flex from 'shared/ui/Flex'
import { ReactChildren } from 'shared/types/global'

const FooterContainer = ({ children }: ReactChildren) => (
  <Flex direction="row" justifyContent="space-between" marginTop={48}>
    {children}
  </Flex>
)

export default FooterContainer
