import { useIntl } from 'react-intl'
import styled from 'styled-components'

import Flex from 'components/Flex'

import BaseModal, { BaseModalProps } from '../BaseModal'

const StyledBaseModal = styled(BaseModal)<{ width?: number }>`
  border-radius: 35px;
  padding: 48px 24px 32px 24px;
  border: 1px solid ${({ theme }) => theme.color.base.c2};
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.05);
  ${({ width }) => `width: ${width}px;`}
`

const Title = styled.h2<{
  align: IslandModalProps['titleAlign']
  size: number
}>`
  flex: 1;
  font-weight: 600;
  font-size: ${({ size }) => `${size}px`};
  text-align: ${({ align }) => align};
  margin: 0;
`

const MODAL_STYLES: BaseModalProps['style'] = {
  overlay: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'rgba(15, 23, 42, .5)'
  }
}

export type IslandModalProps = BaseModalProps & {
  titleId?: string
  title?: string
  titleAlign?: 'center' | 'left'
  titleMargin?: number
  titleSize?: number
  renderHeaderAppend?: () => JSX.Element
  height?: number
  width?: number
}

const IslandModal: React.FC<IslandModalProps> = ({
  width,
  titleId,
  titleAlign = 'center',
  title,
  titleMargin = 32,
  titleSize = 20,
  children,
  ...rest
}) => {
  const { formatMessage } = useIntl()

  return (
    <StyledBaseModal {...rest} width={width} style={MODAL_STYLES}>
      {(titleId || title) && (
        <Flex
          width="100%"
          justifyContent="space-between"
          alignItems="center"
          marginBottom={titleMargin}
        >
          <Title align={titleAlign} size={titleSize}>
            {titleId ? formatMessage({ id: titleId }) : title}
          </Title>

          {rest.renderHeaderAppend && rest.renderHeaderAppend()}
        </Flex>
      )}

      <div>{children}</div>
    </StyledBaseModal>
  )
}

export default IslandModal
