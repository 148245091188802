import { useIntl } from 'react-intl'

import Avatar from 'components/Avatar'
import IconButton, { IconButtonIntents } from 'components/IconButton'
import ProgressLine from 'components/ProgressLine'
import { UserSummaryFragment } from 'types/gradoo/graphql'

import {
  ArrowPlaceholder,
  Center,
  Container,
  Counts,
  Name
} from './Style'

type ProfileItemProps = {
  userSummary: UserSummaryFragment
  totalQuestions: number
  totalImages: number
  onButtonClick: () => void
  withBorder?: boolean
  withArrow?: boolean
}

const ProfileItem: React.FC<ProfileItemProps> = ({
  userSummary,
  totalQuestions,
  totalImages,
  onButtonClick,
  withBorder = false,
  withArrow = false
}) => {
  const { formatMessage } = useIntl()

  const currentProgress =
    (userSummary.questionsAnswered || 0) +
    (userSummary.imagesUploaded || 0)
  const totalProgress = totalQuestions + totalImages

  const percentage = (currentProgress / totalProgress) * 100

  return (
    <Container withBorder={withBorder}>
      <Avatar
        src={
          userSummary.userGroup?.avatar ||
          userSummary.userGroup?.defaultAvatar?.avatar ||
          ''
        }
      />
      <Center>
        <Name>
          {`${userSummary.userGroup?.user?.firstName} ${userSummary.userGroup?.user?.lastName}`}
        </Name>
        <ProgressLine percentage={percentage} />
        <Counts>
          {formatMessage(
            { id: 'Panel.profilePage.item.answersCount' },
            {
              count: userSummary.questionsAnswered,
              from: totalQuestions
            }
          )}
          {' | '}
          {formatMessage(
            { id: 'Panel.profilePage.item.imagesCount' },
            { count: userSummary.imagesUploaded, from: totalImages }
          )}
        </Counts>
      </Center>

      {withArrow ? (
        <IconButton
          data-testid={`ProfileItemIconButton:${userSummary.userGroup.id}`}
          onClick={onButtonClick}
          icon="arrow-right-filled"
          size={14}
          intent={IconButtonIntents.secondary}
        />
      ) : (
        <ArrowPlaceholder />
      )}
    </Container>
  )
}

export default ProfileItem
export { default as ProfilePageItemSkeleton } from './Skeleton'
