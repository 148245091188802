import React from 'react'

import { ReactComponent as plus } from 'assets/icons/add_sign.svg'
import { ReactComponent as arrow_narrow_left } from 'assets/icons/arrow-narrow-left.svg'
import { ReactComponent as refresh } from 'assets/icons/refresh.svg'
import { theme } from 'styles/theme'

import { ReactComponent as home } from './icons/home-02.svg'
import { ReactComponent as map } from './icons/map-02.svg'
import { ReactComponent as printer } from './icons/printer.svg'
import { ReactComponent as save } from './icons/save-01.svg'
import { ReactComponent as upload } from './icons/upload-01.svg'
import { ReactComponent as user } from './icons/user-03.svg'

export const iconSet = {
  general: [
    'arrow_narrow_left',
    'plus',
    'refresh',
    'home',
    'map',
    'user',
    'save',
    'upload',
    'printer'
  ]
} as const

export type IconName = typeof iconSet['general'][number]

const svgIconMap: {
  [key in IconName]: React.FC<React.SVGProps<SVGSVGElement>>
} = {
  arrow_narrow_left: arrow_narrow_left,
  plus: plus,
  refresh,
  home,
  map,
  user,
  save,
  upload,
  printer
}

export interface IconProps {
  testID?: string
  name: IconName
  size?: number
  color?: string
  fill?: string
  strokeFill?: string
}

const Icon: React.FC<IconProps> = ({
  name,
  color = theme.color.base.c9,
  fill = 'none',
  size = 24
}) => {
  const SvgIcon = svgIconMap[name]

  return (
    <SvgIcon width={size} height={size} color={color} fill={fill} />
  )
}

export default Icon
