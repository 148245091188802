import styled from 'styled-components'

import Flex from 'shared/ui/Flex'
import { ReactChildren } from 'shared/types/global'

import SubTitle from '../SubTitle'

const Description = styled.span`
  color: ${({ theme }) => theme.color.base.c6};
  font-size: 12px;
`

type SubSectionProps = {
  title: string
  description?: string
} & ReactChildren

const ProjectTemplatesSubSection: React.FC<SubSectionProps> = ({
  title,
  description = '',
  children
}) => {
  return (
    <Flex direction="column" rowGap={32}>
      <div>
        <SubTitle marginBottom={4}>{title}</SubTitle>

        <Description>{description}</Description>
      </div>

      <div>{children}</div>
    </Flex>
  )
}

export default ProjectTemplatesSubSection
