import { useMutation } from '@apollo/client'
import { PublisherInterface } from '@chili-publish/publisher-interface'
import { theme } from 'gradoo-theme'
import { useEffect, useRef, useState } from 'react'
import toast from 'react-hot-toast'
import { useIntl } from 'react-intl'
import { useLocation } from 'react-router-dom'
import {
  ArrowContainer,
  Popover,
  PopoverState
} from 'react-tiny-popover'
import styled from 'styled-components'

import FileUpload from 'components/FileUpload'
import Flex from 'components/Flex'
import useAuth from 'data/gradoo/hooks/useAuth'
import { ADD_NEW_CHILI_IMAGE } from 'data/layoutcreator/mutations/images'
import CMYK from 'entities/CMYK'
import { DevLogger } from 'helpers/Logger'
import { replaceSpaces } from 'helpers/string'
import useFont from 'screens/YearbookEditor/components/Tools/hooks/useFont'
import { SMALL_SCREEN } from 'styles/constants'
import { FileTypes } from 'types/global'

import LeftPanel from './components/LeftPanel'
import RightPanel from './components/RightPanel'
import { useTextMigration } from './hooks/useTextMigration'
import gradooSrc from './images/gradoo.svg'
import imagesSrc from './images/images.svg'
import shapesSrc from './images/shapes.svg'
import textSrc from './images/text.svg'
import ChiliColors from './services/ChiliColors'
import ChiliImages from './services/ChiliImages'
import ChiliShapes from './services/ChiliShapes'
import { Font, Frame, ShapeTypes, TextFormat } from './types'

const shapesApi = ChiliShapes.getInstance()
const imagesApi = ChiliImages.getInstance()
const colorsApi = ChiliColors.getInstance()

const SHAPES_DEFAULT_COLOR = new CMYK({ c: 0, m: 0, y: 0, k: 100 })

const logger = DevLogger('YearbooksEditor/Tools')

const Tool = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 4px;
  font-size: 8px;
  font-weight: 600;
  cursor: pointer;
`
const PopoverContent = styled.div`
  background-color: white;
  border: 1px solid ${({ theme }) => theme.color.base.c2};
  border-radius: 4px;
`

const ToolIcon = styled.img`
  width: 24px;
  height: 24px;
`

enum ToolsTypes {
  text = 'text',
  image = 'image',
  shape = 'shape',
  toggle = 'toggle'
}

const TOOLS = [
  {
    id: 'text',
    type: ToolsTypes.text,
    icon: textSrc
  },
  {
    id: 'images',
    type: ToolsTypes.image,
    icon: imagesSrc
  },
  {
    id: 'shapes',
    type: ToolsTypes.shape,
    icon: shapesSrc
  }
]

const Tools = ({
  publisherConnector,
  isDocumentLoaded
}: {
  publisherConnector: PublisherInterface
  isDocumentLoaded: boolean
}): JSX.Element => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const [isRightPanelVisible, setIsRightPanelVisible] =
    useState(false)
  const { formatMessage } = useIntl()

  const [isLeftPanelShown, setIsLeftPanelShown] =
    useState<boolean>(true) // TODO: just make use of selectedFrame 🤔
  const [selectedFrame, setSelectedFrame] = useState<Frame | null>(
    null
  )
  const [selectedTextFormat, setSelectedTextFormat] =
    useState<TextFormat | null>(null)
  const [selectedFont, setSelectedFont] = useState<Font | null>(null)

  const imageInputRef = useRef<HTMLInputElement>(null)
  const { pathname } = useLocation()
  const projectId = pathname.split('/')[1]
  const groupId = useAuth().authGroupId!

  const [addNewChiliImage] = useMutation(ADD_NEW_CHILI_IMAGE)

  const { setFont } = useFont(selectedFont, publisherConnector)
  const { migrate } = useTextMigration({
    publisherConnector
  })
  const gradooToolRef = useRef(null)

  const handleResize = () => {
    setWindowWidth(window.innerWidth)
  }

  useEffect(() => {
    if (isDocumentLoaded) {
      migrate()
    }
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [isDocumentLoaded])

  const toggleRightPanel = () => {
    setIsRightPanelVisible(!isRightPanelVisible)
  }

  const isSmallScreen = windowWidth < SMALL_SCREEN

  const tools = [
    ...TOOLS.slice(0, TOOLS.length - 1),
    ...(isSmallScreen
      ? [
          {
            id: 'togglePanel',
            type: ToolsTypes.toggle,
            icon: gradooSrc
          }
        ]
      : []),
    TOOLS[TOOLS.length - 1] // Add the last element back
  ]

  const handleToolClick = async (type: ToolsTypes) => {
    if (type === ToolsTypes.image) {
      imageInputRef.current!.click()
      return
    }

    const newFrame = await addFrame(type)

    if (type === ToolsTypes.shape) {
      await shapesApi.changeShapeType(
        ShapeTypes.ellipse,
        newFrame.javaScriptDescriptor
      )
      await publisherConnector.setProperty(
        newFrame.javaScriptDescriptor,
        'hasFill',
        'true'
      )
      const newColor = await colorsApi.addNewColor(
        SHAPES_DEFAULT_COLOR
      )
      await shapesApi.setColor(
        newFrame.javaScriptDescriptor,
        newColor.path
      )
    } else if (type === ToolsTypes.text) {
      await publisherConnector.setProperty(
        newFrame.javaScriptDescriptor,
        'autoGrowFrame',
        'true'
      )
      const textFlowXml = `
        <TextFlow>
          <p><span fontFamily="Inter_Regular">Lorem ipsum dolor sit amet,\n consectetur adipiscing elit.\n Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</span></p>
        </TextFlow>`

      await publisherConnector.executeFunction(
        `document.pages[0].frames[${newFrame.id}]`,
        'ImportTextFlow',
        textFlowXml,
        true
      )
    }

    setIsLeftPanelShown(true)
  }

  const addFrame = async (type: ToolsTypes) => {
    const newFrame = (await publisherConnector.executeFunction(
      'document.pages[1].frames',
      'Add',
      type,
      '10mm',
      '50mm',
      '100mm',
      '70mm'
    )) as Frame

    return newFrame
  }

  const handleImageSelect = async (selectedImage: File) => {
    const loadingToastId = toast.loading(
      formatMessage({ id: 'Toasts.components.uploading' })
    )

    logger.debug([
      'handleImageSelect() | projectId:',
      replaceSpaces(projectId)
    ])

    const { data } = await addNewChiliImage({
      variables: {
        image: selectedImage,
        projectId,
        groupId
      }
    })

    logger.debug([
      'handleImageSelect() | Mutation performed',
      JSON.stringify(data)
    ])

    imagesApi
      .createNewFrame(data.addNewChiliImage)
      .then(() => {
        toast.remove(loadingToastId)
      })
      .catch(() => {
        toast.error(
          formatMessage({
            id: 'Toasts.components.uploading.error'
          })
        )
        toast.remove(loadingToastId)
      })
  }

  useEffect(() => {
    if (selectedTextFormat?.font === null) {
      setFont()
    }
  }, [selectedTextFormat])

  // Listeners
  useEffect(() => {
    if (publisherConnector) {
      const addTextSelectionChangedListener = async () =>
        publisherConnector.addListener(
          'TextSelectionChanged',
          async selectedFrameId => {
            if (selectedFrameId) {
              const textFormat = (await publisherConnector.getObject(
                'document.selectedText.textFormat'
              )) as TextFormat
              setSelectedTextFormat(textFormat)

              const fontInfo = (await publisherConnector.getObject(
                'document.selectedText.textFormat.font'
              )) as { id: string }

              if (fontInfo) {
                const fontInstance =
                  (await publisherConnector.getObject(
                    `cp_object:document.fonts[${fontInfo.id}]`
                  )) as Font
                setSelectedFont(fontInstance)
              }
            }
          }
        )
      addTextSelectionChangedListener().catch(err =>
        console.log('TextSelectionChanged err', err)
      )

      // Frame select listener
      const addSelectListener = async () =>
        publisherConnector.addListener(
          'SelectedFrameChanged',
          async (frameId: string) => {
            if (frameId) {
              setIsLeftPanelShown(true)
              const frame = (await publisherConnector.getObject(
                `document.selectedFrame`
              )) as Frame
              setSelectedFrame(frame)

              if (frame.type === 'text') {
                await publisherConnector.executeFunction(
                  'document',
                  'SetCursor',
                  'text'
                )
              }
            } else {
              setSelectedFrame(null)
            }
          }
        )
      addSelectListener().catch(err => console.log(err))

      // Frame move & rotate listener
      const addMoveListener = async () => {
        const callback = async (frameId: string) => {
          if (frameId) {
            const frame = (await publisherConnector.getObject(
              `document.selectedFrame`
            )) as Frame
            setSelectedFrame(frame)
          }
        }

        await publisherConnector.addListener('FrameMoved', callback)
        await publisherConnector.addListener('FrameRotated', callback)
      }
      addMoveListener().catch(err => console.log(err))
    }
  }, [publisherConnector])

  const changeFrameProps = async (
    frameProp: string,
    value: string
  ): Promise<void> => {
    try {
      return publisherConnector.setProperty(
        `document.selectedFrame`,
        frameProp,
        value
      )
    } catch (err) {
      console.log('err', err)
    }
  }

  const changeTextFormat = async (prop: string, value: string) => {
    try {
      return publisherConnector.setProperty(
        'document.selectedText.textFormat',
        prop,
        value
      )
    } catch (err) {
      console.log('err', err)
    }
  }

  return (
    <>
      <Flex columnGap={32}>
        {tools.map(tool => {
          if (tool.type === ToolsTypes.toggle) {
            return null // Skip rendering the togglePanel here
          }
          return (
            <Tool
              key={tool.id}
              onClick={() => {
                if (tool.type === ToolsTypes.toggle) {
                  toggleRightPanel()
                } else {
                  handleToolClick(tool.type as ToolsTypes)
                }
              }}
            >
              <ToolIcon src={tool.icon} alt={tool.id} />
              {formatMessage({ id: `Editor.tool.${tool.id}` })}
            </Tool>
          )
        })}

        {isSmallScreen && (
          <Popover
            isOpen={isRightPanelVisible}
            positions={['bottom']}
            padding={10}
            align="center"
            content={({
              position,
              childRect,
              popoverRect
            }: PopoverState) => (
              <ArrowContainer
                position={position}
                childRect={childRect}
                popoverRect={popoverRect}
                arrowSize={10}
                arrowColor="transparent"
                arrowClassName="right_panel_popover_arrow"
              >
                <PopoverContent>
                  <RightPanel gradooToolRef={gradooToolRef} />
                </PopoverContent>
              </ArrowContainer>
            )}
          >
            <Tool
              key="togglePanel"
              onClick={toggleRightPanel}
              ref={gradooToolRef}
            >
              <img
                src={gradooSrc}
                width={24}
                height={24}
                alt="Gradoo Icon"
              />
              {formatMessage({ id: `Editor.tool.togglePanel` })}
            </Tool>
          </Popover>
        )}
        <FileUpload
          type={FileTypes.image}
          ref={imageInputRef}
          onSelect={handleImageSelect}
        />
      </Flex>
      {isLeftPanelShown && (
        <LeftPanel
          frame={selectedFrame}
          textFormat={selectedTextFormat}
          font={selectedFont}
          changeFrameProps={changeFrameProps}
          changeTextFormat={changeTextFormat}
          publisherConnector={publisherConnector}
        />
      )}
      {!isSmallScreen && <RightPanel />}
    </>
  )
}

export default Tools
