import { useEffect, useState } from 'react'

import { GetProductionAttrsQuery } from 'types/layoutcreator/graphql'

export const useProductionAttributes = (
  productionAttrs: GetProductionAttrsQuery | undefined
) => {
  const [proofCopySentToProd, setProofCopySentToProd] =
    useState(false)
  const [preparingForProduction, setPreparingForProduction] =
    useState(false)
  const [needsApproval, setNeedsApproval] = useState(false)
  const [hasApprovalDate, setHasApprovalDate] = useState(false)

  useEffect(() => {
    if (productionAttrs?.getProductionAttributes) {
      const {
        noApproval,
        proofDataInProductionDate,
        proofDataUploadDate,
        finalDataUploadDate,
        waitingForApprovalDate,
        approvalDate
      } = productionAttrs.getProductionAttributes

      if (Boolean(approvalDate)) {
        setHasApprovalDate(true)
      }

      setProofCopySentToProd(
        Boolean(
          !noApproval &&
            proofDataUploadDate &&
            !finalDataUploadDate &&
            proofDataInProductionDate &&
            !waitingForApprovalDate
        )
      )

      setPreparingForProduction(
        Boolean(
          (proofDataUploadDate &&
            !finalDataUploadDate &&
            !waitingForApprovalDate &&
            !proofDataInProductionDate &&
            !approvalDate) ||
            (proofDataUploadDate &&
              finalDataUploadDate &&
              !waitingForApprovalDate &&
              !approvalDate)
        )
      )

      setNeedsApproval(
        Boolean(
          (proofDataUploadDate || finalDataUploadDate) &&
            waitingForApprovalDate &&
            !approvalDate
        )
      )
    }
  }, [productionAttrs?.getProductionAttributes])

  return {
    proofCopySentToProd,
    preparingForProduction,
    needsApproval,
    hasApprovalDate
  }
}
