import { useEffect, useRef, useState } from 'react'

const loadedImages = new Set<string>()

function useImageLoading(image: string) {
  const [isLoading, setIsLoading] = useState<boolean>(
    !loadedImages.has(image)
  )
  const isMounted = useRef(true)

  useEffect(() => {
    isMounted.current = true
    return () => {
      isMounted.current = false
    }
  }, [])

  useEffect(() => {
    if (!image || loadedImages.has(image)) {
      setIsLoading(false)
      return
    }

    setIsLoading(true)
    const img = new Image()
    img.src = image
    img.onload = () => {
      if (isMounted.current) {
        loadedImages.add(image)
        setIsLoading(false)
      }
    }
  }, [image])

  return {
    url: image,
    isLoading
  }
}

export default useImageLoading
