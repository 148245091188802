import { useEffect, useState } from 'react'

const initImagesState = (count: number) => new Array(count).fill(true)

function useMultipleImageLoading(images: string[]) {
  const [imagesState, setImagesState] = useState(
    initImagesState(images.length)
  )

  useEffect(() => {
    setImagesState(initImagesState(images.length))

    images.forEach((src, i) => {
      const img = new Image()
      img.src = src
      img.onload = () => {
        const state = imagesState
        state[i] = false
        setImagesState(state)
      }
    })
  }, [images])

  return {
    state: imagesState,
    isLoading: imagesState.every(item => item === true)
  }
}

export default useMultipleImageLoading
