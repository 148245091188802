import { useIntl } from 'react-intl'
import styled from 'styled-components'

import guideActionSrc from './guide-action.jpg'

const CallToAction = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 380px;
  height: 405px;
  padding: 24px;
  border-radius: 14px;
  border: 0.736px solid var(--gradoo-base-3, #cbd5e1);
  background: url(${guideActionSrc}) center / cover;
  box-shadow: -2.2094595432281494px 2.2094595432281494px
    1.4729729890823364px 0 rgba(203, 213, 225, 0.2);
`

const Title = styled.div`
  color: ${({ theme }) => theme.color.brand_02};
  font-size: 16px;
  font-weight: 600;
`

const Subtitle = styled.div`
  color: ${({ theme }) => theme.color.base.c5};
  font-size: 12px;
  font-weight: 400;
`

const GuideAction = () => {
  const { formatMessage } = useIntl()
  return (
    <CallToAction>
      <Title>{formatMessage({ id: 'GuideAction.title' })}</Title>
      <Subtitle>
        {formatMessage({ id: 'GuideAction.subtitle' })}
      </Subtitle>
    </CallToAction>
  )
}

export default GuideAction
