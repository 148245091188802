import { useLazyQuery, useQuery } from '@apollo/client'
import config from 'config'
import { useState } from 'react'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'

import BackgroundItem from 'components/BackgroundItem'
import FileUpload from 'components/FileUpload'
import Flex from 'components/Flex'
import { Skeleton } from 'components/Skeleton'
import UploadZone from 'components/UploadZone'
import useAuth from 'data/gradoo/hooks/useAuth'
import { GET_PROJECT_TOKEN } from 'data/layoutcreator/queries/projects'
import { GET_PAGE_BACKGROUND_XML } from 'data/layoutcreator/queries/themes'
import { isDev } from 'helpers/env'
import { useBackgroundUploads } from 'hooks/useBackgroundUploads'
import ChiliVariables, {
  VariableTypes
} from 'screens/YearbookEditor/components/Tools/services/ChiliVariables'
import { EnvTypes, FileTypes } from 'types/global'

const variablesApi = ChiliVariables.getInstance()

const Container = styled(Flex)`
  flex-direction: column;
  margin-top: 32px;
  width: 100%;
  gap: 27px;
`

const ItemsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 8px;
`

type UploadsTabProps = {}

const ChangeBackgroundPanelUploadsTab: React.FC<
  UploadsTabProps
> = () => {
  const { pathname } = useLocation()
  const projectId = pathname.split('/')[1]
  const { authGroupId } = useAuth()

  const { data: token } = useQuery(GET_PROJECT_TOKEN, {
    variables: { projectId, groupId: authGroupId! }
  })

  const [getBackgroundXML] = useLazyQuery(GET_PAGE_BACKGROUND_XML)

  const [isSelectedLoading, setIsSelectedLoading] = useState(false)

  const getBackgroundQueryParams = (imageId: string) => {
    const params = [
      `?projectId=${projectId}`,
      `&groupId=${authGroupId}`,
      `&imageId=${imageId}`,
      `&token=${token?.getProjectToken}`
    ]

    return params.join('')
  }

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    handleUploadClick,
    handleFileUpload,
    handleImageRemove,
    selectedImageId,
    setSelectedImageId,
    imageInputRef,
    isImagesLoading,
    images
  } = useBackgroundUploads({
    onSelectedBackgroundChange: async data => {
      if (!data) {
        return
      }

      setIsSelectedLoading(true)

      const image = images?.find(image => image.id === data.id)

      if (image?.type === 'dap') {
        await variablesApi.setVariableValue(
          config.themes.variablesKeys.backgroundImage,
          isDev()
            ? config.themes.pageBackgrounds.devDAPValue
            : getBackgroundQueryParams(data.id),
          {
            type: VariableTypes.DAPImage,
            DAPId:
              config.themes.pageBackgrounds.DAPIds[
                process.env.REACT_APP_ENV as EnvTypes
              ]
          }
        )
      } else {
        const { data: xmlData } = await getBackgroundXML({
          variables: { id: data.id }
        })

        const xml = xmlData?.getPageBackgroundXML as string

        await variablesApi.setVariableValue(
          config.themes.variablesKeys.backgroundImage,
          xml,
          {
            type: VariableTypes.chiliImage
          }
        )
      }

      setIsSelectedLoading(false)
    },
    hideBackground: false
  })

  const handleImageClick = (imageId: string) => {
    const newSelectedImageId =
      imageId === selectedImageId ? null : imageId
    setSelectedImageId(newSelectedImageId)
  }

  return (
    <Container {...getRootProps()}>
      <UploadZone
        onClick={handleUploadClick}
        isDragActive={isDragActive}
      />

      <ItemsContainer style={{ opacity: isDragActive ? 0.3 : 1 }}>
        {isImagesLoading
          ? new Array(3 * 10)
              .fill(null)
              .map((_, i) => (
                <Skeleton key={i} width={78} height={103} />
              ))
          : images?.map(image => (
              <BackgroundItem
                key={image.id}
                width={76}
                height={103}
                isRemovable
                isSelected={image.id === selectedImageId}
                isLoading={
                  image.id === selectedImageId && isSelectedLoading
                }
                onClick={() => handleImageClick(image.id)}
                onRemove={() =>
                  handleImageRemove({
                    backgroundId: image.id,
                    type: image.type
                  })
                }
                src={new URL(
                  image.thumbPath,
                  process.env.REACT_APP_REST_URL
                ).toString()}
              />
            ))}
      </ItemsContainer>
      {images?.length === 0 && <span>No images here yet</span>}

      <FileUpload
        {...getInputProps({
          ref: imageInputRef,
          onSelect: (file: any) => handleFileUpload(file),
          type: FileTypes.image
        })}
      />
    </Container>
  )
}

export default ChangeBackgroundPanelUploadsTab
