import styled from 'styled-components'

import Flex from 'shared/ui/Flex'

type ContainerProps = {
  isSelected: boolean
  onClick: ShapeTypePreviewProps['onClick']
}

const Container = styled(Flex).attrs({
  direction: 'column',
  alignItems: 'center'
})<ContainerProps>`
  position: relative;
  border-radius: 5px;
  background: ${({ isSelected, theme }) =>
    isSelected ? theme.color.base.c1 : theme.color.base.c1};
  padding: 12px 0 42px 0;
  min-width: 110px;
  height: 106px;
  cursor: pointer;
  box-shadow: 1px 2px 6px 0px ${({ theme }) => theme.color.base.c2};
  border: 1px solid
    ${({ theme, isSelected }) =>
      isSelected ? theme.color.brand_02 : theme.color.base.c3};

  svg {
    fill: ${({ theme, isSelected }) =>
      isSelected ? theme.color.brand_02 : theme.color.base.c3};
    min-width: 65px;
    min-height: 80px;
  }
`

type ShapeTypePreviewProps = {
  isSelected: boolean
  Image: React.FC
  onClick: () => void
}

const ShapeTypePreview: React.FC<ShapeTypePreviewProps> = ({
  isSelected,
  Image,
  onClick
}) => {
  return (
    <Container onClick={onClick} isSelected={isSelected}>
      <Image />
    </Container>
  )
}

export default ShapeTypePreview
