import styled, { keyframes } from 'styled-components'

import darkSrc from './images/spinner-dark.svg'
import lightSrc from './images/spinner-light.svg'

export enum SpinnerVariants {
  light = 'light',
  dark = 'dark'
}

type SpinnerProps = {
  variant?: SpinnerVariants.light | SpinnerVariants.dark
  size?: number
}

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`

const AnimatedImg = styled.img`
  animation: ${rotate} 1s linear infinite;
`

const Spinner = ({
  variant = SpinnerVariants.dark,
  size = 32
}: SpinnerProps): JSX.Element => (
  <AnimatedImg
    src={variant === SpinnerVariants.dark ? darkSrc : lightSrc}
    style={{ width: size, height: size }}
  />
)

export default Spinner
