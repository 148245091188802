import { useEffect } from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components'

import Flex from 'shared/ui/Flex'
import { addressCountryCodeVar } from 'data/store/verify-address'

import SelectCountryModal, { CountryIDs } from '../SelectCountryModal'
import { Flag } from '../SelectCountryModal/components/CountryInput'
import useSelectCountryModal from '../SelectCountryModal/hooks/use-select-country-modal'
import arrowsSrc from './images/arrows.svg'

const InputButton = styled.button.attrs({
  type: 'button'
})`
  border-radius: 5px;
  background-color: ${({ theme }) => theme.color.base.c1};
  color: ${({ theme }) => theme.color.brand_02};
  border: none;
  outline: none;
  font-weight: 600;
  cursor: pointer;
`

const FlagContainer = styled(Flex)`
  width: 40px;
  height: 40px;
  border-radius: 5px;
  border: 1px solid ${({ theme }) => theme.color.brand_02};

  & img {
    display: block;
  }
`

type CountryInputProps = {
  countryCode: CountryIDs
}

const CountryInput = ({ countryCode }: CountryInputProps) => {
  const { formatMessage } = useIntl()

  const {
    country,
    setCountry,
    openSelectCountryModal,
    closeSelectCountryModal,
    isSelectCountryModalOpen,
    selectedCountry
  } = useSelectCountryModal({
    countryCode,
    reactiveVar: addressCountryCodeVar
  })

  useEffect(() => {
    addressCountryCodeVar(country)
  }, [country])

  return (
    <>
      <InputButton onClick={openSelectCountryModal}>
        <Flex
          alignItems="center"
          style={{ padding: 16 }}
          justifyContent="space-between"
        >
          <Flex columnGap={16} alignItems="center">
            <FlagContainer
              alignItems="center"
              justifyContent="center"
            >
              <Flag src={selectedCountry?.icon} />
            </FlagContainer>
            {formatMessage({
              id: `Project.select.country.${country}`
            })}
          </Flex>
          <img src={arrowsSrc} />
        </Flex>
      </InputButton>
      <SelectCountryModal
        closeModal={closeSelectCountryModal}
        isOpen={isSelectCountryModalOpen}
        setCountry={setCountry}
        selectedCountry={selectedCountry?.id}
      />
    </>
  )
}

export default CountryInput
