import { createClient } from '@liveblocks/client'
import { createRoomContext } from '@liveblocks/react'
import { ReactNode } from 'react'

import useAuth from 'data/gradoo/hooks/useAuth'
import { mapUserAvatar, mapUserInitials } from 'shared/helpers/mappers'
import { LiveUser } from 'shared/types/global'

export const client = createClient({
  authEndpoint: async room => {
    const headers = {
      'Content-Type': 'application/json'
    }

    const body = JSON.stringify({
      gradooToken: localStorage.getItem('token'),
      room
    })

    const response = await fetch(
      `${process.env.REACT_APP_REST_URL}liveblocks/auth`,
      {
        method: 'POST',
        headers,
        body
      }
    )

    return await response.json()
  }
})

type Storage = {
  syncHash: string | null
}

type Presence = {
  cursor: { x: number; y: number } | null
  message: string | null
  draggingPreviewUrl: string | null
  editingPageId: string | null
  info: Omit<LiveUser, 'index'>
}

export type ReactionEvent = {
  x: number
  y: number
  value: string
}

export const {
  RoomProvider,
  useOther,
  useOthersMapped,
  useOthersConnectionIds,
  useSelf,
  useMyPresence,
  useUpdateMyPresence,
  useStorage,
  useMutation,
  useBroadcastEvent,
  useEventListener,
  useLostConnectionListener,
  useStatus
} = createRoomContext<Presence, Storage, {}, ReactionEvent>(client)

interface PageRoomProviderProps {
  children: ReactNode
  projectId: string
  pageId?: string | null
}

export const PageRoomProvider = ({
  children,
  projectId,
  pageId = null
}: PageRoomProviderProps) => {
  const { authGroupId, authUserGroup } = useAuth()

  if (!authGroupId || !authUserGroup) {
    return null
  }

  const authUserAvatar = mapUserAvatar(authUserGroup)
  const authUserInitials = mapUserInitials(authUserGroup.user)

  return (
    <RoomProvider
      id={`${authGroupId}/${projectId}`}
      initialPresence={{
        cursor: null,
        message: null,
        draggingPreviewUrl: null,
        editingPageId: pageId,
        info: {
          id: authUserGroup.user.id,
          avatar: authUserAvatar,
          initials: authUserInitials,
          firstName: authUserGroup.user.firstName,
          lastName: authUserGroup.user.lastName
        }
      }}
      initialStorage={{ syncHash: '' }}
    >
      {children}
    </RoomProvider>
  )
}
