import styled from 'styled-components'

import infoActiveSrc from './images/info-active.svg'
import infoSrc from './images/info.svg'

type ListProps = {
  isActive: boolean
}

const List = styled.ul<ListProps>`
  padding: 0 24px;
  margin-left: 16px;
  font-weight: 400;
  font-size: 11px;
  line-height: 14px;
  list-style: ${({ isActive }) =>
    `url("${isActive ? infoActiveSrc : infoSrc}")`};

  & span {
    position: relative;
    top: -3px;
    color: ${({ theme, isActive }) =>
      isActive ? theme.color.base.c10 : theme.color.base.c5};
  }
`

type InfoListProps = {
  items: string[]
} & ListProps

const InfoList = ({ items, isActive }: InfoListProps) => (
  <List isActive={isActive}>
    {items.map(item => (
      <li key={item}>
        <span>{item}</span>
      </li>
    ))}
  </List>
)

export default InfoList
