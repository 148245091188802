import { AnimatePresence, MotionProps, motion } from 'framer-motion'
import { ReactNode } from 'react'
import styled from 'styled-components'

import { MODAL_Z_INDEX } from 'shared/styles/z-index'

const FADE_DURATION = 0.3
export interface AnimatedModalProps extends MotionProps {
  closeModal: () => void
  children: ReactNode
  isOpen: boolean
  className?: string
  width?: number | string
  maxWidth?: number
}

const overlayVariants = {
  visible: {
    opacity: 1,
    transition: {
      duration: FADE_DURATION
    }
  },
  hidden: {
    opacity: 0,
    transition: {
      duration: FADE_DURATION
    }
  }
}

const Overlay = styled(motion.div)`
  z-index: ${MODAL_Z_INDEX};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
`

const Modal = styled(motion.div)<{
  width?: number | string
  $maxWidth?: number
}>`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  border-radius: 8px;
  padding: 32px 22px 24px 22px;
  z-index: ${MODAL_Z_INDEX + 1};
  width: ${({ width }) =>
    `${width}${typeof width === 'number' ? 'px' : ''}` || '400px'};
  max-width: ${({ $maxWidth }) => `${$maxWidth}px`};
`

const AnimatedModal = ({
  isOpen,
  closeModal,
  children,
  style,
  className,
  width,
  maxWidth
}: AnimatedModalProps) => {
  const onOverlayClick = (e: React.MouseEvent) => {
    if (e.target === e.currentTarget) {
      closeModal()
    }
  }
  return (
    <AnimatePresence>
      {isOpen && (
        <Overlay
          onClick={onOverlayClick}
          initial="hidden"
          animate="visible"
          exit="hidden"
          variants={overlayVariants}
        >
          <Modal
            style={style}
            className={className}
            width={width}
            $maxWidth={maxWidth}
          >
            {children}
          </Modal>
        </Overlay>
      )}
    </AnimatePresence>
  )
}

export default AnimatedModal
