export function removeValuesFromArray<ValueType extends any>(
  array: ValueType[],
  valuesToRemove: ValueType[]
) {
  return array.filter(item => !valuesToRemove.includes(item))
}

export function sortByPattern(
  array: any[],
  pattern: typeof array[number][],
  property?: string
) {
  return array.sort((a, b) => {
    const aValue = property ? a[property] : a
    const bValue = property ? b[property] : b

    const aIndex = pattern.indexOf(aValue)
    const bIndex = pattern.indexOf(bValue)

    if (aIndex === -1) {
      return 1
    }

    if (bIndex === -1) {
      return -1
    }

    return aIndex - bIndex
  })
}
