import styled from 'styled-components'

import logoSVG from 'shared/assets/icons/logo.svg'

const Logo = styled.img.attrs({
  src: logoSVG,
  alt: 'Logotype'
})`
  width: 126px;
`

export default Logo
