import { EnvTypes } from '../types/global'

type MethodOptions = {
  targetEnv?: EnvTypes | EnvTypes[]
}

type Method<Options = MethodOptions> = (
  text: string | string[],
  options?: Options
) => void

interface LoggerService {
  log: Method<MethodOptions & { color?: string }>
  debug: Method
  warning: Method
  danger: Method
}

export class Logger implements LoggerService {
  constructor(
    private context: string,
    private targetEnv?: EnvTypes | EnvTypes[]
  ) {}

  private matchesTargetEnv(localTargetEnv?: EnvTypes | EnvTypes[]) {
    let targetEnv = this.targetEnv

    if (localTargetEnv) {
      targetEnv = localTargetEnv
    }

    if (Array.isArray(targetEnv)) {
      return targetEnv.includes(process.env.REACT_APP_ENV as EnvTypes)
    }

    return targetEnv === process.env.REACT_APP_ENV
  }

  log(
    text: string | string[],
    options?: {
      targetEnv?: EnvTypes | EnvTypes[]
      color?: string
    }
  ) {
    if (!this.matchesTargetEnv(options?.targetEnv)) {
      return
    }

    const formatText = (text: string | string[]) => {
      if (Array.isArray(text)) {
        return text.join(' ')
      }

      return text
    }

    const colorProperty = options?.color
      ? `color: ${options?.color}`
      : ''

    console.log(
      `${options?.color && '%c'}[${this.context}] ${formatText(
        text
      )}`,
      colorProperty
    )
  }

  debug(text: string | string[], options?: MethodOptions) {
    this.log(text, { ...options, color: '#63d4c1' })
  }

  warning(text: string | string[], options?: MethodOptions) {
    this.log(text, { ...options, color: '#d1cc38' })
  }

  danger(text: string | string[], options?: MethodOptions) {
    this.log(text, { ...options, color: '#b5192e' })
  }

  success(text: string | string[], options?: MethodOptions) {
    this.log(text, { ...options, color: '#1da133' })
  }
}

export const DevLogger = (context: string) =>
  new Logger(context, [EnvTypes.dev, EnvTypes.stage])
export const ProdLogger = (context: string) =>
  new Logger(context, EnvTypes.prod)
