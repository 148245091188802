import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams
} from 'react-router-dom'

import Button from 'components/Button_2'
import Tool, { ToolsContainer } from 'components/Tool'

import { ButtonWrapper } from './style'

const TOOLS = [
  {
    to: '/:yearbookId?view=list',
    icon: 'list-outline',
    title: 'Page.editor.tools.list'
  },
  {
    to: '/:yearbookId?view=grid',
    icon: 'grid-filled',
    title: 'Page.editor.tools.grid'
  }
]

const Tools = ({ openModal }: { openModal: () => void }) => {
  const params = useParams()
  const [searchParams] = useSearchParams()
  const { pathname } = useLocation()
  const projectId = pathname.split('/')[1]
  const navigate = useNavigate()
  return (
    <ToolsContainer>
      {TOOLS.map(tool => {
        return (
          <Tool
            key={tool.to}
            {...tool}
            param={params.yearbookId}
            isActive={
              searchParams.has('view') &&
              tool.to.includes(searchParams.get('view') || '')
            }
          />
        )
      })}
      <ButtonWrapper>
        <Button
          onPress={() =>
            navigate(
              projectId ? `/${projectId}/settings` : `/settings`
            )
          }
          size="sm"
          intent="secondary-base"
          textId={'Tools.settings'}
        />
        <Button
          testID={'ToolsAddButton'}
          onPress={openModal}
          size="sm"
          intent="primary-brand-02"
          textId={'Tools.add.page'}
          iconName="plus"
          icon="left"
        />
      </ButtonWrapper>
    </ToolsContainer>
  )
}

export default Tools
