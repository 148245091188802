import { forwardRef } from 'react'
import { FieldValues, UseFormRegister } from 'react-hook-form'
import { useIntl } from 'react-intl'
import styled from 'styled-components'

import Flex from 'components/Flex'
import { AdsPlacement } from 'types/layoutcreator/graphql'

import RadioIcon from './components/RadioIcon'

type LabelBoxProps = {
  hasError?: boolean
}

const LabelBox = styled.label<LabelBoxProps>`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  row-gap: 8px;
  padding: 12px 4px;
  box-shadow: 1px 2px 6px 0 ${({ theme }) => theme.color.base.c2};
  border: 1px solid
    ${({ theme, hasError }) =>
      hasError ? theme.color.error : theme.color.base.c4};
  border-radius: 5px;
  font-size: 10px;
  font-weight: 600;
  color: ${({ theme }) => theme.color.base.c8};
  cursor: pointer;
  text-align: center;
`

const IconContainer = styled(Flex)`
  width: 50px;
  height: 50px;
  background-color: ${({ theme }) => theme.color.base.c3};
  border-radius: 5px;
`

const HiddenRadio = styled.input.attrs({ type: 'radio' })`
  display: none;

  &:checked + ${LabelBox} {
    border-color: ${({ theme }) => theme.color.brand_02};
    color: ${({ theme }) => theme.color.brand_02};
  }

  &:checked + ${LabelBox} svg path {
    fill: #fff;
  }

  &:checked + ${LabelBox} ${IconContainer} {
    background-color: ${({ theme }) => theme.color.brand_02};
  }
`

type RadioInputProps = {
  id: AdsPlacement
} & LabelBoxProps &
  ReturnType<UseFormRegister<FieldValues>>

const RadioInput = forwardRef<HTMLInputElement, RadioInputProps>(
  ({ onChange, onBlur, name, id, hasError }, ref) => {
    const { formatMessage } = useIntl()

    return (
      <Flex flex={1} columnGap={8}>
        <HiddenRadio
          name="adsPlacement"
          id={id}
          ref={ref}
          onChange={onChange}
          onBlur={onBlur}
          value={id}
        />
        <LabelBox htmlFor={id} hasError={hasError}>
          <IconContainer alignItems="center" justifyContent="center">
            <RadioIcon id={id} />
          </IconContainer>
          <div>
            {formatMessage({ id: `Project.print.data.radio.${id}` })}
          </div>
        </LabelBox>
      </Flex>
    )
  }
)

export default RadioInput
