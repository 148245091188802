import { ErrorMessage } from '@hookform/error-message'
import {
  FieldErrors,
  FieldValues,
  Path,
  RegisterOptions,
  UseFormRegister
} from 'react-hook-form'
import styled, { css } from 'styled-components'

import Icon, { IconName } from '../Icon'

type isFullWidthProp = {
  isFullWidth?: boolean
}

export const OuterContainer = styled.div<isFullWidthProp>`
  margin-bottom: 18px;
  ${({ isFullWidth }) =>
    isFullWidth &&
    css`
      width: 100%;
    `}
`

export const InputContainer = styled.div<isFullWidthProp>`
  position: relative;
  ${({ isFullWidth }) =>
    isFullWidth &&
    css`
      width: 100%;
    `}
`

export type StyledInputProps = {
  isInvalid: boolean
  hasIcon?: boolean
} & isFullWidthProp
export const StyledInput = styled.input<StyledInputProps>`
  height: 68px;
  padding: ${({ hasIcon }) =>
    hasIcon ? '20px 16px 20px 56px' : '20px 16px'};
  width: ${({ isFullWidth }) => (isFullWidth ? '100%' : '435px')};
  border: 1px solid
    ${({ theme, isInvalid }) =>
      isInvalid ? theme.color.error : theme.color.base.c3};
  border-radius: 34px;
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
  font-size: 18px;

  &:focus {
    border-color: ${({ theme, isInvalid }) =>
      isInvalid ? theme.color.error : theme.color.brand_02};
  }
`

export const IconLabel = styled.label`
  position: absolute;
  top: 50%;
  left: 16px;
  transform: translateY(-50%);

  & svg,
  & img {
    display: block;
  }
`

export const FormError = styled.div`
  margin-top: 8px;
  font-size: 12px;
  height: 14px;
  color: ${({ theme }) => theme.color.base.c9};
`

export type InputProps<TFormValues extends FieldValues> = {
  id: Path<TFormValues>
  label: String
  register?: UseFormRegister<TFormValues>
  rules?: RegisterOptions
  errors?: FieldErrors<TFormValues>
  isFullWidth?: boolean
  isDisabled?: boolean
  hideErrors?: boolean
  iconName?: IconName
}

function FormInput<TFormValues extends FieldValues>({
  id,
  label,
  register,
  errors,
  rules = {},
  isFullWidth,
  isDisabled,
  hideErrors,
  iconName
}: InputProps<TFormValues>) {
  return (
    <OuterContainer isFullWidth={isFullWidth}>
      <InputContainer isFullWidth={isFullWidth}>
        {iconName ? (
          <IconLabel htmlFor={id}>
            <Icon name={iconName} />
          </IconLabel>
        ) : null}
        <StyledInput
          type="text"
          placeholder={label as string}
          id={id}
          isInvalid={!!errors?.[id]}
          {...(register && register(id, rules))}
          isFullWidth={isFullWidth}
          disabled={isDisabled}
          hasIcon={!!iconName}
        />
      </InputContainer>
      {!hideErrors && (
        <FormError>
          <ErrorMessage name={id as any} errors={errors} />
        </FormError>
      )}
    </OuterContainer>
  )
}

export default FormInput
