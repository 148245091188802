import styled from 'styled-components'

const SelectorContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding-left: 8px;
  padding-right: 8px;
  background-color: ${({ theme }) => theme.color.base.c0};
  --transform-translate-x: -50%;
  --transform-translate-y: -50%;
  border-radius: 100px;
  box-shadow: 0 0 0 0.5px rgba(0, 0, 0, 0.08),
    0 20px 25px -5px rgba(0, 0, 0, 0.1),
    0 10px 10px -5px rgba(0, 0, 0, 0.04);
`

type Props = {
  setReaction: (reaction: string) => void
}

export default function ReactionSelector({ setReaction }: Props) {
  const reactions = ['👍', '🔥', '😍', '👀', '😱', '🙁']
  return (
    <SelectorContainer>
      {reactions.map((reaction, i) => (
        <ReactionButton
          key={`${i}`}
          reaction={reaction}
          onSelect={setReaction}
        />
      ))}
    </SelectorContainer>
  )
}

const ButtonContainer = styled.a`
  padding: 8px;
  font-size: 20px;
  line-height: 28px;
  cursor: pointer;

  :hover {
    transform: scale(1.5);
  }
`
function ReactionButton({
  reaction,
  onSelect
}: {
  reaction: string
  onSelect: (reaction: string) => void
}) {
  return (
    <ButtonContainer onClick={() => onSelect(reaction)}>
      {reaction}
    </ButtonContainer>
  )
}
