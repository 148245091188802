import { useQuery } from '@apollo/client'

import Flex from 'components/Flex'
import { GET_PROJECT_DETAILS } from 'data/layoutcreator/queries/admin'
import Migration from 'screens/AdminProject/components/Migration'
import {
  MigrationStatuses,
  TabProps
} from 'screens/AdminProject/types'

const MigrationsTab: React.FC<TabProps> = ({
  groupId,
  projectId
}) => {
  const { data: projectData, refetch } = useQuery(
    GET_PROJECT_DETAILS,
    {
      variables: {
        projectId
      }
    }
  )

  const projectDetails = projectData?.getProjectDetails
  const migrations = projectDetails?.migrations || []

  if (migrations.length === 0) {
    return <>No migrations found</>
  }

  return (
    <Flex direction="column" rowGap={18} marginBottom={46}>
      {migrations.map((migration, i) => (
        <Migration
          key={migration.id}
          name={`Migration ${i + 1}`}
          linkTo={`./migrations/${migration.id}`}
          status={migration.status as MigrationStatuses}
          id={migration.id}
          date={new Date(migration.startedAt)}
          author={migration.author || null}
          type={migration.type}
        />
      ))}
    </Flex>
  )
}

export default MigrationsTab
