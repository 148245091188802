import { useIntl } from 'react-intl'
import styled from 'styled-components'

import Flex from 'components/Flex'
import { mapUserName } from 'helpers/mappers'
import { useClickToCopy } from 'hooks/useClickToCopy'
import { QuoteFragment } from 'types/gradoo/graphql'

const Container = styled.div`
  padding: 24px 16px;
  background: ${({ theme }) => theme.color.base.c7};
  color: ${({ theme }) => theme.color.base.c0};
  border-radius: 14px;
  cursor: pointer;
`

const Text = styled.span`
  font-size: 14px;
  font-weight: 700;
`

const Divider = styled.div`
  height: 1px;
  width: 120px;
  border-radius: 9999px;
  margin-top: 32px;
  margin-bottom: 12px;
  background: ${({ theme }) => theme.color.base.c0};
`

const Footer = styled(Flex)`
  justify-content: space-between;
`

const Avatar = styled.div<{ src: string }>`
  border-radius: 50%;
  height: 16px;
  width: 16px;
  background: url(${({ src }) => src}) center / cover;
`

const Name = styled.span`
  font-weight: 700;
  font-size: 14px;
`

const Likes = styled.span`
  font-weight: 700;
  font-size: 10px;
`

type QuoteItemProps = {
  quote: QuoteFragment
}

const QuoteItem: React.FC<QuoteItemProps> = ({ quote, ...rest }) => {
  const { formatMessage } = useIntl()
  const { register } = useClickToCopy()

  return (
    <Container {...rest}>
      <Text {...register()}>{quote.quote}</Text>
      <Divider />
      <Footer>
        <Flex columnGap={8}>
          <Avatar
            src={
              quote.userGroup.avatar ||
              quote.userGroup.defaultAvatar?.avatar ||
              ''
            }
          />
          <Name>{mapUserName(quote.userGroup.user)}</Name>
        </Flex>
        <Likes>
          {`${quote.quotesInstanceLikesCount} ${formatMessage({
            id: 'Panel.quote.likesCount'
          })}`}
        </Likes>
      </Footer>
    </Container>
  )
}

export { default as QuoteItemSkeleton } from './Skeleton'
export default QuoteItem
