import styled, { keyframes } from 'styled-components'

import Flex, { FlexDivProps } from 'components/Flex'
import { theme } from 'styles/theme'

const animationHorizontal = keyframes`
  100% {
    transform: translateX(100%);
  }
`

const animationVertical = keyframes`
  100% {
    transform: translateY(100%);
  }
`

type StyledSkeletonProps = {
  baseColor: string
  highlightColor: string
  style: any
  animate: boolean
  animationDirection: SkeletonProps['animationDirection']
} & FlexDivProps

const StyledSkeleton = styled(Flex)<StyledSkeletonProps>`
  height: 100%;
  width: 100%;
  background: ${({ baseColor }) => baseColor};
  border-radius: 10px;
  position: relative;
  user-select: none;
  overflow: hidden;
  z-index: 1;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-repeat: no-repeat;
    background-image: linear-gradient(
      ${({ animationDirection }) =>
        animationDirection === 'horizontal' ? 90 : 0}deg,
      ${({ baseColor }) => baseColor},
      ${({ highlightColor }) => highlightColor},
      ${({ baseColor }) => baseColor}
    );
    transform: ${({ animationDirection }) =>
      animationDirection === 'horizontal'
        ? 'translateX(-100%)'
        : 'translateY(-100%)'};

    animation-name: ${({ animate, animationDirection }) =>
      animate
        ? animationDirection === 'horizontal'
          ? animationHorizontal
          : animationVertical
        : 'none'};
    animation-duration: 1.5s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
  }
`

type SkeletonProps = FlexDivProps & {
  colorLevel?: number
  radius?: number
  width?: number | string
  height?: number | string
  style?: Record<string, any>
  padding?: [number, number]
  paddingLeft?: number
  paddingRight?: number
  paddingTop?: number
  paddingBottom?: number
  children?: JSX.Element | JSX.Element[]
  animate?: boolean
  animationDirection?: 'horizontal' | 'vertical'
}

export const Skeleton: React.FC<SkeletonProps> = ({
  width,
  height,
  colorLevel = 2,
  radius,
  style,
  animate = true,
  children,
  padding,
  paddingLeft,
  paddingRight,
  paddingBottom,
  paddingTop,
  alignItems = 'center',
  justifyContent = 'space-between',
  animationDirection = 'horizontal',
  ...rest
}) => (
  <StyledSkeleton
    baseColor={
      Number.isInteger(colorLevel) &&
      (theme.color.base as any)[`c${colorLevel}`]
    }
    highlightColor={
      Number.isInteger(colorLevel) &&
      (theme.color.base as any)[`c${colorLevel! - 1 || 0}`]
    }
    style={{
      borderRadius: radius,
      height,
      width,
      paddingTop,
      paddingBottom,
      paddingRight,
      paddingLeft,
      padding: padding && `${padding[0]}px ${padding[1]}px`,
      ...style
    }}
    alignItems={alignItems}
    justifyContent={justifyContent}
    animate={animate}
    animationDirection={animationDirection}
    {...rest}
  >
    {children}
  </StyledSkeleton>
)

export const InlineSkeleton = styled(Skeleton)`
  border-radius: 9999px;
`

type LinesSkeletonProps = SkeletonProps & {
  lines?: number | number[] | string[]
  gap?: number
}
export const LinesSkeleton: React.FC<LinesSkeletonProps> = ({
  lines = 2,
  gap = 6,
  ...rest
}) => (
  <Flex direction="column" flex={1} width="100%" rowGap={gap}>
    {(Array.isArray(lines) ? lines : new Array(lines).fill(null)).map(
      (width, i) => (
        <InlineSkeleton
          width={width ? width : '100%'}
          height={8}
          {...rest}
          key={i}
        />
      )
    )}
  </Flex>
)

export const CircleSkeleton = styled(InlineSkeleton)<{
  size: number
}>`
  height: ${({ size }) => size}px;
  width: ${({ size }) => size}px;
`
