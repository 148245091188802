import React from 'react'
import styled from 'styled-components'

import Button from 'shared/ui/Button_2'
import Flex from 'shared/ui/Flex'
import Icon from 'shared/ui/IcomoonIcon'
import { theme } from 'shared/styles/theme'

import { PathItem } from '../../hooks/usePanelNavigation'
import { Panels } from '../RightPanel/types'

const Item = styled.span`
  font-size: 10px;
  font-weight: 600;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
`

const Chevron = styled(Flex).attrs({
  justifyContent: 'center',
  alignItems: 'center'
})`
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: ${({ theme }) => theme.color.brand_02};
  flex-shrink: 0;
  margin: 2px 8px;

  & > svg {
    transform: rotate(180deg);
  }
`

type PanelPaginationProps = {
  path: PathItem<Panels>[]
  onClick: (index: number) => void
}

const PanelPagination: React.FC<PanelPaginationProps> = ({
  path,
  onClick
}) => {
  return (
    <Flex>
      {path.map(({ panel, title }, i: number) => (
        <React.Fragment key={panel}>
          <Item onClick={() => onClick(i)}>{title}</Item>
          {i !== path.length - 1 && (
            <Chevron>
              <Button
                onPress={() => null}
                size="sm"
                intent="sub-action"
                textId={'Tools.add.page'}
                iconName="arrow_narrow_left"
                icon="only"
              />
            </Chevron>
          )}
        </React.Fragment>
      ))}
    </Flex>
  )
}

export default PanelPagination
