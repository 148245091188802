import { PublisherInterface } from '@chili-publish/publisher-interface'
import config from 'config'

import { Maybe } from 'graphql/jsutils/Maybe'
import { capitalizeFirstLetter } from 'shared/helpers/string'
import { ColorCMYK } from 'shared/types/global'

export type ThemeColor = {
  displayName: string
  name: string
  value: ColorCMYK
}

export enum VariableTypes {
  string,
  chiliImage,
  DAPImage
}

export type SetVariableOptions = {
  type?: VariableTypes
  DAPId?: string
}

type VariablesService<InitOptions> = {
  init: (options: InitOptions) => void
}

type InitOptions = {
  publisherConnector: PublisherInterface
}

export default class ChiliVariables
  implements VariablesService<InitOptions>
{
  private static instance: ChiliVariables
  private publisherConnector: Maybe<PublisherInterface>
  private _isInitialized = false

  get isInitialized() {
    return this._isInitialized
  }

  private set isInitialized(value: boolean) {
    this._isInitialized = value
  }

  static getInstance(): ChiliVariables {
    if (!ChiliVariables.instance) {
      ChiliVariables.instance = new ChiliVariables()
    }

    return ChiliVariables.instance
  }

  destroy() {
    this.isInitialized = false
    this.publisherConnector = null
  }

  init({ publisherConnector }: InitOptions) {
    if (!this.isInitialized) {
      this.publisherConnector = publisherConnector
      this.isInitialized = true
    }
  }

  private getVariableSelector(nameOrIndex: string | number) {
    return `document.variables[${nameOrIndex}]`
  }

  parseColorVariable(value: string): ColorCMYK | null {
    if (!value.includes('cmyk:')) {
      return null
    }
    const [_, values] = value.split(':')
    const [c, m, y, k] = values.split('-')

    return {
      c: Number(c),
      m: Number(m),
      y: Number(y),
      k: Number(k)
    }
  }

  convertColorToVariableValue(color: ColorCMYK) {
    const { c, m, y, k } = color

    return `cmyk:${c}-${m}-${y}-${k}`
  }

  async createVariable(name: string, value: string | number | boolean, options: SetVariableOptions = {
    type: VariableTypes.string
  }): Promise<string> {
    const TYPES_MAP = {
      [VariableTypes.string]: 'string',
      [VariableTypes.chiliImage]: 'image',
      [VariableTypes.DAPImage]: 'image'
    }

    const { id, javaScriptDescriptor } = await this.publisherConnector?.executeFunction('document.variables', 'Add') as { id: string; javaScriptDescriptor: string }
    const selector = this.getVariableSelector(id)
    await this.publisherConnector?.setProperty(selector, 'name', name)
    await this.publisherConnector?.setProperty(selector, 'dataType', TYPES_MAP[options.type!])
    await this.setVariableValue(name, value, options)
    
    return javaScriptDescriptor
  }

  async getVariable(nameOrIndex: string | number) {
    return this.publisherConnector?.getObject(
      this.getVariableSelector(nameOrIndex)
    ) as Promise<any>
  }
  
  private async resetDAPVariable(name: string) {
    const selector = this.getVariableSelector(name)

    await this.publisherConnector?.setProperty(
      selector,
      'imageSource',
      ''
    )

    await this.publisherConnector?.setProperty(
      selector,
      'dynamicAssetProvider',
      ''
    )

    await this.publisherConnector?.setProperty(
      selector,
      'dynamicAssetProviderValue',
      ''
    )

    await this.publisherConnector?.setProperty(
      selector,
      'dynamicAssetProviderDisplayValue',
      ''
    )

    await this.publisherConnector?.setProperty(
      selector,
      'imgXML',
      '<item><fileInfo /></item>'
    )
  }

  async setVariableValue(
    name: string,
    value: string | number | boolean,
    options: SetVariableOptions = {
      type: VariableTypes.string
    }
  ) {
    const PROP_MAP = {
      [VariableTypes.string]: 'value',
      [VariableTypes.chiliImage]: 'imgXML',
      [VariableTypes.DAPImage]: 'dynamicAssetProviderValue'
    }

    let property = PROP_MAP[options.type!]

    if (options.type === VariableTypes.DAPImage && options.DAPId) {
      await this.publisherConnector?.setProperty(
        this.getVariableSelector(name),
        'imageSource',
        'dynamic_asset_provider'
      )

      await this.publisherConnector?.setProperty(
        this.getVariableSelector(name),
        'dynamicAssetProvider',
        options.DAPId
      )
    } else if (options.type === VariableTypes.chiliImage) {
      await this.resetDAPVariable(name)
    }

    await this.publisherConnector?.setProperty(
      this.getVariableSelector(name),
      property,
      String(value)
    )
  }

  async getThemeColors(): Promise<ThemeColor[]> {
    const varList = (await this.publisherConnector?.getObject(
      'document.variables'
    )) as { length: string }

    const result = [] as any

    for (let i = 0; i < Number(varList.length); i++) {
      const variable = await this.getVariable(i)
      const color = this.parseColorVariable(variable.value)

      const { themeColorPrefix, pageNumberColor } =
        config.themes.variablesKeys

      if (
        !color ||
        (!variable.name.includes(themeColorPrefix) &&
          !variable.name.includes(pageNumberColor))
      ) {
        continue
      }

      result.push({
        displayName: variable.name
          .replace('template', 'theme')
          .split('_')
          .map((word: string) => capitalizeFirstLetter(word))
          .join(' '),
        name: variable.name,
        value: color
      })
    }

    return result
  }

  async getVariablesCount(): Promise<number> {
    const varList = (await this.publisherConnector?.getObject(
      'document.variables'
    )) as { length: string }

    return Number(varList.length)
  }

  async removeVariable(name: string) {
    return this.publisherConnector?.executeFunction(this.getVariableSelector(name), 'Delete')
  }
}
