import { gql } from 'shared/types/layoutcreator'

export const CHECK_AUTH = gql(/* GraphQL */ `
  query checkAuth {
    checkAuth
  }
`)

export const GET_ADMIN_PROJECTS = gql(/* GraphQL */ `
  query getAdminProjects($groupId: String!) {
    getAdminProjects(groupId: $groupId) {
      id
      name
      pagePreviews {
        ...PagePreviewsFields
      }
      isMigrationRunning
      deleted
    }
  }
`)

export const GET_DB_SNAPSHOT = gql(/* GraphQL */ `
  query getDbSnapshot($groupId: String!) {
    getDbSnapshot(groupId: $groupId)
  }
`)

export const GET_PROJECT_DETAILS = gql(/* GraphQL */ `
  query getProjectDetails($projectId: String!) {
    getProjectDetails(projectId: $projectId) {
      name
      groupId
      pagesCount
      migrations {
        status
        type
        id
        startedAt
        author
      }
    }
  }
`)

export const GET_IMAGES_MIGRATION_DATA = gql(/* GraphQL */ `
  query getImagesMigrationData($id: String!) {
    getImagesMigrationData(id: $id) {
      type
      status
      id
      startedAt
      finishedAt
      author
      documents {
        id
        order
        name
        startedAt
        finishedAt
        status
        error
        variables {
          type
          id
          name
          prevValue
          newValue
          freshExternalUrl
          status
          error
        }
      }
    }
  }
`)

export const GET_EXPORT_DATA = gql(/* GraphQL */ `
  query getExportDocument($taskId: String!) {
    getExportDocument(taskId: $taskId) {
      documentId
      taskId
      isStarted
      isFinished
      isSucceeded
      url
    }
  }
`)


export const GET_KPI = gql(/* GraphQL */ `
  query getKpi($minPagesCount: Int!) {
    getKpi(minPagesCount: $minPagesCount) {
      pagesCreated
      pagesExported
      projectsCreated
      projectsCreatedWithMinPagesCount
      activeGroups
    }
  }
`)
