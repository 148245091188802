import Flex from 'components/Flex'
import {
  CircleSkeleton,
  InlineSkeleton,
  Skeleton
} from 'components/Skeleton'

import { Container, Info, Thumb } from './Styles'

const ContentSetItemSkeleton: React.FC = () => {
  return (
    <Container>
      <Thumb>
        <Skeleton radius={4} />
      </Thumb>
      <Info>
        <Flex direction="column" flex={1}>
          <InlineSkeleton width={87} height={14} marginBottom={6} />
          <InlineSkeleton
            height={6}
            colorLevel={3}
            marginBottom={9}
          />
          <Flex alignItems="center" justifyContent="space-between">
            <InlineSkeleton width={65} height={8} />

            <InlineSkeleton
              animate={false}
              height={16}
              width={51}
              colorLevel={4}
            >
              <CircleSkeleton
                marginLeft={6}
                colorLevel={3}
                size={10}
              />
              <InlineSkeleton
                marginLeft={3}
                marginRight={6}
                colorLevel={3}
                width={27}
                height={10}
              />
            </InlineSkeleton>
          </Flex>
        </Flex>
      </Info>
    </Container>
  )
}

export default ContentSetItemSkeleton
