import { Dispatch, SetStateAction } from 'react'
import { useIntl } from 'react-intl'

import Flex from 'components/Flex'

import {
  Circle,
  Connector,
  StepTitle
} from './components/BarElements'
import { Step, StepsRecord } from './types'

type StepsBarProps<T extends string> = {
  stepId: T
  steps: StepsRecord<T>
  setStep?: Dispatch<SetStateAction<T>>
}

function StepsBar<T extends string>({
  stepId,
  steps,
  setStep
}: StepsBarProps<T>) {
  const { formatMessage } = useIntl()
  const activeStepIndex = Object.values<Step<T>>(steps).findIndex(
    step => step.id === stepId
  )

  return (
    <Flex marginTop={6}>
      {/* marginTop for vertical centering because of absolute pos for titles */}
      {Object.entries<Step<T>>(steps).map(([path, step], index) => {
        const isActive = activeStepIndex >= index
        const isClickable = activeStepIndex > index
        return (
          <Flex key={step.id}>
            {index > 0 && <Connector isActive={isActive} />}
            <Flex
              alignItems="center"
              direction="column"
              style={{
                position: 'relative',
                cursor: isClickable ? 'pointer' : 'default'
              }}
              onClick={() => {
                if (isClickable && setStep) {
                  setStep(step.id)
                }
              }}
            >
              <Circle isActive={isActive}>
                <step.IconComponent isActive={isActive} />
              </Circle>
              <StepTitle isActive={isActive}>
                {formatMessage({ id: `StepsBar.${step.id}` })}
              </StepTitle>
            </Flex>
          </Flex>
        )
      })}
    </Flex>
  )
}

export default StepsBar
