import { gql } from 'shared/types/gradoo'

export const ME = gql(/* GraphQL */ `
  query Me {
    me {
      id
      email
      created
      phone
      phoneCountryCode
      firstName
      lastName
      avatar
    }
  }
`)

export const GROUP_USERS = gql(/* GraphQL */ `
  query GroupUsers($user: ID, $status: GroupsUserGroupStatusChoices) {
    userGroups(user: $user, status: $status) {
      edges {
        node {
          ...AuthUserGroup
        }
      }
    }
  }
`)
