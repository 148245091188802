import { useState } from 'react'
import styled from 'styled-components'

const OPTION_WIDTH = 130

const Container = styled.div<{ width: number }>`
  width: ${({ width }) => width}px;
  height: 34px;
  background-color: ${({ theme }) => theme.color.base.c2};
  position: relative;
  border-radius: 100px;
`

const Modes = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  border-radius: 100px;
  overflow: hidden;
`

const Mode = styled.div<{ isActive: boolean }>`
  color: ${({ theme, isActive }) =>
    isActive ? theme.color.base.c0 : theme.color.base.c8};
  font-weight: 600;
  transition: color 0.2s;
  height: 100%;
  width: ${OPTION_WIDTH}px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 30px;
  cursor: pointer;
`

const ActiveBg = styled.div<{ translateX: number }>`
  background: ${({ theme }) => theme.color.brand_02};
  position: absolute;
  top: 0;
  bottom: 0;
  width: ${OPTION_WIDTH}px;
  transform: translateX(${({ translateX }) => translateX}px);
  border-radius: 100px;
  transition: transform 0.2s ease-out;
  box-shadow: 0px 3px 4px 3px rgba(0, 0, 0, 0.15);
`

type ViewModeSwitchProps = {
  options: string[]
  isDisabled?: boolean
  initialActiveIndex?: number
  onChange: (mode: string, index: number) => void
}

const ViewModeSwitch: React.FC<ViewModeSwitchProps> = ({
  options,
  initialActiveIndex = 0,
  isDisabled
}) => {
  const [activeIndex, setActiveIndex] = useState(initialActiveIndex)

  return (
    <Container width={options.length * OPTION_WIDTH}>
      <ActiveBg translateX={activeIndex * OPTION_WIDTH} />

      <Modes>
        {options.map((option, index) => (
          <Mode
            key={option}
            isActive={index === activeIndex}
            onClick={() => isDisabled || setActiveIndex(index)}
          >
            {option}
          </Mode>
        ))}
      </Modes>
    </Container>
  )
}

export default ViewModeSwitch
