import { gql } from 'types/layoutcreator'

export const TRACK_EXPORT = gql(/* GraphQL */ `
  subscription trackExport($id: String!) {
    trackExport(id: $id) {
      id
      groupId
      projectId
      status
      startedAt
      finishedAt
      outputProfile
      error
      pages {
        pageId
        status
        startedAt
        finishedAt
        pdfUrl
        error {
          code
          message
        }
      }
      author {
        id
        fullName
      }
    }
  }
`)
