import { InlineSkeleton, Skeleton } from 'shared/ui/Skeleton'

const YearbookCardNewSkeleton = () => (
  <Skeleton
    width={265}
    height={200}
    colorLevel={2}
    radius={5}
    direction="column"
    justifyContent="flex-start"
    alignItems="flex-start"
  >
    <Skeleton width={265} height={120} colorLevel={3} radius={5} />
    <InlineSkeleton
      width={140}
      height={18}
      colorLevel={4}
      marginLeft={24}
      marginTop={24}
    />
    <InlineSkeleton
      width={200}
      height={10}
      colorLevel={3}
      marginLeft={24}
      marginTop={8}
    />
  </Skeleton>
)

export default YearbookCardNewSkeleton
