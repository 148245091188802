type SetValue<T> = (value: T | ((val: T) => T)) => void

const useSessionStorage = <T>(key: string) => {
  const getItem = (): T | null => {
    try {
      const item = sessionStorage.getItem(key)
      return item ? JSON.parse(item) : null
    } catch (error) {
      console.error(error)
      return null
    }
  }

  const setItem: SetValue<T> = value => {
    try {
      const valueToStore =
        value instanceof Function ? value(getItem() as T) : value
      sessionStorage.setItem(key, JSON.stringify(valueToStore))
    } catch (error) {
      console.error(error)
    }
  }

  return { getItem, setItem }
}

export default useSessionStorage
