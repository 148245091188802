import { makeLayoutCreatorRequest } from './gql'
import { layoutCreatorSignInMutation } from './gql/mutations'

export const signInToLayoutCreator = async () => {
  const gradooToken = localStorage.getItem('token')

  const response = await makeLayoutCreatorRequest({
    body: layoutCreatorSignInMutation(),
    token: gradooToken!
  })

  const { accessToken, refreshToken } = response.data.signIn

  localStorage.setItem('lcToken', accessToken)
  localStorage.setItem('lcRefreshToken', refreshToken)

  return {
    token: accessToken,
    refreshToken: refreshToken
  }
}
