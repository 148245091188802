import { PublisherInterface } from '@chili-publish/publisher-interface'
import { Form, Formik } from 'formik'
import { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'

import Flex from 'shared/ui/Flex'
import ChiliImages from 'shared/components/Tools/services/ChiliImages'
import ChiliShapes from 'shared/components/Tools/services/ChiliShapes'
import { FrameService } from 'shared/components/Tools/services/types'
import {
  ChangeFrameProps,
  ChangeTextFormat,
  Font,
  Frame,
  FrameSizeFields,
  FrameTypes,
  ImageFrame,
  TextAlign,
  TextFormat
} from 'shared/components/Tools/types'

import FormGroup from '../FormGroup'
import ImageControls from '../ImageControls'
import ShapeControls from '../ShapeControls'
import TextControls, { TEXT_FORMAT_FIELDS } from '../TextControls'

const StyledForm = styled(Form)`
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
`
const StyledFormik = styled(Formik)`
  min-height: 100%;
`

export enum SecondLevelPanels {
  font = 'font',
  color = 'color',
  shapeType = 'shapeType'
}

const shapesApi = ChiliShapes.getInstance()
const imagesApi = ChiliImages.getInstance()

type FieldsType = { [key: string]: Array<keyof Frame> }

const POSITION_FIELDS: FieldsType = {
  text: ['x', 'y', 'rotation'],
  image: ['x', 'y', 'rotation'],
  shape: ['x', 'y', 'rotation'],
  rectangle: ['x', 'y', 'rotation']
}

type FrameControlsProps = {
  frame: Frame
  textFormat: TextFormat | null
  font: Font | null
  changeFrameProps: ChangeFrameProps
  changeTextFormat: ChangeTextFormat
  publisherConnector: PublisherInterface
}

const FrameControls: React.FC<FrameControlsProps> = ({
  frame,
  textFormat,
  font,
  changeFrameProps,
  changeTextFormat,
  publisherConnector
}) => {
  const { type } = frame
  const [secondLevelPanel, setSecondLevelPanel] =
    useState<SecondLevelPanels | null>(null)

  const positionFields = useMemo(
    () => POSITION_FIELDS[type] || [],
    [type]
  )

  const initialPositionFieldsValues = positionFields.reduce(
    (acc, item) => ({
      ...acc,
      [item]: frame[item]
    }),
    {}
  )

  const initialTextFormatFieldsValues = TEXT_FORMAT_FIELDS.reduce(
    (acc, item) => ({
      ...acc,
      [item]: textFormat ? textFormat[item] : ''
    }),
    {}
  )

  const selectedFrameApi = useMemo(() => {
    const apiMap: Partial<Record<FrameTypes, FrameService>> = {
      [FrameTypes.shape]: shapesApi,
      [FrameTypes.image]: imagesApi
    }

    return apiMap[type] || null
  }, [type])

  const initialValues = {
    ...initialPositionFieldsValues,
    ...initialTextFormatFieldsValues,
    [FrameSizeFields.width]: frame.width,
    [FrameSizeFields.height]: frame.height,
    isSizeConnected:
      selectedFrameApi?.getOrAddFrame(frame).isSizeConnected,
    textAlign: textFormat ? textFormat.textAlign : TextAlign.left,
    fontStyle: null,
    textStyles: [],
    shapeType: shapesApi.getShapeType(frame)
  }

  useEffect(() => {
    if (secondLevelPanel) {
      setSecondLevelPanel(null)
    }
  }, [frame])
  return (
    <StyledFormik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={(_, { setSubmitting }) => {
        setSubmitting(false)
      }}
    >
      {() => (
        <StyledForm style={{ flex: 1 }}>
          <Flex justifyContent="space-between">
            {positionFields.map(field => (
              <FormGroup
                key={field}
                id={field}
                onChange={changeFrameProps}
                defaultUnit={field === 'rotation' ? '°' : undefined}
                width={70}
              />
            ))}
          </Flex>
          {type === FrameTypes.text && textFormat && (
            <TextControls
              frame={frame}
              font={font}
              publisherConnector={publisherConnector}
              textFormat={textFormat}
              changeTextFormat={changeTextFormat}
              secondLevelPanel={secondLevelPanel}
              setSecondLevelPanel={setSecondLevelPanel}
            />
          )}
          {type === FrameTypes.shape && (
            <ShapeControls
              publisherConnector={publisherConnector}
              frame={frame}
              changeFrameProps={changeFrameProps}
              secondLevelPanel={secondLevelPanel}
              setSecondLevelPanel={setSecondLevelPanel}
            />
          )}
          {type === FrameTypes.image && (
            <ImageControls
              frame={frame as ImageFrame}
              changeFrameProps={changeFrameProps}
            />
          )}
        </StyledForm>
      )}
    </StyledFormik>
  )
}

export default FrameControls
