import { Route, Routes } from 'react-router-dom'

import Admin from 'screens/Admin'
import AdminImagesMigration from 'screens/AdminImagesMigration'
import AdminProject from 'screens/AdminProject'
import Home from 'screens/Home'
import Maintenance from 'screens/Maintenance'
import Export from 'screens/ProjectExport'
import Settings from 'screens/ProjectSettings'
import { YearbookEditor } from 'screens/YearbookEditor'
import YearbookPages from 'screens/YearbookPages'

const Router = () => (
  <Routes>
    {/* <Route path="*" element={<Maintenance />} /> */}

    <Route path="/" element={<Home />}></Route>
    <Route path=":yearbookId" element={<YearbookPages />} />
    <Route
      path=":yearbookId/editor/:pageId"
      element={<YearbookEditor />}
    />
    <Route path=":yearbookId/settings" element={<Settings />} />
    <Route path=":yearbookId/settings/export" element={<Export />} />
    <Route path="/admin/*" element={<Admin />} />
    <Route path="/admin/old/:yearbookId" element={<AdminProject />} />
    {/* <Route
      path="/admin/:yearbookId/migrations/:migrationId"
      element={<AdminImagesMigration />}
    /> */}
  </Routes>
)

export default Router
