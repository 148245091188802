import { ReactNode } from 'react'
import ReactModal from 'react-modal'

import { useSupportedResize } from 'shared/hooks/useSupportedResize'
import { MODAL_Z_INDEX } from 'shared/styles/z-index'

if (process.env.NODE_ENV !== 'test') ReactModal.setAppElement('#root')

export interface BaseModalProps extends ReactModal.Props {
  closeModal: () => void
  children: ReactNode
}

const INITIAL_STYLE = {
  overlay: {
    zIndex: MODAL_Z_INDEX
  },
  content: {
    background: 'white',
    outline: 'none'
  }
}

const BaseModal = ({
  isOpen,
  closeModal,
  children,
  style,
  className
}: BaseModalProps) => {
  const isSupportedSize = useSupportedResize()

  if (!isSupportedSize) {
    return null
  }

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={closeModal}
      ariaHideApp={false}
      style={{
        overlay: {
          ...INITIAL_STYLE.overlay,
          ...style?.overlay
        },
        content: {
          ...INITIAL_STYLE.content,
          ...style?.content
        }
      }}
      className={className}
    >
      <div onClick={e => e.stopPropagation()}>{children}</div>
    </ReactModal>
  )
}

export default BaseModal
