import { useMutation, useQuery } from '@apollo/client'
import { DragOverlay } from '@dnd-kit/core'
import { useCallback } from 'react'
import toast from 'react-hot-toast'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useIntl } from 'react-intl'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'

import Flex from 'components/Flex'
import { Skeleton } from 'components/Skeleton'
import useAuth from 'data/gradoo/hooks/useAuth'
import {
  ADD_PHOTO_TO_COLLAGES_ALBUM,
  REMOVE_PHOTO_FROM_COLLAGES_ALBUM
} from 'data/gradoo/mutations/collages'
import { COLLAGES_PHOTOS } from 'data/gradoo/queries/collages'
import {
  ADD_NEW_CHILI_IMAGE,
  REPLACE_CHILI_IMAGE
} from 'data/layoutcreator/mutations/images'
import { fetchImageFile } from 'helpers/file'
import useFixMissingScroll from 'hooks/useFixInfiniteScroll'
import useImageLoading from 'hooks/useImageLoading'
import ChiliImages from 'screens/YearbookEditor/components/Tools/services/ChiliImages'
import {
  Draggable,
  useContentDnd
} from 'screens/YearbookEditor/providers/ContentDndProvider'
import { Ids } from 'screens/YearbookEditor/providers/ContentDndProvider/types'
import { ImageSources } from 'types/global'
import { CollagesPhotoFragment } from 'types/gradoo/graphql'

import CollagePanelSkeleton from './Skeleton'

const PAGE_SIZE = 32 // Decided by initial rendered image count for Macbook 13'
const imagesApi = ChiliImages.getInstance()

const Image = styled.div`
  width: 58px;
  height: 58px;
  border-radius: 6px;
  margin-bottom: 6px;
  cursor: pointer;
  background-position: center;
  background-size: cover;
`

const Count = styled.span`
  margin-top: 20px;
  font-size: 14px;
  color: ${({ theme }) => theme.color.base.c6};
`

type ImageWrapperProps = {
  src: string
  onClick?: () => void
}
const ImageWrapper: React.FC<ImageWrapperProps> = ({
  src,
  ...rest
}) => {
  const { url, isLoading } = useImageLoading(src)

  return (
    <Image
      style={{
        backgroundImage: `url('${url}')`
      }}
      {...rest}
    >
      {isLoading && <Skeleton radius={6} />}
    </Image>
  )
}

type CollagePanelProps = {
  collageAlbumId?: string
  collagesPhotoCount?: number
}

const CollagePanel: React.FC<CollagePanelProps> = ({
  collageAlbumId,
  collagesPhotoCount
}) => {
  const { formatMessage } = useIntl()

  const { pathname } = useLocation()
  const projectId = pathname.split('/')[1]
  const { authGroupId: groupId } = useAuth()

  const {
    data: collagesPhotosData,
    fetchMore: fetchMoreCollagePhotos
  } = useQuery(COLLAGES_PHOTOS, {
    skip: !collageAlbumId,
    variables: {
      collagesAlbum: collageAlbumId,
      first: PAGE_SIZE,
      after: null
    },
    context: { client: 'gradoo' },
    notifyOnNetworkStatusChange: true
  })

  const hasNextPage =
    collagesPhotosData?.collagesPhotos?.pageInfo.hasNextPage || false
  const endCursor =
    collagesPhotosData?.collagesPhotos?.pageInfo.endCursor

  const onLoadMore = useCallback(async () => {
    hasNextPage &&
      (await fetchMoreCollagePhotos({
        variables: {
          collageAlbumId,
          after: endCursor
        }
      }))
  }, [collageAlbumId, endCursor, fetchMoreCollagePhotos, hasNextPage])

  const [addPhoto] = useMutation(ADD_PHOTO_TO_COLLAGES_ALBUM, {
    context: { client: 'gradoo' }
  })

  const [removePhoto] = useMutation(
    REMOVE_PHOTO_FROM_COLLAGES_ALBUM,
    {
      context: { client: 'gradoo' }
    }
  )

  const [addNewChiliImage] = useMutation(ADD_NEW_CHILI_IMAGE)
  const [replaceChiliImage] = useMutation(REPLACE_CHILI_IMAGE)

  const collagesPhotos: CollagesPhotoFragment[] =
    (collagesPhotosData?.collagesPhotos?.edges.map(
      edge => edge?.node
    ) as CollagesPhotoFragment[]) || []

  const handleImageClick = async (photo: CollagesPhotoFragment) => {
    const selectedFrame = await imagesApi.getSelectedFrame()

    if (selectedFrame) {
      try {
        toast.dismiss()

        const imageFile = await fetchImageFile(photo.photo)

        if (!imageFile) {
          throw new Error('Cannot fetch image file')
        }

        const loadingToastId = toast.loading(
          formatMessage({ id: 'Toasts.components.uploading' })
        )

        const { data } = await replaceChiliImage({
          variables: {
            projectId,
            groupId: groupId!,
            image: imageFile,
            oldImageId: selectedFrame.externalID
          }
        })

        imagesApi
          .replaceImage(data.replaceChiliImage)
          .then(() => {
            toast.remove(loadingToastId)
          })
          .catch(() => {
            toast.error(
              formatMessage({
                id: 'Toasts.images.replacing.error'
              })
            )
            toast.remove(loadingToastId)
          })

        toast.dismiss(loadingToastId)

        toast.success(
          formatMessage({
            id: 'Toasts.components.replacing.success'
          })
        )
      } catch {
        toast.dismiss()
        toast.error(
          formatMessage({ id: 'Toasts.components.replacing.error' })
        )
      }
    } else {
      try {
        const loadingToastId = toast.loading(
          formatMessage({ id: 'Toasts.components.uploading' })
        )

        const imageFile = await fetchImageFile(photo.photo)

        const { data } = await addNewChiliImage({
          variables: {
            projectId,
            groupId,
            image: imageFile,
            source: ImageSources.collage
          }
        })

        imagesApi
          .createNewFrame({
            ...data.addNewChiliImage,
            imageUrl: photo.photo
          })
          .then(() => {
            toast.remove(loadingToastId)
          })
      } catch {
        toast.dismiss()
        toast.error(
          formatMessage({
            id: 'Toasts.components.uploading.error'
          })
        )
      }
    }
  }

  const { draggingContent } = useContentDnd()

  useFixMissingScroll({
    hasMoreItems: hasNextPage,
    fetchMoreItems: onLoadMore,
    parentElementID: 'scrollableDivParent'
  })

  return (
    <Flex direction="column">
      <InfiniteScroll
        dataLength={collagesPhotos.length}
        next={onLoadMore}
        hasMore={hasNextPage}
        loader={
          <Flex wrap="wrap" columnGap={6}>
            <CollagePanelSkeleton count={4} />
          </Flex>
        }
        scrollThreshold={0.8}
        scrollableTarget="scrollableDivParent" // Parent: RightPanel line:188
        endMessage={
          <Count>
            {`${collagesPhotoCount} ${formatMessage({
              id: 'Panel.collage.count'
            })}`}
          </Count>
        }
      >
        <Flex wrap="wrap" columnGap={6}>
          {collagesPhotos.map(collagesPhoto => (
            <Draggable
              id={collagesPhoto.id}
              key={collagesPhoto.id}
              type={Ids.image}
              onDragStart={() =>
                draggingContent.setImageUrl(collagesPhoto.photo)
              }
            >
              <ImageWrapper
                data-testid={`CollagePhoto:${collagesPhoto.id}`}
                onClick={() => handleImageClick(collagesPhoto)}
                src={collagesPhoto.photo}
              />
            </Draggable>
          ))}
          <DragOverlay>
            <ImageWrapper src={draggingContent.imageUrl} />
          </DragOverlay>
        </Flex>
      </InfiniteScroll>
    </Flex>
  )
}

export default CollagePanel
