import { useMutation } from '@apollo/client'
import { ReactElement, useState } from 'react'
import toast from 'react-hot-toast'
import { useIntl } from 'react-intl'
import {
  useLocation,
  useNavigate,
  useSearchParams
} from 'react-router-dom'

import useAuth from 'data/gradoo/hooks/useAuth'
import { COPY_PAGE } from 'data/layoutcreator/mutations/documents'
import { REMOVE_PAGE } from 'data/layoutcreator/mutations/projects'
import { GET_PROJECT_DOCUMENTS } from 'data/layoutcreator/queries/documents'
import { GET_PROJECT_TEMPLATES } from 'data/layoutcreator/queries/projects'
import {
  isPagePreviewModalOpenVar,
  pagePreviewNumberVar,
  pagePreviewPageIdVar,
  pagePreviewTypeVar,
  pagePreviewUrlVar
} from 'data/store/page-preview-modal'
import { replaceSpaces } from 'helpers/string'

import BlockingUserOverlay from './components/BlockingUserOverlay'
import ConfirmDeleteModal from './components/ConfirmDeleteModal'
import Overlay from './components/Overlay'
import Tool from './components/Tool'
import { TOOLS } from './config'
import { PageToolsProps, ToolsEnum } from './types'

const PageTools = ({
  pageId,
  pageName,
  isViewLoading,
  view,
  previewUrl,
  pageNumber,
  editingLiveUser,
  type
}: PageToolsProps): ReactElement => {
  const { authGroupId } = useAuth()
  const { formatMessage } = useIntl()
  const navigate = useNavigate()
  const { pathname: projectIdParam } = useLocation()
  const [searchParams] = useSearchParams()
  const viewSearchParam = searchParams.get('view')
  const projectId = decodeURIComponent(projectIdParam).replace(
    '/',
    ''
  )

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)

  const refetchQueries = [
    {
      query: GET_PROJECT_DOCUMENTS,
      variables: { groupId: authGroupId, projectId }
    }
  ]

  const [copyPage] = useMutation(COPY_PAGE, {
    refetchQueries
  })

  const [removePage] = useMutation(REMOVE_PAGE, {
    refetchQueries: [
      ...refetchQueries,
      {
        query: GET_PROJECT_TEMPLATES,
        variables: {
          projectId: replaceSpaces(projectId),
          groupId: authGroupId
        }
      }
    ]
  })

  const handleDelete = async () => {
    try {
      const duplicatingToastId = toast.loading(
        formatMessage({ id: 'Pages.page.deleting' })
      )
      const resp = await removePage({
        variables: {
          projectId: replaceSpaces(projectId),
          itemId: pageId
        }
      })
      toast.remove(duplicatingToastId)
      if (resp.data?.removePage) {
        toast.success(formatMessage({ id: 'Pages.page.deleted' }))
      } else {
        toast.error(
          formatMessage({ id: 'Pages.page.deletion.error' })
        )
      }
    } catch (error) {
      toast.error(formatMessage({ id: 'Pages.page.deletion.error' }))
      console.error(error)
    }
  }

  const editPath = viewSearchParam
    ? `editor/${pageId}?view=${viewSearchParam}`
    : `editor/${pageId}`

  const actions: Record<ToolsEnum, () => void> = {
    [ToolsEnum.edit]: () => navigate(editPath),
    [ToolsEnum.duplicate]: async () => {
      try {
        const duplicatingToastId = toast.loading(
          formatMessage({ id: 'Pages.duplicating.page' })
        )
        const resp = await copyPage({
          variables: {
            pageName: pageName,
            itemId: pageId,
            groupId: authGroupId as string,
            projectId
          }
        })
        toast.remove(duplicatingToastId)
        if (resp.data?.copyPage) {
          toast.success(
            formatMessage({ id: 'Pages.page.duplicated' })
          )
        } else {
          toast.error(
            formatMessage({ id: 'Pages.page.duplication.error ' })
          )
        }
      } catch (error) {
        toast.error(
          formatMessage({ id: 'Pages.page.duplication.error ' })
        )
        console.error(error)
      }
    },
    [ToolsEnum.view]: () => {
      if (isViewLoading) {
        return
      }

      pagePreviewUrlVar(previewUrl)
      pagePreviewNumberVar(pageNumber)
      isPagePreviewModalOpenVar(true)
      pagePreviewPageIdVar(pageId)
      pagePreviewTypeVar(type || '')
    },
    [ToolsEnum.delete]: () => {
      setIsDeleteModalOpen(true)
    }
  }

  if (!!editingLiveUser) {
    return (
      <BlockingUserOverlay
        view={view}
        editingLiveUser={editingLiveUser}
      />
    )
  }

  return (
    <Overlay view={view}>
      {TOOLS.map(tool => (
        <Tool
          isDisabled={tool.id === ToolsEnum.view && isViewLoading}
          data-testid={`PageTool:${pageId}:${tool.id}`}
          key={tool.id}
          onClick={actions[tool.id]}
        >
          <img src={tool.icon} alt={tool.id} />
        </Tool>
      ))}

      <ConfirmDeleteModal
        isOpen={isDeleteModalOpen}
        onConfirm={handleDelete}
        closeModal={() => setIsDeleteModalOpen(false)}
      />
    </Overlay>
  )
}

export default PageTools
