import { GradooTheme } from 'gradoo-theme/lib/types'
import React, { memo } from 'react'
import { useIntl } from 'react-intl'
import { useTheme } from 'styled-components'

type TextProps = {
  tag?: 'p' | 'span' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'
  id?: string
  text?: string
  lineHeight?: number | string
  size?: number
  weight?: number
  color?: string | ((theme: GradooTheme) => string)
  align?: 'left' | 'center' | 'right'
  marginTop?: number
  marginBottom?: number
  marginLeft?: number
  marginRight?: number
}

const Text: React.FC<TextProps> = memo(
  ({
    tag = 'span',
    id,
    text,
    size = 14,
    lineHeight,
    weight = 400,
    color,
    align = 'left',
    marginTop = 0,
    marginBottom = 0,
    marginLeft = 0,
    marginRight = 0,
  }) => {
    const { formatMessage } = useIntl()
    const theme = useTheme()

    const content = text || (id && formatMessage({ id }))
    const style = {
      fontSize: size,
      fontWeight: weight,
      color: typeof color === 'function' ? color(theme) : color,
      textAlign: align,
      lineHeight,
      marginTop,
      marginBottom,
      marginLeft,
      marginRight
    }

    const formattedContent = content?.split('\n').map((string, i) => (
      <>
        {i > 0 ? <br /> : null}
        <span>{string}</span>
      </>
    ))

    return tag === 'p' ? (
      <p style={style}>{formattedContent}</p>
    ) : tag === 'span' ? (
      <span style={style}>{formattedContent}</span>
    ) : tag === 'h1' ? (
      <h1 style={style}>{formattedContent}</h1>
    ) : tag === 'h2' ? (
      <h2 style={style}>{formattedContent}</h2>
    ) : tag === 'h3' ? (
      <h3 style={style}>{formattedContent}</h3>
    ) : tag === 'h4' ? (
      <h4 style={style}>{formattedContent}</h4>
    ) : tag === 'h5' ? (
      <h5 style={style}>{formattedContent}</h5>
    ) : tag === 'h6' ? (
      <h6 style={style}>{formattedContent}</h6>
    ) : (
      <></>
    )
  }
)

export default Text
