import { useReactiveVar } from '@apollo/client'
import { ErrorMessage } from '@hookform/error-message'
import { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'

import Flex from 'components/Flex'
import {
  FormError,
  IconLabel,
  InputContainer,
  InputProps,
  OuterContainer,
  StyledInput
} from 'components/FormInput'
import { phoneCountryCodeVar } from 'data/store/verify-address'

import SelectCountryModal, {
  COUNTRIES,
  CountryIDs
} from '../SelectCountryModal'
import { Flag } from '../SelectCountryModal/components/CountryInput'
import useSelectCountryModal from '../SelectCountryModal/hooks/use-select-country-modal'
import openListIconSrc from './images/open_list_icn.svg'

const CountryCode = styled.div`
  font-size: 18px;
`

export type PhoneNumberValue = {
  phoneNumber?: string
}

type PhoneNumberInputProps = {
  countryCode: CountryIDs
}

function PhoneNumberInput<TFormValues extends PhoneNumberValue>({
  id,
  label,
  register,
  errors,
  rules = {},
  isFullWidth,
  isDisabled,
  hideErrors,
  countryCode
}: InputProps<TFormValues> & PhoneNumberInputProps) {
  const {
    country,
    setCountry,
    openSelectCountryModal,
    closeSelectCountryModal,
    isSelectCountryModalOpen,
    selectedCountry
  } = useSelectCountryModal({
    countryCode,
    reactiveVar: phoneCountryCodeVar
  })

  useEffect(() => {
    phoneCountryCodeVar(country)
  }, [country])

  return (
    <>
      <OuterContainer isFullWidth={isFullWidth}>
        <InputContainer isFullWidth={isFullWidth}>
          <IconLabel>
            <Flex
              justifyContent="space-between"
              columnGap={16}
              onClick={openSelectCountryModal}
              style={{ cursor: 'pointer' }}
            >
              <Flex columnGap={8} wrap="wrap" alignItems="center">
                <Flag src={selectedCountry?.icon} />
                <img src={openListIconSrc} />
              </Flex>
              <CountryCode>+{selectedCountry?.phoneCode}</CountryCode>
            </Flex>
          </IconLabel>
          <StyledInput
            type="text"
            placeholder={label as string}
            id={id}
            isInvalid={!!errors?.[id]}
            {...(register && register(id, rules))}
            isFullWidth={isFullWidth}
            disabled={isDisabled}
            hasIcon
            style={{ paddingLeft: 122 }}
          />
        </InputContainer>
        {!hideErrors && (
          <FormError>
            <ErrorMessage name={id as any} errors={errors} />
          </FormError>
        )}
      </OuterContainer>
      <SelectCountryModal
        closeModal={closeSelectCountryModal}
        isOpen={isSelectCountryModalOpen}
        setCountry={setCountry}
        selectedCountry={selectedCountry?.id}
      />
    </>
  )
}

export default PhoneNumberInput
