import Button, { ButtonProps } from 'components/Button'

type ModalButtonProps = ButtonProps

const ModalButton: React.FC<ModalButtonProps> = ({
  padding,
  ...rest
}) => {
  return <Button {...rest} padding={[13, 16]} />
}

export default ModalButton
