import htmr from 'htmr'
import { useMemo } from 'react'
import styled from 'styled-components'

import Flex, { FlexDivProps } from 'components/Flex'
import {
  Font,
  FontFamily,
  FontStyles
} from 'screens/YearbookEditor/components/Tools/types'

import checkSrc from './icon/check.svg'

interface ContainerProps extends FlexDivProps {
  isSelected: boolean
  onClick: () => void
}

const Container = styled(Flex).attrs({
  alignItems: 'center',
  justifyContent: 'space-between'
})<ContainerProps>`
  height: 40px;
  padding: 0 16px;
  background: ${({ theme, isSelected }) =>
    isSelected ? theme.color.base.c1 : theme.color.base.c1};
  border-radius: 32px;
  border: ${({ theme, isSelected }) =>
    isSelected && `1px solid ${theme.color.brand_02}`};
  overflow: hidden;
  cursor: pointer;

  &::after {
    display: flex;
    flex-shrink: 0;
    width: 22px;
    height: 22px;
    background-color: ${({ theme, isSelected }) =>
      isSelected ? theme.color.brand_02 : theme.color.base.c3};
    background-image: ${({ isSelected }) =>
      isSelected && `url('${checkSrc}')`};
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 50%;
    content: '';
  }

  svg {
    transform: translateY(2px);

    path {
      fill: ${({ isSelected, theme }) =>
        isSelected && theme.color.brand_02};
    }
  }
`

type FontPreviewProps = {
  fontFamily: FontFamily
  selectedFontFamily: FontFamily | null
  setFont: (font: Font) => void
}

const FontPreview = ({
  fontFamily,
  selectedFontFamily,
  setFont
}: FontPreviewProps): JSX.Element => {
  const firstFont = useMemo(() => {
    const regular = fontFamily.styles[FontStyles.regular]

    if (regular) return regular

    return Object.values(fontFamily.styles)[0]
  }, [fontFamily])

  return (
    <Container
      marginBottom={16}
      isSelected={fontFamily?.id === selectedFontFamily?.id}
      onClick={() => {
        if (fontFamily?.id !== selectedFontFamily?.id) {
          return setFont(firstFont)
        }
      }}
    >
      {firstFont.svgSmall && htmr(firstFont.svgSmall)}
    </Container>
  )
}

export default FontPreview
