import { useCallback, useEffect, useRef, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import {
  UseFormRegister,
  UseFormResetField,
  UseFormSetValue
} from 'react-hook-form'
import { useIntl } from 'react-intl'
import styled from 'styled-components'
import toast from 'react-hot-toast'

import { FieldValues } from '../../index'
import Container from './components/Container'
import UploadIcon from './components/UploadIcon'
import fileSuccessSrc from './images/file-check-03.svg'
import fileErrorSrc from './images/file-x-03.svg'

const Small = styled.div`
  font-size: 10px;
`

const FakeLink = styled(Small)`
  text-decoration: underline;
  color: ${({ theme }) => theme.color.brand_02};
`

const Img = styled.img`
  width: 64px;
`

const DefaultMessage = styled.div`
  margin-bottom: 4px;
  font-size: 14px;
  font-weight: 600;
`

const SuccessMessage = styled(DefaultMessage)`
  color: ${({ theme }) => theme.color.success};
`

const ErrorMessage = styled(DefaultMessage)`
  color: ${({ theme }) => theme.color.error};
`

const ClickInput = styled.input`
  display: none;
`

type UploadCoverProps = {
  setValue: UseFormSetValue<FieldValues>
  resetField: UseFormResetField<FieldValues>
  hasError: boolean
  register: UseFormRegister<FieldValues>
}

const UploadCover = ({
  setValue,
  resetField,
  hasError,
  register
}: UploadCoverProps) => {
  const { formatMessage } = useIntl()

  const inputRef = useRef<HTMLInputElement | null>(null)

  const [acceptedFile, setAcceptedFile] = useState<File | null>(null)

  const onDropAccepted = useCallback(
    (acceptedFiles: File[]) => {
      if (acceptedFiles.length > 0) {
        setValue('cover', acceptedFiles[0], { shouldValidate: true })
      }
    },
    [setValue]
  )

  const handleFileUploadAfterClick = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const file = e.target.files![0]

      if (file && file.type === 'application/pdf') {
        setAcceptedFile(file)
        setValue('cover', file, { shouldValidate: true })
      } else {
        toast.error(formatMessage({ id: 'Form.field.fileType' }, { format: 'PDF' }))
      }
    },
    [setValue]
  )

  const resetCover = useCallback(
    () => resetField('cover'),
    [resetField]
  )

  const handleCoverClick = useCallback(() => {
    inputRef.current?.click()
  }, [])

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragReject,
    acceptedFiles: dndAcceptedFiles,
    fileRejections
  } = useDropzone({
    onDropAccepted,
    onDropRejected: resetCover,
    onFileDialogOpen: resetCover,
    maxFiles: 1,
    accept: {
      'application/pdf': ['.pdf']
    }
  })

  useEffect(() => {
    if (dndAcceptedFiles.length > 0) {
      setAcceptedFile(dndAcceptedFiles[0])
    }
  }, [dndAcceptedFiles])

  function renderDropZoneState() {
    switch (true) {
      case isDragReject:
        return (
          <>
            <UploadIcon isDragReject />
            <div>
              <ErrorMessage>
                {formatMessage({
                  id: 'Project.printdata.cover.drag.reject'
                })}
              </ErrorMessage>
              <Small>&nbsp;</Small>
            </div>
          </>
        )
      case isDragActive:
        return (
          <>
            <UploadIcon isDragActive />
            <div>
              <div>
                {formatMessage({
                  id: 'Project.printdata.cover.drag.active'
                })}
              </div>
              <Small>&nbsp;</Small>
            </div>
          </>
        )
      case !!acceptedFile: {
        const { name } = acceptedFile!
        return (
          <>
            <Img src={fileSuccessSrc} />
            <div>
              <SuccessMessage>
                {formatMessage({
                  id: 'Project.printdata.cover.add.success'
                })}
              </SuccessMessage>
              <Small>{name}</Small>
            </div>
          </>
        )
      }
      case !!fileRejections.length:
        return (
          <>
            <Img src={fileErrorSrc} />
            <div>
              <ErrorMessage>
                {formatMessage({
                  id: 'Project.printdata.cover.error.title'
                })}
              </ErrorMessage>
              <Small>
                {formatMessage({
                  id: 'Project.printdata.cover.error.subtitle'
                })}
              </Small>
            </div>
          </>
        )
      default:
        return (
          <>
            <UploadIcon />
            <div>
              <DefaultMessage>
                {formatMessage({
                  id: 'Project.printdata.cover.default.title'
                })}
              </DefaultMessage>
              <FakeLink>
                {formatMessage({
                  id: 'Project.printdata.cover.default.subtitle'
                })}
              </FakeLink>
            </div>
          </>
        )
    }
  }

  return (
    <Container
      {...getRootProps()}
      isDragActive={isDragActive}
      isDragReject={isDragReject}
      isSuccessful={!!acceptedFile}
      hasError={!!fileRejections.length || hasError}
      onClick={handleCoverClick}
    >
      <input {...getInputProps()} {...register('cover')} />
      {renderDropZoneState()}

      {/* Unfortunatelly, it's not possible to use react-dropzone to open the file upload dialog in Safari. */}
      <ClickInput
        type="file"
        ref={inputRef}
        onChange={handleFileUploadAfterClick}
      />
    </Container>
  )
}

export default UploadCover
