import styled from 'styled-components'

import { PagePreviewsFragment } from 'data/layoutcreator/queries/projects'
import { theme } from 'styles/theme'
import { FragmentType, getFragmentData } from 'types/layoutcreator'

const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 120px;
  border: 1px solid ${({ theme }) => theme.color.brand_01};
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  background-color: ${({ theme }) => theme.color.brand_01};
`

type Skeleton = {
  width: string
  height: string
  backgroundColor: string
  overlayOpacity?: number
}

interface PageProps {
  skeleton: Skeleton
  previewUrl: string
}

const Page = styled.div<PageProps>(({ skeleton, previewUrl }) => {
  const { overlayOpacity, ...skeletonStyles } = skeleton
  return {
    ...skeletonStyles,
    backgroundImage: previewUrl ? `url(${previewUrl})` : 'none',
    backgroundSize: 'cover',
    '&:after': {
      content: previewUrl ? '""' : '',
      display: 'block',
      width: '100%',
      height: '100%',
      opacity: overlayOpacity,
      background: theme.color.brand_02
    }
  }
})

const SMALL = {
  width: '19px',
  height: '27px',
  backgroundColor: theme.color.base.c1,
  overlayOpacity: 0.6
}

const MEDIUM = {
  width: '35px',
  height: '49px',
  backgroundColor: theme.color.base.c2,
  overlayOpacity: 0.3
}

const LARGE = {
  width: '51px',
  height: '72px',
  backgroundColor: theme.color.base.c4,
  overlayOpacity: 0
}

const skeletons = [SMALL, MEDIUM, LARGE, LARGE, MEDIUM, SMALL].map(
  (skeleton, index) => ({
    ...skeleton,
    [`border-top-${index < 3 ? 'left' : 'right'}-radius`]: '3px',
    [`border-bottom-${index < 3 ? 'left' : 'right'}-radius`]: '3px'
  })
)

const Cover = ({
  pages
}: {
  pages: FragmentType<typeof PagePreviewsFragment>[]
}) => {
  const pagePreviewsFragment = getFragmentData(
    PagePreviewsFragment,
    pages
  )

  return (
    <Container>
      {skeletons.map((skeleton, index) => (
        <Page
          key={index}
          skeleton={skeleton}
          previewUrl={pagePreviewsFragment[index]?.previewUrl}
        />
      ))}
    </Container>
  )
}

export default Cover
