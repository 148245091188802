import { useState } from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components'

import Flex from 'shared/ui/Flex'
import { useClickToCopy } from 'shared/hooks/useClickToCopy'
import {
  RankingQuestionResultFragment,
  RankingsQuestionFragment
} from 'shared/types/gradoo/graphql'

import Option from './components/RankingsOption'

const Title = styled.div`
  font-weight: 600;
  font-size: 11px;
  margin-bottom: 9px;
`

const Count = styled.span`
  font-size: 10px;
  color: ${({ theme }) => theme.color.base.c4};
`

const ShowAllButton = styled.button`
  border: none;
  background: none;
  padding: 0;
  margin: 0;
  font-weight: 600;
  font-size: 10px;
  color: ${({ theme }) => theme.color.brand_02};
  text-decoration: underline;
  cursor: pointer;
`

type RankingsItemProps = {
  'data-testid'?: string
  rankingsQuestion: RankingsQuestionFragment
}

const RankingsItem: React.FC<RankingsItemProps> = ({
  rankingsQuestion,
  ...rest
}) => {
  const { formatMessage } = useIntl()
  const { register } = useClickToCopy()

  const [showAll, setShowAll] = useState(false)

  const rankingQuestionResultOptions =
    (rankingsQuestion.results
      ?.options as RankingQuestionResultFragment[]) || []

  const sortedOptions = rankingQuestionResultOptions
    .filter(op => !!op)
    .sort(
      (op1, op2) => Number(op2.percentage) - Number(op1.percentage)
    )

  const clippedOptions = showAll
    ? sortedOptions
    : sortedOptions.slice(0, 3)
  const hasHiddenOptions = sortedOptions.length > 3

  return (
    <Flex direction="column" width="100%" {...rest}>
      <Title {...register()}>{rankingsQuestion.question}</Title>
      <Flex direction="column" rowGap={10}>
        {clippedOptions.map((rankingQuestionResultOption, i) => (
          <Option
            key={i}
            rankingQuestionResultOption={rankingQuestionResultOption}
          />
        ))}
      </Flex>
      <Flex marginTop={16} justifyContent="space-between">
        <Count>
          {formatMessage(
            { id: 'Panel.rankings.votesCount' },
            {
              voted: rankingsQuestion.results?.alreadyVoted,
              users: rankingsQuestion.results?.possibleVoters
            }
          )}
        </Count>

        {hasHiddenOptions && (
          <ShowAllButton onClick={() => setShowAll(!showAll)}>
            {showAll
              ? formatMessage({ id: 'Panel.rankings.showLess' })
              : formatMessage({ id: 'Panel.rankings.showAll' })}
          </ShowAllButton>
        )}
      </Flex>
    </Flex>
  )
}

export { default as RankingsItemSkeleton } from './Skeleton'
export default RankingsItem
