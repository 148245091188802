import { CMYKValues, RGBValues } from 'shared/types/global'
import { Font as FontType } from 'shared/types/layoutcreator/graphql'

export enum FrameTypes {
  text = 'text',
  image = 'image',
  shape = 'shape'
}

export enum TextAlign {
  left = 'left',
  right = 'right',
  center = 'center',
  justify = 'justify'
}

export enum FontStyles {
  regular = 'Regular',
  bold = 'Bold',
  italic = 'Italic'
}

export enum TextStyles {
  underline = 'underLine',
  lineThrough = 'lineThrough'
}

export enum FrameSizeFields {
  height = 'height',
  width = 'width'
}

export type Frame = {
  paths: string
  id: string
  type: FrameTypes
  x: string
  y: string
  rotation: string
  tag: string
  fillColor: string
  borderColor: string
  javaScriptDescriptor: string
} & Partial<Record<FrameSizeFields, string>>

export type ImageFrame = Frame & {
  imgWidth: string
  imgHeight: string
  imgX: string
  imgY: string
  externalID: string
  externalName: string
  previewURL: string
  keepAssetExternal: string
  path: string
  variable: string | null
}

export type Shape = {
  paths: string
}

export type Path = {
  javaScriptDescriptor: string
  points: string
  name: string
}

export type Point = {
  x: string
  y: string
  id: string
}

export type TextFormat = {
  fontSize: string
  font: string
  lineHeight: string
  trackingRight: string
  textAlign: TextAlign
  underLine: 'true' | 'false'
  lineThrough: 'true' | 'false'
  color: string
}

export type ChiliColor = {
  name: string
  id: string
} & {
  [k in CMYKValues]?: string
} & {
  [k in RGBValues]?: string
}

export type ChiliVariable = {
  id: string
  name: string
  value: string
  javaScriptDescriptor: string
  dynamicAssetProviderValue: string
  dynamicAssetProvider: string
}

export type ChangeFrameProps = (
  frameProp: string,
  value: string
) => Promise<void>

export type ChangeTextFormat = (
  frameProp: string,
  value: string
) => Promise<void>

export interface Font extends FontType {}

export type FontFamily = {
  id: string
  name: string
  styles: Partial<Record<FontStyles, Font>>
}

export type FontFamiliesMap = Record<string, FontFamily>

export enum ShapeTypes {
  star = 'star',
  triangle = 'triangle',
  rect = 'rectangle',
  ellipse = 'ellipse'
}
