import { useQuery } from '@apollo/client'

import Flex from 'components/Flex'
import { GET_PROJECT_DOCUMENTS } from 'data/layoutcreator/queries/documents'
import Document from 'screens/AdminProject/components/Document'
import { TabProps } from 'screens/AdminProject/types'

const PagesTab: React.FC<TabProps> = ({ groupId, projectId }) => {
  const { data: documentsData } = useQuery(GET_PROJECT_DOCUMENTS, {
    variables: {
      groupId,
      projectId
    }
  })

  return (
    <Flex direction="column" flex={1}>
      <Flex justifyContent="space-between" alignItems="center">
        <h2>
          {documentsData?.projectDocuments.length || 0} pages found
        </h2>
      </Flex>

      <Flex direction="column" rowGap={18} marginBottom={46}>
        {documentsData?.projectDocuments.map((document, i) => (
          <Document
            key={document.id}
            groupId={groupId}
            projectId={projectId}
            order={
              document.index === null || document.index === undefined
                ? 0
                : document.index + 1
            }
            name={document.name}
            id={document.id}
          />
        ))}
      </Flex>
    </Flex>
  )
}

export default PagesTab
