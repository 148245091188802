import { useNavigate } from 'react-router-dom'

import Button from 'shared/ui/Button_2'

type HeaderGoBackButtonProps = {
  onBack?: () => void
}

const HeaderGoBackButton = ({ onBack }: HeaderGoBackButtonProps) => {
  const navigate = useNavigate()

  const handleGoBack = () => {
    navigate(-1)
  }

  return (
    <Button
      onPress={onBack || handleGoBack}
      size="lg"
      intent="sub-action"
      iconName="arrow_narrow_left"
      icon="only"
    />
  )
}

export default HeaderGoBackButton
