import { useQuery } from '@apollo/client'
import { error } from 'console'
import { useMemo, useState } from 'react'
import styled from 'styled-components'

import Button, { ButtonIntents } from 'components/Button'
import Flex from 'components/Flex'
import { GET_PROJECT_EXPORTS } from 'data/layoutcreator/queries/export'
import {
  ProjectExportStatuses,
  ProjectExportTypes,
  TabProps
} from 'screens/AdminProject/types'

import ExportTable from './components/ExportTable'
import ProjectExportModal from './components/ProjectExportModal'
import { getDownloadPdfUrl } from './utils.'

const Title = styled.h2`
  font-size: 28px;
`

const ExportTab: React.FC<TabProps> = ({ groupId, projectId }) => {
  const [isModalOpened, setIsModalOpened] = useState(false)
  const [currentExportId, setCurrentExportId] = useState<
    string | null
  >(null)

  const { data: exportItemsData } = useQuery(GET_PROJECT_EXPORTS, {
    variables: {
      groupId,
      projectId
    }
  })

  const exportItems = useMemo(() => {
    if (!exportItemsData) {
      return []
    }

    const result = [...exportItemsData.getProjectExports]

    return result.reverse()
  }, [exportItemsData])

  return (
    <Flex direction="column" flex={1}>
      <Flex
        alignItems="center"
        justifyContent="space-between"
        marginBottom={22}
      >
        <Title>Export history</Title>

        <div>
          <Button
            labelId="Admin.buttons.startExport"
            borderRadius={100}
            intent={ButtonIntents.dark}
            isFullWidth
            onClick={() => setIsModalOpened(true)}
            fontSize={15}
            padding={[12, 22]}
          />
        </div>
      </Flex>

      <ExportTable
        items={exportItems.map(item => ({
          id: item.id,
          type: item.outputProfile as ProjectExportTypes,
          pagesCount: item.pages.length,
          authorFullName: item.author?.fullName || '-',
          date: item.startedAt,
          status: item.status as ProjectExportStatuses,
          downloadUrl:
            item.status === ProjectExportStatuses.completed ||
            item.status === ProjectExportStatuses.warning
              ? getDownloadPdfUrl({
                  groupId,
                  projectId,
                  exportId: item.id
                })
              : null,
          error: item.error || null
        }))}
        onExportClick={exportId => {
          setCurrentExportId(exportId)
          setIsModalOpened(true)
        }}
      />

      <ProjectExportModal
        isOpen={isModalOpened}
        closeModal={() => setIsModalOpened(false)}
        exportId={currentExportId}
        groupId={groupId}
        projectId={projectId}
      />
    </Flex>
  )
}

export default ExportTab
