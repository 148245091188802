import { useQuery } from '@apollo/client'
import { PublisherInterface } from '@chili-publish/publisher-interface'
import config from 'config'
import { useEffect, useMemo, useState } from 'react'

import { GET_ALL_FONTS } from 'data/layoutcreator/queries/fonts'
import {
  Font,
  FontFamiliesMap,
  FontFamily,
  FontStyles
} from 'shared/components/Tools/types'

function transformFonts(data: Font[]): FontFamiliesMap {
  const result = {} as FontFamiliesMap

  data.forEach(item => {
    const family = result[item.family]
    const { style } = item

    if (family) return (family.styles[style as FontStyles] = item)

    // Add new family
    result[item.family] = {
      id: String(Object.entries(result).length),
      name: item.family,
      styles: { [style]: item }
    }
  })

  return result
}

export default function useFont(
  font: Font | null,
  publisherConnector: PublisherInterface
) {
  const { data, loading, error } = useQuery(GET_ALL_FONTS)

  // TODO: Move to the global state (using context) since we're using the hook on multiple levels 🤔
  const [fontFamilies, setFontFamilies] = useState<FontFamiliesMap>(
    {}
  )
  const [currentFontFamily, setCurrentFontFamily] =
    useState<FontFamily | null>(null)
  const [currentFont, setCurrentFont] = useState<Font | null>(null)

  useEffect(() => {
    if (!data) return
    setFontFamilies(transformFonts(data.getAllFonts))
  }, [data])

  useEffect(() => {
    setFontState(font)
  }, [font, fontFamilies])

  const setFontState = (font: Font | null) => {
    if (!font) {
      return
    }

    let newFont: Font | null = font

    if (!fontFamilies[font.family]) {
      newFont = defaultFont
    }

    if (!newFont) {
      return
    }

    setCurrentFont(newFont)
    setCurrentFontFamily(fontFamilies[newFont.family])
  }

  const setFont = async (font: Font | null = defaultFont) => {
    if (!font) {
      return
    }
    setFontState(font)

    const documentFont = (await publisherConnector.getObject(
      `document.fonts[${font.id}]`
    )) as Font & { javaScriptDescriptor: string }

    // If the font is not present in the document, we need to add it first
    if (!documentFont) {
      const newFont = (await publisherConnector.executeFunction(
        'document.fonts',
        'Add'
      )) as Font & { javaScriptDescriptor: string }

      const newFontChiliPath = `document.fonts[${newFont.id}]`

      const props: Array<keyof Font> = [
        'family',
        'style',
        'name',
        'id'
      ]

      for await (const prop of props) {
        await publisherConnector.setProperty(
          newFontChiliPath,
          prop,
          font[prop] as string
        )
      }

      await publisherConnector.setProperty(
        `document.fonts[${font.id}]`,
        'javaScriptDescriptor',
        `cp_object:document.fonts[${font.id}]`
      )
    }

    await publisherConnector.setProperty(
      'document.selectedText.textFormat',
      'font',
      `cp_object:document.fonts[${font.id}]`
    )
  }

  const changeFontStyle = (newStyle: FontStyles) => {
    if (!currentFontFamily?.styles?.[newStyle]) return

    return setFont(currentFontFamily.styles[newStyle] as Font)
  }

  const availableStyles = useMemo(() => {
    return currentFontFamily
      ? Object.keys(currentFontFamily.styles)
      : null
  }, [currentFontFamily])

  const defaultFont = useMemo(() => {
    const family = fontFamilies[config.defaultFont]

    if (!family || Object.entries(family.styles).length < 1) {
      return null
    }

    return family.styles.Regular || Object.values(family.styles)[0]
  }, [fontFamilies])

  return {
    setFont,
    changeFontStyle,
    currentFont,
    availableStyles,
    fontFamilies,
    currentFontFamily,
    loading,
    error
  }
}
