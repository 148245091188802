import { useIntl } from 'react-intl'
import styled from 'styled-components'

import { ReactComponent as Icon } from './assets/full-screen.svg'

const Container = styled.button`
  display: flex;
  align-items: center;
  column-gap: 8px;
  font-size: 12px;
  font-weight: 600;
  padding: 10px 16px;
  border-radius: 100px;
  color: ${({ theme }) => theme.color.base.c6};
  background: ${({ theme }) => theme.color.base.c0};
  border: 1px solid ${({ theme }) => theme.color.base.c2};
  box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.05);
  cursor: pointer;
`

const StyledIcon = styled(Icon)<{ isActive: boolean }>`
  transform: ${({ isActive }) =>
    isActive ? 'none' : 'rotate(180deg)'};
  transition: transform 0.3s;
`

type FullscreenButtonProps = {
  isActive: boolean
  onClick: () => void
}

const FullscreenButton: React.FC<FullscreenButtonProps> = ({
  isActive,
  onClick
}) => {
  const { formatMessage } = useIntl()

  return (
    <Container onClick={onClick}>
      <StyledIcon isActive={isActive} />
      {isActive
        ? formatMessage({ id: 'Buttons.closeFullscreen' })
        : formatMessage({ id: 'Buttons.openFullscreen' })}
    </Container>
  )
}

export default FullscreenButton
