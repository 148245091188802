import styled from 'styled-components'

import {
  ResponsiveProps,
  responsiveStyles
} from 'shared/styles/utils'

const Island = styled.div<{ responsive?: ResponsiveProps }>`
  margin: 88px auto 0 auto;
  max-width: 1136px;
  height: 100%;
  border-radius: 5px;
  padding: 68px 64px;
  box-shadow: 9px 7px 38px rgba(29, 53, 71, 0.08);
  ${responsiveStyles}
`

export default Island
