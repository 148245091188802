import { ImageFormats } from 'shared/types/global'

export const getRestUrl = (path: string) => {
  return `${process.env.REACT_APP_REST_URL}${path}`
}

export const getBase64ImageUrl = (
  base64: string,
  format: ImageFormats
) => {
  return `data:image/${format};base64,${base64}`
}
