import { useMutation, useQuery } from '@apollo/client'
import { PublisherInterface } from '@chili-publish/publisher-interface'
import { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'

import useAuth from 'data/gradoo/hooks/useAuth'
import { REUPLOAD_OLD_IMAGE } from 'data/layoutcreator/mutations/migrations'
import { SAVE_DOCUMENT } from 'data/layoutcreator/mutations/projects'
import { GET_PAGE_DATA } from 'data/layoutcreator/queries/projects'

import ChiliImages from '../components/Tools/services/ChiliImages'
import ChiliVariables from '../components/Tools/services/ChiliVariables'
import { Frame } from '../components/Tools/types'
import { ChiliVariable } from '../components/Tools/types'

const imagesApi = ChiliImages.getInstance()
const variablesApi = ChiliVariables.getInstance()

export const useEditorMigration = ({
  publisherConnector,
  isDocumentRendered
}: {
  publisherConnector: PublisherInterface | null
  isDocumentRendered: boolean
}) => {
  const { pathname } = useLocation()
  const projectId = pathname.split('/')[1]
  const { pageId: documentId } = useParams()
  const { authGroupId: groupId } = useAuth()

  const [isOutdated, setIsOutdated] = useState<boolean | null>(null)
  const [loadingPercent, setLoadingPercent] = useState<number>(0)
  const [outdatedImages, setOutdatedImages] = useState<
    {
      DAPId: string
      DAPValue: string
      variableId: string
      frame: Frame
      variableName: string
    }[]
  >([])

  const [isSuccess, setIsSuccess] = useState<boolean>(false)
  const [isError, setIsError] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const [reuploadOldImage] = useMutation(REUPLOAD_OLD_IMAGE)
  const [saveDocument] = useMutation(SAVE_DOCUMENT)
  const { data: pageMetaData } = useQuery(GET_PAGE_DATA, {
    variables: {
      documentId: documentId!,
      projectId,
      groupId: groupId!
    }
  })

  const save = async () => {
    console.log('Saving the changes...')
    const isDirty = await publisherConnector?.getDirtyState()
    if (!isDirty) {
      return false
    }
    const tempXml = (await publisherConnector?.executeFunction(
      'document',
      'GetTempXML'
    )) as string

    const { data: savingData } = await saveDocument({
      variables: {
        documentId: documentId!,
        xml: tempXml
      }
    })

    if (savingData?.saveDocument) {
      console.log('%cDocument saved successfully!', 'color: green;')
    }
  }

  const adjustPageNumber = async () => {
    if (pageMetaData) {
      const pageIndex = pageMetaData?.getPageData?.index
      if (pageIndex) {
        await variablesApi.setVariableValue(
          'page_number',
          `${pageIndex + 1}`
        )
        console.log('Page number set to:', pageIndex + 1)
        await save()
      }
    } else {
      console.error('Page metadata is not available')
    }
  }

  useEffect(() => {
    if (!isDocumentRendered) {
      return
    }

    adjustPageNumber()
  }, [pageMetaData, isDocumentRendered])

  const checkIsOutdated = async () => {
    let result = false
    const outdatedImages: {
      DAPId: string
      DAPValue: string
      variableId: string
      variableName: string
      frame: Frame
    }[] = []

    const frames = (await publisherConnector?.getObject(
      'document.allFrames'
    )) as any

    const count = Number(frames?.length) || 0

    for (let i = 0; i < count; i++) {
      const frame = (await publisherConnector?.getObject(
        `document.allFrames[${i}]`
      )) as Frame

      if (frame.type !== 'image') {
        continue
      }

      const variableId = await imagesApi.getImageVariableId(
        frame.javaScriptDescriptor
      )

      if (!variableId) {
        continue
      }

      const variable = (await publisherConnector?.getObject(
        `document.variables[${variableId}]`
      )) as ChiliVariable

      if (variable.name === 'background_image') {
        continue
      }

      outdatedImages.push({
        DAPId: variable.dynamicAssetProvider,
        DAPValue: variable.dynamicAssetProviderValue,
        variableName: variable.name,
        variableId,
        frame: frame
      })

      result = true
    }

    setIsOutdated(result)
    console.log(
      `%c${outdatedImages.length} images are to migrate`,
      'color: orange;'
    )
    setOutdatedImages(outdatedImages)
  }

  useEffect(() => {
    if (!isDocumentRendered) {
      return
    }

    checkIsOutdated()
  }, [isDocumentRendered])

  const migrate = async () => {
    let i = 0
    let successfulCounter = 0
    let failedCounter = 0
    let skippedCounter = 0
    let removedCounter = 0
    let isErrorResult = false
    const ONE_FRAME_LOADING_PERCENT =
      100 / (outdatedImages.length + 1) // +1 for saving the document

    setIsLoading(true)

    console.log(`${outdatedImages.length} images to migrate`)
    for (const image of outdatedImages) {
      console.log(`Checking image frame index: ${i}...`)

      if (image.DAPId && image.DAPValue) {
        console.log(`Image frame №${i} is based on variable`, {
          DAPId: image.DAPId,
          DAPValue: image.DAPValue
        })

        let data

        try {
          const response = await reuploadOldImage({
            variables: {
              DAPId: image.DAPId,
              DAPValue: image.DAPValue,
              projectId,
              groupId: groupId!
            }
          })

          data = response.data
        } catch (error) {
          console.error('Error while reuploading the image:', error)
          isErrorResult = true
          failedCounter++
        }

        if (data) {
          console.log('Server response:', data.reuploadOldImage)
        }

        if (data?.reuploadOldImage?.isFound) {
          await imagesApi.migrateImageFrame(
            data.reuploadOldImage.xml,
            image.frame.javaScriptDescriptor
          )
          console.log(
            '%cNew image frame created successfully!',
            'color: green;'
          )
          successfulCounter++
        } else if (
          !data?.reuploadOldImage?.isFound &&
          data?.reuploadOldImage?.source === 'internal'
        ) {
          await publisherConnector?.executeFunction(
            image.frame.javaScriptDescriptor,
            'Delete'
          )
          console.log(
            '%cInternal image is not found. Frame deleted successfully',
            'color: orange;'
          )
          removedCounter++
        }
      } else {
        console.log(
          `Image frame №${i} data isn't valid. Skipping...`,
          image
        )
        await publisherConnector?.setProperty(
          image.frame.javaScriptDescriptor,
          'variable',
          null
        )
        await publisherConnector?.executeFunction(
          image.frame.javaScriptDescriptor,
          'Delete'
        )
        skippedCounter++
      }

      setLoadingPercent(
        Math.round((i + 1) * ONE_FRAME_LOADING_PERCENT)
      )
      i++
    }

    console.log(
      '%cMigration is finished successfully!',
      'color: green;'
    )

    console.log('%cSuccessful:', 'color: green;', successfulCounter)
    console.log('%cFailed:', 'color: red;', failedCounter)
    console.log('%cSkipped:', 'color: yellow;', skippedCounter)
    console.log('%cRemoved:', 'color: orange;', removedCounter)

    await save()

    setIsError(isErrorResult)
    setIsSuccess(!isErrorResult)
    setIsLoading(false)
  }

  return {
    migrate,
    isOutdated,
    loadingPercent,
    isSuccess,
    isError,
    isLoading
  }
}
