import { Skeleton } from 'shared/ui/Skeleton'

import { Center, Container } from './Style'

const ProfilePageItemSkeleton: React.FC = () => (
  <Container>
    <Skeleton height={38} width={38} radius={19} />
    <Center>
      <Skeleton
        height={14}
        width={'40%'}
        colorLevel={3}
        marginBottom={6}
      />
      <Skeleton height={6} width={'80%'} marginBottom={6} />
      <Skeleton height={10} width={'60%'} />
    </Center>
  </Container>
)

export default ProfilePageItemSkeleton
