import { format } from 'date-fns'
import { useIntl } from 'react-intl'
import styled from 'styled-components'

import avatarPlaceholderSrc from 'shared/assets/images/avatar-placeholder.png'
import { LastUpdateFragment } from 'data/layoutcreator/queries/projects'
import { ReactChildren } from 'shared/types/global'
import { FragmentType, getFragmentData } from 'shared/types/layoutcreator'
import { Maybe } from 'shared/types/layoutcreator/graphql'

const Container = styled.div`
  padding: 24px 24px 16px;
  border: 1px solid ${({ theme }) => theme.color.base.c2};
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  background: ${({ theme }) => theme.color.base.c0};
`

const Title = styled.div<{ isDeleted: boolean }>`
  margin-bottom: ${({ theme }) => theme.spacing.tiny};
  font-size: 18px;
  font-weight: 600;
  color: ${({ theme, isDeleted }) =>
    isDeleted ? theme.color.error : theme.color.base.c9};
`

const LastUpdate = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.color.base.c5};
  font-size: 11px;
`

const Avatar = styled.img`
  width: 16px;
  height: 16px;
  margin-right: ${({ theme }) => theme.spacing.tiny};
  overflow: hidden;
  border-radius: 50%;
`

type MetaProps = {
  title?: string
  isDeleted?: boolean
  lastUpdate?: Maybe<FragmentType<typeof LastUpdateFragment>>
} & ReactChildren

const Meta = ({
  title,
  lastUpdate,
  isDeleted,
  children
}: MetaProps) => {
  const { formatMessage } = useIntl()
  const lastUpdateFragment = getFragmentData(
    LastUpdateFragment,
    lastUpdate
  )
  return (
    <Container>
      <Title isDeleted={!!isDeleted}>{title}</Title>
      {lastUpdateFragment && (
        <LastUpdate>
          <Avatar
            src={lastUpdateFragment.avatarUrl || avatarPlaceholderSrc}
            alt={lastUpdateFragment.name}
          />
          {formatMessage(
            {
              id: 'YearbookCard.last.updated'
            },
            {
              date: format(
                new Date(lastUpdateFragment.updatedAt),
                'dd.MM.yy — hh:mm'
              )
            }
          )}
        </LastUpdate>
      )}

      {children}
    </Container>
  )
}

export default Meta
