import { gql } from 'shared/types/layoutcreator'

export const LastUpdateFragment = gql(/* GraphQL */ `
  fragment LastUpdateFields on ProjectUpdateFlagEntity {
    avatarUrl
    name
    updatedAt
  }
`)

export const PagePreviewsFragment = gql(/* GraphQL */ `
  fragment PagePreviewsFields on ProjectDocument {
    id
    name
    path
    previewUrl
  }
`)

export const GET_PROJECTS = gql(/* GraphQL */ `
  query getProjects($groupId: String!) {
    projects(groupId: $groupId) {
      id
      name
      path
      pagePreviews {
        ...PagePreviewsFields
      }
      lastUpdate {
        ...LastUpdateFields
      }
    }
  }
`)

export const GET_PAGE_DATA = gql(/* GraphQL */ `
  query getPageData(
    $groupId: String!
    $projectId: String!
    $documentId: String!
  ) {
    getPageData(
      groupId: $groupId
      projectId: $projectId
      documentId: $documentId
    ) {
      index
      templatePath
      previewUrl
    }
  }
`)

export const TemplateDocumentsFragment = gql(/* GraphQL */ `
  fragment TemplateDocumentFields on TemplateDocumentEntity {
    id
    name
    type
  }
`)

export const GET_PROJECT_TEMPLATE_DATA = gql(/* GraphQL */ `
  query getProjectTemplateData(
    $groupId: String!
    $projectId: String!
  ) {
    getProjectTemplateData(groupId: $groupId, projectId: $projectId) {
      currentTemplatePath
      templateDocuments {
        ...TemplateDocumentFields
      }
    }
  }
`)

export const GET_PROJECT_TEMPLATES = gql(/* GraphQL */ `
  query getProjectTemplates($groupId: String!, $projectId: String!) {
    getProjectTemplates(groupId: $groupId, projectId: $projectId) {
      path
      name
      categoryId
      previewUrl
      isCurrent
    }
  }
`)

export const GET_PROJECT_TOKEN = gql(/* GraphQL */ `
  query getProjectToken($groupId: String!, $projectId: String!) {
    getProjectToken(groupId: $groupId, projectId: $projectId)
  }
`)

export const GET_PROJECT_META = gql(/* GraphQL */ `
  query getProjectMeta($groupId: String!, $projectId: String!) {
    getProjectMeta(groupId: $groupId, projectId: $projectId) {
      id
      name
      coverType
      order
    }
  }
`)
