import config from "config"
import { Locales } from "types/global"

export const setupLocale = () => {
  const urlParams = new URLSearchParams(window.location.search)
  const langParam = urlParams.get('lang')
  
  if (langParam) {
    localStorage.setItem('locale', langParam)
  }

  let locale = localStorage.getItem('locale')

  if (!locale) {
    locale = config.defaultLocale
    localStorage.setItem('locale', locale)
  }

  if (!config.supportedLocales.includes(locale as Locales)) {
    locale = config.defaultLocale
  }

  const MESSAGES_MAP = {
    [Locales.en]: require('../i18n/en-EN.json'),
    [Locales.de]: require('../i18n/de-DE.json')
  }

  return {
    locale: locale as Locales,
    messages: MESSAGES_MAP[locale as Locales] || MESSAGES_MAP[config.defaultLocale]
  }
}
