import { useMutation } from '@apollo/client'
import theme from 'gradoo-theme'
import { GradooTheme } from 'gradoo-theme/lib/types'
import styled from 'styled-components'

import { DELETE_EXPORT } from 'data/layoutcreator/mutations/export'
import ExportStatus from 'screens/AdminProject/tabs/ExportTab/components/ExportStatus'
import {
  ProjectExportStatuses,
  ProjectExportTypes
} from 'screens/AdminProject/types'

const EXPORT_TYPES_MAP: Record<
  ProjectExportTypes,
  {
    name: string
    backgroundColor: (theme: GradooTheme) => string
    color: (theme: GradooTheme) => string
  }
> = {
  [ProjectExportTypes.preview]: {
    name: 'Preview',
    backgroundColor: (theme: GradooTheme) => theme.color.base.c7,
    color: (theme: GradooTheme) => theme.color.base.c2
  },
  [ProjectExportTypes.print]: {
    name: 'Final Production',
    backgroundColor: (theme: GradooTheme) => theme.color.brand_01,
    color: (theme: GradooTheme) => theme.color.base.c10
  }
}

const Table = styled.table``

const Th = styled.th`
  color: ${({ theme }) => theme.color.base.c7};
  font-weight: 600;
  text-transform: uppercase;
  font-size: 12px;
  text-align: left;
  padding-bottom: 12px;
`

const Tr = styled.tr`
  cursor: pointer;
`

const Td = styled.td`
  padding: 20px 0;
  font-size: 14px;
  border-bottom: ${({ theme }) => `1px solid ${theme.color.base.c3}`};
`

const ExportType = styled.div<{ value: ProjectExportTypes }>`
  width: fit-content;
  padding: 0 10px;
  border-radius: 9999px;
  background: ${({ value, theme }) =>
    EXPORT_TYPES_MAP[value].backgroundColor(theme)};
  color: ${({ value, theme }) =>
    EXPORT_TYPES_MAP[value].color(theme)};
  line-height: 22px;
  font-size: 13px;
`

const DownloadLink = styled.a`
  padding: 1px 6px;
  color: ${({ theme }) => theme.color.brand_02};
  font-size: 14px;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`

const Delete = styled.button`
  background: none;
  border: none;
  color: ${({ theme }) => theme.color.error};
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`

function formatDate(ISODate: Date): string {
  if (!ISODate) {
    return ''
  }

  const date = new Date(ISODate)

  const day = String(date.getDate()).padStart(2, '0')
  const month = String(date.getMonth() + 1).padStart(2, '0') // Months are zero-based
  const year = date.getFullYear()
  const hours = String(date.getHours()).padStart(2, '0')
  const minutes = String(date.getMinutes()).padStart(2, '0')

  return `${day}.${month}.${year} - ${hours}:${minutes}`
}

type ExportTableProps = {
  items: {
    id: string
    type: ProjectExportTypes
    pagesCount: number
    date: Date
    authorFullName: string
    status: ProjectExportStatuses
    downloadUrl: string | null
    error: string | null
  }[]
  onExportClick: (exportId: string) => void
}

const ExportTable: React.FC<ExportTableProps> = ({
  items,
  onExportClick
}) => {
  const [deleteExport] = useMutation(DELETE_EXPORT)

  const handleDelete = (id: string) => {
    deleteExport({
      variables: { id },
      refetchQueries: ['getProjectExports']
    })
  }

  return (
    <Table>
      <thead>
        <tr>
          <Th>Type</Th>
          <Th>Pages count</Th>
          <Th>Export Date</Th>
          <Th>Export By</Th>
          <Th>Status</Th>
          <Th></Th>
        </tr>
      </thead>
      <tbody>
        {items.map(item => (
          <Tr key={item.id} onClick={() => onExportClick(item.id)}>
            <Td>
              <ExportType value={item.type}>
                {EXPORT_TYPES_MAP[item.type].name}
              </ExportType>
            </Td>
            <Td>{item.pagesCount}</Td>
            <Td>{formatDate(item.date)}</Td>
            <Td>{item.authorFullName}</Td>
            <Td>
              <ExportStatus value={item.status} />
            </Td>
            <Td>{item.error}</Td>

            <Td>
              {item.downloadUrl && (
                <>
                  <DownloadLink
                    onClick={e => e.stopPropagation()}
                    href={item.downloadUrl}
                  >
                    Download
                  </DownloadLink>
                  {' | '}
                </>
              )}
              <Delete
                onClick={e => {
                  e.stopPropagation()
                  handleDelete(item.id)
                }}
              >
                Delete
              </Delete>
            </Td>
          </Tr>
        ))}
      </tbody>
    </Table>
  )
}

export default ExportTable
