import { GradooTheme } from 'gradoo-theme/lib/types'
import styled from 'styled-components'

import { ProjectExportStatuses } from 'screens/AdminProject/types'

const EXPORT_STATUSES_MAP: Record<
  ProjectExportStatuses,
  {
    name: string
    backgroundColor: (theme: GradooTheme) => string
    color: (theme: GradooTheme) => string
  }
> = {
  [ProjectExportStatuses.exporting]: {
    name: 'Exporting',
    backgroundColor: () => '#FFEFE2',
    color: (theme: GradooTheme) => theme.color.ways.CW4.secondary
  },
  [ProjectExportStatuses.merging]: {
    name: 'Merging',
    backgroundColor: (theme: GradooTheme) => '#e3e6fa',
    color: (theme: GradooTheme) => theme.color.brand_02
  },
  [ProjectExportStatuses.completed]: {
    name: 'Completed',
    backgroundColor: () => '#E8F9EF',
    color: (theme: GradooTheme) => theme.color.ways.CW1.secondary
  },
  [ProjectExportStatuses.error]: {
    name: 'Error',
    backgroundColor: () => '#FFF0EE',
    color: (theme: GradooTheme) => theme.color.error
  },
  [ProjectExportStatuses.warning]: {
    name: 'Warning',
    backgroundColor: () => '#fef6d3',
    color: () => '#c5a13d'
  },
  [ProjectExportStatuses.waiting]: {
    name: 'Waiting',
    backgroundColor: () => '#f7f7f7',
    color: () => '#7a7a7a'
  }
}

const Container = styled.div<{ value: ProjectExportStatuses }>`
  width: fit-content;
  padding: 0 10px;
  border-radius: 9999px;
  background: ${({ value, theme }) =>
    EXPORT_STATUSES_MAP[value]?.backgroundColor(theme)};
  color: ${({ value, theme }) =>
    EXPORT_STATUSES_MAP[value]?.color(theme)};
  line-height: 22px;
  font-size: 13px;
`

const ExportStatus = ({
  value
}: {
  value: ProjectExportStatuses
}) => {
  return (
    <Container value={value}>
      {EXPORT_STATUSES_MAP[value]?.name}
    </Container>
  )
}

export default ExportStatus
