import styled from 'styled-components'

import Flex from 'components/Flex'

const Container = styled.div`
  padding: 12px 16px;
  display: flex;
  gap: 8px;
  background-color: ${({ theme }) => theme.color.base.c2};
  border-radius: 16px;
  font-size: 14px;
`

const RemoveButton = styled.button`
  background: none;
  border: 0;
  padding: 4px;
  font-size: 22px;
  font-weight: 600;
  cursor: pointer;
`

type MigrationsJobItemProps = {
  groupId: string
  projectId: string
  migrationId: string
  onRemove: () => void
}

const MigrationsJobItem: React.FC<MigrationsJobItemProps> = ({
  groupId,
  projectId,
  migrationId,
  onRemove
}) => {
  return (
    <Container>
      <Flex direction="column" rowGap={8} flex={1}>
        <span>
          <b>Group ID:</b> {groupId}
        </span>
        <span>
          <b>Project ID:</b> {projectId}
        </span>
        <span>
          <b>Migration ID:</b> {migrationId}
        </span>
      </Flex>

      <RemoveButton onClick={onRemove}>x</RemoveButton>
    </Container>
  )
}

export default MigrationsJobItem
