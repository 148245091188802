const VerifyAddressIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <g clipPath="url(#clip0_6489_11345)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 2.00002C5.42267 2.00002 3.33333 4.08936 3.33333 6.66669C3.33333 7.92809 3.90211 9.11872 4.86152 10.3645C5.67161 11.4164 6.70849 12.4449 7.82152 13.5489C7.8808 13.6077 7.9403 13.6667 8 13.726C8.0597 13.6667 8.1192 13.6077 8.17848 13.5489C9.29151 12.4449 10.3284 11.4164 11.1385 10.3645C12.0979 9.11872 12.6667 7.92809 12.6667 6.66669C12.6667 4.08936 10.5773 2.00002 8 2.00002ZM2 6.66669C2 3.35298 4.68629 0.666687 8 0.666687C11.3137 0.666687 14 3.35298 14 6.66669C14 8.3508 13.2354 9.82684 12.1949 11.178C11.3253 12.3071 10.2124 13.4104 9.10055 14.5126C8.89024 14.7211 8.67998 14.9295 8.4714 15.1381C8.21106 15.3984 7.78895 15.3984 7.5286 15.1381C7.32002 14.9295 7.10976 14.7211 6.89945 14.5126C5.78765 13.4104 4.6747 12.3071 3.80515 11.178C2.76455 9.82684 2 8.3508 2 6.66669ZM8 5.33335C7.26362 5.33335 6.66667 5.93031 6.66667 6.66669C6.66667 7.40307 7.26362 8.00002 8 8.00002C8.73638 8.00002 9.33333 7.40307 9.33333 6.66669C9.33333 5.93031 8.73638 5.33335 8 5.33335ZM5.33333 6.66669C5.33333 5.19393 6.52724 4.00002 8 4.00002C9.47276 4.00002 10.6667 5.19393 10.6667 6.66669C10.6667 8.13945 9.47276 9.33335 8 9.33335C6.52724 9.33335 5.33333 8.13945 5.33333 6.66669Z"
        fill="#0157FF"
      />
    </g>
    <defs>
      <clipPath id="clip0_6489_11345">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default VerifyAddressIcon
