import { GradooTokens } from '../types'
import { makeGradooRequest, makeLayoutCreatorRequest } from './gql'
import {
  gradooRefreshTokenMutation,
  layoutCreatorRefreshTokenMutation
} from './gql/mutations'
import { isJWTExpired } from './jwt'

export const refreshLayoutCreatorToken = async () => {
  const lcRefreshToken = localStorage.getItem('lcRefreshToken')

  const response = await makeLayoutCreatorRequest({
    body: layoutCreatorRefreshTokenMutation(),
    token: lcRefreshToken!
  })

  const newToken = response.data?.refreshToken

  if (!newToken) {
    throw new Error('Could not refresh token')
  }

  localStorage.setItem('lcToken', newToken)

  return newToken
}

export const refreshGradooToken =
  async (): Promise<GradooTokens | null> => {
    const gradooRefreshToken = localStorage.getItem('refreshToken')

    if (!gradooRefreshToken) {
      return null
    }

    const response = await makeGradooRequest({
      body: gradooRefreshTokenMutation({
        refreshToken: gradooRefreshToken
      })
    })

    const newToken = response?.data?.refreshToken?.token
    const newRefreshToken = response?.data?.refreshToken?.refreshToken

    if (!newToken) {
      return null
    }

    localStorage.setItem('token', newToken)
    localStorage.setItem('refreshToken', newRefreshToken)

    return {
      token: newToken,
      refreshToken: newRefreshToken
    }
  }

export const getValidGradooTokens =
  async (): Promise<GradooTokens | null> => {
    const gradooToken = localStorage.getItem('token')
    const gradooRefreshToken = localStorage.getItem('refreshToken')

    if (!gradooToken || !gradooRefreshToken) {
      return null
    }

    const isTokenExpired = isJWTExpired(gradooToken)

    if (!isTokenExpired) {
      return {
        token: gradooToken,
        refreshToken: gradooRefreshToken
      }
    }

    return refreshGradooToken()
  }
