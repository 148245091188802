import styled from 'styled-components'

import { PANEL_Z_INDEX } from 'styles/z-index'

export enum PanelTypes {
  left = 'left',
  right = 'right'
}

const PanelContainer = styled.div<{ type?: PanelTypes }>`
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 110px;
  z-index: ${PANEL_Z_INDEX};
  ${({ type }) =>
    type === PanelTypes.right ? 'right' : 'left'}: 32px;
  width: 284px;
  height: calc(100% - 200px);
  padding: 24px 16px;
  border: 1px solid ${({ theme }) => theme.color.base.c2};
  border-radius: 5px;
  background: ${({ theme }) => theme.color.base.c0};
  overflow: hidden;
  left: ${({ type }) => type === PanelTypes.left && '24px'};

  @media (max-width: 1440px) {
    top: 120px;
  }

  @media (max-width: 1194px) {
    width: ${({ type }) =>
      type === PanelTypes.right ? '232px' : '284px'};
  }
`

export default PanelContainer
