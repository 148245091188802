import { useQuery } from '@apollo/client'
import { useMemo } from 'react'
import { useIntl } from 'react-intl'

import config from 'config'
import useAuth from 'data/gradoo/hooks/useAuth'
import { GET_PROJECTS } from 'data/layoutcreator/queries/projects'

type Check = () => string | null

export const useProjectNameValidation = ({
  projectId,
  projectName
}: {
  projectId?: string
  projectName: string
}) => {
  const { authGroupId } = useAuth()
  const { formatMessage } = useIntl()

  const { data: projectsData } = useQuery(GET_PROJECTS, {
    variables: {
      groupId: authGroupId!
    }
  })

  const checkIsUnique: Check = () => {
    const message = formatMessage({
      id: 'Project.settings.name.errors.alreadyExists'
    })

    if (!projectsData?.projects) {
      return message
    }

    const isUnique = projectsData?.projects
      .filter(({ id }) => id !== projectId)
      .every(project => project.name !== projectName)

    return isUnique ? null : message
  }

  const checkInvalidSymbols: Check = () => {
    if (!config.projectNameRegExp.test(projectName)) {
      return formatMessage({
        id: 'Project.settings.name.errors.invalidCharacters'
      })
    }

    return null
  }

  const checkIsEmpty: Check = () => {
    if (!projectName) {
      return formatMessage({
        id: 'Project.settings.name.errors.empty'
      })
    }

    return null
  }

  const isProjectNameValid = useMemo(() => {
    return (
      !checkIsEmpty() && !checkIsUnique() && !checkInvalidSymbols()
    )
  }, [projectName, projectsData, projectId])

  const projectNameError = useMemo(() => {
    return checkIsEmpty() || checkIsUnique() || checkInvalidSymbols()
  }, [projectName, projectsData, projectId])

  return {
    isProjectNameValid,
    projectNameError
  }
}
