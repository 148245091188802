import styled, { css } from 'styled-components'

const BOTTOM_BORDER = css`
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 3px;
  border-radius: 20px;
`

const Container = styled.div`
  display: flex;
  align-items: center;
  position: relative;

  &:after {
    ${BOTTOM_BORDER}
    z-index: 0;
    background: ${({ theme }) => theme.color.base.c2};
  }
`

const Tab = styled.div<{ isActive: boolean }>`
  padding: 18px 28px;
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
  position: relative;
  cursor: pointer;
  color: ${({ theme }) => theme.color.base.c7};

  &:after {
    ${BOTTOM_BORDER}
    background: ${({ theme }) => theme.color.base.c2};
    z-index: 1;
  }

  &:hover {
    color: ${({ theme }) => theme.color.base.c9};
    background: ${({ theme }) => theme.color.base.c2};

    &:after {
      ${({ theme, isActive }) =>
        !isActive && `background: ${theme.color.base.c4}`};
    }
  }

  ${({ isActive, theme }) =>
    isActive &&
    css`
      color: ${({ theme }) => theme.color.base.c9};

      &:after {
        background: ${theme.color.base.c9};
      }
    `}
`

type TabBarProps = {
  tabs: string[]
  activeIndex: number
  onTabClick: (index: number) => void
}

const TabBar: React.FC<TabBarProps> = ({
  activeIndex,
  tabs,
  onTabClick
}) => {
  return (
    <Container>
      {tabs.map((tab, index) => (
        <Tab
          key={index}
          isActive={activeIndex === index}
          onClick={() => onTabClick(index)}
        >
          {tab}
        </Tab>
      ))}
    </Container>
  )
}

export default TabBar
