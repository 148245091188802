import styled from 'styled-components'

const DEFAULT_SIZE = 38

type ContainerProps = {
  src: string
  size?: number
  background?: string
}

const Container = styled.div<ContainerProps>`
  position: relative;
  width: ${({ size }) => size || DEFAULT_SIZE}px;
  height: ${({ size }) => size || DEFAULT_SIZE}px;
  border-radius: 50%;
  background-color: ${({ background, theme }) =>
    background || theme.color.base.c2};
  background-image: url(${({ src }) => src});
  background-size: cover;
  background-position: center bottom;
`

type AvatarProps = {
  src: string
  size?: number
  background?: string
}

const Avatar: React.FC<AvatarProps> = ({ src, size, background }) => {
  return <Container src={src} size={size} background={background} />
}

export default Avatar
