import jwt_decode, { JwtPayload } from 'jwt-decode'

import { isTokenDateExpired } from 'shared/helpers/date'

export const isJWTExpired = (token: string) => {
  const decoded = jwt_decode<JwtPayload>(token)
  if (!decoded.exp) {
    throw new Error('Invalid jwt token')
  }

  return isTokenDateExpired(decoded.exp)
}
