import { Dispatch, SetStateAction, useState } from 'react'

import { StepsRecord } from 'components/StepsBar/types'
import IslandModal from 'components/modals/IslandModal'
import { Maybe, UploadTypes } from 'types/layoutcreator/graphql'

import SetUpPrintData from './components/SetUpPrintData'
import SetUpPrintDataIcon from './components/SetUpPrintDataIcon'
import VerifyAddress from './components/VerifyAddress'
import VerifyAddressIcon from './components/VerifyAddressIcon'

export enum ProductionModalStepIds {
  'verifyAddress' = 'verifyAddress',
  'setupPrintData' = 'setupPrintData'
}

export const Steps: StepsRecord<ProductionModalStepIds> = {
  [ProductionModalStepIds.verifyAddress]: {
    id: ProductionModalStepIds.verifyAddress,
    IconComponent: VerifyAddressIcon,
    nextStepId: ProductionModalStepIds.setupPrintData
  },
  [ProductionModalStepIds.setupPrintData]: {
    id: ProductionModalStepIds.setupPrintData,
    IconComponent: SetUpPrintDataIcon,
    prevStepId: ProductionModalStepIds.verifyAddress
  }
}

type CommonModalProps = {
  closeModal: () => void
  orderNumber?: Maybe<string>
}

type ProductionModalProps = CommonModalProps & {
  isModalOpen: boolean
  uploadType: UploadTypes
  modalWidth: number
  onSubmitSuccess: () => void
}

export type ProductionModalStepProps = CommonModalProps & {
  setStep: Dispatch<SetStateAction<ProductionModalStepIds>>
}

const ProductionModal = ({
  isModalOpen,
  closeModal,
  orderNumber,
  uploadType,
  modalWidth,
  onSubmitSuccess
}: ProductionModalProps) => {
  const [step, setStep] = useState<ProductionModalStepIds>(
    ProductionModalStepIds.verifyAddress
  )

  return (
    <IslandModal
      closeModal={closeModal}
      isOpen={isModalOpen}
      width={modalWidth}
    >
      {step === ProductionModalStepIds.verifyAddress ? (
        <VerifyAddress
          setStep={setStep}
          closeModal={closeModal}
          orderNumber={orderNumber}
        />
      ) : (
        <SetUpPrintData
          setStep={setStep}
          closeModal={closeModal}
          orderNumber={orderNumber}
          uploadType={uploadType}
          onSubmitSuccess={onSubmitSuccess}
        />
      )}
    </IslandModal>
  )
}

export default ProductionModal
