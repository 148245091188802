import styled from 'styled-components'

import { ReactChildren } from 'types/global'

import Header, { HeaderProps } from './components/Header'

const Grid = styled.div<{
  background?: string
}>`
  display: grid;
  grid-template-rows: auto 1fr auto;
  min-height: 100%;
  margin-bottom: 300px;
  background: ${({ theme, background }) =>
    background || theme.color.base.c1};
`

const Main = styled.div<{ marginBottom?: number }>`
  display: flex;
  flex-direction: column;
  margin-bottom: ${({ marginBottom }) =>
    marginBottom && `${marginBottom}px`};
`

type DefaultLayoutProps = ReactChildren &
  HeaderProps & {
    background?: string
    marginBottom?: number
  }

const DefaultLayout = ({
  children,
  liveUsers,
  background,
  RightComponent,
  onBack,
  marginBottom
}: DefaultLayoutProps) => (
  <Grid background={background}>
    <Header
      onBack={onBack}
      liveUsers={liveUsers}
      RightComponent={RightComponent}
    />
    <Main marginBottom={marginBottom}>{children}</Main>
  </Grid>
)

export default DefaultLayout
