import { gql } from 'shared/types/layoutcreator'

export const GET_MIGRATIONS_QUEUE = gql(/* GraphQL */ `
  query getMigrationsQueue {
    getMigrationsQueue {
      groupId
      projectId
      migrationId
    }
  }
`)

export const GET_MIGRATION_DATA = gql(/* GraphQL */ `
  query getMigrationData(
    $migrationId: String!
  ) {
    getMigrationData(
      migrationId: $migrationId
    )
  }
`)
