import styled, { css } from 'styled-components'

import Flex from 'shared/ui/Flex'
import { StylesMap } from 'shared/styles/types'

enum States {
  error = 'error',
  success = 'success',
  default = 'default',
  disabled = 'disabled'
}

const STATES_CONTAINER_STYLES_MAP: StylesMap<States> = {
  [States.default]: css<ContainerProps>`
    &:hover {
      border-color: ${({ theme }) => theme.color.base.c5};
    }
    &:focus-within {
      border: 1px solid ${({ theme }) => theme.color.brand_02};
    }
  `,
  [States.disabled]: css`
    border: 1px solid ${({ theme }) => theme.color.base.c3};
  `,
  [States.error]: css<ContainerProps>`
    border: 1px solid ${({ theme }) => theme.color.error};
  `,
  [States.success]: css`
    border: 1px solid ${({ theme }) => theme.color.success};
  `
}

type ContainerProps = {
  state: States
  gap: number
  marginBottom: number
  fullWidth: boolean
  withBackground: boolean
}

const Container = styled(Flex)<ContainerProps>`
  position: relative;
  align-items: center;
  margin-bottom: ${({ marginBottom }) => marginBottom}px;
  border-radius: 55px;
  border: 1px solid ${({ theme }) => theme.color.base.c3};
  padding-left: 24px;
  padding-right: 24px;
  font-size: 18px;

  ${({ fullWidth }) => fullWidth && `width: 100%;`}
  ${({ width }) => width && `width: ${width}px;`}
  ${({ state }) => STATES_CONTAINER_STYLES_MAP[state]}
  ${({ withBackground, theme }) =>
    withBackground && `background-color: ${theme.color.base.c0};`}
`

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-shrink: 1;
  margin-right: 8px;
`

const InputWrapper = styled.div<{ hasContent: boolean }>`
  width: 100%;
  position: relative;
  padding: ${({ hasContent }) =>
    hasContent ? '28px 0px 12px' : '20px 0px 20px'};

  &:focus-within {
    padding: 28px 0px 12px;
  }
`

type StyledInputProps = {
  fontSize?: number
  textOverflow: 'clip' | 'ellipsis'
}
const StyledInput = styled.input<StyledInputProps>`
  border: none;
  width: 100%;
  background: none;
  padding: 0;
  text-overflow: ${({ textOverflow }) => textOverflow};
  ${({ fontSize }) => fontSize && `font-size: ${fontSize}px;`}
`

type LabelProps = {
  state: States
  hasContent: boolean
}

const STATES_LABEL_STYLES_MAP: StylesMap<States> = {
  [States.default]: css<ContainerProps>`
    color: ${({ theme }) => theme.color.base.c8};
    ${Container}:focus-within & {
      color: ${({ theme }) => theme.color.brand_02};
    }
  `,
  [States.disabled]: css``,
  [States.error]: css<ContainerProps>`
    color: ${({ theme }) => theme.color.error} !important;
  `,
  [States.success]: css`
    color: ${({ theme }) => theme.color.success} !important;
  `
}

const Label = styled.label<LabelProps>`
  position: absolute;
  transition: all 0.3s;
  top: ${({ hasContent }) => (hasContent ? '10px' : '18px')};
  font-size: ${({ hasContent }) => (hasContent ? '14px' : '18px')};
  color: ${({ theme }) => theme.color.base.c5};

  ${Container}:focus-within & {
    top: 10px;
    font-size: 14px;
  }

  ${({ state }) => STATES_LABEL_STYLES_MAP[state]}
`

type InfoLabelProps = {
  state: States
}

const STATES_INFOLABEL_STYLES_MAP: StylesMap<States> = {
  [States.default]: css<ContainerProps>``,
  [States.disabled]: css``,
  [States.error]: css<ContainerProps>`
    color: ${({ theme }) => theme.color.error};
  `,
  [States.success]: css`
    color: ${({ theme }) => theme.color.success};
  `
}

const InfoLabel = styled.span<InfoLabelProps>`
  font-size: 12px;
  font-weight: 400;
  margin-left: 24px;
  color: ${({ theme }) => theme.color.base.c8};
  ${({ state }) => STATES_INFOLABEL_STYLES_MAP[state]}
`

export type InputProps = {
  value: string
  label?: string
  readOnly?: boolean
  onChange?: (newValue: string) => void
  renderIcon?: () => JSX.Element
  placeholder?: string
  fontSize?: number
  gap?: number
  marginBottom?: number
  inputTextOverflow?: StyledInputProps['textOverflow']
  fullWidth?: boolean
  onBlur?: () => void
  append?: JSX.Element
  isInvalid?: boolean
  isSuccess?: boolean
  id?: string
  type?: string
  infoLabel?: string
  width?: number
  padding?: [number, number]
  isDisabled?: boolean
  withBackground?: boolean
}

const Input = ({
  value,
  placeholder,
  onChange = () => null,
  fontSize = 14,
  gap = 14,
  renderIcon,
  marginBottom = 0,
  readOnly = false,
  label,
  inputTextOverflow = 'clip',
  fullWidth = false,
  onBlur,
  append,
  isInvalid = false,
  isSuccess = false,
  type = 'text',
  isDisabled,
  id,
  infoLabel,
  withBackground = false,
  ...rest
}: InputProps) => {
  const getInnerState = () => {
    if (isDisabled) return States.disabled
    if (isInvalid) return States.error
    if (isSuccess) return States.success
    return States.default
  }

  const innerState = getInnerState()

  return (
    <>
      <Container
        gap={gap}
        marginBottom={marginBottom}
        fullWidth={fullWidth}
        state={innerState}
        withBackground={withBackground}
        {...rest}
      >
        {renderIcon && <IconWrapper>{renderIcon()}</IconWrapper>}

        <InputWrapper hasContent={Boolean(value)}>
          <Label
            state={innerState}
            hasContent={Boolean(value)}
            htmlFor={id || label || 'input'}
          >
            {label}
          </Label>

          <StyledInput
            id={id || label || 'input'}
            type={type}
            value={value}
            onChange={e => onChange(e.target.value)}
            fontSize={fontSize}
            readOnly={readOnly}
            textOverflow={inputTextOverflow}
            onBlur={onBlur}
            disabled={isDisabled}
          />
        </InputWrapper>

        {append && append}
      </Container>
      {infoLabel && (
        <InfoLabel state={innerState}>{infoLabel}</InfoLabel>
      )}
    </>
  )
}

export default Input
