import styled from 'styled-components'

import DefaultLayout from 'layout/Default'
import { HeaderProps } from 'layout/Default/components/Header'
import { theme } from 'styles/theme'
import { ResponsiveProps, responsiveStyles } from 'styles/utils'
import { ReactChildren } from 'types/global'

const Island = styled.div<{ responsive?: ResponsiveProps }>`
  margin: 88px auto 0 auto;
  max-width: 1136px;
  height: 100%;
  border-radius: 15px 15px 0 0;
  padding: 68px 64px;
  box-shadow: 9px 7px 38px rgba(29, 53, 71, 0.08);
  ${responsiveStyles}
`

type IslandLayoutProps = ReactChildren &
  HeaderProps & {
    showTools?: boolean
    Header?: JSX.Element
    onBack?: () => void
    marginBottom?: number
    noBackground?: boolean
  }

const IslandLayout: React.FC<IslandLayoutProps> = ({
  children,
  Header,
  liveUsers,
  RightComponent,
  onBack,
  marginBottom,
  noBackground
}) => {
  return (
    <DefaultLayout
      liveUsers={liveUsers}
      background={noBackground ? undefined : theme.color.base.c0}
      RightComponent={RightComponent}
      onBack={onBack}
      marginBottom={marginBottom}
    >
      {Header && Header}
      <Island
        responsive={{
          s: {
            padding: '48px 24px',
            margin: '88px 16px 0 16px'
          },
          xl: {
            padding: '68px 64px',
            margin: '88px auto 0 auto'
          }
        }}
      >
        {children}
      </Island>
    </DefaultLayout>
  )
}

export default IslandLayout
