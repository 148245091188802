import copySrc from 'shared/assets/images/copy.svg'
import deleteSrc from 'shared/assets/images/delete.svg'
import editSrc from 'shared/assets/images/edit.svg'
import viewSrc from 'shared/assets/images/view.svg'
import { ToolType, ToolsEnum } from './types'

export const TOOLS: ToolType[] = [
  { id: ToolsEnum.edit, icon: editSrc },
  { id: ToolsEnum.duplicate, icon: copySrc },
  { id: ToolsEnum.view, icon: viewSrc },
  { id: ToolsEnum.delete, icon: deleteSrc }
]
