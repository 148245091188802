import { LiveUser } from 'types/global'

export enum ToolsEnum {
  edit = 'edit',
  duplicate = 'duplicate',
  view = 'view',
  delete = 'delete'
}

export type ToolType = {
  id: ToolsEnum
  icon: string
}

export type PageToolsProps = {
  isViewLoading: boolean
  view: string
  pageId: string
  pageName: string
  previewUrl: string
  pageNumber: number
  editingLiveUser?: LiveUser | null
  type?: string | null
}
