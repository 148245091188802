import styled from 'styled-components'

export const Table = styled.table`
  width: 100%;
  padding-top: 24px;
`

export const Th = styled.th`
  padding: 0 0 40px 0;
  text-transform: uppercase;
  text-align: left;
  font-size: 12px;
  font-weight: 600;
  color: ${({ theme }) => theme.color.base.c7};
`

export const Tr = styled.tr`
  font-size: 14px;

  & td {
    padding: 20px 0;
    border-bottom: 1px solid ${({ theme }) => theme.color.base.c3};
  }

  & td:last-child {
    text-align: right;
    padding-right: 8px;
  }
`
