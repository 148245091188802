import { useEffect, useState } from 'react'
import styled from 'styled-components'

import ColorItem from 'components/ColorItem'
import ColorSelector from 'components/ColorSelector'
import Flex from 'components/Flex'
import CustomScrollbars from 'components/SimpleBar'
import CMYK from 'entities/CMYK'
import ThirdLevelTitle from 'screens/YearbookEditor/components/Tools/components/LeftPanel/components/ThirdLevelTitle'
import SecondLevelTitle from 'screens/YearbookEditor/components/Tools/components/PanelSecondLevelTitle'
import ChiliVariables, {
  ThemeColor
} from 'screens/YearbookEditor/components/Tools/services/ChiliVariables'
import { CMYKValues, ColorCMYK } from 'types/global'

const variablesApi = ChiliVariables.getInstance()

const CurrentColor = styled.div`
  width: 90%;
  height: 57px;
  border-radius: 15px;
  margin-bottom: 16px;
`

type ChangeThemeColorPanelProps = {
  colorName: string
  variableName: string
  value: ColorCMYK
  onBackButtonClick: () => void
}

const ChangeThemeColorPanel: React.FC<ChangeThemeColorPanelProps> = ({
  colorName,
  variableName,
  value,
  onBackButtonClick
}) => {
  const [color, setColor] = useState<ColorCMYK>(value)
  const [themeColors, setThemeColors] = useState<ThemeColor[]>([])

  const fetchThemeColors = async () => {
    const colors = await variablesApi.getThemeColors()
    setThemeColors(colors)
  }

  const setNewColorValue = async (newColor: ColorCMYK) => {
    setColor(newColor)

    setTimeout(async () => {
      await variablesApi.setVariableValue(
        variableName,
        variablesApi.convertColorToVariableValue(newColor)
      )
      fetchThemeColors()
    }, 100)
  }

  const handleColorChange = (field: CMYKValues, value: number) => {
    const newColor = { ...color, [field]: value }

    setNewColorValue(newColor)
  }

  useEffect(() => {
    fetchThemeColors()
  }, [])

  return (
    <CustomScrollbars
      fixedHeaderMarginBottom={210}
      fixedHeader={
        <>
          <SecondLevelTitle
            titleId="Panel.title.changeThemeColor"
            props={{ name: colorName.toLowerCase() }}
            onClick={onBackButtonClick}
          />
          <CurrentColor
            style={{ background: new CMYK({ ...color }).toCSS() }}
          />
        </>
      }
    >
      <div>
        <ThirdLevelTitle titleId="Change.color.selector" />

        <ColorSelector value={color} onChange={handleColorChange} />

        <Flex direction="column" marginTop={50}>
          <ThirdLevelTitle titleId="Change.color.theme" />

          <Flex direction="column" rowGap={16}>
            {themeColors.map(({ value, displayName }) => (
              <ColorItem
                fontSize={14}
                padding={[8, 16]}
                name={displayName}
                editable={false}
                color={new CMYK({ ...value }).toCSS()}
                onClick={() => setNewColorValue(value)}
              />
            ))}
          </Flex>
        </Flex>
      </div>
    </CustomScrollbars>
  )
}

export default ChangeThemeColorPanel
