import { PublisherInterface } from '@chili-publish/publisher-interface'
import config from 'config'
import { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'

import useEditorLoading, {
  LoadingStages
} from 'screens/YearbookEditor/hooks/useEditorLoading'

const Iframe = styled.iframe`
  width: 100%;
  height: 100%;
  border: 0;
`

type EditorIframeProps = {
  onBuild: (publisherConnector: PublisherInterface) => void
  pageId: string
  workspaceId: string
  onLoadingUpdate?: (percent: number) => void
  onDocumentLoad?: () => void
  onWorkspaceRender?: () => void
}

const CHILI_ENV_URL = config.chiliEnvironmentUrl
const API_KEY = process.env.REACT_APP_CHILI_API_KEY
const VIEW_PREFERENCES_ID = config.chiliViewPreferencesId
const VIEWER_ONLY = true

const EditorIframe: React.FC<EditorIframeProps> = ({
  onBuild,
  pageId,
  workspaceId,
  onLoadingUpdate = () => {},
  onDocumentLoad = () => {},
  onWorkspaceRender = () => {}
}) => {
  const [publisherConnector, setPublisherConnector] =
    useState<PublisherInterface | null>(null)
  const iframeRef = useRef<HTMLIFrameElement>(null)
  const { markLoaded } = useEditorLoading()

  const updateLoading = (stage: LoadingStages) => {
    onLoadingUpdate(markLoaded(stage))
  }

  const handleIframeLoad = async () => {
    updateLoading(LoadingStages.iframeLoaded)

    if (!iframeRef.current) {
      return
    }

    const publisherConnectorBuild = await PublisherInterface.build(
      iframeRef.current
    )
    updateLoading(LoadingStages.built)
    setPublisherConnector(publisherConnectorBuild)
    onBuild(publisherConnectorBuild)

    await publisherConnectorBuild.addListener(
      'DocumentFullyLoaded',
      async () => {
        updateLoading(LoadingStages.documentLoaded)
        onDocumentLoad()
      }
    )
  }

  useEffect(() => {
    publisherConnector?.addListener('WorkSpaceRendered', () => {
      updateLoading(LoadingStages.workspaceRendered)
      setTimeout(() => {
        onWorkspaceRender()
      }, 200)
    })
  }, [publisherConnector])

  return (
    <Iframe
      ref={iframeRef}
      onLoad={handleIframeLoad}
      src={`${CHILI_ENV_URL}/editor_html.aspx?doc=${pageId}&ws=${workspaceId}&vp=${VIEW_PREFERENCES_ID}&viewerOnly=${String(
        VIEWER_ONLY
      )}&apiKey=${API_KEY}`}
    />
  )
}

export default EditorIframe
