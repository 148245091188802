export enum Ids {
  editor = 'editor',
  quote = 'quote',
  image = 'image',
  profile = 'profile',
  profileQuestion = 'profileQuestion',
  profileComment = 'profileComment'
}

export type ProfileQuestionWithAnswer = {
  id: string
  question: string
  answer: string | null
}
