import styled from 'styled-components'

import { theme } from 'styles/theme'

import Icon from '../IcomoonIcon'

enum Intents {
  primary = 'primary',
  secondary = 'secondary',
  dark = 'dark'
}

type ButtonCircleProps = {
  size: number
  intent: Intents
}

const INTENT_BG_MAP: Record<Intents, string> = {
  [Intents.primary]: theme.color.brand_02,
  [Intents.secondary]: theme.color.brand_01,
  [Intents.dark]: theme.color.base.c9
}

const INTENT_ACTIVE_BG_MAP: Record<Intents, string> = {
  [Intents.primary]: theme.color.brand_02,
  [Intents.secondary]: theme.color.base.c3,
  [Intents.dark]: theme.color.base.c8
}

const ButtonCircle = styled.button<ButtonCircleProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ size }) => size * 2}px;
  height: ${({ size }) => size * 2}px;
  border-radius: 50%;
  background-color: ${({ intent }) => INTENT_BG_MAP[intent]};
  border: 0;
  outline: 0;
  cursor: pointer;

  &:focus {
    box-shadow: 0 0 0 2.5px #ffffff,
      0 0 0 6px ${({ theme }) => theme.color.brand_02};
  }

  &:active {
    background-color: ${({ intent }) => INTENT_ACTIVE_BG_MAP[intent]};
  }
`

const INTENT_ICON_COLORS_MAP: Record<Intents, string> = {
  [Intents.primary]: theme.color.base.c0,
  [Intents.secondary]: theme.color.brand_02,
  [Intents.dark]: theme.color.base.c0
}

interface IconButtonProps {
  testID?: string
  onClick: () => void
  icon: string
  intent?: Intents
  size: number
}

const IconButton = ({
  testID,
  onClick,
  icon,
  size,
  intent = Intents.primary,
  ...rest
}: IconButtonProps): JSX.Element => (
  <ButtonCircle
    data-testid={testID}
    onClick={onClick}
    size={size}
    intent={intent}
    {...rest}
  >
    <Icon
      icon={icon}
      size={size}
      color={INTENT_ICON_COLORS_MAP[intent]}
    />
  </ButtonCircle>
)

export { Intents as IconButtonIntents }
export default IconButton
