import CMYK from 'entities/CMYK'
import { Color, ColorTypes, Pixels } from 'types/global'

import { Frame, ShapeTypes } from '../types'

export type Document = {
  width: number
  height: number
  pixelHeight: Pixels
  pixelWidth: Pixels
}

export type ChiliDocument = {
  width: string
  height: string
  pixelHeight: Pixels
  pixelWidth: Pixels
  zoom: string
  viewPreferences: string
}

export type DocumentViewPreferences = {
  pageMarginX: number
  pageMarginY: number
}

export type ChiliDocumentViewPreferences = {
  pageCanvasMarginX: string
  pageCanvasMarginY: string
}

export type DocumentColor = {
  id: string
  name: string
  color: Color
  type: ColorTypes
}

export type DocumentFrame = {
  width?: number
  height?: number
  isSizeConnected: boolean
}

export type DocumentImageFrame = {
  zoomLevel: number
  positionX: number // % from frame width
  positionY: number // % from frame height
  ratio: number
  imageWidth: number
  imageHeight: number
  previewURL: string
  isCropped: boolean
} & DocumentFrame

// Right side equals to a chili event name
export enum DocumentEvents {
  fullyRendered = 'DocumentFullyRendered',
  dmChanged = 'DocumentThreeDModelChanged',
  zoomChanged = 'ZoomChanged'
}

export type ColorsService<InitOptions> = {
  init(options: InitOptions): void
  getColor(selector: string): Promise<DocumentColor | null>
  getColorByPath(path: string): Promise<DocumentColor | null>
  generateColorPath(selector: string): string
  addNewColor(color: CMYK): Promise<DocumentColor | null>
  changeColor(
    selector: string,
    values: Partial<CMYK>
  ): Promise<DocumentColor | null>
}

export type FrameService<FrameType = DocumentFrame> = {
  addNewFrame(frame: Frame): FrameType
  getFrame(id: string): FrameType | null
  setFrameProperty(
    id: string,
    field: keyof FrameType,
    value: any
  ): FrameType
  getOrAddFrame(frame: Frame): FrameType
}

export type ShapesService<InitOptions> = {
  init(options: InitOptions): void
  changeShapeType(path: string): Promise<void | null>
  getShapeType(frame: Frame): ShapeTypes | null
} & FrameService

export type ImagesService<InitOptions> = {
  init(options: InitOptions): void
} & FrameService<DocumentImageFrame>

// see more info : https://chilipublishdocs.atlassian.net/wiki/x/mJIV
export type CursorTypes =
  | 'pointer'
  | 'hand'
  | 'zoom'
  | 'selection'
  | 'text'
  | 'chain'
  | 'unchain'

export type CursorProperty = {
  ctrlIcon: null | string
  document: string
  icon: null | string
  iconName: string
  label: string
  name: CursorTypes
  selected: string
  toolTip: string
  width: string
}
