import { theme } from 'gradoo-theme'
import styled, { css } from 'styled-components'

import { capitalizeFirstLetter } from 'helpers/string'
import { ExportStatuses, MigrationStatuses } from 'screens/AdminProject/types'

export const STATUS_COLORS_MAP: Record<string, string> = {
  [MigrationStatuses.notStarted]: theme.color.base.c2,
  [MigrationStatuses.inProgress]: theme.color.ways.CW6.primary,
  [MigrationStatuses.failed]: theme.color.error,
  [MigrationStatuses.skipped]: theme.color.base.c3,
  [MigrationStatuses.completed]: theme.color.success,
}

const Container = styled.div<StatusProps>`
  width: fit-content;
  padding: 6px 10px;
  background: ${({ value }) => STATUS_COLORS_MAP[value]};
  font-size: 12px;
  font-weight: 600;
  border-radius: 50px;

  ${({ size }) =>
    size === 'lg' &&
    css`
      padding: 8px 16px;
      font-size: 14px;
    `}
`

type StatusProps = {
  value: string;
  size?: 'm' | 'lg'
}

const Status: React.FC<StatusProps> = ({ value, size = 'm' }) => {
  return (
    <Container value={value} size={size}>
      {capitalizeFirstLetter(value)}
    </Container>
  )
}

export default Status
