import config from 'config'
import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'

import Flex from 'components/Flex'
import CustomScrollbars from 'components/SimpleBar'
import TabBar from 'components/TabBar'
import SecondLevelTitle from 'screens/YearbookEditor/components/Tools/components/PanelSecondLevelTitle'
import ChiliVariables from 'screens/YearbookEditor/components/Tools/services/ChiliVariables'

import LibraryTab from './tabs/LibraryTab'
import UploadsTab from './tabs/UploadsTab'

const variablesApi = ChiliVariables.getInstance()

type ChangeBackgroundPanelProps = {
  onBackButtonClick: () => void
}

const ChangeBackgroundPanel: React.FC<ChangeBackgroundPanelProps> = ({
  onBackButtonClick
}) => {
  const { formatMessage } = useIntl()

  const [currentTabIndex, setCurrentTabIndex] = useState(0)

  const TABS_MAP: Array<{
    name: string
    component: JSX.Element
  }> = [
    {
      name: formatMessage({
        id: `Project.settings.templates.backgrounds.modal.tabs.library`
      }),
      component: <LibraryTab />
    },
    {
      name: formatMessage({
        id: `Project.settings.templates.backgrounds.modal.tabs.uploads`
      }),
      component: <UploadsTab />
    }
  ]

  useEffect(() => {
    const fetchVar = async () => {
      await variablesApi.getVariable(
        config.themes.variablesKeys.backgroundImage
      )
    }

    fetchVar()
  }, [variablesApi.isInitialized])

  return (
    <CustomScrollbars
      fixedHeader={
        <SecondLevelTitle
          titleId="Panel.title.changeBackground"
          onClick={onBackButtonClick}
          margin={false}
        />
      }
    >
      <TabBar
        tabs={TABS_MAP.map(({ name }) => name)}
        activeIndex={currentTabIndex}
        onChange={setCurrentTabIndex}
        width={242}
      />
      <Flex>{TABS_MAP[currentTabIndex].component}</Flex>
    </CustomScrollbars>
  )
}

export default ChangeBackgroundPanel
