import { useLazyQuery } from '@apollo/client'
import { useEffect } from 'react'
import { Route, Routes, useNavigate } from 'react-router-dom'

import Head from 'components/Head'
import { CHECK_AUTH } from 'data/layoutcreator/queries/admin'
import AdminLayout from 'layout/Admin'

import TabsHeader from './components/TabsHeader'
import ProjectsTab from './tabs/Projects'
import AdminTab from './tabs/Admin'

const Admin: React.FC = () => {
  const navigate = useNavigate()
  
  const [checkAuth] = useLazyQuery(CHECK_AUTH)

  useEffect(() => {
    const check = async () => {
      const { data } = await checkAuth()
      if (!data?.checkAuth) {
        navigate('/')
      }
    }

    check()
  }, [])

  return (
    <AdminLayout renderHeaderContent={() => <TabsHeader />}>
      <Head titleId="Admin.title" />

      <Routes>
        <Route path="/projects" element={<ProjectsTab />} />
        <Route path="/" element={<AdminTab />} />
      </Routes>      
    </AdminLayout>
  )
}

export default Admin
