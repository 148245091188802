import Flex from 'components/Flex'
import { InlineSkeleton, Skeleton } from 'components/Skeleton'

const ChangeBackgroundModalLibraryTabSkeleton = () => (
  <Flex direction="column">
    <Flex justifyContent="flex-end">
      <Flex direction="column">
        <Flex
          justifyContent="space-between"
          alignItems="center"
          marginBottom={10}
        >
          <InlineSkeleton width={75} height={13} />
          <InlineSkeleton width={50} height={13} colorLevel={3} />
        </Flex>
        <InlineSkeleton height={1} width={252} />
      </Flex>
    </Flex>

    <Flex marginTop={25} wrap="wrap" columnGap={10} rowGap={10}>
      {new Array(20).fill(null).map((_, i) => (
        <Skeleton key={i} radius={8} width={84} height={109} />
      ))}
    </Flex>
  </Flex>
)

export default ChangeBackgroundModalLibraryTabSkeleton
