import { gql } from 'types/layoutcreator'

export const START_PROJECT_EXPORT = gql(/* GraphQL */ `
  mutation startExport(
    $groupId: String!
    $projectId: String!
    $pageIds: [String!]
    $outputProfile: String!
    $userId: String!
    $retryFailedPages: Float
  ) {
    startExport(
      groupId: $groupId
      projectId: $projectId
      pageIds: $pageIds
      outputProfile: $outputProfile
      userId: $userId
      retryFailedPages: $retryFailedPages
    ) {
      id
      groupId
      projectId
      status
      startedAt
      finishedAt
      outputProfile
      error
      pages {
        pageId
        status
        startedAt
        finishedAt
        pdfUrl
      }
      author {
        id
        fullName
      }
    }
  }
`)

export const DELETE_EXPORT = gql(/* GraphQL */ `
  mutation deleteExport($id: String!) {
    deleteExport(id: $id)
  }
`)
