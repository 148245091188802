import { Field, FormikContextType, useFormikContext } from 'formik'
import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components'

import ArrowDown from 'shared/assets/icons/chevron-down.svg'
import iconAngled from 'shared/assets/icons/rotate_angle.svg'
import Flex from 'shared/ui/Flex'

const SelectContainer = styled.div`
  position: relative;
  width: 25px;
  height: 30px;
  &:after {
    content: '';
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
    width: 10px;
    height: 20px;
    background: url(${ArrowDown}) no-repeat center;
    pointer-events: none;
  }
`

const AngleIcon = styled.img`
  width: 10px;
  height: 10px;
`

const StyledSelect = styled(Field)`
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  opacity: 0;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  cursor: pointer;

  &:focus {
    outline: none;
  }
`

const InputWrapper = styled.div<{ width?: number }>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  width: ${({ width }) => (width ? `${width}px` : 'auto')};
  min-width: 56px;
  border: 1px solid ${({ theme }) => theme.color.base.c3};
  border-radius: 55px;
  padding: 0 5px;
  height: 42px;
  box-sizing: border-box;
`

const NumericInput = styled(Field)`
  flex-grow: 1;
  border: none;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  width: 100%;
  max-width: calc(100% - 20px);

  &:focus {
    outline: none;
  }
`

const UnitDisplay = styled.span`
  font-size: 16px;
  font-weight: 400;
  color: ${({ theme }) => theme.color.base.c7};
  white-space: nowrap;
  text-align: start;
`

const Label = styled.label`
  margin-top: 6px;
  font-size: 10px;
  font-weight: 600;
  text-align: center;
  color: ${({ theme }) => theme.color.base.c5};
`

type FormGroupProps = {
  id: string
  onChange: (frameProp: string, value: string) => Promise<void>
  width?: number
  defaultUnit?: string
  options?: string[]
}

const FormGroup = ({
  id,
  onChange,
  width,
  defaultUnit = '',
  options
}: FormGroupProps): JSX.Element => {
  const [showOptions, setShowOptions] = useState(false)
  const toggleOptions = () => setShowOptions(!showOptions)
  const { formatMessage } = useIntl()
  const { values, setFieldValue }: FormikContextType<any> =
    useFormikContext()
  const [inputValue, setInputValue] = useState('')

  const initialValue = values[id] || ''
  let [initialNumericValue, unit] = initialValue.includes(' ')
    ? initialValue.split(' ')
    : [initialValue, defaultUnit]

  const displayValue =
    inputValue !== '' ? inputValue : initialNumericValue
  const handleChange = (event: React.SyntheticEvent) => {
    const target = event.target as HTMLInputElement
    let newValue = target.value

    if (id === 'lineHeight') {
      newValue = String(Math.round(Number(newValue) * 100))
    } else {
      newValue = options
        ? target.value
        : `${Math.round(Number(target.value))} ${unit}`
    }
    setInputValue(newValue.split(' ')[0])
    setFieldValue(id, newValue)
    const timeout = setTimeout(() => {
      onChange(id, newValue)
    }, 100)

    return () => {
      clearTimeout(timeout)
    }
  }

  return (
    <Flex direction="column" alignItems="center">
      <InputWrapper width={width || 56}>
        {options ? (
          <>
            {id === 'lineHeight' ? (
              <NumericInput
                id={id}
                name={id}
                value={String(Number(displayValue) / 100)}
                onChange={handleChange}
                type="number"
                step="0.1"
              />
            ) : (
              <NumericInput
                id={id}
                name={id}
                value={
                  Math.round(Number(displayValue)) === 0
                    ? ''
                    : Math.round(Number(displayValue))
                }
                onChange={handleChange}
                type="number"
                placeholder="0"
              />
            )}

            <SelectContainer onClick={toggleOptions}>
              <StyledSelect
                as="select"
                id={id}
                name={id}
                onChange={handleChange}
                value={
                  id === 'lineHeight'
                    ? String(Number(displayValue) / 100)
                    : String(Number(displayValue))
                }
              >
                {options.map((option, index) => (
                  <option key={index} value={option}>
                    {option}
                  </option>
                ))}
              </StyledSelect>
            </SelectContainer>
          </>
        ) : (
          <NumericInput
            id={id}
            name={id}
            value={
              Math.round(Number(displayValue)) === 0
                ? ''
                : Math.round(Number(displayValue))
            }
            onChange={handleChange}
            type="number"
            placeholder="0" // Placeholder set to '0'
          />
        )}
        <UnitDisplay>{unit}</UnitDisplay>
      </InputWrapper>
      <Label htmlFor={id}>
        {id === 'rotation' ? (
          <AngleIcon src={iconAngled} />
        ) : (
          formatMessage({ id: `Panel.field.label.${id}` })
        )}
      </Label>
    </Flex>
  )
}

export default FormGroup
