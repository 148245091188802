import { useQuery } from '@apollo/client'
import { useIntl } from 'react-intl'
import ReactModal from 'react-modal'

import Button, { ButtonIntents } from 'shared/ui/Button'
import Flex from 'shared/ui/Flex'
import Spinner from 'shared/ui/Spinner'
import Modal from 'shared/components/modals/IslandModal'
import { GET_TEMPLATE_PREVIEWS } from 'data/layoutcreator/queries/templates'
import { Content } from 'screens/Home/components/NewProjectModal'
import { TemplateDocumentEntity } from 'shared/types/layoutcreator/graphql'

import { SetTemplateIdType } from '../../index'
import { Carousel, TemplatePageContainer } from './style'

export type PreviewModalTemplate = {
  templateId?: string
  templatePath?: string
  categoryId?: string
}
interface PreviewModalProps
  extends ReactModal.Props,
    PreviewModalTemplate,
    SetTemplateIdType {
  closeModal: () => void
}

// TODO: handle error
const PreviewModal = ({
  closeModal,
  isOpen,
  categoryId,
  templateId,
  templatePath,
  setSelectedTemplateId,
  setTemplateError
}: PreviewModalProps) => {
  const { formatMessage } = useIntl()
  const { data, loading } = useQuery(GET_TEMPLATE_PREVIEWS, {
    skip: !categoryId || !templateId,
    variables: {
      categoryId: categoryId as string,
      templateId: templateId as string
    }
  })

  return (
    <Modal
      closeModal={closeModal}
      title={formatMessage(
        { id: 'Template.modal.title' },
        { name: templateId }
      )}
      titleAlign="left"
      isOpen={isOpen}
    >
      <Content>
        {loading ? (
          <Flex marginTop={124} justifyContent="center">
            <Spinner />
          </Flex>
        ) : (
          <Carousel>
            {data?.templateDocuments?.map(
              (templateDoc: TemplateDocumentEntity) => (
                <TemplatePageContainer key={templateDoc.id}>
                  <img
                    src={templateDoc.previewUrl}
                    alt={templateDoc.name}
                  />
                </TemplatePageContainer>
              )
            )}
          </Carousel>
        )}
        <Flex
          direction="row"
          justifyContent="flex-end"
          marginTop={64}
          columnGap={8}
        >
          <Button
            labelId="Back.to.templates"
            intent={ButtonIntents.neutral}
            onClick={closeModal}
          />
          <Button
            labelId="Choose.template"
            intent={ButtonIntents.dark}
            onClick={() => {
              setTemplateError('')
              setSelectedTemplateId(templatePath)
              closeModal()
            }}
          />
        </Flex>
      </Content>
    </Modal>
  )
}

export default PreviewModal
