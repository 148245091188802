import { ButtonHTMLAttributes } from 'react'
import styled from 'styled-components'

import Icon from 'shared/ui/Icon'

import { bySizeHelper, byTypeHelper } from './helpers'
import {
  ButtonIcon,
  ButtonIntent,
  ButtonSize,
  ButtonState
} from './types'

interface IButtonContainer
  extends ButtonHTMLAttributes<HTMLButtonElement> {
  // Rename the custom type property to avoid conflict with the native type attribute
  buttonIntent: ButtonIntent
  state: ButtonState
  iconType?: ButtonIcon
  paddingTop?: number
  backgroundColor?: string
  width?: number | string
  height?: number | string
  minWidth?: number
  maxHeight?: number
  minHeight?: number
  size: ButtonSize
}

export const ButtonContainer = styled.button<IButtonContainer>`
  cursor: pointer;
  position: relative;
  border: ${({ iconType }) => (iconType === 'only' ? 0 : 1)}px solid
    ${({ buttonIntent, state, theme }) =>
      byTypeHelper.innerBorderColor(buttonIntent, state, theme)};
  display: inline-flex;
  box-shadow: 0px -2px 2px 0.5px rgba(0, 0, 0, 0.1) inset,
    0px 1px 2px 1.5px rgba(255, 255, 255, 0.3) inset,
    0px 1px 2px 0px rgba(33, 37, 41, 0.1);

  flex-direction: row;
  border-radius: 50px;
  align-items: center;
  justify-content: center;
  padding: ${({ size, iconType }) =>
    `${bySizeHelper.paddingV(size)}px ${
      iconType !== 'only' && bySizeHelper.paddingH(size)
    }px`}; // Dynamic horizontal padding based on size

  background-color: ${({ backgroundColor }) => backgroundColor};
  width: ${({ minWidth, iconType, size }) =>
    iconType === 'only'
      ? `${bySizeHelper.iconSize(size) * 1.5}px`
      : minWidth
      ? `${minWidth}px`
      : 'auto'};
  height: ${({ size, iconType, height }) =>
    iconType !== 'only'
  ? `${height}px`
  : `${bySizeHelper.iconSize(size) * 1.5}px`};
  max-height: ${({ maxHeight }) =>
    maxHeight ? `${maxHeight}px` : 'auto'};
  min-height: ${({ minHeight }) => (minHeight ? `${minHeight}px` : 'auto')};
  border-radius: ${({ iconType }) =>
    iconType === 'only' ? '50%' : '50px'};

  /* Add a pseudo-element for the overlay */
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0);
    border-radius: 50px;
    pointer-events: none;
  }

  &:hover::after {
    background-color: rgba(0, 0, 0, 0.2);
  }

  &:active {
    opacity: 0.8;
  }
`

interface IText {
  intent: ButtonIntent
  state: ButtonState
  size: ButtonSize
  iconType?: ButtonIcon
}
export const Text = styled.p<IText>`
  text-align: center;
  font-size: ${({ size }) => bySizeHelper.textSize(size)}px;
  font-weight: 600;
  color: ${({ intent, state, theme }) =>
    byTypeHelper.textColor(intent, state, theme)};
  line-height: ${({ size }) => bySizeHelper.lineHeight(size)}px;
  ${({ iconType }) => iconType === 'left' && `margin-left: 8px;`}
  ${({ iconType }) => iconType === 'right' && `margin-right: 8px`}
`

/** IcomoonIcon */
interface IIcon {
  intent: ButtonIntent
  state: ButtonState
  buttonSize: ButtonSize
  iconType?: ButtonIcon
}
export const CustomIcon = styled(Icon).attrs<IIcon>(
  ({ intent, state, buttonSize, theme }) => ({
    size: bySizeHelper.iconSize(buttonSize),
    fill: byTypeHelper.textColor(intent, state, theme)
  })
)<IIcon>``
