import { gql } from 'shared/types/layoutcreator'

export const GET_THEME_COLORS = gql(/* GraphQL */ `
  query getThemeColors(
    $projectId: String!
    $templatePath: String!
  ) {
    getThemeColors(
      projectId: $projectId
      templatePath: $templatePath
    ) {
      key
      name
      value {
        c
        m
        y
        k
      }
    }
  }
`)

export const GET_THEME_PAGES = gql(/* GraphQL */ `
  query getThemePages(
    $projectId: String!
    $templatePath: String!
  ) {
    getThemePages(
      projectId: $projectId
      templatePath: $templatePath
    ) {
      type
      thumbUrl
    }
  }
`)

export const GET_PAGE_BACKGROUNDS_LIBRARY = gql(/* GraphQL */ `
  query getPageBackgroundsLibrary {
    getPageBackgroundsLibrary {
      id
      category
      thumbPath
    }
  }
`)

export const GET_LIBRARY_PAGE_BACKGROUND_XML = gql(/* GraphQL */ `
  query getLibraryPageBackgroundXML(
    $id: String!
    $category: String!
  ) {
    getLibraryPageBackgroundXML(id: $id, category: $category)
  }
`)

export const GET_PAGE_BACKGROUND_UPLOADS = gql(/* GraphQL */ `
  query getPageBackgroundUploads(
    $groupId: String!
    $projectId: String!
  ) {
    getPageBackgroundUploads(
      groupId: $groupId
      projectId: $projectId
    ) {
      id
      thumbPath
      type
    }
  }
`)

export const GET_PAGE_BACKGROUND_XML = gql(/* GraphQL */`
  query getPageBackgroundXML($id: String!) {
    getPageBackgroundXML(id: $id)
  }
`)
