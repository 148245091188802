import { useQuery } from '@apollo/client'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useIntl } from 'react-intl'

import Button, { Intents } from 'shared/ui/Button'
import Flex from 'shared/ui/Flex'
import FormInput from 'shared/ui/FormInput'
import StepsBar from 'shared/ui/StepsBar'
import useAuth from 'data/gradoo/hooks/useAuth'
import { GET_PROJECTS } from 'data/layoutcreator/queries/projects'
import { useProjectNameValidation } from 'shared/hooks/useProjectNameValidation'

import { Steps } from '../../index'
import { LocalStorageKeys, NewProjectModalStepIds } from '../../types'
import CoverType, { CoverTypes } from '../CoverType'
import FooterContainer from '../FooterContainer'
import StepContent from '../StepContent'
import StepFooter from '../StepFooter'
import StepForm from '../StepForm'
import GuideAction from './components/GuideAction'

type FormValues = {
  title: string
}

const FIELDS: Array<keyof FormValues> = ['title']

type SetupProps = {
  setStep: Dispatch<SetStateAction<NewProjectModalStepIds>>
  closeModal: () => void
}

const Setup = ({ setStep, closeModal }: SetupProps) => {
  const { formatMessage } = useIntl()
  const { authGroupId } = useAuth()

  const { data: projectsData } = useQuery(GET_PROJECTS, {
    variables: {
      groupId: authGroupId!
    }
  })

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setError,
    clearErrors
  } = useForm({
    defaultValues: {
      title: localStorage.getItem(LocalStorageKeys.title) || ''
    }
  })

  const [coverType, setCoverType] = useState<CoverTypes | null>(
    localStorage.getItem(LocalStorageKeys.coverType) as CoverTypes
  )
  const [coverTypeError, setCoverTypeError] = useState<string>('')

  const onSubmit: SubmitHandler<FormValues> = data => {
    localStorage.setItem(LocalStorageKeys.title, data.title)
    setStep(NewProjectModalStepIds.template)
  }

  const handleCoverTypeClick = (id: CoverTypes) => {
    localStorage.setItem(LocalStorageKeys.coverType, id)
    setCoverTypeError('')
    setCoverType(id)
  }

  const checkIsProjectNameUnique = (title: string) => {
    if (!projectsData?.projects) {
      return false
    }

    const isUnique = projectsData?.projects.every(
      project => project.name !== title
    )

    return isUnique
  }

  const title = watch('title')

  const { projectNameError, isProjectNameValid } =
    useProjectNameValidation({
      projectName: title
    })

  useEffect(() => {
    if (!isProjectNameValid) {
      setError('title', {
        type: 'manual',
        message: projectNameError || ''
      })
      return
    }

    clearErrors('title')
  }, [projectNameError, isProjectNameValid])

  return (
    <StepForm
      onSubmit={e => {
        e.preventDefault()
        if (!checkIsProjectNameUnique(title)) {
          return
        }

        if (!coverType) {
          setCoverTypeError(
            formatMessage({
              id: 'New.project.coverType.validation.error'
            })
          )
          return
        }
        handleSubmit(onSubmit)(e)
      }}
    >
      <StepContent>
        <div>
          <Flex alignItems="center" direction="column">
            {FIELDS.map(field => (
              <FormInput<FormValues>
                isFullWidth
                key={field}
                id={field}
                label={formatMessage({
                  id: `New.project.label.${field}`
                })}
                register={register}
                errors={errors}
                rules={{
                  required: formatMessage({
                    id: 'Form.field.is.required'
                  })
                }}
              />
            ))}
          </Flex>
          <CoverType
            error={coverTypeError}
            coverType={coverType}
            handleClick={handleCoverTypeClick}
          />
        </div>
        <GuideAction />
      </StepContent>
      <FooterContainer>
        <Button
          labelId="Actions.cancel"
          type="button"
          onClick={closeModal}
          intent={Intents.neutral}
        />
        <StepsBar
          stepId={NewProjectModalStepIds.setup}
          steps={Steps}
        />
        <StepFooter
          stepId={NewProjectModalStepIds.setup}
          setStep={setStep}
        />
      </FooterContainer>
    </StepForm>
  )
}

export default Setup
