import { useLazyQuery } from '@apollo/client'

import { GET_THEME_COLORS } from 'data/layoutcreator/queries/themes'
import ChiliVariables from 'shared/components/Tools/services/ChiliVariables'

const variablesApi = ChiliVariables.getInstance()

export const useReset = ({
  groupId,
  projectId,
  templatePath
}: {
  groupId: string
  projectId: string
  documentId: string
  templatePath: string
}) => {
  const [getThemeColors] = useLazyQuery(GET_THEME_COLORS)

  const applyThemeColors = async (templatePath: string) => {
    const { data: themeColorsData } = await getThemeColors({
      variables: {
        projectId,
        templatePath
      }
    })

    const themeColors = themeColorsData!.getThemeColors!

    for (const { key, value } of themeColors) {
      await variablesApi.setVariableValue(
        key,
        variablesApi.convertColorToVariableValue(value)
      )
    }
  }

  const reset = async () => {
    await applyThemeColors(templatePath)
  }

  return reset
}
