import { Link } from 'react-router-dom'
import styled from 'styled-components'

const LogoContainerLink = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none;
`

export default LogoContainerLink
