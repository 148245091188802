import { FormikContextType, useFormikContext } from 'formik'
import styled from 'styled-components'

import Flex from 'components/Flex'
import ChiliShapes from 'screens/YearbookEditor/components/Tools/services/ChiliShapes'
import { ShapeTypes } from 'screens/YearbookEditor/components/Tools/types'

import PanelContainer from '../../../../../PanelContainer'
import SecondLevelTitle from '../../../../../PanelSecondLevelTitle'
import { SHAPE_TYPES } from '../../consts'
import ShapeTypePreview from './components/ShapeTypePreview'

const shapesApi = ChiliShapes.getInstance()

type ChangeShapeTypePanelProps = {
  onBackButtonClick: () => void
}

const DisplayGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 16px;
  row-gap: 16px;
`
const ChangeShapeTypePanel = ({
  onBackButtonClick
}: ChangeShapeTypePanelProps): JSX.Element => {
  const {
    values: formValues,
    setFieldValue
  }: FormikContextType<any> = useFormikContext()

  const changeShapeType = (newType: ShapeTypes) => {
    setFieldValue('shapeType', newType)
    shapesApi.changeShapeType(newType)
  }

  return (
    <PanelContainer>
      <SecondLevelTitle
        titleId="Change.shapeType"
        onClick={onBackButtonClick}
      />

      <DisplayGrid>
        {Object.entries(SHAPE_TYPES).map(([type, { Image }]) => (
          <ShapeTypePreview
            key={type}
            onClick={() => changeShapeType(type as ShapeTypes)}
            Image={Image}
            isSelected={formValues.shapeType === type}
          />
        ))}
      </DisplayGrid>
    </PanelContainer>
  )
}

export default ChangeShapeTypePanel
