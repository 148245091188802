export enum HistoryEntryType {
  final = 'final',
  sample = 'sample',
  preview = 'preview'
}

export enum HistoryEntryStatus {
  completed = 'completed',
  failed = 'failed',
  progress = 'progress'
}

export type HistoryEntry = {
  type: HistoryEntryType
  pages: number
  exportDate: string
  exportedBy: string
  status: HistoryEntryStatus
  link?: string
}
