import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  align-items: center;
  margin: auto;
  margin-bottom: 64px;
`

export const FooterText = styled.p`
  font-size: 16px;
  line-height: 24px;
`

export const VSeparator = styled.span`
  margin: 2px 8px;
  width: 1px;
  height: 16px;
  background-color: ${({ theme }) => theme.color.base.c5};
`

export const StyledLink = styled.a`
  text-decoration: underline;
  color: ${({ theme }) => theme.color.brand_02};
  text-underline-offset: 2px;
`
export const StyledButton = styled.button`
  outline: none;
  border: none;
  background: none;
  cursor: pointer;
  padding: 0;
  margin: 0;
  text-decoration: underline;
  color: ${({ theme }) => theme.color.brand_02};
  text-underline-offset: 2px;
`
