import Flex from 'components/Flex'
import { InlineSkeleton, Skeleton } from 'components/Skeleton'

const PageBackgroundItemSkeleton = () => (
  <Flex columnGap={16} alignItems="center">
    <Skeleton width={56} height={80} />

    <Flex direction="column" rowGap={4}>
      <InlineSkeleton height={13} width={80} />
      <Skeleton
        radius={8}
        width={120}
        padding={[8, 11]}
        columnGap={5}
      >
        <Skeleton radius={2} height={12} width={12} colorLevel={3} />
        <InlineSkeleton height={8} colorLevel={3} />
      </Skeleton>
    </Flex>
  </Flex>
)

export default PageBackgroundItemSkeleton
