import { useMutation } from '@apollo/client'
import { PublisherInterface } from '@chili-publish/publisher-interface'
import config from 'config'
import { createContext, useEffect, useRef, useState } from 'react'
import toast from 'react-hot-toast'
import { useIntl } from 'react-intl'
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams
} from 'react-router-dom'
import styled from 'styled-components'

import Button from 'components/Button_2'
import FadeOut from 'components/FadeOut'
import IcomoonIcon from 'components/IcomoonIcon'
import PageHeader from 'components/PageHeader'
import { PageRoomProvider } from 'context/project-room'
import useAuth from 'data/gradoo/hooks/useAuth'
import { SAVE_PAGE_PREVIEW } from 'data/layoutcreator/mutations/documents'
import { SAVE_DOCUMENT } from 'data/layoutcreator/mutations/projects'
import { GET_PROJECT_DOCUMENTS_PREVIEWS } from 'data/layoutcreator/queries/documents'
import { fetchImageFile } from 'helpers/file'
import { getBase64ImageUrl } from 'helpers/url'
import useDisablePinchZoom from 'hooks/useDisablePinchZoom'
import { BLOB_URLS_STORAGE_KEY } from 'screens/YearbookPages/components/DndPages'
import useFetchImageBlob from 'screens/YearbookPages/components/hooks/useFetchImageBlob'
import useSessionStorage from 'screens/YearbookPages/components/hooks/useSessionStorage'
import { ImageFormats } from 'types/global'

import Skeleton from './Skeleton'
import PageMigrationModal from './components/PageMigrationModal'
import Tools from './components/Tools'
import BasicService from './components/Tools/services/BasicService'
import ChiliColors from './components/Tools/services/ChiliColors'
import ChiliDocument from './components/Tools/services/ChiliDocument'
import ChiliFrames from './components/Tools/services/ChiliFrames'
import ChiliImages from './components/Tools/services/ChiliImages'
import ChiliShapes from './components/Tools/services/ChiliShapes'
import ChiliText from './components/Tools/services/ChiliText'
import ChiliVariables from './components/Tools/services/ChiliVariables'
import {
  CursorProperty,
  CursorTypes
} from './components/Tools/services/types'
import User from './components/User'
import useEditorLoading, {
  LoadingStages
} from './hooks/useEditorLoading'
import { useEditorMigration } from './hooks/useEditorMigration'
import ContentDndProvider, {
  EditorSimulation
} from './providers/ContentDndProvider'

const colorsApi = ChiliColors.getInstance()
const shapesApi = ChiliShapes.getInstance()
const imagesApi = ChiliImages.getInstance()
const variablesApi = ChiliVariables.getInstance()
const documentApi = ChiliDocument.getInstance()
const framesApi = ChiliFrames.getInstance()
const textApi = ChiliText.getInstance()

const chiliApis = [
  colorsApi,
  shapesApi,
  imagesApi,
  variablesApi,
  documentApi,
  framesApi,
  textApi
] as BasicService[]

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: scroll;
`

const LeaveButton = styled.button`
  background: ${({ theme }) => theme.color.brand_02};
  color: ${({ theme }) => theme.color.base.c0};
  height: 32px;
  font-weight: 600;
  border: 0;
  padding: 0 16px;
  border-radius: 14px;
  cursor: pointer;

  &:focus {
    box-shadow: 0 0 0 2.5px #ffffff,
      0 0 0 6px ${({ theme }) => theme.color.brand_02};
  }
`

const PageContent = styled.div`
  position: relative;
  flex: 1;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.color.base.c0};
`

const LoadingBar = styled(FadeOut)`
  position: absolute;
  transition: 0.2s cubic-bezier(0.83, 0, 0.17, 1);
  height: 4px;
  top: 3px;
  left: 0;
  border-radius: 0 2px 2px 0;
  background: ${({ theme }) => theme.color.brand_02};
  z-index: 2;
`

const EditorIframe = styled.iframe`
  width: 100%;
  height: 100%;
  border: 0;
`

const BottomActions = styled.div`
  position: absolute;
  bottom: 24px;
  margin-bottom: 4px;
  right: 50%;
  transform: translateX(50%);
  display: flex;
  gap: 8px;
  min-width: 260px;
`

const ZoomWrapper = styled.div`
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.05);
  padding: 4px 8px;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${({ theme }) => theme.color.base.c2};
`

const ZoomText = styled.span`
  font-size: 12px;
  font-weight: 600;
  color: ${({ theme }) => theme.color.base.c6};
  min-width: 30px;
  text-align: center;
`

const MakeFitZoomButton = styled.button`
  color: ${({ theme }) => theme.color.brand_02};
  border: none;
  font-size: 12px;
  font-weight: 600;
  text-decoration: underline;
  text-underline-offset: 2px;
  background: transparent;
  cursor: pointer;
`

const ZoomIconButton = styled.button`
  display: flex;
  align-items: center;
  border: none;
  justify-content: center;
  background: transparent;
  cursor: pointer;
  padding: 0px 8px;

  &:hover {
    color: ${({ theme }) => theme.color.brand_02};
  }
`

const MoveIconButton = styled.button<{ isActive: boolean }>`
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.05);
  cursor: pointer;
  border: 1px solid
    ${({ theme, isActive }) =>
      isActive ? theme.color.brand_02 : theme.color.base.c2};
  border-radius: 100px;
  padding: 4px 16px;
  display: flex;
  align-items: center;
  color: ${({ theme, isActive }) =>
    isActive ? theme.color.brand_02 : theme.color.base.c10};
  &:hover {
    color: ${({ theme }) => theme.color.brand_02};
  }
`

type EditorContextValue = {
  isWorkspaceRendered: boolean
}

export const EditorContext = createContext({} as EditorContextValue)

const CHILI_ENV_URL = config.chiliEnvironmentUrl
const API_KEY = process.env.REACT_APP_CHILI_API_KEY
const WORKSPACE_ID = config.chiliWorkspaceId
const VIEW_PREFERENCES_ID = config.chiliViewPreferencesId
const VIEWER_ONLY = true

const ZOOM_STEP = 10
const ZOOM_STEP_PERCENT = ZOOM_STEP / 100
const MAX_ZOOM_STEP_COUNTER = 20
const INITIAL_ZOOM_LABEL = 100
const MIN_ZOOM_STEP_COUNTER = 5

export const YearbookEditor = (): JSX.Element => {
  useDisablePinchZoom()
  const { formatMessage } = useIntl()
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const projectId = decodeURIComponent(pathname.split('/')[1])
  const documentId = pathname.split('/')[3]
  const [searchParams] = useSearchParams()
  const viewSearchParam = searchParams.get('view')

  const { authGroupId, authUserGroup } = useAuth()
  const { pageId: docId } = useParams()
  const [saveDocument] = useMutation(SAVE_DOCUMENT)
  const [isSaveDocLoading, setIsSaveDocLoading] = useState(false)

  const [publisherConnector, setPublisherConnector] =
    useState<PublisherInterface | null>(null)
  const [isDocumentLoaded, setIsDocumentLoaded] = useState(false)
  const [isWorkspaceRendered, setIsWorkspaceRendered] =
    useState(false)
  const { loadingPercent, markLoaded } = useEditorLoading()
  const [currentZoomLabel, setCurrentZoomLabel] = useState(
    INITIAL_ZOOM_LABEL
  )
  const [currentZoomValue, setCurrentZoomValue] = useState<
    null | number
  >(null)
  const initialZoom = useRef<null | number>(null)
  const [cursorType, setCursorType] = useState<CursorTypes | null>(
    null
  )
  const fetchImageBlob = useFetchImageBlob()
  const { setItem: setUpdatedPageBlob, getItem } = useSessionStorage<
    Record<string, string>
  >(BLOB_URLS_STORAGE_KEY)
  const [isMigrationModalOpen, setIsMigrationModalOpen] =
    useState(false)
  const {
    isOutdated,
    migrate,
    loadingPercent: migrationLoadingPercent,
    isLoading: isMigrationLoading,
    isSuccess: isMigrationSuccess,
    isError: isMigrationError
  } = useEditorMigration({
    publisherConnector,
    isDocumentRendered: isWorkspaceRendered
  })

  const [savePagePreview] = useMutation(SAVE_PAGE_PREVIEW, {
    refetchQueries: [
      {
        query: GET_PROJECT_DOCUMENTS_PREVIEWS,
        variables: {
          groupId: authGroupId,
          projectId
        }
      }
    ]
  })

  useEffect(() => {
    if (isWorkspaceRendered && publisherConnector) {
      publisherConnector.getProperty('cursor').then(cursor => {
        const cursorData = cursor as CursorProperty
        if (cursor) {
          setCursorType(cursorData?.name || null)
        }
      })

      publisherConnector.getProperty('zoom').then(zoom => {
        initialZoom.current = typeof zoom === 'number' ? zoom : null
        setCurrentZoomValue(initialZoom.current)
      })
    }
  }, [isWorkspaceRendered, publisherConnector, setCursorType])

  async function iFrameLoaded() {
    markLoaded(LoadingStages.iframeLoaded)

    const iframe = document?.getElementById(
      'chili-iframe'
    ) as HTMLIFrameElement

    const publisherConnectorBuild = await PublisherInterface.build(
      iframe,
      {
        // penpalDebug: true
      }
    )
    markLoaded(LoadingStages.built)
    setPublisherConnector(publisherConnectorBuild)

    await publisherConnectorBuild.addListener(
      'DocumentFullyLoaded',
      async () => {
        markLoaded(LoadingStages.documentLoaded)
        setIsDocumentLoaded(true)

        const screenWidth = window.innerWidth
        const screenHeight = window.innerHeight
        const horizontalMargin = (screenWidth - 800) / 2
        let pageCanvasMarginX, pageCanvasMarginY, pageFitMarginY

        if (screenWidth >= 1440) {
          pageCanvasMarginX = horizontalMargin + 130
          pageCanvasMarginY = 48
          pageFitMarginY = 53
        } else if (screenWidth >= 1194) {
          pageCanvasMarginX = horizontalMargin + 127
          pageCanvasMarginY = 48
          pageFitMarginY = 55
        } else if (screenWidth >= 1000) {
          pageCanvasMarginX = horizontalMargin + 205
          pageCanvasMarginY = 80
          pageFitMarginY = 160
        } else {
          pageCanvasMarginX = horizontalMargin + 350
          pageCanvasMarginY = 80
          pageFitMarginY = 160
        }

        if (screenHeight >= 950) {
          pageFitMarginY = 90
        }
        if (screenHeight >= 1030) {
          pageFitMarginY = 140
        }
        await publisherConnectorBuild.setProperty(
          'document.viewPreferences',
          'pageCanvasMarginX',
          pageCanvasMarginX
        )
        await publisherConnectorBuild.setProperty(
          'document.viewPreferences',
          'pageCanvasMarginY',
          pageCanvasMarginY
        )
        await publisherConnectorBuild.setProperty(
          'document.viewPreferences',
          'pageFitMarginY',
          pageFitMarginY
        )
      }
    )
  }

  useEffect(() => {
    if (publisherConnector && isDocumentLoaded) {
      chiliApis.forEach((api: BasicService) => {
        api.init({ publisherConnector })
      })
    }

    return function cleanup() {
      chiliApis.forEach((api: BasicService) => {
        api.destroy()
      })
    }
  }, [publisherConnector, isDocumentLoaded])

  useEffect(() => {
    return function cleanup() {
      imagesApi.destroy()
    }
  }, [])

  useEffect(() => {
    publisherConnector?.addListener('WorkSpaceRendered', () => {
      markLoaded(LoadingStages.workspaceRendered)
      setTimeout(() => {
        setIsWorkspaceRendered(true)
      }, 200)
    })
  }, [publisherConnector])

  const saveDoc = async () => {
    const isDirty = await publisherConnector?.getDirtyState()
    if (!isDirty) {
      return false
    }

    const savingDocToast = toast.loading(
      formatMessage({ id: 'Toasts.saving.document' })
    )
    const tempXml = (await publisherConnector?.executeFunction(
      'document',
      'GetTempXML'
    )) as string

    if (docId) {
      setIsSaveDocLoading(true)
      const resp = await saveDocument({
        variables: {
          documentId: docId,
          xml: tempXml
        }
      })
      setIsSaveDocLoading(false)
      toast.remove(savingDocToast)
      return resp.data ? resp.data.saveDocument : false
    }
    toast.remove(savingDocToast)
    return false
  }

  const handleBack = async () => {
    // Unselect all frames
    await publisherConnector?.executeFunction(
      'document.selectedFrames',
      'Clear'
    )

    const base64 = await documentApi.getSnapshot()
    const url = getBase64ImageUrl(base64, ImageFormats.png)
    if (url) {
      const imageBlob = await fetchImageBlob(url)
      const existingBlobs = getItem() || {}
      existingBlobs[documentId] = imageBlob as string

      setUpdatedPageBlob(existingBlobs)

      savePagePreview({
        variables: {
          groupId: authGroupId!,
          projectId,
          pageId: documentId,
          image: await fetchImageFile(url)
        }
      })
    }
    const backPath = viewSearchParam
      ? `/${projectId}?view=${viewSearchParam}`
      : `/${projectId}`
    navigate(backPath)
  }
  useEffect(() => {
    if (isOutdated) {
      setIsMigrationModalOpen(true)
    }
  }, [isOutdated])

  const onZoomIn = async () => {
    if (!currentZoomValue || !initialZoom.current) {
      return
    }

    const maxZoomValue =
      initialZoom.current +
      initialZoom.current * ZOOM_STEP_PERCENT * MAX_ZOOM_STEP_COUNTER
    const newZoomValue =
      currentZoomValue + initialZoom.current * ZOOM_STEP_PERCENT
    const finalZoomValue = Math.min(newZoomValue, maxZoomValue)

    const maxValueLabel =
      INITIAL_ZOOM_LABEL + MAX_ZOOM_STEP_COUNTER * ZOOM_STEP
    const newZoomLabel = currentZoomLabel + ZOOM_STEP
    const finalZoomLabel = Math.min(newZoomLabel, maxValueLabel)

    setCurrentZoomLabel(finalZoomLabel)
    setCurrentZoomValue(finalZoomValue)

    await publisherConnector?.setProperty(
      'document',
      'zoom',
      finalZoomValue
    )
  }

  const onZoomOut = async () => {
    if (!currentZoomValue || !initialZoom.current) {
      return
    }

    const minZoomValue =
      initialZoom.current -
      initialZoom.current * ZOOM_STEP_PERCENT * MIN_ZOOM_STEP_COUNTER

    const newZoomValue =
      currentZoomValue - initialZoom.current * ZOOM_STEP_PERCENT
    const finalZoomValue = Math.max(newZoomValue, minZoomValue)

    const minValueLabel =
      INITIAL_ZOOM_LABEL - MIN_ZOOM_STEP_COUNTER * ZOOM_STEP
    const newZoomLabel = currentZoomLabel - ZOOM_STEP
    const finalZoomLabel = Math.max(newZoomLabel, minValueLabel)

    setCurrentZoomLabel(finalZoomLabel)
    setCurrentZoomValue(finalZoomValue)
    await publisherConnector?.setProperty(
      'document',
      'zoom',
      finalZoomValue
    )
  }

  const onZoomFit = async () => {
    if (!currentZoomValue || !initialZoom.current) {
      return
    }

    setCurrentZoomLabel(100)
    setCurrentZoomValue(initialZoom.current)
    await publisherConnector?.executeFunction(
      'document.editor',
      'Fit',
      'page'
    )
  }

  const onMoveToggle = async () => {
    if (cursorType === 'pointer') {
      await publisherConnector?.executeFunction(
        'document',
        'SetCursor',
        'hand'
      )
      setCursorType('hand')
    } else {
      await publisherConnector?.executeFunction(
        'document',
        'SetCursor',
        'pointer'
      )
      setCursorType('pointer')
    }
  }

  return (
    <EditorContext.Provider value={{ isWorkspaceRendered }}>
      <Container>
        <ContentDndProvider>
          <PageHeader
            titleId={'Editor.title'}
            withBackButton
            beforeGoingBackFn={saveDoc}
            onBackButtonClick={handleBack}
            renderBackButton={handleGoBack => (
              <>
                <Button
                  loading={isSaveDocLoading}
                  textId="Editor.leaveButton"
                  intent="primary-brand-02"
                  onPress={handleGoBack}
                  icon="left"
                  iconName="save"
                  minWidth={216}
                />
              </>
            )}
            tools={
              <Tools
                publisherConnector={publisherConnector!}
                isDocumentLoaded={isDocumentLoaded}
              />
            }
            user={
              authUserGroup ? (
                <User authUserGroup={authUserGroup} />
              ) : undefined
            }
          />
          <PageContent>
            <Skeleton isShown={!isWorkspaceRendered} />

            <LoadingBar
              isShown={!isWorkspaceRendered}
              style={{
                width: isWorkspaceRendered ? 0 : `${loadingPercent}%`
              }}
            />

            <EditorSimulation />

            <EditorIframe
              id="chili-iframe"
              onLoad={iFrameLoaded}
              src={`${CHILI_ENV_URL}/editor_html.aspx?doc=${docId}&ws=${WORKSPACE_ID}&vp=${VIEW_PREFERENCES_ID}&viewerOnly=${String(
                VIEWER_ONLY
              )}&apiKey=${API_KEY}`}
            />
          </PageContent>
        </ContentDndProvider>
        <BottomActions>
          <MoveIconButton
            isActive={cursorType === 'hand'}
            onClick={onMoveToggle}
          >
            <IcomoonIcon size={24} icon="move" />
          </MoveIconButton>
          {initialZoom.current && (
            <ZoomWrapper>
              <ZoomIconButton onClick={onZoomOut}>
                <IcomoonIcon size={24} icon="zoom-out" />
              </ZoomIconButton>

              <ZoomText>{currentZoomLabel}%</ZoomText>

              <ZoomIconButton onClick={onZoomIn}>
                <IcomoonIcon size={24} icon="zoom-in" />
              </ZoomIconButton>
              {initialZoom.current !== currentZoomValue && (
                <MakeFitZoomButton onClick={onZoomFit}>
                  Make fit
                </MakeFitZoomButton>
              )}
            </ZoomWrapper>
          )}
        </BottomActions>
      </Container>

      <PageMigrationModal
        isOpen={isMigrationModalOpen}
        closeModal={() => setIsMigrationModalOpen(false)}
        isError={isMigrationError}
        isSuccess={isMigrationSuccess}
        isLoading={isMigrationLoading}
        onStartUpdateClick={migrate}
        loadingPercent={migrationLoadingPercent}
      />
    </EditorContext.Provider>
  )
}

const YearbookEditorWrapped = () => {
  const { pathname } = useLocation()
  const projectId = decodeURIComponent(pathname.split('/')[1])
  const { pageId } = useParams()

  return (
    <PageRoomProvider projectId={projectId} pageId={pageId}>
      <YearbookEditor />
    </PageRoomProvider>
  )
}

export default YearbookEditorWrapped
