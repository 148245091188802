import { gql } from 'shared/types/layoutcreator'

export const GET_CATEGORIES = gql(/* GraphQL */ `
  query getCategories {
    categories {
      id
      name
      path
    }
  }
`)

export const GET_TEMPLATES = gql(/* GraphQL */ `
  query getTemplates {
    templates {
      id
      name
      categoryId
      previewUrl
      path
    }
  }
`)

export const GET_TEMPLATE_PREVIEWS = gql(/* GraphQL */ `
  query getTemplateDocs($categoryId: String!, $templateId: String!) {
    templateDocuments(
      categoryId: $categoryId
      templateId: $templateId
    ) {
      id
      name
      previewUrl
      path
      type
    }
  }
`)
