import { gql } from 'types/layoutcreator'

export const REUPLOAD_OLD_IMAGE = gql(/* GraphQL */ `
  mutation reuploadOldImage(
    $groupId: String!
    $projectId: String!
    $DAPId: String!
    $DAPValue: String!
  ) {
    reuploadOldImage(
      groupId: $groupId
      projectId: $projectId
      DAPId: $DAPId
      DAPValue: $DAPValue
    ) {
      source
      isFound
      xml
    }
  }
`)

export const START_MIGRATIONS_FOR_RANDOM_PROJECTS = gql(/* GraphQL */ `
  mutation startMigrationsForRandomProjects(
    $count: Int!
  ) {
    startMigrationsForRandomProjects(
      count: $count
    )
  }
`)

export const START_PROJECT_MIGRATION = gql(/* GraphQL */ `
  mutation startMigration(
    $projectId: String!
  ) {
    startMigration(
      projectId: $projectId
    )
  }
`)

export const REMOVE_MIGRATION_FROM_QUEUE = gql(/* GraphQL */ `
  mutation removeMigrationFromQueue(
    $migrationId: String!
  ) {
    removeMigrationFromQueue(
      migrationId: $migrationId
    )
  }
`)

export const EMPTY_MIGRATIONS_QUEUE = gql(/* GraphQL */ `
  mutation emptyMigrationsQueue {
    emptyMigrationsQueue
  }
`)
