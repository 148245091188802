import { useEffect, useState } from 'react'

import { MIN_MAIN_CONTAINER_WIDTH } from 'shared/styles/constants'

const minSupportedSize = MIN_MAIN_CONTAINER_WIDTH
export const useSupportedResize = () => {
  const [isSupported, setIsSupported] = useState<boolean>(true)

  useEffect(() => {
    const handleWindowResize = () => {
      if (window.innerWidth < minSupportedSize) {
        setIsSupported(false)
      } else {
        setIsSupported(true)
      }
    }

    window.addEventListener('resize', handleWindowResize)

    return () => {
      window.removeEventListener('resize', handleWindowResize)
    }
  }, [])

  return isSupported
}
