import styled from 'styled-components'

import Flex from 'shared/ui/Flex'
import Text from 'shared/ui/Text'

const Container = styled(Flex)`
  flex-direction: column;
  align-items: center;
  padding-top: 16px;
  border: 1px solid ${({ theme }) => theme.color.base.c3};
  border-radius: 5px;
  background-color: ${({ theme }) => theme.color.base.c0};
  height: 132px;
  width: 164px;
`

export enum KPIValueSizes {
  sm,
  md,
  lg
}

const KPI_VALUE_SIZES_MAP = {
  [KPIValueSizes.sm]: 28,
  [KPIValueSizes.md]: 36,
  [KPIValueSizes.lg]: 48
}

const formatValue = (value: number): string => {
  if (value < 1000) {
    return value.toString()
  }

  return `${value / 1000}`
}

type KPIItemProps = {
  value: number
  valueSize: KPIValueSizes
  label: string
}

const KPIItem: React.FC<KPIItemProps> = ({
  value,
  label,
  valueSize
}) => {
  return (
    <Container>
      <Flex alignItems="center" height={64}>
        <Text
          text={formatValue(value)}
          size={KPI_VALUE_SIZES_MAP[valueSize]}
          weight={600}
          color={theme => theme.color.brand_02}
        />
      </Flex>
      <Text
        text={label}
        size={13}
        color={theme => theme.color.base.c6}
        align="center"
      />
    </Container>
  )
}

export default KPIItem
