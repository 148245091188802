import { useIntl } from 'react-intl'
import styled from 'styled-components'

import Flex from 'shared/ui/Flex'
import Spinner from 'shared/ui/Spinner'

import Tag from '../History/components/Tag'
import { HistoryEntryStatus } from '../History/types'
import chevronRightSrc from './images/chevron-right.svg'
import fileAttachmentIconSrc from './images/file-attachment-02.svg'
import sendIconSrc from './images/send-01.svg'
import stickerSquareSrc from './images/sticker-square.svg'

const LIST_ITEM_ICONS = {
  exportPreviewPdf: fileAttachmentIconSrc,
  sampleProduction: stickerSquareSrc,
  finalProduction: sendIconSrc,
  dataUploaded: sendIconSrc
}

const IconContainer = styled.div`
  padding: 12px;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.color.base.c2};

  & > img {
    display: block;
  }
`

type ContainerProps = {
  isDisabled?: boolean | undefined
}
const Container = styled(Flex).attrs({
  alignItems: 'center',
  columnGap: 16
})<ContainerProps>`
  padding: 16px;
  border-radius: 5px;
  border: 1px solid ${({ theme }) => theme.color.base.c2};
  background: #fff;
  box-shadow: 2px 3px 2px 0 rgba(248, 249, 250, 0.8);
  cursor: ${({ isDisabled }) =>
    isDisabled ? 'not-allowed' : 'pointer'};
  opacity: ${({ isDisabled }) => (isDisabled ? 0.5 : 1)};
`

const Label = styled(Flex)`
  font-weight: 600;
  flex: 1;
  row-gap: 4px;
  flex-direction: column;
  align-items: flex-start;
`

type ListItemProps = {
  id: keyof typeof LIST_ITEM_ICONS
  onClick: () => void
  hasTag?: boolean | undefined
  loading?: boolean | undefined
  tagId?: string
} & ContainerProps

export const ListItem = ({
  id,
  onClick,
  isDisabled,
  hasTag,
  loading,
  tagId
}: ListItemProps) => {
  const { formatMessage } = useIntl()

  return (
    <Container
      onClick={isDisabled ? () => null : onClick}
      isDisabled={isDisabled}
    >
      <IconContainer>
        <img src={LIST_ITEM_ICONS[id]} />
      </IconContainer>
      <Label>
        {formatMessage({ id: `Project.export.button.${id}` })}
        {hasTag ? (
          <Tag
            textI18nId={`Project.export.tag.${tagId ?? id}`}
            intent={
              id === 'sampleProduction'
                ? HistoryEntryStatus.completed
                : HistoryEntryStatus.progress
            }
          />
        ) : null}
      </Label>
      {loading ? (
        <Spinner size={24} />
      ) : (
        <img src={chevronRightSrc} />
      )}
    </Container>
  )
}
