import { useQuery } from '@apollo/client'
import { useEffect, useRef, useState } from 'react'
import toast from 'react-hot-toast'
import { useLocation, useNavigate } from 'react-router-dom'

import Flex from 'components/Flex'
import Head from 'components/Head'
import PageContainer from 'components/PageContainer'
import {
  PageRoomProvider,
  useLostConnectionListener,
  useMutation,
  useOthersMapped,
  useSelf,
  useStorage,
  useUpdateMyPresence
} from 'context/project-room'
import useAuth from 'data/gradoo/hooks/useAuth'
import {
  GET_PROJECT_DOCUMENTS,
  GET_PROJECT_DOCUMENTS_PREVIEWS
} from 'data/layoutcreator/queries/documents'
import DefaultLayout from 'layout/Default'
import NewPageModal from 'screens/YearbookPages/components/NewPageModal'
import Tools from 'screens/YearbookPages/components/Tools'
import { LiveUser } from 'types/global'
import { ProjectDocument } from 'types/layoutcreator/graphql'

import DndPages from './components/DndPages'
import LiveCursorWrapper from './components/LiveCursorWrapper'
import PagePreviewModal from './components/PagePreviewModal'
import PagesMigrationModal from './components/PagesMigrationModal'
import { usePagesMigration } from './components/hooks/usePagesMigration'
import useSessionStorage from './components/hooks/useSessionStorage'

export const getProjectSyncHash = (ids: string[]) => {
  return ids.join('|')
}

export const YearbookPages = () => {
  const [isNewPageModalOpen, setIsNewPageModalOpen] = useState(false)
  const openNewPageModal = () => setIsNewPageModalOpen(true)
  const closeNewPageModal = () => setIsNewPageModalOpen(false)
  const { authGroupId } = useAuth()
  const navigate = useNavigate()
  const updateMyPresence = useUpdateMyPresence()

  const othersMapped = useOthersMapped(other => ({
    info: other.presence.info,
    editingPageId: other.presence.editingPageId
  }))

  const currentUserInfo = useSelf(me => me.presence.info)
  const currentUserConnectionId = useSelf(me => me.connectionId)

  const syncHash = useStorage(root => root.syncHash)

  const setSyncHash = useMutation(({ storage }, syncHash: string) => {
    storage.set('syncHash', syncHash)
  }, [])

  const didMountSync = useRef(false)
  const didMountProjects = useRef(false)

  useLostConnectionListener(event => {
    switch (event) {
      case 'lost':
        toast.loading('Still trying to reconnect...')
        break

      case 'restored':
        toast.dismiss()
        toast.success('Successfully reconnected again!')
        break

      case 'failed':
        toast.dismiss()
        toast.error('Could not restore the connection')
        break
    }
  })

  const liveUsers: LiveUser[] =
    currentUserInfo && currentUserConnectionId
      ? [
          {
            ...currentUserInfo,
            index: currentUserConnectionId
          },
          ...othersMapped
            .filter(other => !other[1].editingPageId)
            .map(other => ({
              ...other[1].info,
              index: other[0]
            }))
        ]
      : []

  const editingLiveUsersMap: { [key: string]: LiveUser } =
    othersMapped
      .filter(other => !!other[1].editingPageId)
      .reduce(
        (obj, other) => ({
          ...obj,
          [other[1].editingPageId as string]: {
            ...other[1].info,
            index: other[0]
          }
        }),
        {}
      )

  const { pathname: projectIdParam } = useLocation()
  const projectId = decodeURIComponent(projectIdParam).replace(
    '/',
    ''
  )
  const projectVars = {
    groupId: authGroupId as string,
    projectId: projectId.replace('/', '')
  }

  const [items, setItems] = useState<ProjectDocument[]>([])
  const {
    loading,
    error,
    data,
    refetch: refetchProjects
  } = useQuery(GET_PROJECT_DOCUMENTS, {
    skip: !authGroupId,
    variables: projectVars
  })

  const [isMigrationModalOpen, setIsMigrationModalOpen] =
    useState(false)
  const {
    isLoading: isMigrationLoading,
    isError: isMigrationError,
    isSuccess: isMigrationSuccess,
    loadingPercent: migrationLoadingPercent,
    isOutdated,
    migrate
  } = usePagesMigration({
    documentsData: data,
    refetch: async () => {
      await refetchProjects()
    }
  })

  useEffect(() => {
    if (isOutdated) {
      setIsMigrationModalOpen(true)
    }
  }, [isOutdated])

  const { data: previewsData } = useQuery(
    GET_PROJECT_DOCUMENTS_PREVIEWS,
    {
      variables: projectVars
    }
  )

  useEffect(() => {
    if (data?.projectDocuments) {
      if (!didMountProjects.current) {
        //to prevent any setSyncHash if the projects is not loaded yet
        didMountProjects.current = true
      }

      setItems(data.projectDocuments)
    }
  }, [data])

  const itemsHash = getProjectSyncHash(items.map(item => item.id))

  //itemHash is string which join all project ids (for example 'id1|id2|id3')
  //if itemsHash has changed means the user has added/removed or changed project order
  //so we trigger to update the syncHash on liveblocks
  useEffect(() => {
    if (
      !itemsHash ||
      !didMountSync.current ||
      syncHash === null ||
      !didMountProjects.current
    ) {
      return
    }
    if (syncHash !== itemsHash) {
      setSyncHash(itemsHash)
    }
  }, [itemsHash])

  //if syncHash is updated and is different from current user itemHash we trigger to refetch the projects
  useEffect(() => {
    if (!didMountSync.current) {
      didMountSync.current = true
      return
    }
    if (syncHash === null || !authGroupId || !itemsHash) {
      return
    }

    if (syncHash !== itemsHash) {
      refetchProjects()
    }
  }, [syncHash])

  const onDraggingProject = (draggingPreviewUrl: string | null) => {
    if (!didMountSync.current) {
      return
    }
    updateMyPresence({ draggingPreviewUrl })
  }
  return (
    <>
      <DefaultLayout
        liveUsers={liveUsers}
        RightComponent={
          <>
            <Tools openModal={openNewPageModal} />
            <NewPageModal
              isOpen={isNewPageModalOpen}
              closeModal={closeNewPageModal}
            />
          </>
        }
        onBack={() => navigate('/')}
      >
        <LiveCursorWrapper>
          <Head titleId="Page.editor.title" />
          <PageContainer>
            <Flex justifyContent="center" marginBottom={128}>
              {loading && <p>Loading...</p>}
              {error && <p>Error :(</p>}
              <DndPages
                items={items}
                customPreviews={
                  previewsData?.getProjectDocumentsPreviews || []
                }
                editingLiveUsersMap={editingLiveUsersMap}
                setItems={setItems}
                onDraggingProject={onDraggingProject}
              />
            </Flex>
          </PageContainer>
        </LiveCursorWrapper>
      </DefaultLayout>
      <PagePreviewModal
        projectId={projectVars.projectId}
        groupId={projectVars.groupId}
      />

      <PagesMigrationModal
        isOpen={isMigrationModalOpen}
        closeModal={() => setIsMigrationModalOpen(false)}
        isError={isMigrationError}
        isSuccess={isMigrationSuccess}
        isLoading={isMigrationLoading}
        onStartUpdateClick={migrate}
        loadingPercent={migrationLoadingPercent}
      />
    </>
  )
}

const YearbookPagesWrapped = () => {
  const { pathname } = useLocation()
  const projectId = decodeURIComponent(pathname).replace('/', '')
  return (
    <PageRoomProvider projectId={projectId}>
      <YearbookPages />
    </PageRoomProvider>
  )
}
export default YearbookPagesWrapped
