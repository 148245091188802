import { useEffect, useRef } from 'react'

import {
  ChangeFrameProps,
  Frame,
  FrameSizeFields
} from 'shared/components/Tools/types'

import { FrameService } from '../../../services/types'

type UseConnectedSizeProps = {
  frame: Frame
  changeFrameProps: ChangeFrameProps
  isConnected: boolean
  frameApi: FrameService
}

export function useConnectedSize({
  frame,
  changeFrameProps,
  isConnected,
  frameApi
}: UseConnectedSizeProps) {
  const timeouts = useRef<NodeJS.Timeout[]>([])

  const clearTimeouts = () => {
    timeouts.current.forEach((timeout: NodeJS.Timeout) =>
      clearTimeout(timeout)
    )
  }

  useEffect(() => {
    updateField(isConnected, FrameSizeFields.width)

    return () => {
      clearTimeouts()
    }
  }, [frame.width])

  useEffect(() => {
    updateField(isConnected, FrameSizeFields.height)

    return () => {
      clearTimeouts()
    }
  }, [frame.height])

  const updateField = (
    isConnected: boolean,
    field: FrameSizeFields
  ) => {
    const opposite =
      field === FrameSizeFields.width
        ? FrameSizeFields.height
        : FrameSizeFields.width

    clearTimeouts()
    let timeout: NodeJS.Timeout | null = null

    if (isConnected) {
      timeout = setTimeout(() => {
        frameApi.setFrameProperty(
          frame.id,
          opposite,
          Number.parseFloat(frame[field]!) || 0
        )
        changeFrameProps(opposite, frame[field] || '')
      }, 300)

      timeouts.current.push(timeout)
    }
  }
}

export default useConnectedSize
