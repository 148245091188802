import { gql } from '@apollo/client'

export const GET_FONT_PREVIEW_PATH = gql`
  query getBigFontPreviewSvg($fontId: String!) {
    getBigFontPreviewSvg(id: $fontId)
  }
`

export const GET_ALL_FONTS = gql`
  query getAllFonts {
    getAllFonts {
      id
      name
      family
      style
      svgBig
      svgSmall
    }
  }
`
