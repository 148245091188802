import { useLocation } from 'react-router-dom'
import styled from 'styled-components'

const Container = styled.nav`
  display: flex;
  gap: 24px;
`

const Item = styled.a<{ isActive?: boolean }>`
  color: ${({ theme, isActive }) =>
    isActive ? theme.color.brand_01 : theme.color.base.c0};
  text-decoration: none;
  font-size: 16px;
  font-weight: 600;
`

const ITEMS = [
  {
    labelId: 'Projects',
    path: '/admin/projects'
  },
  {
    labelId: 'Templates',
    path: '' // /admin/templates
  },
  {
    labelId: 'Media',
    path: '' // /admin/media
  },
  {
    labelId: 'Admin',
    path: '/admin'
  }
]

const Menu = () => {
  const location = useLocation()

  const isActive = (path: string) => location.pathname === path

  return (
    <Container>
      {ITEMS.map(({ labelId, path }) => (
        <Item key={labelId} href={path} isActive={isActive(path)}>
          {labelId}
        </Item>
      ))}
    </Container>
  )
}

export default Menu
