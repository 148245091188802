import { useMutation, useQuery } from '@apollo/client'
import { useEffect, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components'

import ColorItem, { ColorItemSkeleton } from 'shared/ui/ColorItem'
import Flex from 'shared/ui/Flex'
import { CHANGE_PROJECT_CURRENT_TEMPLATE } from 'data/layoutcreator/mutations/projects'
import { GET_PROJECT_TEMPLATES } from 'data/layoutcreator/queries/projects'
import {
  GET_THEME_COLORS,
  GET_THEME_PAGES
} from 'data/layoutcreator/queries/themes'
import CMYK from 'shared/entities/CMYK'
import { sortByPattern } from 'shared/helpers/array'
import { capitalizeFirstLetter, replaceSpaces } from 'shared/helpers/string'
import { PageTypes } from 'shared/types/global'

import SubSection from '../../../../components/SubSection'
import ChangeBackgroundModal from './components/ChangeBackgroundModal'
import ChangeColorModal from './components/ChangeColorModal'
import PageBackgroundItem from './components/PageBackgroundItem'
import PageBackgroundItemSkeleton from './components/PageBackgroundItem/Skeleton'

const ThemeImage = styled.div<{ src: string }>`
  width: 134px;
  height: 190px;
  border-radius: 8px;
  background: ${({ src }) => `url(${src})`};
  background-size: cover;
  background-position: center;
`

const ThemeName = styled.span`
  margin-top: 16px;
  font-size: 14px;
  font-weight: 600;
  color: ${({ theme }) => theme.color.base.c6};
`

const ThemeCurrent = styled.div`
  margin-top: 8px;
  padding: 2px 11px;
  border-radius: 5px;
  background: ${({ theme }) => theme.color.brand_01};
  font-size: 11px;
  font-weight: 600;
  color: ${({ theme }) => theme.color.brand_02};
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.16);
`

const MakeCurrentButton = styled.button`
  margin-top: 16px;
  border-radius: 7px;
  background: transparent;
  border: 1px solid ${({ theme }) => theme.color.base.c3};
  padding: 8px 11px;
  cursor: pointer;
  color: ${({ theme }) => theme.color.base.c7};
  font-weight: 600;
  font-size: 10px;

  &:hover {
    background: ${({ theme }) => theme.color.base.c1};
  }
`

const THEME_PAGES_ORDER = [
  PageTypes.blank,
  PageTypes.collages,
  PageTypes.reports,
  PageTypes.quotes,
  PageTypes.profile,
  PageTypes.rankings
]

type ProjectTemplateProps = {
  path: string
  name: string
  previewUrl: string
  isCurrent: boolean
  groupId: string
  projectId: string
}

const ProjectTemplate: React.FC<ProjectTemplateProps> = ({
  path,
  name,
  previewUrl,
  isCurrent,
  groupId,
  projectId
}) => {
  const { formatMessage } = useIntl()

  const themeVars = {
    projectId: replaceSpaces(projectId),
    templatePath: path
  }

  const [selectedColor, setSelectedColor] = useState({
    value: { c: 0, m: 0, y: 0, k: 0 },
    key: '',
    name: ''
  })
  const [selectedPage, setSelectedPage] = useState(null)

  const { data: themeColorsData, loading: isThemeColorsLoading } =
    useQuery(GET_THEME_COLORS, {
      variables: themeVars
    })

  const {
    data: themePagesData,
    loading: isThemePagesLoading,
    refetch: refetchThemePages
  } = useQuery(GET_THEME_PAGES, {
    variables: themeVars
  })

  const themeColors = themeColorsData?.getThemeColors
  const themePages = themePagesData?.getThemePages
  const sortedThemePages = useMemo(
    () =>
      sortByPattern(
        [...(themePages || [])],
        THEME_PAGES_ORDER,
        'type'
      ),
    [themePages]
  )

  const [isChangeColorModalOpen, setIsChangeColorModalOpen] =
    useState(false)
  const [isChangeBgModalOpen, setIsChangeBgModalOpen] =
    useState(false)

  const [changeCurrentTemplate] = useMutation(
    CHANGE_PROJECT_CURRENT_TEMPLATE,
    {
      refetchQueries: [
        {
          query: GET_PROJECT_TEMPLATES,
          variables: { projectId, groupId }
        }
      ]
    }
  )

  const [previewRefreshCounts, setPreviewRefreshCounts] = useState<
    Record<string, number>
  >({})

  const handleBackgroundChange = (pageType: string) => {
    refreshPreview(pageType)
  }

  const refreshPreview = (pageType: string) => {
    setPreviewRefreshCounts({
      ...previewRefreshCounts,
      [pageType]: previewRefreshCounts[pageType] + 1
    })
  }

  useEffect(() => {
    if (!themePages) {
      return
    }

    const initialRefreshCounts = Object.fromEntries(
      themePages.map(page => [page.type, 0])
    )

    setPreviewRefreshCounts(initialRefreshCounts)
  }, [themePages])

  useEffect(() => {
    refetchThemePages()
  }, [])

  return (
    <Flex
      columnGap={60}
      rowGap={60}
      responsive={{
        s: {
          flexDirection: 'column'
        },
        l: {
          flexDirection: 'row'
        }
      }}
    >
      {/* Left side  */}
      <Flex direction="row" columnGap={32} flex={1}>
        <Flex direction="column" alignItems="center">
          <ThemeImage src={previewUrl} />
          <ThemeName>{name}</ThemeName>
          {isCurrent ? (
            <ThemeCurrent>
              {formatMessage({ id: 'Labels.current' })}
            </ThemeCurrent>
          ) : (
            <MakeCurrentButton
              onClick={() => {
                changeCurrentTemplate({
                  variables: {
                    groupId,
                    projectId,
                    templatePath: path
                  }
                })
              }}
            >
              {formatMessage({
                id: 'Project.settings.templates.makeCurrent'
              })}
            </MakeCurrentButton>
          )}
        </Flex>
        <SubSection
          title={formatMessage({
            id: 'Project.settings.templates.colors.title'
          })}
          description={formatMessage({
            id: 'Project.settings.templates.colors.description'
          })}
        >
          <Flex direction="column" rowGap={4}>
            {isThemeColorsLoading
              ? new Array(4)
                  .fill(null)
                  .map((_, i) => (
                    <ColorItemSkeleton width={252} key={i} />
                  ))
              : themeColors?.map((color, i) => (
                  <ColorItem
                    key={i}
                    color={new CMYK(color.value).toCSS()}
                    name={color.name}
                    onEditClick={() => {
                      setSelectedColor(color)
                      setIsChangeColorModalOpen(true)
                    }}
                  />
                ))}
          </Flex>
        </SubSection>
      </Flex>

      {/* Right side  */}
      <Flex flex={1}>
        <SubSection
          title={formatMessage({
            id: 'Project.settings.templates.backgrounds.title'
          })}
          description={formatMessage({
            id: 'Project.settings.templates.backgrounds.description'
          })}
        >
          <Flex
            direction="row"
            rowGap={23}
            justifyContent="space-between"
            wrap="wrap"
          >
            {isThemePagesLoading
              ? new Array(4)
                  .fill(null)
                  .map((_, i) => (
                    <PageBackgroundItemSkeleton key={i} />
                  ))
              : sortedThemePages.map((page, i) => (
                  <PageBackgroundItem
                    key={i}
                    previewUrl={`${page.thumbUrl}&rc=${
                      previewRefreshCounts[page.type] || 0
                    }`}
                    name={`${capitalizeFirstLetter(page.type)} Page`}
                    onChangeClick={() => {
                      setIsChangeBgModalOpen(true)
                      setSelectedPage(page.type)
                    }}
                  />
                ))}

            {sortedThemePages.length < 1 &&
              formatMessage({
                id: 'Project.settings.templates.backgrounds.pages.noPages'
              })}
          </Flex>
        </SubSection>
      </Flex>

      <ChangeColorModal
        isOpen={isChangeColorModalOpen}
        templatePath={path}
        closeModal={() => setIsChangeColorModalOpen(false)}
        {...selectedColor}
        colorKey={selectedColor.key}
      />

      <ChangeBackgroundModal
        isOpen={isChangeBgModalOpen}
        templatePath={path}
        closeModal={() => setIsChangeBgModalOpen(false)}
        pageType={selectedPage}
        onChange={handleBackgroundChange}
      />
    </Flex>
  )
}

export default ProjectTemplate
