import styled from 'styled-components'

import Flex from 'components/Flex'

import { ReactComponent as BackgroundIcon } from './icons/background.svg'
import { useEffect, useState } from 'react'
import { Skeleton } from 'components/Skeleton'
import useImageLoading from 'hooks/useImageLoading'

const Preview = styled.div`
  width: 56px;
  height: 80px;
  border-radius: 6px;
  background-color: ${({ theme }) => theme.color.base.c2};
  background-size: cover;
  background-position: center;
`

const Name = styled.span`
  font-size: 12px;
  font-weight: 600;
  color: ${({ theme }) => theme.color.base.c7};
`

const ChangeButton = styled.button`
  display: flex;
  gap: 5px;
  align-items: center;
  border-radius: 7px;
  background: transparent;
  border: 1px solid ${({ theme }) => theme.color.base.c3};
  padding: 8px 11px;
  cursor: pointer;
  color: ${({ theme }) => theme.color.base.c7};
  font-weight: 600;
  font-size: 10px;

  &:hover {
    background: ${({ theme }) => theme.color.base.c1};
  }
`

type PageBackgroundItemProps = {
  previewUrl: string
  name: string
  onChangeClick: () => void
}

const PageBackgroundItem: React.FC<PageBackgroundItemProps> = ({
  previewUrl,
  name,
  onChangeClick,
}) => {
  const { isLoading } = useImageLoading(previewUrl)

  return (
    <Flex columnGap={16}>
      <Preview
        style={{
          backgroundImage: `url("${isLoading ? null : previewUrl}")`
        }}
      >
        {isLoading && <Skeleton />}
      </Preview>

      <Flex direction="column" rowGap={4} justifyContent="center">
        <Name>{name}</Name>

        <ChangeButton onClick={onChangeClick}>
          <BackgroundIcon />
          Change background
        </ChangeButton>
      </Flex>
    </Flex>
  )
}

export default PageBackgroundItem
