import { useQuery } from '@apollo/client'
import { useState } from 'react'

import Button from 'components/Button_2'
import Head from 'components/Head'
import PageContainer from 'components/PageContainer'
import SectionHeader from 'components/SectionHeader'
import useAuth from 'data/gradoo/hooks/useAuth'
import { GET_PROJECTS } from 'data/layoutcreator/queries/projects'
import { useLiveUsers } from 'hooks/useLiveUsers'
import DefaultLayout from 'layout/Default'

import YearbookCard from '../../components/YearbookCard'
import Cards from './components/Cards'
import NewProjectModal from './components/NewProjectModal'

const Home = () => {
  const liveUsers = useLiveUsers()
  const {
    authGroupId,
    loading: authLoading,
    error: authError
  } = useAuth()

  const {
    loading: projectsLoading,
    error: projectsError,
    data: projectsData
  } = useQuery(GET_PROJECTS, {
    skip: !authGroupId,
    variables: { groupId: authGroupId as string }
  })

  const loading = authLoading || projectsLoading

  const error = authError || projectsError

  const projects = projectsData?.projects || []

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)

  const closeModal = () => setIsModalOpen(false)

  return (
    <>
      <Head titleId="Projects.title" />
      <DefaultLayout
        liveUsers={liveUsers}
        RightComponent={
          <Button
            onPress={() => setIsModalOpen(true)}
            textId="Add.new.project"
            intent="primary-brand-02"
            iconName="plus"
            icon="left"
            size="sm"
          />
        }
      >
        <PageContainer>
          <SectionHeader
            titleId="Home.lets.start"
            marginTop={48}
            marginBottom={22}
          />
          {loading && <p>Loading...</p>}
          {error && <p>Error :(</p>}
          <Cards>
            {projects.map(project => (
              <YearbookCard
                key={project.id}
                linkTo={`${project.id}?view=grid`}
                name={project.name}
                lastUpdate={project?.lastUpdate ?? null}
                pagePreviews={project.pagePreviews}
              />
            ))}
          </Cards>
        </PageContainer>
      </DefaultLayout>
      <NewProjectModal
        isModalOpen={isModalOpen}
        closeModal={closeModal}
      />
    </>
  )
}

export default Home
