import { ColorItemSkeleton } from 'shared/ui/ColorItem'
import Flex from 'shared/ui/Flex'
import { InlineSkeleton, Skeleton } from 'shared/ui/Skeleton'

import PageBackgroundItemSkeleton from './components/PageBackgroundItem/Skeleton'

const ProjectTemplateSkeleton = ({
  isCurrent = false
}: {
  isCurrent?: boolean
}) => {
  return (
    <Flex columnGap={52}>
      <Flex direction="column" alignItems="center">
        <Skeleton height={190} width={134} />
        <InlineSkeleton marginTop={16} height={14} width={110} />
        {isCurrent ? (
          <Skeleton
            height={16}
            width={62}
            radius={5}
            marginTop={8}
            colorLevel={4}
          />
        ) : (
          <Skeleton
            width={135}
            height={30}
            marginTop={16}
            radius={7}
          />
        )}
      </Flex>

      <Flex justifyContent="space-between" columnGap={82}>
        <Flex direction="column" rowGap={32}>
          <Flex direction="column" rowGap={10}>
            <InlineSkeleton height={20} width={120} />

            <InlineSkeleton height={12} width={250} />
          </Flex>

          <Flex direction="column" rowGap={4}>
            {new Array(4).fill(null).map((_, i) => (
              <ColorItemSkeleton width={252} key={i} />
            ))}
          </Flex>
        </Flex>

        <Flex direction="column" rowGap={32}>
          <Flex direction="column" rowGap={10}>
            <InlineSkeleton height={20} width={120} />

            <InlineSkeleton height={12} width={250} />
          </Flex>

          <Flex wrap="wrap" flex={1} rowGap={4} columnGap={58}>
            {new Array(4).fill(null).map((_, i) => (
              <PageBackgroundItemSkeleton key={i} />
            ))}
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default ProjectTemplateSkeleton
