import { useQuery } from '@apollo/client'
import styled from 'styled-components'

import { MODULE_INSTANCE_USER_SUMMARY_RESULT } from 'data/gradoo/queries/modules'
import { useClickToCopy } from 'hooks/useClickToCopy'
import { ProfilePageQuestionFragment } from 'types/gradoo/graphql'

const QuestionText = styled.span`
  font-size: 14px;
  font-weight: 600;
`

const AnswerWrapper = styled.div`
  position: relative;
`

const CharCount = styled.span`
  position: absolute;
  bottom: 15px;
  right: 10px;
  font-size: 11px;
  font-weight: 600;
`

const AnswerTextArea = styled.textarea`
  width: 100%;
  height: 350px;
  padding: 18px 14px;
  border-radius: 14px;
  line-height: 24px;
  margin-top: 22px;
  resize: none;
  border: 1px solid ${({ theme }) => theme.color.base.c8};
  color: ${({ theme }) => theme.color.base.c8};
  position: relative;
`

type ProfileAnswerQuestionPanelProps = {
  moduleInstanceId: string
  question: ProfilePageQuestionFragment
}

const ProfileAnswerQuestionPanel: React.FC<
  ProfileAnswerQuestionPanelProps
> = ({ moduleInstanceId, question }) => {
  const { register } = useClickToCopy()

  const { data: moduleInstanceResult } = useQuery(
    MODULE_INSTANCE_USER_SUMMARY_RESULT,
    {
      skip: !moduleInstanceId,
      variables: {
        id: moduleInstanceId as string
      },
      context: { client: 'gradoo' }
    }
  )

  const maxChars =
    moduleInstanceResult?.moduleInstance?.profilePageSetup?.maxChars

  const profilePageAnswer =
    question.profilePageAnswers?.edges?.[0]?.node
  const profilePageAnswerText =
    profilePageAnswer?.profilePageQuestionOption?.text ||
    profilePageAnswer?.text ||
    ''
  return (
    <div>
      <QuestionText>{question.text}</QuestionText>
      <AnswerWrapper>
        <AnswerTextArea
          data-testid={`QuestionAnswer:${profilePageAnswer?.id}`}
          value={profilePageAnswerText}
          readOnly
          {...register({ isInput: true })}
        />
        <CharCount>
          {`${profilePageAnswerText.length}/${maxChars}`}
        </CharCount>
      </AnswerWrapper>
    </div>
  )
}

export default ProfileAnswerQuestionPanel
