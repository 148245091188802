import { useEffect } from 'react'

const useDisablePinchZoom = (): void => {
  useEffect(() => {
    const handleWheel = (e: WheelEvent): void => {
      if (e.ctrlKey || e.metaKey) {
        e.preventDefault()
      }
    }

    window.addEventListener('wheel', handleWheel, {
      passive: false
    })

    return () => {
      window.removeEventListener('wheel', handleWheel)
    }
  }, [])
}

export default useDisablePinchZoom
