import { theme } from 'gradoo-theme'
import htmr from 'htmr'
import { Dispatch, FC, SetStateAction } from 'react'
import styled from 'styled-components'

import Button from 'shared/ui/Button_2'
import Flex from 'shared/ui/Flex'

import { SecondLevelPanels } from '../FrameControls'

type IPreviewContainer = {
  width?: string
  height?: string
  color?: string
}
const PreviewContainer = styled(Flex)<IPreviewContainer>`
  width: 100%;
  margin-left: 16px;
  svg {
    fill: ${({ color }) => color};
    max-width: ${({ width }) => width}px;
    height: ${({ height }) => height}px;
  }
`

const ButtonContainer = styled(Flex)`
  min-height: 75px;
  border: 1px solid ${({ theme }) => theme.color.base.c3};
  box-shadow: 1px 2px 6px 0px #e9ecef;
  border-radius: 5px;

  justify-content: space-between;
  overflow: hidden;
  background-color: ${({ theme }) => theme.color.base.c1};
`

const ButtonStyle = styled.div`
  margin-right: 16px;
  display: flex;
  align-items: center;
`

type SelectedFontProps = {
  svgImage: FC<{}> | (() => JSX.Element) | string
  onClick: Dispatch<SetStateAction<SecondLevelPanels | null>>
  width?: string
  height?: string
  color?: string
}

const Selector = ({
  svgImage,
  onClick,
  height,
  width,
  color = theme.color.base.c9
}: SelectedFontProps): JSX.Element => {
  const renderSvgImage = () => {
    // If svgImage is a string, we render it as HTML using htmr to convert the string to JSX elements.

    if (typeof svgImage === 'string') {
      return htmr(svgImage)
    } else {
      const SvgImageComponent = svgImage // Capitalize to indicate it's a component.
      return <SvgImageComponent />
    }
  }

  return (
    <ButtonContainer
      direction="row"
      marginTop={32}
      justifyContent="center"
      alignItems="center"
    >
      <PreviewContainer width={width} height={height} color={color}>
        {renderSvgImage()}
      </PreviewContainer>
      <ButtonStyle>
        <Button
          onPress={() => onClick(SecondLevelPanels.font)}
          icon="only"
          iconName="refresh"
          intent="sub-action"
        />
      </ButtonStyle>
    </ButtonContainer>
  )
}

export default Selector
