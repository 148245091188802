import Flex from 'shared/ui/Flex'
import Text from 'shared/ui/Text'

import BookIcon from './components/BookIcon'

export default function EmptyInside() {
  return (
    <Flex width="100%" direction="column">
      <Flex style={{ backgroundColor: 'white' }} grow={1}>
        {Array.from(Array(2).keys()).map((_, i) => (
          <Flex
            key={i}
            direction="column"
            alignItems="center"
            justifyContent="center"
            grow={1}
            rowGap={8}
          >
            <BookIcon />
            <Flex width={90}>
              <Text
                align="center"
                id="Inside.stays.empty"
                color={theme => theme.color.base.c7}
                size={10}
                lineHeight="140%"
              />
            </Flex>
          </Flex>
        ))}
      </Flex>
      <Text
        tag="p"
        align="center"
        id="Cover.inside"
        color={theme => theme.color.base.c7}
        weight={600}
        marginTop={24}
        size={12}
      />
    </Flex>
  )
}
