import React from 'react'
import toast from 'react-hot-toast'
import { useIntl } from 'react-intl'

export type ClickToCopyReturnedProps<T> = {
  ref: React.MutableRefObject<T | null>
  onClick: () => void
  style: React.CSSProperties
}

type RegisterOptions = {
  isInput?: boolean
}

export const useClickToCopy = () => {
  const { formatMessage } = useIntl()

  const notify = () => {
    toast.success(formatMessage({ id: 'Toasts.textCopied' }))
  }

  const copy = async (
    ref: React.RefObject<any>,
    isInput: boolean
  ) => {
    const text = isInput
      ? (ref?.current as unknown as HTMLInputElement).value
      : ref?.current?.innerText

    if (!text) {
      return
    }

    await navigator.clipboard.writeText(text)
  }

  const register = <T extends HTMLElement>(
    options?: RegisterOptions
  ): ClickToCopyReturnedProps<T> => {
    const ref = React.createRef<T | null>()

    return {
      ref,
      onClick: async () => {
        await copy(ref, !!options?.isInput)
        notify()
      },
      style: { cursor: 'pointer' }
    }
  }

  return {
    register
  }
}
