import { useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { FontFamily } from 'shared/components/Tools/types'

const MAX_LIST_SIZE = 5
const LOCAL_STORAGE_KEY = 'recentlyUsedFonts'

type StorageMap = Record<string, FontFamily[]>

const getStorageMap = (): StorageMap | null => {
  const storageMapJSON = localStorage.getItem(LOCAL_STORAGE_KEY) as
    | string
    | null

  return storageMapJSON && JSON.parse(storageMapJSON)
}

const updateLocalStorage = (
  projectId: string,
  newValue: FontFamily[]
) => {
  let storageMap = getStorageMap() || {}

  storageMap[projectId] = newValue

  localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(storageMap))
}

const getFromLocalStorage = (
  projectId: string
): FontFamily[] | null => {
  const storageMap = getStorageMap()

  if (!storageMap) return null

  return storageMap[projectId] || null
}

function useRecentlyUsedFonts(currentFontFamily: FontFamily | null) {
  const [recent, setRecent] = useState<FontFamily[]>([])
  const prevFamily = useRef<FontFamily | null>(null)

  const { pathname } = useLocation()
  const projectId = pathname.split('/')[1]

  const updateRecent = () => {
    const fontFamily = prevFamily.current

    if (!fontFamily) {
      return null
    }

    if (recent.some(family => family.id === fontFamily.id)) {
      return null
    }

    if (recent.length === MAX_LIST_SIZE) {
      const withoutLast = recent.slice(0, -1)
      return setRecent([fontFamily, ...withoutLast])
    }

    return setRecent([fontFamily, ...recent])
  }

  useEffect(() => {
    const storageData = getFromLocalStorage(projectId)

    if (storageData) {
      setRecent(storageData)
    }
  }, [])

  useEffect(() => {
    updateRecent()

    prevFamily.current = currentFontFamily
  }, [currentFontFamily])

  useEffect(() => {
    updateLocalStorage(projectId, recent)
  }, [recent])

  return recent
}

export default useRecentlyUsedFonts
