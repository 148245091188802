import { useIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import Icon from 'components/IcomoonIcon'
import { theme } from 'styles/theme'

export const ToolsContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 16px;
`

const Container = styled.div<{ isActive?: boolean }>`
  color: ${({ theme, isActive }) =>
    isActive ? theme.color.base.c9 : theme.color.base.c6};
`

const StyledLink = styled(Link)`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 10px;
  font-weight: 600;
  text-decoration: none;
  color: inherit;
`

const Title = styled.div`
  margin-top: 4px;
`

const Tool = ({
  to,
  icon,
  title,
  param,
  isActive = false
}: {
  to: string
  icon: string
  title: string
  param?: string
  isActive?: boolean
}) => {
  const { formatMessage } = useIntl()
  return (
    <Container isActive={isActive}>
      <StyledLink to={param ? to.replace(':yearbookId', param) : to}>
        <Icon
          icon={icon}
          size={16}
          color={isActive ? theme.color.base.c9 : theme.color.base.c6}
        />
        <Title>{formatMessage({ id: title })}</Title>
      </StyledLink>
    </Container>
  )
}

export default Tool
