import { format, parseISO } from 'date-fns'
import { useIntl } from 'react-intl'
import styled from 'styled-components'

import Avatar from 'components/Avatar'
import Flex from 'components/Flex'
import { useClickToCopy } from 'hooks/useClickToCopy'
import { ProfilePageCommentFragment } from 'types/gradoo/graphql'

const Bubble = styled.div`
  position: relative;
  font-size: 14px;
  padding: 20px;
  background-color: ${({ theme }) => theme.color.base.c0};
  border: 1px solid ${({ theme }) => theme.color.base.c3};
  border-radius: 14px;
  line-height: 20px;
  margin-bottom: 20px;
`

const Triangle = styled.div`
  position: absolute;
  bottom: -16px;
  left: 12px;
  height: 16px;
  width: 35px;
  overflow: hidden;

  &::after {
    height: 27px;
    width: 27px;
    border: 1px solid ${({ theme }) => theme.color.base.c3};
    border-radius: 9px;
    content: '';
    background: ${({ theme }) => theme.color.base.c0};
    left: 3px;
    transform: scale(0.7, 1) rotate(45deg);
    bottom: 3px;
    position: absolute;
  }
`

const From = styled.span`
  font-size: 11px;
  font-weight: 600;
`

const Date = styled.span`
  font-size: 11px;
  color: ${({ theme }) => theme.color.base.c5};
`

type CommentItemProps = {
  comment: ProfilePageCommentFragment
}

const CommentItem: React.FC<CommentItemProps> = ({ comment }) => {
  const { formatMessage } = useIntl()
  const { register } = useClickToCopy()

  const commentUserAvatar =
    comment.comentatorUserGroup.defaultAvatar?.avatar ||
    comment.comentatorUserGroup.avatar ||
    ''
  const commentUserFullName = `${comment.comentatorUserGroup.user.firstName} ${comment.comentatorUserGroup.user.lastName}`

  return (
    <div>
      <Bubble {...register()}>
        {comment.text}
        <Triangle />
      </Bubble>
      <Flex columnGap={6}>
        <Avatar src={commentUserAvatar} size={28} />
        <Flex direction="column" rowGap={2}>
          <From>
            {formatMessage(
              { id: 'Panel.profileDetails.tabs.comments.item.from' },
              { fullName: commentUserFullName }
            )}
          </From>
          <Date>{format(parseISO(comment.created), 'dd.MM.yy')}</Date>
        </Flex>
      </Flex>
    </div>
  )
}

export default CommentItem
