export enum NewProjectModalStepIds {
  'setup' = 'title',
  'template' = 'template'
}

export enum LocalStorageKeys {
  title = 'new-project-title',
  orderNumber = 'new-project-order-number',
  coverType = 'new-project-cover-type'
}
