export const layoutCreatorSignInMutation = () => `mutation {
  signIn {
    accessToken
    refreshToken
  }
}`

export const gradooRefreshTokenMutation = ({
  refreshToken
}: {
  refreshToken: string
}) => `mutation {
  refreshToken(input:{refreshToken: "${refreshToken}"}) {
    token
    refreshToken
  }
}`

export const layoutCreatorRefreshTokenMutation = () => `mutation {
  refreshToken
}`
