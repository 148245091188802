import { FormikContextType, useFormikContext } from 'formik'

import Options from '../Options'
import { FormOptionsCommonProps } from '../types'

type FormOptionsProps<ValueType> = {
  onChange: (value: ValueType) => void
  nullable?: boolean
} & FormOptionsCommonProps<ValueType>

const FormOptions = <ValueType extends any>({
  field,
  options,
  onChange,
  disabled = [],
  nullable = false
}: FormOptionsProps<ValueType>): JSX.Element => {
  const {
    values: formValues,
    setFieldValue
  }: FormikContextType<any> = useFormikContext()

  const value = formValues![field]

  const handleChange = (selectedValue: ValueType) => {
    if (nullable && value === selectedValue)
      return onChange(null as ValueType)

    setFieldValue(field, selectedValue)
    onChange(selectedValue)
  }

  const isActive = (optionValue: ValueType) => value === optionValue

  return (
    <Options
      options={options}
      disabled={disabled}
      isActive={isActive}
      onOptionClick={handleChange}
    />
  )
}

export default FormOptions
