import Flex from 'components/Flex'
import { InlineSkeleton } from 'components/Skeleton'

import QuotesItemSkeleton from './QuoteItem/Skeleton'

const QuotesPanelSkeleton: React.FC = () => {
  return (
    <>
      <InlineSkeleton height={26}>
        <InlineSkeleton
          colorLevel={4}
          width="50%"
          justifyContent="center"
        >
          <InlineSkeleton height={8} width={50} />
        </InlineSkeleton>

        <Flex width="50%" justifyContent="center">
          <InlineSkeleton colorLevel={3} height={8} width={65} />
        </Flex>
      </InlineSkeleton>

      <Flex marginTop={32} direction="column" rowGap={8}>
        {new Array(2).fill(null).map((_, i) => (
          <QuotesItemSkeleton key={i} />
        ))}
      </Flex>
    </>
  )
}

export default QuotesPanelSkeleton
