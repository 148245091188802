import { useIntl } from 'react-intl'
import styled, { css } from 'styled-components'

import Flex from 'components/Flex'

import checkSrc from './images/check.svg'
import lookupSrc from './images/lookup.svg'

const TemplateName = styled.div`
  font-weight: 600;
  font-size: 14px;
  text-align: center;
`

type OverlayProps = {
  alwaysVisible?: boolean
}
const Overlay = styled(Flex).attrs({
  alignItems: 'center',
  justifyContent: 'center',
  direction: 'column',
  rowGap: 8
})<OverlayProps>`
  opacity: ${({ alwaysVisible }) => (alwaysVisible ? 1 : 0)};
  position: absolute;
  inset: 0;
  width: 100%;
  background: rgba(15, 23, 42, 0.9);
  color: ${({ theme }) => theme.color.base.c2};
  font-weight: 600;
  font-size: 10px;
`

const CoverContainer = styled.div<{ isSelected: boolean }>`
  position: relative;
  overflow: hidden;
  border-radius: 15px;
  cursor: pointer;

  &:hover ${Overlay} {
    opacity: 1;
  }

  ${({ isSelected }) =>
    isSelected &&
    css`
      box-shadow: 0 0 0 2.5px #ffffff, 0 0 0 6px #6366f1,
        0 0 0 8px #f8fafc;
    `}
`

const Cover = styled.img`
  display: block;
  width: 134px;
  height: 190px;
`

type TemplateItemProps = {
  id: string
  isSelected: boolean
  previewUrl: string
  name: string
  categoryId: string
  openModal: (name: string, categoryId: string, id: string) => void
}

const TemplatesItem: React.FC<TemplateItemProps> = ({
  id,
  isSelected,
  previewUrl,
  name,
  categoryId,
  openModal
}) => {
  const { formatMessage } = useIntl()

  return (
    <Flex key={id} direction="column" rowGap={8}>
      <CoverContainer
        data-testid={`TemplateCard:${id}`}
        isSelected={isSelected}
        onClick={() => openModal(name, categoryId, id)}
      >
        <Cover src={previewUrl} />
        {isSelected ? (
          <Overlay alwaysVisible>
            <img
              src={checkSrc}
              alt={formatMessage({
                id: 'New.project.templates.selected'
              })}
            />
            {formatMessage({
              id: 'New.project.templates.selected'
            })}
          </Overlay>
        ) : (
          <Overlay>
            <img
              src={lookupSrc}
              alt={formatMessage({
                id: 'New.project.templates.preview'
              })}
            />
            {formatMessage({
              id: 'New.project.templates.preview'
            })}
          </Overlay>
        )}
      </CoverContainer>
      <TemplateName>{name}</TemplateName>
    </Flex>
  )
}

export default TemplatesItem
