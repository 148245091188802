import React from 'react'
import { useIntl } from 'react-intl'
import styled, { css } from 'styled-components'

import Flex from 'shared/ui/Flex'
import { FormError } from 'shared/ui/FormInput'

import InfoList from './components/InfoList'
import hardSrc from './images/hard.png'
import softSrc from './images/soft.png'

const Option = styled.div<{ isActive: boolean }>`
  width: 230px;
  height: 296px;
  border: ${({ theme, isActive }) =>
    `${isActive ? 0 : '1px'} solid ${theme.color.base.c3}`};
  border-radius: 14px;
  overflow: hidden;
  cursor: pointer;
  ${({ isActive, theme }) =>
    isActive &&
    css`
      color: ${theme.color.base.c10};
      background: ${theme.color.brand_01};

      & ${Image} {
        opacity: 0.5;
      }
    `}
`

const Image = styled.img`
  width: 100%;
  height: 188px;
`

const Title = styled.div`
  font-weight: 600;
  font-size: 16px;
  text-align: center;
`

export enum CoverTypes {
  soft = 'soft',
  hard = 'hard'
}

interface ICoverType {
  id: CoverTypes
  img: string
}

const COVER_TYPES: ICoverType[] = [
  {
    id: CoverTypes.soft,
    img: softSrc
  },
  {
    id: CoverTypes.hard,
    img: hardSrc
  }
]

type CoverTypeProps = {
  coverType: CoverTypes | null
  error: string
  handleClick: (id: CoverTypes) => void
}

const CoverType = ({
  coverType,
  error,
  handleClick
}: CoverTypeProps) => {
  const { formatMessage } = useIntl()

  return (
    <>
      <Flex justifyContent="center" columnGap={24}>
        {COVER_TYPES.map(({ id, img }) => (
          <Option
            key={id}
            onClick={() => handleClick(id)}
            isActive={coverType === id}
          >
            <Image src={img} />
            <Title>
              {formatMessage({
                id: `New.project.coverTypeTitle.${id}`
              })}
            </Title>
            <InfoList
              isActive={coverType === id}
              items={[0, 1].map(i =>
                formatMessage({
                  id: `New.project.coverTypeInfo.${id}.${i}`
                })
              )}
            />
          </Option>
        ))}
      </Flex>
      {error && (
        <Flex justifyContent="center">
          <FormError>{error}</FormError>
        </Flex>
      )}
    </>
  )
}

export default CoverType
