import React from 'react'

import { ShapeTypes } from 'screens/YearbookEditor/components/Tools/types'

import { ReactComponent as ElipsePreview } from './images/elipse.svg'
import { ReactComponent as RectanglePreview } from './images/rectangle.svg'
import { ReactComponent as StarPreview } from './images/star.svg'
import { ReactComponent as TrianglePreview } from './images/triangle.svg'

export const SHAPE_TYPES: Record<ShapeTypes, { Image: React.FC }> = {
  [ShapeTypes.ellipse]: {
    Image: ElipsePreview
  },
  [ShapeTypes.star]: {
    Image: StarPreview
  },
  [ShapeTypes.rect]: {
    Image: RectanglePreview
  },
  [ShapeTypes.triangle]: {
    Image: TrianglePreview
  }
}
