import { PublisherInterface } from '@chili-publish/publisher-interface'

import CMYK from 'entities/CMYK'
import RGB from 'entities/RGB'
import { Maybe } from 'graphql/jsutils/Maybe'
import { CMYKValues, ColorTypes } from 'types/global'

import { ChiliColor } from '../types'
import { ColorsService, DocumentColor } from './types'

type InitOptions = {
  publisherConnector: PublisherInterface
}

export default class ChiliColors
  implements ColorsService<InitOptions>
{
  private static instance: ChiliColors
  private publisherConnector: Maybe<PublisherInterface>
  private isInitialized = false

  static getInstance(): ChiliColors {
    if (!ChiliColors.instance) {
      ChiliColors.instance = new ChiliColors()
    }

    return ChiliColors.instance
  }

  destroy() {
    this.isInitialized = false
    this.publisherConnector = null
  }

  generateColorPath(nameOrId: string): string {
    return `cp_object:document.colors[${nameOrId}]`
  }

  init({ publisherConnector }: InitOptions) {
    if (!this.isInitialized) {
      this.publisherConnector = publisherConnector
      this.isInitialized = true
    }
  }

  private parseChiliObject(object: any): DocumentColor | null {
    const { id, name, type } = object

    let color

    if (type === ColorTypes.cmyk) {
      color = new CMYK({
        c: Number(object.c),
        m: Number(object.m),
        y: Number(object.y),
        k: Number(object.k)
      })
    } else if (type === ColorTypes.rgb) {
      color = new RGB({
        r: Number(object.r),
        g: Number(object.g),
        b: Number(object.b)
      })
    } else {
      return null
    }

    return {
      id,
      name,
      type,
      color
    }
  }

  async getColor(nameOrId: string): Promise<DocumentColor | null> {
    if (!this.isInitialized) {
      return null
    }

    const object = (await this.publisherConnector?.getObject(
      this.generateColorPath(nameOrId)
    )) as ChiliColor

    if (!object) {
      return null
    }

    return this.parseChiliObject(object)
  }

  async getColorByPath(path: string): Promise<DocumentColor | null> {
    if (!this.isInitialized) {
      return null
    }

    const object = (await this.publisherConnector?.getObject(
      path
    )) as ChiliColor

    if (!object) {
      return null
    }

    return this.parseChiliObject(object)
  }

  private generateName(color: CMYK): string {
    return `cmyk(${color.c}, ${color.m}, ${color.y}, ${color.k})`
  }

  async addNewColor(color: CMYK) {
    const newColor = (await this.publisherConnector?.executeFunction(
      'document.colors',
      'Add'
    )) as ChiliColor

    const newColorPath = this.generateColorPath(newColor.id)
    const colorName = this.generateName(color)

    await this.publisherConnector?.setProperty(
      newColorPath,
      'name',
      colorName
    )
    await this.publisherConnector?.setProperty(
      newColorPath,
      'type',
      'CMYK'
    )

    const cmykFields = [
      CMYKValues.c,
      CMYKValues.m,
      CMYKValues.y,
      CMYKValues.k
    ]

    for (const field of cmykFields) {
      await this.publisherConnector?.setProperty(
        newColorPath,
        field,
        color[field].toString()
      )
    }

    return {
      id: newColor.id,
      path: newColorPath,
      name: colorName,
      type: ColorTypes.cmyk,
      color
    }
  }

  async changeColor(
    path: string,
    values: Partial<CMYK>
  ): Promise<DocumentColor | null> {
    for (const [field, value] of Object.entries(values)) {
      await this.publisherConnector?.setProperty(
        path,
        field,
        value.toString()
      )
    }

    return this.getColorByPath(path)
  }

  async removeColor(selector: string): Promise<void> {
    await this.publisherConnector?.executeFunction(selector, 'Delete')
  }
}
