import { Dispatch, FC, SetStateAction } from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components'

import Button from 'components/Button_2'
import Flex from 'components/Flex'
import IslandModal from 'components/modals/IslandModal'

import CountryInput from './components/CountryInput'
import AT from './images/AT.svg'
import DE from './images/DE.svg'
import TR from './images/TR.svg'

const Title = styled.div`
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
`

export type CountryIDs = 'de' | 'at' | 'tr'

type Country = {
  id: CountryIDs
  icon: string
  phoneCode: number
}

export const COUNTRIES: Country[] = [
  {
    id: 'de',
    icon: DE,
    phoneCode: 49
  },
  {
    id: 'at',
    icon: AT,
    phoneCode: 43
  },
  {
    id: 'tr',
    icon: TR,
    phoneCode: 90
  }
]

type SelectCountryProps = {
  closeModal: () => void
  isOpen: boolean
  setCountry: Dispatch<SetStateAction<CountryIDs>>
  selectedCountry?: CountryIDs
}

const SelectCountryModal: FC<SelectCountryProps> = ({
  closeModal,
  isOpen,
  setCountry,
  selectedCountry
}) => {
  const { formatMessage } = useIntl()
  return (
    <IslandModal closeModal={closeModal} isOpen={isOpen}>
      <Flex direction="column" rowGap={32}>
        <Title>
          {formatMessage({ id: 'Project.select.country.title' })}
        </Title>
        <Flex rowGap={16} direction="column">
          {COUNTRIES.map(({ id, icon }) => (
            <CountryInput
              id={id}
              key={id}
              icon={icon}
              hasError={false}
              setCountry={setCountry}
              isSelected={id === selectedCountry}
            />
          ))}
        </Flex>
        <Button
          onPress={closeModal}
          textId="Project.select.country.set"
          intent="secondary-contrast"
          size="2xl"
          minWidth={340}
        />
      </Flex>
    </IslandModal>
  )
}

export default SelectCountryModal
