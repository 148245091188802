export const enterFullscreen = () => {
  return document.body.requestFullscreen()
}

export const exitFullscreen = () => {
  return document.exitFullscreen()
}

export const toggleFullscreen = () => {
  if (document.fullscreenElement) {
    return exitFullscreen()
  }

  return enterFullscreen()
}
