import {
  useLazyQuery,
  useMutation,
  useReactiveVar
} from '@apollo/client'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { toast } from 'react-hot-toast'
import { useIntl } from 'react-intl'
import { useParams } from 'react-router-dom'

import Button from 'shared/ui/Button_2'
import Flex from 'shared/ui/Flex'
import FormInput from 'shared/ui/FormInput'
import StepsBar from 'shared/ui/StepsBar'
import useAuth from 'data/gradoo/hooks/useAuth'
import { ME } from 'data/gradoo/queries/auth'
import { SEND_TO_PRODUCTION } from 'data/layoutcreator/mutations/production'
import { GET_PRODUCTION_EXPORTS } from 'data/layoutcreator/queries/production'
import {
  addressCountryCodeVar,
  phoneCountryCodeVar
} from 'data/store/verify-address'
import { ProjectExportTypes } from 'screens/AdminOldProject/types'
import {
  AdsPlacement,
  ProductionDataInput,
  UploadTypes,
  UploaderInput
} from 'shared/types/layoutcreator/graphql'

import { useToastValidation } from '../../hooks/useToastValidation'
import {
  ProductionModalStepIds,
  ProductionModalStepProps,
  Steps
} from '../../index'
import { setUpPrintDataSchema } from '../../schemas'
import FormHeader from '../FormHeader'
import ModalTitle from '../ModalTitle'
import { VERIFY_ADDRESS_LOCAL_STORAGE_KEY } from '../VerifyAddress'
import { COUNTRIES } from '../VerifyAddress/components/SelectCountryModal'
import DownloadTemplateLink from './components/DownloadTemplateLink'
import RadioInput from './components/RadioInput'
import UploadCover from './components/UploadCover'

export type FieldValues = {
  bnwPages?: string | undefined
  adsPlacement: AdsPlacement
  cover: File
}

type SetupPrintDataProps = ProductionModalStepProps & {
  uploadType: UploadTypes
  onSubmitSuccess: () => void
}
const SetUpPrintData = ({
  orderNumber,
  uploadType,
  onSubmitSuccess,
  setStep
}: SetupPrintDataProps) => {
  const {
    authGroupId,
    authUserId,
    authUserGroupYear,
    authUserInstituteInternalId
  } = useAuth()
  const { formatMessage } = useIntl()
  const { projectId } = useParams()

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    resetField
  } = useForm<FieldValues>({
    resolver: yupResolver(setUpPrintDataSchema)
  })

  const [getMeData] = useLazyQuery(ME, {
    context: { client: 'gradoo' }
  })

  const [sendToProduction, { loading: sendingToProduction }] =
    useMutation(SEND_TO_PRODUCTION, {
      refetchQueries: [
        {
          query: GET_PRODUCTION_EXPORTS,
          variables: {
            groupId: authGroupId!,
            projectId: projectId
          }
        }
      ]
    })

  const addressCountry = useReactiveVar(addressCountryCodeVar)
  const phoneCountry = useReactiveVar(phoneCountryCodeVar)
  const phoneCountryCode = COUNTRIES.find(
    ({ id }) => id === phoneCountry
  )?.phoneCode

  async function onSubmit(data: FieldValues) {
    const { data: meData } = await getMeData()

    const uploader: UploaderInput = {
      name: `${meData?.me?.firstName} ${meData?.me?.lastName}`.trim(),
      email: meData?.me?.email || '',
      phoneNumber: meData?.me?.phone || ''
    }

    const verifiedAddressDataString = localStorage.getItem(
      VERIFY_ADDRESS_LOCAL_STORAGE_KEY
    ) as string

    const verifiedAddressData = JSON.parse(verifiedAddressDataString)

    const { cover, ...printData } = data

    const productionData: ProductionDataInput = {
      deliveryAddress: {
        ...verifiedAddressData,
        phoneNumber: `+${phoneCountryCode}${verifiedAddressData.phoneNumber}`,
        countryCode: addressCountry.toUpperCase()
      },
      ...printData,
      outputProfile: ProjectExportTypes.print,
      uploader,
      projectId: projectId as string,
      groupId: authGroupId as string,
      userId: authUserId as string,
      orderNumber: orderNumber as string,
      customerNumber: authUserInstituteInternalId as string,
      year: authUserGroupYear as number,
      uploadType
    }

    await toast.promise(
      sendToProduction({
        variables: {
          productionData,
          cover
        }
      }).then(() => onSubmitSuccess()),
      {
        loading: formatMessage({
          id: 'Toasts.production.data.submitting'
        }),
        success: formatMessage({
          id: 'Toasts.production.data.success'
        }),
        error: formatMessage({
          id: 'Toasts.production.data.error'
        })
      }
    )
  }

  useToastValidation({ formKey: 'print.data', errors })

  return (
    <div>
      <Flex>
        <ModalTitle>
          {formatMessage({ id: 'Project.printdata.title' })}
        </ModalTitle>
      </Flex>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Flex direction="row" columnGap={48} marginBottom={64}>
          <Flex direction="column" flex={0.6}>
            <FormHeader>
              {formatMessage({
                id: 'Project.print.data.data.info'
              })}
            </FormHeader>
            <FormInput<FieldValues>
              id="bnwPages"
              label={formatMessage({
                id: 'Project.print.data.bnw.pages.label'
              })}
              errors={errors}
              register={register}
              isFullWidth
              hideErrors
            />
            <Flex columnGap={8}>
              {Object.values(AdsPlacement).map(id => (
                <RadioInput
                  id={id}
                  key={id}
                  {...register('adsPlacement')}
                  hasError={!!errors['adsPlacement']}
                />
              ))}
            </Flex>
          </Flex>
          <Flex direction="column" flex={0.4}>
            <FormHeader>
              {formatMessage({
                id: 'Project.printdata.upload.cover'
              })}
            </FormHeader>
            <Flex direction="column" rowGap={24}>
              <UploadCover
                setValue={setValue}
                resetField={resetField}
                hasError={!!errors.cover}
                register={register}
              />
              <DownloadTemplateLink orderNumber={orderNumber || ''} />
            </Flex>
          </Flex>
        </Flex>
        <Flex justifyContent="space-between">
          <Button
            intent="secondary-base"
            textId="Project.go.back"
            onPress={() => {
              if (Steps.setupPrintData.prevStepId) {
                setStep(Steps.setupPrintData.prevStepId)
              }
            }}
            size="xl"
          />
          <StepsBar<ProductionModalStepIds>
            stepId={ProductionModalStepIds.setupPrintData}
            steps={Steps}
            setStep={setStep}
          />
          <Button
            type="submit"
            intent="primary-brand-02"
            textId="Project.print.data.send"
            isDisabled={sendingToProduction}
            size="xl"
          />
        </Flex>
      </form>
    </div>
  )
}

export default SetUpPrintData
