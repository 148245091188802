import React from 'react'

import HeaderGoBackButton from 'shared/ui/HeaderGoBackButton'
import { ReactComponent as Logo } from 'shared/assets/icons/logo-admin.svg'
import { LiveUser } from 'shared/types/global'
import Flex from 'shared/ui/Flex'
import LiveAvatarsRow from 'shared/ui/LiveAvatarsRow'

type HeaderProps = {
  liveUsers: LiveUser[]
}

const AdminProjectSettingsHeader: React.FC<HeaderProps> = ({
  liveUsers
}) => {
  return (
    <Flex justifyContent="space-between" alignItems='center'>
      <Flex columnGap={16}>
        <HeaderGoBackButton />
        <Logo />
      </Flex>

      <LiveAvatarsRow liveUsers={liveUsers} />
    </Flex>
  )
}

export default AdminProjectSettingsHeader
