import { useIntl } from 'react-intl'

import Flex from 'components/Flex'
import ProgressLine from 'components/ProgressLine'
import { Skeleton } from 'components/Skeleton'
import useImageLoading from 'hooks/useImageLoading'
import {
  ModuleInstanceResultFragment,
  ModuleType
} from 'types/gradoo/graphql'

import {
  ClosedLabel,
  Container,
  Count,
  Info,
  Thumb,
  Title
} from './Styles'
import { ReactComponent as LockIcon } from './icons/lock.svg'
import { ReactComponent as UnlockIcon } from './icons/unlock.svg'

type ContentSetItemProps = {
  'data-testid'?: string
  moduleInstanceResult: ModuleInstanceResultFragment
  usersTotal?: number | null
  onClick: () => void
}

const ContentSetItem: React.FC<ContentSetItemProps> = ({
  moduleInstanceResult,
  usersTotal,
  ...rest
}) => {
  const { formatMessage } = useIntl()

  let progress = 1,
    total = 1,
    textInfo = ''

  if (moduleInstanceResult.module.type === ModuleType.ProfilePage) {
    progress =
      moduleInstanceResult.profilePageSetup?.usersDelivered || 0
    total = usersTotal || 0
    textInfo = `${progress}/${total} ${formatMessage({
      id: 'Panel.contentSets.item.count.delivered'
    })}`
  } else if (
    moduleInstanceResult.module.type === ModuleType.Rankings
  ) {
    progress = moduleInstanceResult.rankingsSetup?.rankingsCount || 0
    total = progress || 1
    textInfo = `${progress} ${formatMessage({
      id: 'Panel.contentSets.item.count.rankings'
    })}`
  } else if (
    moduleInstanceResult.module.type === ModuleType.Collages
  ) {
    progress = moduleInstanceResult.collagesSetup?.photosCount || 0
    total = progress || 1
    textInfo = `${progress} ${formatMessage({
      id: 'Panel.contentSets.item.count.collages'
    })}`
  } else if (
    moduleInstanceResult.module.type === ModuleType.Reports
  ) {
    progress = moduleInstanceResult.reportsSetup?.reportsCount || 0
    total = progress || 1
    textInfo = `${progress} ${formatMessage({
      id: 'Panel.contentSets.item.count.reports'
    })}`
  } else if (moduleInstanceResult.module.type === ModuleType.Quotes) {
    progress = moduleInstanceResult.quotesSetup?.quotesCount || 0
    total = progress || 1
    textInfo = `${progress} ${formatMessage({
      id: 'Panel.contentSets.item.count.quotes'
    })}`
  } else if (
    moduleInstanceResult.module.type === ModuleType.CustomPages
  ) {
    console.log(moduleInstanceResult)
    progress =
      moduleInstanceResult.customPagesInstances?.edges.length || 0
    total = progress || 1
    textInfo = `${progress} ${formatMessage({
      id: 'Panel.contentSets.item.count.customPages'
    })}`
  }

  const percentage = (progress / total) * 100
  const isActive = moduleInstanceResult.isActive

  const { isLoading: isThumbLoading, url: thumbUrl } =
    useImageLoading(moduleInstanceResult.module.image || '')

  return (
    <Container {...rest}>
      <Thumb style={{ backgroundImage: `url('${thumbUrl}')` }}>
        {isThumbLoading && <Skeleton radius={4} />}
      </Thumb>
      <Info>
        <Flex direction="column" flex={1}>
          <Title>{moduleInstanceResult.module.name}</Title>
          <ProgressLine percentage={percentage} />
        </Flex>
        <Flex alignItems="center" justifyContent="space-between">
          <Count>{textInfo}</Count>
          <ClosedLabel>
            {isActive ? <UnlockIcon /> : <LockIcon />}
            <span>
              {formatMessage({
                id: isActive
                  ? 'Panel.contentSets.item.label.open'
                  : 'Panel.contentSets.item.label.closed'
              })}
            </span>
          </ClosedLabel>
        </Flex>
      </Info>
    </Container>
  )
}

export { default as ContentSetItemSkeleton } from './Skeleton'
export default ContentSetItem
