import { FormikContextType, useFormikContext } from 'formik'
import { useEffect, useState } from 'react'
import styled from 'styled-components'

import ColorItem from 'shared/ui/ColorItem'
import ColorSelector from 'shared/components/ColorSelector'
import Flex from 'shared/ui/Flex'
import CMYK from 'shared/entities/CMYK'
import ChiliVariables, {
  ThemeColor
} from 'shared/components/Tools/services/ChiliVariables'

import PanelContainer from '../../../PanelContainer'
import SecondLevelTitle from '../../../PanelSecondLevelTitle'
import ThirdLevelTitle from '../ThirdLevelTitle'

const variableApi = ChiliVariables.getInstance()

const StyledColorItem = styled(ColorItem)`
  padding: 8px 16px;
  border-radius: 15px;
  font-size: 14px;
`

const THEME_COLORS = [
  {
    color: new CMYK({ c: 0, m: 100, y: 57, k: 24 }),
    name: 'Color 1'
  },
  {
    color: new CMYK({ c: 0, m: 100, y: 57, k: 24 }),
    name: 'Color 2'
  }
]

const SAVED_COLORS = [
  {
    color: new CMYK({ c: 0, m: 100, y: 57, k: 24 }),
    name: 'Color 1'
  },
  {
    color: new CMYK({ c: 0, m: 100, y: 57, k: 24 }),
    name: 'Color 2'
  },
  {
    color: new CMYK({ c: 0, m: 100, y: 57, k: 24 }),
    name: 'Color 3'
  }
]

const CurrentColor = styled.div`
  width: 100%;
  min-height: 64px;
  border-radius: 5px;
  margin-top: 24px;
`

type ChangeColorPanelProps = {
  onBackButtonClick: () => void
}

const ChangeColorPanel: React.FC<ChangeColorPanelProps> = ({
  onBackButtonClick
}): JSX.Element => {
  const {
    values: formValues,
    setFieldValue
  }: FormikContextType<any> = useFormikContext()

  const [themeColors, setThemeColors] = useState<ThemeColor[]>([])

  const value = formValues!.palletteColor

  useEffect(() => {
    if (!variableApi.isInitialized) {
      return
    }

    const fetchThemeColors = async () => {
      setThemeColors(await variableApi.getThemeColors())
    }

    fetchThemeColors()
  }, [variableApi.isInitialized])
  return (
    <PanelContainer>
      <SecondLevelTitle
        titleId="Change.color"
        onClick={onBackButtonClick}
        margin={false}
      />

      <CurrentColor
        style={{
          background: new CMYK(value).toCSS()
        }}
      />

      <Flex marginTop={32} direction="column" rowGap={48}>
        <div>
          <ThirdLevelTitle titleId="Change.color.selector" />
          <ColorSelector
            value={value}
            onChange={(field, value) => {
              setFieldValue(`palletteColor.${field}`, value)
            }}
          />
        </div>

        <div>
          <ThirdLevelTitle titleId="Change.color.theme" />
          <Flex direction="column" rowGap={16}>
            {themeColors.map(({ value, displayName }, i) => (
              <StyledColorItem
                key={i}
                color={new CMYK(value).toCSS()}
                name={displayName}
                onClick={() => {
                  setFieldValue('palletteColor', value)
                }}
                isSelected={formValues!.color === value}
                padding={[8, 16]}
                selectable={true}
                editable={false}
              />
            ))}
          </Flex>
        </div>
      </Flex>
    </PanelContainer>
  )
}

export default ChangeColorPanel
