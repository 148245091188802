import { gql } from 'shared/types/gradoo';

export const ADD_PHOTO_TO_COLLAGES_ALBUM = gql(/* GraphQL */ `
  mutation AddPhotoToCollagesAlbum(
    $input: AddPhotoToCollagesAlbumInput!
  ) {
    addPhotoToCollagesAlbum(input: $input) {
      clientMutationId,
      photo {
        ...CollagesPhoto
      }
    }
  }
`);

export const REMOVE_PHOTO_FROM_COLLAGES_ALBUM = gql(/* GraphQL */ `
  mutation RemovePhotoFromCollagesAlbum(
    $input: RemovePhotoFromCollagesAlbumInput!
  ) {
    removePhotoFromCollagesAlbum(input: $input) {
      success
    }
  }
`);
