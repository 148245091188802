import { useEffect } from "react";
import { useQuery } from "@apollo/client";
import config from "config";
import useAuth from "data/gradoo/hooks/useAuth";
import { ME } from "data/gradoo/queries/auth";

export const useIntercom = () => {
  const {
    authGroupId,
    authUserGroup,
  } = useAuth()

  const {
    data: meData,
  } = useQuery(ME, {
    context: { client: 'gradoo' }
  })

  useEffect(() => {
    if (!authUserGroup || !meData?.me?.email) {
      return
    }
  
    const { user } = authUserGroup 
  
    ;(window as any).Intercom('boot', {
      api_base: 'https://api-iam.intercom.io',
      app_id: config.intercomAppId,
      name: `${user.firstName} ${user.lastName}`,
      email: meData?.me?.email,
      created_at: new Date(meData?.me?.created).valueOf()
    });
  }, [authGroupId])
}
