import { useState } from 'react'
import styled from 'styled-components'

import Flex from 'components/Flex'
import { toggleFullscreen } from 'helpers/screen'
import { ReactChildren } from 'types/global'

import FullscreenButton from './components/FullscreenButton'

const Container = styled(Flex)`
  flex-direction: column;
  height: 100%;
  background: ${({ theme }) => theme.color.base.c1};
`

const HeaderContainer = styled.header`
  width: 100%;
  padding: 12px 24px;
  background: ${({ theme }) => theme.color.base.c7};
  color: ${({ theme }) => theme.color.base.c0};
`

const Content = styled.div`
  flex: 1;
  width: 1136px;
  margin: 0 auto;
`

const Bottom = styled(Flex)`
  position: fixed;
  left: 24px;
  bottom: 24px;
`

type AdminLayoutProps = {
  renderHeaderContent: () => React.ReactNode
} & ReactChildren

const AdminLayout: React.FC<AdminLayoutProps> = ({
  renderHeaderContent,
  children
}) => {
  const [isFullscreenActive, setIsFullscreenActive] = useState(
    !!document.fullscreenElement
  )
  const updateFullscreenState = () => {
    setIsFullscreenActive(!!document.fullscreenElement)
  }

  const handleFullscreenChange = async () => {
    await toggleFullscreen()
    updateFullscreenState()
  }

  return (
    <Container height="100%" direction="column">
      <HeaderContainer>{renderHeaderContent()}</HeaderContainer>

      <Content>{children}</Content>

      <Bottom>
        <FullscreenButton
          onClick={handleFullscreenChange}
          isActive={isFullscreenActive}
        />
      </Bottom>
    </Container>
  )
}

export default AdminLayout
