import { useQuery } from '@apollo/client'
import { useIntl } from 'react-intl'
import styled from 'styled-components'

import { GET_CATEGORIES } from 'data/layoutcreator/queries/templates'
import { CategoryEntity } from 'shared/types/layoutcreator/graphql'

const Container = styled.div`
  width: 190px;
  padding: 24px 16px;
  background: ${({ theme }) => theme.color.base.c0};
  border: 1px solid ${({ theme }) => theme.color.base.c1};
  border-radius: 15px;
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.05);
`

const Header = styled.div`
  margin-bottom: 8px;
  font-weight: 600;
  font-size: 14px;
`

const List = styled.div`
  font-weight: 600;
  font-size: 12px;
  line-height: 22px;
  color: ${({ theme }) => theme.color.base.c5};
`

const Category = styled.div<{ isSelected: boolean }>`
  cursor: pointer;
  color: ${({ isSelected, theme }) =>
    isSelected && theme.color.brand_02};
`

type CategoriesProps = {
  currentCategoryId: string | null
  onCategorySelect: (categoryId: string | null) => void
}

// TODO: handle error 🤔
const Categories: React.FC<CategoriesProps> = ({
  currentCategoryId,
  onCategorySelect
}) => {
  const { loading, data } = useQuery(GET_CATEGORIES)
  const { formatMessage } = useIntl()

  return (
    <Container>
      <Header>
        {formatMessage({
          id: 'New.project.templates.categories.title'
        })}
      </Header>
      <List>
        {loading ? (
          'Loading categories...'
        ) : (
          <>
            <Category
              isSelected={currentCategoryId === null}
              onClick={() => onCategorySelect(null)}
            >
              {formatMessage({
                id: 'New.project.templates.categories.all'
              })}
            </Category>
            {data?.categories.map((category: CategoryEntity) => (
              <Category
                key={category.id}
                isSelected={category.name === currentCategoryId}
                onClick={() => onCategorySelect(category.name)}
              >
                {category.name}
              </Category>
            ))}
          </>
        )}
      </List>
    </Container>
  )
}

export default Categories
