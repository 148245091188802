import {
  NetworkStatus,
  useMutation,
  useQuery,
  useReactiveVar
} from '@apollo/client'
import { useState } from 'react'
import toast from 'react-hot-toast'
import { useIntl } from 'react-intl'
import { useLocation } from 'react-router-dom'

import Flex from 'shared/ui/Flex'
import Head from 'shared/components/Head'
import PageHeader from 'shared/components/PageHeader'
import useAuth from 'data/gradoo/hooks/useAuth'
import { START_PROJECT_EXPORT } from 'data/layoutcreator/mutations/export'
import { GET_PRODUCTION_ATTRS } from 'data/layoutcreator/queries/erp'
import { GET_PRODUCTION_EXPORTS } from 'data/layoutcreator/queries/production'
import { GET_PROJECT_META } from 'data/layoutcreator/queries/projects'
import { isExportLinkItemDisabledVar } from 'data/store/settings-export'
import IslandLayout from 'shared/layout/Island'
import { OUTPUT_TYPES } from 'screens/AdminOldProject/tabs/ExportTab/components/ProjectExportModal'
import { UploadTypes } from 'shared/types/layoutcreator/graphql'

import History from './components/History'
import { ListItem } from './components/ListItem'
import ProductionModal from './components/ProductionModal'
import { Section } from './components/Section'
import StatusModal, { Status } from './components/StatusModal'
import YouMadeIt from './components/YouMadeIt'
import { useProductionAttributes } from './hooks/use-production-attributes'

const MODAL_WIDTH = 965

const ProjectExport = () => {
  const { formatMessage } = useIntl()
  const { pathname } = useLocation()
  const projectId = pathname.split('/')[1]
  const {
    authGroupId,
    authUserGroupYear,
    authUserInstituteInternalId,
    authUserId,
    loading: loadingAuth
  } = useAuth()
  const isExportLinkItemDisabled = useReactiveVar(
    isExportLinkItemDisabledVar
  )

  const [isProductionModalOpen, setIsProductionModalOpen] =
    useState<boolean>(false)
  const [uploadType, setUploadType] = useState<UploadTypes>(
    UploadTypes.Final
  )

  const [isStatusModalOpen, setIsStatusModalOpen] =
    useState<boolean>(false)
  const [status, setStatus] = useState<Status>(Status.needApproval)

  const closeProductionModal = () => setIsProductionModalOpen(false)
  const closeStatusModal = () => setIsStatusModalOpen(false)

  const openModal = (uploadType: UploadTypes) => {
    setUploadType(uploadType)
    setIsProductionModalOpen(true)
  }

  const onSubmitSuccess = () => {
    setIsProductionModalOpen(false)
    setStatus(
      uploadType === UploadTypes.Final
        ? Status.prepareForProduction
        : Status.checkReport
    )
    setIsStatusModalOpen(true)
    isExportLinkItemDisabledVar(true)
  }

  const { data: projectMetaData, loading: loadingProjectMeta } =
    useQuery(GET_PROJECT_META, {
      variables: {
        groupId: authGroupId!,
        projectId
      },
      skip: !authGroupId
    })

  const {
    data: productionAttrs,
    loading: loadingProductionAttrs,
    refetch: refetchProductionAttrs,
    networkStatus: productionAttrsNetworkStatus
  } = useQuery(GET_PRODUCTION_ATTRS, {
    variables: {
      orderNumber: projectMetaData?.getProjectMeta?.order as string,
      year: authUserGroupYear as number,
      customerNumber: authUserInstituteInternalId as string
    },
    skip: !projectMetaData?.getProjectMeta,
    notifyOnNetworkStatusChange: true
  })

  const loadingListItems =
    loadingProjectMeta ||
    loadingProductionAttrs ||
    loadingAuth ||
    productionAttrsNetworkStatus === NetworkStatus.refetch

  const {
    preparingForProduction,
    proofCopySentToProd,
    hasApprovalDate,
    needsApproval
  } = useProductionAttributes(productionAttrs)

  if (needsApproval) {
    setStatus(Status.needApproval)
    setIsStatusModalOpen(true)
  }

  const [startExport, { loading: isStartExportLoading }] =
    useMutation(START_PROJECT_EXPORT, {
      refetchQueries: [
        {
          query: GET_PRODUCTION_EXPORTS,
          variables: {
            groupId: authGroupId!,
            projectId
          }
        }
      ]
    })

  const handleStartPreviewExport = async () => {
    await toast.promise(
      startExport({
        variables: {
          groupId: authGroupId!,
          projectId,
          outputProfile: OUTPUT_TYPES[0].apiKey,
          userId: authUserId!
        }
      }),
      {
        loading: formatMessage({
          id: 'Project.export.preview.start.export.loading'
        }),
        success: formatMessage({
          id: 'Project.export.preview.start.export.success'
        }),
        error: formatMessage({
          id: 'Project.export.preview.start.export.error'
        })
      }
    )
  }

  return (
    <>
      <IslandLayout
        showTools={false}
        Header={
          <PageHeader
            titleId="Project.export.title"
            titleProps={{
              orderNumber: projectMetaData?.getProjectMeta.order
                ? projectMetaData.getProjectMeta.order
                : null
            }}
            withBackButton
          />
        }
      >
        <Head titleId="Project.export.head.title" />
        <Flex columnGap={108} marginBottom={48}>
          <Section
            titleI18nId="Project.export.preview.title"
            textI18nId="Project.export.preview.text"
          >
            <Flex direction="column" rowGap={16}>
              <ListItem
                id="exportPreviewPdf"
                onClick={handleStartPreviewExport}
                isDisabled={isStartExportLoading}
              />
            </Flex>
          </Section>
          <Section
            titleI18nId="Project.export.production.title"
            textI18nId="Project.export.production.text"
          >
            <Flex direction="column" rowGap={16}>
              {hasApprovalDate ? (
                <YouMadeIt
                  deliveryDate={
                    productionAttrs?.getProductionAttributes
                      ?.orderDeliveryDate
                  }
                />
              ) : (
                <>
                  <ListItem
                    id="sampleProduction"
                    onClick={() => openModal(UploadTypes.Proof)}
                    isDisabled={
                      loadingListItems ||
                      proofCopySentToProd ||
                      preparingForProduction ||
                      needsApproval ||
                      isExportLinkItemDisabled
                    }
                    hasTag={
                      proofCopySentToProd ||
                      preparingForProduction ||
                      needsApproval
                    }
                    loading={loadingListItems}
                  />
                  <ListItem
                    id={
                      preparingForProduction
                        ? 'dataUploaded'
                        : 'finalProduction'
                    }
                    onClick={() => openModal(UploadTypes.Final)}
                    hasTag={preparingForProduction || needsApproval}
                    tagId={
                      preparingForProduction
                        ? 'preparingForProduction'
                        : 'needsApproval'
                    }
                    isDisabled={
                      loadingListItems ||
                      preparingForProduction ||
                      isExportLinkItemDisabled
                    }
                    loading={loadingListItems}
                  />
                </>
              )}
            </Flex>
          </Section>
        </Flex>
        <Flex>
          <Section
            titleI18nId="Project.export.history.title"
            textI18nId="Project.export.history.text"
          >
            <History
              refetchProductionAttrs={refetchProductionAttrs}
            />
          </Section>
        </Flex>
      </IslandLayout>
      <ProductionModal
        isModalOpen={isProductionModalOpen}
        closeModal={closeProductionModal}
        orderNumber={projectMetaData?.getProjectMeta?.order}
        uploadType={uploadType}
        modalWidth={MODAL_WIDTH}
        onSubmitSuccess={onSubmitSuccess}
      />
      <StatusModal
        closeModal={closeStatusModal}
        isOpen={isStatusModalOpen}
        modalWidth={MODAL_WIDTH}
        status={status}
      />
    </>
  )
}

export default ProjectExport
