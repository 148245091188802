import { useIntl } from 'react-intl'
import styled from 'styled-components'

import { ReactComponent as CloudIcon } from './icons/upload-cloud.svg'

const Container = styled.div<{ isDragActive: boolean }>`
  display: flex;
  gap: 10px;
  width: 100%;
  height: 64px;
  align-items: center;
  justify-content: center;
  border: 1px dashed ${({ theme }) => theme.color.brand_02};
  background: ${({ theme, isDragActive }) =>
    isDragActive ? theme.prevColor.primary.c1 : theme.color.base.c1};
  border-radius: 5px;
  cursor: pointer;
  color: ${({ theme }) => theme.color.brand_02};
  font-size: 14px;
  font-weight: 600;
  transition: background 0.1s ease-in-out;

  :hover {
    background: ${({ theme }) => theme.prevColor.primary.c1};
  }
`

type UploadZoneProps = {
  onClick: () => void
  isDragActive: boolean
}

const UploadZone: React.FC<UploadZoneProps> = ({
  onClick,
  isDragActive
}) => {
  const { formatMessage } = useIntl()

  return (
    <Container onClick={onClick} isDragActive={isDragActive}>
      <CloudIcon />
      <span>
        {formatMessage({
          id: `Project.settings.templates.backgrounds.modal.tabs.uploads.uploadArea`
        })}
      </span>
    </Container>
  )
}

export default UploadZone
