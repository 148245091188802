import Flex from 'shared/ui/Flex'
import { InlineSkeleton, Skeleton } from 'shared/ui/Skeleton'

const TemplatesItemSkeleton = () => {
  return (
    <Flex direction="column" alignItems="center" rowGap={8}>
      <Skeleton height={190} width={134} radius={15} />

      <InlineSkeleton height={12} width={50} />
    </Flex>
  )
}

export default TemplatesItemSkeleton
