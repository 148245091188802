import { Status } from '@liveblocks/client'
import { useIntl } from 'react-intl'
import styled from 'styled-components'

import IcomoonIcon from 'shared/ui/IcomoonIcon'
import { useStatus } from 'shared/context/project-room'
import { useFullScreenHandle } from 'shared/hooks/useFullScreen'

const Container = styled.div`
  display: flex;
  position: fixed;
  bottom: 24px;
  left: 0px;
  right: 0px;
  justify-content: center;
`

const LeftView = styled.div`
  position: absolute;
  left: 16px;
  display: flex;
  gap: 8px;
`

const ConnectedDot = styled.div`
  width: 6px;
  height: 6px;
  border-radius: 3px;
  background: ${({ color }) => color};
`

const Content = styled.div`
  display: flex;
  padding: 6px 16px;
  align-items: center;
  gap: 14px;
  border-radius: 100px;
  border: 1px solid ${({ theme }) => theme.color.base.c2};
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.05);
`

const Item = styled.div`
  display: flex;
  height: 21px;
  align-items: center;
  gap: 8px;
`

const Text = styled.div`
  color: ${({ theme }) => theme.color.base.c6};
  font-size: 12px;
  font-family: Inter;
  font-weight: 600;
`

const KeyView = styled.div`
  display: flex;
  padding: 2px 3px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  border: 0.5px solid ${({ theme }) => theme.color.base.c4};
`

const Key = styled.div`
  color: ${({ theme }) => theme.color.base.c4};
  text-align: center;
  font-size: 10px;
  font-family: Inter;
  font-weight: 600;
  line-height: 10px;
`

const Button = styled.button`
  border: none;
  background-color: transparent;
  display: flex;
  padding: 6px 16px;
  align-items: center;
  gap: 14px;
  border-radius: 100px;
  border: 1px solid ${({ theme }) => theme.color.base.c2};
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.05);

  &:hover {
    background: ${({ theme }) => theme.color.base.c2};
    border: 1px solid ${({ theme }) => theme.color.base.c4};
  }

  &:hover ${Text} {
    color: ${({ theme }) => theme.color.base.c8};
  }
`

type ShortcutItem = {
  key: string
  displayKey?: string
  nameId: string
}

const shortcutKeys: ShortcutItem[] = [
  {
    key: 'e',
    nameId: 'Pages.shortcut.reactions'
  },
  {
    key: 'c',
    nameId: 'Pages.shortcut.chat'
  },
  {
    key: 'Escape',
    displayKey: 'ESC',
    nameId: 'Pages.shortcut.escape'
  }
]

export default function ShortcutHint() {
  const { formatMessage } = useIntl()
  const {
    active: fullScreenActive,
    enter,
    exit
  } = useFullScreenHandle()

  const status = useStatus()

  const statusColorMap: { [key in Status]: string } = {
    initial: '#FCE83A',
    connecting: '#FCE83A',
    connected: '#56F000',
    reconnecting: '#FCE83A',
    disconnected: '#FF3838'
  }

  const statusTextMap: { [key in Status]: string } = {
    initial: formatMessage({ id: 'Connection.status.connecting' }),
    connecting: formatMessage({ id: 'Connection.status.connecting' }),
    connected: formatMessage({ id: 'Connection.status.connected' }),
    reconnecting: formatMessage({
      id: 'Connection.status.reconnecting'
    }),
    disconnected: formatMessage({
      id: 'Connection.status.disconnected'
    })
  }

  const onToggleFullScreen = () => {
    fullScreenActive ? exit() : enter()
  }

  return (
    <Container>
      <LeftView>
        <Content>
          <Item>
            <ConnectedDot color={statusColorMap[status]} />
            <Text>{statusTextMap[status]}</Text>
          </Item>
        </Content>
        <Button onClick={onToggleFullScreen}>
          <IcomoonIcon
            icon={fullScreenActive ? 'scale-03' : 'scale-02'}
            size={16}
          />
          <Text>
            {fullScreenActive
              ? formatMessage({
                  id: 'Pages.shortcut.closeFullScreen'
                })
              : formatMessage({
                  id: 'Pages.shortcut.goFullScreen'
                })}
          </Text>
        </Button>
      </LeftView>

      <Content>
        {shortcutKeys.map(shortcutKey => (
          <Item key={shortcutKey.key}>
            <Text>{formatMessage({ id: shortcutKey.nameId })}</Text>
            <KeyView>
              <Key>
                {shortcutKey.displayKey ||
                  shortcutKey.key.toUpperCase()}
              </Key>
            </KeyView>
          </Item>
        ))}
      </Content>
    </Container>
  )
}
