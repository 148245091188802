import { OverlayScrollbarsComponent } from 'overlayscrollbars-react'
import React, { FC } from 'react'
import styled from 'styled-components'

import 'overlayscrollbars/styles/overlayscrollbars.css'
import { PanelTypes } from 'screens/YearbookEditor/components/Tools/components/PanelContainer'
import { HEADER_HEIGHT } from 'styles/constants'
import { PANEL_Z_INDEX } from 'styles/z-index'

const PADDING = 24

interface CustomScrollbarsProps {
  children: React.ReactNode
  [key: string]: any
  panelType?: PanelTypes
  fixedHeader?: React.ReactNode
  fixedHeaderMarginBottom?: number
}

export const FixedHeader = styled.div<{
  type: PanelTypes
}>`
  position: fixed;
  z-index: 1;
  background-color: ${({ theme }) => theme.color.base.c0};
  width: 280px;
  padding-top: 24px;
  padding-bottom: 16px;
  padding-left: 16px;

  @media (max-width: 1194px) {
    width: ${({ type }) =>
      type === PanelTypes.right ? '228px' : '280px'};
  }
`

export const FixedHeaderWrapper = styled.div<{
  marginBottom?: number
}>`
  margin-bottom: ${({ marginBottom }) =>
    marginBottom ? `${marginBottom}px` : '80px'};
`

const Content = styled.div<{
  fixedHeader?: boolean
  fixedHeaderMarginBottom?: number
}>`
  padding: ${({ fixedHeader }) =>
    fixedHeader ? '0px 16px 24px 16px' : '24px 16px'};
`

const StyledScrollbars = styled(OverlayScrollbarsComponent)<{
  type?: PanelTypes
  fixedHeader?: boolean
  fixedHeaderMarginBottom?: number
}>`
  display: flex;
  flex-direction: column;
  top: ${HEADER_HEIGHT + PADDING}px;
  position: fixed;
  z-index: ${PANEL_Z_INDEX};
  width: 284px;
  height: calc(100% - ${HEADER_HEIGHT + PADDING * 2}px);
  border: 1px solid ${({ theme }) => theme.color.base.c2};
  border-radius: 5px;
  background: ${({ theme }) => theme.color.base.c0};
  overflow: hidden;
  left: ${({ type }) => type === PanelTypes.left && '24px'};
  right: ${({ type }) => type === PanelTypes.right && '24px'};

  @media (max-width: 1194px) {
    width: ${({ type }) =>
      type === PanelTypes.right ? '232px' : '284px'};
  }
`

const CustomScrollbars: FC<CustomScrollbarsProps> = ({
  panelType = PanelTypes.left,
  children,
  fixedHeader,
  fixedHeaderMarginBottom,
  ...props
}) => (
  <StyledScrollbars
    {...props}
    options={{ scrollbars: { autoHide: 'scroll' } }}
    type={panelType}
    fixedHeader={Boolean(fixedHeader)}
  >
    {fixedHeader && (
      <FixedHeaderWrapper marginBottom={fixedHeaderMarginBottom}>
        <FixedHeader type={panelType}>{fixedHeader}</FixedHeader>
      </FixedHeaderWrapper>
    )}

    <Content fixedHeader={Boolean(fixedHeader)}>{children}</Content>
  </StyledScrollbars>
)

export default CustomScrollbars
