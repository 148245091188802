import { DragOverlay as DndKitDragOverlay } from '@dnd-kit/core'
import {
  DropAnimation,
  DropAnimationOptions
} from '@dnd-kit/core/dist/components/DragOverlay/hooks/useDropAnimation'
import { useEffect, useState } from 'react'
import styled from 'styled-components'

import { ReactChildren, Seconds } from 'types/global'

import { useContentDnd } from '../..'

const DRAGGING_SCALE = 0.7
const DROPPING_FINAL_SCALE = 0.4
const DRAGGING_SCALE_TRANSITION: Seconds = 0.2

const Wrapper = styled.div<{ isOver: boolean }>`
  transform: scale(${({ isOver }) => (isOver ? DRAGGING_SCALE : 1)});
  transition: ${DRAGGING_SCALE_TRANSITION}s;
`

const animation: DropAnimationOptions = {
  keyframes: (params: any) => {
    return [
      {
        transform: `
          translate(${params.transform.initial.x}px, ${params.transform.initial.y}px) 
          scale(${DRAGGING_SCALE})
        `,
        opacity: 1
      },
      {
        transform: `
          translate(${params.transform.initial.x}px, ${params.transform.initial.y}px) 
          scale(${DROPPING_FINAL_SCALE})
        `,
        opacity: 0
      }
    ]
  }
}

const DragOverlay: React.FC<ReactChildren> = ({ children }) => {
  const { activeId, isOver } = useContentDnd()

  const [anim, setAnim] = useState<{
    dropAnimation?: DropAnimation | null
  }>({})

  useEffect(() => {
    if (isOver) {
      setAnim({
        dropAnimation: animation
      })
    } else {
      setAnim({
        dropAnimation: null
      })
    }
  }, [isOver])

  return (
    <DndKitDragOverlay {...anim}>
      {activeId !== null ? (
        <Wrapper isOver={isOver}>{children}</Wrapper>
      ) : null}
    </DndKitDragOverlay>
  )
}

export default DragOverlay
