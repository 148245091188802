import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

type RedirectKeepingUrlParamsProps = {
  to: string
}
export default function RedirectKeepingUrlParams({
  to
}: RedirectKeepingUrlParamsProps) {
  const navigate = useNavigate()
  const { search } = useLocation()

  useEffect(() => {
    navigate(to + search)
  })

  return null
}
