const TitleIcon = () => {
  const size = 16
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.306 22.5416C8.6733 21.6105 7.44 19.927 5.75655 18.2435C4.07328 16.5603 2.39002 15.3271 1.45881 14.6943C1.28649 14.9256 1.17104 15.1938 1.13693 15.4833C1.02779 16.4097 0.88522 18.478 1.15369 21.944C1.19097 22.4253 1.57508 22.8094 2.05637 22.8467C5.5224 23.1152 7.59065 22.9726 8.51705 22.8635C8.8066 22.8293 9.07475 22.7139 9.306 22.5416Z"
        fill="#A5B4FC"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.5534 10.3218C23.0072 8.868 23.5611 6.74255 22.3274 5.09785C21.9105 4.54201 21.3867 3.92644 20.73 3.26985C20.0735 2.61326 19.4579 2.08937 18.9021 1.67244C17.2573 0.43875 15.1319 0.992635 13.6781 2.44644L1.64046 14.4841C1.3685 14.7561 1.18193 15.1008 1.13693 15.4828C1.07404 16.0166 1.00005 16.9295 1 18.2705C1.00007 16.9298 1.07405 16.017 1.13693 15.4833C1.17104 15.1938 1.28649 14.9256 1.45881 14.6943C2.39002 15.3271 4.07328 16.5603 5.75655 18.2435C7.4398 19.9268 8.673 21.6101 9.30577 22.5413C9.37971 22.4862 9.44989 22.4253 9.5158 22.3594L21.5534 10.3218Z"
        fill="#4F46E5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.04116 22.9646C3.78987 22.5974 3.31805 21.9592 2.67991 21.3211C2.04103 20.6822 1.40215 20.21 1.03516 19.959C1.05943 20.5615 1.0975 21.2223 1.15338 21.9436C1.19066 22.4249 1.57476 22.809 2.05606 22.8463C2.77756 22.9022 3.43849 22.9403 4.04116 22.9646Z"
        fill="#4F46E5"
      />
    </svg>
  )
}

export default TitleIcon
