import styled from 'styled-components'

import Flex from 'shared/ui/Flex'

export const Container = styled(Flex).attrs({
  columnGap: 8
})`
  padding: 12px 16px;
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.color.base.c3};
  box-shadow: -2px 2px 1px rgba(203, 213, 225, 0.2);
  cursor: pointer;
`

export const Thumb = styled.div`
  background-size: cover;
  background-position: center;
  width: 82px;
  height: 56px;
  border-radius: 4px;
`

export const Info = styled(Flex).attrs({
  direction: 'column',
  flex: 1
})`
  align-items: justify-content;
  padding: 3px 0;
`

export const Title = styled.span`
  margin-bottom: 7px;
  font-size: 12px;
  font-weight: 600;
`

export const Count = styled.span`
  font-size: 8px;
  color: ${({ theme }) => theme.color.base.c6};
`

export const ClosedLabel = styled(Flex).attrs({
  alignItems: 'center',
  columnGap: 3
})`
  padding: 3px 6px;
  background: ${({ theme }) => theme.color.base.c4};
  border-radius: 9999px;
  font-weight: 600;
  font-size: 8px;
  color: ${({ theme }) => theme.color.base.c0};
`
