import { useQuery } from '@apollo/client'

import { CountryIDs } from 'screens/ProjectExport/components/ProductionModal/components/VerifyAddress/components/SelectCountryModal'
import {
  AuthUserGroupFragment,
  GroupsUserGroupStatusChoices,
  Maybe
} from 'types/gradoo/graphql'

import { GROUP_USERS, ME } from '../queries/auth'

type Phone = {
  number: string
  country: CountryIDs
}

type UseAuthData = {
  authGroupId?: Maybe<string>
  authUserGroup?: Maybe<AuthUserGroupFragment>
  authUserGroupYear?: Maybe<number>
  authUserInstituteInternalId?: Maybe<string>
  authUserId?: Maybe<string>
  authUserPhone: Phone
  loading: boolean
  error?: Error
}

const useAuth = (): UseAuthData => {
  const {
    loading: meLoading,
    error: meError,
    data: meData
  } = useQuery(ME, {
    context: { client: 'gradoo' }
  })

  const authUserId = meData?.me?.id
  const authUserPhone: Phone = {
    number: meData?.me?.phone ?? '',
    country: (meData?.me?.phoneCountryCode as CountryIDs) ?? ''
  }

  const {
    loading: groupUsersLoading,
    error: groupUsersError,
    data: groupUsersData
  } = useQuery(GROUP_USERS, {
    skip: !authUserId,
    variables: {
      user: authUserId,
      status: GroupsUserGroupStatusChoices.Accepted
    },
    context: { client: 'gradoo' }
  })

  const authUserGroup = groupUsersData?.userGroups?.edges?.[0]?.node

  const authGroupId = authUserGroup?.group?.id
  const authUserGroupYear = authUserGroup?.group?.year
  const authUserInstituteInternalId =
    authUserGroup?.group?.institute?.internalId

  return {
    authGroupId,
    authUserGroup,
    authUserGroupYear,
    authUserInstituteInternalId,
    authUserId,
    authUserPhone,
    loading: meLoading || groupUsersLoading,
    error: meError || groupUsersError
  }
}

export default useAuth
