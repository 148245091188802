import { PublisherInterface } from '@chili-publish/publisher-interface'

import { Maybe } from 'graphql/jsutils/Maybe'

type Callback = (params: any) => void
type Event = Record<string, Callback[]>

type InitOptions = {
  publisherConnector: PublisherInterface
}

export enum BasicEventTypes {
  initialized = 'initialized'
}

class BasicService<EventTypes extends string = string> {
  protected eventCallbacks: Partial<
    Record<EventTypes | BasicEventTypes, Event>
  > = {}
  protected publisherConnector: Maybe<PublisherInterface>
  private _isInitialized = false

  protected static selectedFrame = 'document.selectedFrame'

  get isInitialized() {
    return this._isInitialized
  }

  protected set isInitialized(value: boolean) {
    this._isInitialized = value
  }

  init({ publisherConnector }: InitOptions) {
    if (!this.isInitialized) {
      this.publisherConnector = publisherConnector
      this.isInitialized = true

      this.triggerListeners(BasicEventTypes.initialized)
    }
  }

  async destroy() {
    this.isInitialized = false
    this.publisherConnector = null
  }

  private generateId(event: EventTypes | BasicEventTypes): string {
    const index = Object.entries(
      this.eventCallbacks[event] || {}
    ).length
    return `${event}-${index}`
  }

  addListener(
    event: EventTypes | BasicEventTypes,
    callback: Callback
  ): string {
    if (!this.eventCallbacks[event]) {
      this.eventCallbacks[event] = {}
    }

    const id = this.generateId(event)

    ;(this.eventCallbacks[event] as Event)[id] = [callback]

    return id
  }

  removeListener(id: string) {
    for (const [eventType, event] of Object.entries(
      this.eventCallbacks
    )) {
      for (const eventId of Object.keys(event as Event)) {
        if (eventId === id) {
          ;(this.eventCallbacks[eventType as EventTypes] as Event)[
            eventId
          ] = []
          return
        }
      }
    }
  }

  protected triggerListeners(
    event: EventTypes | BasicEventTypes,
    params?: any
  ) {
    if (!this.eventCallbacks[event]) {
      return
    }

    Object.values(this.eventCallbacks[event]!).forEach(callbacks =>
      callbacks.forEach(callback => {
        callback(params)
      })
    )
  }
}

export default BasicService
