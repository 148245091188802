import { gql } from 'types/gradoo'

export const RANKINGS_QUESTIONS = gql(/* GraphQL */ `
  query RankingsQuestions(
    $after: String
    $first: Int
    $moduleInstance: ID
  ) {
    rankingsQuestions(
      after: $after
      first: $first
      moduleInstance: $moduleInstance
    ) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          ...RankingsQuestion
        }
      }
    }
  }
`)
