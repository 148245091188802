export enum Panels {
  contentSets = 'contentSets',
  collagesList = 'collagesList',
  collage = 'collage',
  quotes = 'quotes',
  rankings = 'rankings',
  preword = 'preword',
  profilePage = 'profilePage',
  profileDetails = 'profileDetails',
  profileAnswerQuestion = 'profileAnswerQuestion',
  reports = 'reports',
  report = 'report',
  customPages = 'customPages',
  customPage = 'customPage'
}

export enum HeaderTypes {
  firstLevelTitle = 'firstLevelTitle',
  secondLevelTitle = 'secondLevelTitle',
  pagination = 'pagination'
}

export type PushWithTitle = (params: {
  panel: Panels
  title?: string
  props?: any
}) => void

export type PanelProps = {
  push: PushWithTitle
}
