import { useIntl } from 'react-intl'
import styled from 'styled-components'

import Button from 'components/Button_2'
import Flex from 'components/Flex'

const Title = styled.div`
  font-size: 18px;
  font-weight: 700;
`

type PanelSecondLevelTitleProps = {
  onClick: () => void
  titleId: string
  props?: any
  margin?: boolean
}

const PanelSecondLevelTitle = ({
  onClick,
  titleId,
  props,
  margin = true
}: PanelSecondLevelTitleProps): JSX.Element => {
  const { formatMessage } = useIntl()

  return (
    <Flex
      columnGap={8}
      alignItems="center"
      marginBottom={margin ? 48 : 0}
    >
      <Button
        onPress={onClick}
        size="lg"
        intent="sub-action"
        textId={'Tools.add.page'}
        iconName="arrow_narrow_left"
        icon="only"
      />
      <Title>{formatMessage({ id: titleId }, props)}</Title>
    </Flex>
  )
}

export default PanelSecondLevelTitle
