import {
  DndContext,
  DragEndEvent,
  DragMoveEvent,
  DragOverEvent,
  DragStartEvent,
  MouseSensor,
  UniqueIdentifier,
  useSensor,
  useSensors
} from '@dnd-kit/core'
import { createContext, useContext, useRef, useState } from 'react'

import { useMousePosition } from 'shared/hooks/useMousePosition'
import { Coordinates, Percent, ReactChildren } from 'shared/types/global'
import { QuoteFragment } from 'shared/types/gradoo/graphql'

import { useCreateEditorContent } from './hooks/useCreateEditorContent'
import { snapLeftTopCornerToCursor } from './modifiers/snapLeftTopCornerToCursor'
import { Ids, ProfileQuestionWithAnswer } from './types'

const Context = createContext<{
  isDragging: boolean
  activeId: any
  isOver: boolean
  draggingContent: any
  droppableRef: any
}>({
  isDragging: false,
  activeId: null,
  isOver: false,
  draggingContent: {},
  droppableRef: null
})

export const useContentDnd = () => useContext(Context)

const ContentDndProvider: React.FC<{} & ReactChildren> = ({
  children
}) => {
  const mouseSensor = useSensor(MouseSensor, {
    activationConstraint: {
      delay: 100,
      tolerance: 10
    }
  })

  const sensors = useSensors(mouseSensor)

  const [activeId, setActiveId] = useState<UniqueIdentifier | null>(
    null
  )
  const [isDragging, setIsDragging] = useState(false)
  const [isOver, setIsOver] = useState(false)

  const [quote, setQuote] = useState<QuoteFragment | null>(null)
  const [imageUrl, setImageUrl] = useState<string | null>(null)
  const [profile, setProfile] = useState<any | null>(null)
  const [profileQuestion, setProfileQuestion] =
    useState<ProfileQuestionWithAnswer | null>(null)
  const [profileComment, setProfileComment] = useState<any | null>(
    null
  )

  const droppableRef = useRef<HTMLDivElement | null>(null)
  const mousePosition = useMousePosition()

  const value = {
    activeId,
    isDragging,
    isOver,
    draggingContent: {
      quote,
      imageUrl,
      profile,
      profileQuestion,
      profileComment,

      setQuote,
      setImageUrl,
      setProfile,
      setProfileQuestion,
      setProfileComment
    },
    droppableRef
  }

  const {
    addQuote,
    addImage,
    addProfileQuestion,
    addProfileComment,
    addProfile
  } = useCreateEditorContent()

  const handleDragStart = (event: DragStartEvent) => {
    setIsDragging(true)
    setActiveId(event.active.id)
  }

  const handleDragOver = (event: DragOverEvent) => {
    if (event.over?.id === Ids.editor) {
      setIsOver(true)
    }
  }

  const handleDragMove = (event: DragMoveEvent) => {
    if (event.over?.id !== Ids.editor) {
      setIsOver(false)
    }
  }

  const getRelativePosition = (
    event: DragEndEvent
  ): Coordinates<Percent> | null => {
    if (!droppableRef.current) {
      return null
    }

    const droppableX = droppableRef.current.getBoundingClientRect().x
    const droppableY = droppableRef.current.getBoundingClientRect().y
    const droppableWidth =
      droppableRef.current.getBoundingClientRect().width
    const droppableHeight =
      droppableRef.current.getBoundingClientRect().height

    const dropX = mousePosition.x
    const dropY = mousePosition.y

    const relativeX = dropX - droppableX
    const relativeY = dropY - droppableY

    return {
      x: relativeX / (droppableWidth / 100),
      y: relativeY / (droppableHeight / 100)
    }
  }

  const handleDragEnd = async (event: DragEndEvent) => {
    setIsDragging(false)
    setActiveId(null)

    const position = getRelativePosition(event)

    if (event.over?.id === Ids.editor && position) {
      switch (event.active.data.current?.type) {
        case Ids.quote:
          await addQuote({
            position,
            text: quote!.quote,
            width: 30
          })
          break
        case Ids.image:
          await addImage({
            position,
            url: imageUrl!
          })
          break
        case Ids.profileQuestion:
          await addProfileQuestion({
            position,
            question: profileQuestion!
          })
          break
        case Ids.profileComment:
          await addProfileComment({
            position,
            comment: profileComment!
          })
          break
        case Ids.profile:
          addProfile({
            position,
            profile
          })
      }
    }
  }

  return (
    <Context.Provider value={value}>
      <DndContext
        onDragStart={handleDragStart}
        onDragOver={handleDragOver}
        onDragEnd={handleDragEnd}
        onDragMove={handleDragMove}
        modifiers={[snapLeftTopCornerToCursor]}
        sensors={sensors}
      >
        {children}
      </DndContext>
    </Context.Provider>
  )
}

export { default as EditorSimulation } from './components/EditorSimulation'
export { default as Draggable } from './components/Draggable'
export { default as DragOverlay } from './components/DragOverlay'
export { Context as DndContext }
export default ContentDndProvider
