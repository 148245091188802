import { useMutation } from '@apollo/client'
import {
  Dispatch,
  FormEvent,
  SetStateAction,
  useEffect,
  useState
} from 'react'
import toast from 'react-hot-toast'
import { useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'

import Button, { Intents } from 'shared/ui/Button'
import Flex from 'shared/ui/Flex'
import { FormError } from 'shared/ui/FormInput'
import StepsBar from 'shared/ui/StepsBar'
import Templates from 'shared/components/Templates'
import Categories from 'shared/components/TemplatesCategories'
import useAuth from 'data/gradoo/hooks/useAuth'
import { CREATE_NEW_PROJECT } from 'data/layoutcreator/mutations/projects'
import { GET_PROJECTS } from 'data/layoutcreator/queries/projects'
import { TemplateEntity } from 'shared/types/layoutcreator/graphql'

import { Steps } from '../../index'
import { LocalStorageKeys, NewProjectModalStepIds } from '../../types'
import FooterContainer from '../FooterContainer'
import StepContent from '../StepContent'
import StepFooter from '../StepFooter'
import StepForm from '../StepForm'
import { clearNewProjectLocalStorageData } from './utils'

type TemplateProps = {
  setStep: Dispatch<SetStateAction<NewProjectModalStepIds>>
  closeModal: () => void
}

const Template = ({ setStep, closeModal }: TemplateProps) => {
  const navigate = useNavigate()
  const { formatMessage } = useIntl()
  const [templateError, setTemplateError] = useState<string>('')
  const [currentCategoryId, setCurrentCategoryId] = useState<
    string | null
  >(null)

  const { authGroupId } = useAuth()

  const [createProject, { data, loading, error }] = useMutation(
    CREATE_NEW_PROJECT,
    {
      refetchQueries: [
        { query: GET_PROJECTS, variables: { groupId: authGroupId } }
      ]
    }
  )
  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault()
    if (!selectedTemplateId) {
      setTemplateError(formatMessage({ id: 'Template.select.error' }))
      // setStep
      return false
    }

    try {
      await createProject({
        variables: {
          templatePath: selectedTemplateId,
          groupId: authGroupId as string,
          projectName: localStorage.getItem(
            LocalStorageKeys.title
          ) as string,
          coverType: localStorage.getItem(
            LocalStorageKeys.coverType
          ) as string
        }
      })
    } catch (err) {
      console.log(err)
      toast.error(
        formatMessage({ id: 'New.project.creation.failed' })
      )
    }

    closeModal()
  }

  useEffect(() => {
    if (data?.createNewProject) {
      clearNewProjectLocalStorageData()
      navigate('/')
    } else if (error) {
      console.error(error)
    }
  }, [data])

  const [selectedTemplateId, setSelectedTemplateId] =
    useState<string>()

  return (
    <StepForm onSubmit={handleSubmit}>
      <StepContent>
        <Flex width="100%" columnGap={64} alignItems="flex-start">
          <Categories
            currentCategoryId={currentCategoryId}
            onCategorySelect={setCurrentCategoryId}
          />
          <Templates
            setSelectedTemplateId={setSelectedTemplateId}
            setTemplateError={setTemplateError}
            selectedTemplateId={selectedTemplateId}
            filter={(template: TemplateEntity) =>
              currentCategoryId
                ? template.categoryId === currentCategoryId
                : true
            }
          />
          {templateError && (
            <Flex justifyContent="center" marginTop={16}>
              <FormError>{templateError}</FormError>
            </Flex>
          )}
        </Flex>
      </StepContent>
      <FooterContainer>
        <Button
          labelId="New.project.go.back"
          type="button"
          onClick={() => {
            if (Steps.template.prevStepId) {
              setStep(Steps.template.prevStepId)
            }
          }}
          intent={Intents.neutral}
        />
        <StepsBar<NewProjectModalStepIds>
          stepId={NewProjectModalStepIds.template}
          steps={Steps}
          setStep={setStep}
        />
        <StepFooter
          stepId={NewProjectModalStepIds.template}
          isLoading={loading}
          setStep={setStep}
        />
      </FooterContainer>
    </StepForm>
  )
}

export default Template
