import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import { ReactComponent as Logo } from 'shared/assets/icons/logo-admin.svg'
import Button from 'shared/ui/Button_2'
import Flex from 'shared/ui/Flex'
import { logout } from 'shared/utils/auth'

import HeaderGoBackButton from '../../../../shared/ui/HeaderGoBackButton'
import Menu from './components/Menu'

const Side = styled(Flex)`
  width: 300px;
`

const Left = styled(Side)`
  column-gap: 16px;
  justify-content: flex-start;
`

const Right = styled(Side)`
  justify-content: flex-end;
`

const Center = styled(Flex)``

const TabsHeader = () => {
  const navigate = useNavigate()

  const handleGoBack = () => {
    navigate(-1)
  }

  return (
    <Flex justifyContent="space-between" alignItems="center">
      <Left>
        <HeaderGoBackButton onBack={handleGoBack} />
        <Logo />
      </Left>

      <Center>
        <Menu />
      </Center>

      <Right>
        <Button
          intent="secondary-contrast"
          size="sm"
          textId="Log out from admin"
          onPress={logout}
        />
      </Right>
    </Flex>
  )
}

export default TabsHeader
