import {
  useLazyQuery,
  useMutation,
  useReactiveVar
} from '@apollo/client'
import { FC, useEffect, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components'

import { START_PAGE_EXPORT } from 'data/layoutcreator/mutations/admin'
import { GET_EXPORT_DATA } from 'data/layoutcreator/queries/admin'
import {
  isPagePreviewModalOpenVar,
  pagePreviewNumberVar,
  pagePreviewPageIdVar,
  pagePreviewTypeVar,
  pagePreviewUrlVar
} from 'data/store/page-preview-modal'
import { ExportStatuses } from 'screens/AdminOldProject/types'
import AnimatedModal from 'shared/components/modals/AnimatedModal'
import useInterval from 'shared/hooks/useInterval'
import Button from 'shared/ui/Button_2'

import { downloadPDF } from './utils'

const Content = styled.div<{ isCoverPreview?: boolean }>`
  width: ${({ isCoverPreview }) => (isCoverPreview ? 896 : 448)}px;
  margin: auto;
  @media only screen and (max-width: 1024px) {
    width: ${({ isCoverPreview }) => isCoverPreview && '100%'};
  }
`

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
`

const PreviewImage = styled.img`
  width: 100%;
`

const PageInfo = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 12px;
`
const PageNumber = styled.p`
  margin: 0;
  color: ${({ theme }) => theme.color.base.c6};
`

const PageType = styled.p`
  color: ${({ theme }) => theme.color.base.c6};
  margin: 0;
  &:first-letter {
    text-transform: uppercase;
  }
`

const ErrorText = styled.p`
  color: ${({ theme }) => theme.color.error};
  text-align: right;
  margin: 0;
  margin-top: 4px;
  font-size: 12px;
`

interface PagePreviewModalProps {
  projectId: string
  groupId: string
  isCoverPreview?: boolean
}

// Note: chili is not always stable so we need to check error case a few times
const GET_DOCUMENT_CHECK_AMOUNT = 2

const PagePreviewModal: FC<PagePreviewModalProps> = ({
  projectId,
  groupId,
  isCoverPreview
}) => {
  const { formatMessage } = useIntl()
  const isOpen = useReactiveVar(isPagePreviewModalOpenVar)
  const pagePreviewUrl = pagePreviewUrlVar()
  const pageNumber = pagePreviewNumberVar()
  const pageType = pagePreviewTypeVar()
  const pageId = pagePreviewPageIdVar()
  const closeModal = () => isPagePreviewModalOpenVar(false)
  const [status, setStatus] = useState<ExportStatuses>(
    ExportStatuses.notStarted
  )
  const [error, setError] = useState<string>('')
  const [taskId, setTaskId] = useState<string>('')
  const getExportCounter = useRef(0)

  const [startExport, { loading }] = useMutation(START_PAGE_EXPORT)
  const [getExportData] = useLazyQuery(GET_EXPORT_DATA, {
    fetchPolicy: 'network-only'
  })

  useEffect(() => {
    if (status === ExportStatuses.failed && !loading) {
      setError(formatMessage({ id: 'Page.preview.export.fail' }))
    } else {
      setError('')
    }
  }, [status, loading, formatMessage])

  const changeStatus = (data?: {
    isStarted: boolean
    isFinished: boolean
    isSucceeded: boolean
  }) => {
    data?.isStarted && setStatus(ExportStatuses.inProgress)
    if (data?.isFinished) {
      setStatus(
        data?.isSucceeded
          ? ExportStatuses.completed
          : ExportStatuses.failed
      )
    }
  }

  useInterval(async () => {
    if (!taskId || status !== ExportStatuses.inProgress) {
      return
    }

    const { data } = await getExportData({
      variables: {
        taskId
      }
    })

    if (!data?.getExportDocument.isFinished) {
      return
    }

    getExportCounter.current += 1

    if (data?.getExportDocument.isSucceeded) {
      getExportCounter.current = 0
      changeStatus(data?.getExportDocument)
      data.getExportDocument.url &&
        downloadPDF(data.getExportDocument.url)
    }

    if (
      !data?.getExportDocument.isSucceeded &&
      getExportCounter.current > GET_DOCUMENT_CHECK_AMOUNT
    ) {
      getExportCounter.current = 0
      changeStatus(data?.getExportDocument)
    }
  }, 3000)

  const onGeneratePDFPreview = async () => {
    const { data } = await startExport({
      variables: {
        groupId,
        projectId,
        documentId: pageId,
        outputProfile: 'preview',
        isCoverPage: Boolean(isCoverPreview)
      }
    })
    setTaskId(data?.startExportDocument?.taskId || '')
    changeStatus(data?.startExportDocument)
  }

  const pageText = formatMessage(
    { id: 'Page.preview.pageNumber' },
    {
      number: pageNumber
    }
  )

  return (
    <AnimatedModal
      isOpen={isOpen}
      closeModal={closeModal}
      width={isCoverPreview ? '90%' : 700}
      maxWidth={940}
    >
      <Content isCoverPreview={isCoverPreview}>
        <PreviewImage
          src={pagePreviewUrl.replace('type=medium', 'type=highest')}
          alt="Preview"
        />
        <PageInfo>
          {!isCoverPreview ? (
            <PageNumber>{pageText}</PageNumber>
          ) : null}
          {pageType && (
            <PageType>
              {formatMessage({
                id: `Page.type.${pageType.toLowerCase()}`
              })}
            </PageType>
          )}
        </PageInfo>
      </Content>

      <Footer>
        <Button
          intent="secondary-base"
          textId="Add.page.cancel"
          onPress={closeModal}
          size="sm"
        />

        <Button
          icon="left"
          iconName="printer"
          intent="sub-action"
          textId="Page.preview.generatePdf"
          onPress={onGeneratePDFPreview}
          minWidth={240}
          loading={status === ExportStatuses.inProgress || loading}
          size="sm"
        />
      </Footer>
      <ErrorText>{error}</ErrorText>
    </AnimatedModal>
  )
}

export default PagePreviewModal
