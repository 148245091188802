import styled from 'styled-components'

const PagesContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
  overflow: auto;
  max-height: 400px;
`

export default PagesContainer
