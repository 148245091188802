import { useMutation, useQuery } from '@apollo/client'
import { useState } from 'react'
import React from 'react'
import { useIntl } from 'react-intl'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'

import Button from 'components/Button_2'
import Flex from 'components/Flex'
import SectionHeader from 'components/SectionHeader'
import { InlineSkeleton } from 'components/Skeleton'
import useAuth from 'data/gradoo/hooks/useAuth'
import { CHANGE_PROJECT_CURRENT_TEMPLATE } from 'data/layoutcreator/mutations/projects'
import { GET_PROJECT_TEMPLATES } from 'data/layoutcreator/queries/projects'
import { replaceSpaces } from 'helpers/string'
import Section from 'screens/ProjectSettings/components/Section'

import ChangeTemplateModal from './components/ChangeTemplateModal'
import ProjectTemplate from './components/Template'
import ProjectTemplateSkeleton from './components/Template/Skeleton'

const Separator = styled.div`
  height: 1px;
  border-radius: 1px;
  background: ${({ theme }) => theme.color.base.c3};
`

const ProjectTemplateSection: React.FC = () => {
  const { formatMessage } = useIntl()
  const { pathname } = useLocation()
  const projectId = pathname.split('/')[1]
  const { authGroupId: groupId } = useAuth()

  const [isChangeTemplateModalOpen, setIsChangeTemplateModalOpen] =
    useState(false)

  const { data: templates, loading: templatesLoading } = useQuery(
    GET_PROJECT_TEMPLATES,
    {
      variables: {
        projectId: replaceSpaces(projectId),
        groupId: groupId!
      }
    }
  )

  const [changeCurrentTemplate] = useMutation(
    CHANGE_PROJECT_CURRENT_TEMPLATE,
    {
      refetchQueries: [
        {
          query: GET_PROJECT_TEMPLATES,
          variables: { projectId: replaceSpaces(projectId), groupId }
        }
      ]
    }
  )

  const getSortedTemplates = () => {
    if (!templates?.getProjectTemplates) {
      return []
    }

    return [...templates?.getProjectTemplates].sort((a, b) =>
      a.isCurrent ? -1 : 0
    )
  }

  return (
    <Section
      Header={
        <SectionHeader
          titleId="Project.settings.templates.title"
          marginTop={62}
          renderAppend={() => (
            <Button
              textId={'Project.settings.templates.change'}
              onPress={() => setIsChangeTemplateModalOpen(true)}
              intent="sub-action"
            />
          )}
        />
      }
    >
      <Flex direction="column" rowGap={48}>
        {templatesLoading
          ? new Array(2).fill(null).map((_, i) => (
              <React.Fragment key={i}>
                <ProjectTemplateSkeleton
                  key={i}
                  isCurrent={i === 0}
                />
                <InlineSkeleton height={1} />
              </React.Fragment>
            ))
          : getSortedTemplates().map(
              ({ path, name, previewUrl, isCurrent }) => (
                <React.Fragment key={path}>
                  <ProjectTemplate
                    path={path}
                    name={name}
                    previewUrl={previewUrl}
                    isCurrent={isCurrent}
                  />
                  <Separator />
                </React.Fragment>
              )
            )}
      </Flex>

      <ChangeTemplateModal
        isOpen={isChangeTemplateModalOpen}
        closeModal={() => setIsChangeTemplateModalOpen(false)}
        onChange={(templatePath: string) => {
          changeCurrentTemplate({
            variables: {
              groupId: groupId!,
              projectId: replaceSpaces(projectId),
              templatePath
            }
          })
        }}
      />
    </Section>
  )
}

export default ProjectTemplateSection
