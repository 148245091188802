import { useEffect, useMemo, useState } from 'react'
import Draggable from 'react-draggable'
import { useIntl } from 'react-intl'
import styled, { css } from 'styled-components'

import Flex from 'shared/ui/Flex'
import SliderInput from 'shared/ui/SliderInput'
import Spinner from 'shared/ui/Spinner'
import { borderRadius } from 'shared/helpers/styles'
import ChiliImages, {
  ChiliImagesEventTypes
} from 'shared/components/Tools/services/ChiliImages'
import { DocumentImageFrame } from 'shared/components/Tools/services/types'
import { ImageFrame } from 'shared/components/Tools/types'

import { ReactComponent as CropIcon } from '../../icons/crop.svg'

const imagesApi = ChiliImages.getInstance()

type ImageCropProps = {
  frame: ImageFrame
}

const PREVIEW_ACTIVE_BORDER_WIDTH = 4
const PREVIEW_ACTIVE_BORDER_RADIUS = 5
const PREVIEW_CONTAINER_HEIGHT = 144
const PREVIEW_ACTIVE_HEIGHT =
  PREVIEW_CONTAINER_HEIGHT - PREVIEW_ACTIVE_BORDER_WIDTH * 2
const PREVIEW_CONTAINER_WIDTH = 250
const PREVIEW_ACTIVE_MAX_WIDTH = 210
const FILTER_MIN_WIDTH =
  (PREVIEW_CONTAINER_WIDTH - PREVIEW_ACTIVE_MAX_WIDTH) / 2
const DRAGGABLE_BOUNDS_OFFSET = 5

const previewContainerPseudoBorder = css`
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  height: ${PREVIEW_ACTIVE_BORDER_WIDTH}px;
  background: ${({ theme }) => theme.color.base.c0};
  z-index: 1;
`

const PreviewContainer = styled.div<{ image: string }>`
  width: 100%;
  height: ${PREVIEW_CONTAINER_HEIGHT}px;
  border-radius: 5px;
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  overflow: hidden;
  display: flex;

  &::before {
    ${previewContainerPseudoBorder}
    top: 0;
  }

  &::after {
    ${previewContainerPseudoBorder}
    bottom: 0;
  }
`

const filterStyles = css`
  position: absolute;
  height: 100%;
  min-width: ${FILTER_MIN_WIDTH}px;
  background: rgba(15, 23, 42, 0.8);
  transition: 0.3s;
  z-index: 2;
`

const filterPseudoBorderStyles = css`
  content: '';
  position: absolute;
  background: ${({ theme }) => theme.color.base.c0};
  top: 0;
  bottom: 0;
  width: ${PREVIEW_ACTIVE_BORDER_WIDTH}px;
`

const RightFilter = styled.div`
  ${filterStyles}
  right: 0;

  &::after {
    ${filterPseudoBorderStyles}
    left: 0;
    ${borderRadius(PREVIEW_ACTIVE_BORDER_RADIUS, 'right')}
  }
`

const LeftFilter = styled.div`
  ${filterStyles}
  left: 0;

  &::after {
    ${filterPseudoBorderStyles}
    right: 0;
    ${borderRadius(PREVIEW_ACTIVE_BORDER_RADIUS, 'left')}
  }
`

const PreviewActive = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  height: ${PREVIEW_ACTIVE_HEIGHT}px;
  transition: 0.3s;
`

const PreviewImage = styled.img`
  width: auto;
  transition: height 0.3s, transform 0.1s;
  user-drag: none;
  user-select: none;
  cursor: move;
`

const SpinnerWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

const Error = styled.span`
  width: 100%;
  text-align: center;
  font-size: 14px;
`

const ImageCrop: React.FC<ImageCropProps> = ({ frame }) => {
  const [zoom, setZoom] = useState(
    imagesApi.getFrame(frame.id)!.zoomLevel
  )
  const [url, setUrl] = useState<string | null>(null)
  const [previewPosition, setPreviewPosition] = useState({
    x: 0,
    y: 0
  })
  const [previewImageHeight, setPreviewImageHeight] =
    useState<number>(0)
  const [previewImageRatio, setPreviewImageRatio] = useState(0)
  const [frameRatio, setFrameRatio] = useState<number>(
    Number.parseFloat(frame.height!) / Number.parseFloat(frame.width!)
  )
  const [loading, setLoading] = useState(false)
  // Indicates whether we need to load a new preview for created/updated image frames
  const [isImageLoaded, setIsImageLoaded] = useState(false)

  const [error, setError] = useState(false)
  const { formatMessage } = useIntl()

  const getPreviewActiveWidth = (frameRatio: number) =>
    Math.min(
      PREVIEW_ACTIVE_HEIGHT / frameRatio,
      PREVIEW_ACTIVE_MAX_WIDTH
    )

  const previewActiveWidth = useMemo(
    () => getPreviewActiveWidth(frameRatio),
    [frameRatio]
  )

  const previewImageWidth = useMemo(
    () => previewImageHeight * previewImageRatio,
    [previewImageHeight, previewImageRatio]
  )

  const loadPreview = () => {
    setLoading(true)
    setUrl(imagesApi.getFrame(frame.id)?.previewURL as string)

    setLoading(false)
  }

  useEffect(() => {
    if ((frame as any).imgLoaded === 'true') {
      loadPreview()
    }
  }, [frame.id])

  useEffect(() => {
    // We cannot load preview before image is loaded inside the frame
    if (isImageLoaded) {
      loadPreview()
    }
  }, [isImageLoaded])

  const handleZoomChange = (zoom: number) => {
    imagesApi.changeZoomLevel(zoom)
    setZoom(zoom)
  }

  const updatePreview = (frame: DocumentImageFrame) => {
    const frameHeight = frame?.height || 0
    const frameWidth = frame?.width || 0

    const frameRatio = frameHeight / frameWidth
    const frameToImageRatio = frameHeight / frame.imageHeight
    const height = PREVIEW_ACTIVE_HEIGHT / frameToImageRatio

    const position = {
      x: (frame.positionX * getPreviewActiveWidth(frameRatio)) / 100,
      y: frame.positionY * (PREVIEW_ACTIVE_HEIGHT / 100)
    }

    setPreviewImageHeight(height)
    setFrameRatio(frameRatio)
    setPreviewImageRatio(frame.ratio)
    setPreviewPosition(position)
  }

  useEffect(() => {
    const updateListener = imagesApi.addListener(
      ChiliImagesEventTypes.frameUpdated,
      ({ frame }: { frame: DocumentImageFrame }) => {
        updatePreview(frame)
      }
    )

    const creationListener = imagesApi.addListener(
      ChiliImagesEventTypes.frameCreated,
      () => {
        setIsImageLoaded(false)
        setLoading(true)
      }
    )

    const replacementListener = imagesApi.addListener(
      ChiliImagesEventTypes.imageReplaced,
      () => {
        setIsImageLoaded(false)
        setLoading(true)
      }
    )

    const imageLoadedListener = imagesApi.addListener(
      ChiliImagesEventTypes.imageFrameLoaded,
      () => {
        setIsImageLoaded(true)
        imagesApi.removeFrameLoadedListener()
      }
    )

    return () => {
      imagesApi.removeListener(updateListener)
      imagesApi.removeListener(creationListener)
      imagesApi.removeListener(replacementListener)
      imagesApi.removeListener(imageLoadedListener)
    }
  }, [])

  useEffect(() => {
    const documentFrame = imagesApi.getFrame(frame.id)

    if (!documentFrame) {
      return
    }

    updatePreview(documentFrame)
  }, [frame.id])

  const handleDrag = (_: any, params: { x: number; y: number }) => {
    const newPosition = {
      x: params.x,
      y: params.y
    }

    setPreviewPosition(newPosition)

    const onePercentX = PREVIEW_ACTIVE_HEIGHT / frameRatio / 100
    const onePercentY = PREVIEW_ACTIVE_HEIGHT / 100

    imagesApi.changePosition({
      x: newPosition.x / onePercentX,
      y: newPosition.y / onePercentY
    })
  }

  const previewFilterWidth = useMemo(
    () => (PREVIEW_CONTAINER_WIDTH - previewActiveWidth) / 2,
    [previewActiveWidth]
  )

  const draggableBounds = useMemo(
    () => ({
      left: -previewImageWidth + DRAGGABLE_BOUNDS_OFFSET,
      right: previewActiveWidth - DRAGGABLE_BOUNDS_OFFSET,
      top: -previewImageHeight + DRAGGABLE_BOUNDS_OFFSET,
      bottom: PREVIEW_ACTIVE_HEIGHT - DRAGGABLE_BOUNDS_OFFSET
    }),
    [previewImageHeight, previewImageWidth, previewActiveWidth]
  )

  return (
    <Flex marginTop={30} direction="column" rowGap={20}>
      <PreviewContainer image={url || ''}>
        <PreviewActive style={{ width: previewActiveWidth }}>
          {loading ? (
            <SpinnerWrapper>
              <Spinner />
            </SpinnerWrapper>
          ) : error ? (
            <Error>
              {formatMessage({
                id: 'Panel.image.crop.preview.loading.error'
              })}
            </Error>
          ) : (
            url !== null && (
              <Draggable
                position={previewPosition}
                onDrag={handleDrag}
                bounds={draggableBounds}
              >
                <PreviewImage
                  key={url}
                  src={url || ''}
                  alt="preview image"
                  style={{
                    height: `${previewImageHeight}px`
                  }}
                />
              </Draggable>
            )
          )}
        </PreviewActive>

        <LeftFilter style={{ width: previewFilterWidth }} />
        <RightFilter style={{ width: previewFilterWidth }} />
      </PreviewContainer>

      <Flex justifyContent="space-between" columnGap={14}>
        <CropIcon />
        <SliderInput value={zoom} onChange={handleZoomChange} />
      </Flex>
    </Flex>
  )
}

export default ImageCrop
