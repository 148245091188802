import { useQuery } from '@apollo/client'
import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'

import { GET_PAGE_BACKGROUNDS_LIBRARY } from 'data/layoutcreator/queries/themes'

export const useBackgroundsLibrary = ({
  hideBackground,
  onSelectedBackgroundChange
}: {
  hideBackground: boolean
  onSelectedBackgroundChange: (
    data: {
      id: string
      category: string
    } | null
  ) => void
}) => {
  const { formatMessage } = useIntl()

  const [selectedImage, setSelectedImage] = useState<{
    id: string
    category: string
  } | null>(null)
  const [selectedCategoryIndex, setSelectedCategoryIndex] =
    useState(0)

  const { data, loading } = useQuery(GET_PAGE_BACKGROUNDS_LIBRARY)
  const images = data?.getPageBackgroundsLibrary

  const categories = [
    formatMessage({
      id: `Project.settings.templates.backgrounds.modal.tabs.library.categories.all`
    }),
    ...Array.from(new Set(images?.map(image => image.category)))
  ]

  const filteredImages = images?.filter(image => {
    if (selectedCategoryIndex === 0) {
      return true
    }

    return image.category === categories[selectedCategoryIndex]
  })

  const handleCategoryChange = (
    newValue: string,
    newIndex: number
  ) => {
    if (selectedImage?.category !== newValue) {
      setSelectedImage(null)
    }

    setSelectedCategoryIndex(newIndex)
  }

  useEffect(() => {
    onSelectedBackgroundChange(selectedImage)
  }, [selectedImage])

  useEffect(() => {
    if (hideBackground) {
      setSelectedImage(null)
    }
  }, [hideBackground])

  return {
    categories,
    selectedCategoryIndex,
    handleCategoryChange,
    images: filteredImages,
    isLoading: loading,
    selectedImage,
    setSelectedImage
  }
}
