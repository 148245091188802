import { useState } from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components'

import Flex from 'components/Flex'
import SearchInput from 'components/SearchInput'
import CustomScrollbars from 'components/SimpleBar'
import Spinner from 'components/Spinner'
import useTextSearch from 'hooks/useTextSearch'
import {
  Font,
  FontFamiliesMap,
  FontFamily
} from 'screens/YearbookEditor/components/Tools/types'

import SecondLevelTitle from '../../../../../PanelSecondLevelTitle'
import ThirdLevelTitle from '../../../ThirdLevelTitle'
import FontPreview from './components/FontPreview'
import useRecent from './hooks/useRecentlyUsedFonts'

const List = styled.div`
  flex-direction: column;
`

type ChangeFontPanelProps = {
  fontFamilies: FontFamiliesMap
  isLoading: boolean
  isError: boolean
  onBackButtonClick: () => void
  currentFontFamily: FontFamily | null
  setFont: (font: Font) => Promise<void>
}

const ChangeFontPanel = ({
  fontFamilies,
  isLoading,
  isError,
  onBackButtonClick,
  currentFontFamily,
  setFont
}: ChangeFontPanelProps): JSX.Element => {
  const [searchPattern, setSearchPattern] = useState('')
  const { formatMessage } = useIntl()

  const recent = useRecent(currentFontFamily)
  const filteredAll = useTextSearch<FontFamily>(
    Object.values(fontFamilies),
    searchPattern,
    'name'
  )
  const filteredRecent = useTextSearch<FontFamily>(
    Object.values(recent),
    searchPattern,
    'name'
  )

  const isRecentIncludes = (id: string) =>
    recent.some((family: FontFamily) => family.id === id)

  return (
    <CustomScrollbars
      fixedHeaderMarginBottom={200}
      fixedHeader={
        <>
          <SecondLevelTitle
            titleId="Change.font"
            onClick={onBackButtonClick}
          />
          <SearchInput
            width={250}
            value={searchPattern}
            onChange={setSearchPattern}
            iconSize={24}
            marginBottom={20}
            borderRadius={55}
            label={formatMessage({
              id: 'Change.font.input.field.placeholder'
            })}
          />
        </>
      }
    >
      <List>
        {!!filteredRecent.length && (
          <Flex direction="column" marginBottom={16}>
            <ThirdLevelTitle titleId="Change.font.recent" />
            {Object.values(filteredRecent).map(
              (fontFamily: FontFamily) => (
                <FontPreview
                  key={fontFamily.id}
                  fontFamily={fontFamily}
                  selectedFontFamily={currentFontFamily}
                  setFont={setFont}
                />
              )
            )}
          </Flex>
        )}

        <ThirdLevelTitle titleId="Change.font.all" />
        {isLoading && <Spinner />}
        {isError && <p>Error loading fonts :(</p>}
        {fontFamilies &&
          Object.values(filteredAll).map(
            (fontFamily: FontFamily) =>
              !isRecentIncludes(fontFamily.id) && (
                <FontPreview
                  key={fontFamily.id}
                  fontFamily={fontFamily}
                  selectedFontFamily={currentFontFamily}
                  setFont={setFont}
                />
              )
          )}
      </List>
    </CustomScrollbars>
  )
}

export default ChangeFontPanel
