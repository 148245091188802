import { useQuery } from '@apollo/client'
import config from 'config'
import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'

import Button from 'components/Button_2'
import ColorItem, { ColorItemSkeleton } from 'components/ColorItem'
import Flex from 'components/Flex'
import { Skeleton } from 'components/Skeleton'
import Switch from 'components/Switch'
import useAuth from 'data/gradoo/hooks/useAuth'
import { GET_PAGE_DATA } from 'data/layoutcreator/queries/projects'
import CMYK from 'entities/CMYK'
import { getBase64ImageUrl } from 'helpers/url'
import ChiliDocument from 'screens/YearbookEditor/components/Tools/services/ChiliDocument'
import ChiliVariables, {
  ThemeColor
} from 'screens/YearbookEditor/components/Tools/services/ChiliVariables'
import { DocumentEvents } from 'screens/YearbookEditor/components/Tools/services/types'
import { ColorCMYK, ImageFormats } from 'types/global'

import ColorsSet from '../ColorsSet'
import DesignersGuide from './components/DesignersGuide'
import ChangeBackgroundPanel from './components/panels/ChangeBackgroundPanel'
import ChangeThemeColorPanel from './components/panels/ChangeThemeColorPanel'
import { useReset } from './hooks/useReset'

const variablesApi = ChiliVariables.getInstance()
const documentApi = ChiliDocument.getInstance()

const Container = styled(Flex)`
  flex-direction: column;
  gap: 48px;

  position: relative;
`

const Section = styled.div`
  width: 250px;
`

const SectionHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-bottom: 16px;
`

const SectionTitle = styled.h3`
  font-size: 16px;
  font-weight: 700;
  margin: 0;
`

const SectionDescription = styled.span`
  font-size: 12px;
  color: ${({ theme }) => theme.color.base.c6};
`

const PagePreview = styled.div<{ src: string }>`
  width: 72px;
  height: 98px;
  border-radius: 8px;
  background: ${({ theme }) => theme.color.base.c2};
  background: ${({ src }) => `url(${src})`};
  background-size: cover;
  background-position: center;
`

const ShowPageNumberContainer = styled(Flex)`
  margin-top: -34px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 16px;
  background: ${({ theme }) => theme.color.base.c1};
  border-radius: 5px;
  font-size: 14px;
  color: ${({ theme }) => theme.color.base.c8};
`

const ColorSetWrapper = styled.div`
  width: 150px;
`

const ColorsSetContainer = styled.div`
  margin-top: auto;
  padding-top: 10px;
  display: flex;

  flex-direction: column;
  gap: 50px;
  margin-bottom: 20px;
  max-height: 200px;
`

enum SecondLevelPanels {
  changeThemeColor,
  changeBackground
}

const StartDesigningPanel: React.FC = () => {
  const { formatMessage } = useIntl()

  const { authGroupId: groupId } = useAuth()
  const { pathname } = useLocation()
  const projectId = pathname.split('/')[1]
  const documentId = pathname.split('/')[3]

  const [secondLevelPanel, setSecondLevelPanel] =
    useState<SecondLevelPanels | null>(null)
  const [currentColor, setCurrentColor] = useState<ThemeColor | null>(
    null
  )

  const { data: pageData } = useQuery(GET_PAGE_DATA, {
    variables: {
      groupId: groupId!,
      projectId,
      documentId
    }
  })

  const { templatePath } = pageData?.getPageData || {}

  const reset = useReset({
    groupId: groupId!,
    projectId,
    documentId,
    templatePath: templatePath!
  })

  const [themeColors, setThemeColors] = useState<ThemeColor[]>([])
  const [isThemeColorsLoading, setIsThemeColorsLoading] =
    useState(true)
  const [pageNumberColor, setPageNumberColor] = useState<ColorCMYK>({
    c: 0,
    m: 0,
    y: 0,
    k: 0
  })
  const [showPageNumber, setShowPageNumber] = useState(true)
  const [previewUrl, setPreviewUrl] = useState<string | null>(null)
  const [isPreviewLoading, setIsPreviewLoading] = useState(true)

  const handleColorItemEditClick = (color: ThemeColor) => {
    setSecondLevelPanel(SecondLevelPanels.changeThemeColor)
    setCurrentColor(color)
  }

  const handlePageNumberCurrentColorClick = () => {
    setSecondLevelPanel(SecondLevelPanels.changeThemeColor)
    setCurrentColor({
      displayName: 'page number color',
      name: config.themes.variablesKeys.pageNumberColor,
      value: pageNumberColor
    })
  }

  const handlePageNumberColorOtherClick = async (
    color: ColorCMYK
  ) => {
    await variablesApi.setVariableValue(
      config.themes.variablesKeys.pageNumberColor,
      variablesApi.convertColorToVariableValue(color)
    )
    fetchPageNumberColor()
  }

  const handlePanelBackButtonClick = () => {
    setSecondLevelPanel(null)
    fetchThemeColors()
    fetchPageNumberColor()
    fetchPagePreview()
  }

  const handleReset = async () => {
    await reset()
    fetchThemeColors()
    fetchPageNumberColor()
    fetchShowPageNumber()
    fetchPagePreview()
  }

  const handleShowPageNumberClick = () => {
    variablesApi.setVariableValue(
      config.themes.variablesKeys.pageNumberVisibility,
      !showPageNumber
    )
    setShowPageNumber(!showPageNumber)
  }

  const fetchThemeColors = async () => {
    setIsThemeColorsLoading(true)
    const colors = await variablesApi.getThemeColors()

    setThemeColors(
      colors.filter(({ name }) =>
        name.includes(config.themes.variablesKeys.themeColorPrefix)
      )
    )
    setIsThemeColorsLoading(false)
  }

  const fetchPageNumberColor = async () => {
    const pageNumberColor = await variablesApi.getVariable(
      config.themes.variablesKeys.pageNumberColor
    )

    const color = variablesApi.parseColorVariable(
      pageNumberColor.value
    )!

    if (color) {
      setPageNumberColor(color)
    }
  }

  const fetchShowPageNumber = async () => {
    const showPageNumber = await variablesApi.getVariable(
      config.themes.variablesKeys.pageNumberVisibility
    )

    setShowPageNumber(Boolean(showPageNumber))
  }

  const fetchPagePreview = async () => {
    setIsPreviewLoading(true)
    const base64 = await documentApi.getSnapshot()
    setPreviewUrl(getBase64ImageUrl(base64, ImageFormats.png))
    if (base64) {
      setIsPreviewLoading(false)
    }
  }

  useEffect(() => {
    if (!variablesApi.isInitialized) {
      return
    }

    fetchThemeColors()
    fetchPageNumberColor()
    fetchPagePreview()
    fetchShowPageNumber()
  }, [variablesApi.isInitialized])

  useEffect(() => {
    documentApi.on(DocumentEvents.fullyRendered, () => {
      fetchPagePreview()
    })
  }, [documentApi.isInitialized])

  return (
    <Container>
      <Section>
        <DesignersGuide />
      </Section>

      <Section>
        <SectionHeader>
          <SectionTitle>
            {formatMessage({
              id: 'Panel.startDesigning.section.editTheme.title'
            })}
          </SectionTitle>
          <SectionDescription>
            {formatMessage({
              id: 'Panel.startDesigning.section.editTheme.description'
            })}
          </SectionDescription>
        </SectionHeader>

        <Flex columnGap={8} marginTop={26}>
          <PagePreview src={previewUrl!}>
            {isPreviewLoading && <Skeleton radius={8} />}
          </PagePreview>

          <Flex flex={1} direction="column" rowGap={4}>
            {isThemeColorsLoading
              ? new Array(3)
                  .fill(null)
                  .map((_, i) => <ColorItemSkeleton key={i} />)
              : themeColors.map(color => (
                  <ColorItem
                    key={color.name}
                    name={color.displayName}
                    color={new CMYK({ ...color.value }).toCSS()}
                    editable
                    onEditClick={() =>
                      handleColorItemEditClick(color)
                    }
                  />
                ))}
          </Flex>
        </Flex>

        <Flex justifyContent="space-between" marginTop={16}>
          <Button
            textId="Panel.button.changeBackground"
            onPress={() =>
              setSecondLevelPanel(SecondLevelPanels.changeBackground)
            }
            intent="secondary-base"
            size="sm"
            minWidth={178}
          />

          <Button
            textId="Panel.button.reset"
            onPress={handleReset}
            intent="secondary-base"
            size="sm"
            minWidth={58}
          />
        </Flex>
      </Section>

      {secondLevelPanel === SecondLevelPanels.changeThemeColor &&
        currentColor && (
          <ChangeThemeColorPanel
            colorName={currentColor!.displayName.toLowerCase()}
            variableName={currentColor!.name}
            value={currentColor!.value}
            onBackButtonClick={handlePanelBackButtonClick}
          />
        )}

      {secondLevelPanel === SecondLevelPanels.changeBackground && (
        <ChangeBackgroundPanel
          onBackButtonClick={handlePanelBackButtonClick}
        />
      )}
      <ColorsSetContainer>
        <Flex>
          <Section>
            <SectionHeader>
              <SectionTitle>
                {formatMessage({
                  id: 'Panel.startDesigning.section.editPageNumber.title'
                })}
              </SectionTitle>
            </SectionHeader>
            <ColorSetWrapper>
              <ColorsSet
                labelId="Panel.field.label.textColor"
                currentColor={new CMYK({ ...pageNumberColor })}
                onCurrentClick={handlePageNumberCurrentColorClick}
                onOtherClick={(color: ColorCMYK) =>
                  handlePageNumberColorOtherClick(color)
                }
              />
            </ColorSetWrapper>
          </Section>
        </Flex>
        <ShowPageNumberContainer>
          <span>
            {formatMessage({
              id: 'Panel.field.label.showPageNumber'
            })}
          </span>

          <Switch
            value={showPageNumber}
            onChange={handleShowPageNumberClick}
          />
        </ShowPageNumberContainer>
      </ColorsSetContainer>
    </Container>
  )
}

export default StartDesigningPanel
