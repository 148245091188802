import { useContext } from 'react'
import styled, { ThemeContext, css } from 'styled-components'

import { Options as OptionsType } from '../types'

const Option = styled.button<{
  isActive: boolean
  isDisabled: boolean
}>`
  width: 44px;
  height: 44px;
  border-radius: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.color.base.c3};
  background: transparent;
  cursor: pointer;

  ${({ isDisabled, theme }) =>
    isDisabled &&
    css`
      cursor: not-allowed;
      background: ${theme.color.base.c2};
      border: 1px solid ${theme.color.base.c3};
    `}

  ${({ isActive, theme }) =>
    isActive &&
    css`
      background: ${theme.color.brand_02};
      border: none;
      // Outline
      box-shadow: 0px 2px 6px 0px #e9ecef;
    `}
`

type FormOptionsProps<ValueType> = {
  options: OptionsType<ValueType>
  isActive: (value: ValueType) => boolean
  onOptionClick: (value: ValueType) => void
  disabled?: ValueType[]
  nullable?: boolean
}

const Options = <ValueType extends any>({
  options,
  onOptionClick,
  isActive,
  disabled = []
}: FormOptionsProps<ValueType>): JSX.Element => {
  const theme = useContext(ThemeContext)

  const isDisabled = (value: ValueType) => disabled.includes(value)

  return (
    <>
      {options.map((option, i) => (
        <Option
          key={i}
          isActive={isActive(option.value)}
          isDisabled={isDisabled(option.value)}
          disabled={disabled.includes(option.value)}
          onClick={() => onOptionClick(option.value)}
        >
          <option.Icon
            style={{ width: 16, height: 16 }}
            stroke={
              isActive(option.value)
                ? theme.color.base.c0
                : theme.color.base.c9
            }
          />
        </Option>
      ))}
    </>
  )
}

export default Options
