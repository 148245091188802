import { useQuery } from '@apollo/client'
import { Dispatch, SetStateAction, useState } from 'react'
import styled from 'styled-components'

import useAuth from 'data/gradoo/hooks/useAuth'
import { GET_TEMPLATES } from 'data/layoutcreator/queries/templates'
import { TemplateEntity } from 'shared/types/layoutcreator/graphql'

import TemplatesItem from './components/Item'
import TemplatesItemSkeleton from './components/Item/Skeleton'
import PreviewModal, {
  PreviewModalTemplate
} from './components/PreviewModal'

const OuterContainer = styled.div`
  width: 100%;
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: 134px 134px 134px 134px;
  justify-content: space-between;
  row-gap: 48px;
  width: 100%;
  height: 50vh;
  max-height: 500px;
  overflow: auto;
  padding-right: 20px;
`

export type SetTemplateIdType = {
  setSelectedTemplateId: Dispatch<SetStateAction<string | undefined>>
  setTemplateError: Dispatch<SetStateAction<string>>
  selectedTemplateId?: string
  filter?: (template: TemplateEntity) => boolean
}

// TODO: handle error
const Templates = ({
  setSelectedTemplateId,
  setTemplateError,
  selectedTemplateId,
  filter = () => true
}: SetTemplateIdType) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [modalData, setModalData] = useState<PreviewModalTemplate>()
  const { authGroupId } = useAuth()

  const { data, loading } = useQuery(GET_TEMPLATES, {
    skip: !authGroupId
  })
  const closeModal = () => setIsModalOpen(false)

  const openModal = (
    templateId: string,
    categoryId: string,
    templatePath: string
  ) => {
    setModalData({
      templateId,
      categoryId,
      templatePath
    })
    setIsModalOpen(true)
  }

  return (
    <>
      <OuterContainer>
        <Grid>
          {loading
            ? new Array(4)
                .fill(null)
                .map((_, i) => <TemplatesItemSkeleton key={i} />)
            : data?.templates
                .filter(filter)
                .map((template: TemplateEntity) => {
                  const isSelected =
                    selectedTemplateId === template.id
                  return (
                    <TemplatesItem
                      key={template.id}
                    {...template}
                    isSelected={isSelected}
                    openModal={openModal}
                  />
                )
              })}
        </Grid>
      </OuterContainer>
      <PreviewModal
        closeModal={closeModal}
        isOpen={isModalOpen}
        setSelectedTemplateId={setSelectedTemplateId}
        setTemplateError={setTemplateError}
        {...modalData}
      />
    </>
  )
}

export default Templates
