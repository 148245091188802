import { useLazyQuery, useMutation } from '@apollo/client'
import { useState } from 'react'
import styled from 'styled-components'

import Button, { ButtonIntents } from 'components/Button'
import Flex from 'components/Flex'
import Status from 'components/Status'
import { START_PAGE_EXPORT } from 'data/layoutcreator/mutations/admin'
import { GET_EXPORT_DATA } from 'data/layoutcreator/queries/admin'
import { ExportStatuses } from 'screens/AdminProject/types'

const Container = styled(Flex)`
  flex-direction: column;
  border-radius: 8px;
  background: ${({ theme }) => theme.color.base.c0};
  padding: 16px 20px;
  cursor: pointer;
`

const Number = styled(Flex)`
  font-weight: 600;
  font-size: 16px;
  flex-direction: column;
  justify-content: center;
  margin-right: 6px;
`

const Name = styled.span`
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 6px;
`

const Property = styled.span``

const PropName = styled.span`
  font-weight: 600;
  margin-right: 4px;
`

const Chevron = styled.div`
  font-size: 18px;
  font-weight: 600;
`

const MoreContainer = styled(Flex)`
  padding: 16px 16px 10px 16px;
`

type DocumentProps = {
  groupId: string
  projectId: string
  order: number
  name: string
  id: string
}

const Document: React.FC<DocumentProps> = ({
  groupId,
  projectId,
  order,
  name,
  id
}) => {
  const [status, setStatus] = useState<ExportStatuses>(
    ExportStatuses.notStarted
  )

  const [startExport] = useMutation(START_PAGE_EXPORT)
  const [getExportData] = useLazyQuery(GET_EXPORT_DATA, {
    fetchPolicy: 'network-only'
  })

  const [basicProperties, setBasicProperties] = useState<
    Record<string, { name: string; value: string | JSX.Element }>
  >({
    id: { name: 'ID:', value: id }
  })

  const changeStatus = (data?: {
    isStarted: boolean
    isFinished: boolean
    isSucceeded: boolean
  }) => {
    data?.isStarted && setStatus(ExportStatuses.inProgress)
    data?.isFinished &&
      setStatus(
        data?.isSucceeded
          ? ExportStatuses.completed
          : ExportStatuses.failed
      )
  }

  return (
    <Container>
      <Flex justifyContent="space-between">
        <Flex columnGap={10}>
          <Number>{order}</Number>
          <Flex direction="column" rowGap={2}>
            <Name>{name}</Name>
            {Object.values(basicProperties).map(
              ({ name, value }, i) => (
                <Property key={i}>
                  <PropName>{name}</PropName>
                  {value}
                </Property>
              )
            )}
          </Flex>
        </Flex>

        <Flex alignItems="center" columnGap={8}>
          <Status value={status} />
        </Flex>
      </Flex>

      <Flex marginTop={20} columnGap={8}>
        {status === ExportStatuses.notStarted && (
          <Button
            padding={[8, 18]}
            borderRadius={100}
            labelId="Admin.buttons.startExport"
            fontSize={14}
            intent={ButtonIntents.dark}
            onClick={async () => {
              const { data } = await startExport({
                variables: {
                  groupId,
                  projectId,
                  documentId: id
                }
              })
              setBasicProperties({
                ...basicProperties,
                taskId: {
                  name: 'Task ID:',
                  value: data?.startExportDocument.taskId || ''
                }
              })
              changeStatus(data?.startExportDocument)
            }}
          />
        )}
        {status === ExportStatuses.inProgress && (
          <Button
            padding={[8, 18]}
            borderRadius={100}
            labelId="Admin.buttons.exporting"
            fontSize={14}
            intent={ButtonIntents.neutral}
            isDisabled
          />
        )}
        {(status === ExportStatuses.failed ||
          status === ExportStatuses.completed) && (
          <Button
            padding={[8, 18]}
            borderRadius={100}
            labelId="Admin.button.restartExport"
            fontSize={14}
            intent={ButtonIntents.dark}
            onClick={async () => {
              const { data } = await startExport({
                variables: {
                  groupId,
                  projectId,
                  documentId: id
                }
              })
              setBasicProperties({
                ...basicProperties,
                taskId: {
                  name: 'Task ID:',
                  value: data?.startExportDocument.taskId || ''
                }
              })
              changeStatus(data?.startExportDocument)
            }}
          />
        )}
        <Button
          padding={[8, 18]}
          borderRadius={100}
          labelId="Admin.buttons.refresh"
          fontSize={14}
          intent={ButtonIntents.neutral}
          onClick={async () => {
            const { data } = await getExportData({
              variables: {
                taskId: basicProperties.taskId.value as string
              }
            })
            setBasicProperties({
              ...basicProperties,
              url: {
                name: 'URL:',
                value: data?.getExportDocument.url ? (
                  <a
                    style={{ color: 'blue' }}
                    href={data?.getExportDocument.url}
                  >
                    {data?.getExportDocument.url}
                  </a>
                ) : (
                  'undefined'
                )
              }
            })
            changeStatus(data?.getExportDocument)
          }}
        />
      </Flex>
    </Container>
  )
}

export default Document
