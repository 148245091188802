import styled from 'styled-components'

const DEFAULT_HEIGHT = 6

const BACKGROUND =
  'linear-gradient(92deg, #4F46E5 2.26%, #A5B4FC 110.68%)'

const Line = styled.div<{ percentage: number; height?: number }>`
  width: 100%;
  height: ${({ height }) => height || DEFAULT_HEIGHT}px;
  border-radius: 9999px;
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: ${BACKGROUND};
    opacity: 0.2;
  }

  &::after {
    content: '';
    position: absolute;
    width: ${({ percentage }) => percentage}%;
    height: 100%;
    background: ${BACKGROUND};
    border-radius: 9999px;
    opacity: 1;
  }
`

type ProgressLineProps = {
  percentage: number
  height?: number
}

const ProgressLine: React.FC<ProgressLineProps> = ({
  percentage,
  height
}) => {
  return <Line percentage={percentage} height={height} />
}

export default ProgressLine
