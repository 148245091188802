import fscreen from 'fscreen'
import {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'

export interface FullScreenHandle {
  active: boolean
  enter: () => void
  exit: () => void
  node?: React.MutableRefObject<HTMLDivElement | null>
}

/**
 * @remarks
 * Full screen for partial content, use FullScreen component as a wrapper for the content. If you want to make the whole page full screen, using the useFullScreenHandle hook is enough.
 * Refactored from the library : https://github.com/snakesilk/react-fullscreen
 * @example 
 * function App() {
  const screen1 = useFullScreenHandle();
  const screen2 = useFullScreenHandle();

  const reportChange = useCallback((state, handle) => {
    if (handle === screen1) {
      console.log('Screen 1 went to', state, handle);
    }
    if (handle === screen2) {
      console.log('Screen 2 went to', state, handle);
    }
  }, [screen1, screen2]);

  return (
    <div>
      <button onClick={screen1.enter}>
        First
      </button>

      <button onClick={screen2.enter}>
        Second
      </button>

      <FullScreen handle={screen1} onChange={reportChange}>
        <div className="full-screenable-node" style={{background: "red"}}>
          First
          <button onClick={screen2.enter}>
            Switch
          </button>
          <button onClick={screen1.exit}>
            Exit
          </button>
        </div>
      </FullScreen>

      <FullScreen handle={screen2} onChange={reportChange}>
        <div className="full-screenable-node" style={{background: "green"}}>
          Second
          <button onClick={screen1.enter}>
            Switch
          </button>
          <button onClick={screen2.exit}>
            Exit
          </button>
        </div>
      </FullScreen>
    </div>
  );
}
 */

export const useFullScreenHandle = (): FullScreenHandle => {
  const documentEl = document.documentElement

  const [active, setActive] = useState<boolean>(false)
  const node = useRef<HTMLDivElement | null>(null)
  const el = node.current || documentEl

  useEffect(() => {
    const handleChange = () => {
      setActive(fscreen.fullscreenElement === el)
    }
    fscreen.addEventListener('fullscreenchange', handleChange)
    return () =>
      fscreen.removeEventListener('fullscreenchange', handleChange)
  }, [el])

  const enter = useCallback(() => {
    if (el) {
      return fscreen.requestFullscreen(el)
    }
  }, [el])

  const exit = useCallback(() => {
    if (fscreen.fullscreenElement === el) {
      return fscreen.exitFullscreen()
    }
  }, [el])

  return useMemo(
    () => ({
      active,
      enter,
      exit,
      node
    }),
    [active, enter, exit]
  )
}

export interface FullScreenProps {
  handle: FullScreenHandle
  children: any
  onChange?: (state: boolean, handle: FullScreenHandle) => void
}

export const FullScreen: React.FC<FullScreenProps> = ({
  handle,
  onChange,
  children
}) => {
  useEffect(() => {
    if (onChange) {
      onChange(handle.active, handle)
    }
  }, [handle, handle.active, onChange])

  return (
    <div
      ref={handle.node}
      style={
        handle.active ? { height: '100%', width: '100%' } : undefined
      }
    >
      {children}
    </div>
  )
}
