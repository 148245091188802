import { gql } from 'types/layoutcreator'

export const GET_PROJECT_DOCUMENTS = gql(/* GraphQL */ `
  query getDocuments($groupId: String!, $projectId: String!) {
    projectDocuments(groupId: $groupId, projectId: $projectId) {
      id
      path
      previewUrl
      name
      type
      index
    }
  }
`)

export const GET_PROJECT_DOCUMENTS_PREVIEWS = gql(/* GraphQL */ `
  query getProjectDocumentsPreviews(
    $groupId: String!
    $projectId: String!
  ) {
    getProjectDocumentsPreviews(
      groupId: $groupId
      projectId: $projectId
    ) {
      id
      path
    }
  }
`)
