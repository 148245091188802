import { useMutation, useQuery } from '@apollo/client'
import { useState } from 'react'
import toast from 'react-hot-toast'
import { useIntl } from 'react-intl'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'

import Spinner from 'shared/ui/Spinner'
import useAuth from 'data/gradoo/hooks/useAuth'
import { ADD_NEW_PAGE } from 'data/layoutcreator/mutations/projects'
import { GET_PROJECT_DOCUMENTS } from 'data/layoutcreator/queries/documents'
import {
  GET_PROJECT_TEMPLATE_DATA,
  TemplateDocumentsFragment
} from 'data/layoutcreator/queries/projects'
import { replaceSpaces } from 'shared/helpers/string'
import { getFragmentData } from 'shared/types/layoutcreator'
import { TemplateDocumentFieldsFragment } from 'shared/types/layoutcreator/graphql'

import collagesSrc from './images/collages.svg'
import emptySrc from './images/empty.svg'
import profileSrc from './images/profile.svg'
import quotesSrc from './images/quotes.svg'
import rankingsSrc from './images/rankings.svg'
import reportsSrc from './images/reports.svg'
import textSrc from './images/text.svg'

const PageTypesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 22px;
  text-align: center;
`

const PageButton = styled.a`
  position: relative;
  font-weight: 600;
  font-size: 14px;
  text-align: center;
  cursor: pointer;
`

export const ImgContainer = styled.div`
  position: relative;
  width: 91px;
  height: 125px;

  & img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    transition: transform 0.2s ease-out;
  }

  &:hover img {
    transform: scale(1.1);
  }
`

const SpinnerContainer = styled.div`
  position: absolute;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 210px;
  height: 289px;
`

const mapTemplateNameToThumbnail = (
  type: string
): { img: string; id: string } => {
  const MAP: Record<string, { img: string; id: string }> = {
    blank: { img: emptySrc, id: 'empty' },
    profile: { img: profileSrc, id: 'profile' },
    collages: { img: collagesSrc, id: 'collage' },
    reports: { img: reportsSrc, id: 'reports' },
    quotes: { img: quotesSrc, id: 'quotes' },
    rankings: { img: rankingsSrc, id: 'rankings' }
  }

  return MAP[type] || { img: textSrc, id: 'other' }
}

type PageTypesProps = {
  closeModal: () => void
  groupId: string
  projectId: string
}

const PageTypes: React.FC<PageTypesProps> = ({
  closeModal,
  groupId,
  projectId
}) => {
  const [addId, setAddId] = useState<string>('')
  const { formatMessage } = useIntl()

  const {
    loading: loadingTemplateData,
    data: templateData,
    error: errorLoadingTemplateData
  } = useQuery(GET_PROJECT_TEMPLATE_DATA, {
    variables: {
      projectId: replaceSpaces(projectId),
      groupId
    }
  })
  const [addNewPage, { loading: loadingAddNewPage }] = useMutation(
    ADD_NEW_PAGE,
    {
      refetchQueries: [
        {
          query: GET_PROJECT_DOCUMENTS,
          variables: { groupId, projectId }
        }
      ]
    }
  )

  const handleClick = async (
    templateDocument: TemplateDocumentFieldsFragment
  ) => {
    if (!loadingAddNewPage) {
      setAddId(templateDocument.id)
      try {
        const resp = await addNewPage({
          variables: {
            type: templateDocument.type,
            groupId,
            projectId: replaceSpaces(projectId)
          }
        })
        if (resp.data?.addNewPage) {
          closeModal()
          return
        }
        toast.error(
          formatMessage({ id: 'Add.page.error.adding.page' })
        )
      } catch (e) {
        toast.error(
          formatMessage({ id: 'Add.page.error.adding.page' })
        )
        console.error(e)
      }
    }
  }

  const templateDocs =
    templateData?.getProjectTemplateData?.templateDocuments

  if (
    errorLoadingTemplateData ||
    (!loadingTemplateData && !templateDocs?.length)
  ) {
    return (
      <PageTypesContainer>
        <div style={{ gridColumnEnd: 3 }}>
          {formatMessage({ id: 'Add.page.error.loading.templates' })}
        </div>
      </PageTypesContainer>
    )
  }

  return (
    <PageTypesContainer>
      {loadingTemplateData ? (
        <div style={{ gridColumnEnd: 3 }}>
          <Spinner />
        </div>
      ) : (
        templateDocs?.map(templateDocument => {
          const templateDocumentFragment = getFragmentData(
            TemplateDocumentsFragment,
            templateDocument
          )

          const { img, id } = mapTemplateNameToThumbnail(
            templateDocumentFragment.type
          )

          const name = formatMessage({ id: `Add.page.type.${id}` })
          return (
            <PageButton
              data-testid={`TemplatePage:${templateDocumentFragment.id}`}
              key={templateDocumentFragment.name}
              onClick={() => handleClick(templateDocumentFragment)}
            >
              <ImgContainer>
                <img src={img} alt={name} />
              </ImgContainer>
              {loadingAddNewPage &&
                templateDocumentFragment.id === addId && (
                  <SpinnerContainer>
                    <Spinner />
                  </SpinnerContainer>
                )}
              <br />
              <span>{name}</span>
            </PageButton>
          )
        })
      )}
    </PageTypesContainer>
  )
}

export default PageTypes
