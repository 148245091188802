import { ReactiveVar, useReactiveVar } from '@apollo/client'
import { useMemo, useState } from 'react'

import { COUNTRIES, CountryIDs } from '../index'

type UseSelectCountryModal = {
  countryCode: CountryIDs
  reactiveVar: ReactiveVar<CountryIDs | ''>
}
const useSelectCountryModal = ({
  countryCode,
  reactiveVar
}: UseSelectCountryModal) => {
  const reactiveVarValue = useReactiveVar(reactiveVar)
  const [country, setCountry] = useState<CountryIDs>(countryCode)

  const [isSelectCountryModalOpen, setIsSelectCountryModalOpen] =
    useState(false)
  const closeSelectCountryModal = () =>
    setIsSelectCountryModalOpen(false)

  const openSelectCountryModal = () =>
    setIsSelectCountryModalOpen(true)

  const selectedCountry = useMemo(
    () => COUNTRIES.find(({ id }) => id === reactiveVarValue),
    [reactiveVarValue]
  )

  return {
    country,
    setCountry,
    isSelectCountryModalOpen,
    closeSelectCountryModal,
    openSelectCountryModal,
    selectedCountry
  }
}

export default useSelectCountryModal
