import DefaultLayout from 'shared/layout/Default'
import { HeaderProps } from 'shared/layout/Default/components/Header'
import { theme } from 'shared/styles/theme'
import { ReactChildren } from 'shared/types/global'
import Island from 'shared/ui/Island'

type IslandLayoutProps = ReactChildren &
  HeaderProps & {
    showTools?: boolean
    Header?: JSX.Element
    onBack?: () => void
    marginBottom?: number
    noBackground?: boolean
  }

const IslandLayout: React.FC<IslandLayoutProps> = ({
  children,
  Header,
  liveUsers,
  RightComponent,
  onBack,
  marginBottom,
  noBackground
}) => {
  return (
    <DefaultLayout
      liveUsers={liveUsers}
      background={noBackground ? undefined : theme.color.base.c0}
      RightComponent={RightComponent}
      onBack={onBack}
      marginBottom={marginBottom}
    >
      {Header && Header}
      <Island
        responsive={{
          s: {
            padding: '48px 24px',
            margin: '88px 16px 0 16px'
          },
          xl: {
            padding: '68px 64px',
            margin: '88px auto 0 auto'
          }
        }}
      >
        {children}
      </Island>
    </DefaultLayout>
  )
}

export default IslandLayout
