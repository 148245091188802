import 'normalize.css'
import { createGlobalStyle } from 'styled-components'

import { theme } from 'styles/theme'

const GlobalStyle = createGlobalStyle`  
  * {
    box-sizing: border-box;
  }
  
  html, body, #root {
    height: 100%;
  }

  body {
    background: ${theme.color.base.c0};
    color: ${theme.color.base.c9};
    
    &.ReactModal__Body--open {
      overflow: hidden;
    }
  }

  body,
  input,
  textarea,
  select,
  button {
    color: ${theme.color.base.c9};
    /* font-family: Inter, sans-serif; */
    font-family: Roobert Pro, sans-serif;
    font-synthesis: none;
    -moz-font-feature-settings: 'kern';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    &:focus {
      outline: none;
    }
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }

  .ReactModal__Overlay.ReactModal__Overlay--after-open {
    overflow: scroll;
  }

  a {
    color: ${theme.color.base.c9};
    text-decoration: none;
  }

  .right_panel_popover_arrow {
  &::before {
    content: '';
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    border-width: 0 11px 11px;
    border-style: solid;
    border-color: transparent transparent ${theme.color.base.c2} transparent; 
    top:0px;

  }
  &::after {
    content: '';
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    border-width: 0 11px 11px;
    border-style: solid;
    border-color: transparent transparent ${theme.color.base.c0}; 
    top: 2px;
  }
  }


 
`

export default GlobalStyle
