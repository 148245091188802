import { Helmet } from 'react-helmet-async'
import { useIntl } from 'react-intl'

const Head = ({ titleId: id }: { titleId: string }) => {
  const { formatMessage } = useIntl()
  return (
    <Helmet>
      <title>{formatMessage({ id })} | Gradoo Layoutcreator</title>
    </Helmet>
  )
}

export default Head
