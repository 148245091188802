import { useIntl } from 'react-intl'
import styled from 'styled-components'

const Title = styled.div`
  font-size: 12px;
  font-weight: 600;
  color: ${({ theme }) => theme.color.base.c6};
  margin-bottom: 24px;
`

type ThirdLevelTitleProps = {
  titleId: string
}

const ThirdLevelTitle = ({
  titleId
}: ThirdLevelTitleProps): JSX.Element => {
  const { formatMessage } = useIntl()

  return <Title>{formatMessage({ id: titleId })}</Title>
}

export default ThirdLevelTitle
