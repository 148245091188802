import { ShapeTypes } from '../../types'
import { Coordinates } from './types'

export default {
  [ShapeTypes.star]: [
    { x: 25, y: 0 },
    { x: 31, y: 19 },
    { x: 50, y: 19 },
    { x: 36, y: 31 },
    { x: 42, y: 50 },
    { x: 25, y: 38 },
    { x: 8, y: 50 },
    { x: 14, y: 31 },
    { x: 0, y: 19 },
    { x: 19, y: 19 }
  ]
} as Partial<Record<ShapeTypes, Coordinates[]>>
