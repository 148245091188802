import { useQuery } from '@apollo/client'
import styled from 'styled-components'

import Flex from 'shared/ui/Flex'
import { Skeleton } from 'shared/ui/Skeleton'
import { PROFILE_PAGE_QUESTIONS } from 'data/gradoo/queries/profile'
import {
  Draggable,
  useContentDnd
} from 'screens/YearbookEditor/providers/ContentDndProvider'
import { Ids } from 'screens/YearbookEditor/providers/ContentDndProvider/types'
import { ProfilePageQuestionFragment } from 'shared/types/gradoo/graphql'

import { Panels } from '../../../../types'
import { TabProps } from '../../types'

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  height: 68px;
  padding: 16px 16px 2px 16px;
  gap: 4px;
  border: 2px solid ${({ theme }) => theme.color.base.c6};
  border-radius: 14px;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    cursor: pointer;
  }
`

const Label = styled.label`
  font-size: 12px;
  color: ${({ theme }) => theme.color.base.c6};
`

const Text = styled.label`
  font-size: 16px;
  color: ${({ theme }) => theme.color.base.c9};
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
`

export const QuestionAnswerDragOverlayContent = () => {
  const { draggingContent } = useContentDnd()

  return (
    <InputWrapper>
      <Label>{draggingContent.profileQuestion?.question}</Label>
      <Text>{draggingContent.profileQuestion?.answer}</Text>
    </InputWrapper>
  )
}

const QuestionsAnswersTab: React.FC<TabProps> = ({
  moduleInstanceId,
  userGroupId,
  push
}) => {
  const { data: questionsData, loading: questionsLoading } = useQuery(
    PROFILE_PAGE_QUESTIONS,
    {
      skip: !moduleInstanceId,
      variables: {
        moduleInstance: moduleInstanceId as string,
        ppqUserGroup: userGroupId
      },
      context: { client: 'gradoo' }
    }
  )

  const { draggingContent } = useContentDnd()

  const questions: ProfilePageQuestionFragment[] =
    (questionsData?.profilePageQuestions?.edges.map(
      edge => edge?.node
    ) as ProfilePageQuestionFragment[]) || []

  if (questionsLoading) {
    return (
      <Flex direction="column" rowGap={16}>
        {new Array(5).fill(null).map((_, i) => (
          <Skeleton key={i} height={63} />
        ))}
      </Flex>
    )
  }

  return (
    <Flex direction="column" rowGap={16}>
      {questions.map((question, i) => {
        const profilePageAnswer =
          question.profilePageAnswers?.edges?.[0]?.node
        const profilePageAnswerText =
          profilePageAnswer?.profilePageQuestionOption?.text ||
          profilePageAnswer?.text ||
          ''
        return (
          <Draggable
            key={question.id}
            id={question.id}
            type={Ids.profileQuestion}
            onDragStart={() =>
              draggingContent.setProfileQuestion({
                id: question.id,
                question: question.text,
                answer: profilePageAnswerText
              })
            }
            onDragEnd={() => draggingContent.setProfileQuestion(null)}
          >
            <InputWrapper
              data-testid={`ProfilePhotoQuestion:${question.id}`}
              key={i}
              onClick={() =>
                push({
                  panel: Panels.profileAnswerQuestion,
                  props: { moduleInstanceId, question }
                })
              }
            >
              <Label>{question.text}</Label>
              <Text>{profilePageAnswerText}</Text>
            </InputWrapper>
          </Draggable>
        )
      })}
    </Flex>
  )
}

export default QuestionsAnswersTab
