import styled from 'styled-components'

import { ReactChildren } from 'types/global'

const Container = styled.div`
  display: flex;
  justify-content: space-between;
`

const StepContent = ({ children }: ReactChildren) => (
  <Container>{children}</Container>
)

export default StepContent
