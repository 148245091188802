import { useIntl } from 'react-intl'
import styled from 'styled-components'

import BackgroundItem from 'components/BackgroundItem'
import Flex from 'components/Flex'
import Select from 'components/Select'
import { useBackgroundsLibrary } from 'hooks/useBackgroundsLibrary'

import Skeleton from './Skeleton'

const ItemsContainer = styled.div`
  flex: 0 1 auto;
  padding: 5px;
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  grid-gap: 10px;
  align-items: center;
  overflow-y: auto;
`

type LibraryTabProps = {
  onSelectedBackgroundChange: (
    data: {
      id: string
      category: string
    } | null
  ) => void
  hideBackground: boolean
}

const FORMAT_PREFIX =
  'Project.settings.templates.backgrounds.modal.tabs.library'

const ChangeBackgroundModalLibraryTab: React.FC<LibraryTabProps> = ({
  onSelectedBackgroundChange,
  hideBackground
}) => {
  const { formatMessage } = useIntl()

  const {
    categories,
    selectedCategoryIndex,
    handleCategoryChange,
    isLoading,
    images,
    setSelectedImage,
    selectedImage
  } = useBackgroundsLibrary({
    hideBackground,
    onSelectedBackgroundChange
  })

  return isLoading ? (
    <Skeleton />
  ) : (
    <>
      <Flex
        marginBottom={25}
        justifyContent="flex-end"
        flex="0 0 auto"
      >
        <Select
          width={252}
          label={formatMessage({
            id: `${FORMAT_PREFIX}.categories.label`
          })}
          options={categories}
          activeIndex={selectedCategoryIndex}
          onChange={handleCategoryChange}
        />
      </Flex>

      <ItemsContainer>
        {images?.map((image, i) => (
          <BackgroundItem
            key={i}
            onClick={() =>
              setSelectedImage(
                selectedImage?.id === image.id
                  ? null
                  : {
                      id: image.id,
                      category: image.category
                    }
              )
            }
            src={new URL(
              image.thumbPath,
              process.env.REACT_APP_REST_URL
            ).toString()}
            isSelected={selectedImage?.id === image.id}
          />
        ))}
      </ItemsContainer>
    </>
  )
}

export default ChangeBackgroundModalLibraryTab
