import Button, { ButtonIntents } from 'shared/ui/Button'
import Flex from 'shared/ui/Flex'
import { ProjectDocument } from 'shared/types/layoutcreator/graphql'

import ExportDocument from '../../components/ExportDocument'
import PagesContainer from '../../components/PagesContainer'
import SecondLevelTitle from '../../components/SecondLevelTitle'

type StartExportModeProps = {
  projectDocuments: ProjectDocument[]
  selectedPages: number[]
  isLoading: boolean
  onStart: () => void
  onCancel: () => void
  onPageClick: (index: number) => void
}

const StartExportMode: React.FC<StartExportModeProps> = ({
  projectDocuments,
  selectedPages,
  isLoading,
  onStart,
  onCancel,
  onPageClick
}) => {
  return (
    <Flex direction="column" flex={1}>
      <SecondLevelTitle>Pages:</SecondLevelTitle>

      <PagesContainer>
        {projectDocuments.map((document, i) => (
          <ExportDocument
            key={document.id}
            id={document.id}
            number={i + 1}
            name={document.name}
            isSelected={selectedPages.includes(i)}
            onClick={() => onPageClick(i)}
          />
        ))}
      </PagesContainer>

      <Flex marginTop={32} justifyContent="flex-end" columnGap={12}>
        <Button
          labelId="Actions.cancel"
          intent={ButtonIntents.neutral}
          onClick={onCancel}
          borderRadius={100}
        />

        <Button
          labelId="Admin.buttons.startExport"
          intent={ButtonIntents.dark}
          onClick={onStart}
          borderRadius={100}
          isDisabled={isLoading}
        />
      </Flex>
    </Flex>
  )
}

export default StartExportMode
