import { useState } from 'react'
import styled from 'styled-components'

import { toggleFullscreen } from 'shared/helpers/screen'
import { ReactChildren } from 'shared/types/global'
import Flex from 'shared/ui/Flex'
import Island from 'shared/ui/Island'
import ScreenContentLoadingBar from 'shared/ui/ScreenContentLoadingBar'

import FullscreenButton from './components/FullscreenButton'

const Container = styled(Flex)`
  flex-direction: column;
  min-height: fit-content;
  height: 100%;
  background: ${({ theme }) => theme.color.base.c1};
`

const Header = styled.header`
  width: 100%;
  padding: 12px 24px;
  background: ${({ theme }) => theme.color.base.c7};
  color: ${({ theme }) => theme.color.base.c0};
`

const Content = styled.div<{ isFullWidth: boolean }>`
  flex: 1;
  width: ${({ isFullWidth }) => (isFullWidth ? '100%' : `1136px`)};
  margin: 0 auto;
  position: relative;
`

const Bottom = styled(Flex)`
  position: fixed;
  left: 24px;
  bottom: 24px;
`

const IslandContainer: React.FC<ReactChildren> = ({ children }) => {
  return (
    <Island
      responsive={{
        s: {
          padding: '48px 24px',
          margin: '88px 16px 0 16px'
        },
        xl: {
          padding: '68px 64px',
          margin: '88px auto 0 auto'
        }
      }}
    >
      {children}
    </Island>
  )
}

type AdminLayoutProps = {
  renderHeaderContent: () => React.ReactNode
  showFullscreenButton?: boolean
  isFullWidth?: boolean
  loadingPercent?: number
  contentType?: 'default' | 'island'
} & ReactChildren

const AdminLayout: React.FC<AdminLayoutProps> = ({
  showFullscreenButton = true,
  renderHeaderContent,
  isFullWidth = false,
  loadingPercent = 0,
  contentType = 'default',
  children
}) => {
  const [isFullscreenActive, setIsFullscreenActive] = useState(
    !!document.fullscreenElement
  )
  const updateFullscreenState = () => {
    setIsFullscreenActive(!!document.fullscreenElement)
  }

  const handleFullscreenChange = async () => {
    await toggleFullscreen()
    updateFullscreenState()
  }

  return (
    <Container>
      <Header>{renderHeaderContent()}</Header>

      <Content isFullWidth={isFullWidth}>
        <ScreenContentLoadingBar loadingPercent={loadingPercent} />

        {contentType === 'island' ? (
          <IslandContainer>{children}</IslandContainer>
        ) : (
          children
        )}
      </Content>

      <Bottom>
        {showFullscreenButton && (
          <FullscreenButton
            onClick={handleFullscreenChange}
            isActive={isFullscreenActive}
          />
        )}
      </Bottom>
    </Container>
  )
}

export default AdminLayout
