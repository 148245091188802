import styled from 'styled-components'

import { LiveUser } from 'shared/types/global'
import LiveAvatar from '../LiveAvatar'

export const Container = styled.div`
  display: flex;
  margin-right: 24px;
`

export const LiveAvatarWrapper = styled.div`
  display: flex;
  margin-left: -8px;
`

type LiveAvatarsRowProps = {
  liveUsers: LiveUser[]
}

const LiveAvatarsRow: React.FC<LiveAvatarsRowProps> = ({
  liveUsers
}) => {
  return (
    <Container>
      {liveUsers.map(liveUser => (
        <LiveAvatarWrapper key={liveUser.index}>
          <LiveAvatar liveUser={liveUser} enableTooltip />
        </LiveAvatarWrapper>
      ))}
    </Container>
  )
}

export default LiveAvatarsRow
