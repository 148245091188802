import { gql } from 'shared/types/gradoo'

export const CUSTOM_PAGES_INSTANCES = gql(/* GraphQL */ `
  query CustomPagesInstances(
    $after: String
    $first: Int!
    $userGroup: String!
    $moduleInstance: ID
  ) {
    customPagesInstances(
      after: $after
      first: $first
      userGroup: $userGroup
      moduleInstance: $moduleInstance
    ) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          id
          title
        }
        cursor
      }
    }
  }
`)

export const CUSTOM_PAGES_INSTANCES_RESULT = gql(/* GraphQL */ `
  query CustomPagesInstancesResult(
    $after: String
    $first: Int!
    $moduleInstance: String!
  ) {
    customPagesInstancesResult(
      after: $after
      first: $first
      moduleInstance: $moduleInstance
    ) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          id
          title
        }
        cursor
      }
    }
  }
`)

export const CUSTOM_PAGES_INSTANCE = gql(/* GraphQL */ `
  query CustomPagesInstance($id: ID!) {
    customPagesInstance(id: $id) {
      created
      modified
      id
      pageFormat
      title
      page
      pageType
      pageQuality
    }
  }
`)
