import styled, { keyframes } from 'styled-components'

import { ReactChildren } from 'shared/types/global'

import Flex, { FlexDivProps } from '../Flex'

const fadeOut = keyframes`
  0% {
    visibility: visible;
    opacity: 1;
  }
  99% {
    visibility: hidden;
    opacity: 0;
  }
  100% {
    visibility: hidden;
    opacity: 0;
    width: 0;
    height: 0;
  }
`

const Container = styled(Flex)<FadeOutProps>`
  animation-fill-mode: forwards;
  animation-name: ${({ isShown }) => (!isShown ? fadeOut : '')};
  animation-duration: ${({ duration }) => duration}s;
  animation-timing-function: ${({ timing }) => timing};
`

type FadeOutProps = {
  isShown: boolean
  duration?: number
  timing?: string
}

const FadeOut: React.FC<
  FadeOutProps & FlexDivProps & Partial<ReactChildren>
> = ({
  isShown,
  duration = 0.2,
  timing = 'ease',
  children,
  ...rest
}) => {
  return (
    <Container
      {...rest}
      duration={duration}
      timing={timing}
      isShown={isShown}
    >
      {children}
    </Container>
  )
}

export default FadeOut
