import { useMutation, useQuery } from '@apollo/client'
import { useState } from 'react'
import toast from 'react-hot-toast'
import styled from 'styled-components'

import Button, { ButtonIntents } from 'components/Button'
import Flex from 'components/Flex'
import Input from 'components/Input'
import IslandModal, {
  IslandModalProps
} from 'components/modals/IslandModal'
import {
  EMPTY_MIGRATIONS_QUEUE,
  REMOVE_MIGRATION_FROM_QUEUE,
  START_MIGRATIONS_FOR_RANDOM_PROJECTS,
  START_PROJECT_MIGRATION
} from 'data/layoutcreator/mutations/migrations'
import { GET_MIGRATIONS_QUEUE } from 'data/layoutcreator/queries/migrations'

import MigrationsJobItem from './MigrationsJobItem'

const Label = styled.label`
  font-weight: 600;
  margin-bottom: 12px;
`

const Value = styled.button`
  background-color: ${({ theme }) => theme.color.base.c2};
  border: 0;
  border-radius: 100px;
  padding: 10px 18px;
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: 600;
  color: ${({ theme }) => theme.color.base.c6};
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.color.base.c3};
  }
`

const JobsList = styled(Flex)`
  flex: 1;
  flex-direction: column;
  gap: 16px;
  min-width: 570px;
  overflow-y: auto;
`

const RANDOM_AMOUNT_VALUES = [10, 30, 50, 100, 200, 500]

type ProjectExportModalProps = Pick<
  IslandModalProps,
  'isOpen' | 'closeModal'
>

const ColorMigrationsModal: React.FC<ProjectExportModalProps> = ({
  isOpen,
  closeModal
}) => {
  const [projectId, setProjectId] = useState<string>('')
  const [randomProjectsAmount, setRandomProjectsAmount] =
    useState<string>('0')

  const { data: queueData, refetch: refreshQueue } = useQuery(
    GET_MIGRATIONS_QUEUE
  )
  const [startMigration] = useMutation(START_PROJECT_MIGRATION, {
    refetchQueries: [{ query: GET_MIGRATIONS_QUEUE }]
  })
  const [startRandomProjectsMigrations] = useMutation(
    START_MIGRATIONS_FOR_RANDOM_PROJECTS
  )
  const [removeJob] = useMutation(REMOVE_MIGRATION_FROM_QUEUE, {
    refetchQueries: [{ query: GET_MIGRATIONS_QUEUE }]
  })
  const [emptyQueue] = useMutation(EMPTY_MIGRATIONS_QUEUE, {
    refetchQueries: [{ query: GET_MIGRATIONS_QUEUE }]
  })

  const handleValueClick = (value: number) => {
    setRandomProjectsAmount(value.toString())
  }

  const handleProjectMigrationStartClick = async () => {
    const toastId = toast.loading('Starting migration...')
    const result = await startMigration({ variables: { projectId } })

    if (result.data?.startMigration) {
      return toast.success('Migration started', { id: toastId })
    }

    toast.error('Failed to start migration', { id: toastId })
  }

  const handleRemoveJobClick = async (migrationId: string) => {
    const toastId = toast.loading('Removing job...')

    const { data } = await removeJob({ variables: { migrationId } })

    if (data?.removeMigrationFromQueue) {
      return toast.success('Job removed', { id: toastId })
    }

    return toast.error('Error while removing job', { id: toastId })
  }

  const handleRandomProjectsMigrationStartClick = async () => {
    const toastId = toast.loading('Starting migrations...')
    const result = await startRandomProjectsMigrations({
      variables: { count: parseInt(randomProjectsAmount) }
    })

    if (result.data?.startMigrationsForRandomProjects) {
      return toast.success('Migrations started', { id: toastId })
    }

    toast.error('Failed to start migrations', { id: toastId })
  }

  const handleEmptyQueueClick = async () => {
    const toastId = toast.loading('Emptying queue...')

    const result = await emptyQueue()

    if (result.data?.emptyMigrationsQueue) {
      return toast.success('Queue emptied', { id: toastId })
    }

    return toast.error('Failed to empty queue', { id: toastId })
  }

  return (
    <IslandModal
      isOpen={isOpen}
      closeModal={closeModal}
      width={1000}
      title="Migrations"
      titleAlign="left"
      titleSize={22}
      renderHeaderAppend={() => (
        <Flex columnGap={10}>
          <Button
            labelId="Admin.buttons.refreshQueue"
            onClick={() => refreshQueue()}
            intent={ButtonIntents.neutral}
            borderRadius={100}
            padding={[14, 20]}
          />

          <Button
            labelId="Admin.buttons.emptyQueue"
            onClick={handleEmptyQueueClick}
            intent={ButtonIntents.danger}
            borderRadius={100}
            padding={[14, 20]}
          />
        </Flex>
      )}
    >
      <Flex height={500} columnGap={40}>
        <JobsList>
          <span>
            <b>{queueData?.getMigrationsQueue.length}</b> migrations
            in the queue
          </span>
          {queueData?.getMigrationsQueue.map(item => (
            <MigrationsJobItem
              migrationId={item.migrationId}
              groupId={item.groupId}
              projectId={item.projectId}
              onRemove={() => handleRemoveJobClick(item.migrationId)}
            />
          ))}
        </JobsList>

        <Flex width={400} direction="column" height="fit-content">
          <Label>Start migration for a project:</Label>

          <Flex
            alignItems="center"
            columnGap={10}
            flex={1}
            marginBottom={30}
          >
            <Input
              label="Project ID"
              value={projectId}
              onChange={setProjectId}
              fullWidth
              isDisabled={false}
            />

            <Button
              labelId="Start"
              onClick={handleProjectMigrationStartClick}
              intent={ButtonIntents.dark}
              borderRadius={100}
            />
          </Flex>

          <Label>Start migrations for random projects:</Label>

          <Flex alignItems="center" columnGap={10} flex={1}>
            <Input
              label="Amount"
              value={randomProjectsAmount}
              onChange={setRandomProjectsAmount}
              fullWidth
              isDisabled={false}
            />

            <Button
              labelId="Start"
              onClick={handleRandomProjectsMigrationStartClick}
              intent={ButtonIntents.dark}
              borderRadius={100}
            />
          </Flex>

          <Flex wrap="wrap" marginTop={15} columnGap={10} width={260}>
            {RANDOM_AMOUNT_VALUES.map(value => (
              <Value onClick={() => handleValueClick(value)}>
                {value}
              </Value>
            ))}
          </Flex>
        </Flex>
      </Flex>
    </IslandModal>
  )
}

export default ColorMigrationsModal
