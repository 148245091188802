import React, { Dispatch, SetStateAction } from 'react'

import Button, { Intents } from 'shared/ui/Button'
import Flex from 'shared/ui/Flex'
import Spinner from 'shared/ui/Spinner'

import { Steps } from '../../index'
import { NewProjectModalStepIds } from '../../types'

type StepFooterProps = {
  isLoading?: boolean
  stepId: NewProjectModalStepIds
  setStep: Dispatch<SetStateAction<NewProjectModalStepIds>>
}

const StepFooter = ({
  isLoading,
  stepId,
  setStep
}: StepFooterProps) => {
  const step = Steps[stepId]

  return (
    <Flex columnGap={8}>
      {step.prevStepId ? (
        <Flex alignItems="center">
          <Button
            labelId="New.project.go.back"
            onClick={() =>
              step.prevStepId && setStep(step.prevStepId)
            }
            intent={Intents.neutral}
          />
        </Flex>
      ) : (
        <div />
      )}
      {isLoading ? (
        <Flex alignItems="center">
          <Spinner />
        </Flex>
      ) : (
        <Button
          labelId={`New.project.button.${step.id}`}
          type="submit"
          intent={Intents.dark}
        />
      )}
    </Flex>
  )
}

export default StepFooter
