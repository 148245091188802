import { useNavigate, useParams } from "react-router-dom"
import { useLiveUsers } from "shared/hooks/useLiveUsers"
import Header from './components/Header'
import withRoom from "shared/hocs/withRoom"
import ProjectSettings from "shared/components/ProjectSettings"
import AdminLayout from "shared/layout/Admin"
import Island from "shared/ui/Island"
import Flex from "shared/ui/Flex"
import Button from "shared/ui/Button_2"

const ProjectSettingsScreen = () => {
  const liveUsers = useLiveUsers()
  const { groupId, projectId } = useParams()
  const navigate = useNavigate()

  return (
    <AdminLayout
      showFullscreenButton={false}
      renderHeaderContent={() => <Header liveUsers={liveUsers} />}
      isFullWidth
    >
      <Flex marginBottom={120}>
        <Island
          responsive={{
            s: {
              padding: '48px 24px',
              margin: '88px 16px 0 16px'
            },
            xl: {
              padding: '68px 64px',
              margin: '88px auto 0 auto'
            }
          }}
        >
          <ProjectSettings
            groupId={groupId!}
            projectId={projectId!}
            exportBlock={{
              titleId: 'AdminProjectSettings.exportBlock.title',
              descriptionId: 'AdminProjectSettings.exportBlock.description',
              renderContent: () => (
                <Button
                  textId="AdminProjectSettings.exportBlock.buttons.goToExport"
                  intent="secondary-contrast"
                  onPress={() => navigate('export')}
                  size="md"
                />
              )
            }}
          />
        </Island>
      </Flex>
    </AdminLayout>
  )
}

export default withRoom(ProjectSettingsScreen)
