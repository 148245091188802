import Flex from 'shared/ui/Flex'
import Input from 'shared/ui/Input'
import IslandModal, {
  IslandModalProps
} from 'shared/components/modals/IslandModal'
import Button, { ButtonIntents } from 'shared/ui/Button'
import { useLazyQuery } from '@apollo/client'
import { GET_MIGRATION_DATA } from 'data/layoutcreator/queries/migrations'
import { useState } from 'react'

type ProjectExportModalProps = Pick<
  IslandModalProps,
  'isOpen' | 'closeModal'
>

const ColorMigrationsModal: React.FC<ProjectExportModalProps> = ({
  isOpen,
  closeModal,
}) => {
  const [id, setId] = useState<string>('')
  const [data, setData] = useState<string | null>(null)
  const [getData] = useLazyQuery(GET_MIGRATION_DATA)

  const handleGetDataClick = async () => {
    const { data } = await getData({ variables: { migrationId: id } })
    setData(data?.getMigrationData || null)
  }

  return (
    <IslandModal
      isOpen={isOpen}
      closeModal={closeModal}
      width={800}
      title="Get migration data"
      titleAlign="left"
      titleSize={22}
    >
      <Flex alignItems="center" columnGap={10} flex={1} width={400}>
        <Input
          label="Migration ID"
          value={id}
          onChange={setId}
          fullWidth
          isDisabled={false}
        />

        <Button
          labelId='Get data'
          onClick={handleGetDataClick}
          intent={ButtonIntents.dark}
          borderRadius={100}
        />
      </Flex>

      <Flex direction='column'>
        <div>Migration data:</div>
        <div>{data}</div>
      </Flex>
    </IslandModal>
  )
}

export default ColorMigrationsModal
