import { useIntl } from 'react-intl'
import styled from 'styled-components'

const Container = styled.div`
  flex: 1;
`

const Title = styled.h2`
  padding: 8px 0;
  margin: 0 0 16px;
  font-weight: 600;
  font-size: 28px;
`

const Text = styled.div`
  font-size: 14px;
  margin-bottom: 32px;
`

type SectionProps = {
  titleI18nId: string
  textI18nId: string
  children: JSX.Element | JSX.Element[]
}

export const Section = ({
  titleI18nId,
  textI18nId,
  children
}: SectionProps) => {
  const { formatMessage } = useIntl()

  return (
    <Container>
      <Title>{formatMessage({ id: titleI18nId })}</Title>
      <Text>{formatMessage({ id: textI18nId })}</Text>
      {children}
    </Container>
  )
}
