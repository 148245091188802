import { useQuery } from '@apollo/client'
import { useState } from 'react'
import { useIntl } from 'react-intl'
import { useLocation } from 'react-router-dom'

import { ButtonIntents } from 'components/Button'
import Flex from 'components/Flex'
import ModalButton from 'components/ModalButton'
import Templates from 'components/Templates'
import TemplatesCategories from 'components/TemplatesCategories'
import IslandModal, {
  IslandModalProps
} from 'components/modals/IslandModal'
import useAuth from 'data/gradoo/hooks/useAuth'
import { GET_PROJECT_TEMPLATES } from 'data/layoutcreator/queries/projects'
import { TemplateEntity } from 'types/layoutcreator/graphql'

type ChangeColorModalProps = Pick<
  IslandModalProps,
  'isOpen' | 'closeModal'
> & {
  onChange: (newTemplatePath: string) => void
}

const ChangeTemplateModal: React.FC<ChangeColorModalProps> = ({
  isOpen,
  closeModal,
  onChange
}) => {
  const { formatMessage } = useIntl()
  const [selectedTemplate, setSelectedTemplate] = useState<
    string | undefined
  >(undefined)
  const [currentCategoryId, setCurrentCategoryId] = useState<
    string | null
  >(null)
  const { pathname } = useLocation()
  const projectId = pathname.split('/')[1]
  const groupId = useAuth().authGroupId!

  const { data: projectTemplates } = useQuery(GET_PROJECT_TEMPLATES, {
    variables: {
      projectId,
      groupId
    }
  })

  const handleCancel = () => {
    closeModal()
    setSelectedTemplate(undefined)
  }

  const handleSwitch = () => {
    onChange(selectedTemplate!)
    closeModal()
    setSelectedTemplate(undefined)
  }

  const filterExistingTemplates = (template: TemplateEntity) =>
    !projectTemplates?.getProjectTemplates?.some(
      projectTemplate => projectTemplate.path === template.id
    ) || false

  const filterTemplatesByCategory = (template: TemplateEntity) => {
    if (currentCategoryId === null) {
      return true
    }

    return template.categoryId === currentCategoryId
  }

  return (
    <IslandModal
      isOpen={isOpen}
      closeModal={closeModal}
      title={formatMessage(
        { id: 'Project.settings.templates.change.modal.title' },
        { name: ''.toLowerCase() }
      )}
      titleAlign="left"
    >
      <Flex direction="column" alignItems="flex-end">
        <Flex columnGap={64} marginTop={32} marginBottom={72}>
          <TemplatesCategories
            currentCategoryId={currentCategoryId}
            onCategorySelect={setCurrentCategoryId}
          />

          <Templates
            selectedTemplateId={selectedTemplate}
            setSelectedTemplateId={setSelectedTemplate}
            setTemplateError={() => {}}
            filter={template =>
              filterExistingTemplates(template) &&
              filterTemplatesByCategory(template)
            }
          />
        </Flex>

        <Flex width={292} columnGap={8}>
          <ModalButton
            labelId="Actions.cancel"
            isFullWidth
            intent={ButtonIntents.neutral}
            onClick={handleCancel}
          />

          <ModalButton
            labelId="Project.settings.templates.buttons.switchTheme"
            isFullWidth
            intent={ButtonIntents.dark}
            isDisabled={!selectedTemplate}
            onClick={handleSwitch}
          />
        </Flex>
      </Flex>
    </IslandModal>
  )
}

export default ChangeTemplateModal
