import styled, { css } from 'styled-components'

import { theme } from 'shared/styles/theme'

type ContainerProps = Pick<Required<SwitchProps>, 'width' | 'height'>
const Container = styled.div<ContainerProps>`
  position: relative;
  height: ${({ height }) => height}px;
  width: ${({ width }) => width}px;
  border-radius: 9999px;
  cursor: pointer;
`

type CircleProps = { size: number } & Pick<
  Required<SwitchProps>,
  'padding'
>
const Circle = styled.div<CircleProps>`
  position: absolute;
  top: ${({ padding }) => padding}px;
  height: ${({ size }) => size}px;
  width: ${({ size }) => size}px;
  border-radius: 50%;
  background: ${({ theme }) => theme.color.base.c0};
  filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.1))
    drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.1));
  transition: 0.2s;
`

type SwitchProps = {
  value: boolean
  onChange: (newValue: boolean) => void
  height?: number
  width?: number
  padding?: number
}

const Switch: React.FC<SwitchProps> = ({
  value,
  onChange,
  width = 44,
  height = 24,
  padding = 2
}) => {
  const circleSize = height - padding * 2

  return (
    <Container
      width={width}
      height={height}
      onClick={() => onChange(!value)}
      style={{
        background: value ? theme.color.brand_02 : theme.color.base.c3
      }}
    >
      <Circle
        size={circleSize}
        padding={padding}
        style={{
          left: value ? width - circleSize - padding : padding
        }}
      />
    </Container>
  )
}

export default Switch
