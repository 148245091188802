import { useQuery } from '@apollo/client'
import { useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'

import { GET_PROJECT_EXPORTS } from 'data/layoutcreator/queries/export'
import AdminLayout from 'shared/layout/Admin'

import ExportTable from './components/ExportTable'
import Header from './components/Header'
import { ProjectExportStatuses, ProjectExportTypes } from './types'
import { getDownloadPdfUrl } from './utils.'
import Flex from 'shared/ui/Flex'
import Text from 'shared/ui/Text'
import Button from 'shared/ui/Button_2'
import ProjectExportModal from './components/ProjectExportModal'

const AdminProjectExport: React.FC = () => {
  const { groupId, projectId } = useParams()

  const [isModalOpened, setIsModalOpened] = useState(false)
  const [currentExportId, setCurrentExportId] = useState<
    string | null
  >(null)

  const { data: exportItemsData } = useQuery(GET_PROJECT_EXPORTS, {
    variables: {
      groupId: groupId!,
      projectId: projectId!
    }
  })

  const exportItems = useMemo(() => {
    if (!exportItemsData) {
      return []
    }

    const result = [...exportItemsData.getProjectExports]

    return result.reverse()
  }, [exportItemsData])

  return (
    <AdminLayout
      renderHeaderContent={() => <Header />}
      contentType="island"
    >
      <Flex justifyContent='space-between' marginBottom={48}>
        <Flex direction='column' rowGap={12}>
          <Text
            tag='h1'
            id='AdminExport.title'
            size={28}
            weight={600}
          />

          <Text
            tag='span'
            id='AdminExport.description'
            size={14}
          />
        </Flex>

        <Button
          textId='AdminExport.buttons.start'
          intent='primary-brand-02'
          size='lg'
          minHeight={44}
          onPress={() => setIsModalOpened(true)}
        />
      </Flex>

      <ExportTable
        items={exportItems.map(item => ({
          id: item.id,
          type: item.outputProfile as ProjectExportTypes,
          pagesCount: item.pages.length,
          authorFullName: item.author?.fullName || '-',
          date: item.startedAt,
          status: item.status as ProjectExportStatuses,
          downloadUrl:
            item.status === ProjectExportStatuses.completed ||
            item.status === ProjectExportStatuses.warning
              ? getDownloadPdfUrl({
                  groupId: groupId!,
                  projectId: projectId!,
                  exportId: item.id
                })
              : null,
          error: item.error || null
        }))}
        onExportClick={exportId => {
          setCurrentExportId(exportId)
          setIsModalOpened(true)
        }}
      />

      <ProjectExportModal
        isOpen={isModalOpened}
        closeModal={() => setIsModalOpened(false)}
        exportId={currentExportId}
        groupId={groupId!}
        projectId={projectId!}
      />
    </AdminLayout>
  )
}

export default AdminProjectExport
