import styled from 'styled-components'

import { HEADER_HEIGHT } from 'styles/constants'
import { HEADER_Z_INDEX } from 'styles/z-index'

export const StyledHeader = styled.header`
  background-color: ${({ theme }) => theme.color.base.c0};
  min-height: ${HEADER_HEIGHT}px;
`

export const HeaderContainer = styled.div`
  background-color: ${({ theme }) => theme.color.base.c0};
  display: flex;
  padding: 8px 24px;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 0;
  z-index: ${HEADER_Z_INDEX};
  width: 100%;
`

export const LiveAvatarsRow = styled.div`
  display: flex;
  margin-right: 24px;
`

export const LiveAvatarWrapper = styled.div`
  display: flex;
  margin-left: -8px;
`
export const IconsContainer = styled.div`
  margin-right: 16px;
`
