import Button from 'components/Button_2'

type HeaderGoBackButtonProps = {
  onBack: () => void
}

const HeaderGoBackButton = ({ onBack }: HeaderGoBackButtonProps) => {
  return (
    <Button
      onPress={onBack}
      size="lg"
      intent="sub-action"
      iconName="arrow_narrow_left"
      icon="only"
    />
  )
}

export default HeaderGoBackButton
