import styled from 'styled-components'

import { ReactChildren } from 'types/global'

const CONTAINER_WIDTH = 1140
const COLUMN_GAP = 24
const COLUMN_COUNT = 4

const COLUMN_WIDTH =
  (CONTAINER_WIDTH - COLUMN_GAP * (COLUMN_COUNT - 1)) / 4

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(${COLUMN_COUNT}, ${COLUMN_WIDTH}px);
  column-gap: 24px;
  row-gap: 24px;
  margin-bottom: 32px;
`

const Cards = ({ children }: ReactChildren) => (
  <Container>{children}</Container>
)

export default Cards
