import { useQuery } from '@apollo/client'

import Flex from 'shared/ui/Flex'
import { COLLAGES_ALBUMS } from 'data/gradoo/queries/collages'
import { CollagesAlbumFragment } from 'shared/types/gradoo/graphql'

import { PanelProps, Panels } from '../../types'
import Skeleton from './Skeleton'
import CollageItem from './components/CollageItem'

type CollagesListPanelProps = PanelProps & {
  moduleInstanceId?: string
}

const CollagesListPanel: React.FC<CollagesListPanelProps> = ({
  moduleInstanceId,
  push
}) => {
  const { data: collagesAlbumsData, loading } = useQuery(
    COLLAGES_ALBUMS,
    {
      skip: !moduleInstanceId,
      variables: {
        moduleInstance: moduleInstanceId,
        cpFirst: 5
      },
      context: { client: 'gradoo' }
    }
  )

  const collagesAlbums: CollagesAlbumFragment[] =
    (collagesAlbumsData?.collagesAlbums?.edges.map(
      edge => edge?.node
    ) as CollagesAlbumFragment[]) || []

  return (
    <Flex direction="column">
      <Flex direction="column" rowGap={24}>
        {loading ? (
          <Skeleton />
        ) : (
          collagesAlbums.map(collagesAlbum => (
            <CollageItem
              data-testid={`CollageItem:${collagesAlbum.id}`}
              key={collagesAlbum.id}
              onClick={() =>
                push({
                  panel: Panels.collage,
                  title: collagesAlbum.name,
                  props: {
                    collageAlbumId: collagesAlbum.id,
                    collagesPhotoCount: collagesAlbum.photosCount
                  }
                })
              }
              title={collagesAlbum.name}
              images={
                collagesAlbum.collagesPhotos?.edges?.map(
                  edge => edge?.node?.photo
                ) as string[]
              }
            />
          ))
        )}
      </Flex>
    </Flex>
  )
}

export default CollagesListPanel
