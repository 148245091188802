import Flex from 'shared/ui/Flex'

import { RankingsItemSkeleton } from './components/RankingsItem'

const RankingsPanelSkeleton: React.FC = () => (
  <Flex direction="column" rowGap={26}>
    {new Array(2).fill(null).map((_, i) => (
      <RankingsItemSkeleton key={i} />
    ))}
  </Flex>
)

export default RankingsPanelSkeleton
