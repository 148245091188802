import { useQuery } from '@apollo/client'

import Flex from 'components/Flex'
import { RANKINGS_QUESTIONS } from 'data/gradoo/queries/rankings'
import { RankingsQuestionFragment } from 'types/gradoo/graphql'

import Skeleton from './Skeleton'
import RankingsItem from './components/RankingsItem'

type RankingsPanelProps = {
  moduleInstanceId?: string
}

const RankingsPanel: React.FC<RankingsPanelProps> = ({
  moduleInstanceId
}) => {
  const { data: rankingsQuestionsData, loading } = useQuery(
    RANKINGS_QUESTIONS,
    {
      skip: !moduleInstanceId,
      variables: {
        moduleInstance: moduleInstanceId
      },
      context: { client: 'gradoo' }
    }
  )

  const rankingsQuestions: RankingsQuestionFragment[] =
    (rankingsQuestionsData?.rankingsQuestions?.edges.map(
      edge => edge?.node
    ) as RankingsQuestionFragment[]) || []

  return (
    <Flex direction="column" rowGap={22}>
      {loading ? (
        <Skeleton />
      ) : (
        rankingsQuestions.map(rankingsQuestion => (
          <RankingsItem
            data-testid={`RankingsItem:${rankingsQuestion.id}`}
            key={rankingsQuestion.id}
            rankingsQuestion={rankingsQuestion}
          />
        ))
      )}
    </Flex>
  )
}

export default RankingsPanel
