import { useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
import {
  UseFormRegister,
  UseFormResetField,
  UseFormSetValue
} from 'react-hook-form'
import { useIntl } from 'react-intl'
import styled from 'styled-components'

import { FieldValues } from '../../index'
import Container from './components/Container'
import UploadIcon from './components/UploadIcon'
import fileSuccessSrc from './images/file-check-03.svg'
import fileErrorSrc from './images/file-x-03.svg'

const Small = styled.div`
  font-size: 10px;
`

const FakeLink = styled(Small)`
  text-decoration: underline;
  color: ${({ theme }) => theme.color.brand_02};
`

const Img = styled.img`
  width: 64px;
`

const DefaultMessage = styled.div`
  margin-bottom: 4px;
  font-size: 14px;
  font-weight: 600;
`

const SuccessMessage = styled(DefaultMessage)`
  color: ${({ theme }) => theme.color.success};
`

const ErrorMessage = styled(DefaultMessage)`
  color: ${({ theme }) => theme.color.error};
`

type UploadCoverProps = {
  setValue: UseFormSetValue<FieldValues>
  resetField: UseFormResetField<FieldValues>
  hasError: boolean
  register: UseFormRegister<FieldValues>
}

const UploadCover = ({
  setValue,
  resetField,
  hasError,
  register
}: UploadCoverProps) => {
  const { formatMessage } = useIntl()

  const onDropAccepted = useCallback((acceptedFiles: File[]) => {
    if (acceptedFiles.length > 0) {
      setValue('cover', acceptedFiles[0], { shouldValidate: true })
    }
  }, [])

  const resetCover = useCallback(() => resetField('cover'), [])

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragReject,
    acceptedFiles,
    fileRejections
  } = useDropzone({
    onDropAccepted,
    onDropRejected: resetCover,
    onFileDialogOpen: resetCover,
    maxFiles: 1,
    accept: {
      'application/pdf': ['.pdf']
    }
  })

  function renderDropZoneState() {
    switch (true) {
      case isDragReject:
        return (
          <>
            <UploadIcon isDragReject />
            <div>
              <ErrorMessage>
                {formatMessage({
                  id: 'Project.print.data.cover.drag.reject'
                })}
              </ErrorMessage>
              <Small>&nbsp;</Small>
            </div>
          </>
        )
      case isDragActive:
        return (
          <>
            <UploadIcon isDragActive />
            <div>
              <div>
                {formatMessage({
                  id: 'Project.print.data.cover.drag.active'
                })}
              </div>
              <Small>&nbsp;</Small>
            </div>
          </>
        )
      case !!acceptedFiles.length: {
        const { name } = acceptedFiles[0]
        return (
          <>
            <Img src={fileSuccessSrc} />
            <div>
              <SuccessMessage>
                {formatMessage({
                  id: 'Project.print.data.cover.add.success'
                })}
              </SuccessMessage>
              <Small>{name}</Small>
            </div>
          </>
        )
      }
      case !!fileRejections.length:
        return (
          <>
            <Img src={fileErrorSrc} />
            <div>
              <ErrorMessage>
                {formatMessage({
                  id: 'Project.print.data.cover.error.title'
                })}
              </ErrorMessage>
              <Small>
                {formatMessage({
                  id: 'Project.print.data.cover.error.subtitle'
                })}
              </Small>
            </div>
          </>
        )
      default:
        return (
          <>
            <UploadIcon />
            <div>
              <DefaultMessage>
                {formatMessage({
                  id: 'Project.print.data.cover.default.title'
                })}
              </DefaultMessage>
              <FakeLink>
                {formatMessage({
                  id: 'Project.print.data.cover.default.subtitle'
                })}
              </FakeLink>
            </div>
          </>
        )
    }
  }

  return (
    <Container
      {...getRootProps()}
      isDragActive={isDragActive}
      isDragReject={isDragReject}
      isSuccessful={!!acceptedFiles.length}
      hasError={!!fileRejections.length || hasError}
    >
      <input {...getInputProps()} {...register('cover')} />
      {renderDropZoneState()}
    </Container>
  )
}

export default UploadCover
