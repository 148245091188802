import { useMutation, useQuery } from '@apollo/client'
import { useEffect, useMemo, useState } from 'react'
import toast from 'react-hot-toast'
import { useIntl } from 'react-intl'
import styled from 'styled-components'

import Button from 'shared/ui/Button_2'
import Flex from 'shared/ui/Flex'
import Input from 'shared/ui/Input'
import {
  CHANGE_PROJECT_META,
  CHANGE_PROJECT_NAME,
} from 'data/layoutcreator/mutations/projects'
import {
  GET_PROJECTS,
  GET_PROJECT_META
} from 'data/layoutcreator/queries/projects'
import { useProjectNameValidation } from 'shared/hooks/useProjectNameValidation'
import CoverType, {
  CoverTypes
} from 'screens/Home/components/NewProjectModal/components/CoverType'

import Footer from './components/Footer'
import SubTitle from './components/SubTitle'
import DangerZoneSection from './sections/DangerZoneSection'
import ProjectTemplateSection from './sections/ProjectTemplateSection'

const ExportContainer = styled.div`
  width: 100%;
  padding: 24px;
  border-radius: 14px;
  border: 1px solid ${({ theme }) => theme.color.base.c5};
  background: ${({ theme }) => theme.color.base.c2};
`

const ExportTitle = styled.h4`
  font-weight: 600;
  font-size: 18px;
  color: ${({ theme }) => theme.color.brand_02};
  margin-top: 0;
  margin-bottom: 4px;
`

const ExportDescription = styled.span`
  font-size: 12px;
  color: ${({ theme }) => theme.color.base.c10};
`

const FooterWrapper = styled.div`
  margin-top: 48px;
`

type ProjectSettingsProps = {
  projectId: string
  groupId: string
  exportBlock: {
    titleId: string;
    descriptionId: string;
    renderContent(props: { groupId: string; projectId: string }): JSX.Element;
  }
}

const ProjectSettings: React.FC<ProjectSettingsProps> = ({ groupId, projectId, exportBlock }) => {
  const { formatMessage } = useIntl()

  const commonVars = {
    groupId,
    projectId
  }

  const { data: projectsData } = useQuery(GET_PROJECTS, {
    variables: commonVars
  })

  const { data: projectMetaData } =
    useQuery(GET_PROJECT_META, {
      variables: commonVars
    })

  const [changeProjectName] = useMutation(CHANGE_PROJECT_NAME, {
    refetchQueries: [
      { query: GET_PROJECTS, variables: { groupId } }
    ]
  })

  const [changeProjectMeta] = useMutation(CHANGE_PROJECT_META, {
    refetchQueries: [
      { query: GET_PROJECTS, variables: { groupId } },
      { query: GET_PROJECT_META, variables: commonVars }
    ]
  })

  const [projectName, setProjectName] = useState('')
  const [coverType, setCoverType] = useState<CoverTypes>(
    (projectMetaData?.getProjectMeta.coverType as CoverTypes) || CoverTypes.soft
  )

  const { projectNameError, isProjectNameValid } =
    useProjectNameValidation({
      projectId,
      projectName
    })

  useEffect(() => {
    if (projectMetaData?.getProjectMeta) {
      setProjectName(
        decodeURIComponent(projectMetaData.getProjectMeta.name)
      )
      setCoverType(
        projectMetaData.getProjectMeta.coverType as CoverTypes
      )
    }
  }, [projectMetaData])

  const handleNameChange = async () => {
    const toastId = toast.loading(
      formatMessage({
        id: 'Project.settings.name.save.loading'
      })
    )

    const { data } = await changeProjectName({
      variables: {
        ...commonVars,
        newName: projectName
      }
    })

    const result = data?.changeProjectName

    if (!result) {
      return toast.error(
        formatMessage({
          id: 'Project.settings.name.save.error'
        }),
        { id: toastId }
      )
    }

    toast.success(
      formatMessage({
        id: 'Project.settings.name.save.success'
      }),
      { id: toastId }
    )
  }

  const isProjectNameUnique = useMemo(() => {
    if (!projectsData?.projects) {
      return false
    }

    const isUnique = projectsData?.projects
      .filter(({ id }) => id !== projectId)
      .every(project => project.name !== projectName)

    return isUnique
  }, [projectsData, projectName])

  const handleCoverTypeChange = (newCoverType: CoverTypes) => {
    setCoverType(newCoverType)

    changeProjectMeta({
      variables: {
        ...commonVars,
        input: {
          coverType: newCoverType
        }
      }
    })
  }

  const isNameChangeDisabled = useMemo(() => {
    return (
      projectName ===
        decodeURIComponent(
          projectMetaData?.getProjectMeta?.name || ''
        ) && isProjectNameUnique
    )
  }, [projectName, projectMetaData, isProjectNameUnique])

  return (
    <>
      <Flex direction="column" marginBottom={150}>
        <Flex
          justifyContent="space-between"
          columnGap={16}
          rowGap={32}
          responsive={{
            s: {
              flexDirection: 'column'
            },
            l: {
              flexDirection: 'row'
            }
          }}
        >
          <Flex
            columnGap={16}
            responsive={{
              s: {
                flexDirection: 'column'
              },
              m: {
                flexDirection: 'row'
              },
              l: {
                flexDirection: 'column'
              }
            }}
          >
            <Flex direction="column" flex={1}>
              <Flex
                justifyContent="space-between"
                alignItems="center"
                marginBottom={14}
                height={36}
              >
                <SubTitle>
                  {formatMessage({
                    id: 'Project.settings.name.title'
                  })}
                </SubTitle>
                <Button
                  state={
                    isNameChangeDisabled ? 'disabled' : 'default'
                  }
                  textId={formatMessage({
                    id: 'Project.settings.name.save.button'
                  })}
                  onPress={
                    isNameChangeDisabled ? () => {} : handleNameChange
                  }
                  size="sm"
                  intent="primary-brand-02"
                />
              </Flex>

              <Flex direction="column" rowGap={6}>
                <Input
                  label={formatMessage({
                    id: 'Project.settings.input.label'
                  })}
                  value={projectName}
                  onChange={newName => setProjectName(newName)}
                  isInvalid={
                    !isProjectNameValid && Boolean(projectNameError)
                  }
                  infoLabel={
                    !isProjectNameValid && projectNameError
                      ? projectNameError
                      : ''
                  }
                />
              </Flex>
            </Flex>

            <Flex
              direction="column"
              flex={1}
              rowGap={16}
              marginTop={26}
            >
              <SubTitle>
                {formatMessage({
                  id: 'Project.settings.order.title'
                })}
              </SubTitle>

              <ExportContainer>
                <ExportTitle>
                  {formatMessage({
                    id: exportBlock.titleId
                  })}
                </ExportTitle>
                <ExportDescription>
                  {formatMessage({
                    id: exportBlock.descriptionId
                  })}
                </ExportDescription>

                <Flex
                  columnGap={10}
                  width={390}
                  marginTop={14}
                  height={34}
                >
                  {exportBlock.renderContent({ groupId, projectId })}
                </Flex>
              </ExportContainer>
            </Flex>
          </Flex>

          <Flex direction="column">
            <Flex height={36} alignItems="center">
              <SubTitle>Edit cover type</SubTitle>
            </Flex>

            <Flex marginTop={14}>
              <CoverType
                coverType={coverType}
                error=""
                handleClick={handleCoverTypeChange}
              />
            </Flex>
          </Flex>
        </Flex>
      </Flex>

      <Flex direction="column" rowGap={50}>
        <ProjectTemplateSection groupId={groupId} projectId={projectId} />
        <DangerZoneSection groupId={groupId} projectId={projectId} />
      </Flex>

      <FooterWrapper>
        <Footer />
      </FooterWrapper>
    </>
  )
}

export default ProjectSettings
