import { useState } from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components'

import Head from 'shared/components/Head'
import { StepsRecord } from 'shared/ui/StepsBar/types'
import Modal from 'shared/components/modals/IslandModal'

import Setup from './components/Setup'
import Template from './components/Template'
import TemplateIcon from './components/TemplateIcon'
import TitleIcon from './components/TitleIcon'
import { NewProjectModalStepIds } from './types'

const Title = styled.div`
  margin-bottom: 48px;
  font-weight: 700;
  font-size: 20px;
`

export const Content = styled.div`
  width: 917px;
`

type NewProjectModalProps = {
  isModalOpen: boolean
  closeModal: () => void
}

export const Steps: StepsRecord<NewProjectModalStepIds> = {
  [NewProjectModalStepIds.setup]: {
    id: NewProjectModalStepIds.setup,
    IconComponent: TitleIcon,
    nextStepId: NewProjectModalStepIds.template
  },
  [NewProjectModalStepIds.template]: {
    id: NewProjectModalStepIds.template,
    IconComponent: TemplateIcon,
    prevStepId: NewProjectModalStepIds.setup
  }
}

const NewProjectModal = ({
  isModalOpen,
  closeModal
}: NewProjectModalProps) => {
  const { formatMessage } = useIntl()
  const [step, setStep] = useState<NewProjectModalStepIds>(
    NewProjectModalStepIds.setup
  )

  return (
    <Modal closeModal={closeModal} isOpen={isModalOpen}>
      <Content>
        <Head titleId="New.project.header" />
        <Title>{formatMessage({ id: 'Create.new.project' })}</Title>
        {step === NewProjectModalStepIds.setup ? (
          <Setup setStep={setStep} closeModal={closeModal} />
        ) : (
          <Template setStep={setStep} closeModal={closeModal} />
        )}
      </Content>
    </Modal>
  )
}

export default NewProjectModal
