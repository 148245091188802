import { useState } from 'react'

export enum LoadingStages {
  iframeLoaded = 'iframeLoaded',
  built = 'built',
  documentLoaded = 'documentLoaded',
  workspaceRendered = 'workspaceRendered',
}

export default function useEditorLoading() {
  const [percent, setPercent] = useState(0)

  const STAGES_MAP: Record<LoadingStages, number> = {
    [LoadingStages.iframeLoaded]: 10,
    [LoadingStages.built]: 50,
    [LoadingStages.documentLoaded]: 70,
    [LoadingStages.workspaceRendered]: 100,
  }

  const markLoaded = (stage: LoadingStages): number => {
    setPercent(STAGES_MAP[stage])
    return STAGES_MAP[stage]
  }

  return {
    loadingPercent: percent,
    markLoaded
  }
}
