import { useQuery } from '@apollo/client'
import React from 'react'

import Flex from 'components/Flex'
import { Skeleton } from 'components/Skeleton'
import useAuth from 'data/gradoo/hooks/useAuth'
import { CUSTOM_PAGES_INSTANCES_RESULT } from 'data/gradoo/queries/custom'

import { PanelProps, Panels } from '../../types'
import ReportItem from '../ReportsPanel/components/ReportItem'

type CustomPagePanelProps = PanelProps & {
  moduleInstanceId?: string
}

const CustomPagesPanel: React.FC<CustomPagePanelProps> = ({
  moduleInstanceId,
  push
}) => {
  const { authGroupId } = useAuth()
  const { data: moduleInstanceResultsData, loading } = useQuery(
    CUSTOM_PAGES_INSTANCES_RESULT,
    {
      skip: !authGroupId,
      variables: {
        first: 100,
        moduleInstance: moduleInstanceId as string
      },
      context: { client: 'gradoo' }
    }
  )
  const loadingUI = new Array(3)
    .fill(null)
    .map((_, i) => <Skeleton key={i} height={48} />)
  return (
    <Flex direction="column">
      <Flex direction="column" rowGap={16}>
        {loading
          ? loadingUI
          : moduleInstanceResultsData?.customPagesInstancesResult?.edges.map(
              customPageInstance =>
                customPageInstance?.node && (
                  <ReportItem
                    data-testid={`CustomPageItem:${customPageInstance?.node.id}`}
                    key={customPageInstance?.node.id}
                    onClick={() =>
                      push({
                        panel: Panels.customPage,
                        title: customPageInstance?.node?.title,
                        props: {
                          customPageInstanceId:
                            customPageInstance?.node?.id
                        }
                      })
                    }
                    title={customPageInstance?.node.title}
                  />
                )
            )}
      </Flex>
    </Flex>
  )
}

export default CustomPagesPanel
