import { gql } from 'types/gradoo'

export const PROFILE_PAGE_PHOTO_CATEGORIES = gql(/* GraphQL */ `
  query ProfilePagePhotoCategories(
    $after: String
    $first: Int
    $moduleInstance: ID
    $ppqUserGroup: ID
  ) {
    profilePagePhotoCategories(
      after: $after
      first: $first
      moduleInstance: $moduleInstance
    ) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          ...ProfilePagePhotoCategory
        }
      }
    }
  }
`)

export const PROFILE_PAGE_QUESTIONS = gql(/* GraphQL */ `
  query ProfilePageQuestions(
    $after: String
    $first: Int
    $moduleInstance: ID
    $ppqUserGroup: ID
  ) {
    profilePageQuestions(
      after: $after
      first: $first
      moduleInstance: $moduleInstance
    ) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          ...ProfilePageQuestion
        }
      }
    }
  }
`)

export const PROFILE_PAGE_COMMENTS = gql(/* GraphQL */ `
  query ProfilePageComments(
    $after: String
    $first: Int
    $profileUserGroup: ID
    $comentatorUserGroup: ID
    $moduleInstance: ID
  ) {
    profilePageComments(
      after: $after
      first: $first
      profileUserGroup: $profileUserGroup
      comentatorUserGroup: $comentatorUserGroup
      moduleInstance: $moduleInstance
    ) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          ...ProfilePageComment
        }
      }
    }
  }
`)
