import { isDev } from 'helpers/env'

export const redirectToGradooLogin = () => {
  if (isDev()) {
    return
  }

  window.location.href = `${process.env.REACT_APP_GRADOO_APP_URL}/splash?lang=en&layoutcreator=1`
}

export const logout = () => {
  localStorage.removeItem('token')
  localStorage.removeItem('refreshToken')
  localStorage.removeItem('lcToken')
  localStorage.removeItem('lcRefreshToken')
  redirectToGradooLogin()
}
