import Flex from 'shared/ui/Flex'
import { Skeleton } from 'shared/ui/Skeleton'

const ProjectSettingsOrderBlockActionsSkeleton = () => (
  <Flex columnGap={10}>
    <Skeleton width={120} height={34} colorLevel={3} />
    <Skeleton width={120} height={34} colorLevel={3} />
  </Flex>
)

export default ProjectSettingsOrderBlockActionsSkeleton
