import Button from 'shared/ui/Button_2'

type ActionButtonProps = {
  onClick: () => void
  textId: string
}

const AdminActionButton: React.FC<ActionButtonProps> = ({
  onClick,
  textId
}) => {
  return (
    <Button
      textId={textId}
      onPress={onClick}
      intent="sub-action"
      minWidth={248}
      size="xl"
    />
  )
}

export default AdminActionButton
