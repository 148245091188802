import { useQuery } from '@apollo/client'
import { useEffect, useMemo } from 'react'
import toast from 'react-hot-toast'

import Button, { ButtonIntents } from 'components/Button'
import Flex from 'components/Flex'
import { GET_PROJECT_EXPORTS } from 'data/layoutcreator/queries/export'
import { downloadPdf } from 'screens/AdminProject/tabs/ExportTab/utils.'
import { ProjectExportStatuses } from 'screens/AdminProject/types'
import {
  ExportProject,
  ProjectDocument
} from 'types/layoutcreator/graphql'

import ExportDocument from '../../components/ExportDocument'
import PagesContainer from '../../components/PagesContainer'
import SecondLevelTitle from '../../components/SecondLevelTitle'
import { ExportPageWithMeta } from '../../types'

type ExportingModeProps = {
  exportData: ExportProject
  projectDocuments: ProjectDocument[]
  isReadyForDownload: boolean
  isFinished: boolean
  onStartNew: () => void
}

const ExportingMode: React.FC<ExportingModeProps> = ({
  exportData,
  projectDocuments,
  isReadyForDownload,
  isFinished,
  onStartNew
}) => {
  const { refetch: refetchExports } = useQuery(GET_PROJECT_EXPORTS, {
    variables: {
      groupId: exportData?.groupId,
      projectId: exportData?.projectId
    }
  })

  const pages = useMemo(() => {
    if (!exportData?.pages) {
      return []
    }

    const result: ExportPageWithMeta[] = []

    for (const page of exportData.pages) {
      const document = projectDocuments.find(
        document => document.id === page.pageId
      )
      if (document) {
        result.push({
          ...page,
          name: document.name,
          index: document.index || 0
        })
      }
    }

    return result
  }, [exportData, projectDocuments])

  useEffect(() => {
    refetchExports()
  }, [])

  useEffect(() => {
    if (isFinished) {
      toast.success('Export finished')
      refetchExports()
    }
  }, [isFinished])

  return (
    <Flex direction="column" flex={1}>
      <SecondLevelTitle>Pages:</SecondLevelTitle>

      <PagesContainer>
        {pages.map(page => (
          <ExportDocument
            key={page.pageId}
            id={page.pageId}
            number={page.index + 1}
            name={page.name}
            status={page.status as ProjectExportStatuses}
            error={{
              code: page.error?.code || undefined,
              message: page.error?.message || undefined
            }}
            isSelected
          />
        ))}
      </PagesContainer>

      <Flex marginTop={32} justifyContent="flex-end" columnGap={12}>
        {isFinished && (
          <Button
            labelId="Admin.buttons.startNewExport"
            intent={ButtonIntents.neutral}
            onClick={onStartNew}
            borderRadius={100}
          />
        )}

        {isReadyForDownload && (
          <Button
            labelId="Admin.buttons.downloadPdf"
            intent={ButtonIntents.dark}
            onClick={() =>
              downloadPdf({
                projectId: exportData?.projectId,
                groupId: exportData?.groupId,
                exportId: exportData?.id
              })
            }
            borderRadius={100}
          />
        )}
      </Flex>
    </Flex>
  )
}

export default ExportingMode
