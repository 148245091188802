import { gql } from 'types/layoutcreator'

export const LOGIN_TO_ADMIN = gql(/* GraphQL */ `
  mutation adminLogin($login: String!, $password: String!) {
    adminLogin(login: $login, password: $password)
  }
`)

export const START_MIGRATION = gql(/* GraphQL */ `
  mutation startImageMigration(
    $groupId: String!
    $projectId: String!
    $author: String
    $type: String
  ) {
    startImageMigration(
      groupId: $groupId
      projectId: $projectId
      author: $author
      type: $type
    )
  }
`)

export const START_PAGE_EXPORT = gql(/* GraphQL */ `
  mutation startExportDocument(
    $groupId: String!
    $projectId: String!
    $documentId: String!
    $outputProfile: String
  ) {
    startExportDocument(
      groupId: $groupId
      projectId: $projectId
      documentId: $documentId
      outputProfile: $outputProfile
    ) {
      documentId
      taskId
      isStarted
      isFinished
      isSucceeded
      isVariableSet
      url
    }
  }
`)

export const FIX_VARIABLES = gql(/* GraphQL */ `
  mutation fixVariables($groupId: String!, $projectId: String!) {
    fixVariables(groupId: $groupId, projectId: $projectId)
  }
`)

export const ADD_NEW_ASSET = gql(/* GraphQL */ `
  mutation addNewAsset(
    $id: String!
    $path: String!
    $name: String!
    $file: Upload!
  ) {
    addNewAsset(id: $id, name: $name, path: $path, file: $file)
  }
`)

export const RECOVER_DELETED_PROJECT = gql(/* GraphQL */ `
  mutation recoverDeletedProject(
    $groupId: String!
    $projectId: String!
  ) {
    recoverDeletedProject(groupId: $groupId, projectId: $projectId)
  }
`)

export const EMPTY_EXPORT_QUEUE = gql(/* GraphQL */ `
  mutation emptyExportQueue {
    emptyExportQueue
  }
`)
