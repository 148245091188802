import {
  useLazyQuery,
  useMutation,
  useQuery,
  useSubscription
} from '@apollo/client'
import { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import styled from 'styled-components'

import Flex from 'components/Flex'
import Input from 'components/Input'
import Select from 'components/Select'
import IslandModal, {
  IslandModalProps
} from 'components/modals/IslandModal'
import useAuth from 'data/gradoo/hooks/useAuth'
import { START_PROJECT_EXPORT } from 'data/layoutcreator/mutations/export'
import { GET_PROJECT_DOCUMENTS } from 'data/layoutcreator/queries/documents'
import { GET_PROJECT_EXPORT } from 'data/layoutcreator/queries/export'
import { TRACK_EXPORT } from 'data/layoutcreator/subscriptions/export'
import { removeValuesFromArray } from 'helpers/array'
import {
  ProjectExportStatuses,
  ProjectExportTypes
} from 'screens/AdminProject/types'
import { ExportProject } from 'types/layoutcreator/graphql'

import ExportStatus from '../ExportStatus'
import ExportingMode from './modes/ExportingMode'
import StartExportMode from './modes/StartExportMode'

const RETRY_FAILED_PAGES_MAX = 5

enum ExportModalModes {
  startExport = 'startExport',
  exporting = 'exporting'
}

const Label = styled.label`
  font-weight: 600;
  margin-bottom: 12px;
`

type ProjectExportModalProps = Pick<
  IslandModalProps,
  'isOpen' | 'closeModal'
> & {
  groupId: string
  projectId: string
  exportId: string | null
}

export const OUTPUT_TYPES: {
  name: string
  type: ProjectExportTypes
  apiKey: string
}[] = [
  {
    name: 'Preview',
    type: ProjectExportTypes.preview,
    apiKey: 'preview'
  },
  {
    name: 'Final Production',
    type: ProjectExportTypes.print,
    apiKey: 'print'
  }
]

const ProjectExportModal: React.FC<ProjectExportModalProps> = ({
  isOpen,
  closeModal,
  groupId,
  projectId,
  exportId
}) => {
  const { authUserId } = useAuth()

  const [mode, setMode] = useState<ExportModalModes>(
    ExportModalModes.startExport
  )

  const [pagesFrom, setPagesFrom] = useState('')
  const [pagesTo, setPagesTo] = useState('')
  const [outputTypeIndex, setOutputTypeIndex] = useState(0)
  const [retryFailedPages, setRetryFailedPages] = useState('0')
  const [selectedPages, setSelectedPages] = useState<number[]>([])
  const [exportFreshData, setExportFreshData] =
    useState<ExportProject | null>(null)

  const { data: documentsData } = useQuery(GET_PROJECT_DOCUMENTS, {
    variables: {
      groupId,
      projectId
    }
  })

  const projectDocuments = documentsData?.projectDocuments || []

  const [getProjectExport] = useLazyQuery(GET_PROJECT_EXPORT)

  const [
    startExport,
    { data: startExportData, loading: isStartExportLoading }
  ] = useMutation(START_PROJECT_EXPORT)
  const { data: trackExportData, loading: isTrackExportLoading } =
    useSubscription(TRACK_EXPORT, {
      variables: {
        id: startExportData?.startExport.id || ''
      }
    })

  useEffect(() => {
    if (startExportData?.startExport) {
      setExportFreshData(startExportData.startExport || null)
    }
  }, [startExportData])

  useEffect(() => {
    setExportFreshData(
      trackExportData?.trackExport
        ? trackExportData.trackExport
        : null
    )
  }, [trackExportData])

  useEffect(() => {
    if (exportFreshData) {
      setMode(ExportModalModes.exporting)
    }
  }, [exportFreshData])

  useEffect(() => {
    if (!exportId) {
      return
    }

    const setExportData = async () => {
      const { data } = await getProjectExport({
        variables: { id: exportId }
      })

      setExportFreshData(data!.getProjectExport)
    }

    setMode(ExportModalModes.exporting)
    setExportData()
  }, [exportId])

  const handleStartExport = async () => {
    await toast.promise(
      startExport({
        variables: {
          groupId,
          projectId,
          outputProfile: OUTPUT_TYPES[outputTypeIndex].apiKey,
          userId: authUserId!,
          pageIds: selectedPages.map(
            index => projectDocuments[index].id
          ),
          retryFailedPages: retryFailedPages
            ? parseInt(retryFailedPages)
            : 0
        }
      }),
      {
        loading: 'Starting export...',
        success: 'Export started',
        error: 'Error while starting export'
      }
    )
  }

  const resetAllFields = () => {
    setPagesFrom('1')
    setPagesTo(
      projectDocuments.length
        ? projectDocuments.length.toString()
        : '1'
    )
    setOutputTypeIndex(0)
  }

  const handleCancel = () => {
    resetAllFields()
    closeModal()
  }

  const handleStartNewExport = () => {
    resetAllFields()
    setMode(ExportModalModes.startExport)
    setExportFreshData(null)
  }

  const handlePageClick = (index: number) => {
    if (selectedPages.includes(index)) {
      setSelectedPages(removeValuesFromArray(selectedPages, [index]))
      return
    }

    setSelectedPages([...selectedPages, index].sort())
  }

  const handlePagesFromChange = (newValue: string) => {
    const value = Number.parseInt(newValue)
    if (value < 1) {
      setPagesFrom('1')
      return
    }

    setPagesFrom(newValue)
  }

  const handlePagesToChange = (newValue: string) => {
    const value = Number.parseInt(newValue)
    if (value > projectDocuments.length) {
      setPagesTo('1')
      return
    }

    setPagesTo(newValue)
  }

  const setPagesRange = () => {
    if (projectDocuments.length) {
      setPagesFrom('1')
      setPagesTo(projectDocuments.length.toString())
    }
  }

  useEffect(() => {
    setPagesRange()
  }, [projectDocuments])

  useEffect(() => {
    if (pagesFrom && pagesTo) {
      const from = parseInt(pagesFrom)
      const to = parseInt(pagesTo)

      if (from && to) {
        const pages = Array.from(
          { length: to - from + 1 },
          (_, i) => i + from - 1
        )

        setSelectedPages(pages)
      }
    }
  }, [pagesFrom, pagesTo])

  useEffect(() => {
    let counter = selectedPages[0]
    const isConsecutive = selectedPages.every(pageIndex => {
      const result = pageIndex === counter
      counter++
      return result
    })

    if (isConsecutive) {
      setPagesFrom((selectedPages[0] + 1).toString())
      setPagesTo(
        (selectedPages[selectedPages.length - 1] + 1).toString()
      )
    } else {
      setPagesFrom('')
      setPagesTo('')
    }
  }, [selectedPages])

  const isReadyForDownload =
    exportFreshData?.status === ProjectExportStatuses.completed ||
    exportFreshData?.status === ProjectExportStatuses.warning

  const isExportFinished =
    exportFreshData?.status === ProjectExportStatuses.completed ||
    exportFreshData?.status === ProjectExportStatuses.error ||
    exportFreshData?.status === ProjectExportStatuses.warning

  return (
    <IslandModal
      isOpen={isOpen}
      closeModal={closeModal}
      width={1000}
      title="Project export"
      titleAlign="left"
      titleSize={22}
      renderHeaderAppend={() =>
        mode === ExportModalModes.exporting ? (
          <ExportStatus
            value={exportFreshData?.status as ProjectExportStatuses}
          />
        ) : (
          <></>
        )
      }
    >
      <Flex direction="row" columnGap={36}>
        <Flex alignItems="center" flex={1} columnGap={10}>
          <Input
            label="Page from"
            type="number"
            placeholder="From"
            value={pagesFrom}
            onChange={handlePagesFromChange}
            padding={[14, 12]}
            isDisabled={mode === ExportModalModes.exporting}
          />
          {'-'}
          <Input
            label="Page to"
            type="number"
            placeholder="To"
            value={pagesTo}
            onChange={handlePagesToChange}
            padding={[14, 12]}
            isDisabled={mode === ExportModalModes.exporting}
          />
        </Flex>

        <Flex direction="column" flex={1}>
          <Label>Output type:</Label>
          <Select
            label="Type"
            activeIndex={outputTypeIndex}
            options={OUTPUT_TYPES.map(({ name }) => name)}
            onChange={(_, index) => setOutputTypeIndex(index)}
            isDisabled={mode === ExportModalModes.exporting}
          />
        </Flex>

        <Input
          type="number"
          value={retryFailedPages}
          onChange={(newNumber: string) => {
            const value = newNumber
              ? Math.min(
                  Number(newNumber),
                  RETRY_FAILED_PAGES_MAX
                ).toString()
              : ''
            setRetryFailedPages(value)
          }}
          padding={[14, 12]}
          label="Retry failed pages"
          isDisabled={mode === ExportModalModes.exporting}
        />
      </Flex>

      <Flex marginTop={12}>
        {mode === ExportModalModes.startExport && (
          <StartExportMode
            selectedPages={selectedPages}
            onPageClick={handlePageClick}
            projectDocuments={projectDocuments}
            isLoading={isStartExportLoading}
            onStart={handleStartExport}
            onCancel={handleCancel}
          />
        )}
        {mode === ExportModalModes.exporting && (
          <ExportingMode
            exportData={exportFreshData!}
            projectDocuments={projectDocuments}
            isFinished={isExportFinished}
            isReadyForDownload={isReadyForDownload}
            onStartNew={handleStartNewExport}
          />
        )}
      </Flex>
    </IslandModal>
  )
}

export default ProjectExportModal
