import { useIntl } from 'react-intl'
import styled, { css } from 'styled-components'

import { HistoryEntryStatus, HistoryEntryType } from '../../types'

type PillProps = {
  intent: HistoryEntryStatus | HistoryEntryType | string
}

type TagProps = {
  textI18nId: string
} & PillProps

const Pill = styled.div<PillProps>`
  display: inline-flex;
  padding: 0 10px;
  border-radius: 20px;
  ${({ theme, intent }) => {
    function getColors(): [string, string] {
      switch (intent) {
        case HistoryEntryType.sample:
        case 'proof':
          return [theme.color.brand_02, '#fff']
        case HistoryEntryType.final:
          return [theme.color.brand_01, '#000']
        case HistoryEntryType.preview:
          return [theme.color.base.c7, theme.color.base.c2]
        case HistoryEntryStatus.failed:
        case 'error':
          return ['#FFF0EE', theme.color.error]
        case HistoryEntryStatus.completed:
          return ['#E8F9EF', '#41A569']
        case HistoryEntryStatus.progress:
        default:
          return ['#FFEFE2', '#FF8325']
      }
    }
    const [background, color] = getColors()
    return css`
      background: ${background};
      color: ${color};
    `
  }};
  font-size: 13px;
  line-height: 22px;
`

const Tag = ({ textI18nId, intent }: TagProps) => {
  const { formatMessage } = useIntl()
  return (
    <Pill intent={intent}>{formatMessage({ id: textI18nId })}</Pill>
  )
}

export default Tag
