import { motion } from 'framer-motion'
import { ReactNode, useCallback, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components'

import {
  ReactionEvent,
  useBroadcastEvent,
  useEventListener,
  useMyPresence,
  useOther,
  useOthersConnectionIds,
  useUpdateMyPresence
} from 'context/project-room'
import { useSelf } from 'context/project-room'
import { mapUserShortName } from 'helpers/mappers'
import useInterval from 'hooks/useInterval'
import { MAIN_CONTAINER_WIDTH } from 'styles/constants'
import { getLiveHexColor } from 'styles/utils'

import FlyingReaction from './components/FlyingReaction'
import ReactionSelector from './components/ReactionSelector'
import ShortcutHint from './components/ShortcutHint'

const LiveCursorContainer = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
  user-select: none;
`

const Content = styled.div``

interface INameContent {
  color: string
  isDragging: boolean
}
const NameContent = styled.div<INameContent>`
  margin-left: 20px;
  background-color: ${({ color }) => color};
  padding: 2px 16px;
  ${({ isDragging }) =>
    isDragging
      ? `border-top-left-radius: 8px; 
           border-top-right-radius: 8px;`
      : `border-radius: 5px;`}
`

const Name = styled.div`
  color: ${({ theme }) => theme.color.base.c0};
  font-family: Inter;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
`

const DraggingContent = styled.div<{ previewUrl: string }>`
  position: absolute;
  right: 0px;
  width: 134px;
  height: 190px;
  background: url(${({ previewUrl }) => previewUrl}) center / cover;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  overflow: hidden;
`

const DraggingContentOverlay = styled.div<{ color: string }>`
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: ${({ color }) => color};
  opacity: 0.7;
`

const ChatContent = styled.div<{ color: string }>`
  margin-left: 20px;
  padding 8px 16px;
  background-color: ${({ color }) => color};
  color: ${({ theme }) => theme.color.base.c0};
  border-radius: 20px;
`

const ChatText = styled.div`
  color: ${({ theme }) => theme.color.base.c0};
  font-family: Inter;
  font-size: 14px;
  line-height: 16px;
`

const ChatInput = styled.input`
  background-color: transparent;
  color: ${({ theme }) => theme.color.base.c0};
  border-style: none;
  outline: 0px;
  font-family: Inter;
  font-size: 14px;
  line-height: 16px;

  &::placeholder {
    opacity: 0.5;
    color: ${({ theme }) => theme.color.base.c0};
  }
`

/*
problem: we have a centered div (from PageContainer) of 1140px width (CW) that get's centered
  on YearbookPages
  |------PX------|-------------------CW-------------------|------PX------|
  |-----------------x-----------------|
  so for example, on a screen 1 with 2000px width, the cursor is on a x position of 600px
  from a viewer on a screen 2 of 1500px width, the x position from S1 will be showed a way
  more distant on CW because PX will be different
  SCREEN 1
  |------PX------|-------------------CW-------------------|------PX------|
  |-----------------x-----------------|
  SCREEN 2
  |---PX---|-------------------CW-------------------|---PX---|
  |-----------------x-----------------|

solution: we set as a global position (GX) based on the distance of x inside CW so
  SCREEN 1
  |------PX------|-------------------CW-------------------|------PX------|
  |---------------x---------------|
                 |----------GX----------|
  SCREEN 2
  |---PX---|-------------------CW-------------------|---PX---|
  |---------------x---------------|
           |----------GX----------|
  and calculate the x on each screen based on the global position and the screen PX

  PX on screen 1 would be 430px (2PX+CW=W => PX=(W-CW)/2 => (2000 -1140)/2
  PX on screen 2 would be 180px (2PX+CW=W => PX=(W-CW)/2 => (2000 -1140)/2

  so we store the cursor from x as GX (based on the screen 1 PX)
  and read the cursor from GX as x (based on the screen 2 PX)
*/

const getPX = () => {
  const W = window.innerWidth
  const CW = MAIN_CONTAINER_WIDTH
  return (W - CW) / 2
}

const getGXFromX = (x: number): number => {
  return x - getPX()
}

const getXFromGX = (gx: number): number => {
  return gx + getPX()
}

type LiveCursorProps = {
  id: number
}

export function LiveCursor({ id }: LiveCursorProps) {
  const color = getLiveHexColor(id)
  const cursor = useOther(id, other => other.presence.cursor)
  const info = useOther(id, other => other.presence.info)
  const message = useOther(id, other => other.presence.message)

  const draggingPreviewUrl = useOther(
    id,
    other => other.presence.draggingPreviewUrl
  )

  const isDragging = !!draggingPreviewUrl

  if (!cursor) {
    return null
  }
  const { x, y } = cursor
  return (
    <LiveCursorContainer
      initial={{ x: getXFromGX(x), y }}
      animate={{ x: getXFromGX(x), y }}
      transition={{
        type: 'spring',
        bounce: 0.6,
        damping: 30,
        mass: 0.8,
        stiffness: 350,
        restSpeed: 0.01
      }}
    >
      <Content>
        <svg
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g filter="url(#filter0_d_3923_4081)">
            <path
              d="M10.75 25.5L7 6L23.5 15.75L15.25 18L10.75 25.5Z"
              fill={color}
            />
            <path
              d="M10.0135 25.6416L10.3839 27.5679L11.3931 25.8859L15.7381 18.6443L23.6973 16.4736L25.4088 16.0068L23.8815 15.1043L7.38155 5.35431L5.94933 4.508L6.2635 6.14164L10.0135 25.6416Z"
              stroke="white"
              strokeWidth="1.5"
              strokeLinecap="square"
            />
          </g>
          <defs>
            <filter
              id="filter0_d_3923_4081"
              x="0.398437"
              y="0.0159907"
              width="31.4194"
              height="35.6198"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="1.5" />
              <feGaussianBlur stdDeviation="2.25" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.35 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_3923_4081"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_3923_4081"
                result="shape"
              />
            </filter>
          </defs>
        </svg>
      </Content>
      {message ? (
        <ChatContent color={color}>
          <ChatText>{message}</ChatText>
        </ChatContent>
      ) : (
        <NameContent color={color} isDragging={isDragging}>
          <Name>{mapUserShortName(info)}</Name>
        </NameContent>
      )}
      {draggingPreviewUrl ? (
        <DraggingContent previewUrl={draggingPreviewUrl}>
          <DraggingContentOverlay color={color} />
        </DraggingContent>
      ) : null}
    </LiveCursorContainer>
  )
}

const Container = styled.div``

const CursorContainer = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
`

const ReactionContent = styled.div`
  position: absolute;
  left: 4px;
  top: 12px;
  pointer-events: none;
  user-select: none;
`

const ReactSelectorContainer = styled.div<{ x: number; y: number }>`
  position: absolute;
  left: ${({ x }) => x}px;
  top: ${({ y }) => y}px;
`

enum CursorMode {
  Hidden,
  Chat,
  ReactionSelector,
  Reaction
}

type CursorState =
  | {
      mode: CursorMode.Hidden
    }
  | {
      mode: CursorMode.Chat
      message: string
      previousMessage: string | null
    }
  | {
      mode: CursorMode.ReactionSelector
      position: { x: number; y: number }
    }
  | {
      mode: CursorMode.Reaction
      reaction: string
      isPressed: boolean
    }

type Reaction = {
  value: string
  timestamp: number
  point: { x: number; y: number }
}

export default function LiveCursorWrapper({
  children
}: {
  children: ReactNode
}) {
  const { formatMessage } = useIntl()
  const [{ cursor }] = useMyPresence()
  const selfConnectionId = useSelf(me => me.connectionId)

  const broadcast = useBroadcastEvent()
  const updateMyPresence = useUpdateMyPresence()
  const ids = useOthersConnectionIds()

  const [state, setState] = useState<CursorState>({
    mode: CursorMode.Hidden
  })
  const [reactions, setReactions] = useState<Reaction[]>([])

  const setReaction = useCallback((reaction: string) => {
    setState({
      mode: CursorMode.Reaction,
      reaction,
      isPressed: false
    })
  }, [])

  // Remove reactions that are not visible anymore (every 1 sec)
  useInterval(() => {
    setReactions(reactions =>
      reactions.filter(
        reaction => reaction.timestamp > Date.now() - 4000
      )
    )
  }, 1000)

  useInterval(() => {
    if (
      state.mode === CursorMode.Reaction &&
      state.isPressed &&
      cursor
    ) {
      setReactions(reactions =>
        reactions.concat([
          {
            point: { x: cursor.x, y: cursor.y },
            value: state.reaction,
            timestamp: Date.now()
          }
        ])
      )
      broadcast({
        x: cursor.x,
        y: cursor.y,
        value: state.reaction
      })
    }
  }, 100)

  useEffect(() => {
    //Cursor
    let scroll = {
      x: window.scrollX,
      y: window.scrollY
    }

    let lastPosition: { x: number; y: number } | null = null

    function transformPosition(cursor: { x: number; y: number }) {
      return {
        x: getGXFromX(cursor.x),
        y: cursor.y
      }
    }

    function onPointerMove(event: PointerEvent) {
      event.preventDefault()
      const position = {
        x: event.pageX,
        y: event.pageY
      }
      lastPosition = position
      updateMyPresence({
        cursor: transformPosition(position)
      })
    }

    function onPointerLeave() {
      lastPosition = null
      updateMyPresence({ cursor: null })
    }

    function onDocumentScroll() {
      if (lastPosition) {
        const offsetX = window.scrollX - scroll.x
        const offsetY = window.scrollY - scroll.y
        const position = {
          x: lastPosition.x + offsetX,
          y: lastPosition.y + offsetY
        }
        lastPosition = position
        updateMyPresence({
          cursor: transformPosition(position)
        })
      }
      scroll.x = window.scrollX
      scroll.y = window.scrollY
    }

    //Reactions
    function onKeyUp(e: KeyboardEvent) {
      if (e.key.toLocaleLowerCase() === 'c') {
        setState({
          mode: CursorMode.Chat,
          previousMessage: null,
          message: ''
        })
      } else if (e.key === 'Escape') {
        updateMyPresence({ message: '' })
        setState({ mode: CursorMode.Hidden })
      } else if (e.key.toLocaleLowerCase() === 'e' && lastPosition) {
        setState({
          mode: CursorMode.ReactionSelector,
          position: lastPosition
        })
      }
    }

    function onPointerDown() {
      setState(state =>
        state.mode === CursorMode.Reaction
          ? { ...state, isPressed: true }
          : state
      )
    }

    function onPointerUp() {
      setState(state =>
        state.mode === CursorMode.Reaction
          ? { ...state, isPressed: false }
          : state
      )
    }

    document.addEventListener('scroll', onDocumentScroll)
    document.addEventListener('pointermove', onPointerMove)
    document.addEventListener('pointerleave', onPointerLeave)

    document.addEventListener('keyup', onKeyUp)
    document.addEventListener('pointerdown', onPointerDown)
    document.addEventListener('pointerup', onPointerUp)

    return () => {
      document.removeEventListener('scroll', onDocumentScroll)
      document.removeEventListener('pointermove', onPointerMove)
      document.removeEventListener('pointerleave', onPointerLeave)

      document.removeEventListener('keyup', onKeyUp)
      document.removeEventListener('pointerdown', onPointerDown)
      document.removeEventListener('pointerup', onPointerUp)
    }
  }, [updateMyPresence])

  useEventListener(eventData => {
    const event = eventData.event as ReactionEvent
    setReactions(reactions =>
      reactions.concat([
        {
          point: { x: event.x, y: event.y },
          value: event.value,
          timestamp: Date.now()
        }
      ])
    )
  })

  return (
    <Container>
      {children}
      {reactions.map(reaction => {
        return (
          <FlyingReaction
            key={reaction.timestamp.toString()}
            x={getXFromGX(reaction.point.x)}
            y={reaction.point.y}
            timestamp={reaction.timestamp}
            value={reaction.value}
          />
        )
      })}
      {cursor && (
        <CursorContainer
          style={{
            transform: `translateX(${getXFromGX(
              cursor.x
            )}px) translateY(${cursor.y}px)`
          }}
        >
          {state.mode === CursorMode.Chat && selfConnectionId && (
            <>
              <ChatContent
                onKeyUp={e => e.stopPropagation()}
                color={getLiveHexColor(selfConnectionId)}
              >
                {state.previousMessage && (
                  <div>{state.previousMessage}</div>
                )}
                <ChatInput
                  autoFocus={true}
                  onChange={e => {
                    updateMyPresence({ message: e.target.value })
                    setState({
                      mode: CursorMode.Chat,
                      previousMessage: null,
                      message: e.target.value
                    })
                  }}
                  onKeyDown={e => {
                    if (e.key === 'Enter') {
                      setState({
                        mode: CursorMode.Chat,
                        previousMessage: state.message,
                        message: ''
                      })
                    } else if (e.key === 'Escape') {
                      setState({
                        mode: CursorMode.Hidden
                      })
                    }
                  }}
                  placeholder={
                    state.previousMessage
                      ? ''
                      : formatMessage({ id: 'Pages.chat.hint' })
                  }
                  value={state.message}
                  maxLength={50}
                />
              </ChatContent>
            </>
          )}
          {state.mode === CursorMode.Reaction && (
            <ReactionContent>{state.reaction}</ReactionContent>
          )}
        </CursorContainer>
      )}
      {state.mode === CursorMode.ReactionSelector && (
        <ReactSelectorContainer
          x={state.position.x}
          y={state.position.y}
        >
          <ReactionSelector
            setReaction={reaction => {
              setReaction(reaction)
            }}
          />
        </ReactSelectorContainer>
      )}
      {ids.map(id => (
        <LiveCursor key={id} id={id} />
      ))}
      <ShortcutHint />
    </Container>
  )
}
