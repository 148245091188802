import { useMutation } from '@apollo/client'
import config from 'config'
import { useMemo, useState } from 'react'
import toast from 'react-hot-toast'
import { useIntl } from 'react-intl'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'

import { ButtonIntents } from 'components/Button'
import Flex from 'components/Flex'
import ModalButton from 'components/ModalButton'
import NumbersInput, {
  NumbersInputStatuses
} from 'components/NumbersInput'
import IslandModal, {
  IslandModalProps
} from 'components/modals/IslandModal'
import useAuth from 'data/gradoo/hooks/useAuth'
import { LINK_ORDER } from 'data/layoutcreator/mutations/projects'
import { GET_PROJECT_META } from 'data/layoutcreator/queries/projects'

const Description = styled.p`
  color: ${({ theme }) => theme.color.base.c6};
  text-align: center;
  margin: 0;
  margin-bottom: 35px;
`

const Hint = styled.p<{ isError: boolean; isVisible: boolean }>`
  text-align: center;
  margin: 0;
  margin-top: 24px;
  width: 100%;
  font-size: 12px;
  color: ${({ theme, isError }) =>
    isError ? theme.color.error : theme.color.brand_02};
  visibility: ${({ isVisible }) =>
    isVisible ? 'visible' : 'hidden'};

  & > a {
    width: 100%;
    color: ${({ theme, isError }) =>
      isError ? theme.color.error : theme.color.brand_02};
  }
`

type LinkOrderModalProps = Pick<
  IslandModalProps,
  'isOpen' | 'closeModal'
> & {}

const NUMBERS_COUNT = 5

const getInitialValue = () => {
  return Array(NUMBERS_COUNT)
    .fill(null)
    .map(() => '-')
    .join('')
}

const LinkOrderModal: React.FC<LinkOrderModalProps> = ({
  isOpen,
  closeModal
}) => {
  const { formatMessage } = useIntl()
  const { pathname } = useLocation()

  const {
    authGroupId,
    authUserGroupYear,
    authUserInstituteInternalId
  } = useAuth()
  const projectId = pathname.split('/')[1]

  const [value, setValue] = useState(getInitialValue())

  const [linkOrder, { data, reset }] = useMutation(LINK_ORDER, {
    refetchQueries: [
      {
        query: GET_PROJECT_META,
        variables: { projectId, groupId: authGroupId }
      }
    ]
  })
  const [currentResultValue, setCurrentResultValue] = useState<
    string | null
  >(null)

  const isResultActual = useMemo(
    () => value === currentResultValue,
    [value, currentResultValue]
  )
  const isError = useMemo(
    () => !!data?.linkOrder?.errorType && isResultActual,
    [data, isResultActual]
  )
  const isSuccessful = useMemo(
    () => !!data?.linkOrder?.successful && isResultActual,
    [data]
  )

  const handleConfirm = async () => {
    const toastId = toast.loading(
      formatMessage({ id: 'Messages.loading' })
    )

    setCurrentResultValue(value)

    if (authUserGroupYear && authUserInstituteInternalId) {
      await linkOrder({
        variables: {
          groupId: authGroupId!,
          projectId,
          orderNumber: value,
          year: authUserGroupYear,
          customerNumber: authUserInstituteInternalId
        }
      })
    } else {
      toast.remove(toastId)
      toast.error(
        'Missing customer number and graduation year params.'
      )
      return
    }

    toast.remove(toastId)

    return
  }

  const handleCancel = () => {
    reset()
    setCurrentResultValue(getInitialValue())
    closeModal()
  }

  const status = useMemo(() => {
    if (!isError) {
      if (isSuccessful) {
        return NumbersInputStatuses.success
      }

      return NumbersInputStatuses.default
    }

    return NumbersInputStatuses.error
  }, [data, value, isError])

  const handleHelpClick = () => {
    ;(window as any).Intercom(
      'showArticle',
      config.intercom.articles.orderHelp
    )
  }

  return (
    <IslandModal
      isOpen={isOpen}
      closeModal={closeModal}
      width={345}
      title={
        formatMessage(
          {
            id: `Project.settings.order.modal.title.${
              isSuccessful ? 'success' : 'default'
            }`
          },
          {
            br: <br />
          }
        ) as string
      }
    >
      <Description>
        {formatMessage({
          id: `Project.settings.order.modal.description.${
            isSuccessful ? 'success' : 'default'
          }`
        })}
      </Description>

      <Flex justifyContent="center">
        <NumbersInput
          disabled={status === NumbersInputStatuses.success}
          status={status}
          count={NUMBERS_COUNT}
          onChange={setValue}
        />
      </Flex>

      <Hint isVisible={!isSuccessful} isError={isError}>
        {isError ? (
          formatMessage(
            {
              id: `Project.settings.order.modal.message.${
                data!.linkOrder!.errorType
              }`
            },
            {
              link: (
                <a href="mailto:support@gradoo.com">
                  {formatMessage({
                    id: 'Project.settings.order.modal.message.invalid.link'
                  })}
                </a>
              )
            }
          )
        ) : (
          <a onClick={handleHelpClick}>
            {formatMessage({
              id: 'Project.settings.order.modal.hint'
            })}
          </a>
        )}
      </Hint>

      <Flex marginTop={48} columnGap={7}>
        {isSuccessful ? (
          <ModalButton
            labelId={`Project.settings.order.modal.buttons.gotIt`}
            intent={ButtonIntents.dark}
            isFullWidth
            onClick={handleCancel}
          />
        ) : (
          <>
            <ModalButton
              labelId="Actions.cancel"
              isFullWidth
              intent={ButtonIntents.neutral}
              onClick={handleCancel}
            />
            <ModalButton
              labelId={`Project.settings.order.modal.buttons.confirm`}
              intent={ButtonIntents.dark}
              isFullWidth
              onClick={handleConfirm}
              isDisabled={value.split('').some(char => char === '-')}
            />
          </>
        )}
      </Flex>
    </IslandModal>
  )
}

export default LinkOrderModal
