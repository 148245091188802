import config from 'config'
import { isProd } from 'shared/helpers/env'
import { freeze } from 'shared/helpers/runtime'

export const redirectToGradooLogin = async () => {
  if (!isProd()) {
    return
  }

  if (config.debugMode) {
    console.log('Freezing for 5 seconds before redirect...')
    await freeze(5000)
  }

  window.location.href = `${process.env.REACT_APP_GRADOO_APP_URL}/splash?lang=en&layoutcreator=1`
}

export const logout = () => {
  localStorage.removeItem('token')
  localStorage.removeItem('refreshToken')
  localStorage.removeItem('lcToken')
  localStorage.removeItem('lcRefreshToken')
  redirectToGradooLogin()
}
