export enum ProjectExportTypes {
  print = 'print',
  preview = 'preview'
}

export enum ProjectExportStatuses {
  exporting = 'exporting',
  merging = 'merging',
  error = 'error',
  warning = 'warning',
  completed = 'completed',
  waiting = 'waiting'
}
