import { gql } from 'types/layoutcreator'

export const SET_THEME_COLOR = gql(/* GraphQL */ `
  mutation setThemeColor(
    $groupId: String!
    $projectId: String!
    $key: String!
    $value: CMYKColorInput!
    $templatePath: String!
  ) {
    setThemeColor(
      groupId: $groupId
      projectId: $projectId
      key: $key
      value: $value
      templatePath: $templatePath
    )
  }
`)

export const SET_THEME_PAGE_LIBRARY_BACKGROUND = gql(/* GraphQL */ `
  mutation setThemePageLibraryBackground(
    $groupId: String!
    $projectId: String!
    $templatePath: String!
    $pageType: String!
    $backgroundId: String!
    $backgroundCategory: String!
  ) {
    setThemePageLibraryBackground(
      groupId: $groupId
      projectId: $projectId
      templatePath: $templatePath
      pageType: $pageType
      backgroundId: $backgroundId
      backgroundCategory: $backgroundCategory
    )
  }
`)

export const SET_THEME_PAGE_UPLOADS_BACKGROUND = gql(/* GraphQL */ `
  mutation setThemePageUploadsBackground(
    $groupId: String!
    $projectId: String!
    $pageType: String!
    $templatePath: String!
    $backgroundId: String!
  ) {
    setThemePageUploadsBackground(
      groupId: $groupId
      projectId: $projectId
      pageType: $pageType
      templatePath: $templatePath
      backgroundId: $backgroundId
    )
  }
`)

export const RESET_THEME_PAGE_BACKGROUND = gql(/* GraphQL */ `
  mutation resetThemePageBackground(
    $groupId: String!
    $projectId: String!
    $pageType: String!
    $templatePath: String!
  ) {
    resetThemePageBackground(
      groupId: $groupId
      projectId: $projectId
      pageType: $pageType
      templatePath: $templatePath
    )
  }
`)

export const UPLOAD_PAGE_BACKGROUND = gql(/* GraphQL */ `
  mutation uploadPageBackground(
    $groupId: String!
    $projectId: String!
    $file: Upload!
  ) {
    uploadPageBackground(
      groupId: $groupId
      projectId: $projectId
      file: $file
    ) {
      id
      thumbPath
    }
  }
`)

export const REMOVE_UPLOADS_PAGE_BACKGROUND = gql(/* GraphQL */ `
  mutation removeUploadsPageBackground(
    $groupId: String!
    $projectId: String!
    $backgroundId: String!
    $type: String!
  ) {
    removeUploadsPageBackground(
      groupId: $groupId
      projectId: $projectId
      backgroundId: $backgroundId
      type: $type
    )
  }
`)
