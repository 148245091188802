import { useQuery } from '@apollo/client'

import Flex from 'shared/ui/Flex'
import { Skeleton } from 'shared/ui/Skeleton'
import { REPORTS_INSTANCES } from 'data/gradoo/queries/reports'
import { ReportsInstanceFragment } from 'shared/types/gradoo/graphql'

import { PanelProps, Panels } from '../../types'
import ReportItem from './components/ReportItem'

type ReportsPanelProps = PanelProps & {
  moduleInstanceId?: string
}

const ReportsPanel: React.FC<ReportsPanelProps> = ({
  moduleInstanceId,
  push
}) => {
  const { data: reportsInstancesData, loading } = useQuery(
    REPORTS_INSTANCES,
    {
      skip: !moduleInstanceId,
      variables: {
        moduleInstance: moduleInstanceId
      },
      context: { client: 'gradoo' }
    }
  )

  const reportsInstances: ReportsInstanceFragment[] =
    (reportsInstancesData?.reportsInstances?.edges.map(
      edge => edge?.node
    ) as ReportsInstanceFragment[]) || []

  return (
    <Flex direction="column">
      <Flex direction="column" rowGap={16}>
        {loading
          ? new Array(3)
              .fill(null)
              .map((_, i) => <Skeleton key={i} height={48} />)
          : reportsInstances.map(reportsInstance => (
              <ReportItem
                data-testid={`ReportItem:${reportsInstance.id}`}
                key={reportsInstance.id}
                onClick={() =>
                  push({
                    panel: Panels.report,
                    title: reportsInstance.title,
                    props: {
                      reportInstanceId: reportsInstance.id
                    }
                  })
                }
                title={reportsInstance.title}
              />
            ))}
      </Flex>
    </Flex>
  )
}

export default ReportsPanel
